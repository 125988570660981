import actionCreatorFactory from 'typescript-fsa';
import * as Models from "models/api";

const actionCreator = actionCreatorFactory('borderResults');

export type registerPayload = {
  params: {
    token: string;
    answer: {
      category: string;
      area: string;
      university: string;
      faculty: string;
      department: string;
      result: string;
    }
  };
  promises?: { resolve: () => void, reject: (e: Error) => void}
}

const borderResultsActions = {
  verifyToken:
    actionCreator<{ token: string | null; }>(
      'VERIFY_TOKEN'),
  setVerifyTokenResult:
    actionCreator<{ token: string | null; error: boolean | null; loading: boolean; }>(
      'SET_VERIFY_TOKEN_RESULT'),

  fetchUniversitiesRecord:
    actionCreator<{ category: string; area: string }>(
      'FETCH_UNIVERSITEIS_RECORD'),
  setUniversitiesRecord:
    actionCreator<{ record: Models.BorderResultsUniversitiesRecord | null }>(
      'SET_UNIVERSISITES_RECORD'),

  fetchFacultiesRecord:
    actionCreator<{ area: string; university: string }>(
      'FETCH_FACULTIES_RECORD'),
  setFacultiesRecord:
    actionCreator<{ record: Models.BorderResultsFacultiesRecord | null }>(
      'SET_FACULTIES_RECORD'),

  fetchDepartmentsRecord:
    actionCreator<{ university: string; faculty: string }>(
      'FETCH_DEPARTMENTS_RECORD'),
  setDepartmentsRecord:
    actionCreator<{ record: Models.BorderResultsDepartmentsRecord | null }>(
      'SET_DEPARTMENTS_RECORD'),

  register:
    actionCreator<registerPayload>(
      'REGISTER'),
};

export default borderResultsActions;