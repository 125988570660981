import { reducerWithInitialState } from 'typescript-fsa-reducers';
import produce from 'immer'

import * as Models from 'models/api'
import infoActions from 'actions/infoActions';

export interface InfoState {
  list: {
    page: number;
    records: Models.InfoListItem[] | null,
    has_next: boolean;
  }
  detail: {
    id: number | null;
    record: Models.Info | null,
  }
}

const initialState: InfoState = {
  list: {
    page: 0,
    records: null,
    has_next: true,
  },
  detail: {
    id: null,
    record: null,
  }
};

export const infoReducer = reducerWithInitialState(initialState)
  .case(infoActions.setRecords, (state, payload) => {
    return produce(state, draftState => {
      draftState.list.page = payload.page
      if (!draftState.list.records || payload.page === 1) {
        draftState.list.records = payload.records.records
      } else {
        draftState.list.records.push(...payload.records.records)
      }
      draftState.list.has_next = payload.records.total_page > payload.page
    })
  })
  .case(infoActions.setDetailRecord, (state, payload) => {
    return produce(state, draftState => {
      draftState.detail.id = payload.id
      draftState.detail.record = payload.record
    })
  })