import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import PrivateHeader from 'containers/parts/PrivateHeader';
import { mappedProps } from 'containers/student/Mypage';
import SiteMain from 'containers/parts/SiteMain';
import CircleChart from '../parts/CircleChart'
import SectionInfoBox from 'components/parts/SectionInfoBox'
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import { DebounceInput } from 'react-debounce-input';
// import { useMediaQuery } from 'react-responsive'

// import { MypageBanner } from 'components/student/campaign/Shiro202003';
// import { MypageBanner as MypageBannerPoint } from 'components/student/campaign/Point202012';
import { MypageBanner } from 'components/student/campaign/Guide202012';

import { SectionStudyTimeStatsBox } from './SectionStudyTimeStatsBox'

const StudentMypage: React.FC<mappedProps> = (props: mappedProps) => {
  const entrustCurriculums: number[] = [13, 14, 15] //変数curriculumsにcodeが含まれていないためidを指定してます
  const [scheduleTab, setScheduleTab] = useState('current');
  const [showHint, setShowHint] = useState(false);
  const [ entrustStudyRecords, setEntrustStudyRecords ] = useState<any>(null)

  useEffect(() => {
    props.fetchData();
    props.fetchStudyAchievementsLatest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    props.fetchEntrustStudyData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.student.entrustStudy.records) {
      setEntrustStudyRecords(props.student.entrustStudy.records.entrust_current)
    }
  }, [props.student.entrustStudy.records])

  const mypage = props.student.mypage
  const studyTimeStats = props.student.studyTimeStats

  const curriculums = mypage && mypage.curriculums.filter(x => props.isRecommendCurriculum(x.id, props.user))
  const alEngineConnectedCurriculums = curriculums && curriculums.filter(x => x.al_engine_connected)
  const isEntrustCurriculums = curriculums && curriculums.some(x => entrustCurriculums.includes(x.id))

  return (
    <React.Fragment>
      <PrivateHeader />
      <SiteMain>
        <div className="site-main__main-contents">

          {
            props.isAvailableGuide &&
            <MypageBanner />
          }

          {/* {
            // MARK: 法人受講生以外
            !props.user.corporation &&
            <MypageBannerPoint />
          } */}

          {/* {
            props.isAvailableShiro202003 &&
            <MypageBanner />
          } */}

          <SectionStudyTimeStatsBox
            fetchNextStudyTimeStats={props.fetchNextStudyTimeStats}
            updateGoalTime={props.updateStudyTimeStatsGoalTime}
            records={studyTimeStats.records}
            recentlyStudiedAt = {props.studyAchievementsLatest && props.studyAchievementsLatest.study_achievements.recently_studied_at}
            consecutiveCorrectAnswerCount={props.studyAchievementsLatest && props.studyAchievementsLatest.study_achievements.consecutive_correct_answer_count}
          />

          {
            props.isAvailableStudentInfo &&
            <SectionInfoBox infoRecords={mypage ? mypage.admin_informations : []} />
          }

          {
            // MARK: 一般受講生はかならず英語を受講するのでalEngineConnectedCurriculumsが存在するはず
            alEngineConnectedCurriculums && alEngineConnectedCurriculums.length > 0 &&
            <section className="box">
                {isEntrustCurriculums &&
                  <header className="box__head">
                    <div className="box__title box__title--ai">
                      {props.user.nick_name}<small className="box__title-small">さん</small>専用のおまかせ学習
                    </div>
                  </header>
                }
              <div className="box__body">
                <ul className="ai-recommendation-list">
                  {
                    curriculums && curriculums.map((x, index) => (
                      !x.al_engine_connected && entrustCurriculums.includes(x.id) &&
                      <li key={index} className="ai-recommendation-list__item  ai-recommendation-list__item--manual">
                        <div className="ai-recommendation-list__subject">
                          { x.name }
                        </div>
                          {entrustStudyRecords && entrustStudyRecords.some((y: any) => y.curriculum === x.name) ?
                            <div className="ai-recommendation-list__topic">
                              {entrustStudyRecords.map((y: any, index: number) => y.curriculum === x.name && <div key={index}>{y.topic.description}</div>)}
                            </div> :
                            <div className="ai-recommendation-list__note">
                              おまかせ学習の設定が未設定です。下のボタンから設定画面へ進んでください。
                            </div>}
                        <div className="ai-recommendation-list__button">
                          <Link to={`entrust/${x.id}`}
                            className="button button--teal">
                            おまかせ学習を見る</Link>
                        </div>
                      </li>
                    ))
                  }
                </ul>
              </div>
              <header className="box__head">
                <div className="box__title box__title--ai">
                  { props.user.nick_name }<small className="box__title-small">さん</small>専用のAIおすすめ学習
                </div>
              </header>
              <div className="box__body">
                <ul className="ai-recommendation-list">
                  {
                    curriculums && curriculums.map((x, index) => (
                      x.al_engine_connected && 
                      <li key={index} className="ai-recommendation-list__item">
                        <div className="ai-recommendation-list__subject">
                          { x.name }
                        </div>
                        <div className="ai-recommendation-list__topic">
                          { x.recommend || '教科・単元選択学習から学習を進めてください。' }
                        </div>
                        <div className="ai-recommendation-list__button">
                          {
                            x.recommend ?
                            <Link to={`recommends/${x.id}`}
                              className="button button--primary">
                              AIおすすめ学習を見る</Link>:
                            <Link to={`study/${x.id}`}
                              className="button button--primary">
                              教科・単元選択学習へ</Link>
                          }
                        </div>
                      </li>
                    ))
                  }
                </ul>
              </div>
              <header className="box__head">
                <div className="box__title box__title--ai">
                  { props.user.nick_name }<small className="box__title-small">さん</small>専用の教科・単元選択学習
                </div>
              </header>
              <div className="box__body">
                <ul className="ai-recommendation-list">
                  {
                    curriculums && curriculums.map((x, index) => (

                      !x.al_engine_connected && !entrustCurriculums.includes(x.id) &&

                      <li key={index} className="ai-recommendation-list__item  ai-recommendation-list__item--manual">
                        <div className="ai-recommendation-list__subject">
                          { x.name }
                        </div>
                        <div className="ai-recommendation-list__note">
                          この教科はAIおすすめ学習機能がありません。教科・単元選択学習から学習を進めてください。
                        </div>
                        <div className="ai-recommendation-list__button">
                          <Link to={`study/${x.id}`}
                            className="button button--teal">
                            教科・単元選択学習へ</Link>
                        </div>
                      </li>
                    ))
                  }
                </ul>
              </div>
            </section>
          }

          <section className="box">
            <header className="box__head">
              <div className="box__title box__title--result">
                学習結果<small className="box__title-small box__title-small--result">（コンプリート率・学習完了率）</small>
              </div>
              <span
                onClick={() => setShowHint(!showHint)}
                className="box__head-note">
                コンプリート率・学習完了率について
              </span>
              <div
                className={classNames("balloon box__balloon", { 'is-active': showHint })}>
                <div
                  onClick={() => setShowHint(false)}
                  className="balloon__close">
                  説明を閉じる
                </div>
                <div className="balloon__title">
                  コンプリート率とは？（英語・数学・物理・化学・古文）
                </div>
                <div className="balloon__lead">
                  コンプリートとは、AIの判定の結果、当該トピックについて教科書や大学入学共通テストのレベルに対して、十分な理解度に達していることを示しています。<br />
                  コンプリート率は、ご入会時に設定された範囲の全トピックのうち、コンプリートとなったトピック数の割合を算出しています。
                </div>
                <div className="balloon__title">
                  学習完了率とは？
                </div>
                <div className="balloon__lead">
                  学習完了率とは、受講者が設定した範囲の全トピックのうち、すべての教材の学習を修了したトピック数の割合を算出しています。（日本史・世界史・英語ベーシック・数学ベーシックなどAIおすすめ学習モードのないドリル教材は、AIによる理解度判定を行っていないため、「コンプリート率」ではなく「学習完了率」の表示となります。）
                </div>
                <div className="balloon__title">
                  教材学習終了率とは？
                </div>
                <div className="balloon__lead">
                  各単元に設定されている教材のうち、学習を済ませた教材数の割合を算出しています。
                </div>
              </div>
            </header>
            <div className="box__body">
              <ul className="study-result-list">
                {
                  curriculums && curriculums.map((x, index) => (
                    <li key={index} className="study-result-list__item">
                      <div className="study-result">
                        <div className="study-result__chart">
                          {
                            x.al_engine_connected ?
                            <CircleChart label={x.name} rate={x.understanding / 100 } />:
                            <CircleChart label={x.name} rate={x.understanding / 100 } colorType={2} />
                          }
                        </div>
                        <div className="study-result__button">
                          <Link to={`/study/${x.id}`} className={classNames("button button--short", {
                            'button--two-line': x.name.length >= 6,
                          })}>{ x.name }科目一覧</Link>
                        </div>
                      </div>
                    </li>
                  ))
                }
              </ul>
            </div>
          </section>

          {
            mypage && mypage.schedule &&
            <section className="box">
              <header className="box__head">
                <div className="box__title box__title--schedule">
                  スケジュール
                </div>
                <Link to='/schedules' className="box__head-link">スケジュール</Link>
              </header>
              <div className="box__body box__body--schedule">
                <div className="schedule">
                  <div className="schedule__head">
                    {
                      mypage.schedule.current &&
                      <div
                        onClick={() => setScheduleTab('current')}
                        className={classNames("schedule__tab", { 'is-active' : scheduleTab === 'current' })}>
                        今週<small className="schedule__date"><Moment format="M月D日">{ mypage.schedule.current.acted_on }</Moment>〜</small>
                      </div>
                    }
                    {
                      mypage.schedule.next &&
                      <div
                        onClick={() => setScheduleTab('next')}
                        className={classNames("schedule__tab", { 'is-active' : scheduleTab === 'next' })}>
                        来週<small className="schedule__date"><Moment format="M月D日">{ mypage.schedule.next.acted_on }</Moment>〜</small>
                      </div>
                    }

                    {
                      mypage.schedule.prev &&
                      <div
                        onClick={() => setScheduleTab('last')}
                        className={classNames("schedule__tab schedule__tab--past",
                          { 'is-active' : scheduleTab === 'last' })}>
                        先週
                      </div>
                    }
                  </div>
                  {
                    mypage.schedule.current &&
                    <div className={classNames("schedule__body", { 'is-active' : scheduleTab === 'current' })}>
                      <DebounceInput
                        minLength={0}
                        debounceTimeout={1000}
                        onChange={(e) => {
                          const id = mypage.schedule.current!.id
                          props.updateSchedule(id, e.target.value)
                        }}
                        element="textarea"
                        className="schedule__input"
                        value={
                          ( mypage && mypage.schedule.current.body ) ? mypage.schedule.current.body : ''
                        } />
                    </div>
                  }
                  {
                    mypage.schedule.next &&
                    <div className={classNames("schedule__body", { 'is-active' : scheduleTab === 'next' })}>
                      <DebounceInput
                        minLength={0}
                        debounceTimeout={1000}
                        onChange={(e) => {
                          const id = mypage.schedule.next!.id
                          props.updateSchedule(id, e.target.value)
                        }}
                        element="textarea"
                        className="schedule__input"
                        value={
                          ( mypage && mypage.schedule.next.body ) ? mypage.schedule.next.body : ''
                        } />
                    </div>
                  }
                  {
                    mypage.schedule.prev &&
                    <div className={classNames("schedule__body", { 'is-active' : scheduleTab === 'last' })}>
                      <textarea
                        className={classNames("schedule__input")}
                        disabled={true}
                        value={
                          ( mypage && mypage.schedule.prev.body ) ? mypage.schedule.prev.body : ''
                        }
                      ></textarea>
                    </div>
                  }
                </div>
              </div>
              <div className="box__more">
                <Link to='/schedules' className="box__more-link">スケジュール</Link>
              </div>
            </section>
          }

          {
            props.user.trainer_contracted && props.isAvailableStudentMessage &&
            <section className="box">
              <header className="box__head">
                <div className="box__title box__title--trainer">
                  トレーナー相談
                </div>
                <div className="box__head-button">
                  <Link to='/messages' className="button button--chat">チャットを開く</Link>
                </div>
              </header>
              <div className="box__body">
                <div className="question-box question-box--trainer">
                  <div className="question-box__inner">
                    <div className="question-box__title">
                      {
                        mypage && mypage.trainer_consultation &&
                        <Link to='/messages' className="question-box__link">新しいメッセージが届いています。こちらからチェックしてください。</Link>
                      }
                      {
                        mypage && !mypage.trainer_consultation &&
                        <span>新しいメッセージはありません。</span>
                      }

                    </div>
                  </div>
                </div>
                <div className="box__more">
                  <Link to='/messages' className="button button--chat">チャットを開く</Link>
                </div>
              </div>
            </section>
          }

          {
            props.availableQuestions &&
            <section className="box">
              <header className="box__head">
                <div className="box__title box__title--teacher">
                  先生からの回答
                </div>
                <Link to='/study_question_logs' className="box__head-link">質問の履歴を見る</Link>
              </header>
              <div className="box__body">
                {
                  props.question.records &&  props.question.records.map((record, index) => (
                    <div key={index} className={`question-box question-box--${record.statusCode}`}>
                      <div className="question-box__inner">
                        <div className="question-box__badge">
                          { record.statusText }
                        </div>
                        <div className="question-box__title">
                          <Link
                            to={`/study_question_logs/${record.id}`}
                            className="question-box__link">
                            { record.content.topic.title } > { record.content.meta!.label}
                          </Link>
                        </div>
                        <div className="question-box__spec">
                          {
                            record.statusCode === 'answered' && record.instructor &&
                            <span className="question-box__name">
                              { record.instructor.nick_name }
                            </span>
                          }
                          <span className="question-box__datetime">
                            <Moment format="M月D日 HH:mm">{ record.created_at }</Moment>
                          </span>
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>
              <div className="box__more">
                <Link to='/study_question_logs' className="box__more-link">質問の履歴を見る</Link>
              </div>
              <div className="box__foot">
                72時間以上経っても先生からの回答がない場合は、
                <a href="https://www.kawaijukuone.co.jp/contact/" className="setting-box__link" target="_blank" rel="noopener noreferrer">お問い合わせ</a>
                よりご連絡ください。<br />
                先生への質問は
                <Link to='/study_logs' className="box__link">学習履歴</Link>
                から行ってください。
              </div>
            </section>

          }

        </div>
      </SiteMain>
    </React.Fragment>
  );
}

export default StudentMypage
