import React, { useEffect } from 'react';
import { Link, useParams, } from 'react-router-dom';

import PrivateHeader from 'containers/parts/PrivateHeader';
import { mappedProps } from 'containers/parent/StudentStudyResultCourse';
import SiteMain from 'containers/parts/SiteMain';
import StudentBar from '../parts/CommonStudentBar'
import CircleChart from '../parts/CircleChart'

import { CourseCompletionTestBox } from 'components/common/course_completion_test/CourseCompletionTestBox';

const ParentStudentStudyResultCourse: React.FC<mappedProps> = (props: mappedProps) => {
  // MARK: 講師、トレーナーとインターフェース統一のためダミーIDを指定
  const id = 1;
  const { course_id } = useParams<{ course_id: string; }>();

  useEffect(() => {
    if (id && course_id) {
      props.fetchData(Number(id), Number(course_id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, course_id]);

  const student = props.getStudent(Number(id))
  const page = student && props.getRecord(Number(id), Number(course_id))
  const completionTestRecord = student && props.getCompletionTest(Number(id), Number(course_id))

  return (
    <React.Fragment>
      <PrivateHeader />
      <SiteMain>
        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          {
            page && 
            <React.Fragment>
              <li className="breadcrumbs__item">
                <Link to={`/student/study_result/${page.curriculum.id}`} className="breadcrumbs__link">
                  { page.curriculum.name }
                </Link>
              </li>
              <li className="breadcrumbs__item">
                { page.course.name }
              </li>
            </React.Fragment>
          }
        </ol>

        {
          student && page &&
          <div className="site-main__main-contents">
            <StudentBar student={student} />

            <div className="page-title">
              { page.curriculum.name }
              &nbsp;
              <div className="page-title__badge">
                { page.course.name }
              </div>
            </div>

            {
              completionTestRecord &&
              <CourseCompletionTestBox
                testRecord={completionTestRecord}
                resultPagePath={`/student/course_complete_results/${completionTestRecord.unit.id}`}
              />
            }

            <div className="subject-chart-list">
              <div className="subject-chart-list__title">
                単元
              </div>
              <div className="subject-chart-list__items">
                { page.units.map(unit => (
                  <div key={unit.id} className="subject-chart-list__item">
                    <div className="subject-chart-list__chart">
                      <CircleChart rate={unit.understanding / 100} colorType={page.curriculum.al_engine_connected ? 1 : 2} />
                    </div>
                    <div className="subject-chart-list__subject">
                      <Link
                        to={`/student/study_result/units/${unit.id}`}
                        className="subject-chart-list__subject-link">
                        { unit.name }
                      </Link>
                    </div>
                    <div className="subject-chart-list__buttons subject-chart-list__buttons--teacher">
                      {
                        unit.posttest && unit.pretest ?
                        <Link
                          to={`/student/study_result/unit_results/${unit.id}`}
                          style={{ pointerEvents: 'all' }}
                          className="button button--short button--primary subject-chart-list__button subject-chart-list__button--large">
                          結果比較
                        </Link>
                        :
                        <span
                          className="button button--short button--primary subject-chart-list__button subject-chart-list__button--large is-disabled">
                          結果比較
                        </span>
                      }
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="back-home">
              <Link to='/' className="back-home__link">マイページへ戻る</Link>
            </div>
          </div>
        }

        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          {
            page && 
            <React.Fragment>
              <li className="breadcrumbs__item">
                <Link to={`/student/study_result/${page.curriculum.id}`} className="breadcrumbs__link">
                  { page.curriculum.name }
                </Link>
              </li>
              <li className="breadcrumbs__item">
                { page.course.name }
              </li>
            </React.Fragment>
          }
        </ol>
      </SiteMain>
    </React.Fragment>
  );
}

export default ParentStudentStudyResultCourse