import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router'
import { reduxForm, InjectedFormProps, FormErrors, SubmissionError, reset } from 'redux-form'

import { AppState } from "store";
import targetComponent from 'components/trainer/StudentMessages';
import trainerActions from 'actions/trainerActions';
import * as apiService from 'services/api'
import uiActions from 'actions/uiActions'

type FormData = {
  body?: string;
  [key: string]: any
};

export const formKey = 'message_create'

export const FormValidate = (values: FormData) => {
  const errors: FormErrors<FormData> = {}
  const requiredFields: string[] = [
    'body',
  ]
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required'
    }
  })
  return errors
}

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch })

const mapStateToProps = (appState: AppState, ownProps: RouteComponentProps) => {
  return {
    api: appState.repository.authorizedApi!,
    user: appState.session.user!,
    trainer: appState.trainer,
    ...ownProps,
  }
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  { dispatch }: ReturnType<typeof mapDispatchToProps>) => {
  const showApiError = (e: Error) => {
    dispatch(uiActions.showApiErrorNotification(e))
  }
  return {
    ...stateProps,
    fetchData: (student_id: number) => {
      dispatch(trainerActions.fetchMessageRecords({ student_id }))
    },
    getStudent: (student_id: number) => {
      const student = stateProps.trainer.student
      if (student.student_id !== student_id) {
        return null
      }
      return student.record
    },
    getMessages: (student_id: number) => {
      const page = stateProps.trainer.message
      if (page.student_id !== student_id) {
        return null
      }
      return page.records
    },
    onSubmit: (values: FormData) => {
      // TODO: refactor sagaにうつす
      const body = values.body!

      const student_id = stateProps.trainer.message.student_id!
      return apiService.createTrainerMessage(
        stateProps.api, { student_id, body })
          .then(() => {
            dispatch(reset(formKey))
            dispatch(trainerActions.fetchMessageRecords({ student_id }))
          })
          .catch((e: Error) => {
            showApiError(e)
            throw new SubmissionError({})
          })
    }
  }
}

type connectMappedProps = ReturnType<typeof mergeProps>
export type mappedProps = connectMappedProps & InjectedFormProps<FormData, connectMappedProps>

const form = reduxForm<FormData, connectMappedProps>({
  form: formKey,
  validate: FormValidate
})(targetComponent)

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(form))