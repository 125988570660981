import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router'

import { AppState } from "store";
import SettingPointExchangeLogs from 'components/student/SettingPointExchangeLogs';
import studentActions from 'actions/studentActions';
import * as Models from 'models/api'

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch })
const mapStateToProps = (appState: AppState, routeProps: RouteComponentProps) => {
  const log = appState.student.pointExchangeLog
  const user = appState.session.user! as Models.StudentUser
  return {
    user,
    log,
    ...routeProps,
  }
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  { dispatch }: ReturnType<typeof mapDispatchToProps>) => {
  return {
    ...stateProps,
    fetchData: () => {
      dispatch(studentActions.fetchPointExchangeLogRecords({ initialize: true }))
    },
    fetchNext: () => {
      dispatch(studentActions.fetchPointExchangeLogRecords({ initialize: false }))
    }
  }
}

type connectMappedProps = ReturnType<typeof mergeProps>
export type mappedProps = connectMappedProps

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(SettingPointExchangeLogs))
