import actionCreatorFactory from 'typescript-fsa';
import * as Models from "models/api";

const actionCreator = actionCreatorFactory('parent');

const actions = {
  fetchMypage:
    actionCreator<void>(
      'FETCH_MYPAGE'),
  setMypage:
    actionCreator<{ record: Models.ParentMypage }>(
      'SET_MYPAGE'),
  updateProfile:
    actionCreator<{ 
      params: Models.UpdateParentProfileParams;
      promises?: { resolve: () => void, reject: (e: Error) => void}
    }>('UPDATE_PROFILE'),
  fetchStudent:
    actionCreator<void>(
      'FETCH_STUDENT'),
  setStudent:
    actionCreator<{ record: Models.StudentUser }>(
      'SET_STUDENT'),
  fetchPaymentLogRecords:
    actionCreator<void>(
      'FETCH_PAYMENT_LOG_RECORDS'),
  setPaymentLogRecords:
    actionCreator<{ records: Models.ParentPaymentLogs }>(
      'SET_PAYMENT_LOG_RECORDS'),
};

export default actions;