import React, { useEffect } from 'react';
import { Redirect, useHistory } from "react-router-dom";
import queryString from 'query-string'

import { mappedProps } from 'containers/signup/SignupStep2';
import SiteMainArea from '../parts/SiteMainArea';
import PublicHeader from '../parts/PublicHeader';
import SignupBoxSteps from './SignupBoxSteps';
import { Field } from 'redux-form';

const Step2: React.FC<mappedProps> = (props: mappedProps) => {
  const history = useHistory()

  useEffect(() => {
    const scFunc = (window as any).sc_func
    if (scFunc) { scFunc('kjone', 'step2', '') }
  }, [])

  useEffect(() => {
    if (history.location.search) {
      const queryParams = queryString.parse(history.location.search);
      if (queryParams.external_alliance) {
        props.change('external_alliance', queryParams.external_alliance)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.search])

  const initPage = `${props.initPage}${history.location.search}`
  const nextPage = `${props.nextPage}${history.location.search}`

  if (!props.isCompleteBeforeStep) {
    return <Redirect to={initPage} />;
  }

  if (props.submitSucceeded) {
    return <Redirect to={nextPage} />;
  }

  const queryParams = queryString.parse(history.location.search);
  const fromMoshinavi = queryParams.external_alliance === 'moshinavi'

  return (
    <React.Fragment>
      <PublicHeader />
      <SiteMainArea>
        <div className="register-box">

          <div className="register-box__title">
            河合塾One 新規お申し込み
          </div>

          <div className="register-box__lead">
            <div>
            {
              fromMoshinavi ? 
              'お申込み日から１４日間、無料でご利用いただけます。':
              'お申込み日から７日間、無料でご利用いただけます。'
            }
            </div>
            <div className="register-box__lead-annotation">
              <div>
                例えば、5月14日17:15にお申込みされた場合は5月20日23:59まで無料でご利用いただけます。<br />
                ※模試ナビ経由の申し込みは、上記から１週間延長されます。
              </div>
            </div>
          </div>

          <SignupBoxSteps step={3} />

          <div className="register-box__subtitle">
            <div>
              ご契約プラン
            </div>
            <div className="register-box__subtitle-annotation">
              ※無料体験期間終了後、自動的に下記プランが適用されます。無料体験期間中に退会される場合は、マイページの「設定変更」→「退会」へお進みください。
            </div>
          </div>

          <form onSubmit={props.handleSubmit}>
            <Field component='input' type="hidden" name="external_alliance" />
            
            <div className="register-box__form">
              <div className="form form--confirm">
                <div className="form__item">
                  <label className="form__label">プラン選択</label>
                  <div className="form__field">
                    <span>
                    {props.selectedPlan!.name}
                    </span>
                    <span className="form__field-annotation">
                      ※ ご選択プランの期間ごとの⾃動更新となります。
                    </span>
                  </div>
                </div>
                <div className="form__item">
                  <label className="form__label">初回ご請求額</label>
                  <div className="form__field">
                    {
                      props.step1Values.trainer_contracted ?
                      <>
                        { props.selectedPlan!.selected_price }円（税込 { props.selectedPlan!.selected_price_with_tax }円）
                      </>:
                      <>
                        { props.selectedPlan!.lump_sum_price}円（税込 { props.selectedPlan!.lump_sum_price_with_tax }円）
                      </>
                    }
                    <div className="form__text">
                      基本プラン：{props.selectedPlan!.selected_desc}<br />
                      {
                        props.step1Values.trainer_contracted &&
                        <span>トレーナーサービス：1,000円（税込 1,100円）【1ヶ月分】</span>
                      }
                    </div>
                  </div>
                </div>
                <div className="form__annotation">
                  ※ 特定商取引法に基づく表記は、<a href="https://www.kawaijukuone.co.jp/law/" target="_blank" rel="noopener noreferrer">こちら</a>をご確認ください。
                </div>
                <div className="form__button">
                  <button
                    className="button button--large button--darkgray button--no-chevron"
                    onClick={(e) => {
                      e.preventDefault();
                      props.history.push(initPage + '#plan-area');
                    }}>
                    プランを変更する
                  </button>
                </div>
              </div>
            </div>

            {
              props.step1Values.trainer_contracted && 
              <React.Fragment>
                <div className="register-box__subtitle">
                  トレーナー希望情報
                </div>
                <div className="register-box__form">
                  <div className="form form--confirm">
                    <div className="form__item">
                      <label className="form__label">文理系統</label>
                      <div className="form__field">
                        {props.step1Values.desired_faculty_kind}
                      </div>
                    </div>
                    <div className="form__item">
                      <label className="form__label">学部系統</label>
                      <div className="form__field">
                        {props.step1Values.desired_faculty}
                      </div>
                    </div>
                    <div className="form__item">
                      <label className="form__label">在籍または<br className="_pc" />合格大学</label>
                      <div className="form__field">
                        {props.step1Values.desired_university}
                      </div>
                    </div>
                    <div className="form__item">
                      <label className="form__label">部活動</label>
                      <div className="form__field">
                        {props.step1Values.club_kind}
                        <br />
                        {props.step1Values.club_name}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="register-box__subtitle">
                  トレーナーに求める条件
                </div>
                <div className="register-box__form">
                  <div className="form form--confirm">
                    <div className="form__item">
                      <label className="form__label">第1希望</label>
                      <div className="form__field">
                        {props.step1Values.first_request}
                      </div>
                    </div>
                    <div className="form__item">
                      <label className="form__label">第2希望</label>
                      <div className="form__field">
                        {props.step1Values.second_request}
                      </div>
                    </div>
                    <div className="form__item">
                      <label className="form__label">第3希望</label>
                      <div className="form__field">
                        {props.step1Values.third_request}
                      </div>
                    </div>
                  </div>
                </div>

              </React.Fragment>
            }

            <div className="register-box__subtitle">
              申し込み情報（受講者・保護者）
            </div>
            <div className="register-box__form">
              <div className="form form--confirm">
                <div className="form__item">
                  <label className="form__label">受講者氏名</label>
                  <div className="form__field">
                    {props.step1Values.student_last_name} {props.step1Values.student_first_name}　
                  </div>
                </div>
                <div className="form__item">
                  <label className="form__label">受講者氏名(カナ)</label>
                  <div className="form__field">
                    {props.step1Values.student_last_name_read} {props.step1Values.student_first_name_read}　
                  </div>
                </div>
                <div className="form__item">
                  <label className="form__label">保護者氏名</label>
                  <div className="form__field">
                    {props.step1Values.last_name} {props.step1Values.first_name}　
                  </div>
                </div>
                <div className="form__item">
                  <label className="form__label">保護者氏名(カナ)</label>
                  <div className="form__field">
                    {props.step1Values.last_name_read} {props.step1Values.first_name_read}　
                  </div>
                </div>
                <div className="form__item">
                  <label className="form__label">受講者メールアドレス</label>
                  <div className="form__field">
                    {props.step1Values.student_email}
                  </div>
                </div>
                <div className="form__item">
                  <label className="form__label">保護者メールアドレス</label>
                  <div className="form__field">
                    {props.step1Values.email}
                  </div>
                </div>
                <div className="form__item">
                  <label className="form__label">住所</label>
                  <div className="form__field">
                    〒{props.step1Values.postal_code}<br />
                    {props.step1Values.prefecture}<br />
                    {props.step1Values.municipality}<br />
                    {props.step1Values.address}<br />
                  </div>
                </div>
              </div>
            </div>
            <div className="register-box__subtitle">
              受講者基本情報
            </div>
            <div className="register-box__form">
              <div className="form form--confirm">
                <div className="form__item">
                  <label className="form__label">生年月日</label>
                  <div className="form__field">
                    {props.step1Values.birthday_year} 年
                    {props.step1Values.birthday_month} 月
                    {props.step1Values.birthday_day} 日
                  </div>
                </div>
                <div className="form__item">
                  <label className="form__label">性別</label>
                  <div className="form__field">
                    {props.step1Values.gender}
                  </div>
                </div>
                <div className="form__item">
                  <label className="form__label">在籍（卒業）高校</label>
                  <div className="form__field">
                    {props.step1Values.high_school_name}
                  </div>
                </div>
                <div className="form__item">
                  <label className="form__label">学年</label>
                  <div className="form__field">
                    {props.step1Values.school_grade}
                  </div>
                </div>
                <div className="form__item">
                  <label className="form__label">ニックネーム</label>
                  <div className="form__field">
                    {props.step1Values.nick_name}
                  </div>
                </div>
              </div>
            </div>

            <div className="form">
              {/*
                FIXME: 急ぎ対応したためstyleタグでスタイリングしています...
               */
              }
              {
                props.error &&
                <React.Fragment>
                  <div style={{ textAlign: "center", marginTop: '30px', marginBottom: '30px' }}>
                    <div className="error-message">
                      { props.error.split('\n').map((x, index) => (
                        <div key={index} style={{ padding: '5px' }}>
                          {
                            // バックエンド側エラーメッセージ変更が難しいことへの対応
                            x === '受講生のメールアドレスはすでに存在します' ?
                              <span>
                                受講者のメールアドレスがすでに存在します。<br />
                                過去に河合塾Oneにご登録いただいたことのある方は、
                                <a href="https://www.kawaijukuone.co.jp/contact/" target="_blank" rel="noopener noreferrer">ヘルプセンター</a>
                                までお問い合わせください。
                              </span>
                              : x
                          }
                        </div>
                      )) }
                    </div>
                  </div>
                </React.Fragment>
              }

              <div className="form__button">
                <button
                  className="button button--large button--darkgray button--no-chevron"
                  onClick={(e) => {
                    e.preventDefault();
                    props.history.push(initPage + '#register-area');
                  }}>
                  入力情報を変更する
                </button>
              </div>
              <div className="form__button">
                <button
                  type="submit"
                  className="button button--primary button--xlarge">
                  申し込みを完了する</button>
              </div>
            </div>

          </form>

        </div>
      </SiteMainArea>
    </React.Fragment>
  );
}

export default Step2
