import { reducerWithInitialState } from 'typescript-fsa-reducers';
import produce from 'immer'
import axios, { AxiosInstance } from 'axios'
import Cookies from 'universal-cookie';

import { authHeaderKey } from 'services/api'

import repositoryActions from 'actions/repositoryActions';

let mock: any = null
if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_MOCK) {
  mock = require('mock/api').default
}

export interface RepositoryState {
  cookies: Cookies;
  apiBaseUrl: string | null;
  api: AxiosInstance | null;
  authorizedApi: AxiosInstance | null;
}

const initialState: RepositoryState = {
  cookies: new Cookies(),
  apiBaseUrl: null,
  api: null,
  authorizedApi: null
};

export const repositoryReducer = reducerWithInitialState(initialState)
  .case(repositoryActions.setApiBaseUrl, (state, url) => {
    return produce(state, draftState => {
      draftState.apiBaseUrl = url;
    })
  })
  .case(repositoryActions.initApi, (state, token) => {
    return produce(state, draftState => {
      const client = axios.create({
        baseURL: state.apiBaseUrl!,
      })
      if (mock) {
        mock.run(client)
      }
      draftState.api = client

    })
  })
  .case(repositoryActions.initAuthorizedApi, (state, token) => {
    return produce(state, draftState => {
      const client = axios.create({
        baseURL: state.apiBaseUrl!,
        headers: {
          [authHeaderKey]: token,
        }
      })
      if (mock) {
        mock.run(client)
      }
      draftState.authorizedApi = client
    })
  })
