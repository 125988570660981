import React from 'react';
import { Redirect, Link, useHistory, useParams, } from 'react-router-dom';

import { mappedProps } from 'containers/student/StudyLogPointExchangeConfirm';
import PrivateHeader from 'containers/parts/PrivateHeader';
import SiteMain from 'containers/parts/SiteMain';

const commaFormat = (value: number) => {
  return value.toLocaleString()
}

const StudyLogPointExchangeConfirm: React.FC<mappedProps> = (props: mappedProps) => {
  const history = useHistory();
  const { curriculum, id } = useParams();

  let prevPathname = `/study_logs/${curriculum}/${id}/buy_ticket`

  if (!props.pointExchangeState.confirm && !props.pointExchangeState.execute  && !props.pointExchangeState.error) {
    return <Redirect to={prevPathname} />;
  }

  if (props.pointExchangeState.error) {
    // MARK: エラー表示
    // https://github.com/gatjp/kawai1-back/blob/feature/point-exchange-program-api/docs/api/v2/student/point_exchange_create.md
    return (
      <React.Fragment>
        <PrivateHeader />
        <SiteMain>
          <ol className="breadcrumbs">
            <li className="breadcrumbs__item">
              <Link to='/' className="breadcrumbs__link">マイページ</Link>
            </li>
            <li className="breadcrumbs__item">
              <Link to={`/study_logs/${curriculum}`} className="breadcrumbs__link">学習履歴</Link>
            </li>
            <li className="breadcrumbs__item">
              <Link to={`/study_logs/${curriculum}/${id}`} className="breadcrumbs__link">先生への質問</Link>
            </li>
            <li className="breadcrumbs__item">
              ポイント交換
            </li>
          </ol>

          <div className="site-main__main-contents">
            <div className="page-title">
              ポイント交換
              <Link to={`/study_logs/${curriculum}`} className="page-title__link page-title__link--back">学習履歴に戻る</Link>
            </div>

            <div className="question-form">
              <div className="point-exchange-confirmation">
                <div className="point-exchange-confirmation__head point-exchange-confirmation__head--question">
                  質問チケット交換
                </div>
                <div className="point-exchange-confirmation__error">
                  ポイントの交換ができませんでした。
                </div>
                <div className="point-exchange-confirmation__text">
                  「ポイント交換」に戻って、現在のポイントをご確認ください。
                </div>
              </div>
              <div className="form">
                <div className="form__buttons">
                  <div className="form__button form__button--flex-item">
                    <button 
                      onClick={e => {
                        e.preventDefault()
                        props.history.push(`/setting/point_exchange`)
                      }}
                      className="button button--gray button--back button--xlarge">ポイント交換へ戻る</button>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <ol className="breadcrumbs">
            <li className="breadcrumbs__item">
              <Link to='/' className="breadcrumbs__link">マイページ</Link>
            </li>
            <li className="breadcrumbs__item">
              <Link to={`/study_logs/${curriculum}`} className="breadcrumbs__link">学習履歴</Link>
            </li>
            <li className="breadcrumbs__item">
              <Link to={`/study_logs/${curriculum}/${id}`} className="breadcrumbs__link">先生への質問</Link>
            </li>
            <li className="breadcrumbs__item">
              ポイント交換
            </li>
          </ol>
        </SiteMain>
      </React.Fragment>
    )
  }

  if (props.pointExchangeState.execute) {
    // MARK: 結果表示
    return (
      <React.Fragment>
        <PrivateHeader />
        <SiteMain>
          <ol className="breadcrumbs">
            <li className="breadcrumbs__item">
              <Link to='/' className="breadcrumbs__link">マイページ</Link>
            </li>
            <li className="breadcrumbs__item">
              <Link to={`/study_logs/${curriculum}`} className="breadcrumbs__link">学習履歴</Link>
            </li>
            <li className="breadcrumbs__item">
              <Link to={`/study_logs/${curriculum}/${id}`} className="breadcrumbs__link">先生への質問</Link>
            </li>
            <li className="breadcrumbs__item">
              ポイント交換
            </li>
          </ol>

          <div className="site-main__main-contents">
            <div className="page-title">
              ポイント交換
              <Link to={`/study_logs/${curriculum}`} className="page-title__link page-title__link--back">学習履歴に戻る</Link>
            </div>

            <div className="question-form">
              <div className="point-exchange-confirmation">
                <div className="point-exchange-confirmation__head point-exchange-confirmation__head--question">
                  質問チケット交換
                </div>
                <div className="point-exchange-confirmation__complete">
                  ポイントの交換が完了しました。
                </div>
                <div className="point-exchange-confirmation__text">
                  「先生に質問」画面に戻って質問をつづけてください。
                </div>
              </div>
              <div className="form">
                <div className="form__buttons">
                  <div className="form__button form__button--flex-item">
                    <button 
                      onClick={e => {
                        e.preventDefault()
                        props.history.push(`/study_logs/${curriculum}/${id}`)
                      }}
                      className="button button--gray button--back button--xlarge">先生への質問へ戻る</button>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <ol className="breadcrumbs">
            <li className="breadcrumbs__item">
              <Link to='/' className="breadcrumbs__link">マイページ</Link>
            </li>
            <li className="breadcrumbs__item">
              <Link to={`/study_logs/${curriculum}`} className="breadcrumbs__link">学習履歴</Link>
            </li>
            <li className="breadcrumbs__item">
              <Link to={`/study_logs/${curriculum}/${id}`} className="breadcrumbs__link">先生への質問</Link>
            </li>
            <li className="breadcrumbs__item">
              ポイント交換
            </li>
          </ol>
        </SiteMain>
      </React.Fragment>
    )
  }

  // 確認画面表示
  const confirmState = props.pointExchangeState.confirm!

  return (
    <React.Fragment>
      <PrivateHeader />
      <SiteMain>
        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          <li className="breadcrumbs__item">
            <Link to={`/study_logs/${curriculum}`} className="breadcrumbs__link">学習履歴</Link>
          </li>
          <li className="breadcrumbs__item">
            <Link to={`/study_logs/${curriculum}/${id}`} className="breadcrumbs__link">先生への質問</Link>
          </li>
          <li className="breadcrumbs__item">
            ポイント交換
          </li>
        </ol>

        <div className="site-main__main-contents">
          <div className="page-title">
            ポイント交換
            <Link to={`/study_logs/${curriculum}`} className="page-title__link page-title__link--back">学習履歴に戻る</Link>
          </div>

          <div className="question-form">
            <div className="point-exchange-confirmation">
              <div className="point-exchange-confirmation__head point-exchange-confirmation__head--question">
                質問チケット交換
              </div>
              <div className="point-exchange-confirmation__text">
                500ptを質問チケット１枚と交換しますが、よろしいですか？
              </div>
              <div className="point-exchange-confirmation__point">
                ご利用後のポイント数：<b>{ commaFormat(props.user.current_points - confirmState.point_exchange.number_ordered * props.pointRate) }pt</b>
                <br className="_sp" />（現在のポイント数 : { commaFormat(props.user.current_points) } pt）
              </div>
              <div className="point-exchange-confirmation__note">
                <b>ご利用いただいたポイントの返還・交換内容の変更はできませんので、予めご了承ください。</b>
              </div>
            </div>
            <div className="form">
              <div className="form__buttons">
                <div className="form__button form__button--flex-item">
                  <form onSubmit={props.handleSubmit}>
                    <button
                      disabled={props.submitting}
                      className="button button--primary button--xlarge">ポイントを交換する</button>
                  </form>
                </div>
                <div className="form__button form__button--flex-item">
                  <button
                    onClick={e => {
                      e.preventDefault()
                      props.history.push(prevPathname)
                    }}
                    className="button button--gray button--back button--xlarge">前のページへ戻る</button>
                </div>
              </div>
            </div>
          </div>

          <div className="back-home">
            <a
              onClick={e => {
                e.preventDefault()
                history.push('/')
              }}
              href="#!" className="back-home__link">マイページへ戻る</a>
          </div>

        </div>

        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          <li className="breadcrumbs__item">
            <Link to={`/study_logs/${curriculum}`} className="breadcrumbs__link">学習履歴</Link>
          </li>
          <li className="breadcrumbs__item">
            <Link to={`/study_logs/${curriculum}/${id}`} className="breadcrumbs__link">先生への質問</Link>
          </li>
          <li className="breadcrumbs__item">
            ポイント交換
          </li>
        </ol>

      </SiteMain>
    </React.Fragment>
  );
}

export default StudyLogPointExchangeConfirm;