const EmailPrefixList = [
  'gmail.com',
  'yahoo.co.jp',
  'hotmail.co.jp',
  'hotmail.com',
  'outlook.jp',
  'icloud.com',
  'me.com'
]

export const makeEmailPredictions = (email: string = ''): string[] => {
  let predictions: string[] = []
  if (1 === (email.match(/@/g) || []).length && 1 <= email.indexOf('@')) {
    const atIndex = email.indexOf('@')
    const input_prefix = email.slice(atIndex+1)
    for(let prefix of EmailPrefixList) {
      if (input_prefix === prefix.slice(0, input_prefix.length) && prefix !== input_prefix) {
        predictions.push(email.slice(0, atIndex+1)+prefix)
      }
    }
    return predictions
  } else {
    return []
  }
}