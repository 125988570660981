import React, { useState } from 'react';
import classNames from 'classnames';

type props = {
}

export const CommonTestHintBalloon: React.FC<props> = (props: props) => {
  const [showHint, setShowHint] = useState(false);

  return <>
    <div className="test-box__head-note" onClick={() => setShowHint(!showHint)}>
      共通テスト予想点数について
    </div>
    <div className={classNames("balloon test-box__balloon", {
      'is-active': showHint,
    })}>
      <div 
        onClick={() => setShowHint(false)}
        className="balloon__close js-close-balloon">
        説明を閉じる
      </div>
      <div className="balloon__title">
        共通テスト予想点数について
      </div>
      <div className="balloon__lead">
        「共通テスト予想点数」は河合塾Oneで用いている指標です。「科目修了テスト」の結果を河合塾Oneが難易を考慮して直近の大学入学共通テストの得点に換算したものです。いまの学力がどのような位置に相当しているかを示すもので、大学入学共通テストを受験した際の得点を予想するものではありません。<br />不安な部分は河合塾Oneで復習し、解消しておきましょう。
      </div>
      <div className="balloon__lead">
        「数学III」の予想点数は大学入学共通テストで未実施科目のためありません。
      </div>
    </div>
  </>;
}
