import React, { useEffect, useState} from 'react';
import { Link, useParams, Redirect } from 'react-router-dom';
import Moment from 'react-moment';

import PrivateHeader from 'containers/parts/PrivateHeader';
import { mappedProps } from 'containers/student/StudyLogs';
import SiteMain from 'containers/parts/SiteMain';

const StudentStudyLogs: React.FC<mappedProps> = (props: mappedProps) => {
  const { curriculum } = useParams<{ curriculum: string; }>();

  const curriculumObj = props.getCurriculum(curriculum)
  const [showDropDown, setShowDropDown] = useState<boolean>(false)

  useEffect(() => {
    if (curriculumObj) {
      props.fetchData(curriculumObj.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curriculum]);

  const downloadPdf = (curriculumCode: string) => {
    const curriculumCodesArray = [curriculumCode]
    props.pdfDownload(curriculumCodesArray)
  }

  if (!curriculumObj) {
    return (
      <Redirect to="/" />
    )
  }

  const studyLogs = props.getStudyLogRecords(curriculumObj.id)
  const hasNext = props.getStudyLogHasNext(curriculumObj.id)

  return (
    <React.Fragment>
      <PrivateHeader />
      <SiteMain>
        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          <li className="breadcrumbs__item">
            学習履歴
          </li>
        </ol>

        <div className="site-main__main-contents">
          <div className="page-title">
            学習履歴
          </div>

          <div className="learning-history-box">
            <div className="learning-history-box__head">
              {showDropDown && <div onClick={() => setShowDropDown(false)} className="bookmark__back"></div>}
              <div className="bookmark__select-section">
                <div className="bookmark__relative">
                  <div onClick={() => { setShowDropDown(!showDropDown) }} className="bookmark__dropdown-button">{curriculumObj.name}
                    <div className="bookmark__dropdown-button-arrow"></div>
                  </div>
                  {
                    props.curriculums && showDropDown &&
                    <div className="bookmark__dropdown-container">
                      <ul>
                        {props.curriculums.map(x => (
                          <li
                            key={x.code}
                            onClick={() => {
                              setShowDropDown(false)
                              props.history.push(`/study_logs/${x.code}`)

                            }}
                            className="bookmark__dropdown-option"
                          > {x.name} </li>
                        ))}
                      </ul>
                    </div>
                  }
                </div>
              </div>
            </div>
            <div className="learning-history-box__body">
              <div className="learning-history-box__title">
                { curriculumObj.name }の学習履歴
              </div>

              {
                studyLogs && studyLogs.map((record, index) => (<React.Fragment key={index}>
                  <div key={index} className={`
                  ${index > 0 && 'learning-history-box__item-border'}
                    learning-history-box__item
                    `}>
                    <div className="learning-history-box__text">
                      <div className="learning-history-box__date">
                        <Moment format="YYYY年M月D日 HH:mm">{ record.created_at }</Moment>
                        〜
                        <Moment format="YYYY年M月D日 HH:mm">{ record.updated_at }</Moment>
                      </div>
                      <span className="learning-history-box__topic">
                        { record.content.topic.title } &gt; { record.content.meta!.label}
                        {
                          // MARK: 練習問題、レベルチェックテストのみ正答数を表示する
                          ( record.content.meta!.type === '練習問題' || record.content.meta!.type === 'レベルチェックテスト' ) &&
                          record.questions &&
                          <>
                            　
                            <span style={{ fontWeight: 'bold' }}>{ record.correct_answers }/{ record.questions }</span>（正解数/問題数）
                          </>
                        }
                      </span>
                    </div>
                    {
                      props.availableQuestions &&
                      <div className="learning-history-box__button">
                        <Link to={`/study_logs/${curriculumObj.code}/${record.id}`} className="button">質問する</Link>
                      </div>
                    }
                  </div>
                  {record.content.meta && 
                  record.content.launch_parameters.pdf_ready &&
                  <div className="learning-history-box__pdf-download-button-container">
                    <button
                      disabled={!record.content.launch_parameters.pdf_ready}
                      onClick={()=> downloadPdf(record.content.launch_parameters.curriculum_code)}
                      className="pdf-download-button">
                      PDF
                      <div className="pdf-download-button__icon" />
                    </button>
                  </div>
                  }
                </React.Fragment>))
              }

              {
                studyLogs && studyLogs.length <= 0 &&
                <div style={{ padding: '20px' }}>
                  学習履歴はありません。
                </div>
              }

            </div>
          </div>

          {
            hasNext &&
            <div className="more-button">
              <div
                onClick={() => { props.fetchNext() }}
                className="more-button__text">
                次のトピックを表示
              </div>
            </div>
          }

          <div className="back-home">
            <Link to='/' className="back-home__link">マイページへ戻る</Link>
          </div>
        </div>

        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          <li className="breadcrumbs__item">
            学習履歴
          </li>
        </ol>
      </SiteMain>

    </React.Fragment>
  );
}

export default StudentStudyLogs
