import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router'

import { AppState } from "store";
import targetComponent from 'components/student/RecommendStudies';
import studentActions from 'actions/studentActions';
import * as Models from 'models/api';
import { getRecommendedCurriculums }  from 'utils/store'

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch })

const mapStateToProps = (appState: AppState, ownProps: RouteComponentProps) => {
  const user = appState.session.user! as Models.StudentUser
  const curriculums = getRecommendedCurriculums(user).filter(x => x.al_engine_connected)

  return {
    user,
    curriculums,
    student: appState.student,
    ...ownProps,
  }
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  { dispatch } : ReturnType<typeof mapDispatchToProps>) => {
  return {
    ...stateProps,
    getCurriculum: (code: string | undefined) => {
      return stateProps.curriculums.find(x => x.code === code || x.id === Number(code))
    },
    fetchData: (curriculum_id: number) => {
      return new Promise<string>((resolve, reject) => {
        dispatch(studentActions.fetchRecommendStudyRecords({
          curriculum_id,
          promises: { resolve, reject }
        }))
      })
    },
    getRecords: (curriculum_id: number) => {
      const page = stateProps.student.recommendStudy
      if (page.curriculum_id !== curriculum_id) {
        return null
      }
      return page.records
    },
    startStudy: (params: { content_id: number, return_pathname: string; rank: number; }) => {
      return new Promise<string>((resolve, reject) => {
        dispatch(studentActions.startStudy({
          params,
          promises: { resolve, reject }
        }))
      })
    },
    
    getLearningCount: (params: {curriculumCodes: Models.StudentStudyLearningCountParams}) =>  {
      return new Promise<Models.StudentStudyLearningCountRecords>((resolve, reject)=> { 
       dispatch(studentActions.getLearningCount({
            params,
            promises: { resolve, reject }
          }))
      }) 
    },
    pdfDownload: (curriculum_codes: string[]) => {
      const curriculumCodesString = curriculum_codes.join("_")
      return new Promise<{url: string}>((resolve, reject) => {
        dispatch(studentActions.fetchPdfDownload({
          pdf_curriculums: curriculumCodesString,
          promises: { resolve, reject }
        }))
      })},
    fetchStudyAchievementsLatest: () => {
      return new Promise<Models.StudyAchievementsLatest>((resolve, reject) => {
        dispatch(studentActions.fetchStudyAchievementsLatest(
          { promises: { resolve, reject } }
        ))
      })
    }
  }
}

type connectMappedProps = ReturnType<typeof mergeProps>
export type mappedProps = connectMappedProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(targetComponent))