import { call, put, select, takeEvery } from 'redux-saga/effects'

import * as apiService from 'services/api'
import uiActions from 'actions/uiActions'
import { AppState } from 'store';
import actions from 'actions/parentActions'
import { updateUser } from './session';

export function* updateProfile(action: ReturnType<typeof actions.updateProfile>) {
  const state: AppState = yield select()
  try {
    yield call(
      apiService.updateParentProfile,
        state.repository.authorizedApi!,
        action.payload.params);

    yield updateUser()

    action.payload.promises && action.payload.promises.resolve()
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
    action.payload.promises && action.payload.promises.reject(e)
  }
}

export function* fetchMypage(action: ReturnType<typeof actions.fetchMypage>) {
  try {
    const response: apiService.getParentMypageResponse = yield call(
      apiService.getParentMypage,
      yield select(x => x.repository.authorizedApi!));
    const record = response.data
    yield put(actions.setMypage({ record }));
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

export function* fetchStudent(action: ReturnType<typeof actions.fetchStudent>) {
  try {
    const response: apiService.getParentStudentResponse = yield call(
      apiService.getParentStudent,
      yield select(x => x.repository.authorizedApi!));
    const record = response.data
    yield put(actions.setStudent({ record }));
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

export function* fetchPaymentLogRecords(action: ReturnType<typeof actions.fetchPaymentLogRecords>) {
  try {
    const response: apiService.getParentPaymentLogsResponse = yield call(
      apiService.getParentPaymentLogs,
      yield select(x => x.repository.authorizedApi!));
    const records = response.data
    yield put(actions.setPaymentLogRecords({ records }));
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

function* saga() {
  yield takeEvery(actions.fetchMypage, fetchMypage);
  yield takeEvery(actions.fetchStudent, fetchStudent);
  yield takeEvery(actions.fetchPaymentLogRecords, fetchPaymentLogRecords);
  yield takeEvery(actions.updateProfile, updateProfile);
}

export default saga;
