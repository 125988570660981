import React, { useState } from 'react';

import { Redirect, useHistory } from 'react-router-dom';
import { mappedProps } from 'containers/student/SettingTrainerEdit';
import PrivateHeader from 'containers/parts/PrivateHeader';
import * as Models from 'models/api'

import SiteSettingMainArea from '../common/SiteSettingMainArea';

import SettingTrainerEdit2 from 'containers/student/SettingTrainerEdit2';

const SettingTrainerEdit: React.FC<mappedProps> = (props: mappedProps) => {
  const history = useHistory()
  const [confirm, setConfirm] = useState(false)

  // MARK: トレーナーの契約は即時実行されるため、
  // submit前にキャッシュしたユーザ情報でトレーナー契約判定しないとsubmit後に停止画面に遷移してしまう
  // ページ分けるか迷ったけどURL切り替えも面倒なのでとりあえずこれで
  const [userCache, setUserCache] = useState<Models.StudentUser | null>(null)
  if (!userCache) {
    setUserCache(props.user)
  }

  // MARK: コーポレートユーザ利用不可
  if (props.user.corporation) {
    return <Redirect to='/' />;
  }

  const trainerContracted = userCache && userCache.trainer_contracted && userCache.trainer

  if (!trainerContracted) {
    // MARK: 未契約の場合
    return (
      <SettingTrainerEdit2 />
    )
  }

  const trainer = userCache!.trainer!

  return (
    <React.Fragment>
      <PrivateHeader />
      <SiteSettingMainArea page="trainer_edit" user={userCache!}>

        {
          props.submitSucceeded &&
          <div className="setting-box">
            <div className="setting-box__title setting-box__title--trainer">
              トレーナー申込・変更
            </div>
            <div className="setting-message">
              トレーナーサービスの停止が完了しました。
            </div>
            <div className="setting-box__button">
              <button
                onClick={() => { history.push('/') }}
                className="button button--gray button--back button--xlarge">マイページに戻る</button>
            </div>
          </div>
        }

        {
          !props.submitSucceeded && confirm && 
          <div className="setting-box">
            <div className="setting-box__title setting-box__title--trainer">
              トレーナー申込・変更
            </div>
            <div className="setting-box__subtitle">
              トレーナーサービスの利用停止
            </div>
            <div className="setting-box__lead">
              下記注意事項をよくお読みの上「トレ−ナ−サービスを停止」より停止をおこなってください。
            </div>
            <div className="setting-box__cautions">
              <dl className="setting-caution-list">
                <dt className="setting-caution-list__title">
                  注意事項
                </dt>
                <dd className="setting-caution-list__item">
                  トレーナーサービス停止の申請を完了すると、すぐにトレーナーサービスのご利用ができなくなります。
                </dd>
                <dd className="setting-caution-list__item">
                  サービス停止後、再びトレーナーサービスにお申込みいただいた場合、同じトレーナーが担任になるとは限りません。
                </dd>
                <dd className="setting-caution-list__item">
                  すでにお支払いいただいている、当月分のトレーナーサービス料金の返金は行いません。
                </dd>
                <dd className="setting-caution-list__item">
                  トレーナーサービスの停止には、保護者の同意が必要です。
                </dd>
              </dl>
            </div>
            <div className="form">
              <div className="form__buttons">
                <div className="form__button form__button--flex-item">
                  <form onSubmit={props.handleSubmit}>
                    <button className="button button--primary button--xlarge">トレーナーサービスを停止</button>
                  </form>
                </div>
                <div className="form__button form__button--flex-item">
                  <button
                    onClick={() => { history.push('/') }}
                    className="button button--gray button--back button--xlarge">マイページに戻る</button>
                </div>
              </div>
            </div>
          </div>
        }

        {
          !props.submitSucceeded && !confirm && 

          <div className="setting-box">
            <div className="setting-box__title setting-box__title--trainer">
              トレーナー申込・変更
            </div>

            <div className="setting-box__subtitle">
              トレーナーサービス
            </div>
            <div className="setting-box__body">
              <div className="trainer-service-badge trainer-service-badge--enabled">
                トレーナーサポート中
              </div>
            </div>

            <div className="setting-box__subtitle">
              トレーナー情報
            </div>
            <div className="setting-box__form">
              <div className="form form--confirm">
                <div className="form__item">
                  <div className="form__label">
                    ニックネーム
                  </div>
                  <div className="form__field">
                    { trainer.nick_name }
                  </div>
                </div>
                <div className="form__item">
                  <div className="form__label">
                    在籍大学
                  </div>
                  <div className="form__field">
                    { trainer.university_name }
                  </div>
                </div>
                <div className="form__item">
                  <div className="form__label">
                    在籍学部系統
                  </div>
                  <div className="form__field">
                    { trainer.department_type }
                  </div>
                </div>
                <div className="form__item">
                  <div className="form__label">
                    合格大学
                  </div>
                  <div className="form__field">
                    { trainer.passed_university }
                  </div>
                </div>
                <div className="form__item">
                  <div className="form__label">
                    出身県
                  </div>
                  <div className="form__field">
                    { trainer.prefecture_name }
                  </div>
                </div>
                <div className="form__item">
                  <div className="form__label">
                    出身高校系統
                  </div>
                  <div className="form__field">
                    { trainer.graduated_high_school_type }
                  </div>
                </div>
                <div className="form__item">
                  <div className="form__label">
                    高校時代の部活
                  </div>
                  <div className="form__field">
                    { trainer.club_activity_name_in_high_school }
                  </div>
                </div>
              </div>
            </div>

            <div className="setting-box__subtitle">
              トレーナーサービスの利用停止
            </div>
            <div className="setting-box__lead">
              サービスの利用を停止する場合は、下記の「トレーナーサービスを停止」より行ってください。
            </div>
            <div className="form">
              <div className="form__buttons">
                <div className="form__button form__button--flex-item">
                  <button
                    onClick={() => { setConfirm(true) }}
                    className="button button--primary button--xlarge">トレーナーサービスを停止</button>
                </div>
                <div className="form__button form__button--flex-item">
                  <button
                    onClick={() => { history.push('/') }}
                    className="button button--gray button--back button--xlarge">マイページに戻る</button>
                </div>
              </div>
            </div>
          </div>
        }

      </SiteSettingMainArea>
    </React.Fragment>
  );
}

export default SettingTrainerEdit;
