import actionCreatorFactory from 'typescript-fsa';
import * as Models from "models/api";

const actionCreator = actionCreatorFactory('user');

export type signupPayload = {
  params: Models.SignupParams;
  promises?: { resolve: () => void, reject: (e: Error) => void}
}

export type createPasswordNotificationPayload = {
  params: { email: string };
  promises?: { resolve: () => void, reject: (e: Error) => void}
}

export type createStudentPasswordNotificationPayload = {
  params: { email: string; login: string; };
  promises?: { resolve: () => void, reject: (e: Error) => void}
}

export type createParentPasswordNotificationPayload = {
  params: { email: string; login: string; };
  promises?: { resolve: () => void, reject: (e: Error) => void}
}

export type createPasswordResetterPayload = {
  params: { token: string; password: string; };
  promises?: { resolve: () => void, reject: (e: Error) => void}
}

export type changePasswordPayload = {
  params: { password: string; };
  promises?: { resolve: () => void, reject: (e: Error) => void}
}

export type withdrawPayload = {
  params: Models.StudentUnsubscribeParams;
  promises?: { resolve: () => void, reject: (e: Error) => void}
}

export type createSmsNotificationPayload = {
  params: { tel: string };
  promises?: { resolve: () => void, reject: (e: Error) => void}
}

// TODO: studentに移す
export type cancelTrainerPayload = {
  promises?: { resolve: () => void, reject: (e: Error) => void}
}

export type requestTrainerPayload = {
  params: Models.RequestTrainerParams;
  promises?: { resolve: () => void, reject: (e: Error) => void}
}

const userActions = {
  signup:
    actionCreator<signupPayload>(
      'SIGNUP'),
  createPasswordNotification:
    actionCreator<createPasswordNotificationPayload>(
      'CREATE_PASSWORD_NOTIFICATION'),
  createStudentPasswordNotification:
    actionCreator<createStudentPasswordNotificationPayload>(
      'CREATE_STUDENT_PASSWORD_NOTIFICATION'),
  createParentPasswordNotification:
    actionCreator<createParentPasswordNotificationPayload>(
      'CREATE_PARENT_PASSWORD_NOTIFICATION'),
  createPasswordResetter:
    actionCreator<createPasswordResetterPayload>(
      'CREATE_PASSWORD_RESETTER'),
  changePassword:
    actionCreator<changePasswordPayload>(
      'CHANGE_PASSWORD'),
  withdraw:
    actionCreator<withdrawPayload>(
      'WITHDRAW'),
  cancelTrainer:
    actionCreator<cancelTrainerPayload>(
      'CANCEL_TRAINER'),
  requestTrainer:
    actionCreator<requestTrainerPayload>(
      'REQUEST_TRAINER'),
  createSmsNotification:
    actionCreator<createSmsNotificationPayload>(
      'CREATE_SMS_NOTIFICATION'),
};

export default userActions;
