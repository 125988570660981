import React from 'react';

import PrivateHeader from 'containers/parts/PrivateHeader';
import { mappedProps } from 'containers/student/campaign/Shiro202003';
import SiteMain from 'containers/parts/SiteMain';
import { Redirect, Link } from 'react-router-dom';

import campaignBannerPC from 'assets/img/campaign/shiro/bnr_top.jpg';
import campaignBannerSP from 'assets/img/campaign/shiro/bnr_top_sp.jpg';
import campaignMainPC from 'assets/img/campaign/shiro/main.jpg';
import campaignMainSP from 'assets/img/campaign/shiro/main_sp.jpg';
import imgComingsoonLesson from 'assets/img/campaign/shiro/img_comingsoon_lesson.png';
import imgComingsoon from 'assets/img/campaign/shiro/img_comingsoon.png';

export const MypageBanner: React.FC = () => {
  return (
    <section className="campaign-box">
      <div className="campaign-box__title">
        Vtuber 電脳少女シロ×河合塾One&nbsp;<br className="_sp" />
        春の勉強応援コラボキャンペーン
      </div>
      <div className="campaign-box__span">
        <div className="campaign-box__badge">
          公開期間
        </div>
        2020年3月10日(火)～<span className="_pc">2020年</span>5月10日(日)
      </div>
      <Link to='/campaign/shiro202003' className="campaign-box__banner">
        <picture>
          <source media="(max-width: 767px)" srcSet={campaignBannerSP} />
          <source media="(min-width: 768px)" srcSet={campaignBannerPC} />
          <img src={campaignBannerPC} alt="" className="campaign-box__image" />
        </picture>
      </Link>
    </section>
  )
}

const StudentCampaignShiro202003: React.FC<mappedProps> = (props: mappedProps) => {
  if (!props.isAvailable) {
    return <Redirect to='/' />;
  }

  return (
    <React.Fragment>
      <PrivateHeader />
      <SiteMain>
        <div className="site-main__main-contents">
          <section className="campaign-shiro-main-image">
            <picture>
              <source media="(max-width: 767px)" srcSet={campaignMainSP} />
              <source media="(min-width: 768px)" srcSet={campaignMainPC} />
              <img src={campaignMainPC} alt="" className="campaign-shiro-main-image__image" />
            </picture>
          </section>
          <section className="campaign-shiro-outline">
            <div className="campaign-shiro-outline__title">
              キャンペーン概要
            </div>
            <div className="campaign-shiro-outline__lead">
              シロが先生に？！<br />新年度！この春、勉強をがんばるみんなを応援したい♪
            </div>
            <div className="campaign-shiro-outline__span">
              公開期間：2020年3月10日(火)〜2020年5月10日(日)
            </div>
            <div className="campaign-shiro-outline__point-title">
              <div className="campaign-shiro-outline__number">
                1
              </div>
              河合塾One14日間無料体験受講者全員！
            </div>
            <div className="campaign-shiro-outline__point-text">
              シロの限定応援動画が視聴できちゃう！
            </div>
            <div className="campaign-shiro-outline__point-title">
              <div className="campaign-shiro-outline__number">
                2
              </div>
              河合塾One本入会者限定！
            </div>
            <div className="campaign-shiro-outline__point-text">
              シロ先生によるSpecial English Lesson 動画（河合塾講師監修）を見よう！
            </div>
            <div className="campaign-shiro-outline__note">
              ※本入会後に視聴できます。（14日間無料体験終了後、自動的に本入会となります）
            </div>
            <div className="campaign-shiro-outline__text">
              キャンペーンの詳細、コンテンツ公開スケジュールは<a className="campaign-shiro-outline__link" href="https://www.kawaijukuone.co.jp/lp002/" target="_blank" rel="noopener noreferrer">こちら</a>をご覧ください。
            </div>
          </section>
          <section className="campaign-shiro-lesson">
            <div className="campaign-shiro-lesson__badge">
              本入会者<br />限定！
            </div>
            <div className="campaign-shiro-lesson__title">
              シロ先生のSpecial English Lesson
            </div>

            {
              props.isMainVideoOpened ?
              <div className="campaign-shiro-lesson__video">
                { /* 315/560=56.25% */}
                <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                  <iframe
                    title='video1'
                    src="https://player.vimeo.com/video/404899024"
                    style={{ position:'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                    frameBorder="0" allow="autoplay; fullscreen"></iframe>
                </div>
              </div> :
              <img className="campaign-shiro-lesson__image" src={imgComingsoonLesson} alt="Coming Soon 4月上旬公開！" />
            }

          </section>
          <section className="campaign-shiro-cheering">
            <div className="campaign-shiro-cheering__title">
              シロ先生からの応援動画
            </div>

            {
              props.isSubVideo1Opened ?
              <div className="campaign-shiro-cheering__video">
                <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                  <iframe
                    title='video1'
                    src="https://player.vimeo.com/video/395097934"
                    style={{ position:'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                    frameBorder="0" allow="autoplay; fullscreen"></iframe>
                </div>
              </div> :
              <div className="campaign-shiro-cheering__video">
                <img className="campaign-shiro-cheering__image" src={imgComingsoon} alt="Coming Soon" />
              </div>
            }

            {
              props.isSubVideo2Opened ?
              <div className="campaign-shiro-cheering__video">
                <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                  <iframe
                    title='video1'
                    src="https://player.vimeo.com/video/396346965"
                    style={{ position:'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                    frameBorder="0" allow="autoplay; fullscreen"></iframe>
                </div>
              </div> :
              <div className="campaign-shiro-cheering__video">
                <img className="campaign-shiro-cheering__image" src={imgComingsoon} alt="Coming Soon" />
              </div>
            }

            {
              props.isSubVideo3Opened ?
              <div className="campaign-shiro-cheering__video">
                <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                  <iframe
                    title='video1'
                    src="https://player.vimeo.com/video/396346982"
                    style={{ position:'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                    frameBorder="0" allow="autoplay; fullscreen"></iframe>
                </div>
              </div> :
              <div className="campaign-shiro-cheering__video">
                <img className="campaign-shiro-cheering__image" src={imgComingsoon} alt="Coming Soon" />
              </div>
            }

          </section>

          <div className="back-home">
            <Link to='/' className="back-home__link">マイページへ戻る</Link>
          </div>
        </div>

      </SiteMain>
    </React.Fragment>
  );
}

export default StudentCampaignShiro202003
