import React, { useEffect, useState } from 'react';
import { Link, useParams, } from 'react-router-dom';

import PrivateHeader from 'containers/parts/PrivateHeader';
import { mappedProps } from 'containers/student/StudyUnitResult';
import SiteMain from 'containers/parts/SiteMain';
import Moment from 'react-moment';
import classNames from 'classnames';
import CorrectAnswerCount from './common/CorrectAnswerCount';

const StudyUnitResult: React.FC<mappedProps> = (props: mappedProps) => {
  const { unit_id } = useParams<{ unit_id: string }>();
  const [disabledStartStudy, setDisabledStartStudy] = useState<boolean>(false);
  const [showMore, setShowMore] = useState<boolean>(false);

  useEffect(() => {
    if (unit_id) {
      props.fetchData(Number(unit_id))

      // MARK: 以前の結果を表示を初期化
      setShowMore(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unit_id]);

  const record = props.getRecord(Number(unit_id))
  const moreRecord = props.getMoreRecord(Number(unit_id))
  const hasMore = record && record.total_page > 1

  const canStudy = record &&
    record.unit.content_id &&
    ( record.unit.status === 'done' || record.unit.status === 'complete' )

  const startStudy = () => {
    if (!record || disabledStartStudy) return

    const return_pathname = `/study/unit_results/${record.unit.id}`
    const content_id = record.unit.content_id
    setDisabledStartStudy(true)
    props.startStudy({ content_id, return_pathname }).then((url) => {
      setDisabledStartStudy(false)
      window.location.href = url
    })
  }

  return (
    <React.Fragment>
      <PrivateHeader />
      <SiteMain>
        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          {
            record &&
            <>
              <li className="breadcrumbs__item">
                <Link to={`/study/course_results/${record.curriculum.id}/${record.course.id}`} className="breadcrumbs__link">
                  科目・単元テスト一覧
                </Link>
              </li>
              <li className="breadcrumbs__item">
                結果比較
              </li>
            </>
          }
        </ol>

        {
          record && 
          <div className="site-main__main-contents">
            <div className="page-title">
            <div className="page-title__left">
              { record.curriculum.name }&nbsp;
              <div className="page-title__badge">
                { record.course.name }
              </div>
                &nbsp;
              <strong>単元テスト</strong>
                { record.unit.name }
            </div>
            <div className="page-title__correct-count">
                {props.student.studyAchievementsLatest.record && <>
                  <CorrectAnswerCount correctAnswerCount={props.student.studyAchievementsLatest.record.study_achievements.consecutive_correct_answer_count}/>
                </>}
              </div>
            </div>  
            <div className="test-box">
              <div className="test-box__title">
                受講後テスト
              </div>
              <div className="test-box__body">
                <div className="test-result-box">
                  <div className="test-result-box__head">
                    <div className="test-result-box__point-head">
                      正解数／問題数
                    </div>
                    <div className="test-result-box__date-head">
                      実施日時
                    </div>
                  </div>
                  <div className="test-result-box__body is-active">
                    {
                      record.results.posttest && record.results.posttest.map((x, index) => (
                        <div key={index} className="test-result-box__result">
                          <div className="test-result-box__result-title">
                            受講後テスト
                          </div>
                          <div className="test-result-box__point">
                            <b>{ x.correct } </b><span className="test-result-box__slash">/</span>{ x.total }
                            <div className="test-result-box__rank">
                              <div className={classNames("test-result-box__rank-badge", {
                                'test-result-box__rank-badge--s-rank': x.rank === 'S'
                              })}>
                                { x.rank }ランク
                              </div>
                            </div>
                          </div>
                          <div className="test-result-box__date">
                            <span className="test-result-box__date-title">実施日時</span>
                            <Moment format="YYYY年M月D日 HH:mm">{ x.acted_at }</Moment>
                          </div>
                        </div>
                      ))
                    }
                    {
                      ( !record.results.posttest || record.results.posttest.length <= 0 ) && 
                      <div className="test-result-box__result test-result-box__result--empty">
                        テスト結果がありません
                      </div>
                    }
                  </div>

                  {
                    hasMore && 
                    <div className="test-result-box__more">
                      <div className="more-button more-button--result">
                        <div
                          onClick={e => {
                            e.preventDefault()
                            setShowMore(!showMore)
                            if (!moreRecord) {
                              props.fetchMore()
                            }
                          }}
                          className={classNames("more-button__text more-button__text--result", {
                            'is-active': showMore,
                          })}>
                          以前の結果を表示
                        </div>
                      </div>
                    </div>
                  }

                  {
                    moreRecord && showMore &&
                    <div className="test-result-box__body is-active">
                      {
                        moreRecord.results.posttest && moreRecord.results.posttest.map((x, index) => (
                          <div key={index} className="test-result-box__result">
                            <div className="test-result-box__result-title">
                              受講後テスト
                            </div>
                            <div className="test-result-box__point">
                              <b>{ x.correct } </b><span className="test-result-box__slash">/</span>{ x.total }
                              <div className="test-result-box__rank">
                                <div className={classNames("test-result-box__rank-badge", {
                                  'test-result-box__rank-badge--s-rank': x.rank === 'S'
                                })}>
                                  { x.rank }ランク
                                </div>
                              </div>
                            </div>
                            <div className="test-result-box__date">
                              <span className="test-result-box__date-title">実施日時</span>
                              <Moment format="YYYY年M月D日 HH:mm">{ x.acted_at }</Moment>
                            </div>
                          </div>
                        ))
                      }
                      {
                        ( !moreRecord.results.posttest || moreRecord.results.posttest.length <= 0 ) && 
                        <div className="test-result-box__result test-result-box__result--empty">
                          テスト結果がありません
                        </div>
                      }
                    </div>
                  }

                  {
                    canStudy &&
                    <div className="test-result-box__button">
                      <a
                        href="#!"
                        onClick={e => {
                          e.preventDefault()
                          startStudy()
                        }}
                        className="button button--large button--primary"
                      >
                        { record.unit.status === 'done' ? 'テストを受ける' : '再テストを受ける' }
                      </a>
                    </div>
                  }
                </div>
              </div>
            </div>
            <div className="test-box">
              <div className="test-box__title">
                受講前テスト
              </div>
              <div className="test-box__body">
                <div className="test-result-box">
                  <div className="test-result-box__head">
                    <div className="test-result-box__point-head">
                      正解数／問題数
                    </div>
                    <div className="test-result-box__date-head">
                      実施日時
                    </div>
                  </div>
                  <div className="test-result-box__body is-active">
                    {
                      record.results.pretest &&
                      <div className="test-result-box__result">
                        <div className="test-result-box__result-title">
                          受講前テスト
                        </div>
                        <div className="test-result-box__point">
                          <b>{ record.results.pretest.correct }</b><span className="test-result-box__slash">/</span>{ record.results.pretest.total }
                          <div className="test-result-box__rank">
                            <div className={classNames("test-result-box__rank-badge", {
                              'test-result-box__rank-badge--s-rank': record.results.pretest.rank === 'S'
                            })}>
                              { record.results.pretest.rank }ランク
                            </div>
                          </div>
                        </div>
                        <div className="test-result-box__date">
                          <span className="test-result-box__date-title">実施日時</span>
                          <Moment format="YYYY年M月D日 HH:mm">{ record.results.pretest.acted_at }</Moment>
                        </div>
                      </div>
                    }
                    {
                      !record.results.pretest &&
                      <div className="test-result-box__result test-result-box__result--empty">
                        テスト結果がありません
                      </div>
                    }
                  </div>
                </div>
              </div>
              <div className="test-result-box__button">
                <Link to={`/study/course_results/${record.curriculum.id}/${record.course.id}`} className="button button--xlarge button--gray button--back">テスト一覧へ戻る</Link>
              </div>
            </div>
            <div className="back-home">
              <Link to='/' className="back-home__link">マイページへ戻る</Link>
            </div>
          </div>
        }

        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          {
            record &&
            <>
              <li className="breadcrumbs__item">
                <Link to={`/study/course_results/${record.curriculum.id}/${record.course.id}`} className="breadcrumbs__link">
                  科目・単元テスト一覧
                </Link>
              </li>
              <li className="breadcrumbs__item">
                結果比較
              </li>
            </>
          }
        </ol>
      </SiteMain>
    </React.Fragment>
  );
}

export default StudyUnitResult