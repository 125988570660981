import actionCreatorFactory from 'typescript-fsa';
import * as Models from "models/api";

const actionCreator = actionCreatorFactory('session');

export type createSessionPayload = {
  login: string,
  password: string,
  promises?: { resolve: () => void, reject: (e: Error) => void}
}

export type setUserPayload = {
  ui: Models.LoginableUIType;
  user: Models.User;
}

const sessionActions = {
  loadSession: actionCreator<void>('LOAD_SESSION'),
  loadDelegateSession: actionCreator<string>('LOAD_DELEGATE_SESSION'),
  createSession: actionCreator<createSessionPayload>('CREATE_SESSION'),
  deleteSession: actionCreator<void>('DELETE_SESSION'),
  setUser: actionCreator<setUserPayload>('SET_USER'),
  unsetUser: actionCreator<void>('UNSET_USER'),
  setLoaded: actionCreator<void>('SET_LOADED'),
};

export default sessionActions;
