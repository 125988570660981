import React, { useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import Moment from 'react-moment';

import { mappedProps } from 'containers/student/SettingPointExchangeLogs';
import PrivateHeader from 'containers/parts/PrivateHeader';
import SideNav from 'containers/student/SettingPointExchangeNav';

const commaFormat = (value: number) => {
  return value.toLocaleString()
}

const SettingPointExchangeLogs: React.FC<mappedProps> = (props: mappedProps) => {
  const history = useHistory()

  useEffect(() => {
    props.fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // MARK: コーポレートユーザ利用不可
  if (props.user.corporation) {
    return <Redirect to='/' />;
  }

  const hasNext = props.log.has_next
  const records = props.log.records

  return (
    <React.Fragment>
      <PrivateHeader />
      <main className="site-main site-main--setting">
        <div className="site-main__main">
          <div className="site-main__main-inner">
            <div className="site-main__main-contents">
              <div className="setting-box">
                <div className="setting-box__title setting-box__title--point-exchange">
                  ポイント交換履歴
                </div>
              </div>
              <div className="purchase-history-box">
                {
                  records && records.map(x => (
                    <div key={x.id} className="purchase-history-box__item">
                      <div className="purchase-history-box__datetime">
                        <Moment format="YYYY年M月D日">{ x.exchanged_on }</Moment>
                      </div>
                      <div className="purchase-history-box__name purchase-history-box__name--no-status">
                        { x.prize_text === 'アマギフ' && 'Amazonギフト券（Eメールタイプ）' }
                        { x.prize_text === '料金割引' && 'プラン料金割引' }
                        { x.prize_text === '質問チケット' && '質問チケット' }
                      </div>
                      <div className="purchase-history-box__price">
                        { commaFormat(x.points_used) }pt
                      </div>
                    </div>
                  ))
                }
              </div>

              {
                hasNext &&
                <div className="more-button">
                  <div
                    onClick={() => { props.fetchNext() }}
                    className="more-button__text">
                    次の履歴を表示
                  </div>
                </div>
              }

              <div className="setting-box">
                <form>
                  <div className="setting-box__form">
                    <div className="form">
                      <div className="form__buttons">
                        <div className="form__button form__button--flex-item">
                          <button 
                            onClick={(e) => {
                              e.preventDefault()
                              history.push('/')
                            }}
                            className="button button--gray button--back button--xlarge">マイページに戻る</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <SideNav />
      </main>
    </React.Fragment>
  );
}

export default SettingPointExchangeLogs;