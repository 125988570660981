import React from 'react';

import { StudyCourseCompletionTestUnitRecord, } from 'models/api';
import { Link } from 'react-router-dom';

type props = {
  testRecord: StudyCourseCompletionTestUnitRecord;
  resultPagePath: string;
}

export const CourseCompletionTestBox: React.FC<props> = ({ testRecord, resultPagePath }: props) => {
  return <>
    <div className="test-box">
      <div className="test-box__title test-box__title--large">
        科目修了テスト
      </div>

      {
        // MARK: 修了前
        !testRecord.unit.attend &&
        <div className="test-box__body">
          <div className="test-box__item">
            <div className="test-box__unit-title test-box__unit-title--completion">
              科目修了テスト
            </div>
            <div className="test-box__status test-box__status--completion is-disabled">
              未受験
            </div>
            <div className="test-box__text is-disabled _pc">
              テスト結果
            </div>
            <div className="test-box__buttons">
              <div className="test-box__button test-box__button--result is-disabled _sp">
                テスト結果
              </div>

            </div>
          </div>
        </div>
      }

      {
        // MARK: 修了後
        testRecord.unit.attend &&
        <div className="test-box__body">
          <div className="test-box__item">
            <div className="test-box__unit-title test-box__unit-title--completion">
              科目修了テスト
            </div>
            <div className="test-box__status test-box__status--completion">
              受験済
            </div>

            <Link
              to={resultPagePath}
              className="test-box__text _pc">
              テスト結果
            </Link>

            <div className="test-box__buttons">
              <Link
                to={resultPagePath}
                className="test-box__button test-box__button--result _sp">
                テスト結果
              </Link>

            </div>
          </div>
        </div>
      }
    </div>
  </>

}
