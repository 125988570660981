import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router'

import { AppState } from "store";
import { studyContentTypes } from 'utils/constants';
import targetComponent from 'components/trainer/StudyTopic';
import trainerActions from 'actions/trainerActions';
//import * as Models from 'models/api';

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch })

const mapStateToProps = (appState: AppState, ownProps: RouteComponentProps) => {
  return {
    user: appState.session.user!,
    student: appState.student,
    trainer: appState.trainer,
    env: appState.ui.env,
    studyContentTypes,
    ...ownProps,
  }
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  { dispatch }: ReturnType<typeof mapDispatchToProps>) => {
  return {
    ...stateProps,
    fetchData: (topic_id: number) => {
      dispatch(trainerActions.fetchStudyTopic({ topic_id }))
    },
    getRecord: (topic_id: number) => {
      const page = stateProps.trainer.studyTopic
      if (page.topic_id !== topic_id) {
        return null
      }
      return page.record
    },
    fetchPdfDownload: (curriculum_codes: string[]) => {
      const curriculumCodesString = curriculum_codes.join("_")
     return new Promise<{url: string}>((resolve, reject) => {
      dispatch(trainerActions.fetchPdfDownload({
        pdf_curriculums: curriculumCodesString,
        promises: { resolve, reject }
      }))})
    },
    startStudy: (params: { content_id: number, return_pathname: string }) => {
      return new Promise<string>((resolve, reject) => {
        dispatch(trainerActions.startStudy({
          params,
          promises: { resolve, reject }
        }))
      })
    },

    /* getLearningCount: (params: { curriculumCodes: Models.StudentStudyLearningCountParams }) => {
      return new Promise<Models.StudentStudyLearningCountRecords>((resolve, reject)=> { 
        dispatch(studentActions.getLearningCount(
           {
             params,
             promises: { resolve, reject }
           }
          ))
       })  
    } */
  }
}

export type mappedProps = ReturnType<typeof mergeProps>
export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(targetComponent))
