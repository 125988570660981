import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import logo from 'assets/img/img_logo.png';
import logoShowin from 'assets/img/logo_showin.png';
import userNoImage from 'assets/img/user_noimage.png';
import userNoImageTrainer from 'assets/img/user_noimage_trainer.png';
import userNoImageInstructor from 'assets/img/user_noimage_teacher.png';
import userNoImageParent from 'assets/img/user_noimage_parent.png';
import { mappedProps } from 'containers/parts/PrivateHeader';
import * as Models from 'models/api'

const getUserImage = (user: Models.User) => {
  if (user.type === 'trainer') {
    return userNoImageTrainer
  } else if (user.type === 'instructor') {
    return userNoImageInstructor
  } else if (user.type === 'parent') {
    return userNoImageParent
  } else {
    return userNoImage
  }
}

const PrivateHeader : React.FC<mappedProps> = (props: mappedProps) => {
  const [userNav, setUserNav] = useState(false);
  const [userSubNav, setUserSubNav] = useState(false);
  const [userNavMobile, setUserNavMobile] = useState(false);
  const [userSubNavMobile1, setUserSubNavMobile1] = useState(false);
  const [userSubNavMobile2, setUserSubNavMobile2] = useState(false);
  const [userSubNavMobile3, setUserSubNavMobile3] = useState(false);
  const [userSubNavMobile4, setUserSubNavMobile4] = useState(false);
  const [userSubNavMobile5, setUserSubNavMobile5] = useState(false);
  const [userSubNavMobile6, setUserSubNavMobile6] = useState(false);
  // issue-545
  const [userSubNavMobile7, setUserSubNavMobile7] = useState(false);
  const entrustCurriculumsList: string[] = ["junior_english", "junior_math", "junior_science"] //ここでおまかせで表示する教科を指定
  // issue-545

  const userImage = getUserImage(props.user)
  const resetMobileNav = () => {
    setUserNavMobile(false)
    setUserSubNavMobile1(false)
    setUserSubNavMobile2(false)
    setUserSubNavMobile3(false)
    setUserSubNavMobile4(false)
    setUserSubNavMobile5(false)
    setUserSubNavMobile6(false)
    setUserSubNavMobile7(false)
  }

  const curriculums = props.curriculums && props.curriculums

  // issue-545
  const entrustCurriculums = curriculums && curriculums.filter(x => entrustCurriculumsList.includes(x.code))
  // issue-545

  const alEngineConnectedCurriculums = curriculums && curriculums.filter(x => x.al_engine_connected)

  // MARK: テスト一覧画面に遷移すべき教科のリスト（単元テストか科目修了テストがある）
  const testListCurridulums = curriculums && curriculums.filter(x => x.al_engine_connected || x.has_completion_test)

  return (
    <header className="site-header">
      <div className="site-header__inner">
        <div className="site-header__logo">
          <Link to='/'>
            <img src={!props.isShowin ? logo : logoShowin} alt="" className={!props.isShowin ? "site-header__image" : "site-header__image-showin"} />
          </Link>
          {!props.isShowin &&
            <div className="site-header__text">
              わたし専用。河合塾One
            </div>}
        </div>

        <div
          className="site-header__nav-button"
          onClick={() => {
            setUserNavMobile(!userNavMobile)
            setUserNav(false)
          }}></div>

        <nav className="site-header__nav">
          <ul className="header-nav">
            <li className="header-nav__item">
              <Link to='/' className="header-nav__link">マイページ</Link>
            </li>

            {
              ( props.user.type === 'parent' || props.user.type === 'instructor' ) &&
              <li className="header-nav__item">
                <Link to='/infos' className="header-nav__link">お知らせ</Link>
              </li>
            }

            {
              props.user.type === 'student' && props.isAvailableStudentInfo &&
              <li className="header-nav__item">
                <Link to='/infos' className="header-nav__link">お知らせ</Link>
              </li>
            }

            {
              // 12/9時点 トレーナーはダミーのリンク設置
              props.user.type === 'trainer' &&
              <li className="header-nav__item">
                <Link to='#' className="header-nav__link">お知らせ</Link>
              </li>
            }

            {
              // MARK: BtoB受講生非表示
              props.uiType === 'student' && !props.studentUser!.corporation &&
              <li className="header-nav__item">
                <a href="https://www.kawaijukuone.co.jp/faq/" className="header-nav__link" target="_blank" rel="noopener noreferrer">よくあるご質問</a>
              </li>
            }
            {
              props.uiType !== 'student' &&
              <li className="header-nav__item">
                <a href="https://www.kawaijukuone.co.jp/faq/" className="header-nav__link" target="_blank" rel="noopener noreferrer">よくあるご質問</a>
              </li>
            }

            <li className="header-nav__item">
              <a href="https://www.kawaijukuone.co.jp/contact/" className="header-nav__link" target="_blank" rel="noopener noreferrer">お問い合わせ</a>
            </li>
          </ul>
        </nav>

        <div className="site-header__user">
          <div
            className="user-tag"
            onClick={() => setUserNav(!userNav)}>
            <div className="user-tag__avatar">
              <img src={userImage} alt="" className="user-tag__image" />
            </div>
            <div className="user-tag__text">
              <span className="user-tag__name">
                { props.user.type === 'parent' ? props.user.full_name : props.user.nick_name }
              </span>さん
            </div>
          </div>
          <div className={classNames('header-nav-mobile', { 'is-active' : userNavMobile })}>
            <div className="header-nav-mobile__user">
              <div className="user-tag" style={{ pointerEvents: 'all' }}>
                <div className="user-tag__avatar">
                  <img src={userImage} alt="" className="user-tag__image" />
                </div>
                <div className="user-tag__text">
                  <span className="user-tag__name">
                    { props.user.type === 'parent' ? props.user.full_name : props.user.nick_name }
                  </span>さん<br />
                  <span className="user-tag__id">ログインID：{props.user.login}</span>

                  {
                    props.uiType === 'student' && !props.studentUser!.corporation &&
                    <React.Fragment>
                      <br />
                      <span className="user-tag__point">
                        現在のポイント：{ props.studentUser!.current_points } pt
                      </span>
                    </React.Fragment>
                  }

                </div>
                <button
                  onClick={(e) => { 
                    e.preventDefault()
                    props.history.push('/logout') 
                  }}
                  className="button button--logout user-tag__button">ログアウト</button>
              </div>
            </div>

            {
              props.isAvailableGuide && props.user.type === 'student' && 
              <div className="header-nav-mobile__tutorial">
                <Link to='/guide' className="header-nav-mobile__button header-nav-mobile__button--tutorial" onClick={resetMobileNav}>
                  初めてご利用になる方
                </Link>
              </div>
            }

            <div className="header-nav-mobile__item">
              <Link to='/' className="header-nav-mobile__link header-nav-mobile__link--mypage" onClick={resetMobileNav}>マイページ</Link>
            </div>

            {
              props.user.type === 'student' && props.isAvailableStudentInfo &&
              <>
                <div className="header-nav-mobile__item">
                  <Link to='/infos' className="header-nav-mobile__link header-nav-mobile__link--information" onClick={resetMobileNav}>お知らせ</Link>
                </div>
              </>
            }

            {
              ( props.user.type === 'parent' || props.user.type === 'instructor' ) &&
              <>
                <div className="header-nav-mobile__item">
                  <Link to='/infos' className="header-nav-mobile__link header-nav-mobile__link--information" onClick={resetMobileNav}>お知らせ</Link>
                </div>
              </>
            }

            {
              // 12/9時点 トレーナーはダミーのリンク設置
              props.user.type === 'trainer' &&
              <div className="header-nav-mobile__item">
                <Link to='#' className="header-nav-mobile__link header-nav-mobile__link--information" onClick={resetMobileNav}>お知らせ</Link>
              </div>
            }

            {
              props.user.type === 'parent' &&
              <React.Fragment>
                <div
                  onClick={() => setUserSubNavMobile2(!userSubNavMobile2)}
                  className={classNames('header-nav-mobile__item header-nav-mobile__item--accordion header-nav-mobile__item--study', { 'is-active' : userSubNavMobile2 })}>
                  教科・単元選択学習
                </div>
                <div className="header-nav-mobile__item">
                  <div className={classNames("header-subnav-mobile", { 'is-active' : userSubNavMobile2 })}>
                    {
                      curriculums && curriculums.map(x => (
                        <div key={x.code} className="header-subnav-mobile__item">
                          <Link
                            to={`/student/study_result/${x.code}`}
                            className="header-subnav-mobile__link"
                            onClick={resetMobileNav}>{x.name}</Link>
                        </div>
                      ))
                    }
                  </div>
                </div>

                <div className="header-nav-mobile__item">
                  <Link to='/student/study_logs' className="header-nav-mobile__link header-nav-mobile__link--history" onClick={resetMobileNav}>受講者の学習履歴</Link>
                </div>
                <div className="header-nav-mobile__item">
                  <Link to='/setting/profile_edit' className="header-nav-mobile__link header-nav-mobile__link--profile" onClick={resetMobileNav}>
                    プロフィール確認・変更
                  </Link>
                </div>
                <div className="header-nav-mobile__item">
                  <Link to='/setting/password_edit' className="header-nav-mobile__link header-nav-mobile__link--password" onClick={resetMobileNav}>
                    パスワード変更
                  </Link>
                </div>
                <div className="header-nav-mobile__item">
                  <Link to='/setting/purchase_logs' className="header-nav-mobile__link header-nav-mobile__link--creditcard" onClick={resetMobileNav}>
                    購入履歴
                  </Link>
                </div>
              </React.Fragment>
            }

            {/*
              props.user.type === 'parent' &&
              <React.Fragment>
                <div className="header-nav-mobile__item">
                  <Link to='/' className="header-nav-mobile__link header-nav-mobile__link--creditcard" onClick={resetMobileNav}>
                    購入履歴
                  </Link>
                </div>
                <div className="header-nav-mobile__item">
                  <Link to='/setting/withdrawal' className="header-nav-mobile__link header-nav-mobile__link--withdraw" onClick={resetMobileNav}>
                    退会
                  </Link>
                </div>
              </React.Fragment>
            */}

            {
              props.user.type === 'student' &&
              <React.Fragment>
                {
                  // MARK: 一般受講生はかならず英語を受講するのでalEngineConnectedCurriculumsが存在するはず
                  alEngineConnectedCurriculums && alEngineConnectedCurriculums.length > 0 &&
                  <>
                    {/* issue-545 */}
                      {curriculums && curriculums.some(x => entrustCurriculumsList.includes(x.code)) && <>
                        <div
                          onClick={() => setUserSubNavMobile7(!userSubNavMobile7)}
                          className={classNames('header-nav-mobile__item header-nav-mobile__item--accordion header-nav-mobile__item--entrust', { 'is-active': userSubNavMobile7 })}>
                          おまかせ学習
                        </div>
                        <div className="header-nav-mobile__item">
                          <div className={classNames("header-subnav-mobile", { 'is-active': userSubNavMobile7 })}>
                            {entrustCurriculums && entrustCurriculums.length > 0 &&
                              entrustCurriculums.map( (x) => (<React.Fragment key={x.code}>
                                {curriculums && curriculums.some(y => y.code === x.code) &&
                                  <div key={x.code} className="header-subnav-mobile__item">
                                    <Link
                                      to={`/entrust/${x.code}`}
                                      className="header-subnav-mobile__link"
                                      onClick={resetMobileNav}>{x.name}</Link>
                                  </div>}
                                  </React.Fragment>))}
                          </div>
                        </div>
                      </>}
                    {/* issue-545 */}
                    <div
                      onClick={() => setUserSubNavMobile1(!userSubNavMobile1)}
                      className={classNames('header-nav-mobile__item header-nav-mobile__item--accordion header-nav-mobile__item--recommend', { 'is-active' : userSubNavMobile1 })}>
                      AIおすすめ学習
                    </div>
                    <div className="header-nav-mobile__item">
                      <div className={classNames("header-subnav-mobile", { 'is-active' : userSubNavMobile1 })}>
                        {
                          alEngineConnectedCurriculums.map(x => (
                            <div key={x.code} className="header-subnav-mobile__item">
                              <Link
                                to={`/recommends/${x.code}`}
                                className="header-subnav-mobile__link"
                                onClick={resetMobileNav}>{x.name}</Link>
                            </div>
                          ))
                        }
                      </div>
                    </div>
                    <div
                      onClick={() => setUserSubNavMobile6(!userSubNavMobile6)}
                      className={classNames('header-nav-mobile__item header-nav-mobile__item--accordion header-nav-mobile__item--test', { 'is-active' : userSubNavMobile6 })}>
                      科目・単元テスト一覧
                    </div>
                    <div className="header-nav-mobile__item">
                      <div className={classNames("header-subnav-mobile", { 'is-active' : userSubNavMobile6 })}>
                        {
                          testListCurridulums && testListCurridulums.map(x => (
                            <div key={x.code} className="header-subnav-mobile__item">
                              <Link
                                to={`/study/course_results/${x.id}`}
                                className="header-subnav-mobile__link"
                                onClick={resetMobileNav}>{x.name}</Link>
                            </div>
                          ))
                        }
                      </div>
                    </div>
                  </>
                }

                <div
                  onClick={() => setUserSubNavMobile2(!userSubNavMobile2)}
                  className={classNames('header-nav-mobile__item header-nav-mobile__item--accordion header-nav-mobile__item--study', { 'is-active' : userSubNavMobile2 })}>
                  教科・単元選択学習
                </div>
                <div className="header-nav-mobile__item">
                  <div className={classNames("header-subnav-mobile", { 'is-active' : userSubNavMobile2 })}>
                    {
                      curriculums && curriculums.map(x => (
                        <div key={x.code} className="header-subnav-mobile__item">
                          <Link
                            to={`/study/${x.code}`}
                            className="header-subnav-mobile__link"
                            onClick={resetMobileNav}>{x.name}</Link>
                        </div>
                      ))
                    }
                  </div>
                </div>

                <div
                  onClick={() => setUserSubNavMobile3(!userSubNavMobile3)}
                  className={classNames('header-nav-mobile__item header-nav-mobile__item--accordion header-nav-mobile__item--history', { 'is-active' : userSubNavMobile3 })}>
                  学習履歴
                </div>
                <div className="header-nav-mobile__item">
                  <div className={classNames("header-subnav-mobile", { 'is-active' : userSubNavMobile3 })}>
                    {
                      curriculums && curriculums.map(x => (
                        <div key={x.code} className="header-subnav-mobile__item">
                          <Link
                            to={`/study_logs/${x.code}`}
                            className="header-subnav-mobile__link"
                            onClick={resetMobileNav}>{x.name}</Link>
                        </div>
                      ))
                    }
                  </div>
                </div>
                {/* // issue-547 */}
                <div className="header-nav-mobile__item">
                <Link to='/bookmark' className="header-nav-mobile__link header-nav-mobile__link--bookmark" onClick={resetMobileNav}>ブックマーク</Link>
                </div>
                {/* // issue-547 */}
                {
                  props.studentUser!.trainer_contracted && props.isAvailableStudentMessage &&
                  <div className="header-nav-mobile__item">
                    <Link to='/messages' className="header-nav-mobile__link header-nav-mobile__link--trainer" onClick={resetMobileNav}>トレーナー相談</Link>
                  </div>
                }

                {
                  props.availableQuestions &&
                  <div className="header-nav-mobile__item">
                    <Link to='/study_question_logs' className="header-nav-mobile__link header-nav-mobile__link--teacher" onClick={resetMobileNav}>先生からの回答</Link>
                  </div>
                }

                {
                  // MARK: BtoB受講生の場合非表示
                  props.uiType === 'student' && !props.studentUser!.corporation &&
                  <div className="header-nav-mobile__item">
                    <a href="https://www.kawaijukuone.co.jp/faq/" className="header-nav-mobile__link header-nav-mobile__link--faq" target="_blank" rel="noopener noreferrer">よくあるご質問</a>
                  </div>
                }
                {
                  props.uiType !== 'student' &&
                  <div className="header-nav-mobile__item">
                    <a href="https://www.kawaijukuone.co.jp/faq/" className="header-nav-mobile__link header-nav-mobile__link--faq" target="_blank" rel="noopener noreferrer">よくあるご質問</a>
                  </div>
                }

                <div className="header-nav-mobile__item">
                  <a href="https://www.kawaijukuone.co.jp/contact/" className="header-nav-mobile__link header-nav-mobile__link--contact" target="_blank" rel="noopener noreferrer">お問い合わせ</a>
                </div>

                {
                  !props.studentUser!.corporation &&
                  <>
                    <div
                      onClick={() => setUserSubNavMobile5(!userSubNavMobile5)}
                      className={classNames('header-nav-mobile__item header-nav-mobile__item--accordion header-nav-mobile__item--exchange', { 'is-active' : userSubNavMobile5 })}>
                      ポイント交換
                    </div>
                    <div className="header-nav-mobile__item">
                      <div className={classNames("header-subnav-mobile", { 'is-active' : userSubNavMobile5 })}>
                        <div className="header-subnav-mobile__item">
                          <Link to='/setting/point_exchange' className="header-subnav-mobile__link" onClick={resetMobileNav}>ポイント交換について</Link>
                        </div>
                        <div className="header-subnav-mobile__item">
                          <Link to='/setting/point_exchange_logs' className="header-subnav-mobile__link" onClick={resetMobileNav}>ポイント交換履歴</Link>
                        </div>
                      </div>
                    </div>
                  </>
                }

                <div
                  onClick={() => setUserSubNavMobile4(!userSubNavMobile4)}
                  className={classNames('header-nav-mobile__item header-nav-mobile__item--accordion header-nav-mobile__item--setting js-toggle-header-subnav-mobile', { 'is-active' : userSubNavMobile4 })}
                >設定・変更</div>
                <div className="header-nav-mobile__item">
                  <div className={classNames("header-subnav-mobile", { 'is-active' : userSubNavMobile4 })}>
                    <div className="header-subnav-mobile__item">
                      <Link to='/setting/profile_edit' className="header-subnav-mobile__link header-subnav-mobile__link--with-icon header-subnav-mobile__link--profile" onClick={resetMobileNav}>プロフィール確認・変更</Link>
                    </div>
                    <div className="header-subnav-mobile__item">
                      <Link to='/setting/password_edit' className="header-subnav-mobile__link header-subnav-mobile__link--with-icon header-subnav-mobile__link--password" onClick={resetMobileNav}>パスワード変更</Link>
                    </div>
                    {
                      !props.studentUser!.corporation &&
                      <>
                        <div className="header-subnav-mobile__item">
                          <Link to='/setting/card_edit' className="header-subnav-mobile__link header-subnav-mobile__link--with-icon header-subnav-mobile__link--creditcard" onClick={resetMobileNav}>
                            クレジットカード情報変更</Link>
                        </div>
                        <div className="header-subnav-mobile__item">
                          <Link to='/setting/trainer_edit' className="header-subnav-mobile__link header-subnav-mobile__link--with-icon header-subnav-mobile__link--trainer-service" onClick={resetMobileNav}>
                            トレーナー申込・変更
                          </Link>
                        </div>
                        <div className="header-subnav-mobile__item">
                          <Link to='/setting/withdrawal' className="header-subnav-mobile__link header-subnav-mobile__link--with-icon header-subnav-mobile__link--withdraw" onClick={resetMobileNav}>
                            退会
                          </Link>
                        </div>
                      </>
                    }
                  </div>
                </div>
              </React.Fragment>
            }
            {
              props.user.type === 'trainer' &&

              <React.Fragment>
                <div className="header-nav-mobile__item">
                  <Link to='/study_summaries' className="header-nav-mobile__link header-nav-mobile__link--csv" onClick={resetMobileNav}>学習状況</Link>
                </div>
                <div className="header-nav-mobile__item">
                  <Link to='/csv_download' className="header-nav-mobile__link header-nav-mobile__link--csv" onClick={resetMobileNav}>学習データ</Link>
                </div>
                <div className="header-nav-mobile__item">
                  <Link to='/setting/profile_edit' className="header-nav-mobile__link header-nav-mobile__link--profile" onClick={resetMobileNav}>プロフィール確認・変更</Link>
                </div>
                <div className="header-nav-mobile__item">
                  <Link to='/setting/password_edit' className="header-nav-mobile__link header-nav-mobile__link--password" onClick={resetMobileNav}>パスワード変更</Link>
                </div>
                <div className="header-nav-mobile__item">
                  <a href="https://www.kawaijukuone.co.jp/contact/" className="header-nav-mobile__link header-nav-mobile__link--contact" target="_blank" rel="noopener noreferrer">お問い合わせ</a>
                </div>
              </React.Fragment>
            }

          </div>
        </div>
        <nav className={classNames('user-nav', { 'is-active' : userNav })}>
          <div className="user-nav__status">
            <span className="user-nav__name">
              { props.user.type === 'parent' ? props.user.full_name : props.user.nick_name }
            </span>さん
            <div className="user-nav__id">
              ログインID：{props.user.login}
            </div>

            {
              props.uiType === 'student' && !props.studentUser!.corporation &&
              <div className="user-nav__point">
                現在のポイント：{ props.studentUser!.current_points } pt
              </div>
            }

          </div>
          <ul className="user-nav__items">
            <li className="user-nav__item">
              <a href="#!" className="user-nav__link user-nav__link--logout" onClick={(e) => {
                e.preventDefault()
                props.history.push('/logout')
              }}>
                ログアウト
              </a>
            </li>
            <li className="user-nav__item">
              {
                props.user.type === 'student' && !props.studentUser!.corporation &&
                <a href="#!" className="user-nav__link" onClick={(e) => {
                  e.preventDefault()
                  props.history.push('/setting/point_exchange')
                }}>
                  ポイント交換
                </a>
              }

              {
                ['student', 'trainer', 'parent'].includes(props.user.type!) &&
                <div
                  className={classNames("user-nav__text", { 'is-active' : userSubNav })}
                  onClick={() => setUserSubNav(!userSubNav)}>
                  設定変更
                </div>
              }
              <ul className={classNames("user-subnav", { "is-active" : userSubNav })}>
                {
                  props.user.type === 'student' &&
                  <React.Fragment>
                    <li className="user-subnav__item">
                      <Link to='/setting/profile_edit' className="user-subnav__link user-subnav__link--profile">
                        プロフィール確認・変更
                      </Link>
                    </li>
                    <li className="user-subnav__item">
                      <Link to='/setting/password_edit' className="user-subnav__link user-subnav__link--password">
                        パスワード変更
                      </Link>
                    </li>
                  </React.Fragment>
                }

                {
                  props.user.type === 'trainer' &&
                  <React.Fragment>
                    <li className="user-subnav__item">
                      <Link to='/setting/profile_edit' className="user-subnav__link user-subnav__link--profile">
                        プロフィール確認・変更
                      </Link>
                    </li>
                    <li className="user-subnav__item">
                      <Link to='/setting/password_edit' className="user-subnav__link user-subnav__link--password">
                        パスワード変更
                      </Link>
                    </li>
                  </React.Fragment>
                }

                {
                  props.user.type === 'parent' &&
                  <React.Fragment>
                    <li className="user-subnav__item">
                      <Link to='/setting/profile_edit' className="user-subnav__link user-subnav__link--profile">
                        プロフィール確認・変更
                      </Link>
                    </li>
                    <li className="user-subnav__item">
                      <Link to='/setting/password_edit' className="user-subnav__link user-subnav__link--password">
                        パスワード変更
                      </Link>
                    </li>
                    <li className="user-subnav__item">
                      <Link to='/setting/purchase_logs' className="user-subnav__link user-subnav__link--creditcard">
                        購入履歴
                      </Link>
                    </li>
                  </React.Fragment>
                  /*
                  props.user.type === 'parent' &&
                  <React.Fragment>
                    <li className="user-subnav__item">
                      <Link to='/setting/withdrawal' className="user-subnav__link user-subnav__link--withdraw">
                        退会
                      </Link>
                    </li>
                  </React.Fragment>*/
                }

                {
                  props.user.type === 'student' &&
                  <>
                    {
                      !props.studentUser!.corporation &&
                      <>
                        <li className="user-subnav__item">
                          <Link to='/setting/card_edit' className="user-subnav__link user-subnav__link--creditcard">
                            クレジットカード変更
                          </Link>
                        </li>
                        <li className="user-subnav__item">
                          <Link to='/setting/trainer_edit' className="user-subnav__link user-subnav__link--trainer">
                            トレーナー申込・変更
                          </Link>
                        </li>
                        <li className="user-subnav__item">
                          <Link to='/setting/withdrawal' className="user-subnav__link user-subnav__link--withdraw">
                            退会
                          </Link>
                        </li>
                      </>
                    }
                  </>
                }
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default PrivateHeader;
