import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router'

import { AppState } from "store";
import RealtimeViewHeader from 'components/parts/RealtimeViewHeader';

import * as Models from 'models/api';
import { getRecommendedCurriculums }  from 'utils/store'
import * as campaignSettingGuide from 'utils/campaign/guide202012'
import * as studentMesageSetting from 'utils/campaign/studentMessage'
import * as studentInfo from 'utils/campaign/studentInfo'
import * as showin from 'utils/campaign/showin'
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
  }
}

const mapStateToProps = (appState: AppState, routeProps: RouteComponentProps) => {
  const user = appState.session.user!
  const uiType = appState.ui.type

  let studentUser: Models.StudentUser | null = null
  if (uiType === 'student') {
    studentUser = user as Models.StudentUser
  }
  if (uiType === 'parent') {
    // MARK: ログイン時に設定している
    studentUser = appState.parent.student
  }

  const availableQuestions = studentUser &&
    (
      !studentUser.corporation ||
      ( studentUser.corporation && studentUser.corporation.available_services.questions )
    )

  const isAvailableGuide = studentUser && campaignSettingGuide.isAvailable(studentUser)
  const isAvailableStudentMessage = studentUser && studentMesageSetting.isAvailable(studentUser)
  const isAvailableStudentInfo = studentUser && studentInfo.isAvailable(studentUser)
  const isShowin = studentUser && showin.isAvailable(studentUser)

  return {
    curriculums: studentUser && getRecommendedCurriculums(studentUser),
    user,
    studentUser,
    availableQuestions,
    isAvailableGuide,
    isAvailableStudentMessage,
    isAvailableStudentInfo,
    isShowin,
    uiType,
    ...routeProps
  }
}

type connectMappedProps = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>
export type mappedProps = connectMappedProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RealtimeViewHeader))