import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router'

import Logout from 'components/common/Logout';
import { AppState } from "store";
import sessionActions from 'actions/sessionActions'

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    logout: () => dispatch(sessionActions.deleteSession()),
  };
}

const mapStateToProps = (appState: AppState, ownProps: RouteComponentProps) => {
  return {
    ...ownProps,
  }
}

type connectMappedProps = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>
export type mappedProps = connectMappedProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Logout))
