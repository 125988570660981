import React, { useState, useEffect } from 'react';
import { Redirect, } from 'react-router-dom';

import { mappedProps } from 'containers/common/FixPaymentInformation';

import PublicHeader from '../parts/PublicHeader';
import SiteMainSimple  from '../parts/SiteMainSimple';

const FixPaymentInformation: React.FC<mappedProps> = (props: mappedProps) => {
  const [token, setToken] = useState<string|null>(null);

  useEffect(() => {
    const scFunc = (window as any).sc_func
    if (scFunc) { scFunc('kjone', 'pay_info', '') }
  }, [])

  useEffect(() => {
    if (props.isAuthenticated && !props.alreadyFixed) {
      props.createCreditcardToken().then(token => {
        setToken(token)
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isAuthenticated, props.alreadyFixed]);

  if (!props.isAuthenticated) {
    return <Redirect to='/login' />
  } else if (props.alreadyFixed) {
    return <Redirect to={props.nextPage} />
  }

  const url = token && props.paymentPageUrl + token

  return (
    <React.Fragment>
      <PublicHeader />
      <SiteMainSimple>
        <div className="account-box">
          <div className="account-box__title">
            クレジットカード情報のご登録／更新
          </div>
          <div className="account-box__lead">
            以下の注意事項をご確認の上、クレジットカード情報をご入力ください。
          </div>
          <div className="account-box__cautions">
            <ul className="account-caution-list">
              <li className="account-caution-list__item">
                { props.user.trial_days || 14 }日間無料トライアル期間中に、お支払いが発生することはありません。
              </li>
              <li className="account-caution-list__item">
                無料トライアル終了後については、初回ご請求額に記載のある金額分のお支払が発生します。
              </li>
              <li className="account-caution-list__item">
                無料トライアルは、初回のお申込みの時のみ適応となります。
              </li>
              <li className="account-caution-list__item">
                ご利用期間の終了時に、お選びいただいたプランで自動更新されます。
              </li>
              <li className="account-caution-list__item">
                解約・変更はユーザー画面からいつでもできます。ただし、長期プラン（６ヶ月プラン・１２ヶ月プラン）から１ヶ月プランに変更することはできません。
              </li>
              <li className="account-caution-list__item">
                追加質問等のオプション料金は、その都度、ご入力いただいたクレジットカードからお支払が発生します。
              </li>
              <li className="account-caution-list__item">
                トレーナーサポートオプションは、１ヶ月ごとの更新となり、その都度お支払が発生します。
              </li>
            </ul>
          </div>

          <div className="account-box__form">
            <form>
              <div className="account-form account-form--card">
                <div className="account-form__button account-form__button--large">
                  {
                    url &&
                    <button
                      onClick={(e) => {
                        e.preventDefault()
                        window.location.href = url

                        const scFunc = (window as any).sc_func
                        if (scFunc) { scFunc('kjone', 'creditcard', '') }
                      }}
                      className="button button--primary button--large">クレジットカード情報のご登録</button>
                  }
                </div>
              </div>
            </form>
          </div>
        </div>
      </SiteMainSimple>
    </React.Fragment>
  );
}
export default FixPaymentInformation
