import React, { useState, useEffect, useRef } from 'react';
import { Field, Fields, WrappedFieldProps, } from 'redux-form'
import { Link, Redirect, useHistory } from "react-router-dom";
import classNames from 'classnames';
import queryString from 'query-string'

import { mappedProps } from 'containers/signup/SignupStep1';
import { Yubinbango } from 'lib/yubinbango';

import SiteMainArea from '../parts/SiteMainArea';
import PublicHeader from '../parts/PublicHeader';
import SignupBoxSteps from './SignupBoxSteps';
import SignupStep1SelectPlanBox from './SignupStep1SelectPlanBox';
import Discount2024winterSignupStep1SelectPlanBox from './campaign/discount2024winter/SignupStep1SelectPlanBox';
import {renderInputField, renderSelectField, renderIfFieldsHasError, FormErrorMessage, RenderEmailField } from 'components/parts/FormParts'

const updateKana = (value: string = '', target: string, change: (field: string, value:any) => void) => {
  if (/^[\u3041-\u309F]+$/.test(value)) {
    const kana = value.split('').map((char) => {
      return String.fromCharCode(char.charCodeAt(0) + 0x60)
    }).join('');
    change(target, kana);
  } else if (value === '') {
    change(target, value);
  }
}

const updateFieldsByPostalCode = (code: string, change: (field: string, value:any) => void) => {
  new Yubinbango(code, (x: any) => {
    if (x.region !== '') {
      change('prefecture', x.region);
    }
    if (x.locality !== '' || x.street !== '') {
      change('municipality', x.locality + x.street);
    }
  });
}

const renderCheckboxWithLabelField = ({
  input,
  children,
  ...custom
}: WrappedFieldProps & { children: React.ReactNode }) => {
  let labelClassName = 'checkbox register-box__checkbox';
  if (input.checked) {
    labelClassName += ' is-checked';
  }
  return (
    <label className={labelClassName}>
      <input
        {...input}
        {...custom}
      />
      {children}
    </label>
  );
}

const Step1: React.FC<mappedProps> = (props: mappedProps) => {
  const history = useHistory()

  const locationHash = history.location.hash

  const registerAreaRef = React.createRef<HTMLDivElement>()
  const planAreaRef = React.createRef<HTMLDivElement>()

  const errorNotificationRef = useRef(null);
  const [submitCounter, setSubmitCounter] = useState(0);

  useEffect(() => {
    if (props.submitFailed) {
      if (errorNotificationRef.current) {
        const target = (errorNotificationRef.current as any)
        window.scrollTo(0, target.offsetTop);
      }
    }
  }, [submitCounter, props.submitFailed]);

  useEffect(() => {
    const scFunc = (window as any).sc_func
    if (scFunc) { scFunc('kjone', 'step1', '') }
  }, [])

  useEffect(() => {
    props.fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(()=>{
    if (locationHash === '#register-area' && registerAreaRef.current) {
      window.scrollTo(0, registerAreaRef.current.offsetTop);
    }
    if (locationHash === '#plan-area' && planAreaRef.current) {
      window.scrollTo(0, planAreaRef.current.offsetTop);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  if (props.submitSucceeded) {
    return <Redirect to={`${props.nextPage}${history.location.search}`} />;
  }

  const queryParams = queryString.parse(history.location.search);
  const fromMoshinavi = queryParams.external_alliance === 'moshinavi'

  return (
    <React.Fragment>
      <PublicHeader />
      <SiteMainArea>
        <div className="register-box">

          <div className="register-box__title">
            河合塾One 新規お申し込み
          </div>

          <div className="register-box__lead">
            {
              fromMoshinavi ? 
              'お申込み日から１４日間、無料でご利用いただけます。':
              'お申込み日から７日間、無料でご利用いただけます。'
            }
          </div>

          <SignupBoxSteps step={1} />

          <div ref={planAreaRef}></div>

          {
            props.isAvailableDiscount2024winter ?
            <Discount2024winterSignupStep1SelectPlanBox {...props} />:
            <SignupStep1SelectPlanBox {...props} />
          }

          {
            props.submitFailed && props.invalid &&
            <div
              ref={errorNotificationRef}
              className="register-box__error-block">
              <div className="error-message error-message--with-icon">
                正しく入力されていない項目、または入力必須項目で未入力の項目があります。
              </div>
            </div>
          }
          
          {
            props.trainerContracted &&
            <React.Fragment>
              <div className="register-box__subtitle register-box__subtitle--long-margin">
                トレーナー希望情報
              </div>

              <div className="register-box__form">
                <div className="form">
                  <div className="form__note form__note--head">
                    担当するトレーナーについて、ご希望を入力してください。<br/>システムで自動マッチングを行い、あなたに最適なトレーナーが指導を行います。
                  </div>
                  <div className="form__item">
                    <label className="form__label">文理系統</label>
                    <div className="form__field">
                      <div className="select form__select-wrapper">
                        <Field
                          component={renderSelectField}
                          name="desired_faculty_kind"
                          className="select__select" >
                          <option value="">決まっていない</option>
                          { props.facultyKinds.map(x => <option key={x.id}>{x.name}</option>) }
                        </Field>
                      </div>
                    </div>
                  </div>
                  <div className="form__item">
                    <label className="form__label">学部系統</label>
                    <div className="form__field">
                      <div className="select form__select-wrapper">
                        <Field
                          component={renderSelectField}
                          name="desired_faculty"
                          className="select__select" >
                          <option value="">決まっていない</option>
                          { props.selectableFaculties.map(x => <option key={x.name}>{x.name}</option>) }
                        </Field>
                      </div>
                    </div>
                  </div>
                  <div className="form__item">
                    <label className="form__label form__label--university">在籍または<br className="_pc" />合格大学</label>
                    <div className="form__field">
                      <div className="select form__select-wrapper">
                        <Field
                          component={renderSelectField}
                          name="desired_university"
                          className="select__select">
                          <option value="">決まっていない</option>
                          { props.universities.map(x => <option key={x.name}>{x.name}</option>) }
                        </Field>
                      </div>
                    </div>
                  </div>
                  <div className="form__item">
                    <label className="form__label">部活動</label>
                    <div className="form__field">
                      <div className="select form__select-wrapper">
                        <Field
                          component={renderSelectField}
                          name="club_kind"
                          className="select__select">
                          <option value="">無所属</option>
                          { props.clubKinds.map(x => <option key={x.name}>{x.name}</option>) }
                        </Field>
                      </div>

                      <br />

                      <Field
                        component={renderInputField}
                        className="input form__input form__input--club"
                        type="text"
                        name="club_name"
                        placeholder=""
                        disabled={!props.selectClubKind}
                      />

                      <div className="form__note">
                        あなたが今在籍している部活動名を入力してください。指導の参考にさせていただきます。
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="register-box__subtitle">
                トレーナーに求める条件
              </div>

              <div className="register-box__form">
                <div className="form">
                  <div className="form__note form__note--head">
                    それぞれ１つずつお選びください
                  </div>

                  {
                    props.requestKeys.map((y, index) => (
                      <div key={y} className="form__item form__item--flex">
                        <div className="form__label form__label--flex-item">
                          第{index+1}希望
                        </div>
                        <div className="form__field form__field--flex-item">
                          {
                            props.trainerRequests.map(x => (
                              <label key={x} className={
                                classNames("radio form__radio-label", {
                                  'is-checked' : props.currentRequests[index] === x })}>
                                <Field
                                  component="input"
                                  className="radio__radio form__radio"
                                  type="radio"
                                  name={y}
                                  value={x}
                                />
                                {x}
                              </label>
                            ))
                          }
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>

              {
                props.submitFailed && 
                <Fields names={[ 'first_request' ]} component={renderIfFieldsHasError}>
                  <div className="register-box__error">
                    <div className="error-message">
                      各希望条件はそれぞれ別のものを選択してください。
                    </div>
                  </div>
                </Fields>
              }

            </React.Fragment>
          }
          <div className="register-box__subtitle register-box__subtitle--long-margin" ref={registerAreaRef}>
            申し込み情報（受講者・保護者）
            <small className="register-box__subtitle-note">
            <span className="register-box__note-required">＊</span>は必須項目です。</small>
          </div>

          <div className="register-box__form">
            <form onSubmit={props.handleSubmit}>

              <div className="form">

                <div className="form__item">
                  <label className="form__label form__label--required">受講者氏名<br className="_pc" />（※①）</label>
                  <div className="form__field">

                    <Field
                      component={renderInputField}
                      className="input input--required form__input form__input--name"
                      type="text"
                      name="student_last_name"
                      placeholder="河合"
                      onKeyUp={(e: any) => {
                        updateKana(props.studentLastName, 'student_last_name_read', props.change);
                      }}
                    />
                    <Field
                      component={renderInputField}
                      className="input input--required form__input form__input--name"
                      type="text"
                      name="student_first_name"
                      placeholder="太郎"
                      onKeyUp={(e: any) => {
                        updateKana(props.studentFirstName, 'student_first_name_read', props.change);
                      }}
                    />

                    {
                      props.submitFailed &&
                      <Fields names={[ 'student_first_name', 'student_last_name' ]} component={renderIfFieldsHasError}>
                        <div className="form__error">
                          <div className="error-message">
                            受講者氏名（全角）をご入力ください。
                          </div>
                        </div>
                      </Fields>
                    }
                  </div>
                </div>

                <div className="form__item">
                  <label className="form__label form__label--required">受講者氏名(カナ)<br className="_pc" />（※①）</label>
                  <div className="form__field">
                    <Field
                      component={renderInputField}
                      className="input input--required form__input form__input--name"
                      type="text"
                      name="student_last_name_read"
                      placeholder="カワイ"
                    />
                    <Field
                      component={renderInputField}
                      className="input input--required form__input form__input--name"
                      type="text"
                      name="student_first_name_read"
                      placeholder="タロウ"
                    />

                    {
                      props.submitFailed &&
                      <Fields
                        names={[ 'student_first_name_read', 'student_last_name_read' ]}
                        component={renderIfFieldsHasError}>
                        <div className="form__error">
                          <div className="error-message">
                            受講者氏名カナ（全角）をご入力ください。
                          </div>
                        </div>
                      </Fields>
                    }

                  </div>
                </div>

                <div className="form__item">
                  <label className="form__label form__label--required">保護者氏名<br className="_pc" />（※②）</label>
                  <div className="form__field">

                    <Field
                      component={renderInputField}
                      className="input input--required form__input form__input--name"
                      type="text"
                      name="last_name"
                      placeholder="河合"
                      onKeyUp={(e: any) => {
                        updateKana(props.parentLastName, 'last_name_read', props.change);
                      }}
                    />
                    <Field
                      component={renderInputField}
                      className="input input--required form__input form__input--name"
                      type="text"
                      name="first_name"
                      placeholder="太郎"
                      onKeyUp={(e: any) => {
                        updateKana(props.parentFirstName, 'first_name_read', props.change);
                      }}
                    />

                    {
                      props.submitFailed &&
                      <FormErrorMessage
                        fieldNames={[ 'first_name', 'last_name' ]}
                        message="保護者氏名（全角）をご入力ください。" />
                    }

                  </div>
                </div>

                <div className="form__item">
                  <label className="form__label form__label--required">保護者氏名(カナ)<br className="_pc" />（※②）</label>
                  <div className="form__field">
                    <Field
                      component={renderInputField}
                      className="input input--required form__input form__input--name"
                      type="text"
                      name="last_name_read"
                      placeholder="カワイ"
                    />
                    <Field
                      component={renderInputField}
                      className="input input--required form__input form__input--name"
                      type="text"
                      name="first_name_read"
                      placeholder="タロウ"
                    />

                    {
                      props.submitFailed &&
                      <Fields
                        names={[ 'first_name_read', 'last_name_read' ]}
                        component={renderIfFieldsHasError}>
                        <div className="form__error">
                          <div className="error-message">
                            保護者氏名カナ（全角）をご入力ください。
                          </div>
                        </div>
                      </Fields>
                    }
                  </div>
                </div>
                <div className="form__item">
                  <div className="form__note">
                    18歳以上の方（高校生を除く）がご自身でお支払いをされる場合は、お手数ですが①②に同じ氏名を入力してください。
                  </div>
                </div>
                <div className="form__item">
                  <div className="email-registration-warning">
                    <div className="email-registration-warning__title">※ メールアドレス登録についてのご注意</div>
                    <div className="email-registration-warning__body">
                      携帯キャリアメールをご利用の場合、ID発行メールが迷惑メールに分類されてしまうことがございます。<br/>
                      ご登録いただく際は、「kawaijukuone.jp」ドメインのメールが受信可能となるよう設定をお願いします。
                    </div>
                  </div>
                </div>

                <div className="form__item">
                  <label className="form__label form__label--required">受講者メールアドレス</label>
                  <div className="form__field">
                    <div className="form__note form__note--orange">
                      受講者用のログインアカウントが発行されます。
                    </div>
                    <Field
                      component={RenderEmailField}
                      className="input input--required form__input form__input--email mousetrap"
                      type="email"
                      name="student_email"
                      placeholder="user＠kawai-one.com"
                      autoComplete="off"
                      change={props.change}
                      inputMode="email"
                      ime-mode="disabled"
                    />

                    {
                      props.submitFailed &&
                      <Fields names={[ 'student_email' ]} component={renderIfFieldsHasError}>
                        <div className="form__error">
                          <div className="error-message">
                            メールアドレス（半角英数記号）をご入力ください。
                          </div>
                        </div>
                      </Fields>
                    }

                  </div>
                </div>
                <div className="form__item">
                  <label className="form__label form__label--required">
                    受講者メールアドレス<br className="_pc" />（確認）
                  </label>
                  <div className="form__field">

                    <Field
                      component={RenderEmailField}
                      className="input input--required form__input form__input--email mousetrap"
                      type="email"
                      name="student_email_confirmation"
                      placeholder=""
                      autoComplete="off"
                      change={props.change}
                      inputMode="email"
                      ime-mode="disabled"
                    />

                    {
                      props.submitFailed &&
                      <Fields names={[ 'student_email_confirmation' ]} component={renderIfFieldsHasError}>
                        <div className="form__error">
                          <div className="error-message">
                            メールアドレス（半角英数記号）をご入力ください。
                          </div>
                        </div>
                      </Fields>
                    }

                  </div>
                </div>


                <div className="form__item">
                  <label className="form__label form__label--required">保護者メールアドレス</label>
                  <div className="form__field">
                    <div className="form__note form__note--orange">
                      保護者用のログインアカウントが発行されます。
                    </div>
                    <Field
                      component={RenderEmailField}
                      className="input input--required form__input form__input--email mousetrap"
                      type="email"
                      name="email"
                      placeholder="user＠kawai-one.com"
                      autoComplete="off"
                      change={props.change}
                      inputMode="email"
                      ime-mode="disabled"
                    />

                    {
                      props.submitFailed &&
                      <Fields names={[ 'email' ]} component={renderIfFieldsHasError}>
                        <div className="form__error">
                          <div className="error-message">
                            メールアドレス（半角英数記号）をご入力ください。
                          </div>
                        </div>
                      </Fields>
                    }

                  </div>
                </div>
                <div className="form__item">
                  <label className="form__label form__label--required">
                    保護者メールアドレス<br className="_pc" />（確認）
                  </label>
                  <div className="form__field">

                    <Field
                      component={RenderEmailField}
                      className="input input--required form__input form__input--email mousetrap"
                      type="email"
                      name="email_confirmation"
                      placeholder=""
                      autoComplete="off"
                      change={props.change}
                      inputMode="email"
                      ime-mode="disabled"
                    />

                    {
                      props.submitFailed &&
                      <Fields names={[ 'email_confirmation' ]} component={renderIfFieldsHasError}>
                        <div className="form__error">
                          <div className="error-message">
                            メールアドレス（半角英数記号）をご入力ください。
                          </div>
                        </div>
                      </Fields>
                    }

                    <div className="form__note">
                      河合塾OneのID・パスワードは、noreply@kawaijukuone.jpで届きます。<br />
                      メール受信拒否設定をご利用の方は、「kawaijukuone.jp」ドメインのメールが受信可能となるよう設定変更をお願いします。
                    </div>
                  </div>
                </div>
                <div className="form__item">
                  <label className="form__label form__label--required">お支払い者住所</label>
                  <div className="form__field">
                    〒
                    <Field
                      component={renderInputField}
                      className="input input--required form__input form__input--zip"
                      type="tel"
                      inputMode="tel"
                      name="postal_code"
                      placeholder="1112222"
                      size={7}
                      ime-mode="disabled"
                    />
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        updateFieldsByPostalCode(
                          props.postalCode, props.change);
                      }}
                      className="button button--primary button--no-chevron form__zip-button">
                      住所検索
                    </button>

                    {
                      props.submitFailed &&
                      <Fields names={[ 'postal_code' ]} component={renderIfFieldsHasError}>
                        <div className="form__error">
                          <div className="error-message">
                            郵便番号（半角数字）を入力してください。
                          </div>
                        </div>
                      </Fields>
                    }

                    <div className="form__note">
                      （半角数字、- [ハイフン] は含めない）
                    </div>

                    <div className="select form__select-wrapper">
                      <Field
                        component={renderSelectField}
                        className="select__select select__select--required"
                        name="prefecture"
                      >
                        <option value="">選択してください</option>
                        { props.prefectures.map(x => (<option key={x}>{x}</option>)) }
                      </Field>
                    </div>

                    {
                      props.submitFailed &&
                      <Fields names={[ 'prefecture' ]} component={renderIfFieldsHasError}>
                        <div className="form__error">
                          <div className="error-message">
                            都道府県を選択してください。
                          </div>
                        </div>
                      </Fields>
                    }

                    <br />

                    <Field
                      component={renderInputField}
                      className="input input--required form__input form__input--city"
                      type="text"
                      name="municipality"
                    />

                    {
                      props.submitFailed &&
                      <Fields names={[ 'municipality' ]} component={renderIfFieldsHasError}>
                        <div className="form__error">
                          <div className="error-message">
                            市区町村名を入力してください。
                          </div>
                        </div>
                      </Fields>
                    }

                    <div className="form__note">
                      市区町村名までを入力してください。
                    </div>

                    <Field
                      component={renderInputField}
                      className="input input--required form__input form__input--address"
                      type="text"
                      name="address"
                    />

                    {
                      props.submitFailed &&
                      <Fields names={[ 'address' ]} component={renderIfFieldsHasError}>
                        <div className="form__error">
                          <div className="error-message">
                            住所を入力してください。
                          </div>
                        </div>
                      </Fields>
                    }

                    <div className="form__note">
                      番地、建物名（マンション・団地・社宅）、部屋番号等
                    </div>

                    <div className="form__note">
                      クレジットカードでのお支払いとなるため、お支払い者の住所をご記入ただく必要がございます。
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="register-box__subtitle register-box__subtitle--long-margin">
                受講者基本情報
                <small className="register-box__subtitle-note">
                <span className="register-box__note-required">＊</span>は必須項目です。</small>
              </div>

              <div className="form__item">
                <label className="form__label form__label--required">生年月日</label>
                <div className="form__field">

                  <Field
                    component={renderInputField}
                    className="input input--required form__input form__input--year"
                    type="number"
                    name="birthday_year"
                    placeholder="2005"
                    ime-mode="disabled"
                    />年
                  <Field
                    component={renderInputField}
                    className="input input--required form__input form__input--month"
                    type="number"
                    name="birthday_month"
                    placeholder="1"
                    ime-mode="disabled"
                    />月
                  <Field
                    component={renderInputField}
                    className="input input--required form__input form__input--day"
                    type="number"
                    name="birthday_day"
                    placeholder="1"
                    ime-mode="disabled"
                    />日

                  <div className="form__note">
                    （半角数字）
                  </div>

                  {
                    props.submitFailed &&
                    <Fields
                      names={[ 'birthday_year', 'birthday_month', 'birthday_day' ]}
                      component={renderIfFieldsHasError}>
                      <div className="form__error">
                        <div className="error-message">
                          生年月日を入力してください。
                        </div>
                      </div>
                    </Fields>
                  }

                </div>
              </div>

              <div className="form__item">
                <label className="form__label form__label--required">性別</label>
                <div className="form__field">
                  <div className="select form__select-wrapper">
                    <Field
                      component={renderSelectField}
                      className="select__select select__select--required"
                      name="gender">
                      <option value="">
                        選択してください
                      </option>
                      { props.masterData && props.masterData.genders.map(x => (<option key={x}>{x}</option>)) }
                    </Field>
                  </div>

                  {
                    props.submitFailed &&
                    <Fields
                      names={[ 'gender' ]}
                      component={renderIfFieldsHasError}>
                      <div className="form__error">
                        <div className="error-message">
                          性別を選択してください。
                        </div>
                      </div>
                    </Fields>
                  }

                </div>
              </div>

              <div className="form__item">
                <label className="form__label form__label--required">在籍（卒業）高校</label>
                <div className="form__field">
                  <Field
                    component={renderInputField}
                    className="input input--required form__input form__input--highschool"
                    type="text"
                    name="high_school_name"
                    placeholder="東京都立河合高等学校"
                  />

                  {
                    props.submitFailed &&
                    <Fields names={[ 'high_school_name' ]} component={renderIfFieldsHasError}>
                      <div className="form__error">
                        <div className="error-message">
                          在籍高校をご入力ください。
                        </div>
                      </div>
                    </Fields>
                  }

                  <div className="form__note">
                    全角で入力してください。中学生の方は中学校名をご記入ください。
                  </div>
                </div>
              </div>
              <div className="form__item">
                <label className="form__label form__label--required">学年</label>
                <div className="form__field">
                  <div className="select form__select-wrapper">
                    <Field
                      component={renderSelectField}
                      className="select__select select__select--required"
                      name="school_grade"
                    >
                      <option value="">
                        選択してください
                      </option>
                      { props.masterData && props.masterData.school_grades.map(x => (<option key={x}>{x}</option>)) }
                   </Field>
                  </div>

                  {
                    props.submitFailed &&
                    <Fields names={[ 'school_grade' ]} component={renderIfFieldsHasError}>
                      <div className="form__error">
                        <div className="error-message">
                          学年を選択してください。
                        </div>
                      </div>
                    </Fields>
                  }
                </div>
              </div>

              <div className="form__item">
                <label className="form__label form__label--required">ニックネーム</label>
                <div className="form__field">

                  <Field
                    component={renderInputField}
                    className="input input--required form__input form__input--nickname"
                    type="text"
                    name="nick_name"
                    placeholder=""
                  />

                  {
                    props.submitFailed &&
                    <Fields names={[ 'nick_name' ]} component={renderIfFieldsHasError}>
                      <div className="form__error">
                        <div className="error-message">
                          ニックネームをご入力ください。
                        </div>
                      </div>
                    </Fields>
                  }

                  <div className="form__note">
                    （全角８文字まで）
                  </div>
                  <div className="form__note form__note--orange">
                    先生への質問や、トレーナーとのやり取りの際に表示されます。後から変更することができます
                  </div>
                </div>
              </div>

              <div className="register-box__agreement register-box__agreement--border">
                <Field
                  component={renderCheckboxWithLabelField}
                  className="checkbox__checkbox"
                  type="checkbox"
                  name="term1_check"
                >
                  <Link to="/privacy_policy" target="_blank">個人情報の取り扱い</Link>に同意する
                </Field>
              </div>

              <Fields names={[ 'term1_check' ]} component={renderIfFieldsHasError}>
                <div className="register-box__error">
                  <div className="error-message">
                    同意されない場合、お申込み頂くことはできません。
                  </div>
                </div>
              </Fields>

              <div className="register-box__agreement">
                <Field
                  component={renderCheckboxWithLabelField}
                  className="checkbox__checkbox"
                  type="checkbox"
                  name="term2_check"
                >
                  <Link to="/terms" target="_blank">河合塾One利用規約</Link>に同意する
                </Field>
              </div>

              <Fields names={[ 'term2_check' ]} component={renderIfFieldsHasError}>
                <div className="register-box__error">
                  <div className="error-message">
                    同意されない場合、お申込み頂くことはできません。
                  </div>
                </div>
              </Fields>

              <div className="form">
                <div className="form__buttons">
                  <div className="form__button form__button--flex-item">
                    <button
                      onClick={(e) => {
                        setSubmitCounter(submitCounter+1)
                      }}
                      type="submit"
                      disabled={props.submitting}
                      className="button button--primary button--xlarge">ご入力内容確認に進む
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

      </SiteMainArea>
    </React.Fragment>
  );
}

export default Step1
