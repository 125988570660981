import React, { useEffect, } from 'react';
import Moment from 'react-moment';

import PrivateHeader from 'containers/parts/PrivateHeader';
import SiteSettingMainArea from '../common/SiteSettingMainArea';

import { mappedProps } from 'containers/parent/SettingPurchaseLogs';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

const SettingPurchaseLogs: React.FC<mappedProps> = (props: mappedProps) => {
  const history = useHistory()

  useEffect(() => {
    props.fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const records = props.parent.paymentLog.records

  return (
    <React.Fragment>
      <PrivateHeader />
      <SiteSettingMainArea page="purchase_logs" user={props.user}>
        <div className="setting-box">
          <div className="setting-box__title setting-box__title--creditcard">
            購入履歴
          </div>
        </div>
        <div className="purchase-history-box">
          { records && records.map(record => (
            <div className="purchase-history-box__item">
              <div className="purchase-history-box__datetime">
                <Moment format="YYYY年M月D日">{ record.created_at }</Moment>
              </div>
              <div className="purchase-history-box__status">

                <div className={classNames(
                  'purchase-history-box__badge', {
                    'purchase-history-box__badge--not-purchased': record.statusCode === 'not-purchased' || record.statusCode === 'purchase-failed',
                    'purchase-history-box__badge--purchased': record.statusCode === 'purchased',
                  }
                )}>
                  {
                    record.statusName 
                  }
                </div>
              </div>
              <div className="purchase-history-box__name">
                { record.name }
              </div>
              <div className="purchase-history-box__price">
                { record.price }円（税込）
              </div>
            </div>
          ))}

        </div>
        <div className="setting-box">
          <form>
            <div className="setting-box__form">
              <div className="form">
                <div className="form__buttons">
                  <div className="form__button form__button--flex-item">
                    <button
                      onClick={(e) => {
                        e.preventDefault()
                        history.push('/')
                      }}
                      className="button button--gray button--back button--xlarge">マイページに戻る</button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </SiteSettingMainArea>
    </React.Fragment>
  );
}

export default SettingPurchaseLogs
