import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, InjectedFormProps, FormErrors, SubmissionError } from 'redux-form'

import { AppState } from "store";
import targetComponent from 'components/student/StudyLogBuyTicket';
import studentActions from 'actions/studentActions';
import * as Models from 'models/api'
import { pointRate, } from 'utils/constants'

type FormData = {
  [key: string]: any
};

export const formKey = 'question_create'

export const FormValidate = (values: FormData) => {
  const errors: FormErrors<FormData> = {}
  return errors
}

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch })

const mapStateToProps = (appState: AppState) => {

  const user = appState.session.user! as Models.StudentUser
  const student = appState.student
  const ticketCount = student.questionsSheetsCountInMonth.record || 0;
  const canTicketPointExchange = user.current_points >= pointRate && ticketCount === 0
  return {
    user,
    student,
    canTicketPointExchange,
    pointRate,
  }
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  { dispatch }: ReturnType<typeof mapDispatchToProps>) => {
  return {
    ...stateProps,
    fetchData: () => {
      dispatch(studentActions.fetchQuestionsSheetsCountInMonth())
    },
    confirmTicketPointExchange: () => {
      return new Promise<void>((resolve, reject) => {
        if (!stateProps.canTicketPointExchange) {
          reject()
          return
        }
        // MARK: 質問チケットを交換する
        const payload = {
          params: {
            item_ordered: 2,
            number_ordered: 1,
          },
          promises: { resolve, reject }
        }
        dispatch(studentActions.confirmPointExchange(payload))
      })
    },
    onSubmit: (values: FormData) => {
      return new Promise<void>((resolve, reject) => {
        dispatch(studentActions.buyQuestionTicket({
          promises: { resolve, reject }
        }))
      }).catch((e: Error) => {
        throw new SubmissionError({})
      })
    }
  }
}

type connectMappedProps = ReturnType<typeof mergeProps>
export type mappedProps = connectMappedProps & InjectedFormProps<FormData, connectMappedProps>

const form = reduxForm<FormData, connectMappedProps>({
  form: formKey,
  validate: FormValidate
})(targetComponent)

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(form)
