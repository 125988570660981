import { AxiosError } from 'axios';

// https://docbase.io/posts/900521/sharing/deb62c78-bf46-4307-903c-70ee2a44317a
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/README.markdown

export type ApiError = AxiosError<{
  errors: string[]
}>

export const isApiError = (e: any):e is ApiError => !!e.isAxiosError

export type UIType = 'student' | 'parent' | 'instructor' | 'trainer' | 'signup';
export type LoginableUIType = 'student' | 'parent' | 'instructor' | 'trainer';
export const loginableUIs = ['student', 'parent', 'instructor', 'trainer']


export type MasterDataCurriculumLevel = {
  level: number;
  course_names: string;
}

export type MasterData = {
  genders: string[];
  school_grades: string[];
  english_levels: MasterDataCurriculumLevel[];
  math_levels: MasterDataCurriculumLevel[];
  physics_levels: MasterDataCurriculumLevel[];
  chemistry_levels: MasterDataCurriculumLevel[];
  classical_japanese_levels: MasterDataCurriculumLevel[];
  world_history_levels: MasterDataCurriculumLevel[];
  japanese_history_levels: MasterDataCurriculumLevel[];
  math_basic_levels: MasterDataCurriculumLevel[];
  english_basic_levels: MasterDataCurriculumLevel[];
  biology_levels: MasterDataCurriculumLevel[];
  english_words_levels: MasterDataCurriculumLevel[];
  new_math_levels: MasterDataCurriculumLevel[];
  junior_english_levels: MasterDataCurriculumLevel[];
  junior_math_levels: MasterDataCurriculumLevel[];
  junior_science_levels: MasterDataCurriculumLevel[];
  modern_japanese_levels: MasterDataCurriculumLevel[];
  /* issue-673 */
  new_chemistry_2024_levels: MasterDataCurriculumLevel[];
  new_biology_2024_levels: MasterDataCurriculumLevel[];
}

export type Users = User[]

export type User = StudentUser | ParentUser | TrainerUser | InstructorUser;

export type UserBase = {
  id: number;
  type: LoginableUIType;
  login: string; // ログインID
  full_name: string; // 氏名
  full_name_read: string; // 氏名カナ
  email: string; //　メールアドレス
  nick_name: string; // ニックネーム
}

export type StudentUser = UserBase & {
  login: string; // ログインID
  password_updated?: boolean; //パスワード初回更新済フラグ 
  nick_name: string; // ニックネーム
  status: 'temporary'|'trial'|'regular'|'suspension'; // ステータス
  email: string; //　メールアドレス
  trainer_contracted: boolean; // トレーナーサービスを申込済み
  created_at: string; // 登録日
  first_request: string; //トレーナー第1希望
  second_request: string; //トレーナー第2希望
  third_request: string; //トレーナー第3希望
  full_name: string; // 氏名
  full_name_read: string; // 氏名カナ
  gender: string; // 性別
  birthday: string; // 誕生日
  postal_code: string; // 郵便番号
  prefecture: string; // 都道府県（任意）
  municipality: string; // 市区町村（任意）
  address: string; // 住所（任意）
  high_school_kind: string;
  high_school_name: string;
  school_grade: string;
  desired_university: string;
  trainer_department_type: string; // ex: "その他（看護・医療・家政・スポーツ・芸術系）"
  trainer_department_name: string; // ex: "農学・獣医畜産学・水産学系統"
  trainer_university: string; // ex: "名古屋工業大学"
  trainer_club_type: string; //  ex: "文化系"
  trainer_club_name: string; // ex: "コンピューター・グラフィックス　イラスト・アニメーション同好会"

  recommend_english: number;
  recommend_math: number | null;
  recommend_physics: number | null;
  recommend_chemistry: number | null;
  recommend_classical_japanese: number | null;
  recommend_world_history: number | null;
  recommend_japanese_history: number | null;
  recommend_math_basic: number | null;
  recommend_english_basic: number | null;
  recommend_biology: number | null;
  recommend_english_words: number | null;
  recommend_new_math: number | null;
  recommend_junior_english: number | null;
  recommend_junior_math: number | null;
  recommend_junior_science: number | null;
  recommend_modern_japanese: number | null;
  recommend_new_chemistry_2024: number | null;
  recommend_new_biology_2024: number | null;

  credit_updated_at: string; // カード情報更新日
  trial_started_at: string; // トライアル申込日
  trial_expired_on?: string; // トライアルサービス利用終了日
  trial_days?: number; // トライアルサービス日数
  contract_month: number; // 当月質問回数
  current_points: number;

  parent: ParentUser;

  trainer?: {
    login: string;
    full_name: string; // 氏名
    full_name_read: string; // 氏名カナ
    gender: string;
    tel: string;
    email: string;
    nick_name: string;
    school_grade: string;
    rank: number; // TODO: 確認
    users_max: number; // TODO: 確認
    english_four_factor: string; // TODO: 確認
    university_name: string; // ex: 名古屋工業大学
    passed_university: string; // ex: 関西学院大学",
    prefecture_name: string; // ex: 大阪府"
    graduated_high_school_type: string;
    graduated_high_school_name: string;
    club_activity_type: string;
    club_activity_name_in_high_school: string; // ex: "バトントワリング部",
    department_type: string; // ex: 工学系統",
    department_name: string;
    passed_department_type: string;
    passed_department_name: string;
    examination_subject: string; // ex: 英語、数学"
  };

  plan: {
    id: number;
    name: string;
  };

  corporation: {
    name: string;

    available_curriculums: {
      chemistry: boolean;
      classical_japanese: boolean;
      english: boolean;
      math: boolean;
      physics: boolean;
      japanese_history: boolean;
      world_history: boolean;
      english_basic: boolean;
      math_basic: boolean;
      biology: boolean;
      english_words: boolean;
      new_math: boolean;
      junior_english: boolean;
      junior_math: boolean;
      junior_science: boolean;
      modern_japanese: boolean;
      new_chemistry_2024: boolean;
      new_biology_2024: boolean;
    };

    available_services: {
      questions: boolean;
    };
  } | null;

  visible_curriculums: {
    id: number;
    code: string;
    name: string;
  }[];

  attended_curriculums: {
    id: number;
    code: string;
    name: string;
  }[];
}

export type ParentUser = UserBase & {
  relation: string;
  postal_code: string; // 郵便番号
  prefecture: string; // 都道府県
  municipality: string; // 市区町村
  address: string; // 住所
}

export type TrainerUser = UserBase & {
  corporation: {
    name: string;
  } | null;
}

export type InstructorUser = UserBase & {
  tel: string;
}

export type CreateSessionParams = {
  ui: LoginableUIType;
  login: string,
  password: string
}

export type CurrentUser = {
  student?: StudentUser;
  trainer?: TrainerUser;
  instructor?: InstructorUser;
  parent?: ParentUser;
}

export type Session = {
  student?: StudentUser;
  trainer?: TrainerUser;
  instructor?: InstructorUser;
  parent?: ParentUser;
  jwt: string;
  exp: number;
}

export type SignupParentParams = {
  full_name: string; // 氏名
  full_name_read: string; // 氏名カナ
  email: string; // メールアドレス
  postal_code: string; // 郵便番号
  prefecture: string; // 都道府県
  municipality: string; // 市区町村
  address: string; // 住所
}

export type SignupParams = {
  full_name: string; // 氏名
  full_name_read: string; // 氏名カナ
  birthday: string; // 生年月日(yyyy-MM-dd)
  gender: string; // 性別
  email: string; // メールアドレス

  high_school_name: string; // 在籍高校（卒業高校）
  school_grade: string; // 学年
  nick_name: string; // ニックネーム

  // トレーナー希望情報
  // 志望学部系統（文・理・総合・その他）
  trainer_department_type?: string;
  // 志望学部
  trainer_department_name?: string;
  // 希望するトレーナーの在籍または合格大学
  trainer_university?: string;
  // 部活
  trainer_club_type?: string;
  // 部活名（任意）（部活が運動系、文化系の場合必須）
  trainer_club_name?: string;

  trainer_contracted: boolean; // トレーナーサービスを利用するか

  // トレーナーに求める条件（第１希望）(トレーナーの在籍大学・合格大学, 学部, 部活)（任意）
  first_request?: string;
  // トレーナーに求める条件（第2希望）(トレーナーの在籍大学・合格大学, 学部, 部活)（任意）
  second_request?: string;
  // トレーナーに求める条件（第3希望）(トレーナーの在籍大学・合格大学, 学部, 部活)（任意）
  third_request?: string;

  // 学習プラン（学習範囲）
  recommend_english: number;
  recommend_math: number | null;
  recommend_physics: number | null;
  recommend_chemistry: number | null;
  recommend_classical_japanese: number | null;
  recommend_world_history: number | null;
  recommend_japanese_history: number | null;
  recommend_biology: number | null;
  recommend_english_words: number | null;
  recommend_new_math: number | null;
  recommend_junior_english: number | null;
  recommend_junior_math: number | null;
  recommend_junior_science: number | null;
  recommend_modern_japanese: number | null;
  recommend_new_chemistry_2024: number | null;
  recommend_new_biology_2024: number | null;

  plan_id: number; // 契約プラン（マスタAPI plansのid）
  // 外部連携key(ex: moshinavi)
  external_alliance?: string;

  // 保護者情報
  parent: SignupParentParams;
}

export type GetCurrentUserParams = {
  ui: LoginableUIType;
}

export type CreatePasswordNotificationParams = {
  ui: LoginableUIType;
  login?: string; // MARK: studentui, parentuiの場合はloginが必須
  email: string;
}

export type CreatePasswordResetterParams = {
  ui: LoginableUIType;
  token: string;
  password: string;
}

export type ChangePasswordParams = {
  ui: LoginableUIType;
  password: string;
}

export type CreateSmsNotificationParams = {
  tel: string;
}

export type Info = {
  id: number;
  published_from: string;
  title: string;
  body: string;
}

export type InfoListItem = {
  id: number;
  title: string;
  published_from: string;
};

export type GetRecordsParams = {
  page: number;
  per_page: number;
}

export type InfoRecords = {
  records: InfoListItem[];
  total_page: number;
  total_count: number;
}

export type Schedule = {
  id: number;
  body: string;
  date: string;
}

export type ScheduleRecords = {
  records: Schedule[];
  total_page: number;
  total_count: number;
}

export type RecommendStudy = {
  curriculum: string;
  title: string;
  description: string;
  type: string;
  content_id: number;
  course_name: string;
  unit_name: string;
  rank: number;
  pdf_ready?: boolean;
}

export type RecommendStudyRecords = {
  records: RecommendStudy[];
  total_page: number;
  total_count: number;
}

export type RecommendStudy2 = {
  rank: number;
  content: {
    id: number;
    type: string;
    curriculum_code: string;
    meta?: ContentMeta;
    learning_count?: number;
    pdf_ready?: boolean;
  }
  unit: {
    id: number;
    name: string;
    understanding: number;
    course_name: string;
  }
  topic: {
    description: string;
  }
  recently_learning_status?: {
    units: {
      name: string;
      shortName?: string;
      understanding: number;
      currently_recommend: boolean;
    }[]
    topics: {
      title: string;
      shortTitle?: string;
      understanding: number;
      currently_recommend: boolean;
    }[]
  } | null
}

export type RecommendStudyRecords2 = {
  curriculum: string;
  contents: RecommendStudy2[];
}

/*
status | ステータス名
--: | -- |
1 | 未対応
2 | 対応中
3 | 回答済み

※"事務局対応中"はフラグ管理

画面表記

受講生側 | 講師側
-- | --
質問中 | 未対応
質問中 | 対応中
質問中 | 事務局対応中＆未対応
回答済み | 回答済み
*/
export type Question = {
  id: number;
  status: number;
  support_required: boolean; // 事務局対応フラグ
  statusText?: string; // MARK: 受講生|講師 毎表記
  statusCode?: string; // MARK: 受講生|講師 毎コード
  created_at: string;
  answer_beginning_at?: string;
  answer_finished_at?: string;
  content: {
    id: number;
    title: string;
    meta?: ContentMeta;
    launch_parameters: {
      m_type: number;
      level: number;
      curriculum_code: string;
    };
    topic: {
      id: number;
      title: string;
    }
  }
  student: Pick<StudentUser, 'id' | 'nick_name'>;
  instructor?: Pick<InstructorUser, 'id' | 'nick_name'>;
}

export type QuestionDetail = Question & {
  body: string;
  answer?: string;

  body_attachment?: {
    url: string;
    mime_type: string;
    file_name: string;
    file_size: number;
  };

  answer_attachment?: {
    url: string;
    mime_type: string;
    file_name: string;
    file_size: number;
  };
}

export type QuestionRecords = {
  records: Question[];
  total_page: number;
  total_count: number;
}

export type StudyLog = {
  id: number;
  content: {
    id: number;
    title: string;
    meta?: ContentMeta;
    launch_parameters: {
      m_type: number;
      level: number;
      curriculum_code: string;
      pdf_ready?: boolean;
    };
    topic: {
      id: number;
      title: string;
    },
  }
  correct_answers?: number;
  questions?: number;
  created_at: string;
  updated_at: string;
}

export type StudyLogRecords = {
  curriculum: {
    id: number;
    name: string;
  };
  records: StudyLog[];
  total_page: number;
  total_count: number;
}

export type Course = {
  id: number;
  name: string;
}

export type CourseRecords = {
  records: Course[];
  total_page: number;
  total_count: number;
}

export type Unit = {
  id: number;
  course_id: number;
  name: string;
  score: number;
}

export type UnitRecords = {
  records: Unit[];
  total_page: number;
  total_count: number;
}

export type Topic = {
  id: number;
  title: string;
  level: string;
}

export type TopicRecords = {
  records: Topic[];
  total_page: number;
  total_count: number;
}

export type CreateQuestionParams = {
  question: {
    content_id: number;
    body: string;
    body_attachment?: {
      encoded_file: string;
      mime_type: string;
      file_name: string;
      file_size: number;
    };
  }
}

export type CreateQuestionAnswerParams = {
  id: number;
  question: {
    answer: string;
    answer_attachment?: {
      encoded_file: string;
      mime_type: string;
      file_name: string;
      file_size: number;
    };
  }
}

export type CreateQuestionPendingAnswerParams = {
  id: number;
  comment: string;
}

export type UpdateStudentProfileParams = {
  email: string;
  postal_code: string;
  prefecture: string;
  municipality: string;
  address: string;
  high_school_kind: string;
  high_school_name: string;
  school_grade: string;
  desired_university: string;
  trainer_department_type: string;
  trainer_department_name: string;
  trainer_university: string;
  trainer_club_type: string;
  trainer_club_name: string;
  recommend_english: number | null;
  recommend_math: number | null;
  recommend_physics: number | null;
  recommend_chemistry: number | null;
  recommend_classical_japanese: number | null;
  recommend_world_history: number | null;
  recommend_japanese_history: number | null;
  recommend_math_basic: number | null;
  recommend_english_basic: number | null;
  recommend_biology: number | null;
  recommend_english_words: number | null;
  recommend_new_math: number | null;
  recommend_junior_math: number | null;
  recommend_junior_english: number | null;
  recommend_junior_science: number | null;
  recommend_modern_japanese: number | null;
  recommend_new_chemistry_2024: number | null;
  recommend_new_biology_2024: number | null;
  plan_id?: number;
}

export type UpdateTrainerProfileParams = {
  email: string;
  tel: string;
}

export type UpdateInstructorProfileParams = {
  email: string;
}

export type UpdateParentProfileParams = {
  email: string;
  postal_code: string;
  prefecture: string;
  municipality: string;
  address?: string;
}

export type StudentMessage = {
  id: number;
  body: string;
  trainer?: {
    nick_name: string;
  };
  created_at: string;
}

export type StudentMessageRecords = {
  records: StudentMessage[];
  total_page: number;
  total_count: number;
}

export type CreateStudentMessageParams = {
  student_id: number;
  body: string;
}

export type StudentMypage = {
  curriculums: {
    id: number;
    name: string;
    recommend: string | null;
    understanding: number;
    al_engine_connected: boolean;
  }[];
  schedule: {
    current?: {
      id: number;
      acted_on: string;
      body: string | null;
    };
    next?: {
      id: number;
      acted_on: string;
      body: string | null;
    };
    prev?: {
      id: number;
      acted_on: string;
      body: string | null;
    };
  };
  trainer_consultation: boolean;
  questions: {
    id: number;
    status: string;
    title: string;
    answer: string | null;
    last_updated_at: string;
  }[];

  admin_informations: InfoListItem[];
}

export type StudentStudyCurriculum = {
  courses: {
    id: number;
    title: string;
    completion_test: boolean;
  }[];
}

export type StudentStudyCourse = {
  curriculum: {
    id: number;
    name: string;
    al_engine_connected: boolean;
  };
  course: {
    id: number;
    name: string;
  };
  units: {
    id: number;
    name: string;
    understanding: number;
    pretest: boolean;
    posttest: boolean;
  }[];
}

export type StudentStudyUnit = {
  curriculum: {
    id: number;
    name: string;
    al_engine_connected: boolean;
  };
  course: {
    id: number;
    name: string;
  };
  unit: {
    id: number;
    name: string;
  };
  topics: {
    id: number;
    title: string;
    check_level: string | null;
    status: string | null;
    pdf_ready?: boolean;
  }[];
}

export type StudentStudyUnitResult = {
  curriculum: {
    id: number;
    name: string;
  };
  course: {
    id: number;
    name: string;
  };
  unit: {
    id: number;
    name: string;
  };
  result: {
    pre_score: number;
    pre_total: number;
    pre_rank?: string;
    pre_acted_at: string;
    post_score: number;
    post_total: number;
    post_acted_at: string;
    post_rank?: string;
  };
}

export type ContentMeta = {
  label: string;
  type: string;
  isVideo: boolean;
}
export type StudentStudyTopic = {
  curriculum: {
    id: number;
    name: string;
  };
  course: {
    id: number;
    name: string;
  };
  unit: {
    id: number;
    name: string;
  };
  topic: {
    id: number;
    title: string;
  };
  contents: {
    id: number;
    position: number;
    title: string;
    iri: string;
    activity_type: string;
    launch_method: string;
    launch_url: string;
    move_on: string;
    meta?: ContentMeta;
    learning_count?: number;
    launch_parameters: {
      m_type: number;
      level: number;
      curriculum_code: string;
      pdf_ready?: boolean;
    };
  }[];
}

export type UpdateScheduleParams = {
  id: number;
  body: string;
}

export type TrainerMypage = {
  students: (StudentUser & { student_consultation: boolean; })[];
  admin_informations: InfoListItem[];
}

// TODO: studentと共通化できそうなら共通化
export type OwnStudentStudyCurriculums = {
  curriculums: {
    id: number;
    name: string;
    understanding: number;
    al_engine_connected: boolean;
  }[];
}

export type OwnStudentStudyCurriculum = {
  courses: {
    id: number;
    title: string;
    completion_test: boolean;
  }[];
}

export type OwnStudentStudyCourse = {
  curriculum: {
    id: number;
    name: string;
    al_engine_connected: boolean;
  };
  course: {
    id: number;
    name: string;
  };
  units: {
    id: number;
    name: string;
    understanding: number;
    pretest: boolean;
    posttest: boolean;
  }[];
}

export type OwnStudentStudyUnit = {
  curriculum: {
    id: number;
    name: string;
    al_engine_connected: boolean;
  };
  course: {
    id: number;
    name: string;
  };
  unit: {
    id: number;
    name: string;
  };
  topics: {
    id: number;
    title: string;
    check_level: string | null;
    status: string | null;
    pdf_ready?: boolean;
  }[];
}

export type OwnStudentStudyTopic = {
  curriculum: {
    id: number;
    name: string;
  };
  course: {
    id: number;
    name: string;
  };
  unit: {
    id: number;
    name: string;
  };
  topic: {
    id: number;
    title: string;
  };
  contents: {
    id: number;
    position: number;
    title: string;
    iri: string;
    activity_type: string;
    launch_method: string;
    launch_url: string;
    move_on: string;
    meta?: ContentMeta;
    launch_parameters: {
      m_type: number;
      level: number;
      curriculum_code: string;
      pdf_ready?: boolean;
    };
  }[];
}

export type OwnStudentStudyUnitResult = {
  curriculum: {
    id: number;
    name: string;
  };
  course: {
    id: number;
    name: string;
  };
  unit: {
    id: number;
    name: string;
  };
  result: {
    pre_score: number;
    pre_total: number;
    pre_rank?: string;
    pre_acted_at: string;
    post_score: number;
    post_total: number;
    post_acted_at: string;
    post_rank?: string;
  };
}

// TODO END

export type RequestTrainerParams = {
  first_request: string; //トレーナー第1希望
  second_request: string; //トレーナー第2希望
  third_request: string; //トレーナー第3希望
}

export type ParentMypage = {
  curriculums: {
    id: number;
    name: string;
    understanding: number;
    al_engine_connected: boolean;
  }[];

  admin_informations: InfoListItem[];
}

export type StudentUnsubscribeParams = {
    unsubscribe_questionnaire: {
      reason_for_unsubscribe: boolean[];
      reason_for_unsubscribe_text?: string;

      purpose_of_use: boolean[];
      purpose_of_use_text?: string;

      used_function: boolean[];
      used_function_text?: string;

      used_subject: boolean[];
      used_subject_text?: string;

      teaching_materials_satisfaction: number;
      teaching_materials_satisfaction_text?: string;

      recommend_satisfaction: number;
      recommend_satisfaction_text?: string;

      instructor_satisfaction: number;
      instructor_satisfaction_text?: string;

      trainer_satisfaction: number;
      trainer_satisfaction_text?: string;

      ux_satisfaction: number;
      ux_satisfaction_text?: string;

      price_satisfaction: number;
      price_satisfaction_text?: string;

      comprehensive_satisfaction: number;
      comprehensive_satisfaction_text?: string;

      introduction: number;
      introduction_text?: string;
    }
}

export type ParentPaymentLog = {
  id: number;
  name: string;
  status: number;
  statusName?: string;
  statusCode?: string;
  price: number;
  created_at: string;
}

export type ParentPaymentLogs = ParentPaymentLog[];

export type InstructorMypage = {
  questions: {
    status: number;
    items: Question[];
  }[];

  admin_informations: InfoListItem[];
}

export type StudentPlan = {
  id: number;
  name: string;
  price: number;
  discount_points: number;
  expired_on: string;
  next_payment_on: string;
}

export type StudentPointExchangeParams = {
  item_ordered: number;
  number_ordered: number;
  request_hash?: string;
}

export type StudentPointExchangeRecord = {
  point_exchange: {
    item_ordered: number;
    item_ordered_text: string;
    number_ordered: number;
    points_used: number;
    request_hash?: string;
  }
}

export type PointExchangeLog = {
  id: number;
  exchanged_on: string;
  prize_text: string;
  points_used: number;
}

export type PointExchangeLogsRecords = {
  records: PointExchangeLog[];
  total_page: number;
  total_count: number;
}

export type StudyAchivementRecord = {
  id: number;
  day1: number;
  day2: number;
  day3: number;
  day4: number;
  day5: number;
  day6: number;
  day7: number;
  start_week_on: string;
  daily_goal_time: number;
  total_learning_time: number;
  continuation_started_on: string;
  continuation_days_mybest: number;
  // created_at: '2020-07-27T03:00:00+09:00',
  // updated_at: '2020-08-02T03:00:00+09:00',
}

export type StudyCourseResultsRecord = {
  curriculum: {
    id: number,
    name: string,
  },
  course: {
    id: number,
    name: string,
  },
  results: {
    unit: {
      id: number,
      name: string,
      rank: string | null,
      status: string | null,
      pretest: boolean | null,
      content_id: number | null,
    }
  }[];
}

export type StudyUnitResultsRecord = {
  curriculum: {
    id: number,
    name: string,
  },
  course: {
    id: number,
    name: string,
  },
  unit: {
    id: number,
    name: string,
    status: string | null,
    content_id: number,
  },
  results: {
    pretest: {
      acted_at: string,
      total: number,
      correct: number,
      understanding: number,
      rank: string,
    } | null,
    posttest: {
      acted_at: string,
      total: number,
      correct: number,
      understanding: number,
      rank: string,
    }[] | null
  }
  total_page: number,
  total_count: number,
}

export type StudyCourseCompletionTestUnitRecord = {
  unit: {
    id: number
    name: string
    content_id: number
    available: boolean
    attend: boolean
  }
}

export type StudyCourseCompletionTestsRecord = {
  curriculum: {
    id: number
    name: string
  }
  course: {
    id: number
    name: string
  }
  results: StudyCourseCompletionTestUnitRecord[] | null
}

export type StudyCourseCompletionTestResultRecord = {
  score: number;
  perfect_score: number;
  expected_min_score: number | null;
  expected_max_score: number | null;
  created_at: string;
}

export type StudyCourseCompletionTestResultsRecord = {
  curriculum: {
    id: number
    name: string
  }
  course: {
    id: number
    name: string
  }
  unit: {
    id: number
    name: string
    content_id: number;
  }
  results: StudyCourseCompletionTestResultRecord[]
  total_page: number,
  total_count: number,
}

// 進学状況収集関連

export type BorderResultsUniversitiesRecord = {
  category: string;
  area: string;
  results: {
    code: string;
    name: string
  }[]
}

export type BorderResultsFacultiesRecord = {
  area: string;
  university: string;
  results: {
    code: string;
    name: string;
  }[]
}

export type BorderResultsDepartmentsRecord = {
  university: string;
  faculty: string;
  results: {
    code: string;
    name: string;
  }[]
}

export type BorderResultsRegisterParams = {
  token: string;
  answer: {
    category: number;
    area: string;
    university: string;
    faculty?: string;
    department?: string;
    result: number
  }
}

// トレーナーCSVエクスポート関連

export type CsvExportHistoryRecord = {
  id: number;
  data_type: string;
  curriculum: string;
  status: string;
  reserved_at: string;
  download_url?: string;
  started_at?: string;
  finished_at?: string;
}

export type CsvExportHistoriesRecord = {
  histories: CsvExportHistoryRecord[];
  total_page: number;
  total_count: number;
}

export type SchoolClassRecord = {
  id: number;
  name: string;
}

export type StudentStudySummaryPerCurriculumRecord = {
  id: number;
  login: string;
  nick_name: string;
  school_grade: string;
  school_class: string | null;
  curriculums: {
    id: number;
    name: string;
    understanding: number;
    al_engine_connected: boolean;
  }[];
}

export type StudentStudySummaryPerUnitRecord = {
  id: number;
  login: string;
  nick_name: string;
  school_grade: string;
  school_class: string | null;
  curriculum: {
    id: number;
    name: string;
    understanding: number;
    al_engine_connected: boolean;
  };
  units: {
    id: number;
    name: string;
    understanding: number;
  }[]
}

export type StudentStudySummaryPerUnitRecords = {
  students: StudentStudySummaryPerUnitRecord[]
  total_page: number,
  total_count: number,
}

export type StudentStudySummaryPerCurriculumRecords = {
  students: StudentStudySummaryPerCurriculumRecord[]
  total_page: number,
  total_count: number,
}

export type StudentStudyLearningCountRecord = {
  curriculum_code: string | null;
  learning_count: number | null;
} | null

export type StudentStudyLearningCountRecords = StudentStudyLearningCountRecord[] | null; ;

export type StudentStudyLearningCountParams = string[];

export type StudentStudyTopicWithStudyCount = {
  curriculum: {
    id: number;
    name: string;
  };
  course: {
    id: number;
    name: string;
  };
  unit: {
    id: number;
    name: string;
  };
  topic: {
    id: number;
    title: string;
  };
  contents: {
    id: number;
    position: number;
    title: string;
    iri: string;
    activity_type: string;
    launch_method: string;
    launch_url: string;
    move_on: string;
    meta?: ContentMeta;
    learning_count?: number;
    launch_parameters: {
      m_type: number;
      level: number;
      curriculum_code?: string;
      pdf_ready? :boolean
    };
  }[] ; 
}

export type LearningCountRecord =   {
  content: {
    id: number,
    title: string,
    launch_parameters: {
      m_type: number,
      level: number,
      curriculum_code: string
    },
    learning_count: number,
    pdf_ready?: boolean
  }
}
// issue-547
export type Bookmark = {
    id: number,
    updated_at: string,
    curriculum_name: string,
    course_name: string,
    unit_name: string,
    topic_title: string,
    content: {
      id: number,
      type: string,
      curriculum_code: string,
    },
  }

export type LearningCountRecords = {contents: LearningCountRecord[]}

export type EntrustCurrentRecord = {
      curriculum:  string,
      m_entrust: string,
      content: {
        id: number,
        type: string,
        curriculum_code: string,
        pdf_ready?: boolean
      },
      unit: {
        id: number,
        name: string,
        understanding: number,
        course_name: string
      },
      topic: {
        description: string
      },
    }

export type EntrustCurrentRecords = {
  entrust_current: EntrustCurrentRecord[]
}

export type EntrustBaseTopicRecord = {
  entrust: {
    curriculum: string,
    m_entrust: string,
    content: {
      id: number,
      type: string,
      curriculum_code: string,
      pdf_ready?: boolean
    },
    unit: {
      id: number,
      name: string,
      course_name: string
    },
    topic: {
      description: string
    }
  }
}
  
export type BookmarksResponse = { bookmarks: Bookmark[] } 

export type Bookmarks  = Bookmark[] | null

export type BookmarksWithMeta = BookmarkWithMeta[] | null

export type BookmarkWithMeta = {
  id : number,
  updated_at: string,
  curriculum_name: string,
  course_name: string,
  unit_name: string,
  topic_title: string,
  content: {
    id: number;
    type: string;
    curriculum_code: string;
    meta?: ContentMeta;
    learning_count?: number;
  }
}

export type DeleteBookmarks = Bookmark[]

export type BookmarksDeleteParams = number[]

//export type LearningCountRecords = {contents: LearningCountRecord[]}

export type StudentBookmarksParams = {
  student_id: string,
  curriculum_id?: string,
}

export type BookmarkDeleteResponse = {
    id : number,
    success : boolean,
     errors: string[]
  }

 export type BookmarksDeleteResponse = BookmarkDeleteResponse[] 

 export type StudentBookmarksLearningCount ={
    student: {
      id: number
      login: string,
      nick_name: string,
      corporation_name: string
    },
    contents: LearningCountRecord[]
  }

export type PdfDownloadParams = {
  curriculum_code_string: string,
}

export type PdfDownloadResponse = {
  url: string,
}
 
  export type FetchStudyStatusParams = {
    school_class_id?: number | null,
    curriculum_id?: number | null,
    beginning_date_on?: string | null,
    end_date_on?: string | null,
  }
  
export type  StudyStatusType = {
  student: {
    id: number,
    login: string,
    nick_name: string,
    school_grade: string
  },
  status: {
    total_answer_time_minutes: number,
     total_answer_count: number,
     correct_answer_percentage: number,
     total_view_count: number
  }
}[]

  export type  StudyStatus = {
    students: StudyStatusType
  }
  
  export type FetchStudyStatusRealtimeParams = {
    school_class_id?: number | null,
    beginning_date_at?: string | null,
  }
  export type Currently_learning = {
    curriculum: {
      id: number,
      name: string,
    },
    course: {
      id: number,
      name: string,
    },
    unit: {
      id: number,
      name: string,
    },
    topic: {
      id: number,
      title: string,
    },
    content: {
      id: number,
      title: string,
    },
    started_at: string,
  }
  export type StudyStatusRealtimeType = {
    student: {
      id: number;
      login: string;
      nick_name: string;
      school_grade: string;
    };
    status: {
      total_answer_count: number;
      correct_answer_percentage: number;
      total_view_count: number;
      recent_answer_results: boolean[];
      count_total: number;
      currently_learning: Currently_learning;
    };
  }[];

  export type StudyStatusRealtime = {
    students : StudyStatusRealtimeType
  }

  export type StudyStatusRealtimeGlobalStateType = StudyStatusRealtime & {
    students: {
        status: {
          count_total: number
        }
    }[]
  }

  export type StudyAchievementsLatest =  {
    study_achievements: {
      recently_studied_at: string,
      consecutive_correct_answer_count: number
    }
  }

  export type TrainerStudyAchievementsLatest =  {
    student: {
        id: number,
        login: string,
        nick_name: string,
        school_grade: string
    },
    study_achievements: {
        recently_studied_at: string,
        consecutive_correct_answer_count: number
    }
  }

  export type FetchTrainerStudyAchievementsLatestParams = {
    student_id: number
  }

