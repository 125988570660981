import { call, put, select, takeEvery } from 'redux-saga/effects'

import * as apiService from 'services/api'
import * as Models from 'models/api'
import infoActions from 'actions/infoActions'
import uiActions from 'actions/uiActions'
import { AppState } from 'store';

export function* fetchRecords(action: ReturnType<typeof infoActions.fetchRecords>) {
  const state: AppState = yield select()
  const initialize = action.payload.initialize
  try {
    const per_page = 10
    let page = state.info.list.page + 1
    if (initialize) {
      page = 1
    }
    const ui = state.ui.type! as Models.LoginableUIType
    const params = {
      ui,
      page,
      per_page,
    }
    const response: apiService.getInfoRecordsResponse = yield call(
      apiService.getInfoRecords,
      yield select(x => x.repository.authorizedApi!), params);
    const records = response.data
    yield put(infoActions.setRecords({ page, records }));
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

export function* fetchDetailRecord(action: ReturnType<typeof infoActions.fetchDetailRecord>) {
  const state: AppState = yield select()
  try {
    const ui = state.ui.type! as Models.LoginableUIType
    const id = action.payload.id
    const params = {
      ui,
      id,
    }
    const response: apiService.getInfoResponse = yield call(
      apiService.getInfo,
      yield select(x => x.repository.authorizedApi!), params);
    const record = response.data
    yield put(infoActions.setDetailRecord({ id, record }));
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

function* infoSaga() {
  yield takeEvery(infoActions.fetchRecords, fetchRecords);
  yield takeEvery(infoActions.fetchDetailRecord, fetchDetailRecord);
}

export default infoSaga;
