import { call, put, takeEvery, select } from 'redux-saga/effects'

import * as apiService from 'services/api'
import uiActions from 'actions/uiActions'
import { AppState } from 'store';
import { StudentUser } from 'models/api'

const delay = (ms: number) => new Promise(res => setTimeout(res, ms))

function* showFlashMessage(action: ReturnType<typeof uiActions.showFlashMessage>) {
  yield call(delay, 500);
  const message = action.payload.message;
  yield put(uiActions.showNotification({
    level: 'success',
    message,
  }));
}

export function* fetchMasterData() {
  const state: AppState = yield select()
  const ui = state.ui.type!
  const session = state.session

  if (ui === 'student' && session.user) {
    const studentUser = session.user as StudentUser
    if (studentUser.status === 'suspension') {
      // MARK: suspension statusのユーザはmasterData apiの実行権限がない
      return
    }
  }

  // MARK: トレーナー、講師は今の所マスターデータ不要
  if (ui === 'signup' || ui === 'student' || ui === 'parent') {
    const response: apiService.getMasterDataResponse = yield call(
      apiService.getMasterData,
      state.repository.authorizedApi || state.repository.api!,
      { ui });
    const records = response.data
    yield put(uiActions.setMasterData({ records }));
  }
}

export function* fetchMasterDataAction(action: ReturnType<typeof uiActions.fetchMasterData>) {
  try {
    yield fetchMasterData()
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

function* uiSaga() {
  yield takeEvery(uiActions.showFlashMessage, showFlashMessage);
  yield takeEvery(uiActions.fetchMasterData, fetchMasterDataAction);
}

export default uiSaga;
