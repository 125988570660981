import actionCreatorFactory from 'typescript-fsa';

import * as Models from 'models/api'

const actionCreator = actionCreatorFactory('ui');

export type showNotificationPayload = {
  level: 'success' | 'error' | 'warning' | 'info',
  message: string,
}

export type showFlashMessagePayload = {
  message: string,
}

export type showApiErrorNotificationPayload = unknown

const uiActions = {
  setType: actionCreator<Models.UIType>('SET_TYPE'),
  setEnv: actionCreator<string>('SET_ENV'),
  showNotification: actionCreator<showNotificationPayload>(
    'SHOW_NOTIFICATION'),
  showApiErrorNotification: actionCreator<showApiErrorNotificationPayload>(
    'SHOW_API_ERROR_NOTIFICATION'),
  showFlashMessage: actionCreator<showFlashMessagePayload>(
    'SHOW_FLASH_MESSAGE'),
  closeNotification: actionCreator<void>('CLOSE_NOTIFICATION'),
  fetchMasterData:
    actionCreator<void>(
      'FETCH_MASTER_DATA'),
  setMasterData:
    actionCreator<{ records: Models.MasterData }>(
      'SET_MASTER_DATA'),
  setOpenRecommendNav:
    actionCreator<boolean>(
      'SET_OPEN_RECOMMEND_NAV'),
  setOpenStudyNav:
    actionCreator<boolean>(
      'SET_OPEN_STUDY_NAV'),
  setOpenHistoryNav:
    actionCreator<boolean>(
      'SET_OPEN_HISTORY_NAV'),
  setOpenTestNav:
    actionCreator<boolean>(
      'SET_OPEN_TEST_NAV'),
  // issue-545    
  setOpenEntrustNav:
    actionCreator<boolean>(
      'SET_OPEN_ENTRUST_NAV'),
  // issue-545    
};

export default uiActions;