import React, { useEffect, useState } from 'react';
import { Link, Redirect, useParams, } from 'react-router-dom';

import PrivateHeader from 'containers/parts/PrivateHeader';
import { mappedProps } from 'containers/student/StudyCourseResult';
import SiteMain from 'containers/parts/SiteMain';
import classNames from 'classnames';
import { UnitTestBoxItem } from './study_course_result/UnitTestBoxItem';
import { CourseCompletionTestBox } from './study_course_result/CourseCompletionTestBox';
import CorrectAnswerCount from 'components/student/common/CorrectAnswerCount';

const StudyCourseResult: React.FC<mappedProps> = (props: mappedProps) => {
  const { curriculum_id, course_id } = useParams<{ curriculum_id: string; course_id: string }>();

  const [disabledStartStudy, setDisabledStartStudy] = useState<boolean>(false);
  const curriculumObj = props.getCurriculum(curriculum_id)

  useEffect(() => {
    if (curriculumObj) {
      props.fetchData(curriculumObj.id, course_id ? Number(course_id) : null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curriculumObj, course_id]);

  useEffect(() => {
    props.fetchStudyAchievementsLatest();
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  , []);

  const courses = props.getCourses(Number(curriculum_id))
  const courseId = Number(course_id) || ( courses && courses[0].id )
  const record = courseId && props.getRecord(courseId)
  const testRecord = courseId && props.getCompletionTest(courseId)

  // MARK: 単元テスト、科目修了どちらもなければ遷移不可
  if (!curriculumObj || ( !curriculumObj.has_completion_test && !curriculumObj.al_engine_connected )) {
    return (
      <Redirect to="/" />
    )
  }

  const startStudy = ({ type, unit_id, content_id, }: { content_id: number, unit_id: number, type?: 'completion_test'; }) => {
    if (disabledStartStudy) return

    let return_pathname = `/study/unit_results/${unit_id}`
    if (type === 'completion_test') {
      return_pathname = `/study/course_complete_results/${unit_id}`
    } 
    setDisabledStartStudy(true)
    props.startStudy({ content_id, return_pathname }).then((url) => {
      setDisabledStartStudy(false)
      window.location.href = url
    })
  }

  return (
    <React.Fragment>
      <PrivateHeader />
      <SiteMain>
        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          <li className="breadcrumbs__item">
            { `科目、単元テスト一覧 （${curriculumObj.name}）`}
          </li>
        </ol>

        <div className="site-main__main-contents">
          <div className="page-title page-title--test">
            { `科目、単元テスト一覧 ${curriculumObj.name}`}
            <div className="page-title__correct-count">
              {props.student.studyAchievementsLatest.record && <>
                <CorrectAnswerCount correctAnswerCount={props.student.studyAchievementsLatest.record.study_achievements.consecutive_correct_answer_count}/>
              </>}
            </div>
          </div>
          <div className="test-tab">
            {
              courses && courses.filter(x => {
                // MARK: 通常教科はすべて表示
                // MARK: ドリル教科は（単元テストがないので）科目修了テストがあれば表示
                if (curriculumObj.al_engine_connected) { return true }
                return x.completion_test
              }).map(course => (
                <div
                  key={course.id}
                  className={classNames("test-tab__item", { 'is-active' : course.id === courseId })}
                  onClick={e => {
                    e.preventDefault()
                    props.history.push(`/study/course_results/${curriculumObj.id}/${course.id}`)
                  }}
                >
                  { course.title }
                </div>
              ))
            }
          </div>

          {
            testRecord &&
            <CourseCompletionTestBox
              curriculumObj={curriculumObj}
              testRecord={testRecord}
              disabledStartStudy={disabledStartStudy}
              startStudy={startStudy} />
          }

          {
            record &&
            <div className="test-box">
              <div className="test-box__title">
                単元テスト
              </div>
              <div className="test-box__body">
                {
                  record.results.map(result => (
                    <UnitTestBoxItem
                      key={result.unit.id}
                      result={result}
                      disabledStartStudy={disabledStartStudy}
                      startStudy={startStudy} />
                  ))
                }
              </div>
            </div>
          }

        </div>

        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          <li className="breadcrumbs__item">
            { `科目、単元テスト一覧 （${curriculumObj.name}）`}
          </li>
        </ol>
      </SiteMain>
    </React.Fragment>
  );
}

export default StudyCourseResult
