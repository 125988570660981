import React from 'react';

import { mappedProps } from 'containers/signup/SignupStep1';

import classNames from 'classnames';
import { Field } from 'redux-form';
import { campaignPlans } from 'utils/campaign/discount2024winter'

const SignupStep1SelectPlanBox: React.FC<mappedProps> = (props: mappedProps) => {

  const selectedPlan =  campaignPlans.find(x => x.id === props.planId);
  return (
    <React.Fragment>
      <div className="register-box__subtitle">
        ご契約プラン
      </div>

      <div className="register-box__explanations">
        <div className="contract-explanations">
          <div className="contract-explanations__plan">
            <div className="contract-explanations__title">
              料金について<small className="contract-explanations__title-note">（税込）</small>
            </div>
            <div className="contract-explanations__price">
              <div className="contract-explanations__price-lead">
                特別キャンペーン期間中！！　1年（12ヶ月）、6ヶ月プランが割引価格でご利用になれます。
              </div>
              <div className="contract-explanations__price-text">
                １年（12ヶ月）プラン：31,980円（税込 35,178円）、６ヶ月プラン：19,980円（税込 21,978円）、１ヶ月プラン：3,980円（税込 4,378円）<br />
              </div>
              <div className="contract-explanations__price-note">
                ※上記お申込みプランで、<a href="https://www.kawaijukuone.co.jp/study/detail/id=73#support" className="contract-explanations__link" target="_blank" rel="noopener noreferrer" >トレーナーサービス</a>以外の河合塾Oneのサービスが、すべてご使用いただけます。<br />
                ※1ヶ月プランは割引対象外になります。予めご了承ください。<br />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="register-box__plans">
        <div className="contract-plans">
          <div className="contract-plans__title">
            プランを選択してください
          </div>
          {/* <div className="contract-plans__note">
            ※価格は税込です。
          </div> */}
          <div className="contract-plans__body">
            {
              campaignPlans.map(plan => (
                <div key={plan.id} className="contract-plans__plan">
                  <div 
                    className={classNames("contract-plan", { 'is-active': plan.id === props.planId })}
                    onClick={() => { props.change('plan_id', plan.id) }}>
                    { plan.recommend &&
                      <div className="contract-plan__recommend">＼おすすめ／</div>
                    }
                    <div className="contract-plan__title">
                      {plan.name}
                    </div>
                    <div className="contract-plan__body">
                      <div className="contract-plan__badge">
                        月あたり
                      </div>
                      <div className="contract-plan__price">
                        <b><em>{ plan.price }</em>円</b><br className='_pc' />
                        （税込 { plan.price_with_tax }円）
                      </div>
                      {
                        plan.price !== plan.lump_sum_price &&
                        <div className="contract-plan__bulk-price">
                          一括払い：<b><em>{ plan.lump_sum_price }</em>円</b><br className='_pc' />
                          （税込 { plan.lump_sum_price_with_tax }円）
                        </div>
                      }
                    </div>
                  </div>
                </div>
              ))
            }
          </div>

          {
            !props.selectedPlan &&
              <div className="contract-plans__error">
                <div className="error-message">
                  ご契約プランが選択されていません。
                </div>
              </div>
          }

        </div>
      </div>

      <div className="register-box__plans">
        <div className="contract-plans">

          <div className="contract-plans__title">
            トレーナーサービス
            &nbsp;
            <span className="contract-plans__small">
              <small>月額</small>1,000<small>円（税込 1,100円）</small>
            </span>
          </div>
          <div className="contract-plans__lead">新規ご登録完了後に申し込むことも可能です。</div>

          <div className="contract-plans__buttons">
            <div className="contract-plans__button">
              <button
                onClick={(e) => {
                  e.preventDefault()
                  props.change('trainer_contracted', true);
                }}
                className={classNames(
                  "button button--large button--no-chevron",
                  { 'button--primary': props.trainerContracted === true })}
              >申し込む</button>
            </div>
            <div className="contract-plans__button">
              <button
                onClick={(e) => {
                  e.preventDefault()
                  props.change('trainer_contracted', false);
                }}
                className={classNames(
                  "button button--large button--no-chevron",
                  { 'button--primary': props.trainerContracted === false })}
              >申し込まない</button>
            </div>
          </div>

          {
            !props.isSelectTrainerContracted &&
            <div className="contract-plans__error">
              <div className="error-message">
                トレーナーサービスが選択されていません。
              </div>
            </div>
          }

          <div className="contract-plans__foot is-active">
            <div className="contract-total-price is-active">
              <div className="contract-total-price__title">
                初回ご請求額<br /><span className="contract-total-price__title-note">（税込）</span>
              </div>
              <div className="contract-total-price__body">
                <div className="contract-total-price__price">
                  {
                    ( !selectedPlan || !props.isSelectTrainerContracted ) && 
                    <>
                      <b>0</b>円
                    </>
                  }
                  {
                    selectedPlan && props.isSelectTrainerContracted && props.trainerContracted &&
                    <>
                      <b>{ selectedPlan.selected_price }</b>円（税込 {selectedPlan.selected_price_with_tax}円）
                    </>
                  }
                  {
                    selectedPlan && props.isSelectTrainerContracted && !props.trainerContracted &&
                    <>
                      <b>{ selectedPlan.lump_sum_price }</b>円（税込 {selectedPlan.lump_sum_price_with_tax}円）
                    </>
                  }
                </div>
                <div className="contract-total-price__detail">
                  <b>基本プラン：</b><br />
                  <span>
                    { selectedPlan && props.isSelectTrainerContracted ? selectedPlan.selected_desc: '-----円' }
                  </span><br />
                  {
                    props.trainerContracted &&
                    <span>
                      <b>トレーナーサービス：</b><br />
                      {
                        props.selectedPlan ?
                        <>1,000円（税込 1,100円）【1ヶ月分】</>
                        : '-----円'
                      }
                    </span>
                  }
                </div>
                <div className="contract-total-price__note">
                  ※ 無料トライアル終了後の料金となります。<br />
                  ※ 基本プランは選択プラン期間ごとの自動更新となります。<br />
                  {
                    ( !props.isSelectTrainerContracted || props.trainerContracted === true ) && 
                    <React.Fragment>
                      ※ トレーナーサービスは月ごとの課金となります。
                    </React.Fragment>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Field component='input' type="hidden" name="plan_id" />
      <Field component='input' type="hidden" name="trainer_contracted" />

      <div className="register-box__explanations">
        <div className="contract-explanations">
          <div className="contract-explanations__plan">
            <div className="contract-explanations__title">
              初回ご請求額について
            </div>
            <div className="contract-explanations__text">
              初回ご請求額には、無料トライアル終了後のお支払について記載しています。<br />
              無料トライアル期間の終了時に、お選びいただいたご契約プランで自動更新されます。<br />
              解約はユーザー設定画面からいつでもできます。<br />
              6ヶ月プラン、12ヶ月プランをご選択いただいた場合、返金もございます。<a href="https://www.kawaijukuone.co.jp/leave/" className="contract-explanations__link" target="_blank" rel="noopener noreferrer">返金方法はこちら</a>。
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SignupStep1SelectPlanBox;
