import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import * as Models from 'models/api'

type props = {
  student: Models.StudentUser;
  type: 'questions'|'study_logs'|'study_result'
}

const InstructorStudentNav: React.FC<props> = (props: props) => {
  const {
    student,
  } = props
  return (
    <div className="trainer-nav">
      {/*
      <div className="trainer-nav__item">
        <Link to={`/students/${student.id}/study_result`}
          className={classNames("button button--no-chevron", { "button--primary": props.type === 'study_result' })}>
          学習結果</Link>
      </div>
      <div className="trainer-nav__item">
        <Link to={`/students/${student.id}/study_logs`}
          className={classNames("button button--no-chevron", { "button--primary": props.type === 'study_logs' })}>
          学習履歴</Link>
      </div>
      */}
      <div className="trainer-nav__item">
        <Link to={`/students/${student.id}/questions`}
          className={classNames("button button--no-chevron", { "button--primary": props.type === 'questions' })}>
          質問履歴</Link>
      </div>
    </div>
  );
}

export default InstructorStudentNav
