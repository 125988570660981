import React, { useEffect, } from 'react';
import { Link, useParams, matchPath, useLocation } from 'react-router-dom';

import PrivateHeader from 'containers/parts/PrivateHeader';
import { mappedProps } from 'containers/trainer/StudentArea';
import SiteMain from 'containers/parts/SiteMain';

import TrainerStudentStudentBar from '../parts/CommonStudentBar'
import TrainerStudentNav from '../parts/TrainerStudentNav'

import { SectionStudyTimeStatsBox } from '../student/SectionStudyTimeStatsBox'

type Props = {
  children?: React.ReactNode;
}

const TrainerStudentArea: React.FC<mappedProps> = (props: mappedProps & Props) => {
  const location = useLocation()
  const { id } = useParams<{ id: string; }>();

  const student = props.getStudent(Number(id))
  const studyTimeStats = props.getStudyTimeStats(Number(id))
  
  useEffect(() => {
    if (id) {
      props.fetchData(Number(id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (id) {
      props.fetchStudyAchievementsLatest(Number(id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, student]);

  // MARK: 科目修了テスト結果一覧画面では学習時間のboxを非表示
  const courseCompleteTestRoute = matchPath<{ id: string, unit_it: string }>(location.pathname, {
    path: "/students/:id/study_result/course_complete_tests/:unit_id",
    exact: true,
  });
  const showStatsBoxAndNav = !courseCompleteTestRoute

  return (
    <React.Fragment>
      <PrivateHeader />
      <SiteMain>
        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          <li className="breadcrumbs__item">
            担当受講者
          </li>
        </ol>

        <div className="site-main__main-contents">
          {
            student && 
            <React.Fragment>
              <TrainerStudentStudentBar student={student} />

              {
                showStatsBoxAndNav &&
                <>
                  {
                    studyTimeStats &&
                    <div style={{marginBottom: 24, marginTop: -12 }}>
                      <SectionStudyTimeStatsBox
                        fetchNextStudyTimeStats={props.fetchNextStudyTimeStats}
                        records={studyTimeStats.records} 
                        recentlyStudiedAt={ props.trainer.trainerStudyAchievementsLatest.records && props.trainer.trainerStudyAchievementsLatest.records.study_achievements.recently_studied_at }
                        consecutiveCorrectAnswerCount={props.trainer.trainerStudyAchievementsLatest.records && props.trainer.trainerStudyAchievementsLatest.records.study_achievements.consecutive_correct_answer_count}
                        />
                    </div>
                  }

                  <TrainerStudentNav student={student} />
                </>
              }

            </React.Fragment>
          }

          { student && props.children }

          <div className="back-home">
            <Link to='/' className="back-home__link">マイページへ戻る</Link>
          </div>
        </div>

        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          <li className="breadcrumbs__item">
            担当受講者
          </li>
        </ol>
      </SiteMain>
    </React.Fragment>
  );
}

export default TrainerStudentArea