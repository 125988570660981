import { call, put, select, takeEvery } from 'redux-saga/effects'

import * as apiService from 'services/api'
import userActions from 'actions/userActions'
import uiActions from 'actions/uiActions'
import { AppState } from 'store';
import * as Models from 'models/api'
import { updateUser } from './session'
import sessionActions from 'actions/sessionActions'

function* createSmsNotification(
  action: ReturnType<typeof userActions.createSmsNotification>) {
  const state: AppState = yield select()
  const ui = state.ui.type!
  if (ui !== 'signup') {
    throw new Error(`invalid ui : ${ui}`);
  }
  try {
    yield call(
      apiService.createSmsNotification,
        state.repository.api!,
        action.payload.params);
    action.payload.promises && action.payload.promises.resolve()
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
    action.payload.promises && action.payload.promises.reject(e)
  }
}

function* signup(action: ReturnType<typeof userActions.signup>) {
  const state: AppState = yield select()
  const ui = state.ui.type
  if (ui !== 'signup') {
    throw new Error(`invalid ui : ${ui}`);
  }
  try {
    yield call(
      apiService.signup, state.repository.api!, action.payload.params);
    action.payload.promises && action.payload.promises.resolve()
  } catch (e) {
    console.error(e);
    // MARK: formに表示する
    // yield put(uiActions.showApiErrorNotification(e));
    action.payload.promises && action.payload.promises.reject(e)
  }
}

function* createPasswordNotification(
  action: ReturnType<typeof userActions.createPasswordNotification>) {
  const state: AppState = yield select()
  const ui = state.ui.type! as Models.LoginableUIType
  if (!Models.loginableUIs.includes(ui)) {
    throw new Error(`invalid ui : ${ui}`);
  }
  try {
    yield call(
      apiService.createPasswordNotification,
        state.repository.api!,
        { ...action.payload.params, ui });
    action.payload.promises && action.payload.promises.resolve()
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
    action.payload.promises && action.payload.promises.reject(e)
  }
}

function* createStudentPasswordNotification(
  action: ReturnType<typeof userActions.createStudentPasswordNotification>) {
  const state: AppState = yield select()
  const ui = state.ui.type! as Models.LoginableUIType
  if (!Models.loginableUIs.includes(ui)) {
    throw new Error(`invalid ui : ${ui}`);
  }
  try {
    yield call(
      apiService.createPasswordNotification,
        state.repository.api!,
        { ...action.payload.params, ui });
    action.payload.promises && action.payload.promises.resolve()
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
    action.payload.promises && action.payload.promises.reject(e)
  }
}

function* createParentPasswordNotification(
  action: ReturnType<typeof userActions.createParentPasswordNotification>) {
  const state: AppState = yield select()
  const ui = state.ui.type! as Models.LoginableUIType
  if (!Models.loginableUIs.includes(ui)) {
    throw new Error(`invalid ui : ${ui}`);
  }
  try {
    yield call(
      apiService.createPasswordNotification,
        state.repository.api!,
        { ...action.payload.params, ui });
    action.payload.promises && action.payload.promises.resolve()
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
    action.payload.promises && action.payload.promises.reject(e)
  }
}

function* createPasswordResetter(
  action: ReturnType<typeof userActions.createPasswordResetter>) {
  const state: AppState = yield select()
  const ui = state.ui.type! as Models.LoginableUIType
  if (!Models.loginableUIs.includes(ui)) {
    throw new Error(`invalid ui : ${ui}`);
  }
  try {
    yield call(
      apiService.createPasswordResetter,
        state.repository.api!,
        { ...action.payload.params, ui });
    action.payload.promises && action.payload.promises.resolve()
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
    action.payload.promises && action.payload.promises.reject(e)
  }
}

function* changePassword(
  action: ReturnType<typeof userActions.changePassword>) {
  const state: AppState = yield select()
  const ui = state.ui.type! as Models.LoginableUIType
  if (!Models.loginableUIs.includes(ui)) {
    throw new Error(`invalid ui : ${ui}`);
  }
  try {
    yield call(
      apiService.changePassword,
        state.repository.authorizedApi!,
        { ...action.payload.params, ui });

    // MARK: userのstatusをpassword変更済みに変更する
    const user = state.session.user!
    yield put(sessionActions.setUser({
      ui,
      user: { ...user, password_updated: true }
    }))

    yield put(uiActions.showFlashMessage({ message: 'パスワードを変更しました。' }))
    action.payload.promises && action.payload.promises.resolve()
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
    action.payload.promises && action.payload.promises.reject(e)
  }
}

function* withdraw(
  action: ReturnType<typeof userActions.withdraw>) {
  const state: AppState = yield select()
  const ui = state.ui.type! as Models.LoginableUIType
  if (ui !== 'student') {
    throw new Error(`invalid ui : ${ui}`);
  }
  try {
    yield call(
      apiService.withdraw,
        state.repository.authorizedApi!, action.payload.params);
    action.payload.promises && action.payload.promises.resolve()
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
    action.payload.promises && action.payload.promises.reject(e)
  }
}

function* cancelTrainer (
  action: ReturnType<typeof userActions.cancelTrainer>) {
  const state: AppState = yield select()
  const ui = state.ui.type! as Models.LoginableUIType
  if (ui !== 'student') {
    throw new Error(`invalid ui : ${ui}`);
  }
  try {
    yield call(
      apiService.cancelTrainer,
        state.repository.authorizedApi!);

    yield updateUser()

    action.payload.promises && action.payload.promises.resolve()
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
    action.payload.promises && action.payload.promises.reject(e)
  }
}

function* requestTrainer (
  action: ReturnType<typeof userActions.requestTrainer>) {
  const state: AppState = yield select()
  const ui = state.ui.type! as Models.LoginableUIType
  if (ui !== 'student') {
    throw new Error(`invalid ui : ${ui}`);
  }
  const params = action.payload.params
  try {
    yield call(
      apiService.requestTrainer,
        state.repository.authorizedApi!,
        params);

    yield updateUser()

    action.payload.promises && action.payload.promises.resolve()
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
    action.payload.promises && action.payload.promises.reject(e)
  }
}

function* userSaga() {
  yield takeEvery(userActions.signup, signup);
  yield takeEvery(userActions.changePassword, changePassword);
  yield takeEvery(userActions.createPasswordNotification, createPasswordNotification);
  yield takeEvery(userActions.createStudentPasswordNotification, createStudentPasswordNotification);
  yield takeEvery(userActions.createParentPasswordNotification, createParentPasswordNotification);
  yield takeEvery(userActions.createPasswordResetter, createPasswordResetter);
  yield takeEvery(userActions.createSmsNotification, createSmsNotification);
  yield takeEvery(userActions.withdraw, withdraw);
  yield takeEvery(userActions.cancelTrainer, cancelTrainer);
  yield takeEvery(userActions.requestTrainer, requestTrainer);
}

export default userSaga;
