import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router'

import { AppState } from "store";
import targetComponent from 'components/trainer/StudyUnit';
import trainerActions from 'actions/trainerActions';

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch })

const mapStateToProps = (appState: AppState, ownProps: RouteComponentProps) => {
  return {
    user: appState.session.user!,
    student: appState.student,
    ...ownProps,
    trainer: appState.trainer,
  }
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  { dispatch }: ReturnType<typeof mapDispatchToProps>) => {

  return {
    ...stateProps,
    fetchData: (unit_id: number) => {
      dispatch(trainerActions.fetchStudyUnit({ unit_id }))
    },
    getRecord: (unit_id: number) => {
      const page = stateProps.trainer.studyUnit
      if (page.unit_id !== unit_id) {
        return null
      }
      return page.record
    },
    fetchContentData: (topic_id: number) => {
      dispatch(trainerActions.fetchStudyTopic({ topic_id }))
    },
    pdfDownload: (curriculum_codes: string[]) => {
      const curriculumCodesString = curriculum_codes.join("_")
      return new Promise<{url: string}>((resolve, reject) => {
        dispatch(trainerActions.fetchPdfDownload({
          pdf_curriculums: curriculumCodesString,
          promises: { resolve, reject }
        }))
      })},
      downloadAllTopicsPdf: (all_topic_ids: number[]) => {
        dispatch(trainerActions.downloadAllTopicsPdf({ all_topic_ids}))
      }
    }
}

export type mappedProps = ReturnType<typeof mergeProps>
export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(targetComponent))
