import React, { useEffect, useState } from 'react';
// import Moment from 'react-moment';
import momentTimezone from 'moment-timezone'
import lodash from 'lodash'
import useTimeout from 'use-timeout'
import classNames from 'classnames';
import {getCorrectAnswerColorType ,CorrectAnswerColor, getLearningDurationMessages, getLearningDurationFaceString/* , LearningDurationMessage  */} from 'utils/store'
import * as Models from 'models/api'

type Props = {
  records: Models.StudyAchivementRecord[];
  fetchNextStudyTimeStats?: () => void;
  updateGoalTime?: (time: number) => Promise<void>;
  recentlyStudiedAt: string | null;
  consecutiveCorrectAnswerCount: number | null;
}

const weekdays = ['月', '火', '水', '木', '金', '土', '日']
const goalTimeMinutes = lodash.times(4, i => i * 15)
const goalTimeHours = lodash.times(24)

const getWeekLearningHoursStatusText = (weekLearningTime: number, weekGoalTime: number) => {
  const achiveWeekGaolTime = weekLearningTime >= weekGoalTime
  const weekRemainingLearningHours = Math.floor((weekGoalTime - weekLearningTime) / 60)
  const weekRemainingLearningMinutes = (weekGoalTime - weekLearningTime ) % 60
  if (achiveWeekGaolTime) return '目標達成'

  let text = '目標まで '
  if (weekRemainingLearningHours > 0) text += `${weekRemainingLearningHours}時間`
  text += `${weekRemainingLearningMinutes}分`
  return text
}

export const SectionStudyTimeStatsBox: React.FC<Props> = (props: Props) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [showGraph, setShowGraph] = useState<boolean>(false)
  const [goalTimeHour, setGoalTimeHour] = useState<number>(0)
  const [goalTimeMinute, setGoalTimeMinute] = useState<number>(0)
  const record = props.records[currentIndex]

  useEffect(() => {
    if (record) {
      const goalTime = record.daily_goal_time || 30
      setGoalTimeHour(Math.floor(goalTime / 60))
      setGoalTimeMinute(goalTime % 60)

      // MARK: recordが最後の要素なら次を取得
      if (!props.records[currentIndex + 1] && props.fetchNextStudyTimeStats) {
        props.fetchNextStudyTimeStats()
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[record])

  useTimeout(() => setShowGraph(true), 500)

  if (props.records.length <= 0) {
    return <></>
  }

  const momentToday = momentTimezone()
  const startWeekOn = momentTimezone(record.start_week_on).startOf('week');
  const endWeekOn = momentTimezone(record.start_week_on).endOf('week');
  const weekDay = momentToday.isoWeekday()

  // MARK: 過去週のデータの可能性があるので
  const inWeek = momentToday.isSameOrAfter(startWeekOn) && momentToday.isSameOrBefore(endWeekOn)

  const goalTime = record.daily_goal_time || 30

  const weekGoalTime = goalTime * 7
  const weekGoalHours = Math.floor(weekGoalTime / 60)
  const weekGoalMinutes = weekGoalTime % 60

  const weekLearningTime = record.day1 + record.day2 + record.day3 + record.day4 + record.day5 + record.day6 + record.day7
  const weekLearningHours = Math.floor(weekLearningTime / 60)
  const weekLearningMinutes = weekLearningTime % 60
  let weekLearningRate = weekLearningTime / weekGoalTime
  if (weekLearningRate > 1) weekLearningRate = 1
  if (!showGraph) weekLearningRate = 0

  const totalLearningTime = record.total_learning_time || 0
  const totalLearningHours = Math.floor(totalLearningTime / 60)
  const totalLearningMinutes = totalLearningTime % 60

  let continuationLearningDaysMybest = record.continuation_days_mybest
  //let continuationLearningDays = 0
  /* if (record.continuation_started_on) {
    continuationLearningDays = momentToday.diff(
      momentTimezone(record.continuation_started_on),
      'days'
    )
  } */

  let noLearningDuration = props.recentlyStudiedAt ? momentTimezone().diff(momentTimezone(props.recentlyStudiedAt), 'days') : null;
  let consecutiveCorrectAnswerCount = props.consecutiveCorrectAnswerCount;

  const colorType = consecutiveCorrectAnswerCount && getCorrectAnswerColorType(consecutiveCorrectAnswerCount);
  //consecutiveCorrectAnswerCount = 1
  const setNextIndex = () => {
    if (props.records[currentIndex + 1]) {
      setCurrentIndex(currentIndex+1)
    }
  }

  const setPrevIndex = () => {
    if (props.records[currentIndex - 1]) {
      setCurrentIndex(currentIndex-1)
    }
  }

  return (
    <section className="study-time-stats">
      <div className="study-time-stats__head">
        <div className="study-time-stats__nav">
          <ul className="study-time-nav">
            <li
              className={classNames("study-time-nav__item study-time-nav__item--this-week", {
                'is-highlighted': currentIndex !== 0,
              })}
              onClick={e => setCurrentIndex(0)}>
              今週
            </li>

            <li
              className={classNames("study-time-nav__item study-time-nav__item--prev", {
                'is-disabled': !props.records[currentIndex + 1],
              })}
              onClick={e => setNextIndex()}>
              前の週
            </li>

            <li
              className={classNames("study-time-nav__item study-time-nav__item--next", {
                'is-disabled': !props.records[currentIndex - 1],
              })}
              onClick={e => setPrevIndex()}>
              次の週
            </li>

          </ul>
        </div>
        <div className="study-time-stats__title">
          { startWeekOn.format('M月D日')}
          &nbsp;
          -
          &nbsp;
          { endWeekOn.format('M月D日')}
          の学習時間
        </div>
      </div>
      <div className="study-time-stats__body">
        <div className="study-time-stats__graphs">
          <div className="study-time-stats__now">
            <div className="study-time-stats__now-time">
              { weekLearningHours > 0 && <> { weekLearningHours }<small>時間</small> </> }
              { weekLearningMinutes }<small>分</small>
            </div>
            <div className="study-time-stats__remains">
              <div className="study-time-graph study-time-graph--bold">
                <div className="study-time-graph__text">
                  { getWeekLearningHoursStatusText(weekLearningTime, weekGoalTime) }
                </div>
                <div className="study-time-graph__bar" style={{width: `${weekLearningRate * 100}%`}}></div>
              </div>
            </div>
          </div>
          <div className="study-time-stats__week">
            <div className="study-time-stats__week-unit">
              (分)
            </div>
            {
              lodash.times(7, i => {
                const x: number = (record as any)[`day${i+1}`]
                let rate = x / goalTime
                if (rate > 1) rate = 1
                if (!showGraph) rate = 0
                return (
                  <div key={i} className="study-time-stats__week-item">
                    <div className={classNames("study-time-stats__day", {
                      'is-active': inWeek && weekDay === i + 1,
                    })}>
                      { weekdays[i] }
                    </div>
                    <div className="study-time-stats__graph">
                      <div className="study-time-graph">
                        <div className="study-time-graph__bar" style={{width: `${rate * 100}%`}}></div>
                      </div>
                    </div>
                    <div className="study-time-stats__minutes">
                      { x }
                    </div>
                  </div>
                )
              })
            }
          </div>
          <div className="study-time-stats__last-study">
            {props.recentlyStudiedAt && <>最終学習：{momentTimezone(props.recentlyStudiedAt).format('YYYY年MM月DD日 HH:mm')}</>}
          </div>
        </div>

        <div className="study-time-stats__times">
          <div className="study-time-stats__times-item study-time-stats__times-item--object">
            <div className="study-time-stats__times-title">
              <b>
                { currentIndex === 0 ? '今週の目標' : '当週の目標' }
              </b>
            </div>
            <div className="study-time-stats__times-body">
              { weekGoalHours > 0 && <><b>{ weekGoalHours }</b>時間</> }
              <b>{ weekGoalMinutes }</b>分
            </div>
          </div>

          {
            props.updateGoalTime && currentIndex === 0 &&
            <>
              <div className="study-time-stats__balloon-trigger" onClick={e => setShowModal(!showModal)}>
                目標設定
              </div>
              <div className={classNames("balloon balloon--study-time study-time-balloon", {
                'is-active': showModal,
              })}>
                <div className="study-time-balloon__lead">
                  目標とする１日の平均学習時間を入力してください。
                </div>
                <div className="study-time-balloon__form">
                  <select
                    value={goalTimeHour}
                    onChange={e => {
                      const x = Number(e.target.value)
                      setGoalTimeHour(x)
                      if (x === 0 && goalTimeMinute === 0) {
                        setGoalTimeMinute(15)
                      }
                    }}
                    className="study-time-balloon__select">
                    {
                      goalTimeHours.map(x => (
                        <option key={x} value={x}>
                          { x }
                        </option>
                      ))
                    }
                  </select>時間
                  <select
                    value={goalTimeMinute}
                    onChange={e => { setGoalTimeMinute(Number(e.target.value)) }}
                    className="study-time-balloon__select">
                    {
                      goalTimeMinutes.filter(x => {
                        return goalTimeHour === 0 ? x !== 0 : true
                      }).map(x => (
                        <option key={x} value={x}>
                          { x }
                        </option>
                      ))
                    }
                  </select>分 /日
                </div>
                <button className="study-time-balloon__button" onClick={e => {
                  e.preventDefault()
                  if (props.updateGoalTime) {
                    props.updateGoalTime(goalTimeHour * 60 + goalTimeMinute).then(x => {
                      setShowModal(false)
                    })
                  }
                }}>設定する</button>
              </div>
            </>
          }
          {
           <div className="study-time-stats__times-item _sp">
            <div className="study-time-stats__times-title">
              最終学習
            </div>
            <div className="study-time-stats__times-body">
              <div className="study-time-stats__last-study-time-grid">
                {props.recentlyStudiedAt && <>{momentTimezone(props.recentlyStudiedAt).format('YYYY年MM月DD日 HH:mm')}</>}
              </div>
            </div>
          </div>
          }
          <div className="study-time-stats__times-item">
            <div className="study-time-stats__times-title">
              { currentIndex === 0 ? '総学習時間' : '当週までの総学習時間' }
            </div>
            <div className="study-time-stats__times-body">
              { totalLearningHours > 0 && <><b>{ totalLearningHours }</b>時間</> }
              <b>{ totalLearningMinutes }</b>分
            </div>
          </div>
          {
            currentIndex === 0 &&
            <div className="study-time-stats__times-item">
              <div className="study-time-stats__times-title">
                学習状況
              </div>
              <div className="study-time-stats__achievements-latest-body">
                <div className="study-time-stats__achievements-latest-section">
                  <div className="study-time-stats__achievements-latest-section-text">
                  {noLearningDuration !== null && (<>
                    {getLearningDurationMessages(noLearningDuration).bold}
                    {getLearningDurationMessages(noLearningDuration).regular}
                  </> )}
                  </div>
                  <span className="study-time-stats__times-note">
                    （連続学習記録：{ continuationLearningDaysMybest }日）
                  </span>
                </div>
                <div className="study-time-stats__achievements-latest-icon-section">
                {noLearningDuration !== null &&
                  <div className={`study-time-stats__face-icon study-time-stats__face-icon--${getLearningDurationFaceString(noLearningDuration)}`}>
                 </div>}
                </div>
              </div>
            </div>
          }

          {
            currentIndex === 0 &&
            <div className="study-time-stats__times-item">
              <div className="study-time-stats__times-title">
                連続正解数
              </div>
              <div className="study-time-stats__achievements-latest-body">
                <div className="study-time-stats__consecutive-count-section">
                {consecutiveCorrectAnswerCount !== null &&
                <div className="study-time-stats__achievements-count-section-text">
                  {consecutiveCorrectAnswerCount}問
                </div>
                }

                {colorType === CorrectAnswerColor.Yellow &&  <div className="study-time-stats__hand-sign-1"></div>}
                {colorType === CorrectAnswerColor.Green  &&  <div className="study-time-stats__hand-sign-2"></div>}
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </section>
  )
}
