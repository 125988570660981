import React from 'react';
import Moment from 'react-moment';

import * as Models from 'models/api'
import storeUtils from 'utils/store'

import pdfIconImage from 'assets/img/img_pdf.png';

const nl2br = require('react-nl2br');

type props = {
  question: Models.QuestionDetail;
  type: Models.LoginableUIType;
}

const QuestionDetail: React.FC<props> = (props: props) => {
  const { question } = props

  // MARK: 受講生画面では不要
  const showStudent =  props.type === 'instructor'
  const showContentLink =  showStudent
  const contentLink = storeUtils.getQuestionContentUrl(question.content.launch_parameters.curriculum_code)

  return (
    <div className="question-form question-form--teacher">
      <div className="question-form__question">
        <div className={`question-box question-box--${question.statusCode}`}>
          <div className="question-box__inner">
            <div className="question-box__badge">
              { question.statusText }
            </div>
            <div className="question-box__title">
              {
                showContentLink ? 
                // eslint-disable-next-line react/jsx-no-target-blank
                <a href={contentLink} className="question-box__link" target="_blank">
                  { question.content.topic.title } > { question.content.meta!.label}
                </a> :
                <React.Fragment>
                  {
                    question.content.topic.title } > { question.content.meta!.label
                  }
                </React.Fragment>
              }
            </div>
            <div className="question-box__spec">
              {
                showStudent && 
                <span className="question-box__name">
                  { question.student.nick_name }さん
                </span>
              }
              <span className="question-box__datetime">
                <Moment format="M月D日 HH:mm">{ question.created_at }</Moment>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="question-form__textarea question-form__textarea--teacher">
        { nl2br(question.body) }
      </div>

      {
        !question.body_attachment &&
        <React.Fragment>
          {/* border bottom追加用 */}
          <div className="question-form__attachment question-form__attachment--teacher">
          </div>
        </React.Fragment>
      }

      {
        question.body_attachment &&
        <React.Fragment>
          <div className="question-form__title question-form__title--teacher">
            添付ファイル
          </div>
          <div className="question-form__attachment question-form__attachment--teacher">
            {
              question.body_attachment.mime_type.startsWith('image/') &&
              <React.Fragment>
                <a href={question.body_attachment.url} target="_blank" rel="noopener noreferrer">
                  <img src={question.body_attachment.url} alt="添付画像" className="question-form__image" /><br />
                  <div className="answer-form__file-name answer-form__file-name--teacher">
                    { question.body_attachment.file_name }
                  </div>
                </a>
              </React.Fragment>
            }
            {
              question.body_attachment.mime_type.startsWith('application/pdf') &&
              <React.Fragment>
                <a href={question.body_attachment.url} target="_blank" rel="noopener noreferrer">
                  <img src={pdfIconImage} alt="" className="question-form__image question-form__image--pdf" />
                  <div className="answer-form__file-name answer-form__file-name--teacher">
                      { question.body_attachment.file_name }
                  </div>
                </a>
              </React.Fragment>
            }
          </div>
        </React.Fragment>
      }

    </div>
  );
}

export default QuestionDetail
