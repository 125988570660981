import React from 'react';
import { NavLink } from 'react-router-dom';

import * as Models from 'models/api'

type props = {
  student: Models.StudentUser;
}

const TrainerStudentNav: React.FC<props> = (props: props) => {
  const { student } = props
  return (
    <div className="trainer-nav">
      <div className="trainer-nav__item">
        <NavLink to={`/students/${student.id}/schedules`}
          activeClassName='button--primary'
          className='button button--no-chevron'>
          スケジュール</NavLink>
      </div>
      <div className="trainer-nav__item">
        <NavLink to={`/students/${student.id}/study_result`}
          activeClassName='button--primary'
          className='button button--no-chevron'>
          学習結果</NavLink>
      </div>
      <div className="trainer-nav__item">
        <NavLink to={`/students/${student.id}/study_logs`}
          activeClassName='button--primary'
          className='button button--no-chevron'>
          学習履歴</NavLink>
      </div>
      {/* // issue-547 */}
      <div className="trainer-nav__item">
        <NavLink to={`/students/${student.id}/bookmark`}
          activeClassName='button--primary'
          className='button button--no-chevron'>
          ブックマーク</NavLink>
      </div>
      {/* // issue-547 */}
      <div className="trainer-nav__item">
        <NavLink to={`/students/${student.id}/messages`}
          activeClassName='button--primary'
          className='button button--no-chevron'>
          相談</NavLink>
      </div>
    </div>
  );
}

export default TrainerStudentNav
