import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router'

import { AppState } from "store"
import TrainerStudySummaries from 'components/trainer/StudySummaries'
import { curriculums } from 'utils/constants'
import trainerActions from 'actions/trainerActions';

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch })

const mapStateToProps = (appState: AppState, ownProps: RouteComponentProps) => {
  const authrorizedApi = appState.repository.authorizedApi!
  return {
    ...ownProps,
    trainer: appState.trainer,
    curriculums,
    authrorizedApi,
  }
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  { dispatch }: ReturnType<typeof mapDispatchToProps>) => {

  return {
    ...stateProps,
    fetchSchoolClassess: () => dispatch(trainerActions.fetchSchoolClasses()),
    fetchCourses: (curriculum_id: number) => {
      dispatch(trainerActions.fetchCurriculumCourses({ curriculum_id }))
    },
    fetchStudySummariesPerCurriculum: (school_class_id?: number) => {
      dispatch(trainerActions.fetchStudySummariesPerCurriculum({
        initialize: true,
        school_class_id,
      }))
    },
    fetchNextStudySummariesPerCurriculum: (school_class_id?: number) => {
      dispatch(trainerActions.fetchStudySummariesPerCurriculum({
        initialize: false,
        school_class_id,
      }))
    },
    fetchStudySummariesPerUnit: (course_id: number, school_class_id?: number) => {
      dispatch(trainerActions.fetchStudySummariesPerUnit({
        initialize: true,
        course_id,
        school_class_id,
      }))
    },
    fetchNextStudySummariesPerUnit: (course_id: number, school_class_id?: number) => {
      dispatch(trainerActions.fetchStudySummariesPerUnit({
        initialize: false,
        course_id,
        school_class_id,
      }))
    },
  }
}

export type mappedProps = ReturnType<typeof mergeProps>

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(TrainerStudySummaries))
