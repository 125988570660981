import { StudentUser } from 'models/api';

const disabledCorporationNames: string[] = []

export const isAvailable = (user: StudentUser): boolean => {
  // MARK: 「2020KEI　大学入学前教育」のみお知らせを表示しないようにしたい。他の法人にはお知らせは表示させたい
  // MARK: issue-671 「2020KEI　大学入学前教育」のお知らせ非表示制御を解除

  return !user.corporation || !disabledCorporationNames.includes(user.corporation.name)
}
