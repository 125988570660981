import React from 'react';
import { Link } from 'react-router-dom';
import logo from 'assets/img/img_logo.png';

const PublicHeader : React.FC = () => {
  return (
    <header className="site-header">
      <div className="site-header__inner">
        <div className="site-header__logo">
          <Link to='/'>
            <img src={logo} alt="" className="site-header__image" />
          </Link>
          <div className="site-header__text">
            わたし専用。河合塾One
          </div>
        </div>
      </div>
    </header>
  );
}

export default PublicHeader;
