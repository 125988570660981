import actionCreatorFactory from 'typescript-fsa';
import * as Models from "models/api";

const actionCreator = actionCreatorFactory('student');

const actions = {
  fetchRecommendStudyRecords:
    actionCreator<{
      curriculum_id: number; 
      promises?: { resolve: () => void, reject: (e: Error) => void}
    }>(
      'FETCH_RECOMMEND_STUDY_RECORDS'),
  // issue-545 おまかせ学習のコンテンツを新規のAPIから取得する場合はこちらを編集して使う
  fetchEntrustStudyRecords:
    actionCreator<{
      curriculum_id?: number; 
      promises?: { resolve: (records: Models.EntrustCurrentRecords) => void, reject: (e: Error) => void}
    }>('FETCH_ENTRUST_STUDY_RECORDS'),      
  setEntrustStudyRecords:
    actionCreator<{
      curriculum_id?: number;
      records: Models.EntrustCurrentRecords;
    }>('SET_ENTRUST_STUDY_RECORDS'),
  updateEntrustBaseTopic: 
    actionCreator<{ 
      topic_id: number; 
      promises?: { resolve: (records: any) => void, reject: (e: Error) => void}
    }>('UPDATE_ENTRUST_BASE_TOPIC'),
      // issue-545 おまかせ学習の設定  
  setRecommendStudyRecords:
    actionCreator<{ curriculum_id: number; records: Models.RecommendStudyRecords2 | null }>(
      'SET_RECOMMEND_STUDY_RECORDS'),
  resetRecommendStudyRecords:
    actionCreator(
      'RESET_RECOMMEND_STUDY_RECORDS'),
  fetchQuestionRecords:
    actionCreator<{ initialize: boolean }>(
      'FETCH_QUESTION_RECORDS'),
  fetchQuestionDetail:
    actionCreator<{ question_id: number }>(
      'FETCH_QUESTION_DETAIL'),
  setQuestionDetail:
    actionCreator<{ question_id: number; record: Models.QuestionDetail | null }>(
      'SET_QUESTION_DETAIL'),
  setQuestionRecords:
    actionCreator<{ page: number; records: Models.QuestionRecords }>(
      'SET_QUESTION_RECORDS'),
  fetchStudyLogRecords:
    actionCreator<{ curriculum_id: number; initialize: boolean; }>(
      'FETCH_STUDY_LOG_RECORDS'),
  setStudyLogRecords:
    actionCreator<{ page: number; curriculum_id: number; records: Models.StudyLogRecords }>(
      'SET_STUDY_LOG_RECORDS'),
  fetchStudyLogDetail:
    actionCreator<{ study_log_id: number }>(
      'FETCH_STUDY_LOG_DETAIL'),
  setStudyLogDetail:
    actionCreator<{
      study_log_id: number;
      record: Models.StudyLog | null; }>(
      'SET_STUDY_LOG_DETAIL'),
  fetchStudyAchievementsLatest:
    actionCreator<{}>(
      'FETCH_STUDY_ACHIEVEMENTS_LATEST'),
  setStudyAchievementsLatest:
    actionCreator<{
      record: Models.StudyAchievementsLatest | null;
    }>(
      'SET_STUDY_ACHIEVEMENT_LATEST'),
  fetchQuestionsSheetsCountInMonth:
    actionCreator<void>(
      'FETCH_QUESTIONS_SHEETS_COUNT_IN_MONTH'),
  setQuestionsSheetsCountInMonth:
    actionCreator<{ count: number; }>(
      'SET_QUESTIONS_SHEETS_COUNT_IN_MONTH'),
  fetchScheduleRecords:
    actionCreator<{ initialize: boolean }>(
      'FETCH_SCHEDULE_RECORDS'),
  setScheduleRecords:
    actionCreator<{ page: number; records: Models.ScheduleRecords }>(
      'SET_SCHEDULE_RECORDS'),
  fetchMessageRecords:
    actionCreator<void>(
      'FETCH_MESSAGE_RECORDS'),
  setMessageRecords:
    actionCreator<{ records: Models.StudentMessageRecords }>(
      'SET_MESSAGE_RECORDS'),
  fetchMypage:
    actionCreator<void>(
      'FETCH_MYPAGE'),
  setMypage:
    actionCreator<{ record: Models.StudentMypage }>(
      'SET_MYPAGE'),
  fetchNextStudyTimeStats:
    actionCreator<void>(
      'FETCH_NEXT_STUDY_TIME_STATS'),
  setStudyTimeStats:
    actionCreator<{ records: Models.StudyAchivementRecord[]; has_next: boolean; }>(
      'SET_STUDY_TIME_STATS'),
  pushStudyTimeStats:
    actionCreator<{ records: Models.StudyAchivementRecord[]; has_next: boolean; }>(
      'PUSH_STUDY_TIME_STATS'),
  updateStudyTimeStats:
    actionCreator<{ record: Models.StudyAchivementRecord; index: number; }>(
      'UPDATE_STUDY_TIME_STATS'),
  fetchPlan:
    actionCreator<void>(
      'FETCH_PLAN'),
  setPlan:
    actionCreator<{ record: Models.StudentPlan }>(
      'SET_PLAN'),
  fetchStudyCurriculum:
    actionCreator<{ curriculum_id: number; }>(
      'FETCH_STUDY_CURRICULUM'),
  setStudyCurriculum:
    actionCreator<{ curriculum_id: number; record: Models.StudentStudyCurriculum | null }>(
      'SET_STUDY_CURRICULUM'),
  fetchStudyCourse:
    actionCreator<{ course_id: number; }>(
      'FETCH_STUDY_COURSE'),
  setStudyCourse:
    actionCreator<{ course_id: number; record: Models.StudentStudyCourse | null }>(
      'SET_STUDY_COURSE'),
  fetchStudyUnit:
    actionCreator<{ unit_id: number; }>(
      'FETCH_STUDY_UNIT'),
  setStudyUnit:
    actionCreator<{ unit_id: number; record: Models.StudentStudyUnit | null }>(
      'SET_STUDY_UNIT'),
  fetchStudyTopic:
    actionCreator<{ topic_id: number; }>(
      'FETCH_STUDY_TOPIC'),
  setStudyTopic:
    actionCreator<{ topic_id: number; record: Models.StudentStudyTopic | null }>(
      'SET_STUDY_TOPIC'),
  downloadAllTopicsPdf:
    actionCreator<{ all_topic_ids: number[]; }>(
      'FETCH_ALL_TOPICS_PDF'),
  updateProfile:
    actionCreator<{ 
      params: Models.UpdateStudentProfileParams;
      promises?: { resolve: () => void, reject: (e: Error) => void}
    }>('UPDATE_PROFILE'),
  updateSchedule:
    actionCreator<{ 
      params: Models.UpdateScheduleParams;
      promises?: { resolve: () => void, reject: (e: Error) => void}
    }>('UPDATE_SCHEDULE'),
  createQuestion:
    actionCreator<{ 
      params: Models.CreateQuestionParams;
      promises?: { resolve: () => void, reject: (e: Error) => void}
    }>('CREATE_QUESTION'),
  startStudy:
    actionCreator<{ 
      //issue-545
      params: { content_id: number; return_pathname: string; rank?: number; learned?: boolean; m_entrust?: string};
      promises?: { resolve: (url: string) => void, reject: (e: Error) => void}
    }>('START_STUDY'),
  skipRecommendStudyPretest:
    actionCreator<{
      params: { content_id: number; rank: number; }
      promises?: { resolve: () => void, reject: (e: Error) => void}
    }>(
      'SKIP_RECOMMEND_STUDY_PRETEST'),
  createCreditcardToken:
    actionCreator<{ 
      promises?: { resolve: (token: string) => void, reject: (e: Error) => void}
    }>('CREATE_CREDITCARD_TOKEN'),
  buyQuestionTicket:
    actionCreator<{ 
      promises?: { resolve: () => void, reject: (e: Error) => void}
    }>('BUY_QUESTION_TICKET'),

  setPointExchangeConfirmRecord:
    actionCreator<{ record: Models.StudentPointExchangeRecord }>(
      'SET_POINT_EXCHANGE_CONFIRM_RECORD'),
  setPointExchangeExecuteRecord:
    actionCreator<{ record: Models.StudentPointExchangeRecord }>(
      'SET_POINT_EXCHANGE_EXECUTE_RECORD'),
  setPointExchangeParams:
    actionCreator<{ params: Models.StudentPointExchangeParams }>(
      'SET_POINT_EXCHANGE_PARAMS'),
  setPointExchangeError:
    actionCreator<Error>(
      'SET_POINT_EXCHANGE_ERROR'),
  resetPointExchangeState:
    actionCreator<void>(
      'RESET_POINT_EXCHANGE_STATE'),
  confirmPointExchange:
    actionCreator<{
      params: Models.StudentPointExchangeParams,
      promises?: { resolve: () => void, reject: (e: Error) => void}
    }>(
      'CONFIRM_POINT_EXCHANGE'),
  executePointExchange:
    actionCreator<{
      promises?: { resolve: () => void, reject: (e: Error) => void}
    }>(
      'EXECUTE_POINT_EXCHANGE'),

  fetchPointExchangeLogRecords:
    actionCreator<{ initialize: boolean }>(
      'FETCH_POINT_EXCHANGE_LOG_RECORDS'),
  setPointExchangeLogRecords:
    actionCreator<{ page: number; records: Models.PointExchangeLogsRecords }>(
      'SET_POINT_EXCHANGE_LOG_RECORDS'),
  updateStudyTimeStatsGoalTime:
    actionCreator<{ 
      params: { daily_goal_time: number };
      promises?: { resolve: () => void, reject: (e: Error) => void}
    }>('UPDATE_STUDY_TIME_STATS_GOAL_ITME'),
  fetchStudyCourseResult:
    actionCreator<{ curriculum_id: number; course_id?: number | null; }>(
      'FETCH_STUDY_COURSE_RESULT'),
  setStudyCourseResult:
    actionCreator<{ curriculum_id: number; course_id: number; record: Models.StudyCourseResultsRecord | null }>(
      'SET_STUDY_COURSE_RESULT'),
  fetchStudyUnitResult:
    actionCreator<{ unit_id: number; initialize: boolean }>(
      'FETCH_STUDY_UNIT_RESULT'),
  setStudyUnitResult:
    actionCreator<{ unit_id: number; record: Models.StudyUnitResultsRecord | null }>(
      'SET_STUDY_UNIT_RESULT'),
  setStudyUnitResultMore:
    actionCreator<{ record: Models.StudyUnitResultsRecord | null}>(
      'SET_STUDY_UNIT_RESULT_MORE'),

  fetchStudyCourseCompletionTestResult:
    actionCreator<{ unit_id: number; initialize: boolean; }>(
      'FETCH_STUDY_COURSE_COMPLETION_TEST_RESULTS'),
  setStudyCourseCompletionTests:
    actionCreator<{ course_id: number; record: Models.StudyCourseCompletionTestsRecord | null }>(
      'SET_STUDY_COURSE_COMPLETION_TESTS'),
  setStudyCourseCompletionTestResults:
    actionCreator<{ unit_id: number; record: Models.StudyCourseCompletionTestResultsRecord | null }>(
      'SET_STUDY_COURSE_COMPLETION_TEST_RESULTS'),
  setStudyCourseCompletionTestResultsMore:
    actionCreator<{ record: Models.StudyCourseCompletionTestResultsRecord | null}>(
      'SET_STUDY_COURSE_COMPLETION_TEST_RESULTS_MORE'),

  getLearningCount:
    actionCreator<{ 
      params: { curriculumCodes:Models.StudentStudyLearningCountParams }
      promises?: { resolve: (data : Models.StudentStudyLearningCountRecords)=> void , reject: (e: Error) => void}
    }>(
      'GET_LEARNING_COUNT'),        

  setLearningCount:
    actionCreator<{ 
      params:  Models.StudentStudyLearningCountRecords | null
    }>(
      'SET_LEARNING_COUNT'),       
  // issue-547
  fetchBookmarks:
    actionCreator<{ 
      //params: {}
      promises?: { resolve: (data : Models.BookmarksResponse)=> void , reject: (e: Error) => void}
     }>(
      'FETCH_BOOKMARKS'),
  setBookmarks:
    actionCreator<{
      params: Models.Bookmarks | null
    }>(
      'SET_BOOKMARKS'),
    fetchBookmarksLearningCount:
    actionCreator<{ }>(
      'FETCH_BOOKMARK_LEARNING_COUNT'),
  setBookmarksLearningCount:
    actionCreator<{
      params: Models.StudentStudyLearningCountRecords | null
    }>(
      'SET_BOOKMARK_LEARNING_COUNT'),
  deleteBookmarks:
    actionCreator<{
      params:  Models.BookmarksDeleteParams,
      promises?: { resolve: (data : any) => void, reject: (e: Error) => void}
    }>(
      'DELETE_BOOKMARKS'),
  renewBookmarksState:
    actionCreator<void>(
      'RENEW_BOOKMARKS_STATE'),
  // issue-547    
  fetchPdfDownload:
  actionCreator<{
    pdf_curriculums: string; 
    promises?: { resolve: (record:{url : string}) => void, reject: (e: Error) => void}
  }>(
    'FETCH_PDF_DOWNLOAD'),      
};

export default actions;
