import moment, { Moment } from "moment";

const setting: { [key: string]: any } = {
  production: {
    startAt:   moment('2020-07-01T00:00:00+0900'),
    endAt:     moment('2020-09-01T00:00:00+0900'),
  },
  staging: {
    startAt:   moment('2020-06-01T00:00:00+0900'),
    endAt:     moment('2020-09-01T00:00:00+0900'),
  },
  development: {
    startAt:   moment('2020-06-01T00:00:00+0900'),
    endAt:     moment('2020-09-01T00:00:00+0900'),
  }
}

export const isOpenedKey = (type: string, now: Moment, key: string): boolean => {
  if (!isAvailable(type, now)) {
    return false
  }
  const dt = setting[type][key]
  return dt <= now
}

export const isAvailable = (type: string, now: Moment): boolean => {
  const endAt = setting[type].endAt
  const startAt = setting[type].startAt
  return endAt > now && now >= startAt
}
