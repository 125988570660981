import React, { useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Moment from 'react-moment';

import PrivateHeader from 'containers/parts/PrivateHeader';
import { mappedProps } from 'containers/student/StudyQuestionLogs';
import SiteMain from 'containers/parts/SiteMain';

const StudyQuestionLogs: React.FC<mappedProps> = (props: mappedProps) => {
  useEffect(() => {
    props.fetchFirst();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const records = props.question.records
  const hasNext = props.question.has_next

  if (!props.availableQuestions) {
    return <Redirect to='/' />
  }

  return (
    <React.Fragment>
      <PrivateHeader />
      <SiteMain>
        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          <li className="breadcrumbs__item">
            先生からの回答
          </li>
        </ol>

        <div className="site-main__main-contents">
          <div className="page-title page-title--question">
            先生からの回答
          </div>
          <div className="question-list">
            <div className="question-list__body">
              { 
                records && records.map((record, index) => (
                  <div key={index} className={`question-box question-box--${record.statusCode}`}>
                    <div className="question-box__inner">
                      <div className="question-box__badge">
                        { record.statusText }
                      </div>
                      <div className="question-box__title">
                        <Link
                          to={`/study_question_logs/${record.id}`}
                          className="question-box__link">
                          { record.content.topic.title } > { record.content.meta!.label}
                        </Link>
                      </div>

                      <div className="question-box__spec">
                        {
                          record.statusCode === 'answered' && record.instructor &&
                          <span className="question-box__name">
                            { record.instructor.nick_name }
                          </span>
                        }
                        <span className="question-box__datetime">
                          <Moment format="M月D日 HH:mm">{ record.created_at }</Moment>
                        </span>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>

            <div className="question-list__foot">
              先生への質問は
              <Link to='/study_logs' className="box__link">学習履歴</Link>
              から行ってください。
            </div>
          </div>

          {
            hasNext &&
            <div className="more-button">
              <div
                onClick={() => { props.fetchNext() }}
                className="more-button__text">
                次のトピックを表示
              </div>
            </div>
          }

          <div className="back-home">
            <Link to='/' className="back-home__link">マイページへ戻る</Link>
          </div>
        </div>

        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          <li className="breadcrumbs__item">
            先生からの回答
          </li>
        </ol>
      </SiteMain>
    </React.Fragment>
  );
}

export default StudyQuestionLogs