import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { AppState } from "store";
import StudentGuide from 'components/student/Guide';
import * as Models from 'models/api';
import * as campaignSettingGuide from 'utils/campaign/guide202012'

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch })

const mapStateToProps = (appState: AppState) => {
  const uiType = appState.ui.type
  const user = appState.session.user
  let studentUser: Models.StudentUser | null = null
  if (uiType === 'student') {
    studentUser = user as Models.StudentUser
  }

  const isAvailableGuide = studentUser && campaignSettingGuide.isAvailable(studentUser)

  return {
    studentUser,
    isAvailableGuide,
  }
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  { dispatch }: ReturnType<typeof mapDispatchToProps>) => {
  return {
    ...stateProps,
  }
}

export type mappedProps = ReturnType<typeof mergeProps>

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(StudentGuide)