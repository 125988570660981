import React from 'react';

import { StudyCourseCompletionTestResultRecord, } from 'models/api';

import Moment from 'react-moment';

type props = {
  result: StudyCourseCompletionTestResultRecord
}

export const CourseCompletionTestResultBoxItem: React.FC<props> = ({ result }: props) => {
  return <div className="completion-test-result-box__result">
    <div className="completion-test-result-box__result-title">
      科目修了テスト
      <div className="completion-test-result-box__date _sp">
        <span className="completion-test-result-box__date-title">実施日時</span>
        <Moment format="YYYY年M月D日 HH:mm">{ result.created_at }</Moment>
      </div>
    </div>
    <div className="completion-test-result-box__points">
      <div className="completion-test-result-box__point">
        <b>{ result.score }</b><small>点</small>
      </div>
      {
        ( result.expected_max_score !== null && result.expected_min_score !== null ) ?
        <div className="completion-test-result-box__expectation">
          <div className="completion-test-result-box__expectation-head _sp">
            共通テスト予想点数
          </div>
          <b>{result.expected_min_score}〜{result.expected_max_score}</b>点<small>（<b>{result.perfect_score}</b>点満点）</small>
        </div>:
        <div className="completion-test-result-box__expectation">
          <div className="completion-test-result-box__expectation-head _sp">
            共通テスト予想点数
          </div>
          <b>
            −&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </b>
        </div>
      }

    </div>
    <div className="completion-test-result-box__date _pc">
      <span className="completion-test-result-box__date-title">実施日時</span>
      <Moment format="YYYY年M月D日 HH:mm">{ result.created_at }</Moment>
    </div>
  </div>
}
