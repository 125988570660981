import { fork } from 'redux-saga/effects'

import sessionSaga from './session'
import userSaga from './user'
import infoSaga from './info'
import trainerSaga from './trainer'
import instructorSaga from './instructor'
import studentSaga from './student'
import uiSaga from './ui'
import parentSaga from './parent'
import studyResultSaga from './studyResult'
import borderResultsSaga from './borderResults'

function* rootSaga() {
  yield fork(sessionSaga)
  yield fork(userSaga)
  yield fork(infoSaga)
  yield fork(trainerSaga)
  yield fork(instructorSaga)
  yield fork(studentSaga)
  yield fork(uiSaga)
  yield fork(parentSaga)
  yield fork(studyResultSaga)
  yield fork(borderResultsSaga)
}

export default rootSaga;
