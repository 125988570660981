import React from 'react';
import { Redirect } from 'react-router-dom';
import { Field, WrappedFieldProps } from 'redux-form'

import PublicHeader from '../parts/PublicHeader';
import SiteMainSimple  from '../parts/SiteMainSimple';
import { mappedProps } from 'containers/common/FixProvisionalPassword';

const renderTextField = ({
  input,
  ...custom
}: WrappedFieldProps) => (
  <input
    {...input}
    {...custom}
  />
)

const FixProvisionalPassword: React.FC<mappedProps> = (props: mappedProps) => {
  if (!props.isAuthenticated) {
    return <Redirect to='/login' />
  } else if (props.alreadyFixed) {
    return <Redirect to={props.nextPage} />
  }

  return (
    <React.Fragment>
      <PublicHeader />
      <SiteMainSimple>
        <div className="account-box">
          <div className="account-box__title">
            初回ログイン パスワード変更
          </div>
          <div className="account-box__lead">
            仮パスワードから正式なパスワードへの変更が必要です。<br />
            新たにパスワードを設定してください。
          </div>
          <div className="account-box__caution">
            新たに設定したパスワードは、今後のログインする際に<br />
            必要になりますので、大切に保管してください。
          </div>
          <div className="account-box__form">
            <form onSubmit={props.handleSubmit}>
              <div className="account-form">
                <div className="account-form__item">
                  <label className="account-form__label">新しいパスワード</label>
                  <Field
                    component={renderTextField}
                    className="input input--required account-form__input"
                    type="password"
                    name="password"
                  />
                </div>
                <div className="account-form__item">
                  <label className="account-form__label">新しいパスワード（確認）</label>
                  <Field
                    component={renderTextField}
                    className="input input--required account-form__input"
                    type="password"
                    name="passwordConfirmation"
                  />
                </div>
                {
                  !props.pristine && props.invalid &&
                    <div className="account-form__error">
                      <div className="error-message">
                        パスワードが一致しません。再度入力してください。
                      </div>
                    </div>
                }
                <div className="account-form__button">
                  <button
                    disabled={props.pristine || props.submitting || props.invalid}
                    type="submit"
                    className="button button--primary button--large">
                    パスワードを登録</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </SiteMainSimple>
    </React.Fragment>
  );
}

export default FixProvisionalPassword
