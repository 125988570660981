import React from 'react';
import { Link } from 'react-router-dom';
import { Field } from 'redux-form'

import PublicHeader from '../parts/PublicHeader';
import SiteMainSimple  from '../parts/SiteMainSimple';
import { mappedProps } from 'containers/parent/PasswordReminder';

const PasswordReminder: React.FC<mappedProps> = (props: mappedProps) => {
  const resultfragment = (
    <div className="account-box">
      <div className="account-box__title">
        パスワード再設定
      </div>
      <div className="account-box__lead">
        メールを送信しました。<br />メールに記載されたURLにアクセスして、<br className="_pc" />パスワードの再設定を行ってください。
      </div>
      <div className="account-box__button">
        <Link to='/login' className="button button--large">ログイン画面へ戻る</Link>
      </div>
    </div>
  );

  const formFragment = (
    <div className="account-box account-box--with-foot">
      <div className="account-box__title">
        パスワード再設定
      </div>
      <div className="account-box__lead">
        パスワードを再設定用のURLお送りします。<br />
        本サービスにご登録済の保護者IDと<br />保護者メールアドレスを入力してください。
      </div>
      <div className="account-box__form">
        <form onSubmit={props.handleSubmit}>
          <div className="account-form">
            <div className="account-form__item">
              <label className="account-form__label">保護者ID</label>

              <Field
                component="input"
                className="input input--required account-form__input"
                type="login"
                name="login"
              />
            </div>

            <div className="account-form__item">
              <label className="account-form__label">保護者メールアドレス</label>

              <Field
                component="input"
                className="input input--required account-form__input"
                type="email"
                name="email"
              />
            </div>

            {
              !props.pristine && props.invalid &&
                <div className="account-form__error">
                  <div className="error-message">
                    保護者IDと保護者メールアドレスを正しく入力してください。
                  </div>
                </div>
            }

            <div className="account-form__button">
              <button
                disabled={props.pristine || props.submitting || props.invalid}
                type="submit"
                className="button button--finish button--large">送信</button>
            </div>
          </div>
        </form>
      </div>
      <div className="account-box__foot">
        <Link to='/login' className="account-box__link">ログイン画面へ戻る</Link>
      </div>
    </div>
  )

  return (
    <React.Fragment>
      <PublicHeader />
      <SiteMainSimple>
        { props.submitSucceeded ? resultfragment : formFragment }
      </SiteMainSimple>
    </React.Fragment>
  );
}

export default PasswordReminder
