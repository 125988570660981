import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router'
import * as Models from 'models/api';

import { AppState } from "store";
import targetComponent from 'components/trainer/StudentBookmark';
import trainerActions from 'actions/trainerActions';
import { getAvailableCurriculums }  from 'utils/store'

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch })

const mapStateToProps = (appState: AppState, ownProps: RouteComponentProps) => {
  const studentUser = appState.trainer.student.record
  return {
    curriculums: studentUser && getAvailableCurriculums(studentUser),
    api: appState.repository.authorizedApi!,
    user: appState.session.user!,
    trainer: appState.trainer,
    ...ownProps,
  }
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  { dispatch }: ReturnType<typeof mapDispatchToProps>) => {
  return {
    ...stateProps,

    getStudent: (student_id: number) => {
      const student = stateProps.trainer.student
      if (student.student_id !== student_id) {
        return null
      }
      return student.record
    },

    getStudentBookmarks: (params: Models.StudentBookmarksParams) => {
      return new Promise<Models.BookmarksResponse>((resolve, reject)=> { 
        dispatch(trainerActions.fetchStudentBookmarks(
          {
            params,
            promises: { resolve, reject }
          }
         )) 
      })  
    },

    getStudentBookmarksLearningCount: (params: { curriculumCodes: Models.StudentStudyLearningCountParams , studentId: number}) => {
      return new Promise<Models.StudentStudyLearningCountRecords>((resolve, reject)=> { 
         dispatch(trainerActions.fetchStudentBookmarksLearningCount(
           {
             params,
             promises: { resolve, reject }
           }
          )) 
       })  
    }
  }
}

type connectMappedProps = ReturnType<typeof mergeProps>
export type mappedProps = connectMappedProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(targetComponent)
)
