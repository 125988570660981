import React, { useEffect } from 'react';
import { Link, useParams, } from 'react-router-dom';
import classNames from 'classnames';
import Moment from 'react-moment';

import PrivateHeader from 'containers/parts/PrivateHeader';
import { mappedProps } from 'containers/parent/StudentStudyResultUnitResult';
import SiteMain from 'containers/parts/SiteMain';
import StudentBar from '../parts/CommonStudentBar'

const ParentStudentStudyResultUnitResult: React.FC<mappedProps> = (props: mappedProps) => {
  // MARK: 講師、トレーナーとインターフェース統一のためダミーIDを指定
  const id = 1;
  const { unit_id } = useParams();

  useEffect(() => {
    if (id && unit_id) {
      props.fetchData(Number(id), Number(unit_id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, unit_id]);

  const student = props.getStudent(Number(id))
  const page = student && props.getRecord(Number(id), Number(unit_id))

  return (
    <React.Fragment>
      <PrivateHeader />
      <SiteMain>
        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          {
            page &&
            <React.Fragment>
              <li className="breadcrumbs__item">
                <Link to={`/student/study_result/${page.curriculum.id}`} className="breadcrumbs__link">
                  { page.curriculum.name }
                </Link>
              </li>
              <li className="breadcrumbs__item">
                <Link to={`/student/study_result/courses/${page.course.id}`} className="breadcrumbs__link">
                  { page.course.name }
                </Link>
              </li>
              <li className="breadcrumbs__item">
                <Link to={`/student/study_result/units/${page.unit.id}`} className="breadcrumbs__link">
                  { page.unit.name }
                </Link>
              </li>
              <li className="breadcrumbs__item">
                結果比較
              </li>
            </React.Fragment>
          }
        </ol>

        {
          student && page &&
          <div className="site-main__main-contents">
            <StudentBar student={student} />

            <div className="page-title">
              { page.curriculum.name }&nbsp;
              <div className="page-title__badge">
                { page.course.name }
              </div>
              &nbsp;
              <br className="_sp" />{ page.unit.name }
            </div>

            <div className="study-result-box">
              <div className="study-result-box__inner">
                <div className="study-result-box__head">
                  <div className="study-result-box__title">
                    結果比較
                  </div>
                  <div className="study-result-box__point-head">
                    正解数／問題数
                  </div>
                  <div className="study-result-box__date-head">
                    実施日時
                  </div>
                </div>
                <div className="study-result-box__result">
                  <div className="study-result-box__result-title study-result-box__result-title--teacher">
                    受講後テスト
                  </div>
                  <div className="study-result-box__point">
                    <b>{ page.result.post_score }</b><span className="study-result-box__slash">/</span>{ page.result.post_total }
                    <div className="study-result-box__rank">
                      {/* NOTE： 単元理解度90%以上なら.study-result-box__rank-badge--s-rankをつけてSランク */}
                      <div className={classNames("study-result-box__rank-badge", {
                        "study-result-box__rank-badge--s-rank": page.result.post_rank === 'S'
                      })}>
                        { page.result.post_rank }ランク
                      </div>
                    </div>
                  </div>
                  <div className="study-result-box__date">
                    <span className="study-result-box__date-text">実施日時</span>
                    <Moment format="YYYY年M月D日 HH:mm">{ page.result.post_acted_at }</Moment>
                  </div>
                </div>
                <div className="study-result-box__result">
                  <div className="study-result-box__result-title study-result-box__result-title--teacher">
                    受講前テスト
                  </div>
                  <div className="study-result-box__point">
                    <b>{ page.result.pre_score }</b><span className="study-result-box__slash">/</span>{ page.result.pre_total }
                    <div className="study-result-box__rank">
                      {/* NOTE： 単元理解度0-50%でCランク、50-70%でBランク、70-90%でAランク */}
                      <div className={classNames("study-result-box__rank-badge", {
                        "study-result-box__rank-badge--s-rank": page.result.pre_rank === 'S'
                      })}>
                        { page.result.pre_rank }ランク
                      </div>
                    </div>
                  </div>
                  <div className="study-result-box__date">
                    <span className="study-result-box__date-text">実施日時</span>
                    <Moment format="YYYY年M月D日 HH:mm">{ page.result.pre_acted_at }</Moment>
                  </div>
                </div>
              </div>
              <div className="study-result-box__button">
                <Link
                  to={`/student/study_result/courses/${page.course.id}`}
                  className="button button--xlarge button--gray button--back">
                  単元一覧へ戻る
                </Link>
              </div>
            </div>

            <div className="back-home">
              <Link to='/' className="back-home__link">マイページへ戻る</Link>
            </div>
          </div>
        }

        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          {
            page &&
            <React.Fragment>
              <li className="breadcrumbs__item">
                <Link to={`/student/study_result/${page.curriculum.id}`} className="breadcrumbs__link">
                  { page.curriculum.name }
                </Link>
              </li>
              <li className="breadcrumbs__item">
                <Link to={`/student/study_result/courses/${page.course.id}`} className="breadcrumbs__link">
                  { page.course.name }
                </Link>
              </li>
              <li className="breadcrumbs__item">
                <Link to={`/student/study_result/units/${page.unit.id}`} className="breadcrumbs__link">
                  { page.unit.name }
                </Link>
              </li>
              <li className="breadcrumbs__item">
                結果比較
              </li>
            </React.Fragment>
          }
        </ol>
      </SiteMain>
    </React.Fragment>
  );
}

export default ParentStudentStudyResultUnitResult