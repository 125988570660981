import { routerMiddleware } from "connected-react-router";
import { applyMiddleware, createStore, combineReducers } from "redux";
import createSagaMiddleware from "redux-saga";
import { createBrowserHistory } from "history";
import { reducer as formReducer, FormStateMap } from 'redux-form'
import logger from "redux-logger";

import rootSaga from "sagas/index";

import { sessionReducer, SessionState } from 'states/sessionState';
import { repositoryReducer, RepositoryState } from 'states/repositoryState';
import { uiReducer, UIState } from 'states/uiState';
import { temporaryFormReducer, TemporaryFormState } from 'states/temporaryFormState';
import { infoReducer, InfoState } from 'states/infoState';
import { trainerReducer, TrainerState } from 'states/trainerState';
import { instructorReducer, InstructorState } from 'states/instructorState';
import { studentReducer, StudentState } from 'states/studentState';
import { parentReducer, ParentState } from 'states/parentState';
import { studyResultReducer, StudyResultState } from 'states/studyResultState';
import { borderResultsReducer, BorderResultsState } from 'states/borderResultsState';

import { getUIType } from './uiTypes';

import uiActions from 'actions/uiActions';

export type AppState = {
  session: SessionState,
  form: FormStateMap,
  repository: RepositoryState,
  ui: UIState,
  temporaryForm: TemporaryFormState,
  info: InfoState,
  trainer: TrainerState,
  instructor: InstructorState,
  student: StudentState,
  parent: ParentState,
  studyResult: StudyResultState,
  borderResults: BorderResultsState,
};

const configureStore = () => {
  const history = createBrowserHistory();
  const sagaMiddleware = createSagaMiddleware();

  const uiType = getUIType(
    window.location.hostname,
    process.env.REACT_APP_TYPE,
    process.env.REACT_APP_API_URL);

  const middleware = 
    uiType.env === 'production' || 'staging' ?
      applyMiddleware(sagaMiddleware, routerMiddleware(history))
      :
      applyMiddleware(sagaMiddleware, routerMiddleware(history), logger)
  const store = createStore(
    combineReducers<AppState>({
      session: sessionReducer,
      form: formReducer,
      repository: repositoryReducer,
      ui: uiReducer,
      temporaryForm: temporaryFormReducer,
      info: infoReducer,
      trainer: trainerReducer,
      instructor: instructorReducer,
      student: studentReducer,
      parent: parentReducer,
      studyResult: studyResultReducer,
      borderResults: borderResultsReducer,
    }),
    middleware,
  );

  sagaMiddleware.run(rootSaga).toPromise().catch(e => {
    // MARK: try/catchしていない例外がsagaの中で発生したとき
    const message = '処理できませんでした。ブラウザをリロードして再度お試しください。';
    const level = 'error';
    store.dispatch(uiActions.showNotification({ level, message }));
    console.error(e);
  });

  return store;
};

export default configureStore;
