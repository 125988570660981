import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router'

import { AppState } from "store";
import targetComponent from 'components/instructor/StudentQuestions';
import instructorActions from 'actions/instructorActions';
// import actions from 'actions/studyResultActions';

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch })

const mapStateToProps = (appState: AppState, ownProps: RouteComponentProps) => {
  return {
    user: appState.session.user!,
    instructor: appState.instructor,
    studyResult: appState.studyResult,
    ...ownProps,
  }
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  { dispatch }: ReturnType<typeof mapDispatchToProps>) => {
  return {
    ...stateProps,
    /*getStudent: (student_id: number) => {
      const student = stateProps.studyResult.student
      if (student.student_id !== student_id) {
        return null
      }
      return student.record
    },*/
    getStudentById: (id: number) => {
      if (!stateProps.instructor.question.records) {
        return null
      }
      return stateProps.instructor.question.records
        .map(record => record.student)
        .find(record => record.id === id)
    },
    getQuestions: (student_id: number) => {
      const page = stateProps.instructor.studentQuestion
      if (page.student_id !== student_id) {
        return null
      }
      return page.records
    },
    hasNextQuestion: (student_id: number) => {
      const page = stateProps.instructor.studentQuestion
      if (page.student_id !== student_id) {
        return false
      }
      return page.has_next
    },
    fetchData: (student_id: number) => {
      // 講師の受講生情報取得APIができたら
      // dispatch(actions.fetchStudent({ student_id }))
      dispatch(instructorActions.fetchStudentQuestionRecords({ student_id, initialize: true }))
    },
    fetchNext: () => {
      const studentQuestion = stateProps.instructor.studentQuestion
      const student_id = studentQuestion.student_id!
      dispatch(instructorActions.fetchStudentQuestionRecords({ student_id, initialize: false }))
    }
  }
}

export type mappedProps = ReturnType<typeof mergeProps>
export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(targetComponent))
