import React from 'react';
import { Redirect, useHistory } from 'react-router-dom';

import { mappedProps } from 'containers/student/SettingCardEdit';
import PrivateHeader from 'containers/parts/PrivateHeader';
import SiteSettingMainArea from '../common/SiteSettingMainArea';

const SettingCardEdit: React.FC<mappedProps> = (props: mappedProps) => {
  const history = useHistory()

  // MARK: コーポレートユーザ利用不可
  if (props.user.corporation) {
    return <Redirect to='/' />;
  }

  return (
    <React.Fragment>
      <PrivateHeader />
      <SiteSettingMainArea page="card_edit" user={props.user}>
        <div className="setting-box">
          <div className="setting-box__title setting-box__title--creditcard">
            クレジットカード情報変更
          </div>
          <div className="setting-box__lead">
            以下の注意事項をご確認の上、下記のリンクよりクレジットカード情報を変更してください。
          </div>
          <div className="setting-box__cautions">
            <ul className="account-caution-list">
              <li className="account-caution-list__item">
                { props.user.trial_days || 14 }日間無料トライアル期間中に、お支払いが発生することはありません。
              </li>
              <li className="account-caution-list__item">
                無料トライアル終了後については、初回ご請求額に記載のある金額分のお支払が発生します。
              </li>
              <li className="account-caution-list__item">
                無料トライアルは、初回のお申込みの時のみ適応となります。
              </li>
              <li className="account-caution-list__item">
                ご利用期間の終了時に、お選びいただいたプランで自動更新されます。
              </li>
              <li className="account-caution-list__item">
                解約・変更はユーザー画面からいつでもできます。ただし、長期プラン（６ヶ月プラン・１２ヶ月プラン）から１ヶ月プランに変更することはできません。
              </li>
              <li className="account-caution-list__item">
                追加質問等のオプション料金は、その都度、ご入力いただいたクレジットカードからお支払が発生します。
              </li>
              <li className="account-caution-list__item">
                トレーナーサポートオプションは、１ヶ月ごとの更新となり、その都度お支払が発生します。
              </li>
              <li className="account-caution-list__item">
                クレジットカード情報変更後、一度ログアウトされますので、再度ログインをしてください。
              </li>
            </ul>
          </div>
          <div className="form">
            <div className="form__buttons">
              <div className="form__button form__button--flex-item">
                <button
                  onClick={() => {
                    props.createCreditCardToken().then(token => {
                      window.location.href = props.paymentPageUrl + token

                      const scFunc = (window as any).sc_func
                      if (scFunc) { scFunc('kjone', 'creditcard', '') }
                    })
                  }}
                  className="button button--primary button--xlarge">変更する</button>
              </div>
              <div className="form__button form__button--flex-item">
                <button
                  onClick={() => history.push('/')}
                  className="button button--gray button--back button--xlarge">マイページに戻る</button>
              </div>
            </div>
          </div>
        </div>
      </SiteSettingMainArea>
    </React.Fragment>
  );
}

export default SettingCardEdit;
