import React, { useState } from 'react';
import classNames from 'classnames';

type props = {
}

const PointDescBalloon: React.FC<props> = (props: props) => {
  const [openPointDesc, setOpenPointDesc] = useState<boolean>(false);

  return <>
    <span className="balloon-toggler" onClick={() => {
      setOpenPointDesc(!openPointDesc)
      const body = document.querySelector('body')
      if (body) {
        body.classList.toggle('is-fixed');
        body.classList.toggle('is-fixed--mobile');
      }
    }}>ポイントとは</span>

    <div className={classNames("balloon balloon--form form__balloon", {
      'is-active': openPointDesc,
    })}>
      <div
        onClick={() => {
          setOpenPointDesc(false)
          const body = document.querySelector('body')
          if (body) {
            body.classList.remove('is-fixed', 'is-fixed--mobile');
          }
        }}
        className="balloon__close js-close-balloon">
        閉じる
      </div>
      <div className="balloon__lead">
        以下の学習を行うと、ポイントが貯まります。
      </div>
      <ol className="balloon__list balloon__list--number">
        <li className="balloon__list-body">
          <strong>１日１回学習を行う。</strong>（１回目の学習のみ）・・・<em>1pt</em>
        </li>
        <li className="balloon__list-body">
          <strong>受講前テストまたは受講後テストを１つ受験する。</strong>・・・<em>3pt</em>
        </li>
        <li className="balloon__list-body">
          <strong>AIおすすめ学習で、１つ「もっともおすすめの学習」を行う。</strong><br />（河合塾Oneで未履修のコンテンツを学習した場合のみ付与されます）・・・<em>1pt</em>
        </li>
      </ol>
      <dl className="balloon__list">
        <dt className="balloon__list-title">
          ポイントとは？
        </dt>
        <dd className="balloon__list-body">
          河合塾Oneでは学習を行うことで、ポイントが付与されます。<br/>
          上記３つの項目のうち、１は毎日深夜１時に前日の分が付与されます。２・３は学習を行うごとに付与されます。<br />
        </dd>
      </dl>
    </div>
  </>;
}

export default PointDescBalloon
