import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import PrivateHeader from 'containers/parts/PrivateHeader';
import { mappedProps } from 'containers/trainer/CsvDownload';
import SiteMain from 'containers/parts/SiteMain';
import classNames from 'classnames';
import Moment from 'react-moment';
import { CurridulumType } from 'utils/constants';

const TrainerCsvDownload: React.FC<mappedProps> = (props: mappedProps) => {
  const [currentTab, setCurrentTab] = useState<'list' | 'new'>('new')
  const [statuses, setStatuses] = useState<{ [key: string]: string | undefined; }>({})

  useEffect(() => {
    setStatuses(props.getDefaultStatuses())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentTab === 'list') {
      props.fetchFirst();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  const histories = props.getHistories()
  const hasNext = props.hasNext()
  const fetchNext = () => {
    props.fetchNext()
  }

  const create = (x: CurridulumType, downloadType: string) => {
    const key = props.getStatusKey(props.user.login, x, downloadType)

    if (statuses[key]) {
      return
    }

    props.setCreateCsvExportStatus(key)
    setStatuses({ ...statuses, [key]: key })

    props.createCsvExport({
      curriculum_id: x.id,
      type: downloadType as any,
    }).catch(e => {
      props.removeCreateCsvExportStatus(key)
      setStatuses({ ...statuses, [key]: undefined })
    })
  }

  const newTabContent = <ul className="trainer-study-result-list">
    {
      props.curriculums.map(x => (
        <li key={x.id} className="trainer-study-result-list__item">
          <div className="trainer-study-result-list__subject">
            { x.name }
          </div>
          <div className="trainer-study-result-list__body">
            <ul className="trainer-study-result-sublist">
              {
                props.downloadTypes.map(y => (
                  <li key={y.name} className="trainer-study-result-sublist__item">
                    <div className="trainer-study-result-sublist__title">
                      { y.name }
                    </div>
                    <div className="trainer-study-result-sublist__button">
                      {
                        statuses[props.getStatusKey(props.user.login, x, y.type)] ?
                        <span className="trainer-study-result-sublist__link is-disabled">予約済</span>:
                        <a
                          className="trainer-study-result-sublist__link"
                          href="#!"
                          onClick={e => {
                            e.preventDefault()
                            create(x, y.type)
                          }}
                        >
                          予約する
                        </a>
                      }
                    </div>
                  </li>
                ))
              }
            </ul>
          </div>
        </li>
      ))
    }
  </ul>

  const listTabContent = <ul className="trainer-study-result-history">
    {
      histories.map(x => (
        <li key={x.id} className="trainer-study-result-history__item">
          <div className="trainer-study-result-history__subject">
            { x.curriculum }
          </div>
          <div className="trainer-study-result-history__title">
            { x.data_type }
          </div>
          <div className="trainer-study-result-history__date">
            {
              ( x.status === '完了' || x.status === '失敗' ) ? 
              <Moment format="YYYY年M月D日 HH:mm">{ x.finished_at }</Moment>:
              '----'
            }
          </div>
          <div className="trainer-study-result-history__button">
            {
              x.status === '完了' &&
              <a
                className="trainer-study-result-history__link"
                download
                href={x.download_url}
              >ダウンロード</a>
            }
            {
              ( x.status === '処理中' || x.status === '処理待ち' ) &&
              <span className="trainer-study-result-history__text">{ x.status }</span>
            }
            {
              x.status === '失敗' &&
              <span className="trainer-study-result-history__text is-error">出力エラー</span>
            }
          </div>
        </li>
      ))
    }
    {
      hasNext && 
      <li className="trainer-study-result-history__more" onClick={e => {
        e.preventDefault()
        fetchNext()
      }}>
        次の履歴を表示
      </li>
    }
  </ul>

  return (
    <React.Fragment>
      <PrivateHeader />
      <SiteMain>
        <div className="site-main__main-inner">
          <ol className="breadcrumbs">
            <li className="breadcrumbs__item">
              <Link to='/' className="breadcrumbs__link">マイページ</Link>
            </li>
            <li className="breadcrumbs__item">
              学習データ（CSV出力）
            </li>
          </ol>

          <div className="site-main__main-contents">
            <div className="trainer-study-result">
              <div className="trainer-study-result__title">
                <div className="page-title page-title--no-border page-title--result">
                  学習データ<small>（CSV出力）</small>
                </div>
              </div>
              <div className="trainer-study-result__body">
                <div className="trainer-study-result-tab">
                  <div className="trainer-study-result-tab__item">
                    <a
                      className={classNames("trainer-study-result-tab__link", {
                        'is-active': currentTab === 'new',
                      })}
                      href="#!"
                      onClick={e => {
                        e.preventDefault()
                        setCurrentTab('new')
                      }}
                    >出力予約</a>
                  </div>
                  <div className="trainer-study-result-tab__item">
                    <a
                      className={classNames("trainer-study-result-tab__link", {
                        'is-active': currentTab === 'list',
                      })}
                      href="#!"
                      onClick={e => {
                        e.preventDefault()
                        setCurrentTab('list')
                      }}
                    >出力履歴</a>
                  </div>
                </div>
                { currentTab === 'new' && newTabContent }
                { currentTab === 'list' && listTabContent }
              </div>
            </div>
            <div className="back-home">
              <Link to='/' className="back-home__link">マイページへ戻る</Link>
            </div>
          </div>

          <ol className="breadcrumbs">
            <li className="breadcrumbs__item">
              <Link to='/' className="breadcrumbs__link">マイページ</Link>
            </li>
            <li className="breadcrumbs__item">
              学習データ（CSV出力）
            </li>
          </ol>
        </div>
      </SiteMain>
    </React.Fragment>
  );
}

export default TrainerCsvDownload
