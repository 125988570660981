
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router'

import { AppState } from "store";
import targetComponent from 'components/student/StudyQuestionLog';
import studentActions from 'actions/studentActions'
import * as Models from 'models/api';

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch })

const mapStateToProps = (appState: AppState, ownProps: RouteComponentProps) => {
  const questionDetail = appState.student.questionDetail

  const user = appState.session.user! as Models.StudentUser

  const availableQuestions = (
    !user.corporation ||
    ( user.corporation && user.corporation.available_services.questions )
  )

  return {
    user,
    availableQuestions,
    student: appState.student,
    questionDetail,
    ...ownProps,
  }
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  { dispatch } : ReturnType<typeof mapDispatchToProps>) => {
  return {
    fetchQuestion: (question_id: number) => {
      dispatch(studentActions.fetchQuestionDetail({ question_id }))
    },
    getRecord: (question_id: number) => {
      const page = stateProps.student.questionDetail
      if (page.question_id !== question_id) {
        return null
      }
      return page.record
    },
    ...stateProps,
  }
}

type connectMappedProps = ReturnType<typeof mergeProps>
export type mappedProps = connectMappedProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(targetComponent))
