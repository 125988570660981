import React from 'react';
import { HashRouter, Route, Redirect } from 'react-router-dom';

import Footer from 'components/parts/Footer';
import ScrollToTop from 'components/parts/ScrollToTop';
import Notification from 'containers/parts/Notification';

import SignupStep1 from 'containers/signup/SignupStep1'
import SignupTerm from 'components/signup/SignupTerm';
import SignupTerm2 from 'components/signup/SignupTerm2';
import SignupStep2 from 'containers/signup/SignupStep2'
import SignupResult from 'containers/signup/SignupResult'

const App: React.FC = () => {
  return (
    <React.Fragment>
      <Notification />
      <HashRouter>
        <ScrollToTop />
        <Route exact path='/' render={x => <Redirect to={`/step1${x.location.search}`} />} />
        <Route exact path='/step1' component={SignupStep1} />
        <Route exact path='/privacy_policy' component={SignupTerm} />
        <Route exact path='/terms' component={SignupTerm2} />
        <Route exact path='/step2' component={SignupStep2} />
        <Route exact path='/result' component={SignupResult} />
      </HashRouter>
      <Footer />
    </React.Fragment>
  );
}

export default App;
