import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';

import Footer from 'components/parts/Footer';
import ScrollToTop from 'components/parts/ScrollToTop';
import PrivateRoute from 'containers/parts/PrivateRoute';
import Notification from 'containers/parts/Notification';

import Login from 'containers/common/Login'
import Logout from 'containers/common/Logout';
import PasswordReset from 'containers/common/PasswordReset'
import PasswordReminder from 'containers/student/PasswordReminder'
import FixProvisionalPassword from 'containers/common/FixProvisionalPassword'
import FixPaymentInformation from 'containers/common/FixPaymentInformation'
import SettingPasswordEdit from 'containers/common/SettingPasswordEdit';
import Infos from 'containers/common/Infos';
import Info from 'containers/common/Info';

import SettingProfileEdit from 'containers/student/SettingProfileEdit';
import SettingCardEdit from 'containers/student/SettingCardEdit';
import SettingTrainerEdit from 'containers/student/SettingTrainerEdit';
import SettingWithdrawal from 'containers/student/SettingWithdrawal';
import SettingPointExchange from 'containers/student/SettingPointExchange';
import SettingPointExchangeConfirm from 'containers/student/SettingPointExchangeConfirm';
import SettingPointExchangeLogs from 'containers/student/SettingPointExchangeLogs';
import WithdrawalComplete from 'containers/common/WithdrawalComplete';
import Mypage from 'containers/student/Mypage';
import Guide from 'containers/student/Guide';

import RecommendStudies from 'containers/student/RecommendStudies';
//issue-545 
import EntrustStudy from 'containers/student/EntrustStudy';
import EntrustStudyCurriculum from 'containers/student/EntrustStudyCurriculum';
import EntrustStudyCourse from 'containers/student/EntrustStudyCourse';
import EntrustStudyUnit from 'containers/student/EntrustStudyUnit';
// issue-545
import RecommendStudyPretest from 'containers/student/RecommendStudyPretest';
import StudyQuestionLogs from 'containers/student/StudyQuestionLogs';
import StudyQuestionLog from 'containers/student/StudyQuestionLog';
import Schedules from 'containers/student/Schedules';
import Messages from 'containers/student/Messages';
import StudyLogs from 'containers/student/StudyLogs';
import StudyLogQuestionForm from 'containers/student/StudyLogQuestionForm';
import StudyLogBuyTicket from 'containers/student/StudyLogBuyTicket';
import StudyLogPointExchangeConfirm from 'containers/student/StudyLogPointExchangeConfirm';

import StudyCurriculum from 'containers/student/StudyCurriculum';
import StudyCourse from 'containers/student/StudyCourse';
import StudyCourseResult from 'containers/student/StudyCourseResult';
import StudyCourseCompleteResult from 'containers/student/StudyCourseCompleteResult';
import StudyUnit from 'containers/student/StudyUnit';
import StudyUnitResult from 'containers/student/StudyUnitResult';
import StudyTopic from 'containers/student/StudyTopic';
// issue-547
import Bookmark from 'containers//student/Bookmark';

import PayConfirm from 'containers/student/PayConfirm';
import RegisterBorderResults from 'containers/student/RegisterBorderResults';

// campaign
import CampaignShiro202003 from 'containers/student/campaign/Shiro202003';
import { Helmet } from 'react-helmet';

const App: React.FC = () => {
  return (
    <React.Fragment>
      <Notification />

      <HashRouter>

        <Switch>
          <Route exact path='/login' />
          {/* // MARK: ログイン画面以外のtitleを変更 */}
          <Route>
            <Helmet>
              <title>河合塾One</title>
              <meta name="description" content={''} />
            </Helmet>
          </Route>
        </Switch>

        <ScrollToTop />

        <Route exact path='/password_reminder' component={PasswordReminder} />
        <Route exact path='/password_reset' component={PasswordReset} />
        <Route exact path='/login' component={Login} />
        <Route exact path='/fix_provisional_password' component={FixProvisionalPassword} />
        <Route exact path='/fix_payment_information' component={FixPaymentInformation} />
        <Route exact path='/logout' component={Logout} />
        <Route exact path='/withdrawal_complete' component={WithdrawalComplete} />
        <Route exact path='/pay_confirm' component={PayConfirm} />
        <Route exact path='/regist' component={RegisterBorderResults} />

        <PrivateRoute exact path='/setting/card_edit' component={SettingCardEdit} />
        <PrivateRoute exact path='/setting/trainer_edit' component={SettingTrainerEdit} />
        <PrivateRoute exact path='/setting/withdrawal' component={SettingWithdrawal} />
        <PrivateRoute exact path='/setting/point_exchange' component={SettingPointExchange} />
        <PrivateRoute exact path='/setting/point_exchange_logs' component={SettingPointExchangeLogs} />
        <PrivateRoute exact path='/setting/point_exchange/confirm' component={SettingPointExchangeConfirm} />
        <PrivateRoute exact path='/' component={Mypage} />
        <PrivateRoute exact path='/guide' component={Guide} />
        <PrivateRoute exact path='/infos' component={Infos} />
        <PrivateRoute exact path='/infos/:id' component={Info} />
        <PrivateRoute exact path='/setting/password_edit' component={SettingPasswordEdit} />
        <PrivateRoute exact path='/recommends/:curriculum' component={RecommendStudies} />
        {/* issue-545 */}
        <PrivateRoute exact path='/entrust/:curriculum' component={EntrustStudy} />
        <PrivateRoute exact path='/entrust_setting/:curriculum_id' component={EntrustStudyCurriculum} />
        <PrivateRoute exact path='/entrust_setting/courses/:course_id' component={EntrustStudyCourse} />
        <PrivateRoute exact path='/entrust_setting/units/:unit_id' component={EntrustStudyUnit} />
        {/* issue-545 */}
        <PrivateRoute exact path='/recommends/:curriculum/:index' component={RecommendStudyPretest} />
        <PrivateRoute exact path='/setting/profile_edit' component={SettingProfileEdit} />
        <PrivateRoute exact path='/schedules' component={Schedules} />
        <PrivateRoute exact path='/messages' component={Messages} />
        <PrivateRoute exact path='/study_logs' component={StudyLogs} />
        <PrivateRoute exact path='/study_logs/:curriculum' component={StudyLogs} />
        <PrivateRoute exact path='/study_logs/:curriculum/:id' component={StudyLogQuestionForm} />
        <PrivateRoute exact path='/study_logs/:curriculum/:id/buy_ticket' component={StudyLogBuyTicket} />
        <PrivateRoute exact path='/study_logs/:curriculum/:id/point_exchange_confirm' component={StudyLogPointExchangeConfirm} />
        {/* issue-547 */}
        <PrivateRoute exact path='/bookmark' component={Bookmark} />

        <PrivateRoute exact path='/study/:curriculum_id' component={StudyCurriculum} />
        <PrivateRoute exact path='/study/courses/:course_id' component={StudyCourse} />
        <PrivateRoute exact path='/study/course_results/:curriculum_id' component={StudyCourseResult} />
        <PrivateRoute exact path='/study/course_results/:curriculum_id/:course_id' component={StudyCourseResult} />
        <PrivateRoute exact path='/study/course_complete_results/:unit_id' component={StudyCourseCompleteResult} />
        <PrivateRoute exact path='/study/units/:unit_id' component={StudyUnit} />
        <PrivateRoute exact path='/study/unit_results/:unit_id' component={StudyUnitResult} />
        <PrivateRoute exact path='/study/topics/:topic_id' component={StudyTopic} />

        <PrivateRoute exact path='/study_question_logs' component={StudyQuestionLogs} />
        <PrivateRoute exact path='/study_question_logs/:id' component={StudyQuestionLog} />

        <PrivateRoute exact path='/campaign/shiro202003' component={CampaignShiro202003} />

      </HashRouter>

      <Footer />
    </React.Fragment>
  );
}

export default App;
