import moment, { Moment } from "moment";
import { Plan } from 'utils/constants'

export const campaignPlans: Plan[] = [
  {
    id: 3,
    recommend: true,
    price: '2,665',
    price_with_tax: '2,931',
    name: '１年(12ヶ月)プラン',
    selected_price: '32,980',
    selected_price_with_tax: '36,278',
    selected_desc: '１年(12ヶ月)プラン 31,980円（税込 35,178円）【12ヶ月分】',

    lump_sum_price: '31,980',
    lump_sum_price_with_tax: '35,178',
  },
  {
    id: 2,
    recommend: false,
    price: '3,330',
    price_with_tax: '3,663',
    name: '6ヶ月プラン',

    selected_price: '20,980',
    selected_price_with_tax: '23,078',
    selected_desc: '6ヶ月プラン 19,980円（税込 21,978円）【6ヶ月分】',

    lump_sum_price: '19,980',
    lump_sum_price_with_tax: '21,978',
  },
  {
    id: 1,
    recommend: false,
    price: '3,980',
    price_with_tax: '4,378',
    name: '1ヶ月プラン',

    selected_price: '4,980',
    selected_price_with_tax: '5,478',
    selected_desc: '1ヶ月プラン 3,980円（税込 4,378円）【1ヶ月分】',

    lump_sum_price: '3,980',
    lump_sum_price_with_tax: '4,378',
  },
];

const setting: { [key: string]: any } = {
  production: {
    startAt:   moment('2023-11-06T00:00:00+0900'),
    endAt:     moment('2024-05-07T23:59:59+0900'),
  },
  staging: {
    startAt:   moment('2023-10-23T00:00:00+0900'),
    endAt:     moment('2024-05-07T23:59:59+0900'),
  },
  development: {
    startAt:   moment('2023-10-23T00:00:00+0900'),
    endAt:     moment('2024-05-07T23:59:59+0900'),
  }
}

export const isAvailable = (type: string, now: Moment): boolean => {
  const endAt = setting[type].endAt
  const startAt = setting[type].startAt
  return endAt > now && now >= startAt
}
