import React from 'react';
import { Link } from 'react-router-dom';

const Desc: React.FC = () => {
  return (
    <React.Fragment>
      <div className="setting-box__subtitle">
        ポイントのご利用について
      </div>
      <div className="setting-box__explanations">
        <dl className="point-exchange-explanations">
          <dt className="point-exchange-explanations__head">
            プラン料金割引
          </dt>
          <dd className="point-exchange-explanations__body">
            <ul className="point-exchange-explanations__list">
              <li className="point-exchange-explanations__list-item">
                次回お支払い金額から、ご利用ポイント分の金額が差し引かれます。
              </li>
            </ul>
          </dd>
          <dt className="point-exchange-explanations__head">
            Amazonギフト券（Eメールタイプ）への交換
          </dt>
          <dd className="point-exchange-explanations__body">
            <ul className="point-exchange-explanations__list">
              <li className="point-exchange-explanations__list-item">
                ご利用いただくにはAmazonのアカウントが必要です。
              </li>
              <li className="point-exchange-explanations__list-item">
                Amazonギフト券（Eメールタイプ）を、ご登録の受講者メールアドレス宛に送付されます。
              </li>
              <li className="point-exchange-explanations__list-item">
                受講者メールアドレスの誤入力など、お客様のご入力情報の誤りによる誤配送があった場合、Amazonギフト券の再発行は致しかねます。お申し込み前に、必ずプロフィールからメールアドレスの誤りがないか、確認をお願いいたします。
              </li>
              <li className="point-exchange-explanations__list-item">
                Amazonギフト券の送付には、最大15営業日程度かかります。
              </li>
              <li className="point-exchange-explanations__list-item">
                Amazonギフト券は、Amazonのドメイン（@gc.email.amazon.co.jp）から送付されます。受信ができるようメールの設定をお願いいたします。
              </li>
              <li className="point-exchange-explanations__list-item">
                ギフト券のご利用方法については、<a className="point-exchange-explanations__link" href="https://www.amazon.co.jp/b?node=6105751051" target="_blank" rel="noopener noreferrer">こちら</a>をご確認ください。
              </li>
            </ul>
            <div className="point-exchange-explanations__note">
              Amazonは、Amazon.com, Inc.またはその関連会社の商標です。
            </div>
          </dd>
          <dt className="point-exchange-explanations__head">
            質問チケットへの交換
          </dt>
          <dd className="point-exchange-explanations__body">
            <strong>当月分の質問チケットが０枚の場合に、ポイントを使って質問チケットに交換することができます。</strong><br />質問チケットへの交換は、
            <Link className="point-exchange-explanations__link" to='/study_logs'>学習履歴</Link>
            から質問を行う「先生に質問」画面上で行うことができます。
          </dd>
        </dl>
      </div>
    </React.Fragment>
  );
}

export default Desc;

