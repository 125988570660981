import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router'

import { AppState } from "store";
import targetComponent from 'components/instructor/Question';
import instructorActions from 'actions/instructorActions'

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch })

const mapStateToProps = (appState: AppState, ownProps: RouteComponentProps) => {
  return {
    user: appState.session.user!,
    instructor: appState.instructor,
    ...ownProps,
  }
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  { dispatch }: ReturnType<typeof mapDispatchToProps>) => {
  return {
    ...stateProps,
    fetchData: (id: number) => {
      dispatch(instructorActions.fetchQuestionDetail({ id }))
      if (!stateProps.instructor.processingQuestion.id) {
        // MARK: 対応中の質問を取得する
        dispatch(instructorActions.fetchQuestionRecords({ initialize: true, type: 'processing' }))
      }
    },
    getQuestion: (id: number) => {
      const page = stateProps.instructor.questionDetail
      if (id !== page.question_id) {
        return null
      }
      return page.record
    },
    updateQuestionToProcessing: () => {
      dispatch(instructorActions.updateQuestionDetailToProcessing())
    },
    updateQuestionToNotPending: () => {
      return new Promise<void>((resolve, reject) => {
        dispatch(instructorActions.updateQuestionDetailToNotPending({
          promises: { resolve, reject }
        }))
      })
    },
  }
}

export type mappedProps = ReturnType<typeof mergeProps>

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(targetComponent))
