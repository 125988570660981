import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import moment from "moment";

import { AppState } from "store";
import targetComponent from 'components/student/campaign/Shiro202003';
import * as Models from 'models/api'

import * as campaignSetting from 'utils/campaign/shiro202003'

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch })

const mapStateToProps = (appState: AppState) => {
  const user = appState.session.user! as Models.StudentUser
  const now = moment()
  const env = appState.ui.env!
  const isAvailable = campaignSetting.isAvailable(env, now)
  const isMainVideoOpened = campaignSetting.isOpenedKey(env, now, 'mainVideo')
    && user.status === 'regular'
  const isSubVideo1Opened = campaignSetting.isOpenedKey(env, now, 'subVideo1')
  const isSubVideo2Opened = campaignSetting.isOpenedKey(env, now, 'subVideo2')
  const isSubVideo3Opened = campaignSetting.isOpenedKey(env, now, 'subVideo3')

  return {
    api: appState.repository.authorizedApi!,
    user,
    isAvailable,
    isMainVideoOpened,
    isSubVideo1Opened,
    isSubVideo2Opened,
    isSubVideo3Opened,
  }
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  { dispatch }: ReturnType<typeof mapDispatchToProps>) => {
  return {
    ...stateProps,
  }
}

export type mappedProps = ReturnType<typeof mergeProps>

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(targetComponent)