import { get } from 'lodash'
import React, { useState } from "react"
import { WrappedFieldProps, WrappedFieldsProps, Fields } from "redux-form"

import Mousetrap from 'mousetrap';
import { makeEmailPredictions } from 'utils/predictEmail';

export const renderInputField = ({
  input,
  meta: { touched, error },
  ...custom
}: WrappedFieldProps) => {
  let className = (custom as any).className || ''
  if (touched && error) {
    className += ' input--invalid'
  }
  return (
    <input
      {...input}
      {...custom}
      className={className}
    />
  )
}

export const renderSelectField = ({
  input,
  meta: { touched, error },
  children,
  ...custom
}: WrappedFieldProps & { children: any }) => {
  let className = (custom as any).className || ''
  if (touched && error) {
    className += ' select__select--invalid'
  }
  return (
    <select
      {...input}
      {...custom}
      className={className}
    >
      <optgroup label="">
        {children}
      </optgroup>
    </select>
  )
}

export const renderTextareaField = ({
  input,
  meta: { touched, error },
  ...custom
}: WrappedFieldProps) => {
  let className = (custom as any).className || ''
  // TODO: 仕様確認
  /*
  if (touched && error) {
    className += ' textarea--invalid'
  }*/
  return (
    <textarea
      {...input}
      {...custom}
      className={className}
    />
  )
}

export const renderIfFieldsHasError = (fields: WrappedFieldsProps & { names: string[] }) => {
  if(fields.names.every(x => {
    const field = get(fields, x)
    if (!field) { return false }
    return field.meta.valid
  })) {
    return (
      <React.Fragment></React.Fragment>
    )
  }
  return (
    <React.Fragment>{fields.children}</React.Fragment>
  );
}

type formErrorMessageProps = {
  fieldNames: string[]
  message: string;
}

export const FormErrorMessage: React.FC<formErrorMessageProps> = (props: formErrorMessageProps) => (
  <Fields names={props.fieldNames} component={renderIfFieldsHasError}>
    <div className="form__error">
      <div className="error-message">
        { props.message }
      </div>
    </div>
  </Fields>
)

type formFileInputLoadResult = {
  file: File,
  data: ArrayBuffer | string | null;
}

type formFileInputProps = {
  limitSize: number;
  accept: string;
  className: string;
  onFailed: () => void;
  onLoad: (data: formFileInputLoadResult) => void;
}

export const FormFileInput: React.FC<formFileInputProps> = (props: formFileInputProps) => (
  <input className={props.className} type="file" accept={props.accept} onChange={(e) => {
    const target: HTMLInputElement = e.target as HTMLInputElement;
    if (target.files) {
      const file = target.files.item(0);
      if (file) {
        const limitSize = props.limitSize
        if (file.size > limitSize) {
          props.onFailed()
          target.value = ''
        } else {
          const fileReader = new FileReader();
          fileReader.onload = () => {
            props.onLoad({
              file: file,
              data: fileReader.result
            })
          }
          fileReader.readAsDataURL(file);
        }
      }
    }
  }} />
)

type renderEmailFieldProps = {
  change: Function;
}

export const RenderEmailField = ({
  input,
  meta: { touched, error },
  change,
  ...custom
}: WrappedFieldProps & renderEmailFieldProps) => {
  const [focusedEmailId, setFocusedEmailId] = useState<null|string>(null);
  const [predictionIndex, setPredictionIndex] = useState(-1);
  const [lastPredictionCount, setLastPredictionCount] = useState(0);
  const value = input.value
  let className = (custom as any).className || ''
  if (touched && error) {
    className += ' input--invalid'
  }
  const id = input.name;
  const emailPredictions: {[key: string]: string[]} = {}

  const emailPrediction = makeEmailPredictions(value);
  emailPredictions[id] = emailPrediction;
  const form = document.getElementsByName(id)[0];

  if (form) {
    form.onfocus = () => {
      setPredictionIndex(0);
      setFocusedEmailId(id);
    }
    form.onblur = () => {
      Mousetrap.unbind(['up', 'down', 'enter']);
      setTimeout(() => {
        setFocusedEmailId(null);
      }, 100)
    }
    const predictionCount = emailPrediction.length;
    
    if (0 !== predictionCount && focusedEmailId === id) {
      if (lastPredictionCount !== predictionCount) {
        setLastPredictionCount(predictionCount);
        setPredictionIndex(0);
      }
      Mousetrap.bind('up', (e) => {
        e.preventDefault();
        setPredictionIndex((predictionIndex + predictionCount - 1) % predictionCount);
      })
      Mousetrap.bind('down', (e) => {
        e.preventDefault();
        setPredictionIndex((predictionIndex + 1) % predictionCount);
      })
      Mousetrap.bind('enter', (e) => {
        e.preventDefault();
        change(id, emailPrediction[predictionIndex]);
      })
    }
  }

  return (
    <div>
      <input
        {...input}
        {...custom}
        className={className}
      />

      { focusedEmailId === id && 0 < emailPredictions[id].length &&
        <ul className="email-prediction-list">
          {emailPredictions[id].map((predict, i) => 
            <li key={i} className={predictionIndex === i ? "email-prediction-list--select" : ""} onMouseDown={() => change(id, predict)}><span className="form__note--orange">{value}</span>{predict.slice(value.length)}</li>
          )}
        </ul>
      }
    </div>
  )
}