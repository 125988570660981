import React from 'react';

import { MasterData } from 'models/api';

import { renderSelectField, renderIfFieldsHasError } from 'components/parts/FormParts'
import { Field, Fields } from 'redux-form';

type props = {
  masterData: MasterData | null, 
  submitFailed: boolean;
}

const DefaultStudyPlanForm: React.FC<props> = (props: props) => {

  return <div className="setting-box__form">
    <div className="form">
      <div className="form__item">
        <div className="form__label form__label--required">
          中学英語
        </div>
        <div className="form__field">
          <div className="select form__select-wrapper">
            <Field
              component={renderSelectField}
              className="select__select select__select--required"
              name="recommend_junior_english">
              <option value="">選択してください</option>
              {
                props.masterData && props.masterData.junior_english_levels.map(
                  x => <option key={x.level || 0} value={x.level || 0}>{x.course_names}</option>)
              }
            </Field>

            {
              props.submitFailed &&
              <Fields names={[ 'recommend_junior_english' ]} component={renderIfFieldsHasError}>
                <div className="form__error">
                  <div className="error-message">
                    選択してください。
                  </div>
                </div>
              </Fields>
            }
          </div>
        </div>
      </div>
      <div className="form__item">
        <div className="form__label form__label--required">
        中学数学
        </div>
        <div className="form__field">
          <div className="select form__select-wrapper">
            <Field
              component={renderSelectField}
              className="select__select select__select--required"
              name="recommend_junior_math">
              <option value="">選択してください</option>
              {
                props.masterData && props.masterData.junior_math_levels.map(
                  x => <option key={x.level || 0} value={x.level || 0}>{x.course_names}</option>)
              }
            </Field>

            {
              props.submitFailed &&
              <Fields names={[ 'recommend_junior_math' ]} component={renderIfFieldsHasError}>
                <div className="form__error">
                  <div className="error-message">
                    選択してください。
                  </div>
                </div>
              </Fields>
            }
          </div>
        </div>
      </div>
      <div className="form__item">
        <div className="form__label form__label--required">
        中学理科
        </div>
        <div className="form__field">
          <div className="select form__select-wrapper">
            <Field
              component={renderSelectField}
              className="select__select select__select--required"
              name="recommend_junior_science">
              <option value="">選択してください</option>
              {
                props.masterData && props.masterData.junior_science_levels.map(
                  x => <option key={x.level || 0} value={x.level || 0}>{x.course_names}</option>)
              }
            </Field>

            {
              props.submitFailed &&
              <Fields names={[ 'recommend_junior_science' ]} component={renderIfFieldsHasError}>
                <div className="form__error">
                  <div className="error-message">
                    選択してください。
                  </div>
                </div>
              </Fields>
            }
          </div>
        </div>
      </div>
      <div className="form__item form__item--text">
        <div className="form__label">
          英語
        </div>
        <div className="form__field">
          <div className="form__field-text">
            英文法・英文解釈
          </div>
          {/* <div className="select form__select-wrapper">
            <Field
              component={renderSelectField}
              className="select__select select__select--required"
              name="recommend_english">
              <option value="">選択してください</option>
              {
                // MARK: 英語は受講しない設定は不可
                props.masterData && props.masterData.english_levels.filter(x => x.level !== null).map(
                  x => <option key={x.level || 0} value={x.level || 0}>{x.course_names}</option>)
              }
            </Field>

            <Fields names={[ 'recommend_english' ]} component={renderIfFieldsHasError}>
              <div className="form__error">
                <div className="error-message">
                  選択してください。
                </div>
              </div>
            </Fields>
          </div> */}
        </div>
      </div>
      <div className="form__item">
        <div className="form__label form__label--required">
          数学
        </div>
        <div className="form__field">
          <div className="select form__select-wrapper">
            <Field
              component={renderSelectField}
              className="select__select select__select--required"
              name="recommend_math">
              <option value="">選択してください</option>
              {
                props.masterData && props.masterData.math_levels.map(
                  x => <option key={x.level || 0} value={x.level || 0}>{x.course_names}</option>)
              }
            </Field>

            <Fields names={[ 'recommend_math' ]} component={renderIfFieldsHasError}>
              <div className="form__error">
                <div className="error-message">
                  選択してください。
                </div>
              </div>
            </Fields>
          </div>
          <div className="form__note">
            IAの方はIA、IIBCの方はIAIIBを範囲としてください。
          </div>
        </div>
      </div>

      <div className="form__item">
        <div className="form__label form__label--required">
          物理
        </div>
        <div className="form__field">
          <div className="select form__select-wrapper">
            <Field
              component={renderSelectField}
              className="select__select select__select--required"
              name="recommend_physics">
              <option value="">選択してください</option>
              {
                props.masterData && props.masterData.physics_levels.map(
                  x => <option key={x.level || 0} value={x.level || 0}>{x.course_names}</option>)
              }
            </Field>

            {
              props.submitFailed &&
              <Fields names={[ 'recommend_physics' ]} component={renderIfFieldsHasError}>
                <div className="form__error">
                  <div className="error-message">
                    選択してください。
                  </div>
                </div>
              </Fields>
            }

          </div>
        </div>
      </div>

      <div className="form__item">
        <div className="form__label form__label--required">
          化学
        </div>
        <div className="form__field">
          <div className="select form__select-wrapper">
            <Field
              component={renderSelectField}
              className="select__select select__select--required"
              name="recommend_chemistry">
              <option value="">選択してください</option>
              {
                props.masterData && props.masterData.chemistry_levels.map(
                  x => <option key={x.level || 0} value={x.level || 0}>{x.course_names}</option>)
              }
            </Field>

            {
              props.submitFailed &&
              <Fields names={[ 'recommend_chemistry' ]} component={renderIfFieldsHasError}>
                <div className="form__error">
                  <div className="error-message">
                    選択してください。
                  </div>
                </div>
              </Fields>
            }
          </div>
        </div>
      </div>

      <div className="form__item">
        <div className="form__label form__label--required">
          現代文
        </div>
        <div className="form__field">
          <div className="select form__select-wrapper">
            <Field
              component={renderSelectField}
              className="select__select select__select--required"
              name="recommend_modern_japanese">
              <option value="">選択してください</option>
              {
                props.masterData && props.masterData.modern_japanese_levels.map(
                  x => <option key={x.level || 0} value={x.level || 0}>{x.course_names}</option>)
              }
            </Field>

            {
              props.submitFailed &&
              <Fields names={[ 'recommend_modern_japanese' ]} component={renderIfFieldsHasError}>
                <div className="form__error">
                  <div className="error-message">
                    選択してください。
                  </div>
                </div>
              </Fields>
            }
          </div>
        </div>
      </div>

      <div className="form__item">
        <div className="form__label form__label--required">
          古文
        </div>
        <div className="form__field">
          <div className="select form__select-wrapper">
            <Field
              component={renderSelectField}
              className="select__select select__select--required"
              name="recommend_classical_japanese">
              <option value="">選択してください</option>
              {
                props.masterData && props.masterData.classical_japanese_levels.map(
                  x => <option key={x.level || 0} value={x.level || 0}>{x.course_names}</option>)
              }
            </Field>

            {
              props.submitFailed &&
              <Fields names={[ 'recommend_classical_japanese' ]} component={renderIfFieldsHasError}>
                <div className="form__error">
                  <div className="error-message">
                    選択してください。
                  </div>
                </div>
              </Fields>
            }
          </div>
        </div>
      </div>

      <div className="form__item">
        <div className="form__label form__label--required">
          日本史
        </div>
        <div className="form__field">
          <div className="select form__select-wrapper">
            <Field
              component={renderSelectField}
              className="select__select select__select--required"
              name="recommend_japanese_history">
              <option value="">選択してください</option>
              {
                props.masterData && props.masterData.japanese_history_levels.map(
                  x => <option key={x.level || 0} value={x.level || 0}>{x.course_names}</option>)
              }
            </Field>

            {
              props.submitFailed &&
              <Fields names={[ 'recommend_japanese_history' ]} component={renderIfFieldsHasError}>
                <div className="form__error">
                  <div className="error-message">
                    選択してください。
                  </div>
                </div>
              </Fields>
            }
          </div>
        </div>
      </div>

      <div className="form__item">
        <div className="form__label form__label--required">
          世界史
        </div>
        <div className="form__field">
          <div className="select form__select-wrapper">
            <Field
              component={renderSelectField}
              className="select__select select__select--required"
              name="recommend_world_history">
              <option value="">選択してください</option>
              {
                props.masterData && props.masterData.world_history_levels.map(
                  x => <option key={x.level || 0} value={x.level || 0}>{x.course_names}</option>)
              }
            </Field>

            {
              props.submitFailed &&
              <Fields names={[ 'recommend_world_history' ]} component={renderIfFieldsHasError}>
                <div className="form__error">
                  <div className="error-message">
                    選択してください。
                  </div>
                </div>
              </Fields>
            }
          </div>
        </div>
      </div>
      <div className="form__item">
        <div className="form__label form__label--required">
          生物
        </div>
        <div className="form__field">
          <div className="select form__select-wrapper">
            <Field
              component={renderSelectField}
              className="select__select select__select--required"
              name="recommend_biology">
              <option value="">選択してください</option>
              {
                props.masterData && props.masterData.biology_levels.map(
                  x => <option key={x.level || 0} value={x.level || 0}>{x.course_names}</option>)
              }
            </Field>

            {
              props.submitFailed &&
              <Fields names={[ 'recommend_biology' ]} component={renderIfFieldsHasError}>
                <div className="form__error">
                  <div className="error-message">
                    選択してください。
                  </div>
                </div>
              </Fields>
            }
          </div>
        </div>
      </div>

      <div className="form__item">
        <div className="form__label form__label--required">
          英単語
        </div>
        <div className="form__field">
          <div className="select form__select-wrapper">
            <Field
              component={renderSelectField}
              className="select__select select__select--required"
              name="recommend_english_words">
              <option value="">選択してください</option>
              {
                props.masterData && props.masterData.english_words_levels.map(
                  x => <option key={x.level || 0} value={x.level || 0}>{x.course_names}</option>)
              }
            </Field>

            {
              props.submitFailed &&
              <Fields names={[ 'recommend_english_words' ]} component={renderIfFieldsHasError}>
                <div className="form__error">
                  <div className="error-message">
                    選択してください。
                  </div>
                </div>
              </Fields>
            }
          </div>
        </div>
      </div>

      <div className="form__item">
        <div className="form__label form__label--required">
          新課程数学
        </div>
        <div className="form__field">
          <div className="select form__select-wrapper">
            <Field
              component={renderSelectField}
              className="select__select select__select--required"
              name="recommend_new_math">
              <option value="">選択してください</option>
              {
                props.masterData && props.masterData.new_math_levels.map(
                  x => <option key={x.level || 0} value={x.level || 0}>{x.course_names}</option>)
              }
            </Field>

            {
              props.submitFailed &&
              <Fields names={[ 'recommend_new_math' ]} component={renderIfFieldsHasError}>
                <div className="form__error">
                  <div className="error-message">
                    選択してください。
                  </div>
                </div>
              </Fields>
            }
          </div>
        </div>
      </div>
      {/* issue-673 */}
      <div className="form__item">
        <div className="form__label form__label--required">
          新課程化学
        </div>
        <div className="form__field">
          <div className="select form__select-wrapper">
            <Field
              component={renderSelectField}
              className="select__select select__select--required"
              name="recommend_new_chemistry_2024">
              <option value="">選択してください</option>
              {
                props.masterData && props.masterData.new_chemistry_2024_levels.map(
                  x => <option key={x.level || 0} value={x.level || 0}>{x.course_names}</option>)
              }
            </Field>

            {
              props.submitFailed &&
              <Fields names={[ 'recommend_new_chemistry_2024' ]} component={renderIfFieldsHasError}>
                <div className="form__error">
                  <div className="error-message">
                    選択してください。
                  </div>
                </div>
              </Fields>
            }
          </div>
        </div>
      </div>

      <div className="form__item">
        <div className="form__label form__label--required">
          新課程生物
        </div>
        <div className="form__field">
          <div className="select form__select-wrapper">
            <Field
              component={renderSelectField}
              className="select__select select__select--required"
              name="recommend_new_biology_2024">
              <option value="">選択してください</option>
              {
                props.masterData && props.masterData.new_biology_2024_levels.map(
                  x => <option key={x.level || 0} value={x.level || 0}>{x.course_names}</option>)
              }
            </Field>

            {
              props.submitFailed &&
              <Fields names={[ 'recommend_new_biology_2024' ]} component={renderIfFieldsHasError}>
                <div className="form__error">
                  <div className="error-message">
                    選択してください。
                  </div>
                </div>
              </Fields>
            }
          </div>
        </div>
      </div>

    </div>
  </div>

}

export default DefaultStudyPlanForm;
