import React, { useEffect } from 'react';

import PublicHeader from '../parts/PublicHeader';
import { mappedProps } from 'containers/common/Logout';

const Logout: React.FC<mappedProps> = (props: mappedProps) => {
  useEffect(() => {
    props.logout()
  });

  return (
    <React.Fragment>
      <PublicHeader />
      <main className="site-main site-main--register">
        <div className="site-main__main site-main__main--simple">
          <div className="site-main__main-inner site-main__main-inner--simple">
            <div className="site-main__main-contents">
              <div className="setting-box">
                <div className="setting-box__title setting-box__title--logout">
                  ログアウト
                </div>
                <div className="setting-message">
                  河合塾Oneからログアウトしました。
                </div>
                <div className="setting-box__button">
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      props.history.push('/login')
                    }}
                    href="#!"
                    className="button button--large">ログイン画面へ</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
}

export default Logout
