import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RouteProps } from 'react-router'

import targetComponent from 'components/student/PayConfirm';
import sessionActions from 'actions/sessionActions'
import { AppState } from "store";

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch })

const mapStateToProps = (appState: AppState, ownProps: RouteProps) => {
  return {
  }
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  { dispatch }: ReturnType<typeof mapDispatchToProps>) => {
  return {
    ...stateProps,
    logout: () => dispatch(sessionActions.deleteSession()),
  }
}

export type mappedProps = RouteProps & ReturnType<typeof mergeProps>
export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(targetComponent)
