import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { chunk } from 'lodash'

import PrivateHeader from 'containers/parts/PrivateHeader';
import { mappedProps } from 'containers/trainer/StudySummaries';
import SiteMain from 'containers/parts/SiteMain';

const perPage = 10

const colormap = [
  { color: 'gray', calc: (v: number) => v < 50 },
  { color: 'lightgreen', calc: (v: number) => v >= 50 && v < 70 },
  { color: 'darkgreen', calc: (v: number) => v >= 70 && v < 95 },
  { color: 'red', calc: (v: number) => v >= 95 },
]

const TrainerStudySummaries: React.FC<mappedProps> = (props: mappedProps) => {
  const [curriculumId, setCurriculumId] = useState<number>(0)
  const [schoolClassId, setSchoolClassId] = useState<number>(0)
  const [courseId, setCourseId] = useState<number>(0)

  useEffect(() => {
    props.fetchSchoolClassess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (curriculumId !== 0) {
      props.fetchCourses(curriculumId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curriculumId]);
  
  const schoolClassIdForApi = schoolClassId === 0 ? undefined : schoolClassId

  useEffect(() => {
    if (courseId === 0) {
      props.fetchStudySummariesPerCurriculum(schoolClassIdForApi);
    } else {
      props.fetchStudySummariesPerUnit(courseId, schoolClassIdForApi);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseId, schoolClassIdForApi]);

  const schoolClasses = props.trainer.schholClasses

  const courses = curriculumId === props.trainer.courses.curriculum_id && 
    props.trainer.courses.records

  const perCurriculum = props.trainer.studySummariesPerCurriculum
  const perUnit = props.trainer.studySummariesPerUnit

  // MARK: unitsは受講生ごとに違わないはず
  const allUnits = perUnit.records && perUnit.records.length > 0 && perUnit.records[0].units
  return (
    <React.Fragment>
      <PrivateHeader />
      <SiteMain>
        <div className="site-main__main-inner">
          <ol className="breadcrumbs">
            <li className="breadcrumbs__item">
              <Link to='/' className="breadcrumbs__link">マイページ</Link>
            </li>
            <li className="breadcrumbs__item">
              学習状況
            </li>
          </ol>

          <div className="site-main__main-contents">
            <div className="heatmap-container">
              <div className="heatmap-container__title">
                <div className="page-title page-title--no-border page-title--heatmap">
                  学習状況
                </div>
              </div>
              <div className="heatmap-container__filter">
                <div className="heatmap-filter">
                  <div className="heatmap-filter__item heatmap-filter__item--subject">
                    <div className="heatmap-filter__title">
                      教科／科目
                    </div>
                    <div className="heatmap-filter__body heatmap-filter__body--flex">
                      <div className="heatmap-filter__filter">
                        <div className="filter-select">
                          <select
                            className="filter-select__select"
                            value={curriculumId}
                            onChange={e => {
                              const x = Number(e.target.value)
                              setCurriculumId(x)
                              setCourseId(0)
                            }}
                          >
                            <option value={0}>
                              全教科
                            </option>
                            {
                              props.curriculums.map(x => (
                                <option key={x.id} value={x.id}>
                                  { x.name }
                                </option>
                              ))
                            }
                          </select>
                        </div>
                      </div>
                      <div className="heatmap-filter__filter">
                        <div className="filter-select is-disabled">
                          <select
                            className="filter-select__select"
                            value={courseId}
                            disabled={curriculumId === 0}
                            onChange={e => {
                              const x = Number(e.target.value)
                              setCourseId(x)
                            }}
                          >
                            <option value={0}>
                              科目を選択してください
                            </option>
                            {
                              courses && courses.map(x => (
                                <option key={x.id} value={x.id}>
                                  { x.title }
                                </option>
                              ))
                            }
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="heatmap-filter__item heatmap-filter__item--class">
                    <div className="heatmap-filter__title">
                      クラス
                    </div>
                    <div className="heatmap-filter__body">
                      <div className="heatmap-filter__filter">
                        <div className="filter-select">
                          <select
                            className="filter-select__select"
                            value={schoolClassId}
                            onChange={e => {
                              const x = Number(e.target.value)
                              setSchoolClassId(x)
                              setCurriculumId(0)
                              setCourseId(0)
                            }}
                          >
                            <option value="0">
                              選択してください
                            </option>
                            {
                              schoolClasses && schoolClasses.map(x => (
                                <option key={x.id} value={x.id}>
                                  { x.name }
                                </option>
                              ))
                            }
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="heatmap-container__body">
                {
                  courseId === 0 && perCurriculum.records && <>
                    <div className="heatmap-table">
                      <div className="heatmap-table__left">
                        {
                          chunk(perCurriculum.records, perPage).map((z, index) => (
                            <React.Fragment key={index}>
                              <div className="heatmap-table__head">
                                <div className="heatmap-table__head-item heatmap-table__head-item--student">
                                  担当受講者
                                </div>
                              </div>
                              {
                                z.map(x => (
                                  <div className="heatmap-table__body" key={x.id}>
                                    <div className="heatmap-table__body-item heatmap-table__body-item--student">
                                      <div className="heatmap-table__name">
                                        <Link to={`students/${x.id}/study_result`} className="account-box__link">
                                          { x.nick_name }
                                        </Link>
                                      </div>
                                      <div className="heatmap-table__id">
                                        { x.login }
                                      </div>
                                      <div className="heatmap-table__grade">
                                        { x.school_grade }
                                      </div>
                                      <div className="heatmap-table__trainer">
                                        { x.school_class }
                                      </div>
                                    </div>
                                  </div>
                                ))
                              }
                            </React.Fragment>
                          ))
                        }

                      </div>

                      <div className="heatmap-table__right">

                        {
                          chunk(perCurriculum.records, perPage).map((z, index) => (
                            <React.Fragment key={index}>
                              <div className="heatmap-table__head heatmap-table__head--bold">
                                {
                                  props.curriculums.map(x => (
                                    <div key={x.id} className="heatmap-table__head-item">
                                      { x.name }
                                    </div>
                                  ))
                                }
                              </div>

                              {
                                z.map(x => (
                                  <div className="heatmap-table__body heatmap-table__body--bold" key={x.id}>
                                    {
                                      props.curriculums.map(y => {
                                        const v = x.curriculums.find(x => x.id === y.id)
                                        if (!v) {
                                          return (
                                            <div key={y.id} className="heatmap-table__body-item heatmap-table__body-item--gray">
                                              0%
                                            </div>
                                          )
                                        }
                                        const color = colormap.find(color => color.calc(v.understanding))!.color
                                        return (
                                          <div key={y.id} className={`heatmap-table__body-item heatmap-table__body-item--${color}`}>
                                            { Math.floor(v.understanding)}%
                                          </div>
                                        )
                                      })
                                    }
                                  </div>
                                ))
                              }

                            </React.Fragment>
                          ))
                        }
                      </div>
                    </div>
                  </>
                }

                {
                  courseId !== 0 && courseId === perUnit.course_id && perUnit.records && allUnits && <>
                    <div className="heatmap-table">
                      <div className="heatmap-table__left">
                        {
                          chunk(perUnit.records, perPage).map((z, index) => (
                            <React.Fragment key={index}>
                              <div className="heatmap-table__head">
                                <div className="heatmap-table__head-item heatmap-table__head-item--student">
                                  担当受講者
                                </div>
                              </div>
                              {
                                z.map(x => (
                                  <div className="heatmap-table__body" key={x.id}>
                                    <div
                                      className="heatmap-table__body-item heatmap-table__body-item--student"
                                    >
                                      <div className="heatmap-table__name">
                                        <Link to={`students/${x.id}/study_result/courses/${courseId}`} className="account-box__link">
                                          { x.nick_name }
                                        </Link>
                                      </div>
                                      <div className="heatmap-table__id">
                                        { x.login }
                                      </div>
                                      <div className="heatmap-table__grade">
                                        { x.school_grade }
                                      </div>
                                      <div className="heatmap-table__trainer">
                                        { x.school_class }
                                      </div>
                                    </div>
                                  </div>
                                ))
                              }
                            </React.Fragment>
                          ))
                        }

                      </div>

                      <div className="heatmap-table__right">

                        {
                          chunk(perUnit.records, perPage).map((z, index) => (
                            <React.Fragment key={index}>
                              <div className="heatmap-table__head">
                                <div className="heatmap-table__head-item heatmap-table__head-item--overall">
                                  教科全体
                                </div>
                                {
                                  allUnits.map(x => (
                                    <div key={x.id} className="heatmap-table__head-item">
                                      { x.name }
                                    </div>
                                  ))
                                }
                              </div>

                              {
                                z.map(x => {
                                  const totalColor = colormap.find(color => color.calc(x.curriculum.understanding))!.color
                                  return (
                                    <div className="heatmap-table__body" key={x.id}>
                                      <div className={`heatmap-table__body-item heatmap-table__body-item--bold heatmap-table__body-item--${totalColor}`}>
                                        { Math.floor(x.curriculum.understanding)}%
                                      </div>
                                      {
                                        allUnits.map(unit => {
                                          const y = x.units.find(x => x.id === unit.id)
                                          if (!y) {
                                            return (
                                              <div key={unit.id} className='heatmap-table__body-item heatmap-table__body-item--gray'>
                                                0%
                                              </div>
                                            )
                                          }
                                          const color = colormap.find(color => color.calc(y.understanding))!.color
                                          return (
                                            <div key={unit.id} className={`heatmap-table__body-item heatmap-table__body-item--${color}`}>
                                              { Math.floor(y.understanding)}%
                                            </div>
                                          )
                                        })
                                      }
                                    </div>
                                  )
                                })
                              }
                            </React.Fragment>
                          ))
                        }

                      </div>
                    </div>
                  </>
                }

              </div>

              {
                courseId === 0 && perCurriculum.has_next &&
                <div className="more-button">
                  <div 
                    onClick={(e) => {
                      e.preventDefault()
                      props.fetchNextStudySummariesPerCurriculum(schoolClassIdForApi)
                    }}
                    className="more-button__text">
                    次の10名を表示
                  </div>
                </div>
              }
              {
                courseId !== 0 && perUnit.has_next &&
                <div className="more-button">
                  <div 
                    onClick={(e) => {
                      e.preventDefault()
                      props.fetchNextStudySummariesPerUnit(courseId, schoolClassIdForApi)
                    }}
                    className="more-button__text">
                    次の10名を表示
                  </div>
                </div>
              }


            </div>
          </div>

          <ol className="breadcrumbs">
            <li className="breadcrumbs__item">
              <Link to='/' className="breadcrumbs__link">マイページ</Link>
            </li>
            <li className="breadcrumbs__item">
              学習状況
            </li>
          </ol>
        </div>
      </SiteMain>
    </React.Fragment>
  );
}

export default TrainerStudySummaries
