import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import PrivateHeader from 'containers/parts/PrivateHeader';
import { mappedProps } from 'containers/student/StudyUnit';
import SiteMain from 'containers/parts/SiteMain';
import classNames from 'classnames';
import CorrectAnswerCount from './common/CorrectAnswerCount';

const StudyUnit: React.FC<mappedProps> = (props: mappedProps) => {
  const { unit_id } = useParams<{unit_id: string}>();
  const [showHint, setShowHint] = useState(false);
  const [disableDownload, setDisableDownload] = useState(false);

  useEffect(() => {
    if (unit_id) {
      props.fetchData(Number(unit_id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unit_id]);

  useEffect(() => {
    props.fetchStudyAchievementsLatest();
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  , []);


  const page = props.getRecord(Number(unit_id))

  const downloadPdf = (topicId: number) => {

    props.fetchContentData(topicId);
    setDisableDownload(true);
  };
  const studyTopic = props.student.studyTopic.record;

  useEffect(() => {
    const curriculumCodesArray =
      studyTopic &&
      studyTopic.contents.map(
        (content) => content.launch_parameters.curriculum_code,
      );
    if (
      curriculumCodesArray &&
      curriculumCodesArray.length > 0 &&
      disableDownload
    ) {
      props.pdfDownload(curriculumCodesArray)
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studyTopic/* , disableDownload */]);

  const downloadAllPdf = () => {
    //まずは全トピックを取得する必要がある
    const allTopicIds = page && page.topics.map((topic) => topic.id);
    if (allTopicIds) {
      props.downloadAllTopicsPdf(allTopicIds);
    }
  }

  return (
    <React.Fragment>
      <PrivateHeader />
      <SiteMain>
        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          {
            page &&
            <React.Fragment>
              <li className="breadcrumbs__item">
                <Link to={`/study/${page.curriculum.id}`} className="breadcrumbs__link">
                  { page.curriculum.name }
                </Link>
              </li>
              <li className="breadcrumbs__item">
                <Link to={`/study/courses/${page.course.id}`} className="breadcrumbs__link">
                  { page.course.name }
                </Link>
              </li>
              <li className="breadcrumbs__item">
                { page.unit.name }
              </li>
            </React.Fragment>
          }
        </ol>

        {
          page &&

          <div className="site-main__main-contents">
            <div  className="page-title">
            <div className="page-title__left">
              { page.curriculum.name }
              &nbsp;
              <div className="page-title__badge">
                { page.course.name }
              </div>
                &nbsp;
                { page.unit.name }
              </div>
              <div className="page-title__correct-count">
                {props.student.studyAchievementsLatest.record && <>
                  <CorrectAnswerCount correctAnswerCount={props.student.studyAchievementsLatest.record.study_achievements.consecutive_correct_answer_count}/>
                </>}
              </div>
            </div>

            {
              page.curriculum.al_engine_connected &&
              <div className="suggestion-box">
                AIがおすすめするカリキュラムを進めていくとテストの受講が可能です。
                「<Link to={`/recommends/${page.curriculum.id}`} className="">もっともおすすめ学習</Link>」からご確認ください。
              </div>
            }

            <div className="topic-list">
              <div className="topic-list__head">
                <div className="topic-list__title">
                  “{ page.unit.name }”のトピック
                </div>
                {
                  page.curriculum.al_engine_connected &&
                  <div className="topic-list__head-note">
                    <div
                      onClick={() => setShowHint(!showHint)}
                      className="">
                      AI理解度判定について
                    </div>

                    <div
                      className={classNames("balloon topic-list__balloon", { 'is-active': showHint })}>
                      <div
                        onClick={() => setShowHint(false)}
                        className="balloon__close">
                        説明を閉じる
                      </div>
                      <div className="balloon__title">
                        AI理解度判定とは？
                      </div>
                      <div className="balloon__lead">
                        AI理解度判定とは、それぞれのトピックについてどの程度理解をしているのかを、AIが判定した結果です。
                      </div>
                      <dl className="balloon__list">
                        <dt className="balloon__list-title">
                          コンプリート
                        </dt>
                        <dd className="balloon__list-body">
                          教科書や大学入学共通テストのレベルに対して十分な理解度に達している。
                        </dd>
                        <dt className="balloon__list-title">
                          レベル３
                        </dt>
                        <dd className="balloon__list-body">
                          教科書や大学入学共通テストのレベルに対して７０％以上９５％未満の理解度。
                        </dd>
                        <dt className="balloon__list-title">
                          レベル２
                        </dt>
                        <dd className="balloon__list-body">
                          教科書や大学入学共通テストのレベルに対して５０％以上７０％未満の理解度。
                        </dd>
                        <dt className="balloon__list-title">
                          レベル１
                        </dt>
                        <dd className="balloon__list-body">
                          教科書や大学入学共通テストのレベルに対して５０％未満の理解度。
                        </dd>
                      </dl>
                    </div>
                  </div>
                }
                {
                  !page.curriculum.al_engine_connected &&
                  <div className="topic-list__head-note">
                    <div
                      onClick={() => setShowHint(!showHint)}
                      className="">
                      学習状況とは？
                    </div>

                    <div
                      className={classNames("balloon topic-list__balloon", { 'is-active': showHint })}>
                      <div
                        onClick={() => setShowHint(false)}
                        className="balloon__close">
                        説明を閉じる
                      </div>
                      <div className="balloon__title">
                        学習状況とは？
                      </div>
                      <div className="balloon__lead">
                        それぞれのコンテンツについて、学習進捗を表記しています。
                      </div>
                      <dl className="balloon__list">
                        <dt className="balloon__list-title">
                          学習終了
                        </dt>
                        <dd className="balloon__list-body">
                          コンテンツの教材の学習を終了しています。
                        </dd>
                        <dt className="balloon__list-title">
                          学習中
                        </dt>
                        <dd className="balloon__list-body">
                          コンテンツの教材の学習を開始し、学習中です。
                        </dd>
                        <dt className="balloon__list-title">
                          未学習
                        </dt>
                        <dd className="balloon__list-body">
                          コンテンツの教材の学習を開始していません。
                        </dd>
                      </dl>
                    </div>
                  </div>
                }

              </div>
              <div className="topic-list__pdf-download-button topic-list__pdf-download-button--all">
                {page.topics.some((x) => x.pdf_ready === true) &&
                  <button
                    className="pdf-download-button"
                    onClick={downloadAllPdf}>
                    まとめてダウンロード (PDF)
                    <div className="pdf-download-button__icon" />
                  </button>}
              </div>
              <div className="topic-list__body">

                {
                  page.topics.map((topic, index) => (
                    <div key={index} className="topic-list__item">
                      <Link to={`/study/topics/${topic.id}`} className="topic-list__topic-name">
                        { topic.title }
                      </Link>
                      <div className="topic-list__phase-container">
                      {
                        topic.check_level &&
                        <div className="topic-list__phase">
                          AI理解度判定
                          <div className={classNames("topic-list__level", {  "topic-list__level--complete" : topic.check_level === 'コンプリート' })}>
                            { topic.check_level }
                          </div>
                        </div>
                      }
                      {
                        topic.status &&
                        <div className="topic-list__phase">
                          学習状況
                          <div className={classNames("topic-list__level", {  "topic-list__level--complete" : topic.status === 'complete' })}>
                            { topic.status === 'none' && '未学習' }
                            { topic.status === 'progress' && '学習中' }
                            { topic.status === 'complete' && '学習終了' }
                          </div>
                        </div>
                      }
                      { topic.pdf_ready &&
                        <div className="topic-list__pdf-download-button">
                          <button
                            disabled={!topic.pdf_ready}
                            onClick={()=> downloadPdf(topic.id)}
                            className="pdf-download-button">
                            PDF
                            <div className="pdf-download-button__icon" />
                          </button>
                        </div>
                      }
                      </div>
                    </div>
                  ))
                }
              </div>
              {
                // MARK: 教科：数学、科目：数I、単元：データの分析
                page.unit.id === 5 &&
                <div style={{
                  marginTop: 16,
                  color: '#666',
                  fontSize: 14,
                  textAlign: 'right',
                }}>
                  <p style={{marginBottom: 6}}>
                    新課程の「外れ値」は「4-2:四分位数と箱ひげ図」、「仮説検定」は「4-5:散布図と相互係数」に含みます。
                  </p>
                </div>
              }
              {
                // MARK: 教科：数学、科目：数A、単元：場合の数と確率
                page.unit.id === 6 &&
                <div style={{
                  marginTop: 16,
                  color: '#666',
                  fontSize: 14,
                  textAlign: 'right',
                }}>
                  <p style={{marginBottom: 6}}>
                    新課程の方は「数学Ａ　場合の数と確率　5-14：条件付き確率」を終えたら、<br />
                    教科・単元選択学習から「新課程　数学Ａ　期待値」を選び学習を進めてください。
                  </p>
                </div>
              }
              {
                // MARK: 教科：新課程数学、科目：数A、単元：場合の数と確率
                page.unit.id === 238 &&
                <div style={{
                  marginTop: 16,
                  color: '#666',
                  fontSize: 14,
                  textAlign: 'right',
                }}>
                  <p style={{marginBottom: 6}}>
                    「新課程　数学Ａ　期待値」を終えたら、<br />
                    「<Link to={'/study/units/7'}>数学Ａ　整数の性質</Link>」単元を選び学習を進めてください。
                  </p>
                </div>
              }
              {
                // MARK: 教科：化学 > 化学基礎 > 物質の構成 (unit_id: 51)
                page.unit.id === 51 &&
                <div className="topic-list__additional-info">
                  <p className="topic-list__additional-info-text">
                    新課程の方は「1-7:元素の性質と周期表{"／"}1-8:周期律」については、「教科・単元選択学習」＞「新課程化学」＞「新課程 化学基礎」＞「物質の構成＞元素の性質と周期表／周期律」から学習してください。
                  </p>
                </div>
              }
              {
                // MARK: 新課程化学 > 新課程　化学基礎 > 物質の構成 (unit_id: 346)
                page.unit.id === 346 &&
                <div className="topic-list__additional-info">
                  <p className="topic-list__additional-info-text">
                  「周期律」を終えたら、「教科・単元選択学習」＞「化学」＞「化学基礎」＞「物質の構成＞1-9:化学結合（総論）」に戻って学習を進めてください。
                  </p>
                </div>
              }
              {
                // MARK: 化学 > 化学 > 物質の変化と平衡 (unit_id: 55)
                page.unit.id === 55 &&
                <div className="topic-list__additional-info">
                  <p className="topic-list__additional-info-text">
                    新課程の方は「5-1:発熱反応と吸熱反応／5-2:熱化学方程式／5-3:反応熱の種類／5-4:比熱と温度変化／5-5:状態変化と熱化学方程式／5-6:ヘスの法則／5-7:結合エネルギー／5-8:化学反応と光／5-9:活性化エネルギー／5-10:触媒／5-16:ルシャトリエの原理」については、<br />
                    「教科・単元選択学習」＞「新課程化学」＞「新課程 化学」＞「物質の変化と平衡＞発熱反応と吸熱反応(5-1対応) ／エンタルピー変化と化学反応式(5-2対応) ／反応エンタルピーの種類(5-3対応) ／比熱と温度変化(5-4対応) ／状態変化とエンタルピー変化(5-5対応) ／ヘスの法則(5-6対応) ／結合エネルギー(5-7対応) ／化学反応と光(5-8対応) ／活性化エネルギー(5-9対応) ／触媒(5-10対応) ／ルシャトリエの原理(5-16対応)」から学習してください。
                  </p>
                </div>
              }
             {
                // MARK: 新課程化学 > 新課程　化学 > 物質の変化と平衡 (unit_id: 347)
                page.unit.id === 347 &&
                <div className="topic-list__additional-info">
                  <ul className="topic-list__additional-info-list">
                    <li>「触媒」を終えたら、「教科・単元選択学習」＞「化学」＞「化学」＞「物質の変化と平衡＞5-11:反応速度」に戻って学習を進めてください。</li>
                    <li>「ルシャトリエの原理」を終えたら、「教科・単元選択学習」＞「化学」＞「化学」＞「物質の変化と平衡＞5-17:水の電離平衡」に戻って学習を進めてください。</li>
                  </ul>
                </div>
              }
              {
                // MARK: 化学 > 化学 > 無機物質 (unit_id: 56)
                page.unit.id === 56 &&
                <div className="topic-list__additional-info">
                  <p className="topic-list__additional-info-text">
                    新課程の方は「6-7:アルカリ土類金属／6-14:金属イオンの沈殿」については、「教科・単元選択学習」＞「新課程化学」＞「新課程 化学」＞「無機物質＞アルカリ土類金属／金属イオンの沈殿」から学習してください。
                  </p>
                </div>
              }
              {
                // MARK: 新課程化学 > 新課程　化学 > 無機物質 (unit_id: 348)
                page.unit.id === 348 &&
                <div className="topic-list__additional-info">
                  <ul className="topic-list__additional-info-list">
                    <li>「アルカリ土類金属」を終えたら、「教科・単元選択学習」＞「化学」＞「化学」＞「無機物質＞6-8:両性金属」に戻って学習を進めてください。</li>
                    <li>「金属イオンの沈殿」を終えたら、「教科・単元選択学習」＞「化学」＞「化学」＞「無機物質＞6-15:合金」に戻って学習を進めてください。</li>
                  </ul>
                </div>
              }
              {
                // MARK: 生物 > 生物基礎 > 細胞・代謝 (unit_id: 210)
                page.unit.id === 210 &&
                <div className="topic-list__additional-info">
                  <p className="topic-list__additional-info-text">
                    新課程の方は「真核細胞の構造／原核細胞・単細胞生物」については、「教科・単元選択学習」＞「新課程生物」＞「新課程 生物基礎」＞「細胞・代謝＞真核細胞の構造／原核細胞・単細胞生物」から学習してください。
                  </p>
                </div>
              }
              {
                // MARK: 新課程生物 > 新課程　生物基礎 > 細胞・代謝 (unit_id: 349)
                page.unit.id === 349 &&
                <div className="topic-list__additional-info">
                  <p className="topic-list__additional-info-text">
                  「原核細胞・単細胞生物」を終えたら、「教科・単元選択学習」＞「生物」＞「生物基礎」＞「細胞・代謝＞細胞内共生説」に戻って学習を進めてください。
                  </p>
                </div>
              }
              {
                // MARK: 生物 > 生物基礎 > 遺伝子 (unit_id: 211)
                page.unit.id === 211 &&
                <div className="topic-list__additional-info">
                  <p className="topic-list__additional-info-text">
                    新課程の方は「細胞周期とDNAの複製／転写と翻訳」については、「教科・単元選択学習」＞「新課程生物」＞「新課程 生物基礎」＞「遺伝子＞細胞周期とDNAの複製／転写と翻訳」から学習してください。
                  </p>
                </div>
              }
              {
                // MARK: 新課程生物 > 新課程　生物基礎 > 遺伝子 (unit_id: 350)
                page.unit.id === 350 &&
                <div className="topic-list__additional-info">
                  <p className="topic-list__additional-info-text">
                  「転写と翻訳」を終えたら、「教科・単元選択学習」＞「生物」＞「生物基礎」＞「遺伝子＞ゲノムと遺伝子発現」に戻って学習を進めてください。
                  </p>
                </div>
              }
              {
                // MARK: 生物 > 生物基礎 > 体内環境 (unit_id: 212)
                page.unit.id === 212 &&
                <div className="topic-list__additional-info">
                  <p className="topic-list__additional-info-text">
                    新課程の方は、新課程では「水生生物の体液濃度の調節」は扱われなくなるので学習は不要です。
                  </p>
                </div>
              }
              {
                // MARK: 生物 > 生物基礎 > 自律神経とホルモン (unit_id: 213)
                page.unit.id === 213 &&
                <div className="topic-list__additional-info">
                  <p className="topic-list__additional-info-text">
                    新課程生の方は「神経系・自律神経」は、「教科・単元選択学習」＞「新課程生物」＞「新課程 生物基礎」＞「自律神経とホルモン＞神経系・自律神経」から学習してください。
                  </p>
                </div>
              }
              {
                // MARK: 新課程生物 > 新課程　生物基礎 > 自律神経とホルモン (unit_id: 351)
                page.unit.id === 351 &&
                <div className="topic-list__additional-info">
                  <p className="topic-list__additional-info-text">
                    「神経系・自律神経」が終わったら、「教科・単元選択学習」＞「生物」＞「生物基礎」＞「自律神経とホルモン＞ホルモン・標的細胞・受容体」に戻って学習を進めてください。
                  </p>
                </div>
              }
              {
                // MARK: 生物 > 生物基礎 > 生態系 (unit_id: 216)
                page.unit.id === 216 &&
                <div className="topic-list__additional-info">
                  <ul className="topic-list__additional-info-list">
                    <li>新課程生の方は「生態系とその成り立ち」については、「教科・単元選択学習」＞「新課程生物」＞「新課程 生物基礎」＞「生態系＞生態系とその成り立ち」から学習してください。</li>
                    <li>新課程生の方は、新課程では「物質循環／エネルギーの流れ／生態系における物質収支」は扱われなくなるので学習は不要です。</li>
                  </ul>
                </div>
              }
              {
                // MARK: 新課程生物 > 新課程　生物基礎 > 生態系 (unit_id: 352)
                page.unit.id === 352 &&
                <div className="topic-list__additional-info">
                  <p className="topic-list__additional-info-text">
                    「生態系とその成り立ち」が終わったら、「教科・単元選択学習」＞「生物」＞「生物基礎」＞「生態系＞生態系のバランスと変動」に戻って学習を進めてください。
                  </p>
                </div>
              }              
            </div>
            <div className="back-home">
              <Link to='/' className="back-home__link">マイページへ戻る</Link>
            </div>
          </div>
        }

        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          {
            page &&
            <React.Fragment>
              <li className="breadcrumbs__item">
                <Link to={`/study/${page.curriculum.id}`} className="breadcrumbs__link">
                  { page.curriculum.name }
                </Link>
              </li>
              <li className="breadcrumbs__item">
                <Link to={`/study/courses/${page.course.id}`} className="breadcrumbs__link">
                  { page.course.name }
                </Link>
              </li>
              <li className="breadcrumbs__item">
                { page.unit.name }
              </li>
            </React.Fragment>
          }
        </ol>
      </SiteMain>
    </React.Fragment>
  );
}

export default StudyUnit