import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Moment from 'react-moment';
import classNames from 'classnames';
import * as Models from 'models/api';

import { mappedProps } from 'containers/trainer/StudentBookmark';

type selectOptionType = {
  curriculum_id: number,
  curriculum_name: string,
}

type LearningCount = {
  curriculum_code: string;
  learning_count: number;
}

const TrainerStudentBookmark: React.FC<mappedProps> = (props: mappedProps) => {
  const bookmarksState = props.trainer.studentBookmarks;
  const studentBookmarksLearningCount = props.trainer.studentBookmarksLearningCount.records;
  const { id } : any = useParams();
  const [allBookmarksData, setAllBookmarksData] = useState<Models.Bookmarks>(null)
  const [bookmarksLearningCountArray, setBookmarksLearningCountArray] = useState<LearningCount[] | null>(null)
  const [bookmarksToShow, setBookmarksToShow] = useState<Models.BookmarksWithMeta | null>(null)
  
  useEffect(() => {
    if (id) {
     props.getStudentBookmarks({student_id: id})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const initialOption: selectOptionType = { curriculum_name: "全教科", curriculum_id: 0}
  const [selectedCurriculum, setSelectedCurriculum] = useState<selectOptionType>(initialOption)
  const [selectOptions, setSelectOptions] = useState<selectOptionType[]>([])
  const [showDropDown, setShowDropDown] = useState<boolean>(false);

  useEffect(() => {
    if (bookmarksState.records) {
      setAllBookmarksData(bookmarksState.records)
    }

    setBookmarksToShow(bookmarksState.records)
  }, [bookmarksState])

  useEffect(() => {
  if (studentBookmarksLearningCount) {
   const newBookmarksLearningCountArray = studentBookmarksLearningCount.contents.map((x) => {
    return {
      curriculum_code: x.content.launch_parameters.curriculum_code,
      learning_count: x.content.learning_count
    }
  }) 
      setBookmarksLearningCountArray(newBookmarksLearningCountArray)
    } 
  }, [studentBookmarksLearningCount])

  useEffect(() => {
    if (allBookmarksData) {
      const newCurriculumCodesArray = allBookmarksData.map((x) => x.content.curriculum_code)
      if (newCurriculumCodesArray !== null && newCurriculumCodesArray.length > 0) {
        props.getStudentBookmarksLearningCount({ curriculumCodes: newCurriculumCodesArray, studentId :Number(id)}
        )
      }
    }
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [allBookmarksData])

  // オプション表示用の配列を作る処理
  useEffect(() => {
    let newPullDownArray: selectOptionType[] = [initialOption];
    if (props.curriculums) {
      props.curriculums.forEach((curriculum) => {
        const newSelectOptionObject = { curriculum_name: curriculum.name, curriculum_id: curriculum.id }
        newPullDownArray.push(newSelectOptionObject)
      })
    }
    setSelectOptions(newPullDownArray)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.curriculums])

    // 表示する配列を作る処理
    useEffect(() => {
      if (allBookmarksData) {
        const newBookmarksToShow: Models.Bookmarks  = []
        allBookmarksData.forEach((bookmark) => {
          if (selectedCurriculum) {
            if (selectedCurriculum.curriculum_name === initialOption.curriculum_name) {
              newBookmarksToShow.push(bookmark)
            } else if (bookmark.curriculum_name === selectedCurriculum.curriculum_name) {
              newBookmarksToShow.push(bookmark)
            }
          }
        }
        )
        if (newBookmarksToShow) {
          setBookmarksToShow(newBookmarksToShow)
        }
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allBookmarksData, selectedCurriculum])

  const DropDownHandleSelectChange = (event: string) => {
    setShowDropDown(false)
    let selectedOptionObject = null
    selectOptions.forEach((selectOption) => {
      if (selectOption.curriculum_id === Number(event)) {
        selectedOptionObject = selectOption
        if (selectedOptionObject) {
          setSelectedCurriculum(selectedOptionObject)
        }
      }
    })
  }

    // カリキュラムコードから学習回数を取得
    const findLearningCount = (curriculumCode: string) => {
      if (bookmarksLearningCountArray) {
        const learningCountObject = bookmarksLearningCountArray.find((x: LearningCount) => x.curriculum_code === curriculumCode)
        if (learningCountObject) {
          return learningCountObject.learning_count
        }
      }
      return 0
    }

  return (
    <>
    <div className="site-main__main-contents">
          <div className="bookmark__page-title-container">
            <div className="bookmark__icon">
              <div />
              <div className="bookmark__page-title-container-title">ブックマーク</div>
            </div>
            <div className="bookmark__page-title-container-counter">現在の登録数
              <span className="bookmark__page-title-container-counter-number">
                {allBookmarksData ? allBookmarksData.length : '0'}
              </span>
              <div className="bookmark__page-title-container-counter-after">個</div>
            </div>
          </div>

          <div className="bookmark__select-section">
            <div className="bookmark__relative">
              <div onClick={() => setShowDropDown(!showDropDown)} className="bookmark__dropdown-button">{selectedCurriculum.curriculum_name}
                <div className="bookmark__dropdown-button-arrow"></div>   </div>
              {showDropDown &&
                <div className="bookmark__dropdown-container">
                  <ul>
                    {selectOptions.map((i: any, index) => {
                      return <li onClick={() => {
                        DropDownHandleSelectChange(i.curriculum_id)
                      }} key={index} className="bookmark__dropdown-option">{i.curriculum_name}</li>
                    })}
                  </ul>
                </div>}
            </div>
            {showDropDown && <div onClick={() => setShowDropDown(false)} className="bookmark__back"></div>}
            <div className="bookmark__select-section-counter-container">
              <div className="bookmark__select-section-counter-num">{bookmarksToShow && bookmarksToShow.length}</div>
              <div className="bookmark__select-section-counter-right">個</div>
            </div>
          </div>

          <div className="bookmark__border-section">
            <div className="bookmark__border-line" />
          </div>

          {bookmarksToShow && bookmarksToShow.map((x, index) => {
            return (
              <React.Fragment key={index}>
              <div className={classNames(
                "bookmark__topic-box topic-box__bookmark",
              )}
              >
                <div className="topic-box__subject bookmark__topic-box__subject">
                  <small>登録日時　
                    <Moment format="YYYY年M月D日 HH:mm">{x.updated_at}</Moment>
                  </small>
                </div>

                <div className={classNames("topic-box__title")}>
                  {x.curriculum_name} ＞
                  {x.course_name} ＞
                  {x.unit_name} ＞
                  {x.topic_title} ＞
                  {x.content.meta && x.content.meta.label}
                </div>
                <div className='bookmark__topic-box__bottom-section'>

                  <div>
                    <div className={classNames(
                      "topic-box__learning_counts_bookmark",
                      { "topic-box__learning_counts_bookmark--many":  findLearningCount(x.content.curriculum_code)},
                    )
                    } >
                      学習回数 {findLearningCount(x.content.curriculum_code)}回
                    </div>
                  </div>
                </div>
              </div>
              <div className="bookmark__border-section">
                <div className="bookmark__border-line" />
              </div>
              </React.Fragment>)
          }
          )}
        </div>
    </>
  );
}

export default TrainerStudentBookmark