import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router'

import { AppState } from "store";
import { StudentStudyCourseCompleteResult as targetComponent } from 'components/parent/StudentStudyCourseCompleteResult';

import studyResultActions from 'actions/studyResultActions';

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch })

const mapStateToProps = (appState: AppState, ownProps: RouteComponentProps) => {
  return {
    user: appState.session.user!,
    studyResult: appState.studyResult,
    ...ownProps,
  }
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  { dispatch }: ReturnType<typeof mapDispatchToProps>) => {

  return {
    ...stateProps,
    fetchData: (student_id: number, unit_id: number) => {
      dispatch(studyResultActions.fetchStudent({ student_id }))
      dispatch(studyResultActions.fetchStudyCourseCompletionTestResult({ student_id, unit_id, initialize: true }))
    },
    fetchMore: () => {
      const page = stateProps.studyResult.studyCourseCompletionTestResults
      const unit_id = page.unit_id
      const student_id = page.student_id

      if (!unit_id || !student_id) return

      dispatch(studyResultActions.fetchStudyCourseCompletionTestResult({ student_id, unit_id, initialize: false }))
    },
    getStudent: (student_id: number) => {
      const student = stateProps.studyResult.student
      if (student.student_id !== student_id) {
        return null
      }
      return student.record
    },
    getRecord: (student_id: number, unit_id: number) => {
      const page = stateProps.studyResult.studyCourseCompletionTestResults
      if (page.unit_id !== unit_id || page.student_id !== student_id) {
        return null
      }
      return page.record
    },
    getMoreRecord: (student_id: number, unit_id: number) => {
      const page = stateProps.studyResult.studyCourseCompletionTestResults
      if (page.unit_id !== unit_id || page.student_id !== student_id) {
        return null
      }
      return page.moreRecord
    },
  }
}

export type mappedProps = ReturnType<typeof mergeProps>
export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(targetComponent))
