import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router'

import { AppState } from "store";
import targetComponent from 'components/trainer/StudentStudyLogs';
import studyResultActions from 'actions/studyResultActions';
import trainerAction from 'actions/trainerActions';
import { getAvailableCurriculums }  from 'utils/store'

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch })

const mapStateToProps = (appState: AppState, ownProps: RouteComponentProps) => {
   const studentUser = appState.trainer.student.record
  return {
    // MARK: 学習履歴は受講設定可能な教科をタブ表示する
    curriculums: studentUser && getAvailableCurriculums(studentUser),
    user: appState.session.user!,
    trainer: appState.trainer,
    studyResult: appState.studyResult,
    ...ownProps,
  }}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  { dispatch }: ReturnType<typeof mapDispatchToProps>) => {
  return {
    ...stateProps,
    getCurriculum: (code: string| undefined) => {
      return stateProps.curriculums && stateProps.curriculums.find(x => x.code === code)
    },
    fetchFirst: (curriculum_id: number, student_id: number) => {
      dispatch(studyResultActions.fetchStudyLogRecords({ student_id, curriculum_id, initialize: true }))
    },
    getStudyLogs: (curriculum_id: number, student_id: number) => {
      const page = stateProps.studyResult.studyLog
      if (page.student_id !== student_id) {
        return null
      }
      return page.records
    },
    hasNextStudyLog: (curriculum_id: number, student_id: number) => {
      const page = stateProps.studyResult.studyLog
      if (page.student_id !== student_id) {
        return false
      }
      return page.has_next
    },
    fetchNext: () => {
      const studyLog = stateProps.studyResult.studyLog
      const student_id = studyLog.student_id!
      const curriculum_id = studyLog.curriculum_id!
      dispatch(studyResultActions.fetchStudyLogRecords({ student_id, curriculum_id, initialize: false }))
    },
    pdfDownload: (curriculum_codes: string[]) => {
      const curriculumCodesString = curriculum_codes.join("_")
      return new Promise<{url: string}>((resolve, reject) => {
        dispatch(trainerAction.fetchPdfDownload({
          pdf_curriculums: curriculumCodesString,
          promises: { resolve, reject }
        }))
      })}    
  }
}

export type mappedProps = ReturnType<typeof mergeProps>
export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(targetComponent))
