import { call, put, select, takeEvery, retry, takeLatest } from 'redux-saga/effects'
import momentTimezone from 'moment-timezone'

import * as apiService from 'services/api'
import uiActions from 'actions/uiActions'
import { AppState } from 'store';
import actions from 'actions/studentActions'
import { updateUser }  from './session'
import { AxiosResponse } from 'axios';
import * as Models from 'models/api'
import { getRecommendedCurriculums }  from 'utils/store'

export function* fetchQuestionRecords(action: ReturnType<typeof actions.fetchQuestionRecords>) {
  const state: AppState = yield select()

  try {
    const per_page = 10
    let page = state.student.question.page + 1
    if (action.payload.initialize) {
      page = 1
    }
    const params = {
      page,
      per_page,
    }
    const response: apiService.getStudentQuestionRecordsResponse = yield call(
      apiService.getStudentQuestionRecords,
      yield select(x => x.repository.authorizedApi!), params);
    const records = response.data
    yield put(actions.setQuestionRecords({ page, records }));
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

export function* fetchQuestionDetail(action: ReturnType<typeof actions.fetchQuestionDetail>) {
  const question_id = action.payload.question_id
  try {
    const response: apiService.getStudentQuestionResponse = yield call(
      apiService.getStudentQuestion,
      yield select(x => x.repository.authorizedApi!), question_id);
    const record = response.data
    yield put(actions.setQuestionDetail({ record, question_id }));
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

export function* fetchStudyLogRecords(action: ReturnType<typeof actions.fetchStudyLogRecords>) {
  const state: AppState = yield select()
  try {

    const curriculum_id = action.payload.curriculum_id
    const per_page = 10
    let page = state.student.studyLog.page + 1
    if (action.payload.initialize) {
      page = 1
    }
    const params = {
      page,
      per_page,
      curriculum_id,
    }
    const response: apiService.getStudyLogRecordsResponse = yield call(
      apiService.getStudyLogRecords,
      yield select(x => x.repository.authorizedApi!), params);
    const records = response.data
    yield put(actions.setStudyLogRecords({ curriculum_id, page, records }));
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

export function* fetchStudyLogDetail(action: ReturnType<typeof actions.fetchStudyLogDetail>) {
  const study_log_id = action.payload.study_log_id
  try {
    const response: apiService.getStudentStudyLogResponse = yield call(
      apiService.getStudentStudyLog,
      yield select(x => x.repository.authorizedApi!), study_log_id);
    const record = response.data

    yield put(actions.setStudyLogDetail({ record, study_log_id, }));

  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

export function* fetchStudyAchievementsLatest(action: ReturnType<typeof actions.fetchStudyAchievementsLatest>) {
  try {
    const response: apiService.GetStudyAchievementsLatestResponse = yield call(
      apiService.getStudyAchievementsLatest,
      yield select(x => x.repository.authorizedApi!));
    const record = response.data
    yield put(actions.setStudyAchievementsLatest({ record }));
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

export function* fetchQuestionsSheetsCountInMonth(action: ReturnType<typeof actions.fetchQuestionsSheetsCountInMonth>) {
  try {
    const response: AxiosResponse<number> = yield call(
      apiService.getStudentQuestionsSheetsCountInMonth,
      yield select(x => x.repository.authorizedApi!));
    const count = response.data
    yield put(actions.setQuestionsSheetsCountInMonth({ count }));
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

export function* fetchRecommendStudyRecords(action: ReturnType<typeof actions.fetchRecommendStudyRecords>) {
  const curriculum_id = action.payload.curriculum_id

  try {
    const limit = 4
    const params = {
      limit,
      curriculum_id,
    }
    const response: apiService.getRecommendStudyRecordsResponse2 = yield call(
      apiService.getRecommendStudyRecords2,
      yield select(x => x.repository.authorizedApi!), params);
    const records = response.data
    yield put(actions.setRecommendStudyRecords({ curriculum_id, records }));
    action.payload.promises && action.payload.promises.resolve()
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
    action.payload.promises && action.payload.promises.reject(e)
  }
}

// issue-545 
export function* fetchEntrustStudyRecords(action: ReturnType<typeof actions.fetchEntrustStudyRecords>){
  const curriculum_id = action.payload.curriculum_id;
try {
  //const state: AppState = yield select()
  const params = {
    curriculum_id,
  }
 const response:  apiService.getEntrustCurrentRecordsResponse= yield call(
    apiService.getEntrustCurrent,
    yield select(x => x.repository.authorizedApi!), params);
    const records = response.data
    yield put(actions.setEntrustStudyRecords({ curriculum_id, records }));
} catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}
 // issue-545 おまかせ学習の設定
export function* updateEntrustBaseTopic(action: ReturnType<typeof actions.updateEntrustBaseTopic>){
  const topic_id = action.payload.topic_id
  try {
    //const state: AppState = yield select()
     const params = {
      topic_id,
    }
   const response:  apiService.updateEntrustBaseTopicResponse= yield call(
      apiService.updateEntrustBaseTopic,
      yield select(x => x.repository.authorizedApi!), params);
    if (response) {
      action.payload.promises && action.payload.promises.resolve(response);
    }
  } catch (e) {
      console.error(e);
      yield put(uiActions.showApiErrorNotification(e));
    }
  }

export function* fetchScheduleRecords(action: ReturnType<typeof actions.fetchScheduleRecords>) {
  const state: AppState = yield select()
  try {
    const per_page = 10
    let page = state.student.schedule.page + 1
    if (action.payload.initialize) {
      page = 1
    }
    const params = {
      page,
      per_page,
    }
    const response: apiService.getScheduleRecordsResponse= yield call(
      apiService.getScheduleRecords,
      yield select(x => x.repository.authorizedApi!), params);
    const records = response.data
    yield put(actions.setScheduleRecords({ page, records }));
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

export function* fetchMessageRecords(action: ReturnType<typeof actions.fetchMessageRecords>) {
  const state: AppState = yield select()
  try {
    const studentId = state.session.user!.id
    const params = {
      page: 1,
      per_page: 100,
      studentId,
    }
    const response: apiService.getStudentMessageRecordsResponse= yield call(
      apiService.getStudentMessageRecords,
      yield select(x => x.repository.authorizedApi!), params);
    const records = response.data
    yield put(actions.setMessageRecords({ records }));
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

export function* fetchMypage(action: ReturnType<typeof actions.fetchMypage>) {
  try {
    const api = yield select(x => x.repository.authorizedApi!)
    const response: apiService.getStudentMypageResponse = yield call(
      apiService.getStudentMypage,
      api);
    yield put(actions.setMypage({ record: response.data }));

    // MARK: 週間学習実績取得API
    const records = []
    let date: string | undefined = undefined 

    while(true) {
      const response: AxiosResponse<Models.StudyAchivementRecord> = yield call(
        apiService.getStudentStudyAchivementWeekly,
        api,
        {
          date,
        }
      );

      if(!response.data.start_week_on) {
        break
      }

      records.push(response.data)

      // MARK: 前の週リンク表示のため、初期リクエスト時に前の週分まで取得
      if (records.length >= 2) {
        break
      }

      date = momentTimezone(response.data.start_week_on).subtract(7, 'day').format('YYYY-MM-DD')
    }

    const has_next = records.length === 2
    yield put(actions.setStudyTimeStats({ records, has_next }));

  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

export function* fetchNextStudyTimeStats(action: ReturnType<typeof actions.fetchNextStudyTimeStats>) {
  const state: AppState = yield select()
  try {
    const api = state.repository.authorizedApi!
    if (!state.student.studyTimeStats.has_next) {
      return
    }

    // MARK: 一つ前の週のデータを取得
    const records = state.student.studyTimeStats.records
    const date = momentTimezone(records[records.length - 1].start_week_on).subtract(7, 'day').format('YYYY-MM-DD')

    const response: AxiosResponse<Models.StudyAchivementRecord> = yield call(
      apiService.getStudentStudyAchivementWeekly,
      api, { date }
    );

    if(!response.data.start_week_on) {
      yield put(actions.pushStudyTimeStats({ records: [], has_next: false }));
    } else {
      yield put(actions.pushStudyTimeStats({ records: [response.data], has_next: true }));
    }

  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

export function* updateStudyTimeStatsGoalTime(action: ReturnType<typeof actions.updateStudyTimeStatsGoalTime>) {
  const state: AppState = yield select()
  try {
    const api = yield select(x => x.repository.authorizedApi!)
    yield call(
      apiService.updateStudyAchivementGoalTime,
        api,
        action.payload.params);

    // MARK: 週間学習実績取得API
    const records = state.student.studyTimeStats.records
    if (records.length > 0) {
      const response: AxiosResponse<Models.StudyAchivementRecord> = yield call(
        apiService.getStudentStudyAchivementWeekly,
        api,
        {}
      );

      yield put(actions.updateStudyTimeStats({ record: response.data, index: 0 }));
    }

    action.payload.promises && action.payload.promises.resolve()
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
    action.payload.promises && action.payload.promises.reject(e)
  }
}

export function* fetchPlan(action: ReturnType<typeof actions.fetchPlan>) {
  try {
    const response: apiService.getStudentCurrentPlanResponse = yield call(
      apiService.getStudentCurrentPlan,
      yield select(x => x.repository.authorizedApi!));
    const record = response.data
    yield put(actions.setPlan({ record }));
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

export function* fetchStudyCurriculum(action: ReturnType<typeof actions.fetchStudyCurriculum>) {
  try {
    const curriculum_id = action.payload.curriculum_id
    const params = {
      curriculum_id,
    }

    const response: apiService.getStudentStudyCurriculumResponse = yield call(
      apiService.getStudentStudyCurriculum,
      yield select(x => x.repository.authorizedApi!),
      params);
    const record = response.data
    yield put(actions.setStudyCurriculum({ record, curriculum_id }));
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

export function* fetchStudyCourse(action: ReturnType<typeof actions.fetchStudyCourse>) {
  try {
    const course_id = action.payload.course_id
    const params = {
      course_id
    }

    const response: apiService.getStudentStudyCourseResponse = yield call(
      apiService.getStudentStudyCourse,
      yield select(x => x.repository.authorizedApi!),
      params);
    const record = response.data
    yield put(actions.setStudyCourse({ record, course_id }));

    const curriculum_id = record.curriculum.id

    // MARK: 教科の科目情報を取得する
    yield fetchStudyCoursesIfEmpty(curriculum_id)

    const state: AppState = yield select()
    const courses = state.student.studyCurriculum.record!.courses

    const course = courses.find(x => x.id === course_id)
    if (!course) {
      throw new Error('invalid course')
    }

    if (course.completion_test) {
      // MARK: 科目修了テスト情報を取得
      const response: AxiosResponse<Models.StudyCourseCompletionTestsRecord> = yield call(
        apiService.getStudentStudyCourseCompletionTests,
        yield select(x => x.repository.authorizedApi!),
        { course_id });
      const record = response.data
      yield put(actions.setStudyCourseCompletionTests({ course_id, record }));
    }

  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

function* fetchStudyCoursesIfEmpty(curriculum_id: number) {
  const state: AppState = yield select()

  if (state.student.studyCurriculum.curriculum_id !== curriculum_id) {
    const response: apiService.getStudentStudyCurriculumResponse = yield call(
      apiService.getStudentStudyCurriculum,
      yield select(x => x.repository.authorizedApi!),
      { curriculum_id 
      });
    const record = response.data
    yield put(actions.setStudyCurriculum({ record, curriculum_id }));
  }
}

function* fetchStudyCourseCompletionTestIfEmpty(course_id: number) {
  const state: AppState = yield select()
  if (state.student.studyCourseCompletionTests.course_id !== course_id) {
    const response: AxiosResponse<Models.StudyCourseCompletionTestsRecord> = yield call(
      apiService.getStudentStudyCourseCompletionTests,
      yield select(x => x.repository.authorizedApi!),
      { course_id });
    const record = response.data
    yield put(actions.setStudyCourseCompletionTests({ course_id, record }));
  }
}

export function* fetchStudyCourseResult(action: ReturnType<typeof actions.fetchStudyCourseResult>) {
  try {
    const curriculum_id = action.payload.curriculum_id
    let course_id = action.payload.course_id

    // MARK: 教科の科目情報を取得する
    yield fetchStudyCoursesIfEmpty(curriculum_id)

    const state: AppState = yield select()
    const courses = state.student.studyCurriculum.record!.courses

    if (!course_id) {
      course_id = courses[0].id
    }

    const user = state.session.user! as Models.StudentUser
    const curriculums = getRecommendedCurriculums(user)

    const curriculum = curriculums.find(x => x.id === curriculum_id)

    const course = courses.find(x => x.id === course_id)

    if (!curriculum || !course) {
      throw new Error('invalid course')
    }

    if (course.completion_test) {
      // MARK: 科目修了テスト情報を取得
      const response: AxiosResponse<Models.StudyCourseCompletionTestsRecord> = yield call(
        apiService.getStudentStudyCourseCompletionTests,
        yield select(x => x.repository.authorizedApi!),
        { course_id });
      const record = response.data
      yield put(actions.setStudyCourseCompletionTests({ course_id, record }));
    }

    // MARK: ドリル教科以外の場合は単元テスト結果を取得
    if (curriculum.al_engine_connected) {
      const response: AxiosResponse<Models.StudyCourseResultsRecord> = yield call(
        apiService.getStudentStudyCourseResults,
        yield select(x => x.repository.authorizedApi!),
        { course_id, });
      const record = response.data
      yield put(actions.setStudyCourseResult({ curriculum_id, course_id, record }));
    }
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

export function* fetchStudyCourseCompletionTestResult(action: ReturnType<typeof actions.fetchStudyCourseCompletionTestResult>) {
  try {
    const unit_id = action.payload.unit_id

    const page = 1;
    let per_page: number | undefined = 2;
    if (!action.payload.initialize) {
      // MARK: per_page 未指定で全件取得
      per_page = undefined
    }

    // MARK: 科目修了テスト結果を取得
    const response: AxiosResponse<Models.StudyCourseCompletionTestResultsRecord> = yield call(
      apiService.getStudentStudyCourseCompletionTestResults,
      yield select(x => x.repository.authorizedApi!),
      { unit_id, page, per_page });

    const record = response.data

    // MARK: 科目修了テスト情報を取得
    const course_id = record.course.id
    yield fetchStudyCourseCompletionTestIfEmpty(course_id)

    if (action.payload.initialize) {
      // MARK: 以前の結果表示用データをリセット
      yield put(actions.setStudyCourseCompletionTestResultsMore({ record: null }));
      yield put(actions.setStudyCourseCompletionTestResults({ unit_id, record }));
    } else {
      // MARK: もっと見る
      yield put(actions.setStudyCourseCompletionTestResultsMore({ record }));
    }
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

export function* fetchStudyUnit(action: ReturnType<typeof actions.fetchStudyUnit>) {
  try {
    const unit_id = action.payload.unit_id
    const params = {
      unit_id
    }

    // FIXME: 学習画面から直接このAPIを叩く画面に戻るとエラーになるのでここでmypage用APIを呼び出してみる
    yield put(actions.fetchMypage());

    // FIXME: 一時的に500エラーとなる場合があるのでretryする
    const response: apiService.getStudentStudyUnitResponse =  yield retry(5, 1000, function *() {
      return yield call(
        apiService.getStudentStudyUnit,
        yield select(x => x.repository.authorizedApi!),
        params);
    })

    const record = response.data

    yield put(actions.setStudyUnit({ record, unit_id }));
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

export function* fetchStudyUnitResult(action: ReturnType<typeof actions.fetchStudyUnitResult>) {
  try {
    const unit_id = action.payload.unit_id

    const page = 1;
    let per_page: number | undefined = 2;
    if (!action.payload.initialize) {
      // MARK: per_page 未指定で全件取得
      per_page = undefined
    }

    const response: AxiosResponse<Models.StudyUnitResultsRecord> = yield call(
      apiService.getStudentStudyUnitResults,
      yield select(x => x.repository.authorizedApi!),
      { unit_id, page, per_page });
    const record = response.data

    if (action.payload.initialize) {
      // MARK: 以前の結果表示用データをリセット
      yield put(actions.setStudyUnitResultMore({ record: null }));
      yield put(actions.setStudyUnitResult({ unit_id, record }));
    } else {
      // MARK: もっと見る
      yield put(actions.setStudyUnitResultMore({ record }));
    }
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

export function* fetchStudyTopic(action: ReturnType<typeof actions.fetchStudyTopic>) {
  try {
    const topic_id = action.payload.topic_id
    const params = {
      topic_id
    }

    // FIXME: 学習画面から直接このAPIを叩く画面に戻るとエラーになるのでここでmypage用APIを呼び出してみる
    yield put(actions.fetchMypage());

    // FIXME: 一時的に500エラーとなる場合があるのでretryする
    const response: apiService.getStudentStudyTopicResponse =  yield retry(5, 1000, function *() {
      return yield call(
        apiService.getStudentStudyTopic,
        yield select(x => x.repository.authorizedApi!),
        params);
    })

    const record = response.data
    yield put(actions.setStudyTopic({ record, topic_id }));
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

export function* downloadAllTopicsPdf(action: ReturnType<typeof actions.downloadAllTopicsPdf>) {
  const state: AppState = yield select()
  const all_topic_ids = action.payload.all_topic_ids
  const userId = state.session.user!.login
  const type = state.ui.type! as Models.LoginableUIType
  const env = state.ui.env!
  const params = {
    all_topic_ids,
    id : userId,
    type,
    env
  }
  try {
    const response: apiService.getAllStudentStudyTopicsResponse = yield call(
      apiService.downloadAllTopicsPdf,
      yield select(x => x.repository.authorizedApi!),
      params);

    const records = response.data
    return records

  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}


export function* updateProfile(action: ReturnType<typeof actions.updateProfile>) {
  const state: AppState = yield select()
  try {
    yield call(
      apiService.updateStudentProfile,
        state.repository.authorizedApi!,
        action.payload.params);

    yield updateUser()

    action.payload.promises && action.payload.promises.resolve()
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
    action.payload.promises && action.payload.promises.reject(e)
  }
}

export function* updateSchedule(action: ReturnType<typeof actions.updateSchedule>) {
  const state: AppState = yield select()
  try {
    yield call(
      apiService.updateSchedule,
        state.repository.authorizedApi!,
        action.payload.params);
    // TODO: 通知する?
    action.payload.promises && action.payload.promises.resolve()
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
    action.payload.promises && action.payload.promises.reject(e)
  }
}

export function* createQuestion(action: ReturnType<typeof actions.createQuestion>) {
  const state: AppState = yield select()
  try {
    yield call(
      apiService.createQuestion,
        state.repository.authorizedApi!,
        action.payload.params);
    action.payload.promises && action.payload.promises.resolve()
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
    action.payload.promises && action.payload.promises.reject(e)
  }
}

export function* startStudy(action: ReturnType<typeof actions.startStudy>) {
  const state: AppState = yield select()

  const content_id = action.payload.params.content_id
  const return_pathname = action.payload.params.return_pathname
  const rank = action.payload.params.rank
  const learned = action.payload.params.learned
  const m_entrust = action.payload.params.m_entrust
  const params = {
    rank,
    content_id,
    learned,
    m_entrust,
    return_url: '/#' + return_pathname
  }

  try {
    const response: apiService.createStudyLogResponse = yield call(
      apiService.createStudyLog,
        state.repository.authorizedApi!,
        params);
    action.payload.promises && action.payload.promises.resolve(response.data.launch_url)
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
    action.payload.promises && action.payload.promises.reject(e)
  }
}

export function* skipRecommendStudyPretest(action: ReturnType<typeof actions.skipRecommendStudyPretest>) {
  try {
    const params = action.payload.params
    yield call(
      apiService.skipRecommendStudyPretest,
      yield select(x => x.repository.authorizedApi!), params);

    //MARK: おすすめ学習リストをリセット
    yield put(actions.resetRecommendStudyRecords());

    action.payload.promises && action.payload.promises.resolve()
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
    action.payload.promises && action.payload.promises.reject(e)
  }
}

export function* createCreditcardToken(action: ReturnType<typeof actions.createCreditcardToken>) {
  const state: AppState = yield select()
  try {
    const studentUser = state.session.user as Models.StudentUser
    const params = {
      status: studentUser.status,
    }
    const response: apiService.createCreaditcardTokenResponse = yield call(
      apiService.createCreditcardToken,
        state.repository.authorizedApi!,
        params);
    action.payload.promises && action.payload.promises.resolve(response.data.token)
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
    action.payload.promises && action.payload.promises.reject(e)
  }
}

export function* buyQuestionTicket(action: ReturnType<typeof actions.buyQuestionTicket>) {
  const state: AppState = yield select()
  try {
    yield call(apiService.buyQuestionTicket, state.repository.authorizedApi!);

    action.payload.promises && action.payload.promises.resolve()
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
    action.payload.promises && action.payload.promises.reject(e)
  }
}

export function* confirmPointExchange(action: ReturnType<typeof actions.confirmPointExchange>) {
  const state: AppState = yield select()

  try {
    yield put(actions.resetPointExchangeState());

    const params = action.payload.params

    // MARK: エラー画面表示用
    yield put(actions.setPointExchangeParams({ params }));

    if (params.request_hash!) {
      throw new Error('invalid');
    }

    const response: apiService.createStudentPointExchangeResponse = yield call(
      apiService.createStudentPointExchange,
      state.repository.authorizedApi!,
      params,
    );

    const record = response.data
    yield put(actions.setPointExchangeConfirmRecord({ record }));
    action.payload.promises && action.payload.promises.resolve()
  } catch (e) {
    console.error(e);
    yield put(actions.setPointExchangeError(e));
    // MARK: resolveして次の画面でエラー表示する
    action.payload.promises && action.payload.promises.resolve()
    // yield put(uiActions.showApiErrorNotification(e));
    // action.payload.promises && action.payload.promises.reject(e)
  }
}

export function* executePointExchange(action: ReturnType<typeof actions.executePointExchange>) {
  const state: AppState = yield select()
  try {

    const confirmRecord = state.student.pointExchange.confirm
    if (!confirmRecord) {
      throw new Error('invalid');
    }

    const params = confirmRecord.point_exchange

    const response: apiService.createStudentPointExchangeResponse = yield call(
      apiService.createStudentPointExchange,
      state.repository.authorizedApi!,
      params,
    );

    const record = response.data

    // throw new Error('invalid');

    yield put(actions.setPointExchangeExecuteRecord({ record }));

    yield updateUser()

    action.payload.promises && action.payload.promises.resolve()

  } catch (e) {
    console.error(e);
    yield put(actions.setPointExchangeError(e));
    // MARK: resolveして次の画面でエラー表示する
    action.payload.promises && action.payload.promises.resolve()
    // yield put(uiActions.showApiErrorNotification(e));
    // action.payload.promises && action.payload.promises.reject(e)
  }
}

export function* fetchPointExchangeLogRecords(action: ReturnType<typeof actions.fetchPointExchangeLogRecords>) {
  const state: AppState = yield select()

  try {
    const per_page = 10
    let page = state.student.pointExchangeLog.page + 1
    if (action.payload.initialize) {
      page = 1
    }
    const params = {
      page,
      per_page,
    }
    const response: apiService.getStudentPointExchangeLogsResponse = yield call(
      apiService.getStudentPointExchangeLogs,
      yield select(x => x.repository.authorizedApi!), params);
    const records = response.data
    yield put(actions.setPointExchangeLogRecords({ page, records }));
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

export function* getLearningCount(action: ReturnType<typeof actions.getLearningCount>) {
  const state: AppState = yield select()
  try {
    const response: Models.StudentStudyLearningCountRecords = yield call(
      apiService.getLearningCount,
      state.repository.authorizedApi!,
      action.payload.params.curriculumCodes,
      );
   
    if (response){
      yield put(actions.setLearningCount({ params: response }))
    }

    action.payload.promises && action.payload.promises.resolve(response);
 
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

// issue-547

export function* fetchBookmarks(action: ReturnType<typeof actions.fetchBookmarks>) {
  const state: AppState = yield select()
  try {
    const response: apiService.fetchBookmarksResponse = yield call(
      apiService.fetchBookmarks,
      state.repository.authorizedApi!,
      action.payload,
    );
  if (response) {
    yield put(actions.setBookmarks({ params: response.data.bookmarks}))
  }
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

export function* fetchBookmarksLearningCount(action: ReturnType<typeof actions.getLearningCount>) {
  const state: AppState = yield select()
  try {
    const response: Models.StudentStudyLearningCountRecords = yield call(
      apiService.getLearningCount,
      state.repository.authorizedApi!,
      action.payload.params.curriculumCodes,
    );
    if (response) {
      yield put(actions.setBookmarksLearningCount({ params: response }))
    }
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

export function* deleteBookmarks(action: ReturnType<typeof actions.deleteBookmarks>) {
  const state: AppState = yield select();
  try {
    const response: AxiosResponse = yield call(
      apiService.deleteBookmarks,
      state.repository.authorizedApi!,
      action.payload.params,
    );
    if (response) {
      if (response.status === 200) {
      const errorCheck =response.data.map((item: any) => item.success)
  
      //　レスポンスに削除に失敗したものがある場合エラーを表示する
      if (errorCheck.includes(false)){
        const message = '対象全て、もしくは一部の削除ができませんでした。';
        const level = 'error';
         yield put(uiActions.showNotification({ level, message }));
      }

 const deletedBookmark = response.data.map((item: any) => {
  if (item.success === true) { 
    return item.id
   } 
   return null
  }).filter(Boolean)
  
    const bookmarksState = state.student.bookmarks.records
    if (bookmarksState) {
    const newBookmarksState = 
    bookmarksState.filter((bookmark) => !deletedBookmark.includes(bookmark.id))
    yield put(actions.setBookmarks({ params: newBookmarksState }))
    }}
      action.payload.promises && action.payload.promises.resolve(response);
    }
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

export function* fetchPdfDownload(action: ReturnType<typeof actions.fetchPdfDownload>) {
  let state: AppState = yield select()
  const id = state.session.user!.login
  const code = action.payload.pdf_curriculums
  const type = state.ui.type! as Models.LoginableUIType
  const env = state.ui.env!
  const params = {
    code,
    id,
    type,
    env
  }
  try {
    const response: apiService.getPDFResponse = yield call(
      apiService.getPDF,
      yield select(x => x.repository.authorizedApi!),
      params);
      const record = response.data
    action.payload.promises && action.payload.promises.resolve(record)
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

function* saga() {
  yield takeEvery(actions.fetchStudyLogRecords, fetchStudyLogRecords);
  yield takeEvery(actions.fetchQuestionRecords, fetchQuestionRecords);
  yield takeEvery(actions.fetchRecommendStudyRecords, fetchRecommendStudyRecords);
  // issue-545
  yield takeEvery(actions.fetchEntrustStudyRecords, fetchEntrustStudyRecords);
  // issue-545
  yield takeEvery(actions.updateEntrustBaseTopic, updateEntrustBaseTopic);

  yield takeEvery(actions.fetchScheduleRecords, fetchScheduleRecords);
  yield takeEvery(actions.fetchMessageRecords, fetchMessageRecords);
  yield takeEvery(actions.fetchMypage, fetchMypage);
  yield takeEvery(actions.fetchStudyCurriculum, fetchStudyCurriculum);
  yield takeEvery(actions.fetchStudyCourse, fetchStudyCourse);
  yield takeLatest(actions.fetchStudyCourseResult, fetchStudyCourseResult);
  yield takeEvery(actions.fetchStudyUnit, fetchStudyUnit);
  yield takeEvery(actions.fetchStudyUnitResult, fetchStudyUnitResult);
  yield takeEvery(actions.fetchStudyTopic, fetchStudyTopic);
  yield takeEvery(actions.downloadAllTopicsPdf, downloadAllTopicsPdf );
  yield takeEvery(actions.updateProfile, updateProfile);
  yield takeEvery(actions.updateSchedule, updateSchedule);
  yield takeEvery(actions.createQuestion, createQuestion);
  yield takeEvery(actions.fetchQuestionDetail, fetchQuestionDetail);
  yield takeEvery(actions.startStudy, startStudy);
  yield takeEvery(actions.skipRecommendStudyPretest, skipRecommendStudyPretest);
  yield takeEvery(actions.createCreditcardToken, createCreditcardToken);
  yield takeEvery(actions.fetchStudyLogDetail, fetchStudyLogDetail);
  yield takeEvery(actions.fetchQuestionsSheetsCountInMonth, fetchQuestionsSheetsCountInMonth);
  yield takeEvery(actions.buyQuestionTicket, buyQuestionTicket);
  yield takeEvery(actions.fetchPlan, fetchPlan);
  yield takeEvery(actions.confirmPointExchange, confirmPointExchange);
  yield takeEvery(actions.executePointExchange, executePointExchange);
  yield takeEvery(actions.fetchPointExchangeLogRecords, fetchPointExchangeLogRecords);
  yield takeEvery(actions.updateStudyTimeStatsGoalTime, updateStudyTimeStatsGoalTime);
  yield takeEvery(actions.fetchNextStudyTimeStats, fetchNextStudyTimeStats);
  yield takeEvery(actions.fetchStudyCourseCompletionTestResult, fetchStudyCourseCompletionTestResult);
  yield takeEvery(actions.getLearningCount, getLearningCount);
  // issue-547
  yield takeEvery(actions.fetchBookmarks, fetchBookmarks);
  yield takeEvery(actions.fetchBookmarksLearningCount, fetchBookmarksLearningCount);
  yield takeEvery(actions.deleteBookmarks, deleteBookmarks);
  yield takeEvery(actions.fetchPdfDownload, fetchPdfDownload);
  yield takeEvery(actions.fetchStudyAchievementsLatest, fetchStudyAchievementsLatest);
}

export default saga;
