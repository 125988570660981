import React, { useEffect, useState } from 'react';
import { useParams, Redirect, } from 'react-router-dom';
import Moment from 'react-moment';
import { mappedProps } from 'containers/trainer/StudentStudyLogs';

const TrainerStudentStudyLogs: React.FC<mappedProps> = (props: mappedProps) => {
  const { id } = useParams<{ id: string; }>();

  const [curriculum, setCurriculum] = useState<string | undefined>(undefined)
  const curriculumObj = props.getCurriculum(curriculum)
  const studyLogs = curriculumObj && props.getStudyLogs(curriculumObj.id, Number(id))
  const hasNext = curriculumObj && props.hasNextStudyLog(curriculumObj.id, Number(id))
  const [showDropDown, setShowDropDown] = useState<boolean>(false)
  const [dropDownCurriculumName, setDropDownCurriculumName] = useState<string | null>(null)

  useEffect(() => {
     if(props.curriculums && !dropDownCurriculumName){
      setDropDownCurriculumName(props.curriculums[0].name)
    }
  }, [props.curriculums, dropDownCurriculumName])

  useEffect(() => {
    if (!curriculum) {
      const firstCode = props.curriculums && props.curriculums.length > 0 ? props.curriculums[0].code : undefined
      setCurriculum(firstCode)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.curriculums, curriculum]);

  useEffect(() => {
    if (curriculumObj) {
      props.fetchFirst(curriculumObj.id, Number(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curriculumObj, id]);

  const downloadPdf = (curriculumCode: string) => {
    const curriculumCodesArray = [curriculumCode]
    props.pdfDownload(curriculumCodesArray)
  }


  if (props.curriculums && props.curriculums.length <= 0) {
    return <Redirect to="/" />
  }

  return (
    <React.Fragment>
      <div className="learning-history-box">
        <div className="learning-history-box__head">
          {showDropDown && <div onClick={() => setShowDropDown(false)} className="bookmark__back"></div>}
          <div className="bookmark__select-section">
            <div className="bookmark__relative">
              <div onClick={() => { setShowDropDown(!showDropDown) }} className="bookmark__dropdown-button">{dropDownCurriculumName}
                <div className="bookmark__dropdown-button-arrow"></div>
              </div>
              {
                props.curriculums && showDropDown &&
                <div className="bookmark__dropdown-container">
                  <ul>
                    {props.curriculums.map(x => (
                      <li
                        key={x.code}
                        onClick={() => {
                          setDropDownCurriculumName(x.name)
                          setCurriculum(x.code)
                          setShowDropDown(false)
                        }
                        }
                        className="bookmark__dropdown-option"
                      > {x.name} </li>
                    ))}
                  </ul>
                </div>
              }
            </div>
          </div>
        </div>
        <div className="learning-history-box__body">
          <div className="learning-history-box__title">
            { curriculumObj && curriculumObj.name }の学習履歴
          </div>

          {
            studyLogs && studyLogs.map((record, index) => (<React.Fragment key={index}>
              <div key={index} className={`
              ${index > 0 && 'learning-history-box__item-border'}
                learning-history-box__item-trainer
                `}>
                <div className="learning-history-box__text learning-history-box__text--no-button">
                  <div className="learning-history-box__date">
                    <Moment format="YYYY年M月D日 HH:mm">{ record.created_at }</Moment>
                    〜
                    <Moment format="YYYY年M月D日 HH:mm">{ record.updated_at }</Moment>
                  </div>
                  <div className="learning-history-box__topic" >
                    { record.content.topic.title } &gt; { record.content.meta!.label}
                    {
                      // MARK: 練習問題、レベルチェックテストのみ正答数を表示する
                      ( record.content.meta!.type === '練習問題' || record.content.meta!.type === 'レベルチェックテスト' ) &&
                      record.questions &&
                      <>
                        <span style={{ fontWeight: 'bold' }}>{ record.correct_answers }/{ record.questions }</span> （正解数/問題数）
                      </>
                    }
                  </div>
                </div>
              </div>
              {record.content.meta && record.content.meta.type !== 'completion_test' && record.content.launch_parameters.pdf_ready &&
              <div className="learning-history-box__pdf-download-button-container">
                <button
                  disabled={!record.content.launch_parameters.pdf_ready}
                  onClick={()=> downloadPdf(record.content.launch_parameters.curriculum_code)}
                  className="pdf-download-button">
                  PDF
                  <div className="pdf-download-button__icon" />
                </button>
              </div>
              }
            </React.Fragment >))
          }

          {
            studyLogs && studyLogs.length <= 0 &&
            <div style={{ padding: '20px' }}>
              学習履歴はありません。
            </div>
          }

        </div>
      </div>

      {
        hasNext &&
        <div className="more-button">
          <div
            onClick={() => { props.fetchNext() }}
            className="more-button__text">
            次のトピックを表示
          </div>
        </div>
      }
    </React.Fragment>
  );
}

export default TrainerStudentStudyLogs
