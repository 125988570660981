import React, { useEffect } from 'react';

import { mappedProps } from 'containers/common/WithdrawalComplete';
import PublicHeader from '../parts/PublicHeader';

const WithdrawalComplete: React.FC<mappedProps> = (props: mappedProps) => {
  useEffect(() => {
    props.logout()
  });

  return (
    <React.Fragment>
      <PublicHeader />
      <main className="site-main site-main--setting">
        <div className="site-main__main">
          <div className="site-main__main-inner">
            <div className="site-main__main-contents">
              <div className="setting-box">
                <div className="setting-box__title setting-box__title--withdraw">
                  退会
                </div>
                <div className="setting-message">
                  河合塾Oneからの退会が完了しました。
                </div>
                <div className="setting-box__button">
                  <button
                    onClick={() => {
                      window.location.href = 'https://www.kawaijukuone.co.jp/'
                    }}
                    className="button button--xlarge">河合塾One ホームページへ</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
}

export default WithdrawalComplete
