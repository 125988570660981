import React, { useEffect, useState } from 'react';

import PrivateHeader from 'containers/parts/PrivateHeader';
import { mappedProps } from 'containers/parent/Mypage';
import SiteMain from 'containers/parts/SiteMain';
import classNames from 'classnames';

import SectionInfoBox from 'components/parts/SectionInfoBox'
import CircleChart from 'components/parts/CircleChart';
import { Link } from 'react-router-dom';

const Mypage: React.FC<mappedProps> = (props: mappedProps) => {
  const [showHint, setShowHint] = useState(false);

  useEffect(() => {
    props.fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mypage = props.parent.mypage

  return (
    <React.Fragment>
      <PrivateHeader />
      <SiteMain>
        <div className="site-main__main-contents">
          <SectionInfoBox infoRecords={mypage ? mypage.admin_informations : []} />

          <section className="box">
            <header className="box__head">
              <div className="box__title box__title--result">
                学習結果<small className="box__title-small box__title-small--result">（コンプリート率・学習完了率）</small>
              </div>
              <span
                onClick={() => setShowHint(!showHint)}
                className="box__head-note">
                コンプリート率・学習完了率について
              </span>
              <div
                className={classNames("balloon box__balloon", { 'is-active': showHint })}>
                <div
                  onClick={() => setShowHint(false)}
                  className="balloon__close">
                  説明を閉じる
                </div>
                <div className="balloon__title">
                  コンプリート率とは？（英語・数学・物理・化学・古文）
                </div>
                <div className="balloon__lead">
                  コンプリートとは、AIの判定の結果、当該トピックについて教科書や大学入学共通テストのレベルに対して、十分な理解度に達していることを示しています。<br />
                  コンプリート率は、ご入会時に設定された範囲の全トピックのうち、コンプリートとなったトピック数の割合を算出しています。
                </div>
                <div className="balloon__title">
                  学習完了率とは？
                </div>
                <div className="balloon__lead">
                  学習完了率とは、受講者が設定した範囲の全トピックのうち、すべての教材の学習を修了したトピック数の割合を算出しています。（日本史・世界史・英語ベーシック・数学ベーシックなどAIおすすめ学習モードのないドリル教材は、AIによる理解度判定を行っていないため、「コンプリート率」ではなく「学習完了率」の表示となります。）
                </div>
              </div>
            </header>
            <div className="box__body">
              <ul className="study-result-list">
                {
                  mypage && mypage.curriculums && props.studentUser &&
                    mypage.curriculums
                    .filter(x => props.isRecommendCurriculum(x.id, props.studentUser!))
                    .map((x, index) => (
                    <li key={index} className="study-result-list__item">
                      <div className="study-result">
                        <div className="study-result__chart">
                          {
                            x.al_engine_connected ?
                            <CircleChart label={x.name} rate={x.understanding / 100 } />:
                            <CircleChart label={x.name} rate={x.understanding / 100 } colorType={2} />
                          }
                        </div>
                        <div className="study-result__button">
                          <Link to={`/student/study_result/${x.id}`} className="button button--short">{ x.name }科目一覧</Link>
                        </div>
                      </div>
                    </li>
                  ))
                }
              </ul>
            </div>
          </section>

        </div>
      </SiteMain>
    </React.Fragment>
  );
}

export default Mypage
