import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Link, NavLink } from 'react-router-dom';

import { mappedProps } from 'containers/parts/SiteMain';
import { hideStudyplusCorporationNames } from 'utils/constants'

type siteMainProps = {
  children?: React.ReactNode;
}

const SiteMain: React.FC<mappedProps> = (props: mappedProps & siteMainProps) => {
  const openRecommendNav = props.sidenav.openRecommendNav
  const setOpenRecommendNav = props.setOpenRecommendNav
  const openStudyNav = props.sidenav.openStudyNav
  const setOpenStudyNav = props.setOpenStudyNav
  const openHistoryNav = props.sidenav.openHistoryNav
  const setOpenHistoryNav = props.setOpenHistoryNav
  const openTestNav = props.sidenav.openTestNav
  const setOpenTestNav = props.setOpenTestNav
  // issue-545
  // issue-673
  const entrustCurriculumsList: string[] = ["junior_english",  "junior_math", "junior_science"] //ここで表示する教科を指定
  const openEntrustNav = props.sidenav.openEntrustNav
  const setOpenEntrustNav = props.setOpenEntrustNav
  // issue-545

  const availCurriculums = props.availCurriculums
  const curriculums = props.curriculums
  // issue-545
  const entrustCurriculums = curriculums && curriculums.filter(x => entrustCurriculumsList.includes(x.code))
  // issue-545
  const alEngineConnectedCurriculums = curriculums && curriculums.filter(x => x.al_engine_connected)

  // MARK: テスト一覧画面に遷移すべき教科のリスト（単元テストか科目修了テストがある）
  const testListCurridulums = curriculums && curriculums.filter(x => x.al_engine_connected || x.has_completion_test)

  const trainerUser = props.trainerUser

  const [studyplusAuthToken, setStudyplusAuthToken] = useState<null | { jwt: string; uid: string }>(null)

  useEffect(() => {
    // MARK: 法人トレーナーのみ
    if (trainerUser && trainerUser.corporation && !hideStudyplusCorporationNames.includes(trainerUser.corporation.name)) {
      props.getStudyplusAuthToken().then(x => {
        setStudyplusAuthToken(x);
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trainerUser]);

  return (
    <main className="site-main">
      <div className="site-main__main">
        <div className="site-main__main-inner">
          { props.children }
        </div>
      </div>
      <div className="site-main__sub">
        <ul className="sidebar">
          {
            props.isAvailableGuide && props.uiType === 'student' && 
            <li className="sidebar__item _pc">
              <Link to='/guide' className="sidebar__button sidebar__button--tutorial">
                初めてご利用になる方
              </Link>
            </li>
          }
          <li className="sidebar__item sidebar__item--mypage">
            <NavLink to='/' className="sidebar__link" activeClassName="is-active" exact>
              マイページ
            </NavLink>
          </li>
          {/* issue-545 */}
          {props.uiType !== 'trainer'  && entrustCurriculums && entrustCurriculums.length > 0 &&
            <li className="sidebar__item">
              <div
                onClick={() => setOpenEntrustNav(!openEntrustNav)}
                className={classNames("sidebar__text", { 'is-active': openEntrustNav })}>
                おまかせ学習
              </div>
              <ul className={classNames('sidebar-subnav', { 'is-active': openEntrustNav })}>
                {entrustCurriculums.map(x => (<React.Fragment key={x.code}>
                  {curriculums && curriculums.some(y => y.code === x.code) &&
                    <li key={x.code} className="sidebar-subnav__item">
                      <NavLink to={`/entrust/${x.code}`} className="sidebar-subnav__link" activeClassName="is-active">
                        <span className="sidebar-subnav__text">{x.name}</span>
                      </NavLink>
                    </li>}
                </React.Fragment>
                ))}
              </ul>
            </li>
          }
          {/* issue-545 */}
          {
            props.uiType === 'student' && props.studentUser &&
            <React.Fragment>
              {
                // MARK: 一般受講生はかならず英語を受講するのでalEngineConnectedCurriculumsが存在するはず
                alEngineConnectedCurriculums && alEngineConnectedCurriculums.length > 0 &&
                <>
                  <li className="sidebar__item">
                    <div
                      onClick={() => setOpenRecommendNav(!openRecommendNav)}
                      className={classNames("sidebar__text", { 'is-active' : openRecommendNav })}>
                      AIおすすめ学習
                    </div>

                    <ul className={classNames('sidebar-subnav', { 'is-active' : openRecommendNav })}>
                      {
                        alEngineConnectedCurriculums.map(x => (
                          <li key={x.code} className="sidebar-subnav__item">
                            <NavLink to={`/recommends/${x.code}`} className="sidebar-subnav__link" activeClassName="is-active">
                              <span className="sidebar-subnav__text">{x.name}</span>
                            </NavLink>
                          </li>
                        ))
                      }
                    </ul>
                  </li>
                  <li className="sidebar__item">
                    <div
                      onClick={() => setOpenTestNav(!openTestNav)}
                      className={classNames("sidebar__text", { 'is-active' : openTestNav })}>
                      科目・単元テスト一覧
                    </div>

                    <ul className={classNames('sidebar-subnav', { 'is-active' : openTestNav })}>
                      {
                        testListCurridulums && testListCurridulums.map(x => (
                          <li key={x.code} className="sidebar-subnav__item">
                            <NavLink to={`/study/course_results/${x.id}`} className="sidebar-subnav__link" activeClassName="is-active">
                              <span className="sidebar-subnav__text">{x.name}</span>
                            </NavLink>
                          </li>
                        ))
                      }
                    </ul>
                  </li>
                </>
              }

              <li className="sidebar__item">
                <div
                  onClick={() => setOpenStudyNav(!openStudyNav)}
                  className={classNames("sidebar__text", { 'is-active' : openStudyNav })}>
                  教科・単元選択学習
                </div>
                <ul className={classNames('sidebar-subnav', { 'is-active' : openStudyNav })}>
                  {
                    curriculums && curriculums.map(x => (
                      <li key={x.code} className="sidebar-subnav__item">
                        <NavLink to={`/study/${x.code}`} className="sidebar-subnav__link" activeClassName="is-active">
                          <span className="sidebar-subnav__text">{x.name}</span>
                        </NavLink>
                      </li>
                    ))
                  }
                </ul>
              </li>
              <li className="sidebar__item">
                <div
                  onClick={() => setOpenHistoryNav(!openHistoryNav)}
                  className={classNames("sidebar__text", { 'is-active' : openHistoryNav })}>
                  学習履歴
                </div>
                <ul className={classNames('sidebar-subnav', { 'is-active' : openHistoryNav })}>
                  {
                    availCurriculums && availCurriculums.map(x => (
                      <li key={x.code} className="sidebar-subnav__item">
                        <NavLink to={`/study_logs/${x.code}`} className="sidebar-subnav__link" activeClassName="is-active">
                          <span className="sidebar-subnav__text">{x.name}</span>
                        </NavLink>
                      </li>
                    ))
                  }
                </ul>
              </li>
              {/* // issue-547 */}
              <li className="sidebar__item">
                  <NavLink to='/bookmark' 
                  className="sidebar__link" 
                  activeClassName="is-active"
                  >
                    ブックマーク
                  </NavLink>
                </li>
               {/*  // issue-547 */}
              {
                props.studentUser.trainer_contracted && props.isAvailableStudentMessage &&
                <li className="sidebar__item">
                  <NavLink to='/messages' className="sidebar__link" activeClassName="is-active">
                    トレーナー相談
                  </NavLink>
                </li>
              }
              {
                props.availableQuestions && 
                <li className="sidebar__item">
                  <NavLink to='/study_question_logs' className="sidebar__link" activeClassName="is-active">
                    先生からの回答
                  </NavLink>
                </li>
              }

            </React.Fragment>
          }

          {
            props.uiType === 'parent' &&
            <React.Fragment>
              <li className="sidebar__item">
                <div
                  onClick={() => setOpenStudyNav(!openStudyNav)}
                  className={classNames("sidebar__text", { 'is-active' : openStudyNav })}>
                  教科・単元選択学習
                </div>
                <ul className={classNames('sidebar-subnav', { 'is-active' : openStudyNav })}>
                  {
                    curriculums && curriculums.map(x => (
                      <li key={x.code} className="sidebar-subnav__item">
                        <NavLink to={`/student/study_result/${x.code}`} className="sidebar-subnav__link" activeClassName="is-active">
                          <span className="sidebar-subnav__text">{x.name}</span>
                        </NavLink>
                      </li>
                    ))
                  }
                </ul>
              </li>
              <li className="sidebar__item">
                <NavLink to='/student/study_logs' className="sidebar__link" activeClassName="is-active">
                  受講者の学習履歴
                </NavLink>
              </li>
            </React.Fragment>
          }

          {
            props.uiType === 'trainer' &&
            <React.Fragment>
              <li className="sidebar__item">
                <div
                  onClick={() => setOpenStudyNav(!openStudyNav)}
                  className={classNames("sidebar__text", { 'is-active' : openStudyNav })}>
                  教科・単元選択学習
                </div>
                <ul className={classNames('sidebar-subnav', { 'is-active' : openStudyNav })}>
                {
                    curriculums && curriculums.map(x => (
                      <li key={x.code} className="sidebar-subnav__item">
                        <NavLink to={`/study/${x.code}`} className="sidebar-subnav__link" activeClassName="is-active">
                          <span className="sidebar-subnav__text">{x.name}</span>
                        </NavLink>
                      </li>
                    ))
                  }
                </ul>
              </li>

              <li className="sidebar__item">
                <NavLink to='/study_summaries' className="sidebar__link" activeClassName="is-active">
                  学習状況
                </NavLink>
              </li>
              <li className="sidebar__item">
                <NavLink to='/csv_download' className="sidebar__link" activeClassName="is-active">
                  学習データ
                </NavLink>
              </li>
              {
                trainerUser && trainerUser.corporation && studyplusAuthToken &&
                <React.Fragment>
                  <form
                    acceptCharset="UTF-8"
                    method="post"
                    action={`${props.studyplusAuthUrlPrefix}${studyplusAuthToken.uid}`}
                  >
                    <input type="hidden" name="token" value={studyplusAuthToken.jwt} />
                    <button
                      type="submit"
                      className="button button--primary button--studyplus">
                      Studyplus連携<br/>
                      <p className="button--studyplus-subtext">（外部サイトにリンク）</p>
                    </button>
                  </form>
                </React.Fragment>
              }
            </React.Fragment>
          }

        </ul>
      </div>
    </main>
  )
}
export default SiteMain
