import React, { useEffect, useState } from 'react';
import { Link, Redirect, } from 'react-router-dom';
import Moment from 'react-moment';
import classNames from 'classnames';

import PrivateHeader from 'containers/parts/PrivateHeader';
import { mappedProps } from 'containers/parent/StudentStudyLogs';
import SiteMain from 'containers/parts/SiteMain';
import StudentBar from '../parts/CommonStudentBar'

const ParentStudentStudyLogs: React.FC<mappedProps> = (props: mappedProps) => {
  // MARK: 講師、トレーナーとインターフェース統一のためダミーIDを指定
  const id = 1;

  const firstCode = props.curriculums.length > 0 ? props.curriculums[0].code : undefined
  const [curriculum, setCurriculum] = useState<string | undefined>(firstCode)

  const curriculumObj = props.getCurriculum(curriculum)
  const student = props.getStudent(Number(id))
  const studyLogs = curriculumObj && props.getStudyLogs(curriculumObj.id, Number(id))
  const hasNext = curriculumObj && props.hasNextStudyLog(curriculumObj.id, Number(id))

  useEffect(() => {
    if (curriculumObj) {
      props.fetchFirst(curriculumObj.id, Number(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curriculum, id]);

  if (props.curriculums.length <= 0) {
    return <Redirect to="/" />
  }

  return (
    <React.Fragment>
      <PrivateHeader />
      <SiteMain>
        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          <li className="breadcrumbs__item">
            学習履歴
          </li>
        </ol>

        <div className="site-main__main-contents">
          {
            student && 
            <StudentBar student={student} />
          }

          <div className="learning-history-box">
            <div className="learning-history-box__head">
              {
                props.curriculums && props.curriculums.map(x => (
                  <div
                      key={x.code}
                      onClick={() => { setCurriculum(x.code)  }}
                      className={classNames("learning-history-box__tab", {
                      'is-active' : x.code === curriculum,
                    })}> { x.name } </div>
                ))
              }
            </div>
            <div className="learning-history-box__body">
              <div className="learning-history-box__title">
                { curriculumObj && curriculumObj.name }の学習履歴
              </div>

              {
                studyLogs && studyLogs.map((record, index) => (
                  <div key={index} className="learning-history-box__item">
                    <div className="learning-history-box__text learning-history-box__text--no-button">
                      <div className="learning-history-box__date">
                        <Moment format="YYYY年M月D日 HH:mm">{ record.created_at }</Moment>
                        〜
                        <Moment format="YYYY年M月D日 HH:mm">{ record.updated_at }</Moment>
                      </div>
                      <div className="learning-history-box__topic" >
                        { record.content.topic.title } &gt; { record.content.meta!.label}
                        {
                          // MARK: 練習問題、レベルチェックテストのみ正答数を表示する
                          ( record.content.meta!.type === '練習問題' || record.content.meta!.type === 'レベルチェックテスト' ) &&
                          record.questions &&
                          <>
                            　
                            <span style={{ fontWeight: 'bold' }}>{ record.correct_answers }/{ record.questions }</span> （正解数/問題数）
                          </>
                        }
                      </div>
                    </div>
                  </div>
                ))
              }
              {
                studyLogs && studyLogs.length <= 0 && 
                <div style={{ padding: '20px' }}>
                  学習履歴はありません。
                </div>
              }
            </div>
          </div>

          {
            hasNext &&
            <div className="more-button">
              <div
                onClick={() => { props.fetchNext() }}
                className="more-button__text">
                次のトピックを表示
              </div>
            </div>
          }

          <div className="back-home">
            <Link to='/' className="back-home__link">マイページへ戻る</Link>
          </div>
        </div>

        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          <li className="breadcrumbs__item">
            学習履歴
          </li>
        </ol>
      </SiteMain>
    </React.Fragment>
  );
}

export default ParentStudentStudyLogs
