import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { orderBy } from "lodash";
import storeUtils from 'utils/store'

import RealtimeViewHeader from "containers/parts/RealtimeViewHeader";
import { mappedProps } from "containers/trainer/RealtimeView";
import RealtimeViewMain from "containers/parts/RealtimeViewMain";

import * as Models from 'models/api'
import "react-datepicker/dist/react-datepicker.css";

import CorrectIcon from 'assets/img/ico_correct.png';
import IncorrectIcon from 'assets/img/ico_incorrect.png';

type SortTypeRealtime = {
  nickName: boolean;
  currentStartedAt: boolean;
  correctAnswerPercentage: boolean;
  countTotal: boolean;
};

const RealtimeView: React.FC<mappedProps> = (props: mappedProps) => {
  let { classId }: any = useParams();
  const schoolClasses = props.trainer.schholClasses;
  const selectedClassObject = schoolClasses && schoolClasses.find((x) => x.id === parseInt(classId));
  const [currentTime, setCurrentTime] = useState<Date>(new Date());
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const [displayStartedTime, setDisplayStartedTime] = useState<Date>(new Date());
  const [startDate, setStartDate] = useState<Date>(new Date());
  useEffect(() => {
    setDisplayStartedTime(new Date());
    setStartDate(new Date());
  }, []);

  const [studyStatusRealtime, setStudyStatusRealtime] = useState<Models.StudyStatusRealtimeType | null>(null);

  const initialSelectedSortButton: SortTypeRealtime ={
    nickName: false,
    currentStartedAt: false,
    correctAnswerPercentage: false,
    countTotal: false,
  }

  const initialSelectedSortType = {
    nickName: true,
    currentStartedAt: true,
    correctAnswerPercentage: true,
    countTotal: true,
  };

  // 選択中のasc/descボタン
  const [selectedSortButton, setSelectedSortButton] = useState<SortTypeRealtime>({...initialSelectedSortButton, nickName: true});

  const [selectedSortType, setSelectedSortType] = useState<SortTypeRealtime>(initialSelectedSortType);

  // Sortの状態に従ってテーブルを並び替える
  useEffect(() => {
    const studyStatusRealtime =
      props.studyStatusRealtime && props.studyStatusRealtime.students;
    let newStudyStatusRealtime;
    if (selectedSortButton.nickName)
      newStudyStatusRealtime = orderBy(
        studyStatusRealtime,
        "student.nick_name",
        selectedSortType.nickName ? "asc" : "desc",
      );
    if (selectedSortButton.currentStartedAt)
      newStudyStatusRealtime = orderBy(
        studyStatusRealtime,
        "status.currently_learning.started_at",
        selectedSortType.currentStartedAt ? "asc" : "desc",
      );
    if (selectedSortButton.correctAnswerPercentage)
      newStudyStatusRealtime = orderBy(
        studyStatusRealtime,
        "status.correct_answer_percentage",
        selectedSortType.correctAnswerPercentage ? "asc" : "desc",
      );
    if (selectedSortButton.countTotal)
      newStudyStatusRealtime = orderBy(
        studyStatusRealtime,
        "status.count_total",
        selectedSortType.countTotal ? "asc" : "desc",
      );
    newStudyStatusRealtime && setStudyStatusRealtime(newStudyStatusRealtime);
  }, [selectedSortType, selectedSortButton, props.studyStatusRealtime]);

  useEffect(() => {
    props.fetchData();
    props.fetchSchoolClassess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // MARK: ポーリングを開始する
    props.fetchStudyStatusRealtime({
      school_class_id: classId,
      beginning_date_at: startDate.toISOString()});
    return () => {
      // MARK: ポーリングを停止する
      props.stopFetchStudyStatusRealtime()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, classId]);

  useEffect(() => {
    // ２時間経過した場合はポーリングを停止する
    const elapsedTimeHours = Math.floor((currentTime.getTime() - displayStartedTime.getTime()) /1000 /60 /60,);
    if (elapsedTimeHours >= 2) {
      props.stopFetchStudyStatusRealtime();
    }
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  , [currentTime, displayStartedTime]);

  // ソートやボタン選択の状態を変更
  const handleSortButton = (selectedSort: string) => {
    selectedSort === "student.nick_name" &&
      setSelectedSortButton({ ...initialSelectedSortButton, nickName: true });
    selectedSort === "status.current_started_at" &&
      setSelectedSortButton({...initialSelectedSortButton, currentStartedAt: true});
    selectedSort === "status.correct_answer_percentage" &&
      setSelectedSortButton({...initialSelectedSortButton, correctAnswerPercentage: true});
    selectedSort === "status.count_total" &&
      setSelectedSortButton({ ...initialSelectedSortButton, countTotal: true });

    selectedSort === "student.nick_name" &&
      selectedSortButton.nickName &&
      setSelectedSortType({...selectedSortType, nickName: !selectedSortType.nickName});
    selectedSort === "status.current_started_at" &&
      selectedSortButton.currentStartedAt &&
      setSelectedSortType({...selectedSortType, currentStartedAt: !selectedSortType.currentStartedAt});
    selectedSort === "status.correct_answer_percentage" &&
      selectedSortButton.correctAnswerPercentage &&
      setSelectedSortType({...selectedSortType, correctAnswerPercentage: !selectedSortType.correctAnswerPercentage});
    selectedSort === "status.count_total" &&
      selectedSortButton.countTotal &&
      setSelectedSortType({...selectedSortType, countTotal: !selectedSortType.countTotal});
  };

  const switchStartTime = (event: React.ChangeEvent<HTMLSelectElement>) => {
    // displayStartedTimeの15分前をstartDateに設定
    event.target.value === "1" && setStartDate(new Date(displayStartedTime.getTime() - 15 * 60 * 1000))
    // displayStartedTimeの30分前をstartDateに設定
    event.target.value === "2" && setStartDate(new Date(displayStartedTime.getTime() - 30 * 60 * 1000))
    // startDateを元に戻す
    event.target.value === "3" && setStartDate(new Date(displayStartedTime.getTime()))
  };

  const renderCorrectAnswerPercentage = (correctAnswerPercentage : number, totalAnswerCount: number) => {
    const correctAnswerPercentageThreshold = [50, 70, 90];   // 正答率表示切り替えの閾値の設定
    if (correctAnswerPercentage === 0 && totalAnswerCount === 0) {
      return <div className="_normal">データなし</div>
    }
    if (correctAnswerPercentage < correctAnswerPercentageThreshold[0] && totalAnswerCount > 0) {
      return(
      <div className="trainer-my-page__answer-red">{correctAnswerPercentageThreshold[0]-1}%以下</div>
    )}
    if (correctAnswerPercentage >= correctAnswerPercentageThreshold[0] && correctAnswerPercentage <  correctAnswerPercentageThreshold[1]) {
      return(
      <div className="trainer-my-page__answer-black">
        {correctAnswerPercentageThreshold[0]}〜{correctAnswerPercentageThreshold[1] -1 }%
      </div>
    )}
    if (correctAnswerPercentage >= correctAnswerPercentageThreshold[1] && correctAnswerPercentage < correctAnswerPercentageThreshold[2]) {
      return(
      <div className="trainer-my-page__answer-black">
        {correctAnswerPercentageThreshold[1]}〜{correctAnswerPercentageThreshold[2] -1 }%
      </div>
    )}
    if (correctAnswerPercentage >= correctAnswerPercentageThreshold[2]) {
      return(
      <div className="trainer-my-page__answer-green">90%以上</div>
    )}
  }

  const renderLearningTime = (startedAt : string, currentTime: Date) => {
    if (!startedAt) {return <>-</>}
    const hour = Math.floor((currentTime.getTime() - new Date(startedAt).getTime()) /1000 /60 /60)
    const minute = Math.floor((currentTime.getTime() - new Date(startedAt).getTime()) /1000 /60 %60).toString().padStart(2, '0')
    const second = Math.floor((currentTime.getTime() - new Date(startedAt).getTime()) /1000 %60).toString().padStart(2, '0')
    if (hour >= 24) {
      // 24時間以上の場合は-を表示
      return <>-</>
    } else if (hour >= 1) {
      //  １時間以上の場合は赤文字で60:00を表示
      return <span className="trainer-realtime-view_time-exceeded">60:00</span>
    } else {
      return <>{minute}:{second}</>
    }
  }

  const renderLearningContent = (currentlyLearning: Models.Currently_learning) => {
    const startedAt = currentlyLearning.started_at
    const hour = Math.floor((currentTime.getTime() - new Date(startedAt).getTime()) /1000 /60 /60)
    if (!currentlyLearning.content || hour >= 24) {
      return <>ー</>
    } else {
      return (<>
        {storeUtils.getContentMetaObjectByCode(currentlyLearning.content.title).isVideo === true ? 
          <div className="trainer-realtime-view__video-icon" />:
          <div className="trainer-realtime-view__textbook-icon"/>
        }
        <div>
          {currentlyLearning.curriculum.name} {"＞"}
          {currentlyLearning.course.name} {"＞"}
          {currentlyLearning.unit.name} {"＞"}
          {currentlyLearning.topic.title} {"＞"}
          {storeUtils.getContentMetaObjectByCode(currentlyLearning.content.title).label}
        </div>
      </>)
    }
  }

  return (
    <React.Fragment>
      <RealtimeViewHeader />
      <RealtimeViewMain>
        <div className="site-main__main-contents">
          <section className="trainer-realtime-view">
            <header>
              <div className="trainer-realtime-view__header">
                <div className="trainer-realtime-view__header-label-container">
                  <div className="trainer-realtime-view__header-label">クラス</div>
                  {classId ? selectedClassObject && selectedClassObject.name: <>選択なし</>}
                </div>
                <div className="trainer-realtime-view__header-label-container">
                  <div className="trainer-realtime-view__header-label">表示開始</div>
                  {displayStartedTime && displayStartedTime.toLocaleString('ja-JP', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })}
                  <div className="trainer-realtime-view__select-container">
                    <select
                      className="trainer-realtime-view__select"
                      onChange={switchStartTime}>
                      <option value={0}>開始時刻を変更</option>
                      <option value={1}>15分前からの結果を表示</option>
                      <option value={2}>30分前からの結果を表示</option>
                      <option value={3}>元の表示に戻す</option>
                    </select>
                  </div>
                </div>

                <button
                  className="trainer-realtime-view__close-button"
                  onClick={window.close}
                >
                  <span>このウィンドウを閉じる</span>
                </button>
              </div>
            </header>

            <div className="trainer-realtime-view__table-container">
              <table className={"trainer-realtime-view__table"}>
                <thead>
                  <tr>
                    <th>
                      生徒名
                      <button
                        className={`trainer-my-page__sort-button ${!selectedSortButton.nickName && "trainer-my-page__sort-button--inactive"}`}
                        onClick={() => handleSortButton("student.nick_name")}
                      >
                        {selectedSortType.nickName ? (
                          <>&#x2193;</>
                        ) : (
                          <>&#x2191;</>
                        )}
                      </button>
                    </th>
                    <th className="_no-sort">閲覧中のコンテンツ</th>
                    <th>
                      経過時間
                      <button
                        className={`trainer-my-page__sort-button ${!selectedSortButton.currentStartedAt && "trainer-my-page__sort-button--inactive"}`}
                        onClick={() => {
                          handleSortButton("status.current_started_at");
                        }}
                      >
                        {selectedSortType.currentStartedAt ? (
                          <>&#x2193;</>
                        ) : (
                          <>&#x2191;</>
                        )}
                      </button>
                    </th>
                    <th className="_no-sort">正誤 (直近10問)</th>
                    <th>
                      正答率
                      <button
                        className={`trainer-my-page__sort-button ${!selectedSortButton.correctAnswerPercentage && "trainer-my-page__sort-button--inactive"}`}
                        onClick={() => {
                          handleSortButton(
                            "status.correct_answer_percentage",
                          );
                        }}
                      >
                        {selectedSortType.correctAnswerPercentage ? (
                          <>&#x2193;</>
                        ) : (
                          <>&#x2191;</>
                        )}
                      </button>
                    </th>
                    <th className="_no-sort">問題解答数</th>
                    <th className="_no-sort">視聴数</th>
                    <th>
                      合計
                      <button
                        className={`trainer-my-page__sort-button ${!selectedSortButton.countTotal && "trainer-my-page__sort-button--inactive"}`}
                        onClick={() => {
                          handleSortButton("status.count_total");
                        }}
                      >
                        {selectedSortType.countTotal ? (
                          <>&#x2193;</>
                        ) : (
                          <>&#x2191;</>
                        )}
                      </button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {studyStatusRealtime &&
                    studyStatusRealtime.map((record, index) => {
                      return (
                        <React.Fragment key={index}>
                          <tr>
                            <td className="_align-left">
                              <Link
                                to={`/students/${record.student.id}/schedules`}
                                className="student-box__name"
                              >
                                {record.student.nick_name}
                              </Link>
                              <small>{record.student.school_grade}　{record.student.login}</small>
                            </td>
                            <td className="_align-left">
                              <div className="trainer-realtime-view__table-b-content">
                                {renderLearningContent(record.status.currently_learning)}
                              </div>
                            </td>
                            <td>
                              {renderLearningTime(record.status.currently_learning.started_at, currentTime)}
                            </td>
                            <td className="_align-left">
                              <div className="trainer-realtime-view__answer-icons">
                                {record.status.recent_answer_results.length <= 0 && <>-</>}
                                {record.status.recent_answer_results.map(
                                  (answer, index) => {
                                    return (
                                      <React.Fragment key={index}>
                                        {answer ? (
                                          <img src={CorrectIcon} alt="" width={15} height={15} />
                                        ) : (
                                          <img src={IncorrectIcon} alt="" width={14} height={14} />
                                        )}
                                      </React.Fragment>
                                    );
                                  },
                                )}
                              </div>
                            </td>
                            <td className="_nowrap _bold">
                              {renderCorrectAnswerPercentage(record.status.correct_answer_percentage, record.status.total_answer_count )}
                            </td>
                            <td>{record.status.total_answer_count}</td>
                            <td>{record.status.total_view_count}</td>
                            <td>{record.status.count_total}</td>
                          </tr>
                        </React.Fragment>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </section>
        </div>
      </RealtimeViewMain>
    </React.Fragment>
  );
};

export default RealtimeView;
