import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from "react-router-dom";

import SignupResult from 'components/signup/SignupResult';
import { AppState } from "store";

const mapStateToProps = (appState: AppState, ownProps: RouteComponentProps) => {
  const loginPageUrl = 'https://student.kawaijukuone.jp'
  return {
    ...ownProps,
    loginPageUrl,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({})

type connectMappedProps = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>
export type mappedProps = connectMappedProps

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignupResult))
