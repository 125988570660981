import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router'

import { AppState } from "store";
import studentActions from 'actions/studentActions';
import StudentMypage from 'components/student/Mypage';
import * as Models from 'models/api';
import moment from "moment";

import * as campaignSetting from 'utils/campaign/shiro202003'
import * as campaignSettingPoint from 'utils/campaign/point202007'
import * as campaignSettingGuide from 'utils/campaign/guide202012'
import * as studentMesageSetting from 'utils/campaign/studentMessage'
import * as studentInfo from 'utils/campaign/studentInfo'

import { isRecommendCurriculum, }  from 'utils/store'

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch })

const mapStateToProps = (appState: AppState, ownProps: RouteComponentProps) => {
  const user = appState.session.user! as Models.StudentUser

  const isAvailableShiro202003 = campaignSetting.isAvailable(
    appState.ui.env!, moment())
  const isAvailablePoint202007 = campaignSettingPoint.isAvailable(
    appState.ui.env!, moment())
  const isAvailableGuide = campaignSettingGuide.isAvailable(user)

  const isAvailableStudentMessage = studentMesageSetting.isAvailable(user)
  const isAvailableStudentInfo = studentInfo.isAvailable(user)

  const availableQuestions = (
    !user.corporation ||
    ( user.corporation && user.corporation.available_services.questions )
  )
  const studyAchievementsLatest = appState.student.studyAchievementsLatest.record

  return {
    isAvailableStudentInfo,
    isAvailableStudentMessage,
    isAvailableGuide,
    isAvailableShiro202003,
    isAvailablePoint202007,
    user,
    availableQuestions,
    student: appState.student,
    question: appState.student.question,
    studyAchievementsLatest,
    ...ownProps,
  }
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  { dispatch }: ReturnType<typeof mapDispatchToProps>) => {
  return {
    ...stateProps,
    isRecommendCurriculum,
    fetchData: () => {
      dispatch(studentActions.fetchMypage())
      dispatch(studentActions.fetchQuestionRecords({ initialize: true }))
    },
    fetchNextStudyTimeStats: () => {
      dispatch(studentActions.fetchNextStudyTimeStats())
    },
    updateSchedule: (id: number, body: string) => {
      return new Promise<string>((resolve, reject) => {
        dispatch(studentActions.updateSchedule({
          params: { id, body },
          promises: { resolve, reject }
        }))
      })
    },
    updateStudyTimeStatsGoalTime: (daily_goal_time: number) => {
      return new Promise<void>((resolve, reject) => {
        dispatch(studentActions.updateStudyTimeStatsGoalTime({
          params: { daily_goal_time },
          promises: { resolve, reject }
        }))
      })
    },
    fetchEntrustStudyData: () => {
      return new Promise<Models.EntrustCurrentRecords>((resolve, reject) => {
        dispatch(studentActions.fetchEntrustStudyRecords({
          promises: { resolve, reject }
        }))
      })
    },
    fetchStudyAchievementsLatest: () => {
      return new Promise<Models.StudyAchievementsLatest>((resolve, reject) => {
        dispatch(studentActions.fetchStudyAchievementsLatest(
          { promises: { resolve, reject } }
        ))
      })
    }
  }
}

export type mappedProps = ReturnType<typeof mergeProps>

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(StudentMypage))