import React, { useEffect, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';

import PrivateHeader from 'containers/parts/PrivateHeader';
import { mappedProps } from 'containers/student/StudyTopic';
import SiteMain from 'containers/parts/SiteMain';
import classNames from 'classnames';
import {StudentStudyTopicWithStudyCount,   StudentStudyLearningCountRecords} from '../../models/api';
import CorrectAnswerCount from './common/CorrectAnswerCount';

const StudyTopic: React.FC<mappedProps> = (props: mappedProps) => {
  const { topic_id } = useParams<{topic_id: string}>();
  const history = useHistory()

  const [disabledContents, setDisabledContents] = useState<number[]>([]);
  const isDisabledContent = (contentId: number) => disabledContents.includes(contentId)
  const setDisabledContent = (contentId: number) => {
    if (isDisabledContent(contentId)) { return }
    setDisabledContents(disabledContents.concat([contentId]))
  }
  const unsetDisabledContent = (contentId: number) => {
    setDisabledContents(disabledContents.filter(x => x === contentId))
  }

  useEffect(() => {
    if (topic_id) {
      props.fetchData(Number(topic_id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topic_id]);

  useEffect(() => {
    props.fetchStudyAchievementsLatest();
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  , []);

  const [page, setPage] = useState<StudentStudyTopicWithStudyCount | null>(null)
  useEffect(() => {
    if (topic_id) {
      setPage(props.getRecord(Number(topic_id)))
    }
  }
   // eslint-disable-next-line react-hooks/exhaustive-deps
  , [topic_id, props.getRecord])

// カリキュラムコードの取得
const [curriculumCodesArray, setCurriculumCodesArray] = useState<string[] | null >(null);
  useEffect(() => {
    if (page){
      const newCurriculumCodesArray: any = page.contents.map(x => x.launch_parameters.curriculum_code)
      if (newCurriculumCodesArray) {
        setCurriculumCodesArray(newCurriculumCodesArray)
      }
    }
  }
   // eslint-disable-next-line react-hooks/exhaustive-deps
  , [page])

// 学習回数の取得
const [learningCount, setLearningCount] = useState<StudentStudyLearningCountRecords | null>(null);
  useEffect(() => {
    if (curriculumCodesArray){
      props.getLearningCount({curriculumCodes: curriculumCodesArray}).then(
        (learningCount: StudentStudyLearningCountRecords) => {
          setLearningCount(learningCount)
        }
      )
    }
  }
   // eslint-disable-next-line react-hooks/exhaustive-deps
  , [curriculumCodesArray])

// learning_countの入った新しい配列を作る
const [pageWithCount, setPageWithCount] = useState<StudentStudyTopicWithStudyCount | null>(null)
  useEffect(() => {
    if (page && learningCount){
      const newPageWithCount: StudentStudyTopicWithStudyCount = {...page}
      newPageWithCount.contents = page.contents.map((x: any) => {
        const learningCountRecord = learningCount.find((y: any) => y.curriculum_code === x.launch_parameters.curriculum_code)
        if (learningCountRecord){
          x.learning_count = learningCountRecord.learning_count
        } else {
          x.learning_count = 0
        }
        return x
      })
      setPageWithCount(newPageWithCount)
    }
  }
   // eslint-disable-next-line react-hooks/exhaustive-deps
  , [page, learningCount])

  const [types , setTypes] = useState<string[]>([]);
  useEffect(() => {
    const types = props.studyContentTypes
    setTypes(types)
  }
  , [props.studyContentTypes])

  const [selectedPdfDownload, setSelectedPdfDownload] = useState<string[]>([]);

  const checkBoxHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const curriculum_code = e.target.value
    if (selectedPdfDownload.includes(curriculum_code)) {
      setSelectedPdfDownload(selectedPdfDownload.filter(x => x !== curriculum_code))
    } else {
      setSelectedPdfDownload(selectedPdfDownload.concat([curriculum_code]))
    }
  }

  const downloadSelectedPdf = () => {
    if (selectedPdfDownload.length > 0 ) {
      props.fetchPdfDownload(selectedPdfDownload)
    }
  }

  const downloadAllPdf = () => {
    if (curriculumCodesArray && curriculumCodesArray.length > 0 ) {
      props.fetchPdfDownload(curriculumCodesArray)
    }
  }

  return (
    <React.Fragment>
      <PrivateHeader />
      <SiteMain>
        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          {
            pageWithCount &&
            <React.Fragment>
              <li className="breadcrumbs__item">
                {pageWithCount &&
                <Link to={`/study/${pageWithCount.curriculum.id}`} className="breadcrumbs__link">
                  {pageWithCount.curriculum.name}
                </Link>}
              </li>
              <li className="breadcrumbs__item">
                {pageWithCount &&  <Link to={`/study/courses/${pageWithCount.course.id}`} className="breadcrumbs__link">
                  { pageWithCount.course.name }
                </Link>}
              </li>
              <li className="breadcrumbs__item">
                {pageWithCount &&
                 <Link to={`/study/units/${pageWithCount.unit.id}`} className="breadcrumbs__link">
                 { pageWithCount.unit.name }
                 </Link>}
              </li>
              <li className="breadcrumbs__item">
                {pageWithCount && pageWithCount.topic.title }
              </li>
            </React.Fragment>
          }
        </ol>

        {pageWithCount &&
          <div className="site-main__main-contents">
            <div className="page-title">
            <div className="page-title__left">
              <div>{ pageWithCount.curriculum.name }
                <span> </span>
                <div className="page-title__badge">
                 { pageWithCount.course.name }
                </div>
                  { pageWithCount.unit.name }
              </div>
              </div>
              <div className="page-title__correct-count">
                {props.student.studyAchievementsLatest.record && <>
                  <CorrectAnswerCount correctAnswerCount={props.student.studyAchievementsLatest.record.study_achievements.consecutive_correct_answer_count}/>
                </>}
              </div>
            </div>
            <div className="topic-detail-box">
              <div className="topic-detail-box__title">
                { pageWithCount.topic.title }
              </div>
              <div className="topic-detail-box__download-message">
                {pageWithCount.contents.some((x) => x.launch_parameters.pdf_ready === true) &&
                  <button
                    className="pdf-download-button"
                    onClick={downloadAllPdf}>
                    まとめてダウンロード (PDF)
                    <div className="pdf-download-button__icon" />
                  </button>}
              </div>
              {pageWithCount &&
                types.map((type, index) => {
                  const contents = pageWithCount.contents.filter(x => x.meta!.type === type)
                  if (contents.length <= 0) {
                    return <React.Fragment key={index}></React.Fragment>
                  }
                  return (
                    <React.Fragment key={index}>
                      <div className="topic-detail-box__subtitle page-subtitle">
                        { type }
                        <span className="page-subtitle__note">
                        {
                          pageWithCount.curriculum.name === '英単語' && type === '単語帳' ? '<このトピックで学習する単語>' : null
                        }
                        </span>
                      </div>
                      <div className="topic-detail-box__items">
                        {
                          contents.map((x, index) => (
                          <React.Fragment key={index}>
                          <div key={index} className="topic-detail-box__item_many-section">
                            <div key={index} className={
                              x.learning_count === 0 ?
                              "topic-detail-box__item":
                              "topic-detail-box__item_many"
                            }>
                              <div className={
                                classNames(
                                  "topic-detail-box__content-name topic-detail-box__content-name--2lines",
                                  {
                                    'topic-detail-box__content-name--test': !x.meta!.isVideo,
                                    'topic-detail-box__content-name--video': x.meta!.isVideo,
                                  }
                                )
                              }>
                                { x.meta!.label }
                                <span className="topic-detail-box__content-note">
                                {
                                  pageWithCount.curriculum.name === '英単語' && type === '単語帳' ? '<このトピックで学習する単語>' : null
                                }
                                </span>
                              </div>

                              <div className={"topic-detail-box__learning_counts_container"}>
                                <div className={
                                  x.learning_count === 0 ?
                                  "topic-detail-box__learning_counts" :
                                  "topic-detail-box__learning_counts_many"
                                  }>学習回数{x.learning_count ?
                                ' ' + x.learning_count.toString() :
                                  ' 0'}回</div>
                              </div>

                              <a
                                onClick={(e) => {
                                  e.preventDefault()
                                  const content_id = x.id
                                  if (isDisabledContent(content_id)) { return }
                                  setDisabledContent(content_id)
                                  const return_pathname = history.location.pathname
                                  props.startStudy({ content_id, return_pathname }).then((url)  => {
                                    unsetDisabledContent(content_id)
                                    window.location.href = url
                                  })
                                }}
                                href="#!"
                                className={
                                  classNames(
                                    "topic-detail-box__button button button--primary button--small",
                                    { "is-disabled": isDisabledContent(x.id) }
                                  )
                                }
                                >
                                {
                                  x.meta!.type === '単語帳' ? '単語帳を見る' :
                                  // 「解説」の場合は「テスト開始」ではなく「講義を見る」
                                  x.meta!.isVideo ? '講義を見る' : 'テストを開始'
                                }
                              </a>
                            </div>
                            {x.launch_parameters.pdf_ready && 
                              <div className="topic-detail-box__pdf-download-checkbox-section">
                                <input
                                  value={x.launch_parameters.curriculum_code}
                                  className="topic-detail-box__input-check-box"
                                  id={x.id.toString()}
                                  type="checkbox"
                                  onChange={checkBoxHandler}/>
                                <label
                                  htmlFor={x.id.toString()}
                                  className="topic-detail-box__input-check-box-label"
                                  >
                                  問題のダウンロード</label>
                              </div>}
                            </div>
                            </React.Fragment>
                            ))
                        }
                      </div>
                    </React.Fragment>
                  )
                })
              }


              {/* {
                // MARK: https://github.com/gatjp/kawai1-front/issues/498#issuecomment-1088177412
                // 必要なくなった
                page.topic.title.endsWith('仮説検定の考え方') &&
                <div style={{
                  marginTop: 16,
                  color: '#666',
                  fontSize: 14,
                  textAlign: 'right',
                }}>
                  <p>
                    「仮説検定の考え方」を終えたら、<br />
                    数学の「科目一覧」から「数学Ｉ　図形と計量」単元を選び学習を進めてください。
                  </p>
                </div>
              } */}
              {/* {
                // MARK: https://github.com/gatjp/kawai1-front/issues/498#issuecomment-1088177412
                // 必要なさそう
                page.topic.title.endsWith('期待値、頻度確率') &&
                <div style={{
                  marginTop: 16,
                  color: '#666',
                  fontSize: 14,
                  textAlign: 'right',
                }}>
                  <p>
                    「期待値、頻度確率」を終えたら、<br />
                    以下から「<Link to={'/study/units/7'}>数学Ａ　整数の性質</Link>」単元を選び学習を進めてください。
                  </p>
                </div>
              } */}

              { selectedPdfDownload.length > 0 &&
              <div className="topic-detail-box__pdf-download-button-section">
                <button
                  className="topic-detail-box__pdf-download-button"
                  disabled={selectedPdfDownload.length > 0 ? false : true}
                  onClick={()=> {
                    downloadSelectedPdf()
                  }}
                  >
                  選択した問題をダウンロード
                  <div className="topic-detail-box__download-icon-white" />
                </button>
              </div>
              }
            </div>

            <div className="back-home">
              <Link to='/' className="back-home__link">マイページへ戻る</Link>
            </div>
          </div>
        }

        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          {
            pageWithCount &&
            <React.Fragment>
              <li className="breadcrumbs__item">
                <Link to={`/study/${pageWithCount.curriculum.id}`} className="breadcrumbs__link">
                  { pageWithCount.curriculum.name }
                </Link>
              </li>
              <li className="breadcrumbs__item">
                <Link to={`/study/courses/${pageWithCount.course.id}`} className="breadcrumbs__link">
                  { pageWithCount.course.name }
                </Link>
              </li>
              <li className="breadcrumbs__item">
                <Link to={`/study/units/${pageWithCount.unit.id}`} className="breadcrumbs__link">
                  { pageWithCount.unit.name }
                </Link>
              </li>
              <li className="breadcrumbs__item">
                { pageWithCount.topic.title }
              </li>
            </React.Fragment>
          }

        </ol>
      </SiteMain>
    </React.Fragment>
  );
}

export default StudyTopic