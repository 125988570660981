import React from 'react';
import { Field } from 'redux-form'
import { mappedProps } from 'containers/trainer/SettingProfileEdit';
import PrivateHeader from 'containers/parts/PrivateHeader';
import SiteSettingMainArea from '../common/SiteSettingMainArea';
import { renderInputField, FormErrorMessage } from 'components/parts/FormParts'

const SettingProfileEdit: React.FC<mappedProps> = (props: mappedProps) => {
  return (
    <React.Fragment>
      <PrivateHeader />
      <SiteSettingMainArea page="profile_edit" user={props.user}>
        {
          props.submitSucceeded && 
          <div className="setting-box">
            <div className="setting-box__title setting-box__title--profile">
              プロフィール確認・変更
            </div>
            <div className="setting-message">
              プロフィールを更新しました。
            </div>
            <div className="setting-box__button">
              <button
                onClick={(e) => {
                  e.preventDefault()
                  props.history.push('/')
                }}
                className="button button--gray button--back button--xlarge">マイページに戻る</button>
            </div>
          </div>
        }

        {
          !props.submitSucceeded && 

          <div className="setting-box">
            <form onSubmit={props.handleSubmit}>
              <div className="setting-box__title setting-box__title--profile">
                プロフィール確認・変更
              </div>
              <div className="setting-box__subtitle">
                会員情報
              </div>
              <div className="setting-box__form">
                <div className="form">
                  <div className="form__item">
                    <div className="form__label">
                      ニックネーム
                    </div>
                    <div className="form__field">
                      <div className="form__field-text">
                        { props.user.nick_name }
                      </div>
                    </div>
                  </div>
                  <div className="form__item">
                    <div className="form__label">
                      ログインID
                    </div>
                    <div className="form__field">
                      <div className="form__field-text">
                        { props.user.login }
                      </div>
                    </div>
                  </div>
                  <div className="form__item">
                    <div className="form__label form__label--required">
                      メールアドレス
                    </div>
                    <div className="form__field">
                      <Field
                        component={renderInputField}
                        className="input input--required form__input form__input--email"
                        type="email"
                        name="email"
                      />

                      <FormErrorMessage fieldNames={['email']} message="メールアドレス（半角）をご入力ください。" />
                    </div>
                  </div>
                  <div className="form__item">
                    <div className="form__label form__label--required form__label--2lines">
                      メールアドレス<br />（確認）
                    </div>
                    <div className="form__field">
                      <Field
                        component={renderInputField}
                        className="input input--required form__input form__input--email"
                        type="email"
                        name="email_confirmation"
                      />

                      <FormErrorMessage fieldNames={['email_confirmation']} message="メールアドレス（半角）をご入力ください。" />
                    </div>
                  </div>

                  <div className="form__item">
                    <div className="form__label form__label--required form__label--2lines">
                      電話番号<br />
                    </div>
                    <div className="form__field">
                      <Field
                        component={renderInputField}
                        className="input input--required form__input form__input--tel"
                        type="text"
                        name="tel"
                      />

                      <FormErrorMessage fieldNames={['tel']} message="電話番号（半角数字）をご入力ください。" />

                      <div className="form__note">
                        （半角数字、- [ハイフン] は含めない）
                      </div>
                    </div>
                  </div>

                </div>

                <div className="form">
                  <div className="form__buttons">
                    <div className="form__button form__button--flex-item">
                      <button 
                        disabled={props.submitting || props.invalid}
                        className="button button--primary button--xlarge">変更する</button>
                    </div>
                    <div className="form__button form__button--flex-item">
                      <button
                        onClick={(e) => {
                          e.preventDefault()
                          props.history.push('/')
                        }}
                        className="button button--gray button--back button--xlarge">マイページに戻る</button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        }
      </SiteSettingMainArea>
    </React.Fragment>
  );
}

export default SettingProfileEdit;
