import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router'

import { AppState } from "store";
import targetComponent from 'components/student/Bookmark';
import studentActions from 'actions/studentActions'
import * as Models from 'models/api';
import { getRecommendedCurriculums }  from 'utils/store'
import { getAvailableCurriculums }  from 'utils/store'

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch })

const mapStateToProps = (appState: AppState, ownProps: RouteComponentProps) => {
  const user = appState.session.user! as Models.StudentUser
  const curriculums = getRecommendedCurriculums(user).filter(x => x.al_engine_connected)

  return {
    user,
    curriculums,
    student: appState.student,
    availableCurriculums: user && getAvailableCurriculums(user),
    ...ownProps,
  }
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  { dispatch } : ReturnType<typeof mapDispatchToProps>) => {
  return {
    ...stateProps,
    getBookmarksLearningCount: (params: { curriculumCodes: Models.StudentStudyLearningCountParams }) => {
      return new Promise<Models.StudentStudyLearningCountRecords>((resolve, reject)=> { 
         dispatch(studentActions.fetchBookmarksLearningCount(
           {
             params,
             promises: { resolve, reject }
           }
          )) 
       })  
    },

    getBookmarks:  () => {
      return new Promise<Models.BookmarksResponse>((resolve, reject)=> { 
         dispatch(studentActions.fetchBookmarks(
           {
             promises: { resolve, reject }
           }
          )) 
       })  
    },

    deleteBookmarks: (params: Models.BookmarksDeleteParams) => {
      return new Promise<any>((resolve, reject) => {
        dispatch(studentActions.deleteBookmarks({
          params,
          promises: { resolve, reject }
        }))
      })
    },

    startStudy: (params: { content_id: number, return_pathname: string }) => {
      return new Promise<string>((resolve, reject) => {
        dispatch(studentActions.startStudy({
          params,
          promises: { resolve, reject }
        }))
      })
    },
  }
}

type connectMappedProps = ReturnType<typeof mergeProps>
export type mappedProps = connectMappedProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(targetComponent))