import { reducerWithInitialState } from 'typescript-fsa-reducers';
import produce from 'immer'

import * as Models from 'models/api'
import actions from 'actions/studyResultActions';
import storeUtils from 'utils/store'
import { sortBy } from 'lodash'
import { curriculums } from 'utils/constants'

export interface StudyResultState {
  student: {
    student_id: number | null;
    record: Models.StudentUser | null;
  }
  studyCurriculums: {
    student_id: number | null;
    record: Models.OwnStudentStudyCurriculums | null;
  }
  studyCurriculum: {
    student_id: number | null;
    curriculum_id: number | null;
    record: Models.OwnStudentStudyCurriculum | null;
  }
  studyCourse: {
    student_id: number | null;
    course_id: number | null;
    record: Models.OwnStudentStudyCourse | null;
  }
  studyUnit: {
    student_id: number | null;
    unit_id: number | null;
    record: Models.OwnStudentStudyUnit | null;
  }
  studyUnitResult: {
    student_id: number | null;
    unit_id: number | null;
    record: Models.OwnStudentStudyUnitResult | null;
  }
  studyTopic: {
    student_id: number | null;
    topic_id: number | null;
    record: Models.OwnStudentStudyTopic | null;
  }
  studyLog: {
    student_id: number | null;
    curriculum_id: number | null;
    records: Models.StudyLog[] | null;
    page: number;
    has_next: boolean;
  }
  studyCourseCompletionTests: {
    student_id: number | null;
    course_id: number | null;
    record: Models.StudyCourseCompletionTestsRecord | null;
  }
  studyCourseCompletionTestResults: {
    student_id: number | null;
    unit_id: number | null;
    record: Models.StudyCourseCompletionTestResultsRecord | null;
    moreRecord: Models.StudyCourseCompletionTestResultsRecord | null;
  }
}

const initialState: StudyResultState = {
  student: {
    student_id: null,
    record: null,
  },
  studyCurriculums: {
    student_id: null,
    record: null,
  },
  studyCurriculum: {
    student_id: null,
    curriculum_id: null,
    record: null,
  },
  studyCourse: {
    student_id: null,
    course_id: null,
    record: null,
  },
  studyUnit: {
    student_id: null,
    unit_id: null,
    record: null,
  },
  studyUnitResult: {
    student_id: null,
    unit_id: null,
    record: null,
  },
  studyTopic: {
    student_id: null,
    topic_id: null,
    record: null,
  },
  studyLog: {
    student_id: null,
    curriculum_id: null,
    records: null,
    page: 0,
    has_next: true,
  },
  studyCourseCompletionTests: {
    student_id: null,
    course_id: null,
    record: null,
  },
  studyCourseCompletionTestResults: {
    student_id: null,
    unit_id: null,
    record: null,
    moreRecord: null,
  },
};

export const studyResultReducer = reducerWithInitialState(initialState)
  .case(actions.setStudent, (state, payload) => {
    return produce(state, draftState => {
      draftState.student.record = payload.record
      draftState.student.student_id = payload.student_id
    })
  })
  .case(actions.setStudyCurriculums, (state, payload) => {
    return produce(state, draftState => {
      draftState.studyCurriculums.student_id = payload.student_id

      if (payload.record) {
        // 表示順序
        // 英語: 2, 数学: 1, 物理: 3、化学: 4、古文: 5
        const curriculumIds = curriculums.map(x => x.id)
        draftState.studyCurriculums.record = {
          ...payload.record,
          curriculums: sortBy(payload.record.curriculums, x => curriculumIds.indexOf(x.id)),
        }
      } else {
        draftState.studyCurriculums.record = null
      }
    })
  })
  .case(actions.setStudyCurriculum, (state, payload) => {
    return produce(state, draftState => {
      draftState.studyCurriculum.curriculum_id = payload.curriculum_id
      draftState.studyCurriculum.student_id = payload.student_id
      draftState.studyCurriculum.record = payload.record
    })
  })
  .case(actions.setStudyCourse, (state, payload) => {
    return produce(state, draftState => {
      draftState.studyCourse.course_id = payload.course_id
      draftState.studyCourse.student_id = payload.student_id
      draftState.studyCourse.record = payload.record
    })
  })
  .case(actions.setStudyUnit, (state, payload) => {
    return produce(state, draftState => {
      draftState.studyUnit.unit_id = payload.unit_id
      draftState.studyUnit.student_id = payload.student_id
      if (payload.record) {
        // MARK: 未受講はレベル１で表示
        draftState.studyUnit.record = {
          ...payload.record,
          topics: payload.record.topics.map(x => ({
            ...x,
            check_level: x.check_level === '未受講' ? 'レベル1' : x.check_level
          }))
        }
      } else {
        draftState.studyUnit.record = payload.record
      }
    })
  })
  .case(actions.setStudyUnitResult, (state, payload) => {
    return produce(state, draftState => {
      draftState.studyUnitResult.student_id = payload.student_id
      draftState.studyUnitResult.unit_id = payload.unit_id
      if (payload.record === null) {
        draftState.studyUnitResult.record = payload.record
      } else {
        draftState.studyUnitResult.record = {
          ...payload.record,
          result: {
            ...payload.record.result,
            pre_rank: storeUtils.getStudyUnitResultRank(
              payload.record.result.pre_score, payload.record.result.pre_total
            ),
            post_rank: storeUtils.getStudyUnitResultRank(
              payload.record.result.post_score, payload.record.result.post_total
            ),
          }
        }
      }
    })
  })
  .case(actions.setStudyTopic, (state, payload) => {
    return produce(state, draftState => {
      draftState.studyTopic.topic_id = payload.topic_id
      draftState.studyTopic.student_id = payload.student_id
      if (payload.record) {
        draftState.studyTopic.record = {
          ...payload.record,
          contents: payload.record.contents.map(x => ({
            ...x,
            meta: storeUtils.getContentMetaObjectByCode(x.title)
            //meta: storeUtils.getContentMetaObjectByCode(x.launch_parameters.curriculum_code)
          }))
        }
      } else {
        draftState.studyTopic.record = null
      }
    })
  })
  .case(actions.setStudyLogRecords, (state, payload) => {
    return produce(state, draftState => {
      draftState.studyLog.student_id = payload.student_id
      draftState.studyLog.curriculum_id = payload.curriculum_id
      draftState.studyLog.page = payload.page

      const records = payload.records.records.map(x => ({
        ...x,
        content: {
          ...x.content,
          meta: storeUtils.getContentMetaObjectByCode(
            x.content.launch_parameters.curriculum_code) 
        }
      }))

      if (!draftState.studyLog.records || payload.page === 1) {
        draftState.studyLog.records = records
      } else {
        draftState.studyLog.records.push(...records)
      }
      draftState.studyLog.has_next = payload.records.total_page > payload.page
    })
  })
   .case(actions.setStudyCourseCompletionTests, (state, payload) => {
     return produce(state, draftState => {
       draftState.studyCourseCompletionTests.student_id = payload.student_id
       draftState.studyCourseCompletionTests.course_id = payload.course_id
       draftState.studyCourseCompletionTests.record = payload.record
     })
   })
   .case(actions.setStudyCourseCompletionTestResults, (state, payload) => {
     return produce(state, draftState => {
       draftState.studyCourseCompletionTestResults.student_id = payload.student_id
       draftState.studyCourseCompletionTestResults.unit_id = payload.unit_id
       draftState.studyCourseCompletionTestResults.record = payload.record
     })
   })
   .case(actions.setStudyCourseCompletionTestResultsMore, (state, payload) => {
     return produce(state, draftState => {
       if (!payload.record) {
         draftState.studyCourseCompletionTestResults.moreRecord = null
       } else {
         draftState.studyCourseCompletionTestResults.moreRecord = payload.record
         const records = payload.record.results
         if (records) {
           draftState.studyCourseCompletionTestResults.moreRecord.results = records.slice(2)
         } else {
           draftState.studyCourseCompletionTestResults.moreRecord.results = []
         }
       }
     })
   })