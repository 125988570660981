export const pointRate = 500
export const amazonGiftExchangeRate = 300
export const planDiscountExchangeRate = 500

export type CurridulumType = {
  code: string;
  name: string;
  id: number
  al_engine_connected: boolean;
  has_completion_test: boolean; // MARK: 一つ以上の科目修了テストをもつ教科か
}

export const curriculums: CurridulumType[] = [
  {
    code: 'junior_english',
    name: '中学英語',
    id: 13,
    al_engine_connected: false,
    has_completion_test: false,
  },
  {
    code: 'junior_math',
    name: '中学数学',
    id: 14,
    al_engine_connected: false,
    has_completion_test: false,
  },
  {
    code: 'junior_science',
    name: '中学理科',
    id: 15,
    al_engine_connected: false,
    has_completion_test: false,
  },
  {
    code: 'english',
    name: '英語',
    id: 2,
    al_engine_connected: true,
    has_completion_test: true,
  },
  {
    code: 'math',
    name: '数学',
    id: 1,
    al_engine_connected: true,
    has_completion_test: true,
  },
  {
    code: 'physics',
    name: '物理',
    id: 3,
    al_engine_connected: true,
    has_completion_test: true,
  },
  {
    code: 'chemistry',
    name: '化学',
    id: 4,
    al_engine_connected: true,
    has_completion_test: true,
  },
  {
    code: 'modern_japanese',
    name: '現代文',
    id: 16,
    al_engine_connected: false,
    has_completion_test: false,
  },
  {
    code: 'classical_japanese',
    name: '古文',
    id: 5,
    al_engine_connected: true,
    has_completion_test: true,
  },
  {
    code: 'world_history',
    name: '世界史',
    id: 6,
    al_engine_connected: false,
    has_completion_test: true,
  },
  {
    code: 'japanese_history',
    name: '日本史',
    id: 7,
    al_engine_connected: false,
    has_completion_test: true,
  },
  {
    code: 'english_basic',
    name: '英語ベーシック',
    id: 9,
    al_engine_connected: false,
    has_completion_test: false,
  },
  {
    code: 'math_basic',
    name: '数学ベーシック',
    id: 8,
    al_engine_connected: false,
    has_completion_test: false,
  },
  {
    code: 'biology',
    name: '生物',
    id: 10,
    al_engine_connected: false,
    has_completion_test: true,
  },
  {
    code: 'english_words',
    name: '英単語',
    id: 11,
    al_engine_connected: false,
    has_completion_test: false,
  },
  {
    code: 'new_math',
    name: '新課程数学',
    id: 12,
    al_engine_connected: false,
    has_completion_test: false,
  },
  /* issue-673 */
  {
    code: 'new_chemistry_2024',
    name: '新課程化学',
    id: 17,
    al_engine_connected: false,
    has_completion_test: false,
  },
  {
    code: 'new_biology_2024',
    name: '新課程生物',
    id: 18,
    al_engine_connected: false,
    has_completion_test: false,
  }
];

export const prefectures = [
  '北海道',
  '青森県',
  '岩手県',
  '宮城県',
  '秋田県',
  '山形県',
  '福島県',
  '茨城県',
  '栃木県',
  '群馬県',
  '埼玉県',
  '千葉県',
  '東京都',
  '神奈川県',
  '新潟県',
  '富山県',
  '石川県',
  '福井県',
  '山梨県',
  '長野県',
  '岐阜県',
  '静岡県',
  '愛知県',
  '三重県',
  '滋賀県',
  '京都府',
  '大阪府',
  '兵庫県',
  '奈良県',
  '和歌山県',
  '鳥取県',
  '島根県',
  '岡山県',
  '広島県',
  '山口県',
  '徳島県',
  '香川県',
  '愛媛県',
  '高知県',
  '福岡県',
  '佐賀県',
  '長崎県',
  '熊本県',
  '大分県',
  '宮崎県',
  '鹿児島県',
  '沖縄県'
];

// 学部系統（文・理・総合・その他）
export const facultyKinds = [
  { id: 1, name: '文系' },
  { id: 2, name: '理系' },
  { id: 3, name: '総合系・学際系・文理融合系学部' },
  { id: 4, name: 'その他（看護・医療・家政・スポーツ・芸術系）' },
];

// 在籍／志望 学部系統
export const faculties = [
  { name: '法学・政治学系統', kind_id: 1 },
  { name: '商学・経済学・経営学系統', kind_id: 1 },
  { name: '社会学・社会福祉学系統', kind_id: 1 },
  { name: '文学・外国語学系統', kind_id: 1 },
  { name: '国際関係学系統', kind_id: 1 },
  { name: '教育学・心理学系統', kind_id: 1 },

  { name: '理学系統', kind_id: 2 },
  { name: '工学系統', kind_id: 2 },
  { name: '農学・獣医畜産学・水産学系統', kind_id: 2 },
  { name: '医学系統', kind_id: 2 },
  { name: '薬学系統', kind_id: 2 },

  { name: '総合系・学際系・文理融合系学科', kind_id: 3 },

  { name: '看護学・医療学系統', kind_id: 4 },
  { name: '体育・健康・スポーツ学系統', kind_id: 4 },
  { name: 'その他・家政・生活・芸術系統', kind_id: 4 },
];

// トレーナーの在籍／合格大学
export const universities = [
  { name: '北海道大学' },
  { name: '東北大学' },
  // { name: '筑波大学' },
  { name: '千葉大学' },
  { name: '東京大学' },
  { name: '一橋大学' },
  { name: '東京工業大学' },
  // { name: '横浜国立大学' },
  { name: '東京都立大学（旧 首都大学東京）' },
  { name: '名古屋大学' },
  { name: '京都大学' },
  { name: '大阪大学' },
  { name: '神戸大学' },
  { name: '広島大学' },
  { name: '九州大学' },
  { name: '早稲田大学' },
  { name: '慶應義塾大学' },
  { name: '上智大学' },
  { name: '東京理科大学' },
  { name: '明治大学' },
  { name: '青山学院大学' },
  { name: '立教大学' },
  { name: '中央大学' },
  { name: '法政大学' },
  { name: '同志社大学' },
  { name: '立命館大学' },
  { name: '関西学院大学' },
  { name: '関西大学' },
  { name: '南山大学' },
  { name: '日本大学' },
  // { name: '近畿大学' },
];

export const clubKinds = [
  { name: '運動系部活' },
  { name: '文化系部活' },
];

export type Plan = {
  id: number;
  recommend: boolean;
  price: string;
  price_with_tax: string;
  lump_sum_price: string;
  lump_sum_price_with_tax: string;
  name: string;
  price_message?: string;
  selected_price: string;
  selected_price_with_tax: string;
  selected_desc: string;
}

export const plans: Plan[] = [
  {
    id: 3,
    recommend: true,
    price: '3,581',
    price_with_tax: '3,939',
    lump_sum_price: '42,980',
    lump_sum_price_with_tax: '47,278',
    name: '１年(12ヶ月)プラン',

    selected_price: '43,980',
    selected_price_with_tax: '48,378',
    selected_desc: '１年(12ヶ月)プラン 42,980円（税込 47,278円）【12ヶ月分】',
  },
  {
    id: 2,
    recommend: false,
    price: '3,780',
    price_with_tax: '4,158',
    lump_sum_price: '22,680',
    lump_sum_price_with_tax: '24,948',
    name: '6ヶ月プラン',

    selected_price: '23,680',
    selected_price_with_tax: '26,048',
    selected_desc: '6ヶ月プラン 22,680円（税込 24,948円）【6ヶ月分】',
  },
  {
    id: 1,
    recommend: false,
    price: '3,980',
    price_with_tax: '4,378',
    lump_sum_price: '3,980',
    lump_sum_price_with_tax: '4,378',
    name: '1ヶ月プラン',

    selected_price: '4,980',
    selected_price_with_tax: '5,478',
    selected_desc: '1ヶ月プラン 3,980円（税込 4,378円）【1ヶ月分】',
  },
];

export const trainerRequests = [
  'トレーナーの在籍大学・合格大学',
  '学部',
  '部活',
]

export const highSchoolKinds = [
  "公立高校",
  "私立高校",
  "私立・公立中高一貫校",
  "その他（通信制など・自由入力）",
];

// MARK: 学習コンテンツ一覧画面表示用
export const studyContentTypes = [
  'レベルチェックテスト',
  '基本事項 解説',
  '練習問題',
  '練習問題 解説',

  '語義選択（英→和）',
  '語義選択（和→英）',
  '英文空所補充選択肢',
  '英文空所補充文字入力',
  'カウントダウン',
  'タイムトライアル',
  '単語帳',
]

// MARK: おすすめ学習画面表示用
export const normalContentTypes = [
  '基本事項 解説',
  'レベルチェックテスト',
  '練習問題',
  '練習問題 解説',
]

export const preOrPostContentTypes = [
  '受講前テスト',
  '受講後テスト',
]

export const hideStudyplusCorporationNames: string[] = [
  'ショウイン',
]