import React, { useState } from 'react';
import classNames from 'classnames';

import * as Models from 'models/api'

type props = {
  user?: Models.User;
  student: Models.StudentUser;
}

const CommonStudentBar: React.FC<props> = (props: props) => {
  const {
    user,
    student,
  } = props
  const [showDetail, setShowDetail] = useState(false)
  return (
    <div className="student-bar student-bar--detail">
      <div
        onClick={() => { setShowDetail(!showDetail) }}
        className="student-bar__head">
        { user && user.type === 'parent' ? student.full_name : student.nick_name }
        さんの学習情報
        <span className="student-bar__id">{ student.school_grade }（{ student.login }）</span>
      </div>
      <div className={classNames("student-bar__detail", { 'is-active' : showDetail })}>
        <dl className="student-bar__profile">
          <dt className="student-bar__profile-title">
            トレーナーの系統
          </dt>
          <dt className="student-bar__profile-text">
            { student.trainer_department_name }
          </dt>

          <dt className="student-bar__profile-title">
            トレーナーの大学
          </dt>
          <dt className="student-bar__profile-text">
            { student.trainer_university }
          </dt>

          <dt className="student-bar__profile-title">
            高校種別
          </dt>
          <dt className="student-bar__profile-text">
            { student.high_school_kind }
          </dt>

          <dt className="student-bar__profile-title">
            部活
          </dt>
          <dt className="student-bar__profile-text">
            { student.trainer_club_name }
          </dt>
        </dl>
      </div>
    </div>
  );
}

export default CommonStudentBar
