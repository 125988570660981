import React, { useEffect } from 'react';
import { Link, useParams, Redirect } from 'react-router-dom';

import PrivateHeader from 'containers/parts/PrivateHeader';
import { mappedProps } from 'containers/parent/StudentStudyResultCurriculum';
import SiteMain from 'containers/parts/SiteMain';

import StudentStudentBar from '../parts/CommonStudentBar'

const nl2br = require('react-nl2br');

const ParentStudentStudyResultCurriculum: React.FC<mappedProps> = (props: mappedProps) => {
  // MARK: 講師、トレーナーとインターフェース統一のためダミーIDを指定
  const id = 1;
  const { curriculum_id } = useParams();

  const curriculum = curriculum_id && props.getCurriculum(curriculum_id)

  useEffect(() => {
    if (id && curriculum) {
      props.fetchData(Number(id), curriculum.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, curriculum_id]);

  if (!curriculum) {
    return <Redirect to='/' />;
  }

  const student = props.getStudent(Number(id))
  const page = student && props.getRecord(Number(id), curriculum.id)

  return (
    <React.Fragment>
      <PrivateHeader />
      <SiteMain>
        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          <li className="breadcrumbs__item">
            { curriculum.name}
          </li>
        </ol>

        {
          student && page &&
          <div className="site-main__main-contents">
            <StudentStudentBar student={student} />

            <div className="page-title">
              { curriculum.name }
            </div>

            <div className="page-subtitle">
              科目
            </div>

            <ul className="subject-list">
              { page.courses.map(course => (
                <li key={course.id} className="subject-list__item">
                  {
                    course.title.length <= 8 ?
                    <Link
                      to={`/student/study_result/courses/${course.id}`}
                      className="subject-list__link">
                      { course.title }
                    </Link>:
                    <Link
                      to={`/student/study_result/courses/${course.id}`}
                      className="subject-list__link subject-list__link--multi-line"
                    >
                      { nl2br(course.title.replace('　', '\n') ) }
                    </Link>
                  }
                </li>
              ))}
            </ul>

            <div className="back-home">
              <Link to='/' className="back-home__link">マイページへ戻る</Link>
            </div>
          </div>
        }

        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          <li className="breadcrumbs__item">
            { curriculum.name}
          </li>
        </ol>
      </SiteMain>
    </React.Fragment>
  );
}

export default ParentStudentStudyResultCurriculum