import React, { useState, useEffect } from 'react';
import { Field } from 'redux-form';
import { Link, useParams, Redirect, } from 'react-router-dom';

import { mappedProps } from 'containers/instructor/QuestionForm2';
import PrivateHeader from 'containers/parts/PrivateHeader';
import SiteMain from 'containers/parts/SiteMain';
import QuestionDetail from '../parts/QuestionDetail';

import { renderTextareaField } from 'components/parts/FormParts'

const nl2br = require('react-nl2br');

const QuestionForm: React.FC<mappedProps> = (props: mappedProps) => {
  const { id } = useParams();

  const [confirm, setConfirm] = useState(false)

  useEffect(() => {
    props.fetchQuestion(Number(id))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const loading = props.instructor.questionDetail.loading
  const question = props.getQuestion(Number(id))

  // MARK: 回答済みの場合は詳細に戻す
  const alreadyAnswered = question && question.statusCode === 'answered'

  if (question && alreadyAnswered) {
    return <Redirect to={`/questions/${question.id}`} />;
  }

  if (props.submitSucceeded) {
    return (
      <React.Fragment>
        <PrivateHeader />
        <SiteMain>
          <ol className="breadcrumbs">
            <li className="breadcrumbs__item">
              <Link to='/' className="breadcrumbs__link">マイページ</Link>
            </li>
            <li className="breadcrumbs__item">
              担当教科の質問
            </li>
          </ol>

          <div className="site-main__main-contents">
            <div className="page-title page-title--teacher">
              担当教科の質問
              <Link to='/' className="page-title__link page-title__link--back">マイページへ戻る</Link>
            </div>

            <div className="question-form">
              <div className="question-form__complete question-form__complete--teacher">
                事務局へ送信しました。
              </div>
            </div>

            <div className="back-home">
              <Link to='/' className="back-home__link">マイページへ戻る</Link>
            </div>
          </div>

          <ol className="breadcrumbs">
            <li className="breadcrumbs__item">
              <Link to='/' className="breadcrumbs__link">マイページ</Link>
            </li>
            <li className="breadcrumbs__item">
              担当教科の質問
            </li>
          </ol>
        </SiteMain>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <PrivateHeader />
      <SiteMain>
        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          <li className="breadcrumbs__item">
            担当教科の質問
          </li>
        </ol>

        <div className="site-main__main-contents">
          <div className="page-title page-title--teacher">
            担当教科の質問
            <Link to='/' className="page-title__link page-title__link--back">マイページへ戻る</Link>
          </div>

          {
            !loading && question &&
            <React.Fragment>
              <div className="page-subtitle">
                質問内容
              </div>

              <QuestionDetail question={question} type={props.user.type} />

              {
                confirm &&
                <div className="answer-form">
                  <div className="answer-form__title answer-form__title--primary">
                    事務局宛コメント（受講者には表示されません。）
                  </div>
                  <div className="answer-form__body">
                    <div className="answer-form__textarea answer-form__textarea--confirm">
                      { nl2br(props.questionBody) }
                    </div>
                    <div className="answer-form__buttons">
                      <div className="answer-form__button">
                        <form onSubmit={props.handleSubmit}>
                          <button
                            disabled={props.pristine || props.submitting || props.invalid}
                            className="button button--large button--primary">事務局へ送信</button>
                        </form>
                      </div>
                      <div className="answer-form__button">
                        <a
                          onClick={(e) => {
                            e.preventDefault()
                            setConfirm(false)
                          }}
                          href="#!"
                          className="button button--large button--back button--gray">内容を修正</a>
                      </div>
                    </div>
                  </div>
                </div>
              }

              {
                !confirm &&
                <div className="answer-form">
                  <div className="answer-form__title answer-form__title--primary">
                    事務局宛コメント（受講者には表示されません。）
                  </div>
                  <div className="answer-form__body">
                    <div className="answer-form__textarea">
                      <Field
                        component={renderTextareaField}
                        className="textarea textarea--required"
                        name="body"
                      />
                    </div>
                    <div className="answer-form__buttons">
                      <div className="answer-form__button">
                        <button
                          onClick={(e) => {
                            e.preventDefault()
                            setConfirm(true)
                          }}
                          disabled={props.pristine || props.submitting || props.invalid}
                          className="button button--large button--primary">入力内容の確認</button>
                      </div>
                      <div className="answer-form__button">
                        <Link
                          to={`/questions/${question.id}`}
                          className="button button--large button--back button--gray">前のページへ戻る</Link>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </React.Fragment>
          }

          <div className="back-home">
            <Link to='/' className="back-home__link">マイページへ戻る</Link>
          </div>
        </div>

        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          <li className="breadcrumbs__item">
            担当教科の質問
          </li>
        </ol>
      </SiteMain>
    </React.Fragment>
  );
}

export default QuestionForm