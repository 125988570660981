import React, { useEffect } from 'react';
import { Link, useParams, } from 'react-router-dom';

import PrivateHeader from 'containers/parts/PrivateHeader';
import { mappedProps } from 'containers/instructor/StudentStudyResultUnitResult';
import SiteMain from 'containers/parts/SiteMain';
import StudentBar from '../parts/CommonStudentBar'
import StudentNav from '../parts/InstructorStudentNav';

const TrainerStudentStudyResultUnitResult: React.FC<mappedProps> = (props: mappedProps) => {
  const { id, unit_id } = useParams();

  useEffect(() => {
    if (id && unit_id) {
      props.fetchData(Number(id), Number(unit_id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, unit_id]);

  const student = props.getStudent(Number(id))
  const page = student && props.getRecord(student.id, Number(unit_id))

  return (
    <React.Fragment>
      <PrivateHeader />
      <SiteMain>
        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          <li className="breadcrumbs__item">
            受講者情報
          </li>
        </ol>

        {
          student && page &&

          <div className="site-main__main-contents">
            <StudentBar student={student} />
            <StudentNav student={student} type="study_result" />

            <div className="study-breadcrumbs">
              <Link to={`/students/${student.id}/study_result`} className="study-breadcrumbs__item">
                教科
              </Link>
              <Link to={`/students/${student.id}/study_result/${page.curriculum.id}`} className="study-breadcrumbs__item">
                科目
              </Link>
              <Link to={`/students/${student.id}/study_result/courses/${page.course.id}`} className="study-breadcrumbs__item">
                単元
              </Link>
            </div>

            <div className="page-title">
              { page.curriculum.name }
              <div className="page-title__badge">
                { page.course.name }
              </div>
              <br className="_sp" />
              { page.unit.name }
            </div>

            <div className="study-result-box">
              <div className="study-result-box__inner">
                <div className="study-result-box__head">
                  <div className="study-result-box__title">
                    結果比較
                  </div>
                  <div className="study-result-box__point-head">
                    正解数／問題数
                  </div>
                  <div className="study-result-box__date-head">
                    実施日時
                  </div>
                </div>
                <div className="study-result-box__result">
                  <div className="study-result-box__result-title">
                    受講後テスト
                  </div>
                  <div className="study-result-box__point">
                    <b>27</b><span className="study-result-box__slash">/</span>30
                    <div className="study-result-box__rank">
                      {/* NOTE： 単元理解度90%以上なら.study-result-box__rank-badge--s-rankをつけてSランク */}
                      <div className="study-result-box__rank-badge study-result-box__rank-badge--s-rank">
                        Sランク
                      </div>
                    </div>
                  </div>
                  <div className="study-result-box__date">
                    <span className="study-result-box__date-text">実施日時</span>2020年2月7日 10:23
                  </div>
                </div>
                <div className="study-result-box__result">
                  <div className="study-result-box__result-title">
                    受講前テスト
                  </div>
                  <div className="study-result-box__point">
                    <b>3</b><span className="study-result-box__slash">/</span>30
                    <div className="study-result-box__rank">
                      {/* NOTE： 単元理解度0-50%でCランク、50-70%でBランク、70-90%でAランク */}
                      <div className="study-result-box__rank-badge">
                        Cランク
                      </div>
                    </div>
                  </div>
                  <div className="study-result-box__date">
                    <span className="study-result-box__date-text">実施日時</span>2020年1月27日 18:31
                  </div>
                </div>
              </div>
              <div className="study-result-box__button">
                <Link
                  to={`/students/${student.id}/study_result/courses/${page.course.id}`}
                  className="button button--xlarge button--gray button--back">
                  単元一覧へ戻る
                </Link>
              </div>
            </div>

            <div className="back-home">
              <Link to='/' className="back-home__link">マイページへ戻る</Link>
            </div>
          </div>
        }

        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          <li className="breadcrumbs__item">
            受講者情報
          </li>
        </ol>
      </SiteMain>
    </React.Fragment>
  );
}

export default TrainerStudentStudyResultUnitResult