import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RouteProps } from 'react-router'
import { reduxForm, InjectedFormProps, FormErrors, formValueSelector, SubmissionError } from 'redux-form'

import { AppState } from "store";
import SettingProfileEdit from 'components/parent/SettingProfileEdit';
import validators from 'utils/validators'
import * as Models from 'models/api'
import { prefectures } from 'utils/constants'
import parentActions from 'actions/parentActions'

type FormData = {
  email?: string;
  postal_code?: string;
  prefecture?: string;
  municipality?: string;
  address?: string;
  [key: string]: any
};

export const formKey = 'setting_profile_edit'

export const FormValidate = (values: FormData) => {
  const errors: FormErrors<FormData> = {}
  const requiredFields: string[] = [
    'email',
    'postal_code',
    'prefecture',
    'municipality',
  ]
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required'
    }
  })

  if (
    values.email && ( !validators.email(values.email) || !validators.length(values.email, { max: 254 }) )
    ) {
    errors.email = 'Invalid'
  }

  if (
    values.postal_code && !validators.postalcode(values.postal_code)) {
    errors.postal_code = 'Invalid'
  }

  if (
    values.municipality && !validators.length(values.municipality, { max: 50 })
    ) {
    errors.municipality = 'Invalid'
  }

  if (
    values.address && !validators.length(values.address, { max: 254 })
    ) {
    errors.address = 'Invalid'
  }

  return errors
}

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch })

const mapStateToProps = (appState: AppState, routeProps: RouteProps) => {
  const user = appState.session.user! as Models.ParentUser
  const initialValues: FormData = user
  const selector = formValueSelector(formKey);

  const masterData = appState.ui.master.records

  let studentMathLevel = null;
  let studentPhysicsLevel = null;
  let studentChemistryLevel = null;
  let studentClassicalJapaneseLevel = null;
  let studentWorldHistoryLevel = null;
  let studentJapaneseHistoryLevel = null;
  let studentEnglishWordsLevel = null;
  let studentBiologyLevel = null;
  let studentNewMathLevel = null;
  let studentJuniorEnglishLevel = null;
  let studentJuniorMathLevel = null;
  let studentJuniorScienceLevel = null;
  let studentModernJapaneseLevel = null;
  let studentNewChemistry2024Level = null;
  let studentNewBiology2024Level = null;

  if (appState.parent.student && masterData) {
    studentMathLevel = masterData.math_levels.find(x => x.level === appState.parent.student!.recommend_math);
    studentPhysicsLevel = masterData.physics_levels.find(x => x.level === appState.parent.student!.recommend_physics);
    studentChemistryLevel = masterData.chemistry_levels.find(x => x.level === appState.parent.student!.recommend_chemistry);
    studentClassicalJapaneseLevel = masterData.classical_japanese_levels.find(x => x.level === appState.parent.student!.recommend_classical_japanese);
    studentWorldHistoryLevel = masterData.world_history_levels.find(x => x.level === appState.parent.student!.recommend_world_history);
    studentJapaneseHistoryLevel = masterData.japanese_history_levels.find(x => x.level === appState.parent.student!.recommend_japanese_history);
    studentEnglishWordsLevel = masterData.english_words_levels.find(x => x.level === appState.parent.student!.recommend_english_words);
    studentBiologyLevel = masterData.biology_levels.find(x => x.level === appState.parent.student!.recommend_biology);
    studentNewMathLevel = masterData.new_math_levels.find(x => x.level === appState.parent.student!.recommend_new_math);
    studentJuniorEnglishLevel = masterData.junior_english_levels.find(x => x.level === appState.parent.student!.recommend_junior_english);
    studentJuniorMathLevel = masterData.junior_math_levels.find(x => x.level === appState.parent.student!.recommend_junior_math);
    studentJuniorScienceLevel = masterData.junior_science_levels.find(x => x.level === appState.parent.student!.recommend_junior_science);
    studentModernJapaneseLevel = masterData.modern_japanese_levels.find(x => x.level === appState.parent.student!.recommend_modern_japanese);
    /* issue-673 */
    studentNewChemistry2024Level = masterData.new_chemistry_2024_levels.find(x => x.level === appState.parent.student!.recommend_new_chemistry_2024);
    studentNewBiology2024Level = masterData.new_biology_2024_levels.find(x => x.level === appState.parent.student!.recommend_new_biology_2024);
  }

  return {
    parent: appState.parent,
    api: appState.repository.authorizedApi!,
    user,
    initialValues,
    prefectures,
    masterData,
    studentMathLevel,
    studentPhysicsLevel,
    studentChemistryLevel,
    studentClassicalJapaneseLevel,
    studentWorldHistoryLevel,
    studentJapaneseHistoryLevel,
    studentEnglishWordsLevel,
    studentBiologyLevel,
    studentNewMathLevel,
    studentJuniorEnglishLevel,
    studentJuniorMathLevel,
    studentJuniorScienceLevel,
    studentModernJapaneseLevel,
    studentNewChemistry2024Level,
    studentNewBiology2024Level,
    postalCode: selector(appState, 'postal_code'),
  }
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  { dispatch }: ReturnType<typeof mapDispatchToProps>) => {
  return {
    ...stateProps,
    fetchData: () => {
      dispatch(parentActions.fetchStudent())
    },
    onSubmit: (values: FormData) => {
      return new Promise<void>((resolve, reject) => {
        const params: Models.UpdateParentProfileParams = {
          email: values.email!,
          postal_code: values.postal_code!,
          prefecture: values.prefecture!,
          municipality: values.municipality!,
          address: values.address!,
        }
        dispatch(parentActions.updateProfile({ params, promises: { resolve, reject } }))
      }).catch((e: Error) => {
        throw new SubmissionError({})
      })
    }
  }
}

type connectMappedProps = ReturnType<typeof mergeProps>
export type mappedProps = connectMappedProps & InjectedFormProps<FormData, connectMappedProps>

const form = reduxForm<FormData, connectMappedProps>({
  form: formKey,
  validate: FormValidate
})(SettingProfileEdit)

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(form)
