import React, { useEffect } from 'react';
import { Link, useParams, } from 'react-router-dom';
import classNames from 'classnames';
import Moment from 'react-moment';
import { mappedProps } from 'containers/trainer/StudentStudyResultUnitResult';

const TrainerStudentStudyResultUnitResult: React.FC<mappedProps> = (props: mappedProps) => {
  const { id, unit_id } = useParams();

  useEffect(() => {
    if (id && unit_id) {
      props.fetchData(Number(id), Number(unit_id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, unit_id]);

  const student = props.getStudent(Number(id))
  const page = student && props.getRecord(student.id, Number(unit_id))

  return (
    <React.Fragment>
      {
        student && page &&
        <>
          <div className="study-breadcrumbs">
            <Link to={`/students/${student.id}/study_result`} className="study-breadcrumbs__item">
              教科
            </Link>
            <Link to={`/students/${student.id}/study_result/${page.curriculum.id}`} className="study-breadcrumbs__item">
              科目
            </Link>
            <Link to={`/students/${student.id}/study_result/courses/${page.course.id}`} className="study-breadcrumbs__item">
              単元
            </Link>
          </div>

          <div className="page-title">
            { page.curriculum.name }&nbsp;
            <div className="page-title__badge">
              { page.course.name }
            </div>
            &nbsp;
            <br className="_sp" />{ page.unit.name }
          </div>

          <div className="study-result-box">
            <div className="study-result-box__inner">
              <div className="study-result-box__head">
                <div className="study-result-box__title">
                  結果比較
                </div>
                <div className="study-result-box__point-head">
                  正解数／問題数
                </div>
                <div className="study-result-box__date-head">
                  実施日時
                </div>
              </div>
              <div className="study-result-box__result">
                <div className="study-result-box__result-title study-result-box__result-title--teacher">
                  受講後テスト
                </div>
                <div className="study-result-box__point">
                  <b>{ page.result.post_score }</b><span className="study-result-box__slash">/</span>{ page.result.post_total }
                  <div className="study-result-box__rank">
                    {/* NOTE： 単元理解度90%以上なら.study-result-box__rank-badge--s-rankをつけてSランク */}
                    <div className={classNames("study-result-box__rank-badge", {
                      "study-result-box__rank-badge--s-rank": page.result.post_rank === 'S'
                    })}>
                      { page.result.post_rank }ランク
                    </div>
                  </div>
                </div>
                <div className="study-result-box__date">
                  <span className="study-result-box__date-text">実施日時</span>
                  <Moment format="YYYY年M月D日 HH:mm">{ page.result.post_acted_at }</Moment>
                </div>
              </div>
              <div className="study-result-box__result">
                <div className="study-result-box__result-title study-result-box__result-title--teacher">
                  受講前テスト
                </div>
                <div className="study-result-box__point">
                  <b>{ page.result.pre_score }</b><span className="study-result-box__slash">/</span>{ page.result.pre_total }
                  <div className="study-result-box__rank">
                    {/* NOTE： 単元理解度0-50%でCランク、50-70%でBランク、70-90%でAランク */}
                    <div className={classNames("study-result-box__rank-badge", {
                      "study-result-box__rank-badge--s-rank": page.result.pre_rank === 'S'
                    })}>
                      { page.result.pre_rank }ランク
                    </div>
                  </div>
                </div>
                <div className="study-result-box__date">
                  <span className="study-result-box__date-text">実施日時</span>
                  <Moment format="YYYY年M月D日 HH:mm">{ page.result.pre_acted_at }</Moment>
                </div>
              </div>
            </div>
            <div className="study-result-box__button">
              <Link
                to={`/students/${student.id}/study_result/courses/${page.course.id}`}
                className="button button--xlarge button--gray button--back">
                単元一覧へ戻る
              </Link>
            </div>
          </div>
        </>
      }
    </React.Fragment>
  );
}

export default TrainerStudentStudyResultUnitResult