import React from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import { mappedProps } from 'containers/student/SettingPointExchangeNav';

const Nav: React.FC<mappedProps> = (props: mappedProps) => {
  const openRecommendNav = props.sidenav.openRecommendNav
  const setOpenRecommendNav = props.setOpenRecommendNav
  const openStudyNav = props.sidenav.openStudyNav
  const setOpenStudyNav = props.setOpenStudyNav
  const openHistoryNav = props.sidenav.openHistoryNav
  const setOpenHistoryNav = props.setOpenHistoryNav

  return (
    <div className="site-main__sub site-main__sub--point">
      <dl className="setting-nav _pc">
        <dt className="setting-nav__title">
          ポイント交換
        </dt>
        <dd className="setting-nav__item">
          <NavLink to='/setting/point_exchange' className="setting-nav__link setting-nav__link--point" activeClassName="is-active">ポイント交換について</NavLink>
        </dd>
        <dd className="setting-nav__item">
          <NavLink to='/setting/point_exchange_logs' className="setting-nav__link setting-nav__link--point-history" activeClassName="is-active">ポイント交換履歴</NavLink>
        </dd>
      </dl>
      <div className="_sp">
        <ul className="sidebar sidebar--point">
          <li className="sidebar__item sidebar__item--mypage">
            <NavLink to='/' className="sidebar__link" activeClassName="is-active">マイページ</NavLink>
          </li>
          <li className="sidebar__item">
            <div
              onClick={() => setOpenRecommendNav(!openRecommendNav)}
              className={classNames("sidebar__text", { 'is-active' : openRecommendNav })}>
              AIおすすめ学習
            </div>
            <ul className={classNames('sidebar-subnav', { 'is-active' : openRecommendNav })}>
              {
                props.curriculums && props.curriculums.filter(x => x.al_engine_connected).map(x => (
                  <li key={x.code} className="sidebar-subnav__item">
                    <NavLink to={`/recommends/${x.code}`} className="sidebar-subnav__link" activeClassName="is-active">
                      <span className="sidebar-subnav__text">{x.name}</span>
                    </NavLink>
                  </li>
                ))
              }
            </ul>
          </li>
          <li className="sidebar__item">
            <div
              onClick={() => setOpenStudyNav(!openStudyNav)}
              className={classNames("sidebar__text", { 'is-active' : openStudyNav })}>
              教科・単元選択学習
            </div>
            <ul className={classNames('sidebar-subnav', { 'is-active' : openStudyNav })}>
              {
                props.curriculums && props.curriculums.map(x => (
                  <li key={x.code} className="sidebar-subnav__item">
                    <NavLink to={`/study/${x.code}`} className="sidebar-subnav__link" activeClassName="is-active">
                      <span className="sidebar-subnav__text">{x.name}</span>
                    </NavLink>
                  </li>
                ))
              }
            </ul>
          </li>
          <li className="sidebar__item">
            <div
              onClick={() => setOpenHistoryNav(!openHistoryNav)}
              className={classNames("sidebar__text", { 'is-active' : openHistoryNav })}>
              学習履歴
            </div>
            <ul className={classNames('sidebar-subnav', { 'is-active' : openHistoryNav })}>
              {
                props.curriculums && props.curriculums.map(x => (
                  <li key={x.code} className="sidebar-subnav__item">
                    <NavLink to={`/study_logs/${x.code}`} className="sidebar-subnav__link" activeClassName="is-active">
                      <span className="sidebar-subnav__text">{x.name}</span>
                    </NavLink>
                  </li>
                ))
              }
            </ul>
          </li>
          {
            props.studentUser && props.studentUser.trainer_contracted && props.isAvailableStudentMessage &&
            <li className="sidebar__item">
              <NavLink to='/messages' className="sidebar__link" activeClassName="is-active">
                トレーナー相談
              </NavLink>
            </li>
          }
          <li className="sidebar__item">
            <NavLink to='/study_question_logs' className="sidebar__link" activeClassName="is-active">
              先生からの回答
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Nav;

