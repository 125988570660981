export type EnqueteItem = string
export type Enquete = {
  code: string;
  title: string,
  required: boolean,
  hasEtc: boolean,
  type: 'checkbox' | 'radio' | 'textarea',
  items?: EnqueteItem[]
}

export const getEnqueteItemIndex = (code: string, value: string): null | number => {
  const enquete = withdrawalEnquetes.find(x => x.code === code)
  if (!enquete) {
    return null
  }
  return enquete.items!.findIndex(x => x === value)
}

export const withdrawalEnquetes: Enquete[] = [
  {
    code: 'reason_for_unsubscribe',
    title: '今回、河合塾Oneをご退会される理由を教えてください。',
    required: true,
    hasEtc: true,
    type: 'checkbox',
    items: [
      '学習の全範囲をコンプリートした',
      '河合塾Oneの機能やサービスが期待したものではなかった',
      '教材のレベルが高いと感じた',
      '教材のレベルが低いと感じた',
      '「AIのおすすめ学習」機能が期待したものではなかった',
      '自分に合った学習をできている感じがしなかった',
      '動画教材の質が期待したものではなかった',
      '問題教材の質が期待したものではなかった',
      '河合塾Oneの先生への学習質問サービスに不満がある',
      'トレーナーによるサポートサービスに不満がある',
      '河合塾One自体の使い勝手が良くなかった',
      '他にもっといいサービスを見つけた',
      '価格が高い',
      '通信容量が気になってしまい、学習が思うようにできなかった',
    ]
  },
  {
    code: 'purpose_of_use',
    title: '河合塾Oneのご利用の目的を教えてください。',
    required: true,
    hasEtc: true,
    type: 'checkbox',
    items: [
      '高校の定期テストや学校での学習の対策',
      '模試対策',
      '大学受験対策',
      '大学入学共通テスト対策',
      '国公立大学２次試験対策',
      '私立大学試験対策',
      '高校の学習の先取り',
      '大学に入学する前の学び直し',
      '大学生・社会人の学び直し',
    ]
  },
  {
    code: 'used_function',
    title: '河合塾Oneでよく利用した機能を教えてください。',
    required: true,
    hasEtc: true,
    type: 'checkbox',
    items: [
      'AIおすすめ学習機能（AIのおすすめに従って学習）',
      '教科・単元選択学習機能（自分で教材を選んで学習）',
      '河合塾Oneの先生への学習質問',
      'トレーナーへの相談',
      '特によく利用したものはない',
    ]
  },
  {
    code: 'used_subject',
    title: '河合塾Oneでよく利用した教科・科目を教えてください。',
    required: true,
    hasEtc: true,
    type: 'checkbox',
    items: [
      '英文法',
      '英文解釈',
      '数学IA',
      '数学IIB',
      '数学III',
      '化学',
      '物理',
      '古文',
    ]
  },
  {
    code: 'teaching_materials_satisfaction',
    title: '河合塾Oneの教材（問題・解説）の内容や充実具合についての満足度を教えてください。',
    required: true,
    hasEtc: false,
    type: 'radio',
    items: [
      '５（非常に良い）',
      '４（良い）',
      '３（普通）',
      '２（悪い）',
      '１（非常に悪い）',
      '利用していない',
    ]
  },
  {
    code: 'teaching_materials_satisfaction_text',
    title: '上記の満足度の理由をお答えください。また、こうなれば良いなどご意見、ご要望、お気づきの点をご記入ください。',
    required: false,
    hasEtc: false,
    type: 'textarea',
  },
  {
    code: 'recommend_satisfaction',
    title: 'AIおすすめ学習機能について満足度を教えてください。',
    required: true,
    hasEtc: false,
    type: 'radio',
    items: [
      '５（非常に良い）',
      '４（良い）',
      '３（普通）',
      '２（悪い）',
      '１（非常に悪い）',
      '利用していない',
    ]
  },
  {
    code: 'recommend_satisfaction_text',
    title: '上記の満足度の理由をお答えください。また、こうなれば良いなどご意見、ご要望、お気づきの点をご記入ください。',
    required: false,
    hasEtc: false,
    type: 'textarea',
  },
  {
    code: 'instructor_satisfaction',
    title: '河合塾Oneの先生による学習質問サービスについて満足度を教えてください。',
    required: true,
    hasEtc: false,
    type: 'radio',
    items: [
      '５（非常に良い）',
      '４（良い）',
      '３（普通）',
      '２（悪い）',
      '１（非常に悪い）',
      '利用していない',
    ]
  },
  {
    code: 'instructor_satisfaction_text',
    title: '上記の満足度の理由をお答えください。また、こうなれば良いなどご意見、ご要望、お気づきの点をご記入ください。',
    required: false,
    hasEtc: false,
    type: 'textarea',
  },
  {
    code: 'trainer_satisfaction',
    title: 'トレーナーによるサポートサービスについて満足度を教えてください。',
    required: true,
    hasEtc: false,
    type: 'radio',
    items: [
      '５（非常に良い）',
      '４（良い）',
      '３（普通）',
      '２（悪い）',
      '１（非常に悪い）',
      '利用していない',
    ]
  },
  {
    code: 'trainer_satisfaction_text',
    title: '上記の満足度の理由をお答えください。また、こうなれば良いなどご意見、ご要望、お気づきの点をご記入ください。',
    required: false,
    hasEtc: false,
    type: 'textarea'
  },
  {
    code: 'ux_satisfaction',
    title: '河合塾Oneの操作性・使い勝手・マルチデバイス対応・デザインについて満足度を教えてください。',
    required: true,
    hasEtc: false,
    type: 'radio',
    items: [
      '５（非常に良い）',
      '４（良い）',
      '３（普通）',
      '２（悪い）',
      '１（非常に悪い）',
      '利用していない',
    ]
  },
  {
    code: 'ux_satisfaction_text',
    title: '上記の満足度の理由をお答えください。また、こうなれば良いなどご意見、ご要望、お気づきの点をご記入ください。',
    required: false,
    hasEtc: false,
    type: 'textarea'
  },
  {
    code: 'price_satisfaction',
    title: '河合塾Oneの価格についてどのように思いますか。',
    required: true,
    hasEtc: true,
    type: 'radio',
    items: [
      '高すぎる',
      'やや高く感じる',
      'ちょうどよい',
      'やや安く感じる',
      '安すぎる',
    ]
  },
  {
    code: 'price_satisfaction_text',
    title: '価格について、ご意見、ご要望、お気づきの点をご記入ください。',
    required: false,
    hasEtc: false,
    type: 'textarea',
  },
  {
    code: 'comprehensive_satisfaction',
    title: '河合塾One全体について総合満足度を教えてください。',
    required: true,
    hasEtc: false,
    type: 'radio',
    items: [
      '５（非常に良い）',
      '４（良い）',
      '３（普通）',
      '２（悪い）',
      '１（非常に悪い）',
      '利用していない',
    ]
  },
  {
    code: 'comprehensive_satisfaction_text',
    title: '現在のサービスについてお気づきの点や、河合塾Oneに求める新たなサービスや機能などのご意見、ご要望をご記入ください。',
    required: false,
    hasEtc: false,
    type: 'textarea',
  },
  {
    code: 'introduction',
    title: '河合塾Oneをご友人、ご家族、ご兄弟に紹介しようと思いますか？',
    required: true,
    hasEtc: true,
    type: 'radio',
    items: [
      '積極的に紹介しようと思う',
      '機会があれば紹介しようと思う',
      'あまり紹介しようとは思わない',
      '紹介しようと全く思わない',
    ]
  },
  {
    code: 'introduction_text',
    title: '上記の理由をお答えください。',
    required: false,
    hasEtc: false,
    type: 'textarea',
  }
];
