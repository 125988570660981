import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector, InjectedFormProps, FormErrors } from 'redux-form'
import moment from "moment";

import SignupStep1 from 'components/signup/SignupStep1';
import { AppState } from "store";
import temporaryFormActions from 'actions/temporaryFormActions';
import {
  prefectures,
  faculties,
  universities,
  facultyKinds,
  trainerRequests,
  clubKinds,
  plans,
  } from 'utils/constants'
import validators from 'utils/validators'
import uiActions from 'actions/uiActions';
import { isUndefined } from 'lodash'

import{ isAvailable as isAvailableDiscount2024winter } from 'utils/campaign/discount2024winter'

export const formKey = 'signup_step1'

export type Step1FormData = {
  term1_check?: boolean;
  term2_check?: boolean;
  desired_faculty_kind?: string;
  desired_faculty?: string;
  desired_university?: string;
  club_kind?: string;
  club_name?: string;
  first_request?: string;
  second_request?: string;
  third_request?: string;
  student_first_name?: string;
  student_last_name?: string;
  student_first_name_read?: string;
  student_last_name_read?: string;
  first_name?: string;
  last_name?: string;
  first_name_read?: string;
  last_name_read?: string;
  relation?: string;
  student_email?: string;
  student_email_confirmation?: string;
  email?: string;
  email_confirmation?: string;
  birthday_year?: string;
  birthday_month?: string;
  birthday_day?: string;
  gender?: string;
  high_school_name?: string;
  school_grade?: string;
  nick_name?: string;
  postal_code?: string;
  prefecture?: string;
  municipality?: string;
  address?: string;
  trainer_contracted?: boolean;
  plan_id?: number;
  [key: string]: any
};

// TODO: validation test
export const FormValidate = (values: Step1FormData) => {
  const errors: FormErrors<Step1FormData> = {}

  const requiredFields: string[] = [
    'gender',
    'school_grade',
    'term1_check',
    'term2_check',
    'plan_id',
  ]

  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required'
    }
  })

  if (isUndefined(values.trainer_contracted)) {
    errors.trainer_contracted = 'Required'
  }

  if (
    !values.student_first_name ||
    !validators.zenkaku(values.student_first_name) || !validators.length(values.student_first_name, { max: 20 })
    ) {
    errors.student_first_name = 'Invalid'
  }
  if (
    !values.student_last_name ||
    !validators.zenkaku(values.student_last_name) || !validators.length(values.student_last_name, { max: 20 })
    ) {
    errors.student_last_name = 'Invalid'
  }
  if (
    !values.student_first_name_read ||
    !validators.zenkana(values.student_first_name_read) || !validators.length(values.student_first_name_read, { max: 20 })
    ) {
    errors.student_first_name_read = 'Invalid'
  }
  if (
    !values.student_last_name_read ||
    !validators.zenkana(values.student_last_name_read) || !validators.length(values.student_last_name_read, { max: 20 })
    ) {
    errors.student_last_name_read = 'Invalid'
  }
  if (
    !values.first_name ||
    !validators.zenkaku(values.first_name) || !validators.length(values.first_name, { max: 20 })
    ) {
    errors.first_name = 'Invalid'
  }
  if (
    !values.last_name ||
    !validators.zenkaku(values.last_name) || !validators.length(values.last_name, { max: 20 })
    ) {
    errors.last_name = 'Invalid'
  }
  if (
    !values.first_name_read ||
    !validators.zenkana(values.first_name_read) || !validators.length(values.first_name_read, { max: 20 })
    ) {
    errors.first_name_read = 'Invalid'
  }
  if (
    !values.last_name_read ||
    !validators.zenkana(values.last_name_read) || !validators.length(values.last_name_read, { max: 20 })
    ) {
    errors.last_name_read = 'Invalid'
  }
  if (
    !values.student_email ||
    !validators.email(values.student_email) || !validators.length(values.student_email, { max: 254 })
    ) {
    errors.student_email = 'Invalid'
  }
  if (!values.student_email_confirmation || values.student_email_confirmation !== values.student_email) {
    errors.student_email_confirmation = 'Invalid'
  }
  if (
    !values.email ||
    !validators.email(values.email) || !validators.length(values.email, { max: 254 })
    ) {
    errors.email = 'Invalid'
  }
  if (!values.email_confirmation || values.email_confirmation !== values.email) {
    errors.email_confirmation = 'Invalid'
  }
  if (!values.postal_code || !validators.postalcode(values.postal_code)) {
    errors.postal_code = 'Invalid'
  }
  if (
    !values.prefecture ||
    !validators.zenkaku(values.prefecture) || !validators.length(values.prefecture, { max: 4 })
    ) {
    errors.prefecture = 'Invalid'
  }
  if (
    !values.municipality ||
    !validators.length(values.municipality, { max: 50 })
    ) {
    errors.municipality = 'Invalid'
  }
  if (
    !values.address ||
    !validators.length(values.address, { max: 254 })
    ) {
    errors.address = 'Invalid'
  }

  if (
    !values.birthday_year ||
    !validators.intrange(values.birthday_year, { min: 1950, max: 2010 })
    ) {
    errors.birthday_year = 'Invalid'
  }
  if (
    !values.birthday_month ||
    !validators.intrange(values.birthday_month, { min: 1, max: 12 })
    ) {
    errors.birthday_month = 'Invalid'
  }
  if (
    !values.birthday_day ||
    !validators.intrange(values.birthday_day, { min: 1, max: 31 })
    ) {
    errors.birthday_day = 'Invalid'
  }
  if (values.birthday_year && values.birthday_month && values.birthday_day) {
    if (!validators.date(values.birthday_year, values.birthday_month, values.birthday_day)) {
      errors.birthday_year = 'Invalid'
      errors.birthday_month = 'Invalid'
      errors.birthday_day = 'Invalid'
    }
  }
  if (
    !values.nick_name ||
    !validators.zenkaku(values.nick_name) || !validators.length(values.nick_name, { max: 8 })
    ) {
    errors.nick_name = 'Invalid'
  }
  if (
    !values.high_school_name ||
    !validators.zenkaku(values.high_school_name) || !validators.length(values.high_school_name, { max: 30 })
    ) {
    errors.high_school_name = 'Invalid'
  }

  if (
    values.club_name && !validators.length(values.club_name || '', { max: 20 })
    ) {
    errors.club_name = 'Invalid'
  }

  if (
    (values.first_request && (values.first_request === values.second_request)) ||
    (values.second_request && (values.second_request === values.third_request)) ||
    (values.third_request && (values.third_request === values.first_request))
  ) {
    errors.first_request = 'Invalid'
  }

  return errors
}

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch })

const mapStateToProps = (appState: AppState) => {
  const nextPage = '/step2'
  const selector = formValueSelector(formKey);
  const planId = selector(appState, 'plan_id');
  const postalCode = selector(appState, 'postal_code');
  const selectedPlan =  plans.find(x => x.id === planId);
  const trainerContracted = selector(appState, 'trainer_contracted');
  const isSelectTrainerContracted = !isUndefined(trainerContracted);
  const facultyKindName = selector(appState, 'desired_faculty_kind');
  const facultyKind = facultyKinds.find(x => x.name === facultyKindName);
  const selectableFaculties = facultyKind ?
    faculties.filter(x => x.kind_id === facultyKind.id) : [];
  const selectClubKind = !!selector(appState, 'club_kind');
  const requestKeys = [ 'first_request', 'second_request', 'third_request'];
  const studentLastName = selector(appState, 'student_last_name');
  const studentFirstName = selector(appState, 'student_first_name');
  const parentLastName = selector(appState, 'last_name');
  const parentFirstName = selector(appState, 'first_name');

  const masterData = appState.ui.master.records
  let values = {
    // MARK: planをデフォルト選択する
    plan_id: 3,

    // MARK: debug用
    /*
    term1_check: true,
    term2_check: true,
    student_first_name: 'ああ',
    student_last_name: 'いい',
    first_name: 'ああ',
    last_name: 'いい',
    student_first_name_read: 'アア',
    student_last_name_read: 'イイ',
    first_name_read: 'アア',
    last_name_read: 'イイ',
    relation: 'おお',
    student_email: '1@hoge.com',
    student_email_confirmation: '1@hoge.com',
    email: '1@hoge.com',
    email_confirmation: '1@hoge.com',
    postal_code: '6790203',
    prefecture: '兵庫県',
    municipality: 'か',
    address: 'き',
    trainer_contracted: true,
    recommend_math: '0',
    recommend_physics: '0',
    recommend_classical_japanese: '0',
    recommend_chemistry: '0',
    gender: '男性',
    birthday_year: '1999',
    birthday_month: '1',
    birthday_day: '1',
    nick_name: 'ニックネーム',
    high_school_name: '高校名',
    school_grade: '高1',
    */
  }

  const initialValues = appState.temporaryForm.forms[formKey] || values;

  return {
    isAvailableDiscount2024winter: isAvailableDiscount2024winter(
      appState.ui.env!,
      moment()),
    masterData,
    prefectures,
    plans,
    planId,
    selectedPlan,
    initialValues,
    postalCode,
    nextPage,
    trainerContracted,
    isSelectTrainerContracted,
    facultyKinds,
    selectableFaculties,
    universities,
    clubKinds,
    selectClubKind,
    requestKeys,
    trainerRequests,
    currentRequests: requestKeys.map(x => selector(appState, x)),
    studentLastName,
    studentFirstName,
    parentLastName,
    parentFirstName,
  }
}


const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  { dispatch }: ReturnType<typeof mapDispatchToProps>) => {
  return {
    ...stateProps,
    fetchData: () => {
      if (!stateProps.masterData) {
        dispatch(uiActions.fetchMasterData())
      }
    },
    onSubmit: (values: FormData) => {
      dispatch(temporaryFormActions.setForm({ key: formKey, values }))
    }
  }
}

type connectMappedProps = ReturnType<typeof mergeProps>
export type mappedProps = connectMappedProps & InjectedFormProps<FormData, connectMappedProps>

const form = reduxForm<FormData, connectMappedProps>({
  form: formKey,
  validate: FormValidate,
})(SignupStep1)

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(form)
