import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, InjectedFormProps, SubmissionError, formValueSelector, FormErrors } from 'redux-form'

import { AppState } from "store";
import SettingTrainerEdit2 from 'components/student/SettingTrainerEdit2';
import userActions from 'actions/userActions'
import * as Models from 'models/api'
import {
  trainerRequests,
  } from 'utils/constants'

const formKey = 'setting_trainer_request'

type FormData = {
  first_request?: string;
  second_request?: string;
  third_request?: string;
  [key: string]: any
};

export const FormValidate = (values: FormData) => {
  const errors: FormErrors<FormData> = {}
  const requiredFields: string[] = [
    'first_request',
    'second_request',
    'third_request',
  ]
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required'
    }
  })
  return errors
}

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch })
const mapStateToProps = (appState: AppState) => {
  const selector = formValueSelector(formKey);
  const user = appState.session.user! as Models.StudentUser
  const initialValues = {}
  const trainerRequestKeys = [ 'first_request', 'second_request', 'third_request'];
  const currentTrainerRequests = trainerRequestKeys.map(x => selector(appState, x))
  return {
    user,
    trainerRequestKeys,
    trainerRequests,
    currentTrainerRequests,
    initialValues,
  }
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  { dispatch }: ReturnType<typeof mapDispatchToProps>) => {
  return {
    ...stateProps,
    onSubmit: (values: FormData) => {
      const params = {
        first_request: values.first_request!,
        second_request: values.second_request!,
        third_request: values.third_request!,
      }
      return new Promise<void>((resolve, reject) => {
        dispatch(userActions.requestTrainer({ params, promises: { resolve, reject } }))
      }).catch((e: Error) => {
        throw new SubmissionError({})
      })
    }
  }
}

type connectMappedProps = ReturnType<typeof mergeProps>
export type mappedProps = connectMappedProps & InjectedFormProps<FormData, connectMappedProps>

const form = reduxForm<FormData, connectMappedProps>({
  form: formKey,
  validate: FormValidate
})(SettingTrainerEdit2)

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(form)
