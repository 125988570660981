import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RouteProps } from 'react-router'

import { AppState } from "store";
import studentActions from 'actions/studentActions';
import SettingCardEdit from 'components/student/SettingCardEdit';
import * as Models from 'models/api'

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch })

const mapStateToProps = (appState: AppState, ownProps: RouteProps) => {
  const env = appState.ui.env
  const user = appState.session.user! as Models.StudentUser

  // 入力後 #/pay_confirmにもどってくる
  let paymentPageUrl = 'https://stg-payment.kawaijukuone.jp/creditcard/?accessToken='
  if (env === 'production') {
    paymentPageUrl = 'https://payment.kawaijukuone.jp/creditcard/?accessToken='
  }

  return {
    user,
    paymentPageUrl,
  }
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  { dispatch }: ReturnType<typeof mapDispatchToProps>) => {
  return {
    ...stateProps,
    createCreditCardToken: () => {
      // 「変更する」を押すととAPI creditcard_create をリクエスト https://github.com/gatjp/kawai1-back/blob/master/docs/api/student/creditcard_create.md
      // 戻ってきたトークンで https://payment.kawaijukuone.jp/creditcard/?accessToken= に遷移
      // 新規登録後と同様に #pay_confirm に戻ってログアウトする
      return new Promise<string>((resolve, reject) => {
        dispatch(studentActions.createCreditcardToken({
          promises: { resolve, reject }
        }))
      })
    }
  }
}

export type mappedProps = ReturnType<typeof mergeProps>

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(SettingCardEdit)
