import React, { useEffect, } from 'react';
import { Link, useParams, Redirect, } from 'react-router-dom';

import PrivateHeader from 'containers/parts/PrivateHeader';
import { mappedProps } from 'containers/student/EntrustStudyCurriculum';
import SiteMain from 'containers/parts/SiteMain';
import CorrectAnswerCount from './common/CorrectAnswerCount';

const nl2br = require('react-nl2br');

const StudyCurriculum: React.FC<mappedProps> = (props: mappedProps) => {
  const { curriculum_id } = useParams<{ curriculum_id: string }>();

  const curriculum = curriculum_id && props.getCurriculum(curriculum_id)

  useEffect(() => {
    if (curriculum) {
      props.fetchData(curriculum.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curriculum_id]);

  useEffect(() => {
    props.fetchStudyAchievementsLatest();
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  , []);

  if (!curriculum) {
    return <Redirect to='/' />;
  }

  const page = props.getRecord(curriculum.id)

  return (
    <React.Fragment>
      <PrivateHeader />
      <SiteMain>
        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          <li className="breadcrumbs__item">
            おまかせ学習{"(" + curriculum.name + ")"}
          </li>
        </ol>

        <div className="site-main__main-contents">
          <div className="page-title">
            おまかせ学習設定{"（" + curriculum.name + "）"}
            <div className="page-title__correct-count">
            {props.student.studyAchievementsLatest.record && <>
              <CorrectAnswerCount correctAnswerCount={props.student.studyAchievementsLatest.record.study_achievements.consecutive_correct_answer_count}/>
            </>}
          </div>
          </div>
          <div className="page-subtitle">
            科目
          </div>

          <ul className="subject-list">
            {page && page.courses.map(course => (
              <li key={course.id} className="subject-list__item">
                {
                  course.title.length <= 8 ?
                    <Link to={`/entrust_setting/courses/${course.id}`} className="subject-list__link">
                      {course.title}
                    </Link> :
                    <Link
                      to={`/entrust_setting/courses/${course.id}`}
                      className="subject-list__link subject-list__link--multi-line"
                    >
                      {nl2br(course.title.replace('　', '\n'))}
                    </Link>
                }
              </li>
            ))}
          </ul>

          <div className="back-home">
            <Link to='/' className="back-home__link">マイページへ戻る</Link>
          </div>
        </div>

        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          <li className="breadcrumbs__item">
            おまかせ学習{"(" + curriculum.name + ")"}
          </li>
        </ol>
      </SiteMain>
    </React.Fragment>
  );
}

export default StudyCurriculum