import { call, put, select, takeEvery } from 'redux-saga/effects'

import * as apiService from 'services/api'
import * as Models from 'models/api'
import uiActions from 'actions/uiActions'
import { AppState } from 'store';
import actions from 'actions/instructorActions'
import { updateUser } from './session'

const questionTypeStatusMap: { [key: string]: number } = { 'not-answered': 1, 'processing': 2, 'answered': 3 }

export function* fetchMypage(action: ReturnType<typeof actions.fetchMypage>) {
  try {
    const response: apiService.getInstructorMypageResponse = yield call(
      apiService.getInstructorMypage,
      yield select(x => x.repository.authorizedApi!));
    const record = response.data
    yield put(actions.setMypage({ record }));

    const type = action.payload.type
    const status = questionTypeStatusMap[type]

    const questionRecords = record.questions.find(x => x.status === status)!.items
    yield put(actions.setQuestionRecords({
      page: 1, type: type,
      records: {
        // MARK: これは使ってないので仮の値
        total_count: -1,
        // https://github.com/gatjp/kawai1-back/blob/master/docs/api/instructor/mypage_index.md
        // MARK: APIの最大レスポンス数以上あれば次への動線を出す
        total_page: questionRecords.length < 10 ? 1 : 2,
        records: questionRecords,
      }
    }));

  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

export function* fetchQuestionRecords(action: ReturnType<typeof actions.fetchQuestionRecords>) {
  const state: AppState = yield select()
  try {
    const per_page = 10
    let page = 0;
    const type = action.payload.type
    if (type === 'not-answered' || type === 'answered') {
      page = state.instructor.question.page + 1
      if (action.payload.initialize) {
        page = 1
      }
    } else {
      // MARK: 対応中は1件のみ
      page = 1;
    }

    const status = questionTypeStatusMap[type]

    const params = {
      status,
      page,
      per_page,
    }
    const response: apiService.getInstructorQuestionRecordsResponse= yield call(
      apiService.getInstructorQuestionRecords,
      yield select(x => x.repository.authorizedApi!), params);
    const records = response.data
    yield put(actions.setQuestionRecords({ page, type, records }));

    if (type === 'processing') {
      // MARK: 対応中の質問を記録する
      if (records.records.length > 0) {
        yield put(actions.setProcessingQuestion({ record: records.records[0] }));
      } else {
        yield put(actions.setProcessingQuestion({ record: null }));
      }
    }

  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

export function* fetchStudentQuestionRecords(action: ReturnType<typeof actions.fetchStudentQuestionRecords>) {
  const state: AppState = yield select()
  const student_id = action.payload.student_id

  try {
    const per_page = 10
    let page = state.instructor.studentQuestion.page + 1
    if (action.payload.initialize) {
      page = 1
    }
    const params = {
      page,
      per_page,
      student_id,
    }
    const response: apiService.getInstructorStudentQuestionRecordsResponse = yield call(
      apiService.getInstructorStudentQuestionRecords,
      yield select(x => x.repository.authorizedApi!), params);
    const records = response.data
    yield put(actions.setStudentQuestionRecords({ student_id, page, records }));
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

export function* _fetchQuestionDetail(id: number) {
  const question_id = id;
  try {
    yield put(actions.setQuestionDetail({ record: null, loading: true, question_id }));
    const response: apiService.getInstructorQuestionResponse = yield call(
      apiService.getInstructorQuestion,
      yield select(x => x.repository.authorizedApi!), id);
    const record = response.data
    yield put(actions.setQuestionDetail({ record, loading: false, question_id }));
  } catch (e) {
    yield put(actions.setQuestionDetail({ record: null, loading: false, question_id }));
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

export function* fetchQuestionDetail(action: ReturnType<typeof actions.fetchQuestionDetail>) {
  const id = action.payload.id
  yield _fetchQuestionDetail(id)
}

export function* updateQuestionDetailToProcessing(action: ReturnType<typeof actions.updateQuestionDetailToProcessing>) {
  const state: AppState = yield select()
  const record = state.instructor.questionDetail.record
  if (!record) {
    return
  }
  const id = record.id
  try {
    yield call(
      apiService.updateInstructorQuestionToProcessing,
      yield select(x => x.repository.authorizedApi!), id);
    yield _fetchQuestionDetail(id)
    yield put(actions.setProcessingQuestion({ record }));
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

export function* updateQuestionDetailToNotAnswered(action: ReturnType<typeof actions.updateQuestionDetailToNotAnswered>) {
  const state: AppState = yield select()
  const record = state.instructor.questionDetail.record
  if (!record) {
    return
  }
  const id = record.id
  try {
    yield call(
      apiService.updateInstructorQuestionToNotAnswered,
      yield select(x => x.repository.authorizedApi!), id);
    yield _fetchQuestionDetail(id)
    yield put(actions.setProcessingQuestion({ record: null }));
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

export function* updateQuestionDetailToNotPending(action: ReturnType<typeof actions.updateQuestionDetailToNotPending>) {
  const state: AppState = yield select()
  const record = state.instructor.questionDetail.record
  if (!record) {
    return
  }

  // 講師が事務局対応中の質問を差し戻す処理
  const id = record.id
  try {
    yield call(
      apiService.updateInstructorQuestionToNotPending,
      yield select(x => x.repository.authorizedApi!), id);
    yield _fetchQuestionDetail(id)
    action.payload.promises && action.payload.promises.resolve()
  } catch (e) {
    if (Models.isApiError(e) && e.response && e.response.status === 422) {
      // MARK: 講師であれば誰でも実行可なので、実行時にすでに差し戻された状態の場合がある
      // その場合(status=422)は最新の質問情報を取得し、差し戻された状態であればresolveする
      yield _fetchQuestionDetail(id)

      const newState: AppState = yield select()
      const newRecord = newState.instructor.questionDetail.record

      if (newRecord && !newRecord.support_required) {
        action.payload.promises && action.payload.promises.resolve()
        return
      }
    }

    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
    action.payload.promises && action.payload.promises.reject(e)
  }
}

export function* createQuestionAnswer(action: ReturnType<typeof actions.createQuestionAnswer>) {
  const state: AppState = yield select()
  try {
    yield call(
      apiService.createQuestionAnswer,
        state.repository.authorizedApi!,
        action.payload.params);

    /*const id = action.payload.params.id
    yield _fetchQuestionDetail(id)*/

    yield put(actions.setProcessingQuestion({ record: null }));

    action.payload.promises && action.payload.promises.resolve()
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
    action.payload.promises && action.payload.promises.reject(e)
  }
}

export function* createQuestionPendingAnswer(action: ReturnType<typeof actions.createQuestionPendingAnswer>) {
  const state: AppState = yield select()
  try {
    yield call(
      apiService.createQuestionPendingAnswer,
        state.repository.authorizedApi!,
        action.payload.params);
    action.payload.promises && action.payload.promises.resolve()
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
    action.payload.promises && action.payload.promises.reject(e)
  }
}

export function* updateProfile(action: ReturnType<typeof actions.updateProfile>) {
  const state: AppState = yield select()
  try {
    yield call(
      apiService.updateInstructorProfile,
        state.repository.authorizedApi!,
        action.payload.params);

    //user情報を更新する
    yield updateUser()

    action.payload.promises && action.payload.promises.resolve()
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
    action.payload.promises && action.payload.promises.reject(e)
  }
}

function* saga() {
  yield takeEvery(actions.fetchMypage, fetchMypage);
  yield takeEvery(actions.fetchQuestionRecords, fetchQuestionRecords);
  yield takeEvery(actions.fetchStudentQuestionRecords, fetchStudentQuestionRecords);
  yield takeEvery(actions.fetchQuestionDetail, fetchQuestionDetail);
  yield takeEvery(actions.updateQuestionDetailToProcessing, updateQuestionDetailToProcessing);
  yield takeEvery(actions.updateQuestionDetailToNotAnswered, updateQuestionDetailToNotAnswered);
  yield takeEvery(actions.updateQuestionDetailToNotPending, updateQuestionDetailToNotPending);
  yield takeEvery(actions.updateProfile, updateProfile);
  yield takeEvery(actions.createQuestionAnswer, createQuestionAnswer);
  yield takeEvery(actions.createQuestionPendingAnswer, createQuestionPendingAnswer);
}

export default saga;
