import React from 'react';
import { HashRouter, Route } from 'react-router-dom';

import Footer from 'components/parts/Footer';
import ScrollToTop from 'components/parts/ScrollToTop';
import Notification from 'containers/parts/Notification';
import PrivateRoute from 'containers/parts/PrivateRoute';

import PasswordReminder from 'containers/common/PasswordReminder'
import PasswordReset from 'containers/common/PasswordReset'
import Login from 'containers/common/Login'
import Logout from 'containers/common/Logout';
import Infos from 'containers/common/Infos';
import Info from 'containers/common/Info';
import SettingPasswordEdit from 'containers/common/SettingPasswordEdit';

import Mypage from 'containers/instructor/Mypage';
import Question from 'containers/instructor/Question';
import QuestionForm from 'containers/instructor/QuestionForm';
import QuestionForm2 from 'containers/instructor/QuestionForm2';
import SettingProfileEdit from 'containers/instructor/SettingProfileEdit';
import StudentStudyLogs from 'containers/instructor/StudentStudyLogs';
import StudentQuestions from 'containers/instructor/StudentQuestions';

import StudentStudyResult from 'containers/instructor/StudentStudyResult';
import StudentStudyResultCurriculum from 'containers/instructor/StudentStudyResultCurriculum';
import StudentStudyResultCourse from 'containers/instructor/StudentStudyResultCourse';
import StudentStudyResultUnit from 'containers/instructor/StudentStudyResultUnit';
import StudentStudyResultUnitResult from 'containers/instructor/StudentStudyResultUnitResult';

const App: React.FC = () => {
  return (
    <React.Fragment>
      <Notification />
      <HashRouter>
        <ScrollToTop />

        <Route exact path='/password_reminder' component={PasswordReminder} />
        <Route exact path='/password_reset' component={PasswordReset} />
        <Route exact path='/login' component={Login} />
        <Route exact path='/logout' component={Logout} />

        <PrivateRoute exact path='/' component={Mypage} />
        <PrivateRoute exact path='/infos' component={Infos} />
        <PrivateRoute exact path='/infos/:id' component={Info} />
        <PrivateRoute exact path='/setting/profile_edit' component={SettingProfileEdit} />
        <PrivateRoute exact path='/setting/password_edit' component={SettingPasswordEdit} />
        <PrivateRoute exact path='/students/:id/study_logs' component={StudentStudyLogs} />
        <PrivateRoute exact path='/students/:id/questions' component={StudentQuestions} />
        <PrivateRoute exact path='/students/:id/study_result' component={StudentStudyResult} />
        <PrivateRoute exact path='/students/:id/study_result/:curriculum_id' component={StudentStudyResultCurriculum} />
        <PrivateRoute exact path='/students/:id/study_result/courses/:course_id' component={StudentStudyResultCourse} />
        <PrivateRoute exact path='/students/:id/study_result/units/:unit_id' component={StudentStudyResultUnit} />
        <PrivateRoute exact path='/students/:id/study_result/unit_results/:unit_id' component={StudentStudyResultUnitResult} />
        <PrivateRoute exact path='/questions/:id' component={Question} />
        <PrivateRoute exact path='/questions/:id/form' component={QuestionForm} />
        <PrivateRoute exact path='/questions/:id/form2' component={QuestionForm2} />
      </HashRouter>

      <Footer />
    </React.Fragment>
  );
}

export default App;
