import React from 'react';
import Moment from 'react-moment';

import * as Models from 'models/api'

import pdfIconImage from 'assets/img/img_pdf.png';

const nl2br = require('react-nl2br');

type props = {
  question: Models.QuestionDetail;
  type: Models.LoginableUIType;
}

const QuestionAnswerDetail: React.FC<props> = (props: props) => {
  const { question } = props

  // MARK: 受講生画面では回答済みの場合だけ講師を表示
  const showInstructor = props.type === 'instructor' || ( props.type === 'student' && question.statusCode === 'answered' )

  return (
    <div className="question-form question-form--teacher">
      <div className="question-form__question">
        <div className={`question-box question-box--${question.statusCode}`}>
          <div className="question-box__inner">
            <div className="question-box__title">
              { question.content.topic.title } > { question.content.meta!.label}
            </div>
            <div className="question-box__spec">
              {
                showInstructor && question.instructor &&
                <span className="question-box__name">{ question.instructor.nick_name }</span>
              }
              <span className="question-box__datetime">
                <Moment format="M月D日 HH:mm">{ question.answer_finished_at }</Moment>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="question-form__textarea question-form__textarea--teacher">
        { nl2br(question.answer) }
      </div>
      {
        !question.answer_attachment &&
        <React.Fragment>
          {/* border bottom追加用 */}
          <div className="question-form__attachment question-form__attachment--teacher">
          </div>
        </React.Fragment>
      }

      {
        question.answer_attachment &&
        <React.Fragment>
          <div className="question-form__title question-form__title--teacher">
            添付ファイル
          </div>
          <div className="question-form__attachment question-form__attachment--teacher">
            {
              question.answer_attachment.mime_type.startsWith('image/') &&
              <React.Fragment>
                <a href={question.answer_attachment.url} target="_blank" rel="noopener noreferrer">
                  <img src={question.answer_attachment.url} alt="添付画像" className="question-form__image" /><br />
                  <div className="answer-form__file-name answer-form__file-name--teacher">
                    { question.answer_attachment.file_name }
                  </div>
                </a>
              </React.Fragment>
            }
            {
              question.answer_attachment.mime_type.startsWith('application/pdf') &&
              <React.Fragment>
                <a href={question.answer_attachment.url} target="_blank" rel="noopener noreferrer">
                  <img src={pdfIconImage} alt="" className="question-form__image question-form__image--pdf" />
                  <div className="answer-form__file-name answer-form__file-name--teacher">
                      { question.answer_attachment.file_name }
                  </div>
                </a>
              </React.Fragment>
            }
          </div>
        </React.Fragment>
      }
    </div>
  );
}

export default QuestionAnswerDetail
