import { reducerWithInitialState } from 'typescript-fsa-reducers';
import produce from 'immer'

import * as Models from 'models/api'
import actions from 'actions/parentActions';
import { curriculums } from 'utils/constants'
import { sortBy } from 'lodash';

export interface ParentState {
  mypage: Models.ParentMypage | null;
  student: Models.StudentUser | null;
  paymentLog: {
    records: Models.ParentPaymentLogs | null;
  }
}

const initialState: ParentState = {
  mypage: null,
  student: null,
  paymentLog: {
    records: null,
  }
};

export const parentReducer = reducerWithInitialState(initialState)
  .case(actions.setMypage, (state, payload) => {
    return produce(state, draftState => {
      // 表示順序
      // 英語: 2, 数学: 1, 物理: 3、化学: 4、古文: 5
      const curriculumIds = curriculums.map(x => x.id)
      draftState.mypage = {
        ...payload.record,
        curriculums: sortBy(payload.record.curriculums, x => curriculumIds.indexOf(x.id)),
      }
    })
  })
  .case(actions.setStudent, (state, payload) => {
    return produce(state, draftState => {
      draftState.student = payload.record
    })
  })
  .case(actions.setPaymentLogRecords, (state, payload) => {
    const statusNameMap: { [key: number]: any } = {
      1: { name: '未払い', code: 'not-purchased' },
      2: { name: '支払い済', code: 'purchased' },
      3: { name: '未払い', code: 'purchase-failed' }
    }
    return produce(state, draftState => {
      draftState.paymentLog.records = payload.records.map(x => ({
        ...x,
        statusName: statusNameMap[x.status].name,
        statusCode: statusNameMap[x.status].code,
      }))
    })
  })

