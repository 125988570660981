import React from 'react';
import {getCorrectAnswerColorType ,CorrectAnswerColor } from '../../../utils/store';

interface Props {
  correctAnswerCount : number | null;
}

const CorrectAnswerCount: React.FC<Props> = (props) => {
  let correctAnswerCount  = props.correctAnswerCount;

  const colorType = correctAnswerCount && getCorrectAnswerColorType(correctAnswerCount);
  return (
  <>
  <div className="study-time-stats__correct-answer-count-section">
    <div className="study-time-stats__correct-answer-count-section-inner">
      <div className="study-time-stats__correct-answer-count-section-title">連続正解数</div>
      <div className="study-time-stats__correct-answer-count-section-text">
        {correctAnswerCount}問
      </div>
    </div>
    <div className={`
      ${colorType ===  CorrectAnswerColor.Yellow && "study-time-stats__hand-sign-1" }
      ${colorType === CorrectAnswerColor.Green && "study-time-stats__hand-sign-2" }
    `}>
    </div>
  </div>
  </>);
};

export default CorrectAnswerCount;
