import React, { useEffect, } from 'react';
import { Link, useParams, Redirect, } from 'react-router-dom';

import PrivateHeader from 'containers/parts/PrivateHeader';
import { mappedProps } from 'containers/trainer/StudyCurriculum';
import SiteMain from 'containers/parts/SiteMain';
const nl2br = require('react-nl2br');

const StudyCurriculum: React.FC<mappedProps> = (props: mappedProps) => {
  const { curriculum_id } = useParams<{curriculum_id: string}>();
  const curriculum = curriculum_id && props.getCurriculum(curriculum_id)

  useEffect(() => {
    curriculum &&  curriculum.id &&  props.fetchCourses(curriculum.id)
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  , [curriculum]);

  if (!curriculum) {
    return <Redirect to='/' />;
  }

  const courses  = props.trainer.courses.records
  return (
    <React.Fragment>
      <PrivateHeader />
      <SiteMain>
        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          <li className="breadcrumbs__item">
            { curriculum.name}
          </li>
        </ol>

        <div className="site-main__main-contents">
          <div className="page-title">
            { curriculum.name }
          </div>

          <div className="page-subtitle">
            科目
          </div>

          <ul className="subject-list">
            { courses && courses.map(course => (
              <li key={course.id} className="subject-list__item">
                {
                  course.title.length <= 8 ?
                  <Link to={`/study/courses/${course.id}`} className="subject-list__link">
                    { course.title }
                  </Link>:
                  <Link
                    to={`/study/courses/${course.id}`}
                    className="subject-list__link subject-list__link--multi-line"
                  >
                    { nl2br(course.title.replace('　', '\n') ) }
                  </Link>
                }
              </li>
            ))}
          </ul>

          <div className="back-home">
            <Link to='/' className="back-home__link">マイページへ戻る</Link>
          </div>
        </div>

        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          <li className="breadcrumbs__item">
            { curriculum.name}
          </li>
        </ol>
      </SiteMain>
    </React.Fragment>
  );
}

export default StudyCurriculum