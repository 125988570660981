import React, { useEffect } from 'react';
import { Link, useParams, } from 'react-router-dom';

import PrivateHeader from 'containers/parts/PrivateHeader';
import { mappedProps } from 'containers/instructor/StudentStudyResultCourse';
import SiteMain from 'containers/parts/SiteMain';
import StudentBar from '../parts/CommonStudentBar'
import StudentNav from '../parts/InstructorStudentNav';
import CircleChart from '../parts/CircleChart'

const TrainerStudentStudyResultCourse: React.FC<mappedProps> = (props: mappedProps) => {
  const { id, course_id } = useParams();

  useEffect(() => {
    if (id && course_id) {
      props.fetchData(Number(id), Number(course_id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, course_id]);

  const student = props.getStudent(Number(id))
  const page = student && props.getRecord(student.id, Number(course_id))

  return (
    <React.Fragment>
      <PrivateHeader />
      <SiteMain>
        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          <li className="breadcrumbs__item">
            受講者情報
          </li>
        </ol>

        {
          student && page &&
          <div className="site-main__main-contents">
            <StudentBar student={student} />
            <StudentNav student={student} type="study_result" />

            <div className="study-breadcrumbs">
              <Link to={`/students/${student.id}/study_result`} className="study-breadcrumbs__item">
                教科
              </Link>
              <Link to={`/students/${student.id}/study_result/${page.curriculum.id}`} className="study-breadcrumbs__item">
                科目
              </Link>
              <div className="study-breadcrumbs__item">
                単元
              </div>
            </div>

            <div className="page-title">
              { page.curriculum.name }
              <div className="page-title__badge">
                { page.course.name }
              </div>
            </div>

            <div className="subject-chart-list">
              <div className="subject-chart-list__title">
                単元
              </div>
              <div className="subject-chart-list__items">
                { page.units.map(unit => (
                  <div key={unit.id} className="subject-chart-list__item">
                    <div className="subject-chart-list__chart">
                      <CircleChart rate={unit.understanding / 100} />
                    </div>
                    <div className="subject-chart-list__subject">
                      <Link
                        to={`/students/${student.id}/study_result/units/${unit.id}`}
                        className="subject-chart-list__subject-link">
                        { unit.name }
                      </Link>
                    </div>
                    <div className="subject-chart-list__buttons subject-chart-list__buttons--teacher">
                      <Link
                        to={`/students/${student.id}/study_result/unit_results/${unit.id}`}
                        className="button button--short button--primary subject-chart-list__button subject-chart-list__button--large">
                        結果比較
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="back-home">
              <Link to='/' className="back-home__link">マイページへ戻る</Link>
            </div>
          </div>
        }

        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          <li className="breadcrumbs__item">
            受講者情報
          </li>
        </ol>
      </SiteMain>
    </React.Fragment>
  );
}

export default TrainerStudentStudyResultCourse