import { StudentUser } from 'models/api';

const disabledCorporationNames = [
  'KD河合塾（大受）',
  'KK河合塾（グリーン）',
  '2020KEI　大学入学前教育',
]

export const isAvailable = (user: StudentUser): boolean => {
  // MARK: トレーナーチャットを利用可能か（トレーナーが紐付けられていて、disabled対象の法人でない）
  if (!user.trainer) {
    return false
  }

  return !user.corporation || !disabledCorporationNames.includes(user.corporation.name)
}
