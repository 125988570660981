import React from 'react';
import logo from 'assets/img/logo.png';
import { Schedule } from 'utils/maintenance';

type Props = {
  schedule: Schedule;
}

const Maintenance: React.FC<Props> = (props: Props) => {
  return (
    <React.Fragment>
      <div style={{ textAlign: 'center' }}>
        <div>
          <div>
            <img src={logo} alt="" className="" />
          </div>
          <div style={{ padding: '10px', maxWidth: '800px', margin: '0 auto' }}>
            <p style={{ fontWeight: 'bold', marginBottom: '10px' }}>
              現在システムメンテナンス中です
            </p>
            <p style={{ marginBottom: '10px' }}>
              下記の日時におきまして、システムのメンテナンスを実施いたします。
            </p>
            <p>
              【サーバーメンテナンス実施日時】
            </p>
            <p style={{ fontWeight: 'bold', marginBottom: '10px' }}>
              { props.schedule.title }
            </p>
            <p>
              データメンテナンスを実施しております。<br />
              誠に申し訳ございませんが、メンテナンス中はサービスのご利用を停止させていただいております。<br />
              受講者様には大変ご迷惑をお掛けし大変申し訳ございません。<br />
              今しばらくお待ちいただけますようお願い申し上げます。
              <br /><br />
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Maintenance
