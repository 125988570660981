import moment, { Moment } from "moment";

export type Schedule = {
  startAt: Moment;
  endAt: Moment;
  title: string;
}

export const schedules: Array<Schedule> = [
  {
    startAt: moment('2024-04-30T11:00:00+0900'),
    endAt: moment('2024-04-30T12:00:00+0900'),
    title: '令和6年4月30日(火)11:00～12:00',
  },
];

export const getCurrentSchedule = (now: Moment, schedules: Array<Schedule>): Schedule | undefined => {
  return schedules.find(x => x.endAt > now && now >= x.startAt)
}
