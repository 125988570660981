import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router'
import { reduxForm, InjectedFormProps, SubmissionError, FormErrors } from 'redux-form'

import { AppState } from "store";
import validators from 'utils/validators'
import userActions from 'actions/userActions';
import PasswordReminder from 'components/common/PasswordReminder';

interface FormData {
  email?: string;
  [key: string]: string|undefined;
}

export const FormValidate = (values: FormData) => {
  const errors: FormErrors<FormData> = {}
  if (!values.email || !validators.email(values.email)) {
    errors.email = 'Invalid email address'
  }
  return errors
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onSubmit: (values: FormData) => {
      return new Promise<void>((resolve, reject) => {
        dispatch(userActions.createPasswordNotification({
          params: {
            email: values.email!,
          },
          promises: { resolve, reject }
        }))
      }).catch((e: Error) => {
        throw new SubmissionError({})
      })
    }
  };
}

const mapStateToProps = (appState: AppState, ownProps: RouteComponentProps) => {
  return {
    initialValues: {},
    ...ownProps,
  }
}

type connectMappedProps = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>
export type mappedProps = connectMappedProps & InjectedFormProps<FormData, connectMappedProps>

const form = reduxForm<FormData, connectMappedProps>({
  form: 'password_reminder',
  validate: FormValidate
})(PasswordReminder)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(form))
