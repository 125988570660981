import React, { useEffect } from 'react';
import { Link, Redirect, useParams } from 'react-router-dom';

import PrivateHeader from 'containers/parts/PrivateHeader';
import { mappedProps } from 'containers/common/Info';
import SiteMain from 'containers/parts/SiteMain';

const nl2br = require('react-nl2br');

const Info: React.FC<mappedProps> = (props: mappedProps) => {
  const { id } = useParams();

  useEffect(() => {
    props.fetchData(Number(id))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // MARK: 特定コーポレートユーザ利用不可
  if (props.user.type === 'student' && !props.isAvailableStudentInfo) {
    return <Redirect to='/' />;
  }

  const info = props.getInfo(Number(id))

  return (
    <React.Fragment>
      <PrivateHeader />
      <SiteMain>
        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          <li className="breadcrumbs__item">
            <Link to='/infos' className="breadcrumbs__link">お知らせ</Link>
          </li>
          <li className="breadcrumbs__item">
            { info && info.title }
          </li>
        </ol>

        <div className="site-main__main-contents">
          <div className="page-title page-title--article">
            { info && info.title }
          </div>
          <div className="information-box">
            <div className="information-box__text">
              { info && nl2br(info.body) }
            </div>
          </div>
          <div className="back-home">
            <Link to='/infos' className="breadcrumbs__link">お知らせ一覧へ戻る</Link>
          </div>
        </div>

        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          <li className="breadcrumbs__item">
            <Link to='/infos' className="breadcrumbs__link">お知らせ</Link>
          </li>
          <li className="breadcrumbs__item">
            { info && info.title }
          </li>
        </ol>
      </SiteMain>
    </React.Fragment>
  );
}

export default Info
