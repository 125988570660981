import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router'
import { reduxForm, InjectedFormProps, SubmissionError, FormErrors } from 'redux-form'

import { AppState } from "store";
import SettingPasswordEdit from 'components/common/SettingPasswordEdit';
import userActions, { changePasswordPayload } from 'actions/userActions';

type FormData = {
  password: string;
  password_confirmation: string;
  [key: string]: any
};

export const FormValidate = (values: FormData) => {
  const errors: FormErrors<FormData> = {}
  const requiredFields: string[] = [
    'password',
    'password_confirmation',
  ]
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = '入力してください'
    }
  })

  if (values.password !== values.password_confirmation) {
    errors.password_confirmation = 'パスワードが一致しません。再度入力してください。'
  }
  return errors
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onSubmit: (values: FormData) => {
      return new Promise<void>((resolve, reject) => {
        const payload: changePasswordPayload = {
          params: {
            password: values.password!
          },
          promises: { resolve, reject }
        }
        dispatch(userActions.changePassword(payload))
      }).catch((e: Error) => {
        throw new SubmissionError({})
      })
    }
  }
}

const mapStateToProps = (appState: AppState, routeProps: RouteComponentProps) => {
  const user = appState.session.user!
  const initialValues = {}
  return {
    user,
    initialValues,
    ...routeProps,
  }
}

type connectMappedProps = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>
export type mappedProps = connectMappedProps & InjectedFormProps<FormData, connectMappedProps>

const form = reduxForm<FormData, connectMappedProps>({
  form: 'setting_password_edit',
  validate: FormValidate
})(SettingPasswordEdit)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(form))
