import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RouteProps } from 'react-router'

import FixPaymentInformation from 'components/common/FixPaymentInformation';
import storeUtils from 'utils/store'
import { AppState } from "store";
import studentActions from 'actions/studentActions';
import * as Models from 'models/api'

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch })

const mapStateToProps = (appState: AppState, ownProps: RouteProps) => {
  const user = appState.session.user as Models.StudentUser
  const env = appState.ui.env
  const isAuthenticated = user !== null
  const alreadyFixed = !storeUtils.shouldFixPaymentInformation(
      appState.ui, user);
  const nextPage = ownProps.location && ownProps.location.state ?
    ownProps.location.state.from.pathname : '/';

  // 入力後 #/pay_confirmにもどってくる
  let paymentPageUrl = 'https://stg-payment.kawaijukuone.jp/creditcard/?accessToken='
  if (env === 'production') {
    paymentPageUrl = 'https://payment.kawaijukuone.jp/creditcard/?accessToken='
  }

  return {
    user,
    nextPage,
    isAuthenticated,
    alreadyFixed,
    paymentPageUrl,
  }
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  { dispatch }: ReturnType<typeof mapDispatchToProps>) => {
  return {
    ...stateProps,
    createCreditcardToken: () => {
      return new Promise<string>((resolve, reject) => {
        dispatch(studentActions.createCreditcardToken({
          promises: { resolve, reject }
        }))
      })
    }
  }
}

export type mappedProps = RouteProps & ReturnType<typeof mergeProps>
export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(FixPaymentInformation)
