import { AppState } from 'store';
import { UIState } from 'states/uiState';
import { User, StudentUser, ContentMeta, isApiError, TrainerUser } from 'models/api';
import { curriculums } from 'utils/constants'

const getErrorMessage = (e: Error, joinner: string = '、'): string => {
  let message: string
  if (isApiError(e)) {
    if (!e.response) {
      message = '処理できませんでした。';
    } else {
      message = e.response!.data.errors.join(joinner)
    }
  } else {
    message = '処理できませんでした。';
  }
  return message
}

const hasTemporaryForms = (appState: AppState, keys: string[]): boolean => {
  return keys.every((x: string) => appState.temporaryForm.forms[x]);
}

const shouldFixProvisionalPassword = (ui: UIState, user: User | null) => {
  if (!user || ui.type !== 'student') {
    return false
  }
  const student = user as StudentUser
  const status = student.status
  if (student.corporation) {
    // MARK: 法人受講生対応不要
    return false
  }
  // https://docs.google.com/presentation/d/1YmQ5GVKLEQj-bd9L45a4vbvjwPeCBQvrB4gyqAOr_wg/edit#slide=id.g64e18748f7_10_300
  // MARK: trial	トライアル、regular	本登録でパスワード未変更の場合のみ遷移
  return (status === 'trial' || status === 'regular') && !student.password_updated
}

const shouldFixPaymentInformation = (ui: UIState, user: User | null) => {
  if (!user || ui.type !== 'student') {
    return false
  }
  // https://github.com/gatjp/kawai1-back/blob/master/docs/api/student/sessions_create.md#status-%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6
  // MARK: クレジットカード登録前、利用停止ステータスの場合はクレカ登録画面に遷移させる
  const student = user as StudentUser
  const status = student.status
  if (student.corporation) {
    // MARK: 法人受講生対応不要
    return false
  }

  return status === 'temporary' || status === 'suspension';
}

/*
https://docs.google.com/presentation/d/1n2DPw_yOL8f9yW4Q-9TBT1BzH1J7m7hQo5ukFPAWvqI/edit#slide=id.gefb3f46741_1_19

EW1-001-E2J-1　語義選択（英→和）
EW1-001-J2E-1　語義選択（和→英）
EW1-001-SW-1　英文空所補充選択肢
EW1-001-TW-1　英文空所補充文字入力
EW1-001-CD-1　カウントダウン
EW1-001-TT-1　タイムトライアル
EW1-001-WD-1　単語帳
*/

const getEnglishWordsContentMetaObjectByCode =(code: string): ContentMeta => {
  let match

  if ((match = code.match(/-E2J-(.+)/))) {
    const type = '語義選択（英→和）'
    return {
      isVideo: false,
      type,
      label: `${type} ${match[1]}`,
    }
  }

  if ((match = code.match(/-J2E-(.+)/))) {
    const type = '語義選択（和→英）'
    return {
      isVideo: false,
      type,
      label: `${type} ${match[1]}`,
    }
  }

  if ((match = code.match(/-SW-(.+)/))) {
    const type = '英文空所補充選択肢'
    return {
      isVideo: false,
      type,
      label: `${type} ${match[1]}`,
    }
  }

  if ((match = code.match(/-TW-(.+)/))) {
    const type = '英文空所補充文字入力'
    return {
      isVideo: false,
      type,
      label: `${type} ${match[1]}`,
    }
  }

  if ((match = code.match(/-CD-(.+)/))) {
    const type = 'カウントダウン'
    return {
      isVideo: false,
      type,
      label: `${type} ${match[1]}`,
    }
  }

  if ((match = code.match(/-TT-(.+)/))) {
    const type = 'タイムトライアル'
    return {
      isVideo: false,
      type,
      label: `${type} ${match[1]}`,
    }
  }

  if ((match = code.match(/-WD-(.+)/))) {
    const type = '単語帳'
    return {
      isVideo: false,
      type,
      label: `${type} ${match[1]}`,
    }
  }

  return {
    isVideo: false,
    type: '',
    label: code,
  };
}

/*
POS01　受講後テスト 　例）EG1-POS01
PRE01　受講前テスト     例）EG1-PRE02
LCT1−1  レベルチェックテスト１  例）EG1-037-LCT-1
BV−1　基本事項 解説１   例）EG1-037-BV-1　＊動画コンテンツ
T−1　　練習問題１          例）EG1-037-T-1
TV−1　練習問題 解説１   例）EG1-037-TV-1　＊動画コンテンツ

//両方の形式に対応する
before	after
ER1-ST001-P	ER1-531-ST001-T
ER1-ST001-A	ER1-531-ST001-TV
ER1-ST002-P	ER1-532-ST002-T
ER1-ST002-A	ER1-532-ST002-TV
ER1-ST003-P	ER1-533-ST003-T
ER1-ST003-A	ER1-533-ST003-TV
ER1-ST004-P	ER1-534-ST004-T
ER1-ST004-A	ER1-534-ST004-TV
ER1-ST005-P	ER1-535-ST005-T
ER1-ST005-A	ER1-535-ST005-TV
ER1-ST006-P	ER1-536-ST006-T
ER1-ST006-A	ER1-536-ST006-TV
ER1-ST007-P	ER1-537-ST007-T
ER1-ST007-A	ER1-537-ST007-TV
ER1-ST008-P	ER1-538-ST008-T
ER1-ST008-A	ER1-538-ST008-TV
ER1-ST009-P	ER1-539-ST009-T
ER1-ST009-A	ER1-539-ST009-TV
ER1-ST010-P	ER1-540-ST010-T
ER1-ST010-A	ER1-540-ST010-TV
ER1-ST011-P	ER1-541-ST011-T
ER1-ST011-A	ER1-541-ST011-TV
ER1-ST012-P	ER1-542-ST012-T
ER1-ST012-A	ER1-542-ST012-TV
ER1-ST013-P	ER1-543-ST013-T
ER1-ST013-A	ER1-543-ST013-TV
ER1-ST014-P	ER1-544-ST014-T
ER1-ST014-A	ER1-544-ST014-TV
ER1-ST015-P	ER1-545-ST015-T
ER1-ST015-A	ER1-545-ST015-TV

ER1-ST001-P -> ER1-531-ST001-P
ER1-ST001-A -> ER1-531-ST001-A
*/
const getContentMetaObjectByCode = (code: string): ContentMeta => {
  if (code.startsWith('EW1') || code.startsWith('EW2')) {
    return getEnglishWordsContentMetaObjectByCode(code)
  }

  let match
  if ((match = code.match(/-PO/))) {
    const type = '受講後テスト'
    return {
      isVideo: false,
      type,
      label: type,
    }
  }
  if ((match = code.match(/-PR/))) {
    const type = '受講前テスト'
    return {
      isVideo: false,
      type,
      label: type,
    }
  }
  if ((match = code.match(/-LCT-(.+)/))) {
    const type = 'レベルチェックテスト'
    return {
      isVideo: false,
      type,
      label: `${type} ${match[1]}`,
    }
  }
  if ((match = code.match(/-BV-(.+)/))) {
    const type = '基本事項 解説'
    return {
      isVideo: true,
      type,
      label: `${type} ${match[1]}`
    }
  }
  if ((match = code.match(/-T-(.+)/))) {
    const type = '練習問題'
    return {
      isVideo: false,
      type,
      label: `${type} ${match[1]}`
    }
  }
  if ((match = code.match(/-TV-(.+)/))) {
    const type = '練習問題 解説'
    return {
      isVideo: true,
      type,
      label: `${type} ${match[1]}`
    }
  }

  if ((match = code.match(/ER1-ST(.+)-P$/))) {
    const type = '練習問題'
    return {
      isVideo: false,
      type,
      label: `${type} 1`
    }
  }

  if ((match = code.match(/ER1-ST(.+)-A$/))) {
    const type = '練習問題 解説'
    return {
      isVideo: true,
      type,
      label: `${type} 1`
    }
  }

  if ((match = code.match(/ER1-(.+)-ST(.+)-(T|P)$/))) {
    const type = '練習問題'
    return {
      isVideo: false,
      type,
      label: `${type} 1`
    }
  }

  if ((match = code.match(/ER1-(.+)-ST(.+)-(TV|A)$/))) {
    const type = '練習問題 解説'
    return {
      isVideo: true,
      type,
      label: `${type} 1`
    }
  }

  if ((match = code.match(/-CC/))) {
    const type = '科目修了テスト'
    return {
      isVideo: false,
      type,
      label: type,
    }
  }

  return {
    isVideo: false,
    type: '',
    label: code,
  };
}

const getContentTypeLabelByCode = (code: string): string => {
  const obj = getContentMetaObjectByCode(code)
  return obj.label
}

/*
90%以上 Sランク
70%以上 Aランク
50%以上 Bランク
それ以外 Cランク
*/
const getStudyUnitResultRank = (count: number, total: number): string => {
  const x = count / total
  if (x >= 0.9) { return 'S' }
  if (x >= 0.7) { return 'A' }
  if (x >= 0.5) { return 'B' }
  return 'C';
}

// MARK: 受講生の受講可能な科目か
export const isAvailableCurriculum = (idOrCode: number | string, user: StudentUser) => {
  const curriculum = curriculums.find(x => x.id === idOrCode || x.code === idOrCode)
  if (!curriculum) {
    return false
  }
  return user.visible_curriculums.map(x => x.id).includes(curriculum.id)
}

export const getAvailableCurriculums = (user: StudentUser) => {
  return curriculums.filter(x => isAvailableCurriculum(x.id, user))
}

// MARK: 受講生の受講科目か
export const isRecommendCurriculum = (idOrCode: number | string, user: StudentUser) => {
  const curriculum = curriculums.find(x => x.id === idOrCode || x.code === idOrCode)
  if (!curriculum) {
    return false
  }
  return user.attended_curriculums.map(x => x.id).includes(curriculum.id)
}

export const getRecommendedCurriculums = (user: StudentUser) => {
  return curriculums.filter(x => isRecommendCurriculum(x.id, user))
}

export const isEntrustCurriculum = (idOrCode: number | string, user: StudentUser) => {
  const curriculum = curriculums.find(x => x.id === idOrCode || x.code === idOrCode)
  if (!curriculum) {
    return false
  }
  return user.attended_curriculums.map(x => x.id).includes(curriculum.id)
}

export const getEntrustCurriculums = (user: StudentUser) => {
  return curriculums.filter(x => isEntrustCurriculum(x.id, user))
}

// MARK: 講師質問詳細画面のコンテンツへのUrlを取得する
const getQuestionContentUrl = (curriculumCode: string): string => {
  // MARK: 講師質問詳細画面のコンテンツリンク先修正
  // https://github.com/gatjp/kawai1-front/issues/495
  const code = curriculumCode.replace(/^KE1/, 'EG1');
  const contentLink = `https://content.kawaijukuone.jp/contents/${code}`
  return contentLink;
}

// MARK: https://github.com/gatjp/kawai1-front/issues/486
// 法人の受講可能教科の概念がなくなった
// MARK: 法人に許可されている教科のリスト
// export const getCorporateCurriculums = (user: StudentUser) => {
//   if (!user.corporation) { return []; }
//   return curriculums.filter(x => (user.corporation!.available_curriculums as any)[x.code])
// }

// // MARK: 法人に許可されていない教科が受講設定されているか
// export const attendedInvalidCorporatetCurriculums = (user: StudentUser) => {
//   if (!user.corporation) { return false; }
//   const curriculums = getCorporateCurriculums(user);
//   const ids = curriculums.map(x => x.id)
//   return user.attended_curriculums.some(x => !ids.includes(x.id))
// }

export const LearningDurationFace = {
  VeryGood : 'very-good',
  Good : 'good',
  Average : 'average',
  Poor : 'poor',
  VeryPoor : 'very-poor',
} as const;
type LearningDurationFaceType = typeof LearningDurationFace[keyof typeof LearningDurationFace];
export const getLearningDurationFaceString= (code: number): LearningDurationFaceType | undefined => {
  if (code <= 1) return LearningDurationFace.VeryGood;
  if ( code === 2 ) return LearningDurationFace.Good;
  if (code === 3 ) return LearningDurationFace.Average;
  if (code >= 4 && code <= 6 ) return LearningDurationFace.Poor;
  if (code >= 7 ) return LearningDurationFace.VeryPoor;
  return undefined;
  }

export const getLearningDurationMessages = (noLearningDuration: number):  any| undefined=> {
  if (noLearningDuration <= 1)  return {bold: '継続して学習している', regular: ''}
  if (noLearningDuration === 2) return {bold: '2日間', regular: '学習していない'}
  if (noLearningDuration ===3) return  {bold: '3日間', regular: '学習していない'}
  if (noLearningDuration >= 4 && noLearningDuration <= 6 ) return {bold: '4日以上', regular: '学習していない'}
  if (noLearningDuration >= 7 ) return  {bold: '1週間以上', regular: '学習していない'}
  return undefined;
  }

  export const CorrectAnswerColor = {
    White: "white",
    Yellow: "yellow",
    Green: "green",
  } as const;
  type CorrectAnswerColorType = typeof CorrectAnswerColor[keyof typeof CorrectAnswerColor];
  export const getCorrectAnswerColorType = (correctAnswerCount: number): CorrectAnswerColorType | undefined => {
    if (correctAnswerCount <= 2) return  CorrectAnswerColor.White;
    if (correctAnswerCount >= 3 && correctAnswerCount <= 5) return CorrectAnswerColor.Yellow
    if (correctAnswerCount >= 6) return  CorrectAnswerColor.Green;
     return undefined;
    }
  
export const generateTrainerAvailableCurriculums = (trainerUser: TrainerUser) => {
  // ここで指定した法人名以外の場合は指定した教科を非表示にする
  const availableCorporationNames = [
    '2020KEI　大学入学前教育',
    //'河合塾', //コメントアウトを外すことでモックでの法人名'河合塾'での動作確認ができます
  ]
  const curriculumsCodes = [
    'english_basic',
    'math_basic'
  ]
  if (trainerUser.corporation && availableCorporationNames.includes(trainerUser.corporation.name)) {
    return curriculums
  } else {
    return curriculums.filter((curriculum) => {
      return !curriculumsCodes.includes(curriculum.code)
    })
  }
}

export default {
  hasTemporaryForms,
  shouldFixProvisionalPassword,
  shouldFixPaymentInformation,
  getContentTypeLabelByCode,
  getContentMetaObjectByCode,
  getErrorMessage,
  getStudyUnitResultRank,
  getQuestionContentUrl,
  getEntrustCurriculums,
  getLearningDurationFaceString,
  getLearningDurationMessages,
  getCorrectAnswerColorType,
  generateTrainerAvailableCurriculums,
  LearningDurationFace,
  CorrectAnswerColor
}
