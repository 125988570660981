import React, { useEffect } from 'react';
import Moment from 'react-moment'
import { Link, Redirect } from 'react-router-dom';

import PrivateHeader from 'containers/parts/PrivateHeader';
import { mappedProps } from 'containers/common/Infos';
import SiteMain from 'containers/parts/SiteMain';

const Infos: React.FC<mappedProps> = (props: mappedProps) => {
  useEffect(() => {
    props.fetchFirst()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // MARK: 特定コーポレートユーザ利用不可
  if (props.user.type === 'student' && !props.isAvailableStudentInfo) {
    return <Redirect to='/' />;
  }

  return (
    <React.Fragment>
      <PrivateHeader />
      <SiteMain>
        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          <li className="breadcrumbs__item">
            お知らせ
          </li>
        </ol>

        <div className="site-main__main-contents">
          <div className="page-title page-title--information">
            お知らせ
          </div>

          <ul className="information-list information-list--wide">
            {
              props.info.list.records && props.info.list.records.map((record, index) => (
                <li key={index} className="information-list__item">
                  <Link to={`/infos/${record.id}`} className="information-list__title">
                    { record.title }
                  </Link>
                  <div className="information-list__date">
                    <Moment format="YYYY年M月D日">{ record.published_from }</Moment>
                  </div>
                </li>
              ))
            }
          </ul>
          {
            props.info.list.has_next &&
              <div className="more-button">
                <div
                  onClick={(e) => {
                    e.preventDefault()
                    props.fetchNext()
                  }}
                  className="more-button__text">
                  次の10件を表示
                </div>
              </div>
          }
          <div className="back-home">
            <Link to='/' className="back-home__link">マイページへ戻る</Link>
          </div>
        </div>

        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          <li className="breadcrumbs__item">
            お知らせ
          </li>
        </ol>
      </SiteMain>
    </React.Fragment>
  );
}

export default Infos