import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router'

import { AppState } from "store";
import targetComponent from 'components/parent/StudentStudyResultCurriculum';
import { getRecommendedCurriculums }  from 'utils/store'

import studyResultActions from 'actions/studyResultActions';

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch })

const mapStateToProps = (appState: AppState, ownProps: RouteComponentProps) => {
  const studentUser = appState.parent.student!

  return {
    curriculums: getRecommendedCurriculums(studentUser),
    user: appState.session.user!,
    studyResult: appState.studyResult,
    ...ownProps,
  }
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  { dispatch }: ReturnType<typeof mapDispatchToProps>) => {
  return {
    ...stateProps,
    getCurriculum: (id: string | number | undefined) => {
      return stateProps.curriculums.find(x => x.id === Number(id) || x.code === id)
    },
    getStudent: (student_id: number) => {
      const student = stateProps.studyResult.student
      if (student.student_id !== student_id) {
        return null
      }
      return student.record
    },
    getRecord: (student_id: number, curriculum_id: number) => {
      const page = stateProps.studyResult.studyCurriculum
      if (page.curriculum_id !== curriculum_id || page.student_id !== student_id) {
        return null
      }
      return page.record
    },
    fetchData: (student_id: number, curriculum_id: number) => {
      dispatch(studyResultActions.fetchStudent({ student_id }))
      dispatch(studyResultActions.fetchStudyCurriculum({ curriculum_id, student_id }))
    },
  }
}

export type mappedProps = ReturnType<typeof mergeProps>
export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(targetComponent))
