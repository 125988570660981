import React, { useEffect } from 'react';
import classNames from 'classnames';
import { Link, useParams } from 'react-router-dom';
import { mappedProps } from 'containers/trainer/StudentStudyResult';
import CircleChart from '../parts/CircleChart'

const TrainerStudentStudyResult: React.FC<mappedProps> = (props: mappedProps) => {
  const { id } = useParams<{ id: string; }>();

  useEffect(() => {
    if (id) {
      props.fetchData(Number(id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const student = props.getStudent(Number(id))
  const page = student && props.getRecord(student.id)

  return (
    <React.Fragment>
      <div className="study-breadcrumbs">
        <div className="study-breadcrumbs__item">
          教科
        </div>
      </div>

      <div className="page-subtitle">
        学習結果（コンプリート率・学習完了率）
      </div>
      {
        student && 
        <ul className="study-result-list study-result-list--teacher">
          {
            page && page.curriculums
            .filter(x => props.isRecommendCurriculum(x.id, student))
            .map(curriculum => (
              <li key={curriculum.id} className="study-result-list__item">
                <div className="study-result">
                  <div className="study-result__chart">
                    {
                      curriculum.al_engine_connected ?
                      <CircleChart label={curriculum.name} rate={curriculum.understanding / 100 } />:
                      <CircleChart label={curriculum.name} rate={curriculum.understanding / 100 } colorType={2} />
                    }
                  </div>
                  <div className="study-result__button">
                    <Link
                      to={`/students/${student.id}/study_result/${curriculum.id}`}
                      className={classNames("button button--short", {
                      'button--two-line': curriculum.name.length >= 6,
                      })}
                    >
                      { curriculum.name }科目一覧
                    </Link>
                  </div>
                </div>
              </li>
            ))
          }
        </ul>
      }
    </React.Fragment>
  );
}

export default TrainerStudentStudyResult