import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router'

import { AppState } from "store";
import targetComponent from 'components/instructor/StudentStudyResult';
import { curriculums } from 'utils/constants'
import actions from 'actions/studyResultActions';

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch })

const mapStateToProps = (appState: AppState, ownProps: RouteComponentProps) => {
  return {
    curriculums,
    user: appState.session.user!,
    instructor: appState.instructor,
    studyResult: appState.studyResult,
    ...ownProps,
  }
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  { dispatch }: ReturnType<typeof mapDispatchToProps>) => {
  return {
    ...stateProps,
    getStudent: (student_id: number) => {
      const student = stateProps.studyResult.student
      if (student.student_id !== student_id) {
        return null
      }
      return student.record
    },
    fetchData: (student_id: number) => {
      dispatch(actions.fetchStudent({ student_id }))
    },
  }
}

export type mappedProps = ReturnType<typeof mergeProps>
export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(targetComponent))
