import React from 'react';

import { StudyCourseResultsRecord, } from 'models/api';

import { $PropertyType } from 'utility-types';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

type props = {
  result: $PropertyType<$PropertyType<StudyCourseResultsRecord, 'results'>, 0>;
  startStudy: (props: { content_id: number, unit_id: number, type?: 'completion_test' }) => void;
  disabledStartStudy: boolean;
}

export const UnitTestBoxItem: React.FC<props> = ({ result, startStudy, disabledStartStudy }: props) => {
  const history = useHistory()

  return <div className="test-box__item">
    <div className="test-box__unit-title">
      { result.unit.name }
    </div>

    {
      result.unit.rank === null ?
      <div className="test-box__status is-disabled">
        未受験
      </div> :
      <div className={classNames("test-box__status", { "test-box__status--s": result.unit.rank === 'S' })}>
        { result.unit.rank }ランク
      </div>
    }

    {
      result.unit.pretest || result.unit.status === 'complete' ?
      <a className={classNames("test-box__text _pc")} href="#!" onClick={e => {
        e.preventDefault()
        history.push(`/study/unit_results/${result.unit.id}`)
      }}>テスト結果</a>:
      <span className="test-box__text _pc is-disabled">テスト結果</span>
    }

    <span className={classNames("test-box__text test-box__text--pre-test _sp", {
      'is-disabled': !result.unit.pretest,
    })}>
      {
        result.unit.pretest ? '受講前テスト完了' : '受講前テスト未受験'
      }
    </span>

    <div className="test-box__buttons">
      <span className={classNames("test-box__pre-test _pc", {
        'is-disabled': !result.unit.pretest,
      })}>
        {
          result.unit.pretest ? '受講前テスト完了' : '受講前テスト未受験'
        }
      </span>

      {
        result.unit.pretest || result.unit.status === 'complete' ?
        <a className={classNames("test-box__button test-box__button--result _sp")} href="#!" onClick={e => {
          e.preventDefault()
          history.push(`/study/unit_results/${result.unit.id}`)
        }}>テスト結果</a>:
        <span className="test-box__button test-box__button--result _sp is-disabled">テスト結果</span>
      }

      {
        result.unit.status !== 'done' && result.unit.status !== 'complete' &&
        <span className="test-box__button is-disabled">受講後テスト</span>
      }

      {
        ( result.unit.status === 'done' || result.unit.status === 'complete' ) &&
        <a className={classNames("test-box__button", {
          'is-disabled': disabledStartStudy,
        })} href="#!" onClick={e => {
          e.preventDefault()
          if (result.unit.content_id) {
            startStudy({ unit_id: result.unit.id, content_id: result.unit.content_id })
          }
        }}>{
          result.unit.status === 'done' ? 'テストを受ける' : '再テストを受ける'
        }</a>
      }
    </div>
  </div>

}
