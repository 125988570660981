import { call, put, select, takeEvery, } from 'redux-saga/effects'

import * as apiService from 'services/api'
import actions from 'actions/borderResultsActions'
import uiActions from 'actions/uiActions'
import { AppState } from 'store';

function* verifyToken(action: ReturnType<typeof actions.verifyToken>) {
  const state: AppState = yield select()
  const token = action.payload.token

  try {
    if (!token) {
      throw new Error()
    }

    yield put(actions.setVerifyTokenResult({ token, loading: true, error: false }));

    const response: apiService.borderResultsVerificationResponse =  yield call(
      apiService.borderResultsVerification,
      state.repository.api!,
      { token, }
    )

    if (response.data.remaining_time <= 0) {
      throw new Error()
    }

    yield put(actions.setVerifyTokenResult({ token, loading: false, error: false }));
  } catch (e) {
    console.error(e);
    yield put(actions.setVerifyTokenResult({ token: null, loading: false, error: true }));
  }
}

export function* fetchUniversitiesRecords(action: ReturnType<typeof actions.fetchUniversitiesRecord>) {
  const state: AppState = yield select()
  const category = action.payload.category
  const area = action.payload.area

  try {
    const response: apiService.borderResultsUniversitiesResponse = yield call(
      apiService.borderResultsUniversities,
      state.repository.api!,
      {
        category,
        area,
      })
    const record = response.data
    yield put(actions.setUniversitiesRecord({ record }));
    yield put(actions.setFacultiesRecord({ record: null }));
    yield put(actions.setDepartmentsRecord({ record: null }));
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

export function* fetchFacultiesRecords(action: ReturnType<typeof actions.fetchFacultiesRecord>) {
  const state: AppState = yield select()
  const area = action.payload.area
  const university = action.payload.university

  try {
    const response: apiService.borderResultsFacultiesResponse = yield call(
      apiService.borderResultsFaculties,
      state.repository.api!,
      {
        area,
        university,
      })
    const record = response.data

    yield put(actions.setFacultiesRecord({ record }));
    yield put(actions.setDepartmentsRecord({ record: null }));
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

export function* fetchDepartmentsRecords(action: ReturnType<typeof actions.fetchDepartmentsRecord>) {
  const state: AppState = yield select()
  const university = action.payload.university

  const emptyFaclutyCode = state.borderResults.facultyEmptyCode

   // MARK: 'none' or faculty code.
  const faculty = action.payload.faculty === 'none' ? emptyFaclutyCode : action.payload.faculty

  if (!faculty) {
    // 学部、学科ともにresultsが空のパターン
    yield put(actions.setDepartmentsRecord({ record: {
      university,
      faculty: 'none',
      results: [],
    } }));
    return
  }

  try {
    const response: apiService.borderResultsDepartmentsResponse = yield call(
      apiService.borderResultsDepartments,
      state.repository.api!,
      {
        university,
        faculty,
      })
    const record = response.data
    yield put(actions.setDepartmentsRecord({ record }));
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

export function* register(action: ReturnType<typeof actions.register>) {
  const state: AppState = yield select()
  const token = action.payload.params.token
  const values = action.payload.params.answer
  const facultyEmptyCode = state.borderResults.facultyEmptyCode || undefined
  const departmentEmptyCode = state.borderResults.departmentEmptyCode || undefined

  const params = {
    token,
    answer: {
      category: Number(values.category),
      area: values.area,
      university: values.university,
      faculty: values.faculty === 'none' ? facultyEmptyCode : values.faculty,
      department: values.department === 'none' ? departmentEmptyCode : values.department,
      result: Number(values.result),
    },
  }

  try {
    yield call(
      apiService.borderResultsRegister,
        state.repository.api!,
        params);
    action.payload.promises && action.payload.promises.resolve()
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
    action.payload.promises && action.payload.promises.reject(e)
  }
}

function* borderResultsSaga() {
   yield takeEvery(actions.verifyToken, verifyToken);
   yield takeEvery(actions.fetchUniversitiesRecord, fetchUniversitiesRecords);
   yield takeEvery(actions.fetchFacultiesRecord, fetchFacultiesRecords);
   yield takeEvery(actions.fetchDepartmentsRecord, fetchDepartmentsRecords);
   yield takeEvery(actions.register, register);
}

export default borderResultsSaga;
