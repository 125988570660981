import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RouteProps } from 'react-router'
import queryString from 'query-string'

import { AppState } from 'store';
import sessionActiions from 'actions/sessionActions';

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    loadDelegateSession: (token: string) => dispatch(sessionActiions.loadDelegateSession(token)),
  };
}

const mapStateToProps = (appState: AppState, routeProps: RouteProps) => {
  const queryParams = queryString.parse(routeProps.location!.search);

  let token
  if (queryParams.jwt instanceof Array) {
    token = queryParams.jwt[0]
  } else {
    token = queryParams.jwt
  }

  return {
    token,
    session: appState.session,
  }
}

export type mappedProps = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

type ownProps = RouteProps & mappedProps

export const DelegateLogin: React.FC<ownProps> = (props: ownProps) => {
  useEffect(() => {
    if (props.token) {
      props.loadDelegateSession(props.token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.token])

  if (props.session.user) {
    return <Redirect to='/' />;
  }

  return <React.Fragment></React.Fragment>;
}

export default connect(mapStateToProps, mapDispatchToProps)(DelegateLogin)