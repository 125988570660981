import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router'

import { AppState } from "store"
import Nav from 'components/student/SettingPointExchangeNav'
import uiActions from 'actions/uiActions'
import * as Models from 'models/api';
import { getRecommendedCurriculums }  from 'utils/store'

import * as studentMesageSetting from 'utils/campaign/studentMessage'

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setOpenRecommendNav: (payload: boolean) => {
      dispatch(uiActions.setOpenRecommendNav(payload))
    },
    setOpenStudyNav: (payload: boolean) => {
      dispatch(uiActions.setOpenStudyNav(payload))
    },
    setOpenHistoryNav: (payload: boolean) => {
      dispatch(uiActions.setOpenHistoryNav(payload))
    },
  }
}

const mapStateToProps = (appState: AppState, ownProps: RouteComponentProps) => {
  const uiType = appState.ui.type
  const user = appState.session.user
  const sidenav = appState.ui.sidenav
  let studentUser: Models.StudentUser | null = null
  if (uiType === 'student') {
    studentUser = user as Models.StudentUser
  }
  if (uiType === 'parent') {
    // MARK: ログイン時に設定している
    studentUser = appState.parent.student
  }

  const isAvailableStudentMessage = studentUser && studentMesageSetting.isAvailable(studentUser)

  return {
    curriculums: studentUser && getRecommendedCurriculums(studentUser),
    user,
    studentUser,
    uiType,
    sidenav,
    isAvailableStudentMessage,
    ...ownProps,
  }
}

type connectMappedProps = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>
export type mappedProps = connectMappedProps

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Nav))