import React, { useEffect, useState } from 'react';
import { Link, useParams, Redirect, useHistory } from 'react-router-dom';
import classNames from 'classnames';

import PrivateHeader from 'containers/parts/PrivateHeader';
import { mappedProps } from 'containers/student/RecommendStudyPretest';
import SiteMain from 'containers/parts/SiteMain';

const StudentRecommendStudyPretest: React.FC<mappedProps> = (props: mappedProps) => {
  const { curriculum, index } = useParams<{ curriculum: string; index: string; }>();
  const history = useHistory()

  const [showMoreTopics, setShowMoreTopics] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [disabledContents, setDisabledContents] = useState<number[]>([]);
  const isDisabledContent = (contentId: number) => disabledContents.includes(contentId)
  const setDisabledContent = (contentId: number) => {
    if (isDisabledContent(contentId)) { return }
    setDisabledContents(disabledContents.concat([contentId]))
  }
  const unsetDisabledContent = (contentId: number) => {
    setDisabledContents(disabledContents.filter(x => x === contentId))
  }

  const curriculumObj = props.getCurriculum(curriculum)
  const indexNum = Number(index)
  const record = curriculumObj && props.getRecord(curriculumObj.id, indexNum)
  const unitTopics = record && props.getUnitTopics(record.unit.id)

  useEffect(() => {
    if (record) {
      const unit_id = record.unit.id
      props.fetchUnitTopics(unit_id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record]);

  if (!curriculumObj) {
    return (
      <Redirect to="/" />
    )
  }

  if (!record || record.content.meta!.type !== '受講前テスト') {
    return (
      <Redirect to={`/recommends/${curriculum}`} />
    )
  }

  const processStartStudy = (params: { learned: boolean; }) => {
    if (isDisabledContent(record.content.id)) { return }
    setDisabledContent(record.content.id)
    const return_pathname = `/recommends/${curriculum}`
    const rank = record.rank
    props.startStudy({ content_id: record.content.id, return_pathname, rank, ...params }).then((url)  => {
      unsetDisabledContent(record.content.id)
      window.location.href = url
    })
  }

  const processSkipStudy = () => {
    const return_pathname = `/recommends/${curriculum}`
    const rank = record.rank
    props.skipStudy({ content_id: record.content.id, rank }).then(()  => {
      history.push(return_pathname)
    })
  }

  return (
    <>
      <PrivateHeader />
      <SiteMain>
        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          <li className="breadcrumbs__item"> AIおすすめ学習({curriculumObj.name})</li>
        </ol>

        <div className="site-main__main-contents">
          <div className="page-title page-title--recommend">
            { props.user.nick_name }さん専用のAIおすすめ学習
          </div>

          {
            indexNum === 0 ?
            <div className="page-subtitle page-subtitle--recommend">
              もっともおすすめの学習
            </div>:
            <div className="page-subtitle">
              おすすめの学習
            </div>
          }

          <div className="recommend-header">
            <div className="recommend-header__title">
              <div>
                { curriculumObj.name }
              </div>
              <div className="recommend-header__badge">
                { record.unit.course_name }
              </div>
              <div className="recommend-header__subject">
                { record.unit.name }

                <span className="recommend-header__pre-test">単元受講前テスト</span>
              </div>
            </div>
          </div>

          {
            unitTopics &&
            <dl className="recommend-pre-test-topic">
              <dt className="recommend-pre-test-topic__head">
                主なトピック
              </dt>
              <dd className={classNames("recommend-pre-test-topic__body", {
                'is-truncated': !showMoreTopics,
              })}>
                { unitTopics.map(x => x.title).join(" / ") }
              </dd>
              {
                !showMoreTopics &&
                <dd className="recommend-pre-test-topic__more" onClick={e => {
                  e.preventDefault()
                  setShowMoreTopics(true)
                }}>
                  すべてのトピックを表示
                </dd>
              }
            </dl>
          }

          <dl className="recommend-pre-test-notes">
            <dt className="recommend-pre-test-notes__head">
              【単元受講前テストの注意】
            </dt>
            <dt className="recommend-pre-test-notes__body">
              <ol className="recommend-pre-test-notes__items">
                <li className="recommend-pre-test-notes__item">
                  新しい単元に入る前に、いまのあなたの学習状況や理解レベルをチェックします。テスト結果によって、AIが、あなた専用の学習をおすすめします。
                </li>
                <li className="recommend-pre-test-notes__item">
                  学校、塾、予備校などの授業でこの単元の学習を既に終えている場合は、「既習」を選択し、テストを受験してください。また「未習」でも、テストにチャレンジすることで理解レベルをAIに反映することができます。
                </li>
                <li className="recommend-pre-test-notes__item">
                  テストをスキップすると、この単元は未習の理解度初期レベル設定となり、一からの ステップ学習となります。また、受講前テストの結果は０点扱いとなります。
                </li>
                <li className="recommend-pre-test-notes__item">
                  受講前テストの所要時間は15〜20分程度です。時間がなく、あとで受験する場合は、「いまはテストを受けない」ボタンを押してください。
                </li>
              </ol>
            </dt>
          </dl>

          <div className="recommend-pre-test-buttons">
            <a
              href="#!"
              className={
                classNames(
                  "recommend-pre-test-buttons__button button button--large button--primary",
                  { "is-disabled": isDisabledContent(record.content.id) }
                )
              }
              onClick={(e) => {
                e.preventDefault();
                processStartStudy({ learned: true })
              }}
            >
              既習範囲なので<br className="_pc" /><strong>テストにチャレンジ</strong>
              {
                !props.user.corporation &&
                <div className="recommend-pre-test-buttons__points recommend-pre-test-buttons__points--primary">
                  学習ポイント<b>3pt</b>
                </div>
              }
            </a>

            <a
              href="#!"
              className={
                classNames(
                  "recommend-pre-test-buttons__button button button--large",
                  { "is-disabled": isDisabledContent(record.content.id) }
                )
              }
              onClick={(e) => {
                e.preventDefault();
                processStartStudy({ learned: false })
              }}
            >
              未習範囲だが<br className="_pc" /><strong>テストにチャレンジ</strong>
              {
                !props.user.corporation &&
                <div className="recommend-pre-test-buttons__points">
                  学習ポイント<b>3pt</b>
                </div>
              }
            </a>

            <span
              className="recommend-pre-test-buttons__button button button--large"
              onClick={e => setShowModal(true) }
            >未習範囲なので<br className="_pc" /><strong>テストをスキップ</strong>
              {
                !props.user.corporation &&
                <div className="recommend-pre-test-buttons__points">
                  学習ポイント<span>0pt</span>
                </div>
              }
            </span>
            
            <Link
              to={`/recommends/${curriculum}`}
              className="recommend-pre-test-buttons__skip-button button button--large button--gray">
              いまはテストを受けない
            </Link>
          </div>

          <div className={classNames("pretest-modal", {
            'is-active': showModal,
          })}>
            <div className="pretest-modal__overlay">
              <div className="pretest-modal__flex">
                <div className="pretest-modal__body">
                  <div className="pretest-modal__lead">
                    受講前テストをスキップします。
                  </div>
                  <div className="pretest-modal__text">
                    受講前テストをスキップした場合、テスト結果には０点として表記されます。<br />
                    { !props.user.corporation && 'またポイントも加算されません。' }
                  </div>
                  <div className="pretest-modal__button">
                    <button
                      className="button button--large button--primary"
                      onClick={e => {
                        e.preventDefault()
                        setShowModal(false)
                        processSkipStudy()
                      }}
                    >
                      テストをスキップする</button>
                  </div>
                  <div className="pretest-modal__button">
                    <button className="button button--large button--no-chevron" onClick={e => setShowModal(false) }>スキップを止める</button>
                  </div>
                  <div className="pretest-modal__close" onClick={e => setShowModal(false) }></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          <li className="breadcrumbs__item">
            AIおすすめ学習({curriculumObj.name})
          </li>
        </ol>
      </SiteMain>
    </>
  );
}

export default StudentRecommendStudyPretest
