import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import PrivateHeader from "containers/parts/PrivateHeader";
import { mappedProps } from "containers/trainer/StudyUnit";
import SiteMain from "containers/parts/SiteMain";
import classNames from "classnames";

const StudyUnit: React.FC<mappedProps> = (props: mappedProps) => {
  const { unit_id } = useParams<{ unit_id: string }>();

  const [disableDownload, setDisableDownload] = useState(false);
  useEffect(() => {
    if (unit_id) {
      props.fetchData(Number(unit_id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unit_id]);

  const page = props.getRecord(Number(unit_id));

  const downloadPdf = (topicId: number) => {
    props.fetchContentData(topicId);
    setDisableDownload(true);
  };
  const studyTopic = props.trainer.studyTopic.record;

  useEffect(() => {
    const curriculumCodesArray =
      studyTopic &&
      studyTopic.contents.map(
        (content) => content.launch_parameters.curriculum_code,
      );
    if (
      curriculumCodesArray &&
      curriculumCodesArray.length > 0 &&
      disableDownload
    ) {
      props.pdfDownload(curriculumCodesArray)
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studyTopic]);

  const downloadAllPdf = () => {
    const allTopicIds = page && page.topics.map((topic) => topic.id);
    if (allTopicIds) {
      props.downloadAllTopicsPdf(allTopicIds);    
    }
  }

  return (
    <React.Fragment>
      <PrivateHeader />
      <SiteMain>
        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to="/" className="breadcrumbs__link">
              マイページ
            </Link>
          </li>
          {page && (
            <React.Fragment>
              <li className="breadcrumbs__item">
                <Link
                  to={`/study/${page.curriculum.id}`}
                  className="breadcrumbs__link"
                >
                  {page.curriculum.name}
                </Link>
              </li>
              <li className="breadcrumbs__item">
                <Link
                  to={`/study/courses/${page.course.id}`}
                  className="breadcrumbs__link"
                >
                  {page.course.name}
                </Link>
              </li>
              <li className="breadcrumbs__item">{page.unit.name}</li>
            </React.Fragment>
          )}
        </ol>

        {page && (
          <div className="site-main__main-contents">
            <div className="page-title">
              {page.curriculum.name}
              &nbsp;
              <div className="page-title__badge">{page.course.name}</div>
              &nbsp;
              <br className="_sp" />
              {page.unit.name}
            </div>

            {page.curriculum.al_engine_connected && (
              <div className="suggestion-box">
                AIがおすすめするカリキュラムを進めていくとテストの受講が可能です。
                「
                <Link to={`/recommends/${page.curriculum.id}`} className="">
                  もっともおすすめ学習
                </Link>
                」からご確認ください。
              </div>
            )}

            <div className="topic-list">
              <div className="topic-list__head">
                <div className="topic-list__title">
                  “{page.unit.name}”のトピック
                </div>
                {/* {
                  page.curriculum.al_engine_connected &&
                  <div className="topic-list__head-note">
                    <div
                      onClick={() => setShowHint(!showHint)}
                      className="">
                      AI理解度判定について
                    </div>

                    <div
                      className={classNames("balloon topic-list__balloon", { 'is-active': showHint })}>
                      <div
                        onClick={() => setShowHint(false)}
                        className="balloon__close">
                        説明を閉じる
                      </div>
                      <div className="balloon__title">
                        AI理解度判定とは？
                      </div>
                      <div className="balloon__lead">
                        AI理解度判定とは、それぞれのトピックについてどの程度理解をしているのかを、AIが判定した結果です。
                      </div>
                      <dl className="balloon__list">
                        <dt className="balloon__list-title">
                          コンプリート
                        </dt>
                        <dd className="balloon__list-body">
                          教科書や大学入学共通テストのレベルに対して十分な理解度に達している。
                        </dd>
                        <dt className="balloon__list-title">
                          レベル３
                        </dt>
                        <dd className="balloon__list-body">
                          教科書や大学入学共通テストのレベルに対して７０％以上９５％未満の理解度。
                        </dd>
                        <dt className="balloon__list-title">
                          レベル２
                        </dt>
                        <dd className="balloon__list-body">
                          教科書や大学入学共通テストのレベルに対して５０％以上７０％未満の理解度。
                        </dd>
                        <dt className="balloon__list-title">
                          レベル１
                        </dt>
                        <dd className="balloon__list-body">
                          教科書や大学入学共通テストのレベルに対して５０％未満の理解度。
                        </dd>
                      </dl>
                    </div>
                  </div>
                } */}
                {/* {
                  !page.curriculum.al_engine_connected &&
                  <div className="topic-list__head-note">
                    <div
                      onClick={() => setShowHint(!showHint)}
                      className="">
                      学習状況とは？
                    </div>

                    <div
                      className={classNames("balloon topic-list__balloon", { 'is-active': showHint })}>
                      <div
                        onClick={() => setShowHint(false)}
                        className="balloon__close">
                        説明を閉じる
                      </div>
                      <div className="balloon__title">
                        学習状況とは？
                      </div>
                      <div className="balloon__lead">
                        それぞれのコンテンツについて、学習進捗を表記しています。
                      </div>
                      <dl className="balloon__list">
                        <dt className="balloon__list-title">
                          学習終了
                        </dt>
                        <dd className="balloon__list-body">
                          コンテンツの教材の学習を終了しています。
                        </dd>
                        <dt className="balloon__list-title">
                          学習中
                        </dt>
                        <dd className="balloon__list-body">
                          コンテンツの教材の学習を開始し、学習中です。
                        </dd>
                        <dt className="balloon__list-title">
                          未学習
                        </dt>
                        <dd className="balloon__list-body">
                          コンテンツの教材の学習を開始していません。
                        </dd>
                      </dl>
                    </div>
                  </div>
                } */}
              </div>
              <div className="topic-list__pdf-download-button topic-list__pdf-download-button--all">
                {page.topics.some((x) => x.pdf_ready === true) &&
                  <button
                    className="pdf-download-button"
                    onClick={downloadAllPdf}>
                    まとめてダウンロード (PDF)
                    <div className="pdf-download-button__icon" />
                  </button>}
              </div>
              <div className="topic-list__body">
                {page.topics.map((topic, index) => (
                  <div key={index} className="topic-list__item">
                    <Link
                      to={`/study/topics/${topic.id}`}
                      className="topic-list__topic-name"
                    >
                      {topic.title}
                    </Link>
                    {topic.check_level && (
                      <div className="topic-list__phase">
                        AI理解度判定
                        <div
                          className={classNames("topic-list__level", {
                            "topic-list__level--complete":
                              topic.check_level === "コンプリート",
                          })}
                        >
                          {topic.check_level}
                        </div>
                      </div>
                    )}
                    <div className="topic-list__phase-container-trainer">
                      {/* {
                        topic.status &&
                        <div className="topic-list__phase">
                          学習状況
                          <div className={classNames("topic-list__level", {  "topic-list__level--complete" : topic.status === 'complete' })}>
                            { topic.status === 'none' && '未学習' }
                            { topic.status === 'progress' && '学習中' }
                            { topic.status === 'complete' && '学習終了' }
                          </div>
                        </div>
                      } */}
                      { topic.pdf_ready &&
                        <div className="topic-list__pdf-download-button">
                          <button
                            disabled={!topic.pdf_ready}
                            onClick={()=> downloadPdf(topic.id)}
                            className="pdf-download-button">
                            PDF
                            <div className="pdf-download-button__icon" />
                          </button>
                        </div>
                      }
                    </div>
                  </div>
                ))}
              </div>
              {
                // MARK: 教科：数学、科目：数I、単元：データの分析
                page.unit.id === 5 && (
                  <div
                    style={{
                      marginTop: 16,
                      color: "#666",
                      fontSize: 14,
                      textAlign: "right",
                    }}
                  >
                    <p style={{ marginBottom: 6 }}>
                      新課程の「外れ値」は「4-2:四分位数と箱ひげ図」、「仮説検定」は「4-5:散布図と相互係数」に含みます。
                    </p>
                  </div>
                )
              }
              {
                // MARK: 教科：数学、科目：数A、単元：場合の数と確率
                page.unit.id === 6 && (
                  <div
                    style={{
                      marginTop: 16,
                      color: "#666",
                      fontSize: 14,
                      textAlign: "right",
                    }}
                  >
                    <p style={{ marginBottom: 6 }}>
                      新課程の方は「数学Ａ　場合の数と確率　5-14：条件付き確率」を終えたら、
                      <br />
                      教科・単元選択学習から「新課程　数学Ａ　期待値」を選び学習を進めてください。
                    </p>
                  </div>
                )
              }
              {
                // MARK: 教科：新課程数学、科目：数A、単元：場合の数と確率
                page.unit.id === 238 && (
                  <div
                    style={{
                      marginTop: 16,
                      color: "#666",
                      fontSize: 14,
                      textAlign: "right",
                    }}
                  >
                    <p style={{ marginBottom: 6 }}>
                      「新課程　数学Ａ　期待値」を終えたら、
                      <br />「
                      <Link to={"/study/units/7"}>数学Ａ　整数の性質</Link>
                      」単元を選び学習を進めてください。
                    </p>
                  </div>
                )
              }
            </div>
            <div className="back-home">
              <Link to="/" className="back-home__link">
                マイページへ戻る
              </Link>
            </div>
          </div>
        )}

        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to="/" className="breadcrumbs__link">
              マイページ
            </Link>
          </li>
          {page && (
            <React.Fragment>
              <li className="breadcrumbs__item">
                <Link
                  to={`/study/${page.curriculum.id}`}
                  className="breadcrumbs__link"
                >
                  {page.curriculum.name}
                </Link>
              </li>
              <li className="breadcrumbs__item">
                <Link
                  to={`/study/courses/${page.course.id}`}
                  className="breadcrumbs__link"
                >
                  {page.course.name}
                </Link>
              </li>
              <li className="breadcrumbs__item">{page.unit.name}</li>
            </React.Fragment>
          )}
        </ol>
      </SiteMain>
    </React.Fragment>
  );
};

export default StudyUnit;
