import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Field } from 'redux-form'

import { mappedProps } from 'containers/common/PasswordReset';
import PublicHeader from '../parts/PublicHeader';
import SiteMainSimple  from '../parts/SiteMainSimple';

const PasswordReset: React.FC<mappedProps> = (props: mappedProps) => {
  if (!props.token) {
    return <Redirect to='/' />;
  }

  const resultfragment = (
    <div className="account-box">
      <div className="account-box__title">
        ログインパスワードの再設定
      </div>
      <div className="account-box__lead">
        パスワードの再設定が完了しました。<br />新しいパスワードでログインをしてください。
      </div>
      <div className="account-box__button">
        <Link to='/login' className="button button--large">ログイン画面へ戻る</Link>
      </div>
    </div>
  );

  const formFragment = (
    <div className="account-box">
      <div className="account-box__title">
        ログインパスワードの再設定
      </div>
      <div className="account-box__form">
        <form onSubmit={props.handleSubmit}>
          <div className="account-form">
            <div className="account-form__item">
              <label className="account-form__label">新しいパスワード</label>
              <Field
                component="input"
                className="input input--required account-form__input"
                type="password"
                name="password"
              />
            </div>
            <div className="account-form__item">
              <label className="account-form__label">新しいパスワード（確認）</label>
              <Field
                component="input"
                className="input input--required account-form__input"
                type="password"
                name="passwordConfirmation"
              />
            </div>
            {
              !props.pristine && props.invalid &&
                <div className="account-form__error">
                  <div className="error-message">
                    パスワードが一致しません。再度入力してください。
                  </div>
                </div>
            }
            <div className="account-form__button account-form__button--large">
              <button
                disabled={props.pristine || props.submitting || props.invalid}
                type="submit"
                className="button button--primary button--large">パスワードを登録</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )

  return (
    <React.Fragment>
      <PublicHeader />
      <SiteMainSimple>
        { props.submitSucceeded ? resultfragment : formFragment }
      </SiteMainSimple>
    </React.Fragment>
  );
}

export default PasswordReset
