import React from 'react';
import classNames from 'classnames';

type props = {
  show: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

export const CourseCompletionTestConfirmModal: React.FC<props> = ({ show, onClose, onSubmit }: props) => {
  return (
    <div className={classNames("pretest-modal", {
      'is-active': show,
    })}>
      <div className="pretest-modal__overlay">
        <div className="pretest-modal__flex">
          <div className="pretest-modal__body">
            <div className="pretest-modal__lead">
              科目修了テスト　受験上の注意
            </div>
            <div className="pretest-modal__text">
              解答には以下のように一定時間がかかるため、まとまった時間があるときの受験をおすすめします。以下が標準解答時間です。
            </div>
            <table className="pretest-modal__table">
              <tbody><tr>
                <th>
                  英文法、数学Ｉ、数学II、数学Ｂ、化学基礎、物理基礎、生物基礎、古文文法
                </th>
                <td>
                  30分程度
                </td>
              </tr>
              <tr>
                <th>
                  数学A
                </th>
                <td>
                  45分程度
                </td>
              </tr>
              <tr>
                <th>
                  数学III、化学、物理、世界史、日本史
                </th>
                <td>
                  60分程度
                </td>
              </tr>
            </tbody></table>
            <div className="pretest-modal__button">
              <button className="button button--large button--primary" onClick={e => {
                e.preventDefault()
                onSubmit()
              }}>科目修了テストを受ける</button>
            </div>
            <div className="pretest-modal__button">
              <button className="button button--large button--no-chevron" onClick={e => {
                e.preventDefault()
                onClose()
              }}>いまはテストを受けず、別の機会にする</button>
            </div>
            <div className="pretest-modal__close" onClick={e => onClose()}></div>
          </div>
        </div>
      </div>
    </div>
  )
}
