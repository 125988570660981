import React from "react";
import { Route, Redirect } from "react-router-dom";
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RouteProps } from 'react-router'

import { AppState } from 'store';
import storeUtils from 'utils/store'

type requiredProps = RouteProps & mappedProps

const PrivateRoute: React.FC<requiredProps> = (props: requiredProps) => {
  const { component, children, ...rest } = props

  const isAuthenticated = props.user !== null

  const shouldFixProvisionalPassword = 
    storeUtils.shouldFixProvisionalPassword(props.ui, props.user);

  const shouldFixPaymentInformation  =
    storeUtils.shouldFixPaymentInformation(props.ui, props.user);

  const renderNextRoute = (routeProps: RouteProps) => {
    if (!isAuthenticated) {
      return <Redirect to={{
          pathname: "/login",
          state: { from: routeProps.location }
        }}
      />
    }

    if (shouldFixProvisionalPassword) {
      return <Redirect to={{
        pathname: "/fix_provisional_password",
        state: { from: routeProps.location }
      }} />
    }

    if (shouldFixPaymentInformation) {
      // MARK: /fix_payment_information でカード入力サイトにredirectした後 /pay_confirmに戻ってくる
      if (!routeProps.location || routeProps.location.pathname !== '/pay_confirm') {
        return <Redirect to={{ 
          pathname: "/fix_payment_information",
          state: { from: routeProps.location }
        }} />
      }
    }

    return <Route {...props} />
  }

  return <Route {...rest} render={renderNextRoute} />
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
  };
}

const mapStateToProps = (appState: AppState) => {
  return {
    ...appState.session,
    ui: appState.ui,
  }
}

export type mappedProps = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
