import React, { useEffect, useState } from 'react';
import { Link, useParams, /* Redirect, */ useHistory } from 'react-router-dom';
import classNames from 'classnames';
import useTimeout from 'use-timeout'

import PrivateHeader from 'containers/parts/PrivateHeader';
import { mappedProps } from 'containers/student/RecommendStudies';
import SiteMain from 'containers/parts/SiteMain';
import { normalContentTypes, preOrPostContentTypes } from 'utils/constants'
import { CourseCompletionTestConfirmModal } from 'components/student/common/CourseCompletionTestConfirmModal';
import { RecommendStudy2, StudentStudyLearningCountRecord }  from '../../models/api';
import CorrectAnswerCount from 'components/student/common/CorrectAnswerCount';

const StudentRecommendStudyIndex: React.FC<mappedProps> = (props: mappedProps) => {
  const { curriculum } = useParams<{ curriculum: string; }>();
  const history = useHistory()

  useEffect(() => {
    props.fetchStudyAchievementsLatest();
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  , []);
  
  const [showConfirm, setShowConfirm] = useState(false)
  const [selectedContent, setSelectedContent] = useState<{ content_id: number; rank:number; } | null>(null)

  const [disabledContents, setDisabledContents] = useState<number[]>([]);
  const isDisabledContent = (contentId: number) => disabledContents.includes(contentId)
  const setDisabledContent = (contentId: number) => {
    if (isDisabledContent(contentId)) { return }
    setDisabledContents(disabledContents.concat([contentId]))
  }
  const unsetDisabledContent = (contentId: number) => {
    setDisabledContents(disabledContents.filter(x => x === contentId))
  }

  const [showGraph, setShowGraph] = useState<boolean>(false)

  useTimeout(() => setShowGraph(true), 500)

  const curriculumObj = props.getCurriculum(curriculum)

  useEffect(() => {
    if (curriculumObj) {
      props.fetchData(curriculumObj.id).catch(error => {
        // MARK: 取得リクエストでエラーになった場合にTOPにリダイレクトする
        history.push('/')
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curriculum]);

  useEffect(() => {
    if (!curriculumObj ){
      history.push('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curriculumObj])

// useEffectに切り替えたのでコメントアウト
 /*  if (!curriculumObj) {
    return (
      <Redirect to="/" />
    )
  } */

  const [curriculumName, setCurriculumName] = useState<string | null>(null)
  useEffect(() => {
    if (curriculumObj) {
    setCurriculumName(curriculumObj.name)
    }
  }, [curriculumObj])

  const [records, setRecords] = useState<RecommendStudy2[] | null>(null);
  useEffect(() => {
    if (curriculumObj) {
      const records = props.getRecords(curriculumObj.id)
      if (records) {
        setRecords(records)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curriculumObj, props.getRecords]);

  // カリキュラムコードの取得
  const [curriculumCodes, setCurriculumCodes] = useState<string[] | null>(null)
  useEffect(() => {
    if (records) {
      const curriculumCodes = records.map((x: any )=> x.content.curriculum_code)
      if (curriculumCodes) {
        setCurriculumCodes(curriculumCodes)
      }
    }
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  , [records, /* props.getLearningCount */])

  // 学習回数の取得
  const [learningCount, setLearningCount]  = useState<StudentStudyLearningCountRecord[]| null>(null);
  useEffect(() => {
    if (curriculumCodes) {
       props.getLearningCount({curriculumCodes}).then(
        (learningCount: any) => {
          setLearningCount(learningCount)
        }
      )
    }
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  , [curriculumCodes, /* props.getLearningCount */])

  // learning_countの入った新しい配列を作る
  const [recordsWithCount , setRecordsWithCount] = useState<RecommendStudy2[] | null>(null);
  useEffect(() => {
    if (records && learningCount) {
      let newPageWithCount: RecommendStudy2[] = [...records]
   newPageWithCount = records.map((x: any) => {
       const learningCountRecord = learningCount.find((y: any) => y.curriculum_code === x.content.curriculum_code)
        if (learningCountRecord){
         x.content.learning_count = learningCountRecord.learning_count
        } else {
          x.content.learning_count = 0
        }
        return x
      }
     )
      setRecordsWithCount(newPageWithCount)
    }
  }
  , [records, learningCount])

  const startStudy = ({ rank, content_id, }: { content_id: number, rank: number; }) => {
    if (isDisabledContent(content_id)) { return }
    setDisabledContent(content_id)

    const return_pathname = history.location.pathname
    props.startStudy({ content_id, return_pathname, rank }).then((url)  => {
      unsetDisabledContent(content_id)
      window.location.href = url
    })
  }

  const downloadPdf = (curriculumCode : string) => {
    const curriculumCodesArray = [curriculumCode]
    props.pdfDownload(curriculumCodesArray)
  };

  return (
    <React.Fragment>
      <CourseCompletionTestConfirmModal
        show={showConfirm && !!selectedContent}
        onSubmit={() => {
          startStudy({
            content_id: selectedContent!.content_id,
            rank: selectedContent!.rank,
          })
        }}
        onClose={() => {
          setShowConfirm(false)
          setSelectedContent(null)
        }} />
      <PrivateHeader />
      <SiteMain>
        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          <li className="breadcrumbs__item"> AIおすすめ学習({curriculumName})</li>
        </ol>

        <div className="site-main__main-contents">
          <div className="page-title page-title--recommend">
            { props.user.nick_name }さん専用のAIおすすめ学習
            {props.student.studyAchievementsLatest.record && <>
            <div className="page-title__correct-count">
            <CorrectAnswerCount  correctAnswerCount={props.student.studyAchievementsLatest.record.study_achievements.consecutive_correct_answer_count}/>
            </div>
          </>}
          </div>
          {
            recordsWithCount && recordsWithCount.length === 0 &&
              <React.Fragment>
                <div className="page-subtitle page-subtitle--recommend">
                  もっともおすすめの学習
                </div>
                <div className="topic-box topic-box--recommend">
                  <div className="topic-box__subject">
                    <small>{ curriculumName }</small>
                  </div>
                  <div className="topic-box__title">
                    「教科・単元選択学習」から学習したい教材を選んで、学習してください。AIが学習したトピックをスタート地点として、新たにあなた専用の学習をおすすめしてくれます。
                  </div>
                  <div className="topic-box__type">
                    &nbsp;
                  </div>
                  <div className="topic-box__button">
                    <Link to={`/study/${curriculumObj && curriculumObj.id}`} className="button button--primary">
                      教科・単元選択学習へ
                    </Link>
                  </div>
                </div>
              </React.Fragment>
          }
          {
            recordsWithCount && recordsWithCount.map((record, index) => (
              <React.Fragment key={index}>
                {
                  index === 0 &&
                  <>
                    <div className="page-subtitle page-subtitle--recommend">
                      もっともおすすめの学習
                    </div>
                    <div className="recommend-header">
                      <div className="recommend-header__title">
                        <div>
                          { curriculumName }
                        </div>
                        <div className="recommend-header__badge">
                          {record &&  record.unit.course_name }
                        </div>
                        {
                          record.content.meta!.type !== '科目修了テスト' &&
                          <div className="recommend-header__subject">
                            { record.unit.name }
                            <div className="recommend-progress-bar">
                              <div className="recommend-progress-bar__bar" style={{ width: showGraph ? record.unit.understanding + '%' : 0 }}></div>
                              <div className="recommend-progress-bar__text">
                                { Math.floor(record.unit.understanding) }%
                              </div>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                    <dl className="recommend-status-list">
                      {
                        record.recently_learning_status &&
                        <>
                          <dt className="recommend-status-list__head">
                            単元
                          </dt>
                          <dd className="recommend-status-list__body">
                            <ul className="recommend-status-list__items">
                              {record &&
                                record.recently_learning_status.units.map((x: any) => (
                                  <React.Fragment key={x.name}>
                                    <li className={classNames("recommend-status-list__item", { 'is-active' : x.currently_recommend })} title={x.name}>
                                      { x.shortName }
                                    </li>
                                    &nbsp;
                                  </React.Fragment >
                                ))
                              }
                            </ul>
                          </dd>
                          <dt className="recommend-status-list__head">
                            トピック
                          </dt>
                          <dd className="recommend-status-list__body">
                            <ul className="recommend-status-list__items">
                              {record &&
                                record.recently_learning_status.topics.map((x: any) => (
                                  <React.Fragment key={x.title}>
                                    <li key={x.title} className={classNames("recommend-status-list__item", { 'is-active' : x.currently_recommend })} title={x.title}>
                                      { x.shortTitle }
                                    </li>
                                    &nbsp;
                                  </React.Fragment>
                                ))
                              }
                            </ul>
                          </dd>
                        </>
                      }

                      {
                        record.content.meta!.type !== '科目修了テスト' &&
                        <>
                          <dt className="recommend-status-list__head">
                            教材
                          </dt>
                          <dd className="recommend-status-list__body">
                            {
                              preOrPostContentTypes.includes(record.content.meta!.type) &&
                              <ul className="recommend-status-list__items">
                                {
                                  preOrPostContentTypes.map(x => (
                                    <li className={classNames("recommend-status-list__item", { 'is-active' : record.content.meta!.type === x })} key={x}>
                                      { x }
                                    </li>
                                  ))
                                }
                              </ul>
                            }
                            {
                              normalContentTypes.includes(record.content.meta!.type) &&
                              <ul className="recommend-status-list__items">
                                {
                                  normalContentTypes.map(x => (
                                    <li className={classNames("recommend-status-list__item", { 'is-active' : record.content.meta!.type === x })} key={x}>
                                      { x }
                                    </li>
                                  ))
                                }
                              </ul>
                            }
                          </dd>
                        </>
                      }

                    </dl>
                  </>
                }

                {
                  index === 1 &&
                  <div className="page-subtitle">
                    おすすめの学習
                  </div>
                }

                <div className={classNames(
                  "topic-box",
                  { "topic-box--recommend": index === 0 },
                  { "topic-box--many":  record.content.learning_count !== 0 && index !== 0 },
                  )}>
                  <div className="topic-box__subject">
                    <small>{ curriculumName }</small>
                    {
                      record.content.meta!.type !== '科目修了テスト'  &&
                      <>
                        &nbsp;
                        { record.unit.name }
                      </>
                    }
                  </div>

                  <div className="topic-box__title">
                    { record.topic.description }
                  </div>

                  {
                    record.content.meta!.type !== '科目修了テスト'  &&
                    <div className="topic-box__type">
                      { record.content.meta!.label }
                    </div>
                  }
                  <div className='topic-box__learning_counts_container'>
                    <div className={classNames(
                       "topic-box__learning_counts",
                        {"topic-box__learning_counts--many":  record.content.learning_count !== 0 }
                        )
                      } >
                    学習回数{
                    record.content.learning_count ?
                    ' ' + record.content.learning_count.toString() + '' :
                     ' 0' }回
                    </div>
                  </div>
                  <div className="topic-box__button">
                    {
                      record.content.meta!.type === '受講前テスト' ?
                      <Link
                        to={`/recommends/${curriculum}/${index}`}
                        className="button button--primary"
                      >
                        AIおすすめ学習を始める
                      </Link>:
                      <a
                        onClick={(e) => {
                          e.preventDefault();

                          if (record.content.meta!.type === '科目修了テスト') {
                            setSelectedContent({ content_id: record.content.id, rank: record.rank })
                            setShowConfirm(true)
                          } else {
                            startStudy({ content_id: record.content.id, rank: record.rank })
                          }
                        }}
                        className={
                          classNames(
                            "button button--primary",
                            { "is-disabled": isDisabledContent(record.content.id) }
                          )
                        }
                        href="#!">AIおすすめ学習を始める</a>
                    }
                  </div>
                  { record.content.pdf_ready && 
                  <div className="topic-box__pdf-download-button-container">
                    <button
                      disabled={!record.content.pdf_ready}
                      onClick={()=> downloadPdf(record.content.curriculum_code)}
                      className="pdf-download-button"
                    >
                      PDF
                      <div className="pdf-download-button__icon" />
                    </button>
                  </div>
                  }
                </div>
              </React.Fragment>
            ))
          }
        </div>

        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          <li className="breadcrumbs__item">
            AIおすすめ学習({curriculumName})
          </li>
        </ol>
      </SiteMain>
    </React.Fragment>
  );
}

export default StudentRecommendStudyIndex
