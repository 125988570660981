import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { mappedProps } from 'containers/trainer/StudentSchedules';

const TrainerStudentSchedules: React.FC<mappedProps> = (props: mappedProps) => {
  const { id } = useParams();

  useEffect(() => {
    props.fetchData(Number(id))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const schedules = props.getSchedules(Number(id))
  const hasNext = props.hasNextSchedule(Number(id))

  return (
    <>
      <div className="schedule-box">
        <div className="schedule-box__title">
          <div className="page-title page-title--no-border page-title--schedule">
            スケジュール
          </div>
        </div>
        <div className="schedule-box__body">
          <ul className="schedule-list schedule-list--trainer">
            {
              schedules && schedules.map((schedule, index) => (
                <li key={index} className="schedule-list__item">
                  <div className="schedule-list__date">
                    { schedule.date }〜
                  </div>
                  <div className="schedule-list__text">
                    { schedule.body }
                  </div>
                </li>
              ))
            }
          </ul>
        </div>

        {
          hasNext && 
          <div className="more-button">
            <div
              onClick={(e) => {
                e.preventDefault()
                props.fetchNext()
              }}
              className="more-button__text">
              次の10件を表示
            </div>
          </div>
        }
      </div>
    </>
  );
}

export default TrainerStudentSchedules
