import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom';
import Moment from 'react-moment';
import classNames from 'classnames';
import * as Models from 'models/api';
import PrivateHeader from 'containers/parts/PrivateHeader';
import SiteMain from 'containers/parts/SiteMain';
import { mappedProps } from 'containers/student/Bookmark';
import { curriculums} from '../../utils/constants';

const Bookmark: React.FC<mappedProps> = (props: mappedProps) => {

  type selectOptionType = {
    curriculum_id: number,
    curriculum_name: string,
  }
  const history = useHistory()

  const [disabledContents, setDisabledContents] = useState<number[]>([]);
  const isDisabledContent = (contentId: number) => disabledContents.includes(contentId)
  const setDisabledContent = (contentId: number) => {
    if (isDisabledContent(contentId)) { return }
    setDisabledContents(disabledContents.concat([contentId]))
  }
  const unsetDisabledContent = (contentId: number) => {
    setDisabledContents(disabledContents.filter(x => x === contentId))
  }

  const bookmarksState = props.student.bookmarks;
  const bookmarksLearningCount = props.student.bookmarksLearningCount;

  useEffect(() => {
    props.getBookmarks()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // allBookmarksDataにLearning countsデータを追加
  const [allBookmarksData, setAllBookmarksData] = useState<Models.Bookmarks | null>(null)
  const [bookmarksToShow, setBookmarksToShow] = useState<Models.BookmarksWithMeta | null>(null)
  const [bookmarksLearningCountArray, setBookmarksLearningCountArray] = useState<any>(null)

  const initialOption = { curriculum_name: "全教科", curriculum_id: 0 }
  const [selectOptions, setSelectOptions] = useState<selectOptionType[]>([])
  const [selectedCurriculum, setSelectedCurriculum] = useState<selectOptionType>(initialOption)
  const [deleteSelected, setDeleteSelected] = useState<number[]>([]);
  const [showDeleteButton, setShowDeleteButton] = useState<boolean>(true)

  const [showDropDown, setShowDropDown] = useState<boolean>(false);

  useEffect(() => {
    if (bookmarksState.records) {
      setAllBookmarksData(bookmarksState.records)
    }

    setBookmarksToShow(bookmarksState.records)
  }, [bookmarksState])

  useEffect(() => {
    if (bookmarksLearningCount.records) {
      setBookmarksLearningCountArray(bookmarksLearningCount.records)
    }
  }, [bookmarksLearningCount])

  // オプション表示用の配列を作る処理
  useEffect(() => {
    let newPullDownArray: selectOptionType[] = [initialOption];
    props.availableCurriculums.forEach((curriculum) => {
      const newSelectOptionObject = { curriculum_name: curriculum.name, curriculum_id: curriculum.id }
      newPullDownArray.push(newSelectOptionObject)
    })
    setSelectOptions(newPullDownArray)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curriculums])

  // ブックマークを削除ボタンを押したときの表示処理
  useEffect(() => {
    if (deleteSelected.length > 0) {
      setShowDeleteButton(true)
    } else {
      setShowDeleteButton(false)
    }
  }, [deleteSelected])

  useEffect(() => {
    if (allBookmarksData) {
      const newCurriculumCodesArray = allBookmarksData.map((x: any) => x.content.curriculum_code)
      if (newCurriculumCodesArray !== null &&  newCurriculumCodesArray.length > 0 ) {
        props.getBookmarksLearningCount({ curriculumCodes: newCurriculumCodesArray }
        )
      }
    }
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [allBookmarksData])

  // 表示する配列を作る処理
  useEffect(() => {
    if (allBookmarksData) {
      const newBookmarksToShow: Models.Bookmarks = []
      allBookmarksData.forEach((bookmark) => {
        if (selectedCurriculum) {
          if (selectedCurriculum.curriculum_name === initialOption.curriculum_name) {
            newBookmarksToShow.push(bookmark)
          } else if (bookmark.curriculum_name === selectedCurriculum.curriculum_name) {
            newBookmarksToShow.push(bookmark)
          }
        }
      }
      )
      if (newBookmarksToShow) {
        setBookmarksToShow(newBookmarksToShow)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allBookmarksData, selectedCurriculum])

  const DropDownHandleSelectChange = (event: string) => {
    setShowDropDown(false)
    setDeleteSelected([])
    let selectedOptionObject = null

    selectOptions.forEach((selectOption) => {
      if (selectOption.curriculum_id === Number(event)) {
        selectedOptionObject = selectOption
        if (selectedOptionObject) {
          setSelectedCurriculum(selectedOptionObject)
        }
      }
    })
  }

  const addToDeleteSelected = (contentId: any) => {
    if (deleteSelected.find((id) => id === contentId) === undefined) {
      setDeleteSelected([...deleteSelected, contentId])
    }
  }

  const removeFromDeleteSelected = (contentId: any) => {
    const filtered = deleteSelected.filter((item: number) => {
      return item !== contentId
    })
    setDeleteSelected(filtered)
  }
  
  const deleteBookmarks = () => {
    props.deleteBookmarks(deleteSelected).then((res) => {
      if (res.status === 200 && bookmarksToShow && allBookmarksData) {
        // 削除したブックマークを表示する配列から削除する
        const newBookmarksToShow = bookmarksToShow.filter((bookmark: any) => {
          return !deleteSelected.includes(bookmark.content.id)
        })
        setBookmarksToShow(newBookmarksToShow)
        setDeleteSelected([])
      }
    })
  }

  // カリキュラムコードから学習回数を取得
  const findLearningCount = (curriculumCode: string) => {
    if (bookmarksLearningCountArray) {
      const learningCountObject = bookmarksLearningCountArray.find((x: any) => x.curriculum_code === curriculumCode)
      if (learningCountObject) {
        return learningCountObject.learning_count
      }
    }
    return 0
  }

  return (
    <React.Fragment>
      <PrivateHeader />
      <SiteMain>

        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          <li className="breadcrumbs__item">ブックマーク</li>
        </ol>

        <div className="site-main__main-contents">
          <div className="bookmark__page-title-container">
            <div className="bookmark__icon">
              <div />
              <div className="bookmark__page-title-container-title">ブックマーク</div>
            </div>
            <div className="bookmark__page-title-container-counter">現在の登録数
              <span className="bookmark__page-title-container-counter-number">
                {allBookmarksData ? allBookmarksData.length : '0'}
              </span>
              <div className="bookmark__page-title-container-counter-after">個</div>
            </div>
          </div>

          <div className="bookmark__select-section">
            <div className="bookmark__relative">
              <div onClick={() => setShowDropDown(!showDropDown)} className="bookmark__dropdown-button">{selectedCurriculum.curriculum_name}
                <div className="bookmark__dropdown-button-arrow"></div>   </div>
              {showDropDown &&
                <div className="bookmark__dropdown-container">
                  <ul>
                    {selectOptions.map((i: any, index) => {
                      return <li onClick={() => {
                        DropDownHandleSelectChange(i.curriculum_id)
                      }} key={index} className="bookmark__dropdown-option">{i.curriculum_name}</li>
                    })}
                  </ul>
                </div>}
            </div>
            {showDropDown && <div onClick={() => setShowDropDown(false)} className="bookmark__back"></div>}
            <div className="bookmark__select-section-counter-container">
              <div className="bookmark__select-section-counter-num">{bookmarksToShow && bookmarksToShow.length}</div>
              <div className="bookmark__select-section-counter-right">個</div>
            </div>
          </div>

          <div className="bookmark__border-section">
            <div className="bookmark__border-line" />
          </div>

          {bookmarksToShow && bookmarksToShow.map((x, index) => {
            return (<React.Fragment key={index}>
              <div className={classNames(
                "bookmark__topic-box topic-box__bookmark",
              )}
              >
                <div className="topic-box__subject bookmark__topic-box__subject">
                  <small>登録日時　
                    <Moment format="YYYY年M月D日 HH:mm">{x.updated_at}</Moment>
                  </small>
                  {deleteSelected.includes(x.id) ?
                    <div
                      onClick={() => {
                        removeFromDeleteSelected(x.id)
                      }}
                      className="bookmark__delete-bookmark"
                    >&#x2715; 削除を取り消し</div> :
                    <div
                      onClick={() => {
                        addToDeleteSelected(x.id)
                      }}
                      className="bookmark__delete-bookmark"
                    >ブックマークを削除</div>}
                </div>

                <div className={classNames("topic-box__title", { "bookmark__opacity": deleteSelected.includes(x.id) })}>
                  {x.curriculum_name} ＞
                  {x.course_name} ＞
                  {x.unit_name} ＞
                  {x.topic_title} ＞
                  {x.content.meta && x.content.meta.label}
                </div>
                <div className='bookmark__topic-box__bottom-section'>

                  <div>
                    <div className={classNames(
                      "topic-box__learning_counts_bookmark",
                      { "topic-box__learning_counts_bookmark--many": findLearningCount(x.content.curriculum_code) },
                      { "bookmark__opacity": deleteSelected.includes(x.id) }
                    )
                    } >
                      学習回数 {findLearningCount(x.content.curriculum_code)}回
                    </div>
                  </div>

                  <div className='bookmark__topic-bottom-button-section'>
                    <a
                      onClick={(e) => {
                        e.preventDefault()
                        const content_id = x.content.id
                        if (isDisabledContent(content_id)) { return }
                        setDisabledContent(content_id)
                        const return_pathname = history.location.pathname
                        props.startStudy({ content_id, return_pathname }).then((url) => {
                          unsetDisabledContent(content_id)
                          window.location.href = url
                        })
                      }}
                      href="#!"
                      className={classNames(
                        "button button--primary", 
                        { "is-disabled": deleteSelected.includes(x.id) },
                        { "is-disabled": isDisabledContent(x.content.id) }
                        )}
                    >
                      学習する
                    </a>
                  </div>
                </div>
              </div>
              <div className="bookmark__border-section">
                <div className="bookmark__border-line" />
              </div>
            </React.Fragment>)
          }
          )}
        </div>

        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          <li className="breadcrumbs__item">
            ブックマーク
          </li>
        </ol>

      </SiteMain>
      {showDeleteButton && <>
        <div className="bookmark__float">
          <button onClick={deleteBookmarks} className="button bookmark__outline_button">ブックマークを削除</button>
        </div>
      </>}
    </React.Fragment>

  )
}

export default Bookmark