import actionCreatorFactory from 'typescript-fsa';
import * as Models from "models/api";

const actionCreator = actionCreatorFactory('info');

const infoActions = {
  fetchRecords:
    actionCreator<{ initialize: boolean; }>(
      'FETCH_RECORDS'),
  setRecords:
    actionCreator<{ page: number; records: Models.InfoRecords }>(
      'SET_RECORDS'),
  fetchDetailRecord:
    actionCreator<{ id: number; }>(
      'FETCH_DETAIL_RECORD'),
  setDetailRecord:
    actionCreator<{ id: number; record: Models.Info }>(
      'SET_DETAIL_RECORD'),
};

export default infoActions;