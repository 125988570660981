import React from 'react';
import { useHistory } from 'react-router-dom';
import Moment from 'react-moment';

import { StudentUser, StudentPlan } from 'models/api';
import PointDescBalloon from './PointDescBaloon';

type props = {
  user: StudentUser;
  plan: StudentPlan;
}

const UserInfo: React.FC<props> = (props: props) => {
  const history = useHistory()
  return <div className="setting-box__form">
    <div className="form form--confirm">
      <div className="form__item">
        <div className="form__label">
          ニックネーム
        </div>
        <div className="form__field">
          <div className="form__field-text">
            { props.user.nick_name }
          </div>
        </div>
      </div>
      <div className="form__item">
        <div className="form__label">
          ログインID
        </div>
        <div className="form__field">
          <div className="form__field-text">
            { props.user.login }
          </div>
        </div>
      </div>
      <div className="form__item">
        <div className="form__label">
          ステータス
        </div>
        <div className="form__field">
          <div className="account-status">
            <span className="account-status__badge">
              {/* MARK: temporary, suspensionの場合はこの画面に遷移できない */}
              { props.user.status === 'trial' && 'トライアル' }
              { props.user.status === 'regular' && '本登録' }
            </span>
            <div className="account-status__text">
              {
                // MARK: トライアル終了日はバッチ処理で設定されるため空の場合がある、空の場合は非表示
                props.user.status === 'trial' && props.user.trial_expired_on &&
                <>
                  <Moment format="YYYY年M月D日">{ props.user.trial_expired_on }</Moment>
                  までトライアルサービス利用中
                </>
              }
            </div>
          </div>
        </div>
      </div>
      <div className="form__item">
        <div className="form__label">
          登録日
        </div>
        <div className="form__field">
          <Moment format="YYYY年M月D日">{ props.user.created_at }</Moment>
        </div>
      </div>
      { 
        !props.user.corporation &&
        <>
          <div className="form__item">
            <div className="form__label">
              次回受講料自動継続課金日
            </div>
            <div className="form__field">
              { props.plan &&
              <Moment format="YYYY年M月D日">{ props.plan.next_payment_on }</Moment>
              }
            </div>
          </div>
        </>
      }

      {
        !props.user.corporation &&
        <div className="form__item">
          <div className="form__label">
            現在のポイント
          </div>
          <div className="form__field form__field--with-balloon">
            <span className="form__text form__text--orange">
              { props.user.current_points }pt
            </span>

            <PointDescBalloon />

            <a
              onClick={(e) => {
                e.preventDefault()
                history.push('/setting/point_exchange')
              }}
              href="#!"
              className="link-button link-button--point">ポイント交換について</a>
          </div>
        </div>
      }

    </div>
  </div>
}

export default UserInfo;
