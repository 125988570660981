import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router'

import { AppState } from "store";

import parentActions from 'actions/parentActions';
import * as Modesl from 'models/api'
import Mypage from 'components/parent/Mypage';
import { isRecommendCurriculum }  from 'utils/store'

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch })

const mapStateToProps = (appState: AppState, ownProps: RouteComponentProps) => {
  const studentUser = appState.parent.student
  return {
    studentUser,
    user: appState.session.user! as Modesl.ParentUser,
    parent: appState.parent,
    ...ownProps,
  }
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  { dispatch }: ReturnType<typeof mapDispatchToProps>) => {
  return {
    ...stateProps,
    isRecommendCurriculum,
    fetchData: () => {
      dispatch(parentActions.fetchMypage())
    },
  }
}

export type mappedProps = ReturnType<typeof mergeProps>

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(Mypage))