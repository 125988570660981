import React from 'react';
import PrivateHeader from 'containers/parts/PrivateHeader';
import SiteMain from 'containers/parts/SiteMain';

import { mappedProps } from 'containers/student/Guide';
import { Link, Redirect } from 'react-router-dom';

const StudentGuide: React.FC<mappedProps> = (props: mappedProps) => {
  if (!props.isAvailableGuide) {
    return <Redirect to='/' />
  }

  return (
    <>
      <PrivateHeader />
      <SiteMain>
        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          <li className="breadcrumbs__item">
            河合塾Oneのご利用方法
          </li>
        </ol>

        <div className="site-main__main-contents">
          <div className="tutorial-box">
            <h1 className="tutorial-box__title">
              河合塾Oneをスタートするまえに
            </h1>
            <p className="tutorial-box__lead">
              まずは基本的な機能や使い方を確認しましょう。
            </p>
            <p className="tutorial-box__text">
              主な機能や教材、基本的な使い方を説明します。河合塾Oneを使用するまえに必ずご覧ください。<br />
              {
                // MARK: 法人受講生
                props.studentUser && props.studentUser.corporation &&
                <>
                  動画が終わったら、早速、学習をスタートしましょう。<br />
                  ※法人お申込みの受講者さまには、一部ご使用いただけない機能がございます。
                </>
              }
              {
                // MARK: 一般受講生
                props.studentUser && !props.studentUser.corporation &&
                <>
                  動画が終わったら、早速、下の「AIおすすめ学習を始める」ボタンから、学習をスタートしましょう。<br />
                </>
              }

            </p>
            <div className="tutorial-box__video-wrapper">
              <div className="tutorial-box__video">
                <iframe src="https://youtube.com/embed/fl-XGLL-h7k?rel=0" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title='河合塾Oneをスタートするまえに'></iframe>
              </div>
            </div>
            {
              // MARK: 一般受講生のみ
              props.studentUser && !props.studentUser.corporation &&
              <div className="tutorial-box__button">
                <Link to='/recommends/english' className="button button--primary button--large">AIおすすめ学習を始める</Link>
              </div>
            }
            <h2 className="tutorial-box__subtitle">
              目的別の使い方
            </h2>
            <div className="tutorial-box__items">
              <div className="tutorial-box__item">
                <div className="tutorial-video-box">
                  <div className="tutorial-video-box__video">
                    <iframe width="560" height="315" src="https://youtube.com/embed/_NQYy9VSBhg?rel=0" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title='AIを利用して学習する'></iframe>
                  </div>
                  <h3 className="tutorial-video-box__title">
                    AIを利用して学習する
                  </h3>
                  <div className="tutorial-video-box__text">
                    AIのおすすめする学習範囲の設定や、学習のスタート地点を変更する方法について説明します。
                  </div>
                </div>
              </div>
              <div className="tutorial-box__item">
                <div className="tutorial-video-box">
                  <div className="tutorial-video-box__video">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/_Sbbo_KgM64?rel=0" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title='自分で教材を選んで学習する'></iframe>
                  </div>
                  <h3 className="tutorial-video-box__title">
                    自分で教材を選んで学習する
                  </h3>
                  <div className="tutorial-video-box__text">
                    好きな教材を自分で選んで学習する方法について説明します。
                  </div>
                </div>
              </div>
              <div className="tutorial-box__item">
                <div className="tutorial-video-box">
                  <div className="tutorial-video-box__video">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/cUa79SoadVs?rel=0" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title='河合塾Oneの先生に質問する'></iframe>
                  </div>
                  <h3 className="tutorial-video-box__title">
                    河合塾Oneの先生に質問する
                  </h3>
                  <div className="tutorial-video-box__text">
                    河合塾Oneの教材（問題・解説）で、分からなかったところを質問する方法について説明します。
                  </div>
                </div>
              </div>
              <div className="tutorial-box__item">
                <div className="tutorial-video-box">
                  <div className="tutorial-video-box__video">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/apNitR6qf8A?rel=0" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title='その他の機能'></iframe>
                  </div>
                  <h3 className="tutorial-video-box__title">
                    その他の機能
                  </h3>
                  <div className="tutorial-video-box__text">
                    ポイント機能、学習時間機能、トレーナー機能などについて説明します。
                  </div>
                </div>
              </div>
            </div>
            <div className="tutorial-box__buttons">
              {
                props.studentUser && !props.studentUser.corporation &&
                <div className="tutorial-box__button">
                  <a className="button button--large button--darkgray" href="https://www.kawaijukuone.co.jp/faq/" target="_blank" rel="noopener noreferrer">よくあるご質問</a>
                </div>
              }

              <div className="tutorial-box__button">
                <a className="button button--large button--darkgray" href="https://www.kawaijukuone.co.jp/contact/" target="_blank" rel="noopener noreferrer">お問い合わせ</a>
              </div>
            </div>
          </div>

          <div className="back-home">
            <Link to='/' className="back-home__link">マイページへ戻る</Link>
          </div>
        </div>

        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          <li className="breadcrumbs__item">
            河合塾Oneのご利用方法
          </li>
        </ol>
      </SiteMain>
    </>
  );
}

export default StudentGuide
