import AppParent from 'AppParent';
import AppInstructor from 'AppInstructor';
import AppTrainer from 'AppTrainer';
import AppStudent from 'AppStudent';
import AppSignup from 'AppSignup';

import * as Models from 'models/api'

const metaDef = {
  default: {
    title: '河合塾One',
    description: null,
    robots: 'noindex,nofollow',
  },
  student: {
    title: '河合塾One ログイン',
    description: '河合塾Oneにログイン',
    robots: null,
  },
  signup: {
    title: '河合塾One 新規お申し込み',
    description: 'お申込み日から１４日間、無料でご利用いただけます。',
    robots: null,
  },
}

const uiTypes: {
  env: 'production'|'staging'|'development';
  name: Models.UIType;
  app: any;
  hostname: string;
  apiBaseUrl: string;
}[] = [
  // MARK: production
  {
    env: 'production',
    name: 'parent',
    app: AppParent,
    hostname: 'parent.kawaijukuone.jp',
    apiBaseUrl: 'https://system.kawaijukuone.jp',
  },
  {
    env: 'production',
    name: 'trainer',
    app: AppTrainer,
    hostname: 'trainer.kawaijukuone.jp',
    apiBaseUrl: 'https://system.kawaijukuone.jp',
  },
  {
    env: 'production',
    name: 'instructor',
    app: AppInstructor,
    hostname: 'teacher.kawaijukuone.jp',
    apiBaseUrl: 'https://system.kawaijukuone.jp',
  },
  {
    name: 'student',
    env: 'production',
    app: AppStudent,
    hostname: 'student.kawaijukuone.jp',
    apiBaseUrl: 'https://system.kawaijukuone.jp',
  },
  {
    env: 'production',
    name: 'signup',
    app: AppSignup,
    hostname: 'signup.kawaijukuone.jp',
    apiBaseUrl: 'https://system.kawaijukuone.jp',
  },

  // MARK: staging
  {
    env: 'staging',
    name: 'parent',
    app: AppParent,
    hostname: 'stg-parent.kawaijukuone.jp',
    apiBaseUrl: 'https://stg-system.kawaijukuone.jp',
  },
  {
    env: 'staging',
    name: 'trainer',
    app: AppTrainer,
    hostname: 'stg-trainer.kawaijukuone.jp',
    apiBaseUrl: 'https://stg-system.kawaijukuone.jp',
  },
  {
    env: 'staging',
    name: 'instructor',
    app: AppInstructor,
    hostname: 'stg-teacher.kawaijukuone.jp',
    apiBaseUrl: 'https://stg-system.kawaijukuone.jp',
  },
  {
    env: 'staging',
    name: 'student',
    app: AppStudent,
    hostname: 'stg-student.kawaijukuone.jp',
    apiBaseUrl: 'https://stg-system.kawaijukuone.jp',
  },
  {
    env: 'staging',
    name: 'signup',
    app: AppSignup,
    hostname: 'stg-signup.kawaijukuone.jp',
    apiBaseUrl: 'https://stg-system.kawaijukuone.jp',
  },

  // MARK: intg
  {
    env: 'staging',
    name: 'parent',
    app: AppParent,
    hostname: 'intg-parent.kawaijukuone.jp',
    apiBaseUrl: 'https://stg-system.kawaijukuone.jp/',
  },
  {
    env: 'staging',
    name: 'trainer',
    app: AppTrainer,
    hostname: 'intg-trainer.kawaijukuone.jp',
    apiBaseUrl: 'https://stg-system.kawaijukuone.jp/',
  },
  {
    env: 'staging',
    name: 'instructor',
    app: AppInstructor,
    hostname: 'intg-teacher.kawaijukuone.jp',
    apiBaseUrl: 'https://stg-system.kawaijukuone.jp/',
  },
  {
    env: 'staging',
    name: 'student',
    app: AppStudent,
    hostname: 'intg-student.kawaijukuone.jp',
    apiBaseUrl: 'https://stg-system.kawaijukuone.jp/',
  },
  {
    env: 'staging',
    name: 'signup',
    app: AppSignup,
    hostname: 'intg-signup.kawaijukuone.jp',
    apiBaseUrl: 'https://stg-system.kawaijukuone.jp/',
  },
];

export const getUIType = (
  hostname: string,
  debugType: string| undefined,
  debugApiBaseUrl: string| undefined,
  ) => {
  if (debugType && debugApiBaseUrl) {
    const uiType = uiTypes.find(x => x.name === debugType);
    if (!uiType) {
      throw new Error(`invalid UIType debugType: ${debugType}`);
    }
    const meta = ['signup', 'student'].includes(uiType.name) ?
      metaDef[uiType.name as 'student' | 'signup'] : metaDef.default
    return {
      ...uiType,
      ...meta,
      env: 'development',
      apiBaseUrl: debugApiBaseUrl,
    }
  }
  const uiType = uiTypes.find(x => x.hostname === hostname);
  if (!uiType) {
    throw new Error(`invalid UIType hostname: ${hostname}`);
  }
  const meta = ['signup', 'student'].includes(uiType.name) ?
    metaDef[uiType.name as 'student' | 'signup'] : metaDef.default
  return {
    ...uiType,
    ...meta,
  }
}
