import React from 'react';
import logo from 'assets/img/logo.png';

import { FallbackProps } from 'react-error-boundary';
import { getUIType } from '../../uiTypes';

const Error: React.FC<FallbackProps> = (props: FallbackProps) => {
  const hostname = window.location.hostname
  const uiType = getUIType(
    hostname,
    process.env.REACT_APP_TYPE,
    process.env.REACT_APP_API_URL);

  // 受講者・新規登録の場合は両方のリンクを表示
  // 保護者・講師・トレーナーはマイページのみ

  return (
    <React.Fragment>
      <div style={{ textAlign: 'center' }}>
        <div>
          <div>
            <img src={logo} alt="" className="" />
          </div>
          <div style={{ padding: '10px' }}>
            通信に問題が発生しました。<br /><br />
            申し訳ありませんが、<br />
            再度アクセスをお願いいたします。
          </div>

          {
            uiType.name === 'parent' &&
            <React.Fragment>
              <div style={{ padding: '10px' }}>
                <a href="http://parent.kawaijukuone.jp/">保護者マイページへ</a>
              </div>
            </React.Fragment>
          }

          {
            uiType.name === 'trainer' &&
            <React.Fragment>
              <div style={{ padding: '10px' }}>
                <a href="http://trainer.kawaijukuone.jp/">トレーナーマイページへ</a>
              </div>
            </React.Fragment>
          }

          {
            uiType.name === 'instructor' &&
            <React.Fragment>
              <div style={{ padding: '10px' }}>
                <a href="http://teacher.kawaijukuone.jp/">講師マイページへ</a>
              </div>
            </React.Fragment>
          }

          {
            ( uiType.name === 'student' || uiType.name === 'signup' ) &&
            <React.Fragment>
              <div style={{ padding: '10px' }}>
                <a href="http://student.kawaijukuone.jp/">受講生マイページへ</a>
              </div>
              <div style={{ padding: '10px' }}>
                <a href="http://signup.kawaijukuone.jp/">新規ご登録</a>
              </div>
            </React.Fragment>
          }
        </div>
      </div>
    </React.Fragment>
  );
}

export default Error
