import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Field } from 'redux-form'

import PublicHeader from '../parts/PublicHeader';
import SiteMainSimple from '../parts/SiteMainSimple';
import { mappedProps } from 'containers/common/LoginForm';

const Login: React.FC<mappedProps> = (props: mappedProps) => {
  useEffect(() => {
    if (props.uiType === 'student') {
      const scFunc = (window as any).sc_func
      if (scFunc) { scFunc('kjone', 'login', '') }
    }
  }, [props.uiType])

  return (
    <React.Fragment>
      <PublicHeader />
      <SiteMainSimple>
        <div className="account-box">
          <div className="account-box__title">
            河合塾Oneにログイン<br />
            {
              props.uiType === 'student' &&
              <div className="account-box__badge">受講者用</div>
            }
            {
              props.uiType === 'parent' &&
              <div className="account-box__badge account-box__badge--parent">保護者専用</div>
            }
          </div>
          {
            props.uiType === 'parent' &&
            <div className="account-box__notes">
              <div className="account-box__note">
                こちらは<b>保護者用ページ</b>です。お子様の学習の状況がご覧いただけます。
              </div>
              <div className="account-box__note">
                学習、プラン・学習範囲などの設定変更、カード情報登録・その他の申請・操作は受講者用ページから行なってください。
              </div>
              <div className="account-box__note account-box__note--center">
                <a href="https://student.kawaijukuone.jp/">受講者ページはこちら</a>
              </div>
            </div>
          }
          <div className="account-box__form">
            <form onSubmit={props.handleSubmit}>
              <div className="account-form">
                <div className="account-form__item">
                  <label className="account-form__label">ユーザーID</label>
                  <Field
                    component="input"
                    placeholder={props.loginIdPlaceholder}
                    className="input input--required account-form__input"
                    type="text"
                    name="login"
                  />
                </div>
                <div className="account-form__item">
                  <label className="account-form__label">パスワード</label>
                  <Field
                    component="input"
                    className="input input--required account-form__input"
                    type="password"
                    name="password"
                  />
                </div>

                {
                  props.submitFailed &&
                  <div className="account-form__error">
                    <div className="error-message">
                      ユーザーID、またはパスワードが間違っています。
                    </div>
                  </div>
                }

                <div className="account-form__button">
                  <button
                    type="submit"
                    className="button button--primary button--large">
                    ログイン
                  </button>
                </div>
                {
                  // 12/9 対応
                  ['student', 'trainer', 'parent'].includes(props.uiType!) &&
                  <div className="account-form__text">
                    <Link to='/password_reminder' className="account-form__link">
                      パスワードを忘れた方はこちら
                    </Link>
                  </div>
                }
                {
                  ['student', 'parent'].includes(props.uiType!) &&
                  <div className="account-form__button">
                    <a className="link-button link-button--external" href="https://www.kawaijukuone.co.jp/" target="_blank" rel="noopener noreferrer">会員でない方はこちら</a>
                  </div>
                }
              </div>
            </form>
          </div>
        </div>
      </SiteMainSimple>
    </React.Fragment>
  );
}

export default Login
