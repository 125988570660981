import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router'

import { AppState } from "store";
import targetComponent from 'components/student/StudyCourseResult';
import studentActions from 'actions/studentActions';
import * as Models from 'models/api';
import { getRecommendedCurriculums }  from 'utils/store'

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch })

const mapStateToProps = (appState: AppState, ownProps: RouteComponentProps) => {
  const user = appState.session.user! as Models.StudentUser
  const curriculums = getRecommendedCurriculums(user)

  return {
    user,
    curriculums,
    student: appState.student,
    ...ownProps,
  }
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  { dispatch }: ReturnType<typeof mapDispatchToProps>) => {
  return {
    ...stateProps,
    fetchData: (curriculum_id: number, course_id: number | null) => {
      dispatch(studentActions.fetchStudyCourseResult({ curriculum_id, course_id }))
    },
    getCurriculum: (code: string | undefined) => {
      return stateProps.curriculums.find(x => x.code === code || x.id === Number(code))
    },
    getCourses: (curriculum_id: number) => {
      const page = stateProps.student.studyCurriculum
      if (page.curriculum_id !== curriculum_id || !page.record) {
        return null
      }
      return page.record.courses
    },
    getRecord: (course_id: number) => {
      const page = stateProps.student.studyCourseResult
      if (page.course_id !== course_id) {
        return null
      }
      return page.record
    },
    getCompletionTest: (course_id: number) => {
      const page = stateProps.student.studyCourseCompletionTests
      if (page.course_id !== course_id || !page.record || !page.record.results) {
        return null
      }
      return page.record.results[0]
    },
    startStudy: (params: { content_id: number, return_pathname: string }) => {
      return new Promise<string>((resolve, reject) => {
        dispatch(studentActions.startStudy({
          params,
          promises: { resolve, reject }
        }))
      })
    },
    fetchStudyAchievementsLatest: () => {
      return new Promise<Models.StudyAchievementsLatest>((resolve, reject) => {
        dispatch(studentActions.fetchStudyAchievementsLatest(
          { promises: { resolve, reject } }
        ))
      })
    }
  }
}

export type mappedProps = ReturnType<typeof mergeProps>
export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(targetComponent))
