import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router'

import { AppState } from "store";
import targetComponent from 'components/trainer/StudentStudyResultCourse';

import studyResultActions from 'actions/studyResultActions';

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch })

const mapStateToProps = (appState: AppState, ownProps: RouteComponentProps) => {
  return {
    user: appState.session.user!,
    trainer: appState.trainer,
    studyResult: appState.studyResult,
    ...ownProps,
  }
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  { dispatch }: ReturnType<typeof mapDispatchToProps>) => {
  return {
    ...stateProps,
    getStudent: (student_id: number) => {
      const student = stateProps.trainer.student
      if (student.student_id !== student_id) {
        return null
      }
      return student.record
    },
    getRecord: (student_id: number, course_id: number) => {
      const page = stateProps.studyResult.studyCourse
      if (page.course_id !== course_id || page.student_id !== student_id) {
        return null
      }
      return page.record
    },
    fetchData: (student_id: number, course_id: number) => {
      dispatch(studyResultActions.fetchStudyCourse({ student_id, course_id }))
    },
    getCompletionTest: (student_id: number, course_id: number) => {
      const page = stateProps.studyResult.studyCourseCompletionTests
      if (page.student_id !== student_id || page.course_id !== course_id || !page.record || !page.record.results) {
        return null
      }
      return page.record.results[0]
    },
  }
}

export type mappedProps = ReturnType<typeof mergeProps>
export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(targetComponent))
