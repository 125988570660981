import Cookies from 'universal-cookie';

const COOKIE_API_KEY = 'api_key'

export const getAuthCookie = (cookies: Cookies): string => {
  return cookies.get(COOKIE_API_KEY)
}

export const setAuthCookie = (cookies: Cookies, token: string, expires: number): void => {
  cookies.set(
    COOKIE_API_KEY,
    token,
    { path: '/', expires: new Date(expires * 1000) })
}

export const removeAuthCookie = (cookies: Cookies): void => {
  cookies.remove(COOKIE_API_KEY)
}

export const getNameCookie = (cookies: Cookies, name: string): string => {
  return cookies.get(name)
}

export const setNameCookie = (cookies: Cookies, name: string, value: string, expires: number): void => {
  cookies.set(
    name,
    value,
    { path: '/', expires: new Date(expires * 1000) })
}

export const removeNameCookie = (cookies: Cookies, name: string): void => {
  cookies.remove(name)
}