import actionCreatorFactory from 'typescript-fsa';
import * as Models from "models/api";

const actionCreator = actionCreatorFactory('studyResult');

const actions = {
  fetchStudent:
    actionCreator<{ student_id: number; }>(
      'FETCH_STUDENT'),
  setStudent:
    actionCreator<{ student_id: number; record: Models.StudentUser }>(
      'SET_STUDENT'),
  fetchStudyCurriculums:
    actionCreator<{ student_id: number; }>(
      'FETCH_STUDY_CURRICULUMS'),
  setStudyCurriculums:
    actionCreator<{ student_id: number; record: Models.OwnStudentStudyCurriculums | null }>(
      'SET_STUDY_CURRICULUMS'),
  fetchStudyCurriculum:
    actionCreator<{ curriculum_id: number; student_id: number; }>(
      'FETCH_STUDY_CURRICULUM'),
  setStudyCurriculum:
    actionCreator<{ curriculum_id: number; student_id: number; record: Models.OwnStudentStudyCurriculum | null }>(
      'SET_STUDY_CURRICULUM'),
  fetchStudyCourse:
    actionCreator<{ course_id: number; student_id: number; }>(
      'FETCH_STUDY_COURSE'),
  setStudyCourse:
    actionCreator<{ course_id: number; student_id: number; record: Models.OwnStudentStudyCourse | null }>(
      'SET_STUDY_COURSE'),
  fetchStudyUnit:
    actionCreator<{ unit_id: number; student_id: number; }>(
      'FETCH_STUDY_UNIT'),
  setStudyUnit:
    actionCreator<{ unit_id: number; student_id: number; record: Models.OwnStudentStudyUnit | null }>(
      'SET_STUDY_UNIT'),
  fetchStudyUnitResult:
    actionCreator<{ unit_id: number; student_id: number; }>(
      'FETCH_STUDY_UNIT_RESULT'),
  setStudyUnitResult:
    actionCreator<{ unit_id: number; student_id: number; record: Models.OwnStudentStudyUnitResult | null }>(
      'SET_STUDY_UNIT_RESULT'),
  fetchStudyTopic:
    actionCreator<{ topic_id: number; student_id: number; }>(
      'FETCH_STUDY_TOPIC'),
  setStudyTopic:
    actionCreator<{ topic_id: number; student_id: number; record: Models.OwnStudentStudyTopic | null }>(
      'SET_STUDY_TOPIC'),
  fetchStudyLogRecords:
    actionCreator<{ student_id: number; curriculum_id: number; initialize: boolean; }>(
      'FETCH_STUDY_LOG_RECORDS'),
  setStudyLogRecords:
    actionCreator<{ student_id: number; page: number; curriculum_id: number; records: Models.StudyLogRecords }>(
      'SET_STUDY_LOG_RECORDS'),

  setStudyCourseCompletionTests:
    actionCreator<{ student_id: number; course_id: number; record: Models.StudyCourseCompletionTestsRecord | null }>(
      'SET_STUDY_COURSE_COMPLETION_TESTS'),
  fetchStudyCourseCompletionTestResult:
    actionCreator<{ student_id: number; unit_id: number; initialize: boolean; }>(
      'FETCH_STUDY_COURSE_COMPLETION_TEST_RESULTS'),
  setStudyCourseCompletionTestResults:
    actionCreator<{ student_id: number; unit_id: number; record: Models.StudyCourseCompletionTestResultsRecord | null }>(
      'SET_STUDY_COURSE_COMPLETION_TEST_RESULTS'),
  setStudyCourseCompletionTestResultsMore:
    actionCreator<{ record: Models.StudyCourseCompletionTestResultsRecord | null}>(
      'SET_STUDY_COURSE_COMPLETION_TEST_RESULTS_MORE'),
};

export default actions;