import React, { useEffect, useState } from 'react';
import { Link, useParams, Redirect, } from 'react-router-dom';
import Moment from 'react-moment';
import classNames from 'classnames';

import { mappedProps } from 'containers/instructor/StudentStudyLogs';
import PrivateHeader from 'containers/parts/PrivateHeader';
import SiteMain from 'containers/parts/SiteMain';

import StudentBar from '../parts/CommonStudentBar'
import StudentNav from '../parts/InstructorStudentNav'

const StudentStudyLogs: React.FC<mappedProps> = (props: mappedProps) => {
  const { id } = useParams();

  const [curriculum, setCurriculum] = useState<string>('english')

  const curriculumObj = props.getCurriculum(curriculum)

  useEffect(() => {
    if (curriculumObj) {
      props.fetchFirst(curriculumObj.id, Number(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curriculum, id]);

  if (!curriculumObj) {
    return (
      <Redirect to="/" />
    )
  }

  const student = props.getStudent(Number(id))
  const studyLogs = props.getStudyLogs(curriculumObj.id, Number(id))
  const hasNext = props.hasNextStudyLog(curriculumObj.id, Number(id))

  return (
    <React.Fragment>
      <PrivateHeader />
      <SiteMain>
        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          <li className="breadcrumbs__item">
            受講者情報
          </li>
        </ol>

        <div className="site-main__main-contents">
          {
            student && 
            <React.Fragment>
              <StudentBar student={student} />
              <StudentNav student={student} type="study_logs" />
            </React.Fragment>
          }

          <div className="learning-history-box">
            <div className="learning-history-box__head">
              <div
                onClick={() => { setCurriculum('english') }}
                className={classNames("learning-history-box__tab", {
                'is-active' : curriculum === 'english'
              })}> 英語 </div>
              <div
                onClick={() => { setCurriculum('math') }}
                className={classNames("learning-history-box__tab", {
                'is-active' : curriculum === 'math'
              })}> 数学 </div>
              <div
                onClick={() => { setCurriculum('physics') }}
                className={classNames("learning-history-box__tab", {
                'is-active' : curriculum === 'physics'
              })}> 物理 </div>
              <div
                onClick={() => { setCurriculum('chemistry') }}
                className={classNames("learning-history-box__tab", {
                'is-active' : curriculum === 'chemistry'
              })}> 化学 </div>
              <div
                onClick={() => { setCurriculum('classical_japanese') }}
                className={classNames("learning-history-box__tab", {
                'is-active' : curriculum === 'classical_japanese'
              })}> 古文 </div>
            </div>
            <div className="learning-history-box__body">
              <div className="learning-history-box__title">
                { curriculum === 'math' ? '数学' : '英語' }の学習履歴
              </div>

              {
                studyLogs && studyLogs.map((record, index) => (
                  <div key={index} className="learning-history-box__item">
                    <div className="learning-history-box__text learning-history-box__text--no-button">
                      <div className="learning-history-box__date">
                        <Moment format="YYYY年M月D日 HH:mm">{ record.created_at }</Moment>
                        〜
                        <Moment format="YYYY年M月D日 HH:mm">{ record.updated_at }</Moment>
                      </div>
                      <div className="learning-history-box__topic">
                        { record.content.topic.title } > { record.content.meta!.label}
                      </div>
                    </div>
                  </div>
                ))
              }

              {
                studyLogs && studyLogs.length <= 0 && 
                <div style={{ padding: '20px' }}>
                  学習履歴はありません。
                </div>
              }

            </div>
          </div>

          {
            hasNext &&
            <div className="more-button">
              <div
                onClick={() => { props.fetchNext() }}
                className="more-button__text">
                次のトピックを表示
              </div>
            </div>
          }

          <div className="back-home">
            <Link to='/' className="back-home__link">マイページへ戻る</Link>
          </div>
        </div>

        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          <li className="breadcrumbs__item">
            受講者情報
          </li>
        </ol>
      </SiteMain>
    </React.Fragment>
  );
}

export default StudentStudyLogs
