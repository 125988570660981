import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router'

import { AppState } from "store"
//import SiteMain from 'components/parts/SiteMain'
import RealtimeViewMain from 'components/parts/RealtimeViewMain';
import uiActions from 'actions/uiActions'
import * as Models from 'models/api';
import { getRecommendedCurriculums, getAvailableCurriculums }  from 'utils/store'
import * as campaignSettingGuide from 'utils/campaign/guide202012'
import * as studentMesageSetting from 'utils/campaign/studentMessage'
import { getTrainerStudyplusAuthToken } from 'services/api'

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch })

const mapStateToProps = (appState: AppState, ownProps: RouteComponentProps) => {
  const uiType = appState.ui.type
  const env = appState.ui.env
  const user = appState.session.user
  const sidenav = appState.ui.sidenav
  let studentUser: Models.StudentUser | null = null
  if (uiType === 'student') {
    studentUser = user as Models.StudentUser
  }

  if (uiType === 'parent') {
    // MARK: ログイン時に設定している
    studentUser = appState.parent.student
  }

  let trainerUser: Models.TrainerUser | null = null
  if (uiType === 'trainer') {
    trainerUser = user as Models.TrainerUser
  }

  const availableQuestions = studentUser &&
    (
      !studentUser.corporation ||
      ( studentUser.corporation && studentUser.corporation.available_services.questions )
    )

  const isAvailableGuide = studentUser && campaignSettingGuide.isAvailable(studentUser)
  const isAvailableStudentMessage = studentUser && studentMesageSetting.isAvailable(studentUser)

  const authrorizedApi = appState.repository.authorizedApi!

  let studyplusAuthUrlPrefix = 'https://sandbox.fs-lms.studyplus.co.jp/learning_material_supplier_api/v1/login/'
  if (env === 'production') {
    studyplusAuthUrlPrefix = 'https://fs-lms.studyplus.co.jp/learning_material_supplier_api/v1/login/'
  }

  return {
    curriculums: studentUser && getRecommendedCurriculums(studentUser),
    availCurriculums: studentUser && getAvailableCurriculums(studentUser),
    user,
    studentUser,
    trainerUser,
    uiType,
    sidenav,
    availableQuestions,
    isAvailableGuide,
    isAvailableStudentMessage,
    authrorizedApi,
    studyplusAuthUrlPrefix,
    ...ownProps,
  }
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  { dispatch }: ReturnType<typeof mapDispatchToProps>) => {

  return {
    ...stateProps,
    setOpenRecommendNav: (payload: boolean) => {
      dispatch(uiActions.setOpenRecommendNav(payload))
    },
    setOpenStudyNav: (payload: boolean) => {
      dispatch(uiActions.setOpenStudyNav(payload))
    },
    setOpenHistoryNav: (payload: boolean) => {
      dispatch(uiActions.setOpenHistoryNav(payload))
    },
    setOpenTestNav: (payload: boolean) => {
      dispatch(uiActions.setOpenTestNav(payload))
    },
    // issue-545
    setOpenEntrustNav: (payload: boolean) => {
      dispatch(uiActions.setOpenEntrustNav(payload))
    },
    // issue-545
    getStudyplusAuthToken: async () => {
      try {
        const res = await getTrainerStudyplusAuthToken(stateProps.authrorizedApi)
        return res.data
      } catch (e) {
        console.error(e);
        dispatch(uiActions.showApiErrorNotification(e));
        throw e
      }
    }
  }
}

type connectMappedProps = ReturnType<typeof mergeProps>
export type mappedProps = connectMappedProps

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(RealtimeViewMain))