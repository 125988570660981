import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router'
import { reduxForm, InjectedFormProps, SubmissionError, FormErrors, formValueSelector, submit } from 'redux-form'
import lodash from 'lodash'

import { AppState } from "store";
import SettingPointExchange from 'components/student/SettingPointExchange';
import * as Models from 'models/api'
import studentActions from 'actions/studentActions';

import {
  pointRate,
  amazonGiftExchangeRate,
  planDiscountExchangeRate,
} from 'utils/constants'

export const formKey = 'setting_point_exchange'

type FormData = {
  item_ordered?: string;
  amazon_number_ordered?: string;
  plan_discount_number_ordered?: string;
  [key: string]: any
};

export const FormValidate = (values: FormData) => {
  const errors: FormErrors<FormData> = {}
  if (!values.item_ordered) {
    errors.item_ordered = 'Required'
  }
  if (!values.amazon_number_ordered && !values.plan_discount_number_ordered) {
    errors.amazon_number_ordered = 'Required'
    errors.plan_discount_number_ordered = 'Required'
  }
  return errors
}

export const getAmazonGiftExchangeOptions = (currentPoints: number) => {
  const maxNum = currentPoints / pointRate
  return lodash.times(maxNum, i => ({
    key: i + 1,
    val: `${(i + 1) * pointRate}pt`,
  }))
}

export const getPlanDiscountExchangeOptions = (currentPoints: number, plan: Models.StudentPlan) => {
  const nextPrice = plan.price - plan.discount_points
  const maxNum = Math.min(
    currentPoints / pointRate,
    nextPrice / pointRate,
  )
  return lodash.times(maxNum, i => ({
    key: i + 1,
    val: `${(i + 1) * pointRate}pt`,
  }))
}

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch })
const mapStateToProps = (appState: AppState, routeProps: RouteComponentProps) => {
  const selector = formValueSelector(formKey);
  const user = appState.session.user! as Models.StudentUser
  const plan = appState.student.plan
  const initialValues = {}

  const amazonGiftExchangeOptions = user && getAmazonGiftExchangeOptions(user.current_points) 
  const planDiscountExchangeOptions = user && plan && getPlanDiscountExchangeOptions(user.current_points, plan) 
  const amazonNumberOrdered = selector(appState, 'amazon_number_ordered')
  const planDiscountNumberOrdered = selector(appState, 'plan_discount_number_ordered')
  
  return {
    user,
    plan,
    initialValues,
    pointRate,
    amazonGiftExchangeRate,
    amazonGiftExchangeOptions,
    amazonNumberOrdered,
    planDiscountExchangeRate,
    planDiscountExchangeOptions,
    planDiscountNumberOrdered,
    nextPage: '/setting/point_exchange/confirm',
    ...routeProps,
  }
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  { dispatch }: ReturnType<typeof mapDispatchToProps>) => {
  return {
    ...stateProps,
    manualSubmit: () => {
      // MARK: changeした直後だと値が反映されない
      setTimeout(() => {
        dispatch(submit(formKey))
      }, 0)
    },
    onSubmit: (values: FormData) => {
      const number_ordered = values.item_ordered === '1' ?
        Number(values.plan_discount_number_ordered!):
        Number(values.amazon_number_ordered!)
      const params: Models.StudentPointExchangeParams = {
        item_ordered: Number(values.item_ordered!),
        number_ordered,
      }
      return new Promise<void>((resolve, reject) => {
        const payload = {
          params,
          promises: { resolve, reject }
        }
        dispatch(studentActions.confirmPointExchange(payload))
      }).catch((e: Error) => {
        throw new SubmissionError({})
      })
    },
    fetchData: () => {
      dispatch(studentActions.fetchPlan())
    },
  }
}

type connectMappedProps = ReturnType<typeof mergeProps>
export type mappedProps = connectMappedProps & InjectedFormProps<FormData, connectMappedProps>

const form = reduxForm<FormData, connectMappedProps>({
  form: formKey,
  validate: FormValidate
})(SettingPointExchange)

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(form))
