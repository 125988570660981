import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router'
import { reduxForm, InjectedFormProps, FormErrors, SubmissionError, reset } from 'redux-form'

import { AppState } from "store";
import targetComponent from 'components/student/Messages';
import studentActions from 'actions/studentActions';
import * as apiService from 'services/api'
import uiActions from 'actions/uiActions'
import * as Models from 'models/api';
import * as studentMesageSetting from 'utils/campaign/studentMessage'

type FormData = {
  body?: string;
  [key: string]: any
};

export const formKey = 'message_create'

export const FormValidate = (values: FormData) => {
  const errors: FormErrors<FormData> = {}
  const requiredFields: string[] = [
    'body',
  ]
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required'
    }
  })
  return errors
}

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch })

const mapStateToProps = (appState: AppState, ownProps: RouteComponentProps) => {
  const user = appState.session.user! as Models.StudentUser

  const isAvailableStudentMessage = studentMesageSetting.isAvailable(user)

  return {
    isAvailableStudentMessage,
    api: appState.repository.authorizedApi!,
    user: appState.session.user!,
    student: appState.student,
    ...ownProps,
  }
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  { dispatch }: ReturnType<typeof mapDispatchToProps>) => {
  const showApiError = (e: Error) => {
    dispatch(uiActions.showApiErrorNotification(e))
  }
  return {
    ...stateProps,
    fetchData: () => {
      dispatch(studentActions.fetchMessageRecords())
    },
    onSubmit: (values: FormData) => {
      // TODO: refactor sagaにうつす
      const body = values.body!
      const student_id = stateProps.user.id
      return apiService.createStudentMessage(
        stateProps.api, { student_id, body })
          .then(() => {
            dispatch(reset(formKey))
            dispatch(studentActions.fetchMessageRecords())
          })
          .catch((e: Error) => {
            showApiError(e)
            throw new SubmissionError({})
          })
    }
  }
}

type connectMappedProps = ReturnType<typeof mergeProps>
export type mappedProps = connectMappedProps & InjectedFormProps<FormData, connectMappedProps>

const form = reduxForm<FormData, connectMappedProps>({
  form: formKey,
  validate: FormValidate
})(targetComponent)

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(form))