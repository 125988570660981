import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import classNames from 'classnames';

import { mappedProps } from 'containers/student/SettingWithdrawal';
import PrivateHeader from 'containers/parts/PrivateHeader';
import SiteSettingMainArea from '../common/SiteSettingMainArea';
import { Field, WrappedFieldProps, Fields } from 'redux-form';

import { renderTextareaField, renderIfFieldsHasError } from 'components/parts/FormParts'

const renderCheckboxWithLabelField = ({
  input,
  children,
  ...custom
}: WrappedFieldProps & { children: React.ReactNode }) => {
  let labelClassName = 'checkbox';
  if (input.checked) {
    labelClassName += ' is-checked';
  }
  return (
    <label className={labelClassName}>
      <input
        {...input}
        {...custom}
      />
      {children}
    </label>
  );
}

const SettingWithdrawal: React.FC<mappedProps> = (props: mappedProps) => {
  const [confirmWithdraw, setConfirmWithdraw] = useState(false);

  // MARK: コーポレートユーザ利用不可
  if (props.user.corporation) {
    return <Redirect to='/' />;
  }

  if (props.submitSucceeded) {
    return <Redirect to={props.nextPage} />;
  }

  const confirmFragment = (
    <div className="setting-box">
      <div className="setting-box__title setting-box__title--withdraw">
        退会
      </div>
      <div className="setting-box__lead setting-box__lead--withdraw">
        河合塾Oneをご利用いただきましてありがとうございました。<br />
        下記の注意事項をご確認のうえ、退会を選択される場合は、「退会する」ボタンを押してください。
      </div>
      <div className="setting-box__cautions">
        <dl className="setting-caution-list setting-caution-list--withdraw">
          <dt className="setting-caution-list__title">
            注意事項
          </dt>
          <dd className="setting-caution-list__item">
            退会を選択すると学習情報は全て破棄されます。再度ご入会いただいても、「ＡＩおすすめ学習」など全てはじめからの学習となります。
          </dd>
          <dd className="setting-caution-list__item">
            ６ヶ月プラン、１２ヶ月プランの方は、特定商取引法に基づく表記に記載の返金ポリシーに従って返金いたします。自動的に返金はされません。なお、１か月プランおよびトレーナーサポート等のオプションサービスについての返金や、 ６ヶ月プラン、１２ヶ月プランの退会手続き後に退会申請日を含め１４日以内に返金申請がなされなかった場合の返金は行いません。
          </dd>
          <dd className="setting-caution-list__item">
            ご退会後、河合塾Oneを含め、河合塾グループのご案内が届く場合がございます。情報提供の停止をご希望の場合は、コーポレートサイトよりお手続きをお願い致します。
          </dd>
        </dl>
      </div>
      <div className="setting-box__cautions">
        <div className="withdrawal-caution">
          <div className="withdrawal-caution__head">
            退会すると、河合塾Oneの入塾金特典が受けられなくなります。
          </div>
          <div className="withdrawal-caution__body">
            河合塾Oneの本会員は、河合塾の入塾金（グリーンコース：33,000円、大学受験科：100,000円）が免除となります。<br />一度退会されると、入塾金特典はご利用いただけませんのでご注意ください。
            
          </div>
        </div>
      </div>
      <div className="form">
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <Field
            component={renderCheckboxWithLabelField}
            className="checkbox__checkbox"
            type="checkbox"
            name="check"
          >
            上記事項に<br className="_sp" />
            同意する
          </Field>
        </div>
        <div className="questionnaire">
          <div className="questionnaire__title">
            アンケートにお答えください。
          </div>
          <div className="questionnaire__lead">
            今後、よりよいサービスをお客様に提供できるよう、今回お客様がご退会になられます理由についてお聞かせください。<br />
            お答えいただける範囲で構いませんので、何卒ご協力いただけますと幸いでございます。<br />
            どうぞよろしくお願いいたします。
          </div>
          <div className="questionnaire__note">
            <b>＊</b>は必須項目です。
          </div>

          {
            props.enquetes.map((enquete, index) => (
              <div key={index} className="questionnaire__item">
                <div className="questionnaire__question">
                  { enquete.title }
                  {
                    enquete.type === 'checkbox' &&
                    <small>（複数選択可）</small>
                  }
                  {
                    enquete.required &&
                    <b>＊</b>
                  }
                </div>
                <div className="questionnaire__answer">
                  <div className="questionnaire-form">
                    {
                      enquete.items.map((item, itemIndex) => (
                        <div key={itemIndex} className="questionnaire-form__item">
                          {
                            item.type === 'checkbox' &&
                            <label className={classNames("questionnaire-form__checkbox questionnaire-checkbox", {
                              'is-checked': props.answers[enquete.code] && props.answers[enquete.code][`item-${itemIndex}`]
                            })
                            }>
                              <React.Fragment>
                                <Field
                                  component="input"
                                  className="questionnaire-checkbox__checkbox"
                                  type="checkbox"
                                  name={`answers.${enquete.code}.item-${itemIndex}`}
                                  value={item.title}
                                />{ item.title }
                              </React.Fragment>                              
                            </label>
                          }
                          {
                            item.type === 'radio' &&
                            <label className={classNames("questionnaire-form__radio questionnaire-radio", {
                              'is-checked': props.answers[enquete.code] && props.answers[enquete.code][`item`] === item.title
                            })}>
                              <React.Fragment>
                                <Field
                                  component="input"
                                  className="questionnaire-radio__radio"
                                  type="radio"
                                  name={`answers.${enquete.code}.item`}
                                  value={item.title}
                                />{ item.title }
                              </React.Fragment>
                            </label>
                          }
                          {
                            item.hasText &&
                            <Field
                              component="input"
                              className="questionnaire-form__input input"
                              type="text"
                              name={`answers.${enquete.code}.${itemIndex}-text`}
                              disabled={
                                !(props.answers[enquete.code] && props.answers[enquete.code][`item-${itemIndex}`]) &&
                                !(props.answers[enquete.code] && props.answers[enquete.code][`item`] === item.title)
                              }
                            />
                          }
                          {
                            item.type === 'textarea' &&
                            <Field
                              component={renderTextareaField}
                              className="questionnaire-form__item textarea"
                              name={`answers.${enquete.code}.item`}
                              cols={30}
                              rows={10}
                            />
                          }
                        </div>
                      ))
                    }

                    {
                      // 必須項目を入力しないと送信できないようにするため、FormValidateで使ってるキーを定義
                      // TODO: より良い方法あれば改善したい
                      <Fields
                        component={renderIfFieldsHasError}
                        names={[enquete.code]}
                      >
                      </Fields>
                    }
                  </div>
                </div>
              </div>
            ))
          }

        </div>

        <div className="form__buttons">
          <div className="form__button form__button--flex-item">
            <form onSubmit={props.handleSubmit}>
              <button
                disabled={props.submitting || props.invalid}
                className="button button--primary button--xlarge">河合塾Oneから退会する</button>
            </form>
          </div>
          <div className="form__button form__button--flex-item">
            <button
              onClick={(e) => {
                e.preventDefault()
                props.history.push('/') }}
              className="button button--gray button--back button--xlarge">
                マイページに戻る</button>
          </div>
        </div>
      </div>
    </div>
  )

  const formFragment = (
    <div className="setting-box">
      <div className="setting-box__title setting-box__title--withdraw">
        退会
      </div>
      <div className="setting-box__lead">
        河合塾Oneを退会を選択される場合は、下記「退会する」ボタンからお進みください。
      </div>
      <div className="setting-box__cautions">
        <div className="withdrawal-caution">
          <div className="withdrawal-caution__head">
            退会すると、河合塾Oneの入塾金特典が受けられなくなります。
          </div>
          <div className="withdrawal-caution__body">
            河合塾Oneの本会員は、河合塾の入塾金（グリーンコース：33,000円、大学受験科：100,000円）が免除となります。<br />一度退会されると、入塾金特典はご利用いただけませんのでご注意ください。
          </div>
        </div>
      </div>
      <div className="form">

        <div className="form__buttons">
          <div className="form__button form__button--flex-item">
            <button
              onClick={(e) => {
                e.preventDefault()
                setConfirmWithdraw(true)
              }}
              disabled={props.submitting || props.invalid}
              className="button button--primary button--xlarge">退会する</button>
          </div>
          <div className="form__button form__button--flex-item">
            <button
              onClick={(e) => {
                e.preventDefault()
                props.history.push('/')
              }}
              className="button button--gray button--back button--xlarge">
              マイページに戻る
            </button>
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <React.Fragment>
      <PrivateHeader />
      <SiteSettingMainArea page="withdrawal" user={props.user}>
        { confirmWithdraw ? confirmFragment : formFragment }
      </SiteSettingMainArea>
    </React.Fragment>
  );
}

export default SettingWithdrawal;
