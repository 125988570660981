import React from 'react';

type ownProps = {
  type?: String;
  children?: React.ReactNode;
}

const SiteMainArea: React.FC<ownProps> = (props: ownProps = { type: 'simple' }) => {
  return (
    <main className="site-main site-main--register">
      <div className="site-main__main site-main__main--simple">
        <div className="site-main__main-inner site-main__main-inner--simple">
          <div className="site-main__main-contents">
            { props.children }
          </div>
        </div>
      </div>
    </main>
  );
}

export default SiteMainArea;
