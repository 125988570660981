import React, { useEffect } from 'react';
import { Link, Redirect, useParams, } from 'react-router-dom';

import PrivateHeader from 'containers/parts/PrivateHeader';
import { mappedProps } from 'containers/student/StudyQuestionLog';
import SiteMain from 'containers/parts/SiteMain';
import QuestionDetail from '../parts/QuestionDetail';
import QuestionAnswerDetail from '../parts/QuestionAnswerDetail';

const StudyQuestionLog: React.FC<mappedProps> = (props: mappedProps) => {
  const { id } = useParams<{ id: string; }>();

  useEffect(() => {
    // MARK: APIで常に最新の質問を取得する
    props.fetchQuestion(Number(id))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (!props.availableQuestions) {
    return <Redirect to='/' />
  }

  const record = props.getRecord(Number(id))

  return (
    <React.Fragment>
      <PrivateHeader />
      <SiteMain>
        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          <li className="breadcrumbs__item">
            先生からの回答
          </li>
        </ol>

        <div className="site-main__main-contents">

          <div className="page-title page-title--question">
            先生からの回答

            <Link
              to={`/study_question_logs`}
              className="page-title__link">
              質問の履歴を見る
            </Link>
          </div>

          {
            record && 
            <React.Fragment>
              <div className="page-subtitle">
                質問内容
              </div>

              <QuestionDetail question={record} type={props.user.type} />

              {
                record.answer_finished_at && 
                <React.Fragment>
                  <div className="page-subtitle">
                    回答内容
                  </div>
                  <QuestionAnswerDetail question={record} type={props.user.type} />
                </React.Fragment>
              }

              <div className="answer-form">
                <div className="answer-form__body">
                  <div className="answer-form__buttons">
                    <div className="answer-form__button">
                      <Link
                        to={`/study_question_logs`}
                        className="button button--large button--back button--gray">
                        質問一覧へ戻る
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          }

          <div className="back-home">
            <Link to='/' className="back-home__link">マイページへ戻る</Link>
          </div>
        </div>

        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          <li className="breadcrumbs__item">
            先生からの回答
          </li>
        </ol>
      </SiteMain>
    </React.Fragment>
  );
}

export default StudyQuestionLog