import React, { useEffect, useState } from 'react';
import { Link, useParams, } from 'react-router-dom';

import PrivateHeader from 'containers/parts/PrivateHeader';
import { mappedProps } from 'containers/student/StudyCourseCompleteResult';
import SiteMain from 'containers/parts/SiteMain';
import { CommonTestHintBalloon } from 'components/common/course_completion_test/HintBalloon';
import { CourseCompletionTestResultBoxItem } from 'components/common/course_completion_test/CourseCompletionTestResultBoxItem';
import { CourseCompletionTestConfirmModal } from 'components/student/common/CourseCompletionTestConfirmModal';
import classNames from 'classnames';
import CorrectAnswerCount from './common/CorrectAnswerCount';

export const StudyCourseCompleteResult: React.FC<mappedProps> = (props: mappedProps) => {
  const { unit_id } = useParams<{ unit_id: string }>();
  const [disabledStartStudy, setDisabledStartStudy] = useState<boolean>(false);
  const [showMore, setShowMore] = useState<boolean>(false);
  const [showConfirm, setShowConfirm] = useState(false)

  useEffect(() => {
    if (unit_id) {
      props.fetchData(Number(unit_id))

      // MARK: 以前の結果を表示を初期化
      setShowMore(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unit_id]);

  useEffect(() => {
    props.fetchStudyAchievementsLatest();
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  , []);

  const record = props.getRecord(Number(unit_id))
  const moreRecord = props.getMoreRecord(Number(unit_id))
  const hasMore = record && record.total_page > 1
  const courseId = record && record.course.id
  const testRecord = courseId && props.getCompletionTest(courseId)

  const canStudy = testRecord && ( testRecord.unit.attend || testRecord.unit.available )

  const startStudy = () => {
    if (!record || disabledStartStudy || !canStudy) return

    const return_pathname = `/study/course_complete_results/${record.unit.id}`
    const content_id = record.unit.content_id
    setDisabledStartStudy(true)
    props.startStudy({ content_id, return_pathname }).then((url) => {
      setDisabledStartStudy(false)
      window.location.href = url
    })
  }

  return (
    <React.Fragment>
      <CourseCompletionTestConfirmModal
        show={showConfirm}
        onSubmit={() => { startStudy() }}
        onClose={() => setShowConfirm(false)} />
      <PrivateHeader />
      <SiteMain>
        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          {
            record &&
            <>
              <li className="breadcrumbs__item">
                <Link to={`/study/course_results/${record.curriculum.id}/${record.course.id}`} className="breadcrumbs__link">
                  科目・単元テスト一覧
                </Link>
              </li>
              <li className="breadcrumbs__item">
                科目修了テスト
              </li>
            </>
          }
        </ol>

        <div className="site-main__main-contents">
          {
            record && <>
            <div className="page-title">
            <div className="page-title__left">
              { record.curriculum.name }&nbsp;
              <div className="page-title__badge">
                { record.course.name }
              </div>
              </div>
              <div className="page-title__correct-count">
              {props.student.studyAchievementsLatest.record && <>
                 <CorrectAnswerCount correctAnswerCount={props.student.studyAchievementsLatest.record.study_achievements.consecutive_correct_answer_count}/>
                </>}
              </div>   
            </div>
            <div className="test-box">
              <div className="test-box__title test-box__title--large">
                科目修了テスト
              </div>

              <CommonTestHintBalloon />

              <div className="test-box__body">
                <div className="completion-test-result-box">
                  <div className="completion-test-result-box__head completion-test-result-box__head--completion">
                    <div className="completion-test-result-box__point-head">
                      合計点数
                    </div>
                    <div className="completion-test-result-box__expectation-head _pc">
                      共通テスト予想点数
                    </div>
                    <div className="completion-test-result-box__date-head">
                      実施日時
                    </div>
                  </div>
                  <div className="completion-test-result-box__body is-active">
                    {
                      record.results.length <= 0 && 
                      <div className="completion-test-result-box__result completion-test-result-box__result--empty">
                        テスト結果がありません
                      </div>
                    }
                    {
                      record.results.map((x, index) => 
                      <CourseCompletionTestResultBoxItem key={index} result={x} />)
                    }
                  </div>

                  {
                    hasMore && 
                    <div className="completion-test-result-box__more">
                      <div className="more-button more-button--result">
                        <div
                          className={classNames("more-button__text more-button__text--result", {
                            'is-active': showMore,
                          })}
                          onClick={e => {
                            e.preventDefault()
                            setShowMore(!showMore)
                            if (!moreRecord) {
                              props.fetchMore()
                            }
                          }}
                        >
                          以前の結果を表示
                        </div>
                      </div>
                    </div>
                  }
                  {
                    moreRecord && showMore &&
                    <div className="completion-test-result-box__body is-active">
                      {
                        moreRecord.results.length <= 0 && 
                        <div className="completion-test-result-box__result completion-test-result-box__result--empty">
                          テスト結果がありません
                        </div>
                      }
                      {
                        moreRecord.results.map((x, index) =>
                          <CourseCompletionTestResultBoxItem key={index} result={x} />)
                      }
                    </div>
                  }

                  {
                    canStudy &&
                    <div className="completion-test-result-box__button">
                      <a className={classNames("button button--large button--primary", {
                        'is-disabled': disabledStartStudy,
                      })} href="#!" onClick={e => {
                        e.preventDefault()
                        setShowConfirm(true)
                      }}>
                        {
                          ( testRecord && testRecord.unit.attend ) ? '再テストを受ける' : 'テストを受ける'
                        }
                      </a>
                    </div>
                  }

                  <div className="completion-test-result-box__button">
                    <Link
                      to={`/study/course_results/${record.curriculum.id}/${record.course.id}`}
                      className="button button--xlarge button--gray button--back">
                      テスト一覧へ戻る
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            </>
          }
          <div className="back-home">
            <Link to='/' className="back-home__link">マイページへ戻る</Link>
          </div>
        </div>

        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          {
            record &&
            <>
              <li className="breadcrumbs__item">
                <Link to={`/study/course_results/${record.curriculum.id}/${record.course.id}`} className="breadcrumbs__link">
                  科目・単元テスト一覧
                </Link>
              </li>
              <li className="breadcrumbs__item">
                科目修了テスト
              </li>
            </>
          }
        </ol>
      </SiteMain>
    </React.Fragment>
  );
}