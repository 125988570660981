import React from 'react';
import classNames from 'classnames';
import { Doughnut } from 'react-chartjs-2';

type props = {
  label?: string;
  rate: number;
  colorType?: number;
}

const colorTypes: any = {
  1: 'rgba(0,194,175,1)',
  2: 'rgba(51,178,215,1)',
}

const colorPercentageClasses: any = {
  1: '',
  2: 'circle-chart__percentage--manual',
}

const CircleChart: React.FC<props> = (props: props) => {
  const {
    label,
    rate,
  } = props
  const color = colorTypes[props.colorType || 1]
  const colorPercentageClass = colorPercentageClasses[props.colorType || 1]
  const isLongLabel = label && label.length >= 6
  return (
    <div className="circle-chart">
      <Doughnut
        width={220}
        height={220}
        data={{
          datasets: [{
            data: [rate * 100, 100 - rate * 100],
            backgroundColor: [color, '#e6eef4']
          }]
        }}
        options={{
          cutoutPercentage: 60,
          events: null,
          legend: {
            display: false
          }
        }}
      />
      <div className="circle-chart__text">
        {
          label &&
          <div className={classNames("circle-chart__subject", {
            'circle-chart__subject--basic': isLongLabel,
          })}>
            { label }
          </div>
        }
        <div className={`circle-chart__percentage circle-chart__percentage ${colorPercentageClass}`}>
          { Math.floor(rate * 100) }
          <span className="circle-chart__percent">%</span>
        </div>
      </div>
    </div>
  );
}

export default CircleChart
