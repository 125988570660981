import React, { useEffect, useRef } from 'react';
import { Field } from 'redux-form';
import { useParams } from 'react-router-dom';
import Moment from 'react-moment';

import { mappedProps } from 'containers/trainer/StudentMessages';

const nl2br = require('react-nl2br');

const TrainerStudentMessages: React.FC<mappedProps> = (props: mappedProps) => {
  const { id } = useParams();
  const scrollElement = useRef(null);

  useEffect(() => {
    if (id) {
      props.fetchData(Number(id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const student = props.getStudent(Number(id))
  const messages = props.getMessages(Number(id))

  useEffect(() => {
    if (scrollElement.current) {
      const scrollHeight = (scrollElement.current as any).scrollHeight;
      (scrollElement.current as any).scroll(0, scrollHeight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  return (
    <>
      <div
        ref={scrollElement}
        className="trainer-conversation-list">
        {
          student && messages && messages.map((record, index) => (
            <div key={index} className="trainer-conversation-list__item">
              {
                record.trainer &&
                <div className={`question-box question-box--trainer`}>
                  <div className="question-box__inner">
                    <div className="question-box__title">
                      { nl2br(record.body) }
                    </div>
                    <div className="question-box__spec">
                      <span className={`question-box__name question-box__name--trainer`}>
                        { record.trainer.nick_name }
                      </span>
                      <span className="question-box__datetime">
                        {/* とりあえず対応: mobile safariで2019-12-05 17:42:21 +0900の形式の日付を処理できない */}
                        <Moment format="M月D日　HH:mm">
                          { record.created_at.replace(/-/g, '/') }
                        </Moment>
                      </span>
                    </div>
                  </div>
                </div>
              }
              {
                !record.trainer &&
                <div className={`question-box question-box--student`}>
                  <div className="question-box__inner">
                    <div className="question-box__title">
                      { nl2br(record.body) }
                    </div>
                    <div className="question-box__spec">
                      <span className={`question-box__name question-box__name--student`}>
                        { student.nick_name }
                      </span>
                      <span className="question-box__datetime">
                        {/* とりあえず対応: mobile safariで2019-12-05 17:42:21 +0900の形式の日付を処理できない */}
                        <Moment format="M月D日　HH:mm">
                          { record.created_at.replace(/-/g, '/') }
                        </Moment>
                      </span>
                    </div>
                  </div>
                </div>
              }
            </div>
          ))
        }
      </div>

      {
        messages && 
        <div className="trainer-conversation-form">
          <form onSubmit={props.handleSubmit}>
            <div className="trainer-conversation-form__textarea">
              <Field
                component="textarea"
                className="textarea textarea--trainer"
                name="body"
              />
            </div>
            <div className="trainer-conversation-form__button">
              <button
              disabled={props.pristine || props.submitting || props.invalid}
              className="button button--large button--no-chevron button--trainer">
                送信する</button>
            </div>
          </form>
        </div>
      }
    </>
  );
}

export default TrainerStudentMessages