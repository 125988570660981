import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, InjectedFormProps, SubmissionError, FormErrors } from 'redux-form'
import { RouteProps } from 'react-router'
import queryString from 'query-string'

import PasswordReset from 'components/common/PasswordReset';
import { AppState } from "store";
import userActions from 'actions/userActions';

interface FormData {
  password?: string;
  passwordConfirmation?: string;
  [key: string]: string|undefined;
}

export const FormValidate = (values: FormData) => {
  const errors: FormErrors<FormData> = {}
  const requiredFields: string[] = [
    'password',
    'passwordConfirmation',
  ]

  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = '入力してください'
    }
  })

  if (values.password !== values.passwordConfirmation) {
    errors.passwordConfirmation = 'パスワードが一致しません。再度入力してください。'
  }

  return errors
}

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch })

const mapStateToProps = (appState: AppState, routeProps: RouteProps) => {
  const queryParams = queryString.parse(routeProps.location!.search);

  let token
  if (queryParams.token instanceof Array) {
    token = queryParams.token[0]
  } else {
    token = queryParams.token
  }

  return {
    token,
    initialValues: {},
  }
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  { dispatch }: ReturnType<typeof mapDispatchToProps>) => {
  return {
    ...stateProps,
    onSubmit: (values: FormData) => {
      return new Promise<void>((resolve, reject) => {
        dispatch(userActions.createPasswordResetter({
          params: {
            token: stateProps.token!,
            password: values.password!,
          },
          promises: { resolve, reject }
        }))
      }).catch((e: Error) => {
        throw new SubmissionError({})
      })
    }
  }
}

type connectMappedProps = ReturnType<typeof mergeProps>
export type mappedProps = connectMappedProps & InjectedFormProps<FormData, connectMappedProps>

const form = reduxForm<FormData, connectMappedProps>({
  form: 'password_reset',
  validate: FormValidate
})(PasswordReset)

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(form)
