import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RouteProps } from 'react-router'
import queryString from 'query-string'

import { AppState } from "store";

import { RegisterBorderResults } from 'components/student/RegisterBorderResults';
import { FormErrors, formValueSelector, InjectedFormProps, reduxForm, SubmissionError } from 'redux-form';
import { prefectures } from 'utils/constants'
import borderResultsActions from 'actions/borderResultsActions';

export const formKey = 'register-border-results'

const excludeAreas4 = [
  '1', '2', '4', '5', '8', '9', '10', '11', '12', '13', '15', '16', '17', '18', '20', '23', '25', '26', '27', '28', '29', '30', '31', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '45', '47',
]

const excludeAreas6 = [
  '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '28', '29', '30', '31', '32', '33', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47',
]

const excludeAreas7 = [
  '3', '7', '10', '11', '13', '18', '20', '23', '24', '29', '30', '31', '35', '36', '38', '41', '42', '43', '45',
]

const categories = [
  { key: '1', val: '国立', excludeAreas: [] },
  { key: '2', val: '公立', excludeAreas: ['9', '36', '41', '46'] },
  { key: '3', val: '私立', excludeAreas: ['32'] },
  { key: '4', val: '国公立短大', excludeAreas: excludeAreas4  },
  { key: '5', val: '私立短大', excludeAreas: ['32'] },
  { key: '6', val: '文科省所管外大学校', excludeAreas: excludeAreas6 },
  { key: '7', val: '文科省所管外短期大学校', excludeAreas: excludeAreas7 },
  { key: '8', val: '専門学校', excludeAreas: ['29','32','37','47'] },
  // { key: '9', val: 'その他' },
]

const areas = prefectures.map((x, index) => ({
  key: (index + 1).toString(),
  val: x
}))

const results = [
  { key: '0', val: '未受験' },
  { key: '1', val: '合格' },
  { key: '2', val: '繰り上げ合格' },
  { key: '3', val: '特待生合格' },
  { key: '4', val: '不合格' },
  { key: '5', val: '補欠不合格' },
  { key: '6', val: '第一段階選抜不合格' },
  { key: '7', val: '補欠結果待ち' },
]

interface FormData {
  category?: string;
  area?: string;
  university?: string;
  faculty?: string;
  department?: string;
  result?: string;
  [key: string]: string|undefined;
}

export const FormValidate = (values: FormData) => {
  const errors: FormErrors<FormData> = {}
  const requiredFields: string[] = [
    'category',
    'area',
    'university',
    'faculty',
    'department',
    'result',
  ]

  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = '入力してください'
    }
  })

  return errors
}

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch })

const mapStateToProps = (appState: AppState, routeProps: RouteProps) => {
  const queryParams = queryString.parse(routeProps.location!.search);

  let token
  if (queryParams.token instanceof Array) {
    token = queryParams.token[0]
  } else {
    token = queryParams.token
  }

  const selector = formValueSelector(formKey);
  const categoryValue = selector(appState, 'category')
  const areaValue = selector(appState, 'area')
  const universityValue = selector(appState, 'university')
  const facultyValue = selector(appState, 'faculty')
  const departmentValue = selector(appState, 'department')
  const resultValue = selector(appState, 'result')

  const borderResultsState = appState.borderResults

  const selectedCategory = categories.find(x => x.key === categoryValue)
  const availableAreas = selectedCategory && areas.filter(x => !selectedCategory.excludeAreas.includes(x.key))

  return {
    categories,
    results,

    token,

    categoryValue,
    areaValue,
    universityValue,
    facultyValue,
    departmentValue,
    resultValue,

    availableAreas,

    borderResultsState,
    ...routeProps,
  }
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  { dispatch }: ReturnType<typeof mapDispatchToProps>) => {
  return {
    ...stateProps,
    verifyToken: () => {
      const token = stateProps.token || null
      dispatch(borderResultsActions.verifyToken({ token }))
    },
    fetchUniversities: () => {
      const category = stateProps.categoryValue
      const area = stateProps.areaValue
      dispatch(borderResultsActions.fetchUniversitiesRecord({
        category,
        area,
      }))
    },
    fetchFaculties: () => {
      const area = stateProps.areaValue
      const university = stateProps.universityValue
      dispatch(borderResultsActions.fetchFacultiesRecord({
        area,
        university,
      }))
    },
    fetchDepartments: () => {
      const university = stateProps.universityValue
      const faculty = stateProps.facultyValue
      dispatch(borderResultsActions.fetchDepartmentsRecord({
        university,
        faculty,
      }))
    },
    onSubmit: (values: FormData) => {
      return new Promise<void>((resolve, reject) => {
        dispatch(borderResultsActions.register({
          params: {
            token: stateProps.token!,
            answer: {
              category: values.category!,
              area: values.area!,
              university: values.university!,
              faculty: values.faculty!,
              department: values.department!,
              result: values.result!,
            },
          },
          promises: { resolve, reject }
        }))
      }).catch((e: Error) => {
        throw new SubmissionError({})
      })
    }
  }
}

export type connectMappedProps = ReturnType<typeof mergeProps>
export type mappedProps = connectMappedProps & InjectedFormProps<FormData, connectMappedProps>

const form = reduxForm<FormData, connectMappedProps>({
  form: formKey,
  validate: FormValidate
})(RegisterBorderResults)

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(form)