import React, { useEffect } from 'react';
import { Link, useParams, } from 'react-router-dom';

import PrivateHeader from 'containers/parts/PrivateHeader';
import { mappedProps } from 'containers/student/EntrustStudyCourse';
import SiteMain from 'containers/parts/SiteMain';
import CircleChart from 'components/parts/CircleChart';
import CorrectAnswerCount from './common/CorrectAnswerCount';

const EntrustStudyCourse: React.FC<mappedProps> = (props: mappedProps) => {
  const { course_id } = useParams<{ course_id: string }>();

  useEffect(() => {
    if (course_id) {
      props.fetchData(Number(course_id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course_id]);
  useEffect(() => {
    props.fetchStudyAchievementsLatest();
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  , []);
  const page = props.getRecord(Number(course_id))

  return (
    <React.Fragment>
      <PrivateHeader />
      <SiteMain>
        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          {
            page && 
            <React.Fragment>
              <li className="breadcrumbs__item">
                <Link to={`/entrust_setting/${page.curriculum.id}`} className="breadcrumbs__link">
                  おまかせ学習{"(" + page.curriculum.name + ")"}
                </Link>
              </li>
              <li className="breadcrumbs__item">
                { page.course.name }
              </li>
            </React.Fragment>
          }
        </ol>

        {
          page &&
          <div className="site-main__main-contents">
            <div className="page-title">
              <div className="page-title__left">
                おまかせ学習設定{"（" + page.curriculum.name + "）"}&nbsp;       
                <div className="page-title__badge">
                 { page.course.name }
                </div>
              </div>
              <div className="page-title__correct-count">
                {props.student.studyAchievementsLatest.record && <>
                <CorrectAnswerCount correctAnswerCount={props.student.studyAchievementsLatest.record.study_achievements.consecutive_correct_answer_count}/>
                </>}
              </div>
            </div>
            <div className="subject-chart-list">
              <div className="subject-chart-list__head">
                <div className="subject-chart-list__title">
                  {
                    page.curriculum.al_engine_connected ? '単元（コンプリート率）': '単元（教材学習終了率）'
                  }
                </div>
              </div>

              <div className="subject-chart-list__items">
                {
                  page.units.map((unit, index) => (
                    <div key={index} className="subject-chart-list__item">
                      <div className="subject-chart-list__chart">
                        <CircleChart rate={unit.understanding / 100} colorType={page.curriculum.al_engine_connected ? 1 : 2} />
                      </div>
                      <div className="subject-chart-list__subject">
                        <Link
                          to={`/entrust_setting/units/${unit.id}`}
                          className="subject-chart-list__subject-link">
                          { unit.name }
                        </Link>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>

            <div className="back-home">
              <Link to='/' className="back-home__link">マイページへ戻る</Link>
            </div>
          </div>
        }

        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          {
            page && 
            <React.Fragment>
              <li className="breadcrumbs__item">
                <Link to={`/entrust_setting/${page.curriculum.id}`} className="breadcrumbs__link">
                  おまかせ学習{ "(" +  page.curriculum.name + ")"}
                </Link>
              </li>
              <li className="breadcrumbs__item">
                { page.course.name }
              </li>
            </React.Fragment>
          }
        </ol>
      </SiteMain>
    </React.Fragment>
  );
}

export default EntrustStudyCourse
