import actionCreatorFactory from 'typescript-fsa';
import * as Models from "models/api";

const actionCreator = actionCreatorFactory('instructor');

const actions = {
  fetchMypage:
    actionCreator<{ type: 'not-answered'|'processing'|'answered' }>(
      'FETCH_MYPAGE'),
  setMypage:
    actionCreator<{ record: Models.InstructorMypage; }>(
      'SET_MYPAGE'),
  fetchQuestionRecords:
    actionCreator<{ initialize: boolean; type: 'not-answered'|'processing'|'answered' }>(
      'FETCH_QUESTION_RECORDS'),
  setQuestionRecords:
    actionCreator<{ page: number; type: 'not-answered'|'processing'|'answered'; records: Models.QuestionRecords }>(
      'SET_QUESTION_RECORDS'),
  fetchStudentQuestionRecords:
    actionCreator<{ student_id: number; initialize: boolean; }>(
      'FETCH_STUDENT_QUESTION_RECORDS'),
  setStudentQuestionRecords:
    actionCreator<{ student_id: number; page: number; records: Models.QuestionRecords }>(
      'SET_STUDENT_QUESTION_RECORDS'),
  fetchQuestionDetail:
    actionCreator<{ id: number }>(
      'FETCH_QUESTION_DETAIL'),
  setQuestionDetail:
    actionCreator<{ record: Models.QuestionDetail | null, loading: boolean; question_id: number; }>(
      'SET_QUESTION_DETAIL'),
  setProcessingQuestion:
    actionCreator<{ record: Models.Question | null; }>(
      'SET_PROCESSING_QUESTION'),
  updateQuestionDetailToProcessing:
    actionCreator<void>(
      'UPDATE_QUESTION_DETAIL_TO_PROCESSING'),
  updateQuestionDetailToNotAnswered:
    actionCreator<void>(
      'UPDATE_QUESTION_DETAIL_TO_NOT_ANSWERED'),
  updateQuestionDetailToNotPending:
    actionCreator<{
      promises?: { resolve: () => void, reject: (e: Error) => void}
    }>(
      'UPDATE_QUESTION_DETAIL_TO_NOT_PENDING'),
  updateProfile:
    actionCreator<{ 
      params: Models.UpdateInstructorProfileParams;
      promises?: { resolve: () => void, reject: (e: Error) => void}
    }>('UPDATE_PROFILE'),
  createQuestionAnswer:
    actionCreator<{ 
      params: Models.CreateQuestionAnswerParams;
      promises?: { resolve: () => void, reject: (e: Error) => void}
    }>('CREATE_QUESTION_ANSWER'),
  createQuestionPendingAnswer:
    actionCreator<{ 
      params: Models.CreateQuestionPendingAnswerParams;
      promises?: { resolve: () => void, reject: (e: Error) => void}
    }>('CREATE_QUESTION_PENDING_ANSWER'),
};

export default actions;