import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { AppState } from "store";
import targetComponent from 'components/student/RecommendStudyPretest';
import studentActions from 'actions/studentActions';
import * as Models from 'models/api';
import { getRecommendedCurriculums }  from 'utils/store'

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch })

const mapStateToProps = (appState: AppState) => {
  const user = appState.session.user! as Models.StudentUser
  const curriculums = getRecommendedCurriculums(user).filter(x => x.al_engine_connected)

  return {
    user,
    curriculums,
    student: appState.student,
  }
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  { dispatch } : ReturnType<typeof mapDispatchToProps>) => {
  return {
    ...stateProps,
    getCurriculum: (code: string | undefined) => {
      return stateProps.curriculums.find(x => x.code === code || x.id === Number(code))
    },
    fetchUnitTopics: (unit_id: number) => {
      dispatch(studentActions.fetchStudyUnit({ unit_id }))
    },
    getUnitTopics: (unit_id: number) => {
      const studyUnit = stateProps.student.studyUnit
      if (!studyUnit.record || studyUnit.unit_id !== unit_id) {
        return null
      }
      return studyUnit.record.topics
    },
    getRecord: (curriculum_id: number, index: number) => {
      const page = stateProps.student.recommendStudy
      if (page.curriculum_id !== curriculum_id || !page.records) {
        return null
      }
      return page.records[index]
    },
    startStudy: (params: { content_id: number, return_pathname: string; rank: number; learned?: boolean; }) => {
      return new Promise<string>((resolve, reject) => {
        dispatch(studentActions.startStudy({
          params,
          promises: { resolve, reject }
        }))
      })
    },
    skipStudy: (params: { content_id: number, rank: number; }) => {
      return new Promise<string>((resolve, reject) => {
        dispatch(studentActions.skipRecommendStudyPretest({
          params,
          promises: { resolve, reject }
        }))
      })
    }
  }
}

type connectMappedProps = ReturnType<typeof mergeProps>
export type mappedProps = connectMappedProps;

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(targetComponent)