import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';
import { mappedProps } from 'containers/student/SettingTrainerEdit2';
import PrivateHeader from 'containers/parts/PrivateHeader';
import SiteSettingMainArea from '../common/SiteSettingMainArea';
import classNames from 'classnames';
import { Field } from 'redux-form';

const SettingTrainerEdit2: React.FC<mappedProps> = (props: mappedProps) => {
  const history = useHistory()
  const [confirm, setConfirm] = useState(false)

  return (
    <React.Fragment>
      <PrivateHeader />
      <SiteSettingMainArea page="trainer_edit" user={props.user}>
        {
          props.submitSucceeded &&
          <div className="setting-box">
            <div className="setting-box__title setting-box__title--trainer">
              トレーナー申込・変更
            </div>
            <div className="setting-message">
              トレーナーの申し込みが完了しました。
            </div>
            <div className="setting-box__button">
              <button
                onClick={() => { history.push('/') }}
                className="button button--gray button--back button--xlarge">マイページに戻る</button>
            </div>
          </div>
        }
        {
          !props.submitSucceeded && confirm && 

          <div className="setting-box">
            <div className="setting-box__title setting-box__title--trainer">
              トレーナー申込・変更
            </div>
            <div className="setting-box__subtitle">
              トレーナーに求める条件
            </div>
            <div className="setting-box__body">
              <div className="setting-box__form">
                <div className="form form--confirm">
                  {
                    props.trainerRequestKeys.map((y, index) => (
                      <div key={y} className="form__item">
                        <div className="form__label">
                          第{index+1}希望
                        </div>
                        <div className="form__field">
                          {
                            props.currentTrainerRequests[index]
                          }
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>

            <div className="form">
              <div className="form__buttons">
                <div className="form__button form__button--flex-item">
                  <form onSubmit={props.handleSubmit}>
                    <button className="button button--primary button--xlarge">申し込む</button>
                  </form>
                </div>
                <div className="form__button form__button--flex-item">
                  <button
                    onClick={() => { setConfirm(false) }}
                    className="button button--gray button--back button--xlarge">修正する</button>
                </div>
              </div>
            </div>
          </div>
        }

        {
          !props.submitSucceeded && !confirm && 
          <div className="setting-box">
            <div className="setting-box__title setting-box__title--trainer">
              トレーナー申込・変更
            </div>

            <div className="setting-box__subtitle">
              トレーナーサービス
            </div>
            <div className="setting-box__body">
              <strong>現在ご利用いただいておりません。</strong>
            </div>

            <div className="setting-box__subtitle">
              トレーナーサービスとは
            </div>
            <div className="setting-box__body">
              <div className="setting-box__text">
                河合塾Oneでは、トレーナー担任があなたの学習を、チャットを通じてサポートします。<br />
                最適な学習方法や学習習慣についてのアドバイス、進路についての相談など、あなたの悩みにしっかりこたえます。<br />
                トレーナー担任は、あこがれの難関大学合格を果たした現役の大学生です。<br />
                トレーナーと二人三脚で、成績アップ、大学合格を目指していきましょう。
                <div className="setting-box__note">
                  <strong>※トレーナーサービスはオプションです。１ヶ月更新制で月額1,100円（税込）の費用が加算されます。</strong>
                </div>
              </div>
            </div>

            <div className="setting-box__subtitle">
              トレーナーに求める条件
            </div>
            <div className="setting-box__body">
              <div className="setting-box__note">
                それぞれ１つづつお選びください
              </div>
              <div className="setting-box__form">
                <div className="form">
                  {
                    props.trainerRequestKeys.map((y, index) => (
                      <div key={y} className="form__item form__item--flex">
                        <div className="form__label form__label--flex-item">
                          第{index+1}希望
                        </div>
                        <div className="form__field form__field--flex-item">
                          {
                            props.trainerRequests.map(x => (
                              <label key={x} className={
                                classNames("radio form__radio-label", {
                                  'is-checked' : props.currentTrainerRequests[index] === x })}>
                                <Field
                                  component="input"
                                  className="radio__radio form__radio"
                                  type="radio"
                                  name={y}
                                  value={x}
                                />
                                {x}
                              </label>
                            ))
                          }
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>

            <div className="form">
              <div className="form__buttons">
                <div className="form__button form__button--flex-item">
                  <button
                    onClick={() => { setConfirm(true) }}
                    disabled={props.pristine || props.submitting || props.invalid}
                    className="button button--primary button--xlarge">サービスを申し込む</button>
                </div>
                <div className="form__button form__button--flex-item">
                  <button
                    onClick={() => { history.push('/') }}
                    className="button button--gray button--back button--xlarge">マイページに戻る</button>
                </div>
              </div>
            </div>
          </div>
        }

      </SiteSettingMainArea>
    </React.Fragment>
  );
}

export default SettingTrainerEdit2;
