import React from 'react';
import PublicHeader from '../parts/PublicHeader';

const SignupTerm: React.FC = () => {
  return (
    <React.Fragment>
      <PublicHeader />
      <main className="site-main site-main--register">
        <div className="site-main__main site-main__main--simple">
          <div className="site-main__main-inner site-main__main-inner--simple">
            <div className="site-main__main-contents">
              <div className="register-box">
                <div className="register-box__subtitle register-box__subtitle--terms">
                  個人情報の取り扱いについて
                </div>
                <div className="register-box__terms">
                  <div className="terms">
                    <div className="terms__text">
                      河合塾グループは、ご入力いただいた個人情報および入会後にご提供いただいた個人情報を厳重に取り扱い、適正な個人情報の管理を実施します。
                    </div>
                    <div className="terms__text">
                      ①利用目的<br/>
                      ・個人を特定できない方法・形式による統計資料の作成、各種アンケートなどによる資料作成協力の依頼、および作成した資料・集計結果の開示<br/>
                      ・河合塾グループが主催する講習案内、模擬試験案内、イベント情報などのおすすめ情報を電子メールでご案内させて頂くことがございます。<br/>
                      なお、ご記入いただいた「力ナ氏名」「生年月日」などの項目を利用して、河合塾グループが主催する講習、模擬試験、イベントなどへのご参加の履歴情報と結びつけ、進学や進路選定、および学生生活にお役に立つ情報の提供をはじめとする河合塾グループからの各種ご案内や、進学後に登録できる河合塾同窓会(ALUMNl)のご案内をさせていただくことがございます。
                    </div>
                    <div className="terms__text">
                      ②個人情報提供の任意性<br/>
                      個人情報の提供は任意です。ただし、ご同意いただけない個人情報がある場合、①に記載した利用目的の諸事項やご案内、また、入会時や入会後のサービス提供等に支障が生じる場合があります。あらかじめご了承ください。
                    </div>
                    <div className="terms__text">
                      ③個人情報の開示・訂正・削除<br/>
                      個人情報の開示・訂正・削除を希望される場合は、その旨お申し出ください。速やかに必要な手続きをお取りいたします。
                    </div>
                    <div className="terms__text">
                      ④個人情報の共同利用<br/>
                      「個人情報の取り扱いに関しての契約」を取り交わした河合塾グループ内の法人(教育関連事業を営む学校法人と株式会社)が、前項①の利用目的の範囲内で共同利用します。
                    </div>
                    <div className="terms__text">
                      ⑤個人情報の取り扱いの委託<br/>
                      個人情報の取り扱いの一部または全部を、河合塾グループ内の法人以外に委託する場合は、個人情報を適切に取り扱っていると認められる委託先を選定し、「個人情報の取り扱いに関しての契約」を取り交わすとともに適正な管理および監督を行います。
                    </div>
                    <div className="terms__text">
                      ⑥ご自分以外の方の個人情報の提供<br/>
                      お客様がご自分以外の方の個人情報を河合塾グループに提供される際には、必ずその方に、個人情報を提供すること、および提供される情報の利用目的についてご説明いただき、その方の同意を得たうえでご提供ください。
                    </div>
                    <div className="terms__text">
                      ⑦中学生以下の方の個人情報の提供<br/>
                      中学生以下の方の個人情報を河合塾グループに提供される際は、必ずその保護者（法定代理人を含む）の方の同意のもとでご提供ください。
                    </div>
                    <div className="terms__text">
                      ⑧クッキー（Cookie）について<br/>
                      河合塾グループWebサイトには、お客様に一層便利に河合塾グループWebサイトをご利用いただくため、また、閲覧履歴を取得するために「クッキー（Webサイトからお客様のコンピュータを識別する技術）」を使っているページがあります。<br/>
                      お客様は、ブラウザの設定によりクッキーの受け取りを拒否したり、クッキーを受け取ったとき警告を表示させたりすることができます。<br/>
                      お客様がクッキーの受け取りを拒否された場合も本Webサイトをご利用いただけますが、一部機能がご利用になれない場合があります。<br/>
                      なお、クッキーを利用して取得される情報に、お客様個人を特定できる個人情報は一切含まれません。
                    </div>
                    <div className="terms__text">
                      ⑨クッキー（Cookie）の利用目的および第三者提供について<br/>
                      河合塾グループのWebサイトでは、クッキーを利用して取得したお客様の閲覧履歴情報を、以下の目的で利用します。<br/>
                      1.河合塾グループWebサイトの利用状況の把握およびサービスの企画、向上のための集計、分析<br/>
                      2.広告配信（一部のサイト）<br/>
                      河合塾グループのWebサイトの中には、上記の目的のために、お客様のクッキー情報を第三者に提供している場合があります。詳しくは各サイトのサイトポリシーやセキュリティポリシーを参照してください。
                    </div>
                    <div className="terms__text">
                      ⑩個人情報取扱事業者および管理責任者<br/>
                      株式会社河合塾One　取締役　畑秀史
                    </div>
                    <div className="terms__text">
                      ⑪個人情報の安全管理<br/>
                      当社は、個人情報保護体制および規程、実施手順書などの継続的な見直しおよび改善を行います。
                    </div>
                    <div className="terms__text">
                      ⑫個人情報の取り扱いに関するお問い合わせ先<br/>
                      株式会社 河合塾One<br/>
                      電話番号：03-6811-5547（代）<br/>
                      E-Mail：info_one@kawaijukuone.co.jp<br/>
                      受付時間：10:00～17:00（土日祝、年末年始を除く）
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
}

export default SignupTerm;
