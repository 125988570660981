import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router'

import { AppState } from "store";
import { curriculums } from 'utils/constants'
import targetComponent from 'components/instructor/StudentStudyLogs';
import studyResultActions from 'actions/studyResultActions';

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch })

const mapStateToProps = (appState: AppState, ownProps: RouteComponentProps) => {
  return {
    user: appState.session.user!,
    instructor: appState.instructor,
    studyResult: appState.studyResult,
    ...ownProps,
  }
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  { dispatch }: ReturnType<typeof mapDispatchToProps>) => {
  return {
    ...stateProps,
    getCurriculum: (code: string| undefined) => {
      return curriculums.find(x => x.code === code)
    },
    fetchFirst: (curriculum_id: number, student_id: number) => {
      dispatch(studyResultActions.fetchStudent({ student_id }))
      dispatch(studyResultActions.fetchStudyLogRecords({ student_id, curriculum_id, initialize: true }))
    },
    getStudent: (student_id: number) => {
      const student = stateProps.studyResult.student
      if (student.student_id !== student_id) {
        return null
      }
      return student.record
    },
    getStudyLogs: (curriculum_id: number, student_id: number) => {
      const page = stateProps.studyResult.studyLog
      if (page.student_id !== student_id || page.curriculum_id !== curriculum_id) {
        return null
      }
      return page.records
    },
    hasNextStudyLog: (curriculum_id: number, student_id: number) => {
      const page = stateProps.studyResult.studyLog
      if (page.student_id !== student_id || page.curriculum_id !== curriculum_id) {
        return false
      }
      return page.has_next
    },
    fetchNext: () => {
      const studyLog = stateProps.studyResult.studyLog
      const student_id = studyLog.student_id!
      const curriculum_id = studyLog.curriculum_id!
      dispatch(studyResultActions.fetchStudyLogRecords({ student_id, curriculum_id, initialize: false }))
    }
  }
}

export type mappedProps = ReturnType<typeof mergeProps>
export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(targetComponent))
