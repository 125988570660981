import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router'

import { AppState } from "store";
import targetComponent from 'components/student/StudyUnitResult';
import studentActions from 'actions/studentActions';

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch })

const mapStateToProps = (appState: AppState, ownProps: RouteComponentProps) => {
  return {
    user: appState.session.user!,
    student: appState.student,
    ...ownProps,
  }
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  { dispatch }: ReturnType<typeof mapDispatchToProps>) => {

  return {
    ...stateProps,
    fetchData: (unit_id: number) => {
      dispatch(studentActions.fetchStudyUnitResult({ unit_id, initialize: true }))
    },
    fetchMore: () => {
      const page = stateProps.student.studyUnitResult
      const unit_id = page.unit_id
      if (!unit_id) return
      dispatch(studentActions.fetchStudyUnitResult({ unit_id, initialize: false }))
    },
    getRecord: (unit_id: number) => {
      const page = stateProps.student.studyUnitResult
      if (page.unit_id !== unit_id) {
        return null
      }
      return page.record
    },
    getMoreRecord: (unit_id: number) => {
      const page = stateProps.student.studyUnitResult
      if (page.unit_id !== unit_id) {
        return null
      }
      return page.moreRecord
    },
    startStudy: (params: { content_id: number, return_pathname: string }) => {
      return new Promise<string>((resolve, reject) => {
        dispatch(studentActions.startStudy({
          params,
          promises: { resolve, reject }
        }))
      })
    }
  }
}

export type mappedProps = ReturnType<typeof mergeProps>
export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(targetComponent))
