import React, { useEffect } from 'react';
import { Link, useParams, Redirect, useLocation } from 'react-router-dom';

import { mappedProps } from 'containers/instructor/Question';
import PrivateHeader from 'containers/parts/PrivateHeader';
import SiteMain from 'containers/parts/SiteMain';
import QuestionDetail from '../parts/QuestionDetail';
import QuestionAnswerDetail from '../parts/QuestionAnswerDetail';

const Question: React.FC<mappedProps> = (props: mappedProps) => {
  const { id } = useParams();
  const location = useLocation()

  useEffect(() => {
    props.fetchData(Number(id))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const processionQuestion = props.instructor.processingQuestion
  const loading = props.instructor.questionDetail.loading
  const question = props.getQuestion(Number(id))

  // MARK: 受講生情報から遷移した場合は閲覧のみ可能、回答、事務局質問ボタンを非表示
  const linkFromStudentList = location.search !== ''

  // MARK: 回答済みの場合は、回答、事務局質問ボタンを非表示
  const alreadyAnswered = question && question.statusCode === 'answered'

  // MARK: ユーザーが対応中の質問の場合は入力画面にリダイレクトする
  const processingByMyself =
    question &&
    question.statusCode === 'processing' &&
    question.instructor && question.instructor.id === props.user.id

  // MARK: 他の講師が対応中の場合
  //  ->回答ボタンを非表示にしてエラー表示「現在多の講師が対応中です。」
  const processingByOthers =
    question &&
    question.statusCode === 'processing' &&
    question.instructor && question.instructor.id !== props.user.id

  // MARK: 自分が他の質問を対応中
  const processingOtherQuestion =
    question &&
    processionQuestion.id &&
    processionQuestion.id !== question.id

  const canAnswer = !linkFromStudentList
    && !alreadyAnswered
    && !processingByOthers
    && !processingOtherQuestion

  const canPendingAnswer = !alreadyAnswered

  if (!loading && processingByMyself) {
    return <Redirect to={`/questions/${question!.id}/form`} />;
  }

  return (
    <React.Fragment>
      <PrivateHeader />
      <SiteMain>
        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          <li className="breadcrumbs__item">
            担当教科の質問
          </li>
        </ol>

        <div className="site-main__main-contents">
          <div className="page-title page-title--teacher">
            担当教科の質問
            <Link to='/' className="page-title__link page-title__link--back">マイページへ戻る</Link>
          </div>

          {
            !loading && question &&
            <React.Fragment>

              <div className="page-subtitle">
                質問内容
              </div>

              <QuestionDetail question={question} type={props.user.type} />

              {
                question.answer_finished_at && 
                <React.Fragment>
                  <div className="page-subtitle">
                    回答内容
                  </div>
                  <QuestionAnswerDetail question={question} type={props.user.type} />
                </React.Fragment>
              }

              {
                processingByOthers &&
                <div className="error-message error-message--processing-by-others">
                  この質問は、他の講師が対応しています。
                </div>
              }

              {
                processingOtherQuestion && 
                <div className="error-message error-message--processing-other-question">
                  別の質問が対応中になっています。対応中の質問を先に完了してください。
                </div>
              }

              {
                canAnswer &&
                <div className="answer-form">
                  <div className="answer-form__buttons">
                    <div className="answer-form__button">
                      <button
                        onClick={(e) => {
                          e.preventDefault()
                          props.updateQuestionToProcessing()
                        }}
                        className="button button--large button--primary">回答する</button>
                    </div>
                    <div className="answer-form__button">
                      <Link to='/' className="button button--large button--back button--gray">回答しない</Link>
                    </div>
                  </div>
                </div>
              }

              {
                canPendingAnswer &&
                <div className="answer-form">
                  <Link to={`/questions/${question.id}/form2`} className="answer-form__secretariat-button">事務局対応の質問にする</Link>

                  {
                    question.support_required &&

                    <div className="answer-form__buttons">
                      <div className="answer-form__button">
                        <button
                          onClick={(e) => {
                            e.preventDefault()
                            props.updateQuestionToNotPending().then(() => {
                              // マイページに戻す
                              props.history.push('/')
                            })
                          }}
                          className="button button--large button--back button--gray">事務局対応をやめてマイページに戻る</button>
                      </div>
                    </div>
                  }

                </div>
              }

            </React.Fragment>
          }

          <div className="back-home">
            <Link to='/' className="back-home__link">マイページへ戻る</Link>
          </div>
        </div>

        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          <li className="breadcrumbs__item">
            担当教科の質問
          </li>
        </ol>
      </SiteMain>
    </React.Fragment>
  );
}

export default Question