import { reducerWithInitialState } from 'typescript-fsa-reducers';
import produce from 'immer'

import sessionActions from 'actions/sessionActions';
import { User, StudentUser } from "models/api";
import { isUndefined } from 'lodash'

export interface SessionState {
  user: User | null;
  loaded: boolean;
}

const initialState: SessionState = {
  user: null,
  loaded: false,
};

export const sessionReducer = reducerWithInitialState(initialState)
  .case(sessionActions.setUser, (state, payload) => {
    return produce(state, draftState => {
      const ui = payload.ui
      const user = payload.user
      if (ui === 'student') {
        // MARK: password_updatedがなければtrueを設定してパスワード更新済みとする
        const studentUser = user as StudentUser
        const password_updated = isUndefined(studentUser.password_updated) ?
          true : studentUser.password_updated

        draftState.user = {
          ...studentUser,
          type: ui,
          password_updated
        }
      } else {
        draftState.user = {
          ...user,
          type: ui,
        }
      }
    })
  })
  .case(sessionActions.unsetUser, (state) => {
    return produce(state, draftState => {
      draftState.user = null;
    })
  })
  .case(sessionActions.setLoaded, (state) => {
    return produce(state, draftState => {
      draftState.loaded = true;
    })
  })
