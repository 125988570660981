import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import PrivateHeader from 'containers/parts/PrivateHeader';
import { mappedProps } from 'containers/trainer/Mypage';
import SiteMain from 'containers/parts/SiteMain';
//import userNoImage from 'assets/img/user_noimage.png';
import SectionInfoBox from 'components/parts/SectionInfoBox';

import * as Models from 'models/api'
import DatePicker, { registerLocale }  from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {orderBy} from 'lodash';
import ja from 'date-fns/locale/ja';

type SelectedSortTypeType = {
  login: boolean,
  nickName: boolean,
  totalAnswerTimeMinutes: boolean,
  totalAnswerCount: boolean,
  correctAnswerPercentage: boolean,
  totalViewCount: boolean,
}

const TrainerMypage: React.FC<mappedProps> = (props: mappedProps) => {
  const [studyStatus , setStudyStatus] = useState<Models.StudyStatusType | null >(null);

  useEffect(() => {
    if (props.studyStatus !== null  ){
      setStudyStatus(props.studyStatus.students);
    }
  }
  , [props.studyStatus])

  const filterKeywordRef = useRef<HTMLInputElement>(null);
  const [filterKeyword, setFilterKeyword] = useState('')

  const [curriculumId, setCurriculumId] = useState<number>(0)
  const [schoolClassId, setSchoolClassId] = useState<number>(0)
  //const [courseId, setCourseId] = useState<number>(0)

  const today = new Date();
  const oneYearAgo = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate() + 1 );

  const [startDate, setStartDate] = useState<Date>(new Date(new Date().setDate(new Date().getDate() - 7)));
  const [endDate, setEndDate] = useState<Date>(new Date());

  const initialSelectedSortButton = {
    login: false,
    nickName: false,
    totalAnswerTimeMinutes: false,
    totalAnswerCount: false,
    correctAnswerPercentage: false,
    totalViewCount: false,
  }
  const initialSelectedSortType = {
    login: true,
    nickName: true,
    totalAnswerTimeMinutes: true,
    totalAnswerCount: true,
    correctAnswerPercentage: true,
    totalViewCount: true,
  }

  const [selectedSortButton, setSelectedSortButton] = useState<any>({...initialSelectedSortButton, login: true})
  const [selectedSortType, setSelectedSortType] = useState<SelectedSortTypeType>(initialSelectedSortType) // asc = true, desc = false,

  useEffect(() => {
    props.fetchData();
    props.fetchSchoolClassess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const schoolClasses = props.trainer.schholClasses

  useEffect(() => {
    if (curriculumId !== 0) {
      props.fetchCourses(curriculumId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curriculumId]);

  useEffect(() => {
    props.fetchStudyStatus({
      school_class_id: schoolClassId,
      curriculum_id: curriculumId,
      beginning_date_on: startDate.toISOString(),
      end_date_on: endDate.toISOString()});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const page = props.trainer.mypage

  const filterStudents = () => {
    const current =filterKeywordRef.current
    if (current) {
      setFilterKeyword(current.value)
    }
  }

  const resetFilterStudents = () => {
    const current =filterKeywordRef.current
    if (current) {
      current.value = ''
    }
    setFilterKeyword('')
  }

/*   const students = page && page.students.filter(x => {
    if (filterKeyword.length <= 0) {
      return true
    }
    return x.login.startsWith(filterKeyword) || x.nick_name.startsWith(filterKeyword)
  }) */

  let filteredStudyStatus = studyStatus && studyStatus.filter((x) => {
    if (filterKeyword.length <= 0) {
      return true
    }
    return x.student.login.startsWith(filterKeyword) || x.student.nick_name.startsWith(filterKeyword)
  })

  const [selectedSchoolClassId , setSelectedClassId] = useState<number>(0)

const handleReloadButton = () => {
  // ソートの設定をリセット
  setSelectedSortButton({...initialSelectedSortButton, login: true})
  setSelectedSortType({...initialSelectedSortType})
  setSelectedClassId(schoolClassId)
  props.fetchStudyStatus({
    school_class_id: schoolClassId,
    curriculum_id: curriculumId,
    beginning_date_on: startDate.toISOString(),
    end_date_on: endDate.toISOString()
  });
}

enum SortType {
  Login = 'student.login',
  NickName = 'student.nick_name',
  TotalAnswerTimeMinutes = 'status.total_answer_time_minutes',
  TotalAnswerCount = 'status.total_answer_count',
  CorrectAnswerPercentage = 'status.correct_answer_percentage',
  TotalViewCount = 'status.total_view_count'
}
// ソートの設定を変更
const handleSortButton = ( selectedSort: string) => {
  selectedSort === SortType.Login &&  setSelectedSortButton({...initialSelectedSortButton, login: true})
  selectedSort === SortType.NickName &&  setSelectedSortButton({...initialSelectedSortButton, nickName: true})
  selectedSort === SortType.TotalAnswerTimeMinutes &&  setSelectedSortButton({...initialSelectedSortButton, totalAnswerTimeMinutes: true})
  selectedSort === SortType.TotalAnswerCount &&  setSelectedSortButton({...initialSelectedSortButton, totalAnswerCount: true})
  selectedSort === SortType.CorrectAnswerPercentage &&  setSelectedSortButton({...initialSelectedSortButton, correctAnswerPercentage: true})
  selectedSort === SortType.TotalViewCount &&  setSelectedSortButton({...initialSelectedSortButton, totalViewCount: true})

  selectedSort === SortType.Login &&  selectedSortButton.login && setSelectedSortType({...selectedSortType, login: !selectedSortType.login})
  selectedSort === SortType.NickName &&  selectedSortButton.nickName && setSelectedSortType({...selectedSortType, nickName: !selectedSortType.nickName})
  selectedSort === SortType.TotalAnswerTimeMinutes &&  selectedSortButton.totalAnswerTimeMinutes && setSelectedSortType({...selectedSortType, totalAnswerTimeMinutes: !selectedSortType.totalAnswerTimeMinutes})
  selectedSort === SortType.TotalAnswerCount &&  selectedSortButton.totalAnswerCount && setSelectedSortType({...selectedSortType, totalAnswerCount: !selectedSortType.totalAnswerCount})
  selectedSort === SortType.CorrectAnswerPercentage &&  selectedSortButton.correctAnswerPercentage && setSelectedSortType({...selectedSortType, correctAnswerPercentage: !selectedSortType.correctAnswerPercentage})
  selectedSort === SortType.TotalViewCount &&  selectedSortButton.totalViewCount && setSelectedSortType({...selectedSortType, totalViewCount: !selectedSortType.totalViewCount})
}

 // 並び替えの実行
 useEffect(() => {
  let sortedStudyStatus
  if (selectedSortButton.login) sortedStudyStatus =  orderBy(props.studyStatus && props.studyStatus.students, SortType.Login ,selectedSortType.login ? 'asc' : 'desc' )
  if (selectedSortButton.nickName) sortedStudyStatus =  orderBy(props.studyStatus && props.studyStatus.students, SortType.NickName,selectedSortType.nickName ? 'asc' : 'desc' )
  if (selectedSortButton.totalAnswerTimeMinutes) sortedStudyStatus =  orderBy(props.studyStatus && props.studyStatus.students, SortType.TotalAnswerTimeMinutes,selectedSortType.totalAnswerTimeMinutes ? 'asc' : 'desc' )
  if (selectedSortButton.totalAnswerCount) sortedStudyStatus =  orderBy(props.studyStatus && props.studyStatus.students, SortType.TotalAnswerCount , selectedSortType.totalAnswerCount ? 'asc' : 'desc' )
  if (selectedSortButton.correctAnswerPercentage) sortedStudyStatus =  orderBy(props.studyStatus && props.studyStatus.students, SortType.CorrectAnswerPercentage,selectedSortType.correctAnswerPercentage ? 'asc' : 'desc' )
  if (selectedSortButton.totalViewCount) sortedStudyStatus =  orderBy(props.studyStatus && props.studyStatus.students, SortType.TotalViewCount ,selectedSortType.totalViewCount ? 'asc' : 'desc' )

  sortedStudyStatus && setStudyStatus( sortedStudyStatus)
}
// eslint-disable-next-line react-hooks/exhaustive-deps
, [selectedSortType, selectedSortButton, props.studyStatus ])

const formatTotalAnswerTimeMinutes = (totalAnswerTimeMinutes: number) => {
  const hours = Math.floor(totalAnswerTimeMinutes / 60).toString().padStart(2, '0')
  const minutes = (totalAnswerTimeMinutes % 60).toString().padStart(2, '0')
  return `${hours}:${minutes}`
}

registerLocale('ja', ja)

  return (
    <React.Fragment>
      <PrivateHeader />
      <SiteMain>
        <div className="site-main__main-contents">
          <SectionInfoBox infoRecords={page ? page.admin_informations : []} />

          <section className="box">
            <header className="trainer-my-page__box-header">
              <div className="box__title box__title--studentlist">
                担当受講者
              </div>
              {filterKeyword.length <= 0 &&
                <div className="trainer-my-page__realtime-view-button">
                  <Link className="button" to={selectedSchoolClassId ?`/realtime_view/${selectedSchoolClassId}` : "/realtime_view/"} target="_blank">
                    リアルタイム<br className="_sp" />ビューで見る
                  </Link>
                </div>
              }
            </header>

            <div className="box__body">
              <div className="trainer-my-page__filters">
                { filterKeyword.length <= 0 && <>
                  <div className="student-search-box">
                    <div className="student-search-box__body">
                      <input
                        ref={filterKeywordRef}
                        className="student-search-box__input"
                        type="text"
                        name=""
                        placeholder="ニックネームまたは受講者IDを入力"
                      />
                      <button
                        className="button button--primary button--small button--no-chevron student-search-box__button"
                        onClick={(e) => {
                          e.preventDefault()
                          filterStudents()
                        }}
                      >
                        検索
                      </button>
                    </div>
                  </div>

                  <div className='trainer-my-page__search-class-container'>
                    <div className='trainer-my-page__filter-label'>クラス</div>
                    <div className="trainer-my-page__select-container">
                      <select
                        className="trainer-my-page__select"
                        value={schoolClassId}
                        onChange={e => {
                          const x = Number(e.target.value)
                          setSchoolClassId(x)
                          setCurriculumId(0)
                          //setCourseId(0)
                        }}
                      >
                        <option value="0">選択してください</option>
                        { schoolClasses && schoolClasses.map(x => (
                          <option key={x.id} value={x.id}>{ x.name }</option>
                        )) }
                      </select>
                    </div>
                  </div>
                </> }
                { filterKeyword.length <= 0 && <>
                  <div className='trainer-my-page__date-picker-section'>
                    <div className='trainer-my-page__filter-label'>学習期間</div>
                    <div className='trainer-my-page__date-picker-container'>
                      <div className='trainer-my-page__date-picker'>
                        <DatePicker
                          showIcon
                          minDate={oneYearAgo}
                          selected={startDate}
                          onChange={(date:Date ) => setStartDate(date)}
                          dateFormat="yyyy/MM/dd"
                          calendarStartDay={1}
                          locale='ja'
                          />
                      </div>
                      <div className='trainer-my-page__date-picker-between'>〜</div>

                      <div className='trainer-my-page__date-picker'>
                        <DatePicker
                          showIcon
                          minDate={oneYearAgo}
                          selected={endDate}
                          onChange={(date:Date ) => setEndDate(date)}
                          dateFormat="yyyy/MM/dd"
                          calendarStartDay={1}
                          locale='ja'
                          />
                      </div>
                    </div>
                  </div>
                  <div className='trainer-my-page__curriculum-section'>
                    <div className='trainer-my-page__curriculum-select-section'>
                      <div className='trainer-my-page__filter-label'>教科</div>
                      <div className="trainer-my-page__select-container">
                        <select
                          className="trainer-my-page__select"
                          value={curriculumId}
                          onChange={e => {
                            const x = Number(e.target.value)
                            setCurriculumId(x)
                            //setCourseId(0)
                          }}
                        >
                          <option value={0}>全教科</option>
                          { props.curriculums.map(x => (
                            <option key={x.id} value={x.id}>{ x.name }</option>
                          )) }
                        </select>
                      </div>
                    </div>

                    <button className="button button--primary button--small button--no-chevron trainer-my-page__reload-button" onClick={handleReloadButton}>
                      更新
                    </button>
                  </div>
                </> }
              </div>
              {
                filterKeyword.length > 0 &&
                <div className="student-search-box">
                  <div className="student-search-box__head">
                    <div className="student-search-box__text student-search-box__text--result">
                      “{filterKeyword}”で受講生を検索
                    </div>
                    <button
                      className="student-search-box__button student-search-box__button--cancel"
                      onClick={(e) => {
                        e.preventDefault()
                        resetFilterStudents()
                      }}
                    >
                        検索条件を解除
                    </button>
                  </div>
                </div>
              }
              {
              <div style={{"overflow": "auto"}}>
              <table className={'trainer-my-page__table'}>
                <thead>
                  <tr>
                    <th>
                      ログインID
                      <button
                        className={`trainer-my-page__sort-button ${!selectedSortButton.login && 'trainer-my-page__sort-button--inactive'}`}
                        onClick={()=> handleSortButton('student.login')}
                      >
                        {selectedSortType.login ? <>&#x2193;</>: <>&#x2191;</>}
                      </button>
                    </th>
                    <th>
                      生徒名（ニックネーム）
                      <button
                        className={`trainer-my-page__sort-button ${!selectedSortButton.nickName && 'trainer-my-page__sort-button--inactive'}`}
                        onClick={()=>{handleSortButton('student.nick_name')}}
                      >
                        {selectedSortType.nickName ? <>&#x2193;</>: <>&#x2191;</>}
                      </button>
                    </th>
                    <th>
                      学習時間
                      <button
                        className={`trainer-my-page__sort-button ${!selectedSortButton.totalAnswerTimeMinutes && 'trainer-my-page__sort-button--inactive'}`}
                        onClick={()=> {handleSortButton('status.total_answer_time_minutes')}}
                      >
                        {selectedSortType.totalAnswerTimeMinutes ? <>&#x2193;</>: <>&#x2191;</>}
                      </button>
                    </th>
                    <th>
                      問題解答数
                      <button
                        className={`trainer-my-page__sort-button ${!selectedSortButton.totalAnswerCount && 'trainer-my-page__sort-button--inactive'}`}
                        onClick={()=>{handleSortButton('status.total_answer_count')}}
                      >
                        {selectedSortType.totalAnswerCount ? <>&#x2193;</>: <>&#x2191;</>}
                      </button>
                    </th>
                    <th>
                      正答率
                      <button
                        className={`trainer-my-page__sort-button ${!selectedSortButton.correctAnswerPercentage && 'trainer-my-page__sort-button--inactive'}`}
                        onClick={()=>{handleSortButton('status.correct_answer_percentage')}}
                      >
                        {selectedSortType.correctAnswerPercentage ? <>&#x2193;</>: <>&#x2191;</>}
                      </button>
                    </th>
                    <th>
                      視聴数
                      <button
                        className={`trainer-my-page__sort-button ${!selectedSortButton.totalViewCount && 'trainer-my-page__sort-button--inactive'}`}
                        onClick={()=>{handleSortButton('status.total_view_count')}}
                      >
                        {selectedSortType.totalViewCount ? <>&#x2193;</>: <>&#x2191;</>}
                      </button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    filteredStudyStatus && filteredStudyStatus.map((record, index) => {
                      return (
                        <React.Fragment key={index}>
                        <tr>
                          <td className="_align-left">
                            <Link to={`/students/${record.student.id}/schedules`} className="student-box__name">
                              {record.student.login}
                            </Link>
                          </td>
                          <td className="_align-left">
                            <Link to={`/students/${record.student.id}/schedules`} className="student-box__name">
                              {record.student.nick_name}
                            </Link>
                            <small>{record.student.school_grade}</small>
                          </td>
                          <td>
                            {formatTotalAnswerTimeMinutes(record.status.total_answer_time_minutes)}
                          </td>
                          <td>
                            {record.status.total_answer_count}
                          </td>
                          <td className="_bold">
                            <div className={`
                              ${record.status.correct_answer_percentage <= 49 && 'trainer-my-page__answer-red'}
                              ${record.status.correct_answer_percentage >= 90 && 'trainer-my-page__answer-green'}
                            `}>
                              {record.status.correct_answer_percentage}%
                            </div>
                          </td>
                          <td>
                            {record.status.total_view_count}
                          </td>
                        </tr>
                        </React.Fragment> )})}
                </tbody>
                <tfoot></tfoot>
              </table>
              </div>
              }
              {/* <ul className="student-list">
                {
                  students && students.map((record, index) => (
                    <li key={index} className="student-list__item">
                      <div className="student-box">
                        <div className="student-box__head">
                          <div className="student-box__student">
                            <div className="student-box__avatar">
                              <img src={userNoImage} alt="" className="student-box__avatar-image" />
                            </div>
                            <Link to={`/students/${record.id}/schedules`} className="student-box__name">
                              { record.nick_name}
                            </Link>
                            <div className="student-box__grade">
                              { record.school_grade }
                            </div>
                            <div className="student-box__id">
                              ID: { record.login }
                            </div>
                          </div>
                          <div className="student-box__nav">
                            <Link to={`/students/${record.id}/schedules`} className="student-box__nav-item">
                              スケジュール
                            </Link>
                            <Link to={`/students/${record.id}/study_result`} className="student-box__nav-item">
                              学習結果
                            </Link>
                            <Link to={`/students/${record.id}/study_logs`} className="student-box__nav-item">
                              学習履歴
                            </Link>
                            <Link to={`/students/${record.id}/bookmark`} className="student-box__nav-item">
                              ブックマーク
                            </Link>
                            <Link to={`/students/${record.id}/messages`} className="student-box__nav-item">
                              相談
                            </Link>
                          </div>
                        </div>
                        {
                          record.student_consultation &&
                          <div className="student-box__body">
                            <Link to={`/students/${record.id}/messages`} className="student-box__link">
                              新しいメッセージが届いています。こちらからチェックしてください。
                            </Link>
                          </div>
                        }
                      </div>
                    </li>
                  ))
                }
              </ul> */}
            </div>
          </section>
        </div>
      </SiteMain>
    </React.Fragment>
  );
}

export default TrainerMypage
