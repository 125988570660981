import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router'

import { AppState } from "store";
import targetComponent from 'components/student/EntrustStudyCurriculum';
import studentActions from 'actions/studentActions';
import { getRecommendedCurriculums }  from 'utils/store'
import * as Models from 'models/api';
const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch })

const mapStateToProps = (appState: AppState, ownProps: RouteComponentProps) => {
  const user = appState.session.user! as Models.StudentUser
  return {
    user,
    curriculums: getRecommendedCurriculums(user),
    student: appState.student,
    ...ownProps,
  }
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  { dispatch }: ReturnType<typeof mapDispatchToProps>) => {
  return {
    ...stateProps,
    getCurriculum: (id: string | number | undefined) => {
      return stateProps.curriculums.find(x => x.id === Number(id) || x.code === id)
    },
    fetchData: (curriculum_id: number) => {
      dispatch(studentActions.fetchStudyCurriculum({ curriculum_id }))
    },
    getRecord: (curriculum_id: number) => {
      const page = stateProps.student.studyCurriculum
      if (page.curriculum_id !== curriculum_id) {
        return null
      }
      return page.record
    },
    startStudy: (params: { content_id: number, return_pathname: string; rank: number; }) => {
      return new Promise<string>((resolve, reject) => {
        dispatch(studentActions.startStudy({
          params,
          promises: { resolve, reject }
        }))
      })
    },
    fetchStudyAchievementsLatest: () => {
      return new Promise<Models.StudyAchievementsLatest>((resolve, reject) => {
        dispatch(studentActions.fetchStudyAchievementsLatest(
          { promises: { resolve, reject } }
        ))
      })
    },
  }
}

export type mappedProps = ReturnType<typeof mergeProps>
export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(targetComponent))