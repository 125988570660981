import { reducerWithInitialState } from 'typescript-fsa-reducers';
import produce from 'immer'
import { sortBy } from 'lodash'

import * as Models from 'models/api'
import actions from 'actions/studentActions';
import storeUtils from 'utils/store'
import { curriculums } from 'utils/constants'

const truncate = (str: string, len: number) => {
  return str.length <= len ? str: (str.substr(0, len)+"...");
}

// MARK: トピック詳細で、非表示にするコンテンツのカリキュラムコード
export const disabledContentsCode = [
  'EG1-255-T-2',
  'EG1-255-T-2',
  'EG1-255-TV-2',
  'EG1-316-LCT-1',
  'EG1-316-LCT-2',
  'EG1-316-LCT-3',
  'EG1-376-T-1',
  'EG1-376-T-2',
  'EG1-376-TV-1',
  'EG1-376-TV-2',
  'EG1-377-T-1',
  'EG1-377-T-2',
  'EG1-377-TV-1',
  'EG1-377-TV-2',
  'EG1-378-T-1',
  'EG1-378-T-2',
  'EG1-378-TV-1',
  'EG1-378-TV-2',
  'EG1-316-T-1',
  'EG1-316-T-2',
  'EG1-316-TV-1',
  'EG1-316-TV-2',
]

export interface StudentState {
  mypage: Models.StudentMypage | null;
  studyTimeStats: {
    records: Models.StudyAchivementRecord[];
    has_next: boolean;
  },
  plan: Models.StudentPlan | null;
  questionsSheetsCountInMonth: {
    record: number | null;
  },
  studyCurriculum: {
    curriculum_id: number | null;
    record: Models.StudentStudyCurriculum | null;
  }
  studyCourse: {
    course_id: number | null;
    record: Models.StudentStudyCourse | null;
  }
  studyCourseCompletionTests: {
    course_id: number | null;
    record: Models.StudyCourseCompletionTestsRecord | null;
  }
  studyCourseCompletionTestResults: {
    unit_id: number | null;
    record: Models.StudyCourseCompletionTestResultsRecord | null;
    moreRecord: Models.StudyCourseCompletionTestResultsRecord | null;
  }
  studyCourseResult: {
    curriculum_id: number | null;
    course_id: number | null;
    record: Models.StudyCourseResultsRecord | null;
  }
  studyUnit: {
    unit_id: number | null;
    record: Models.StudentStudyUnit | null;
  }
  studyUnitResult: {
    unit_id: number | null;
    record: Models.StudyUnitResultsRecord | null;
    moreRecord: Models.StudyUnitResultsRecord | null;
  }
  studyTopic: {
    topic_id: number | null;
    record: Models.StudentStudyTopic | null;
  }
  schedule: {
    records: Models.Schedule[] | null;
    page: number;
    has_next: boolean;
  }
  recommendStudy: {
    curriculum_id: number | null;
    records: Models.RecommendStudy2[] | null;
  }
  question: {
    records: Models.Question[] | null;
    page: number;
    has_next: boolean;
  },
  studyLog: {
    curriculum_id: number | null;
    records: Models.StudyLog[] | null;
    page: number;
    has_next: boolean;
  },
  studyLogDetail: {
    study_log_id: number | null;
    record: Models.StudyLog | null;
  },
  studyAchievementsLatest: {
    record: Models.StudyAchievementsLatest | null;
  },
  message: {
    records: Models.StudentMessage[] | null;
  },
  questionDetail: {
    question_id: number | null;
    record: Models.QuestionDetail | null; 
  },
  pointExchange: {
    params: Models.StudentPointExchangeParams | null; 
    confirm: Models.StudentPointExchangeRecord | null; 
    execute: Models.StudentPointExchangeRecord | null; 
    error: string | null;
  },
  pointExchangeLog: {
    records: Models.PointExchangeLog[] | null;
    page: number;
    has_next: boolean;
  },
  LearningCount: {
    records: Models.StudentStudyLearningCountRecords | null;
  },

  entrustStudy: {
    curriculum_id: number | null;
    records: Models.EntrustCurrentRecords | null;
},
  // issue-547
  bookmarks: {
    records: Models.Bookmark[] | null;
  },
  bookmarksLearningCount: {
    records: Models.StudentStudyLearningCountRecords | null;
  }
}

const initialState: StudentState = {
  mypage: null,
  studyTimeStats: {
    records: [],
    has_next: false,
  },
  plan: null,
  pointExchange: {
    params: null,
    confirm: null,
    execute: null,
    error: null,
  },
  pointExchangeLog: {
    records: null,
    page: 0,
    has_next: true,
  },
  questionsSheetsCountInMonth: {
    record: null,
  },
  studyCurriculum: {
    curriculum_id: null,
    record: null,
  },
  studyCourse: {
    course_id: null,
    record: null,
  },
  studyCourseCompletionTests: {
    course_id: null,
    record: null,
  },
  studyCourseCompletionTestResults: {
    unit_id: null,
    record: null,
    moreRecord: null,
  },
  studyCourseResult: {
    curriculum_id: null,
    course_id: null,
    record: null,
  },
  studyUnit: {
    unit_id: null,
    record: null,
  },
  studyUnitResult: {
    unit_id: null,
    record: null,
    moreRecord: null,
  },
  studyTopic: {
    topic_id: null,
    record: null,
  },
  schedule: {
    records: null,
    page: 0,
    has_next: true,
  },
  recommendStudy: {
    curriculum_id: null,
    records: null,
  },
  question: {
    records: null,
    page: 0,
    has_next: true,
  },
  questionDetail: {
    question_id: null,
    record: null,
  },
  studyLog: {
    curriculum_id: null,
    records: null,
    page: 0,
    has_next: true,
  },
  studyLogDetail: {
    study_log_id: null,
    record: null,
  },
  studyAchievementsLatest: {
    record: null,
  },
  message: {
    records: null,
  },
  LearningCount: {
    records: null,
  },
  entrustStudy: {
    curriculum_id: null,
    records: null,
  },
/*   bookmarks : {
    records: null,
  }, */
  bookmarks : {
    records: null,
  },
  bookmarksLearningCount: {
    records: null,
  }
}


export const studentReducer = reducerWithInitialState(initialState)
  .case(actions.setQuestionsSheetsCountInMonth, (state, payload) => {
    return produce(state, draftState => {
      draftState.questionsSheetsCountInMonth.record = payload.count
    })
  })
  .case(actions.resetRecommendStudyRecords, (state, payload) => {
    return produce(state, draftState => {
      draftState.recommendStudy.curriculum_id = null
      draftState.recommendStudy.records = null
    })
  })
  .case(actions.setRecommendStudyRecords, (state, payload) => {
    return produce(state, draftState => {
      draftState.recommendStudy.curriculum_id = payload.curriculum_id
      if (payload.records) {
        const records = payload.records.contents.map(x =>  {
          const meta = storeUtils.getContentMetaObjectByCode(x.content.curriculum_code) 
          const status = x.recently_learning_status
          if (!status || meta.type === '科目修了テスト') {
            return {
              ...x,
              content: {
                ...x.content,
                meta,
              },
              recently_learning_status: null,
            }
          }

          const baseUnits = status.units.filter(x => x.name !== '科目修了テスト')
          // MARK: currently_recommendのひとつ前から表示
          const unitIndex = baseUnits.findIndex(x => x.currently_recommend)
          const units = baseUnits.slice(unitIndex > 0 ? unitIndex-1 : 0).map(x => ({
            ...x,
            shortName: truncate(x.name, 10),
          }))

          const topicIndex = status.topics.findIndex(x => x.currently_recommend)
          const topics = status.topics.slice(topicIndex > 0 ? topicIndex-1 : 0).map(x => ({
            ...x,
            // MARK: topicタイトルは全角コロンの前を短縮表示につかう、
            // 設定されてない場合があるかもしれないのでtruncateも設定しておく
            shortTitle: truncate(x.title.split('：')[0], 10),
          }))

          return {
            ...x,
            content: {
              ...x.content,
              meta,
            },
            recently_learning_status: {
              units,
              topics,
            },
          }
        })

        draftState.recommendStudy.records = records
      } else {
        draftState.recommendStudy.records = null
      }
    })
  })
  .case(actions.setStudyLogRecords, (state, payload) => {
    return produce(state, draftState => {
      draftState.studyLog.curriculum_id = payload.curriculum_id
      draftState.studyLog.page = payload.page

      const records = payload.records.records.map(x => ({
        ...x,
        content: {
          ...x.content,
          meta: storeUtils.getContentMetaObjectByCode(
            x.content.launch_parameters.curriculum_code) 
        }
      }))

      if (!draftState.studyLog.records || payload.page === 1) {
        draftState.studyLog.records = records
      } else {
        draftState.studyLog.records.push(...records)
      }
      draftState.studyLog.has_next = payload.records.total_page > payload.page
    })
  })
  .case(actions.setScheduleRecords, (state, payload) => {
    return produce(state, draftState => {
      draftState.schedule.page = payload.page
      if (!draftState.schedule.records || payload.page === 1) {
        draftState.schedule.records = payload.records.records
      } else {
        draftState.schedule.records.push(...payload.records.records)
      }
      draftState.schedule.has_next = payload.records.total_page > payload.page
    })
  })
  .case(actions.setMessageRecords, (state, payload) => {
    return produce(state, draftState => {
      // MARK: チャット形式なので古い順にする
      draftState.message.records = payload.records.records.reverse()
    })
  })
  .case(actions.setMypage, (state, payload) => {
    return produce(state, draftState => {
      // 表示順序
      // 英語: 2, 数学: 1, 物理: 3、化学: 4、古文: 5
      const curriculumIds = curriculums.map(x => x.id)
      draftState.mypage = {
        ...payload.record,
        curriculums: sortBy(payload.record.curriculums, x => curriculumIds.indexOf(x.id)),
      }
    })
  })
  .case(actions.pushStudyTimeStats, (state, payload) => {
    return produce(state, draftState => {
      draftState.studyTimeStats.records.push(...payload.records)
      draftState.studyTimeStats.has_next = payload.has_next
    })
  })
  .case(actions.setStudyTimeStats, (state, payload) => {
    return produce(state, draftState => {
      draftState.studyTimeStats.records = payload.records
      draftState.studyTimeStats.has_next = payload.has_next
    })
  })
  .case(actions.updateStudyTimeStats, (state, payload) => {
    return produce(state, draftState => {
      if (draftState.studyTimeStats.records.length >= payload.index + 1) {
        draftState.studyTimeStats.records[payload.index] = payload.record
      }
    })
  })
  .case(actions.setStudyCurriculum, (state, payload) => {
    return produce(state, draftState => {
      draftState.studyCurriculum.curriculum_id = payload.curriculum_id
      draftState.studyCurriculum.record = payload.record
    })
  })
  .case(actions.setStudyCourse, (state, payload) => {
    return produce(state, draftState => {
      draftState.studyCourse.course_id = payload.course_id
      draftState.studyCourse.record = payload.record
    })
  })
  .case(actions.setStudyUnit, (state, payload) => {
    return produce(state, draftState => {
      draftState.studyUnit.unit_id = payload.unit_id
      if (payload.record) {
        // MARK: 未受講はレベル１で表示
        draftState.studyUnit.record = {
          ...payload.record,
          topics: payload.record.topics.map(x => ({
            ...x,
            check_level: x.check_level === '未受講' ? 'レベル1' : x.check_level
          }))
        }
      } else {
        draftState.studyUnit.record = payload.record
      }
    })
  })
  .case(actions.setStudyUnitResult, (state, payload) => {
    return produce(state, draftState => {
      draftState.studyUnitResult.unit_id = payload.unit_id
      draftState.studyUnitResult.record = payload.record
    })
  })
  .case(actions.setStudyUnitResultMore, (state, payload) => {
    return produce(state, draftState => {
      if (!payload.record) {
        draftState.studyUnitResult.moreRecord = null
      } else {
        draftState.studyUnitResult.moreRecord = payload.record
        const records = payload.record.results.posttest
        if (records) {
          draftState.studyUnitResult.moreRecord.results.posttest = records.slice(2)
        } else {
          draftState.studyUnitResult.moreRecord.results.posttest = null
        }
      }
    })
  })
  .case(actions.setStudyTopic, (state, payload) => {
    return produce(state, draftState => {
      draftState.studyTopic.topic_id = payload.topic_id
      if (payload.record) {
        draftState.studyTopic.record = {
          ...payload.record,
          contents: payload.record.contents
            .filter(x => !disabledContentsCode.includes(x.title))
            .map(x => ({
              ...x,
              meta: storeUtils.getContentMetaObjectByCode(x.title)
              //meta: storeUtils.getContentMetaObjectByCode(x.launch_parameters.curriculum_code)
            }))
        }
      } else {
        draftState.studyTopic.record = null
      }
    })
  })
  .case(actions.setStudyLogDetail, (state, payload) => {
    return produce(state, draftState => {
      draftState.studyLogDetail.study_log_id = payload.study_log_id
      if (payload.record == null) {
        draftState.studyLogDetail.record = payload.record
      } else {
        const x = payload.record
        draftState.studyLogDetail.record = {
          ...x,
          content: {
            ...x.content,
            meta: storeUtils.getContentMetaObjectByCode(
              x.content.launch_parameters.curriculum_code) 
          }
        }
      }
    })
  })
  .case(actions.setStudyAchievementsLatest, (state, payload) => {
    return produce(state, draftState => {
      draftState.studyAchievementsLatest.record = payload.record
    })
  })
  .case(actions.setQuestionRecords, (state, payload) => {
    return produce(state, draftState => {
      draftState.question.page = payload.page

      const records = payload.records.records.map(x => ({
        ...x,
        statusText: x.status === 3 ? '回答済み' : '質問中',
        statusCode: x.status === 3 ? 'answered' : 'not-answered',
        content: {
          ...x.content,
          meta: storeUtils.getContentMetaObjectByCode(
            x.content.launch_parameters.curriculum_code) 
        }
      }))

      if (!draftState.question.records || payload.page === 1) {
        draftState.question.records = records
      } else {
        draftState.question.records.push(...records)
      }
      draftState.question.has_next = payload.records.total_page > payload.page
    })
  })
  .case(actions.setQuestionDetail, (state, payload) => {
    return produce(state, draftState => {
      if (payload.record === null) {
        draftState.questionDetail.record = payload.record
      } else {
        const x = payload.record
        draftState.questionDetail.record = {
          ...payload.record,
          statusText: x.status === 3 ? '回答済み' : '質問中',
          statusCode: x.status === 3 ? 'answered' : 'not-answered',
          content: {
            ...x.content,
            meta: storeUtils.getContentMetaObjectByCode(
              x.content.launch_parameters.curriculum_code) 
          }
        }
      }
      draftState.questionDetail.question_id = payload.question_id
    })
  })
  .case(actions.setPlan, (state, payload) => {
    return produce(state, draftState => {
      draftState.plan = payload.record
    })
  })
  .case(actions.setPointExchangeConfirmRecord, (state, payload) => {
    return produce(state, draftState => {
      draftState.pointExchange.confirm = payload.record
    })
  })
  .case(actions.setPointExchangeExecuteRecord, (state, payload) => {
    return produce(state, draftState => {
      draftState.pointExchange.execute = payload.record
    })
  })
  .case(actions.setPointExchangeParams, (state, payload) => {
    return produce(state, draftState => {
      draftState.pointExchange.params = payload.params
    })
  })
  .case(actions.setPointExchangeError, (state, error) => {
    return produce(state, draftState => {
      let message: string = 'ポイントの交換ができませんでした。'
      if (Models.isApiError(error)) {
        if (error.response && error.response.data && error.response!.data.errors) {
          message = error.response!.data.errors.join('、')
        }
      }
      draftState.pointExchange.error = message
    })
  })
  .case(actions.resetPointExchangeState, (state, payload) => {
    return produce(state, draftState => {
      draftState.pointExchange.params = null
      draftState.pointExchange.confirm = null
      draftState.pointExchange.execute = null
      draftState.pointExchange.error = null
    })
  })
  .case(actions.setPointExchangeLogRecords, (state, payload) => {
    return produce(state, draftState => {
      draftState.pointExchangeLog.page = payload.page
      const records = payload.records.records
      if (!draftState.pointExchangeLog.records || payload.page === 1) {
        draftState.pointExchangeLog.records = records
      } else {
        draftState.pointExchangeLog.records.push(...records)
      }
      draftState.pointExchangeLog.has_next = payload.records.total_page > payload.page
    })
  })
  .case(actions.setStudyCourseResult, (state, payload) => {
    return produce(state, draftState => {
      draftState.studyCourseResult.curriculum_id = payload.curriculum_id
      draftState.studyCourseResult.course_id = payload.course_id
      draftState.studyCourseResult.record = payload.record
    })
  })
  .case(actions.setStudyCourseCompletionTests, (state, payload) => {
    return produce(state, draftState => {
      draftState.studyCourseCompletionTests.course_id = payload.course_id
      draftState.studyCourseCompletionTests.record = payload.record
    })
  })
  .case(actions.setStudyCourseCompletionTestResults, (state, payload) => {
    return produce(state, draftState => {
      draftState.studyCourseCompletionTestResults.unit_id = payload.unit_id
      draftState.studyCourseCompletionTestResults.record = payload.record
    })
  })
  .case(actions.setStudyCourseCompletionTestResultsMore, (state, payload) => {
    return produce(state, draftState => {
      if (!payload.record) {
        draftState.studyCourseCompletionTestResults.moreRecord = null
      } else {
        draftState.studyCourseCompletionTestResults.moreRecord = payload.record
        const records = payload.record.results
        if (records) {
          draftState.studyCourseCompletionTestResults.moreRecord.results = records.slice(2)
        } else {
          draftState.studyCourseCompletionTestResults.moreRecord.results = []
        }
      }
    })
  })
  .case(actions.setLearningCount, (state, payload) => {
    return produce(state, draftState => {
      draftState.LearningCount.records = payload.params;
      })
    })
  // issue-545  
  .case(actions.setEntrustStudyRecords, (state, payload) => {
    return produce(state, draftState => {
      if (payload.records) {
        if (payload.curriculum_id) {
          draftState.entrustStudy.curriculum_id = payload.curriculum_id;
        } else {
          draftState.entrustStudy.curriculum_id = null;
        }
        draftState.entrustStudy.records = {
          entrust_current: payload.records.entrust_current.map((x) => ({
            ...x,
            content: {
              ...x.content,
              meta: storeUtils.getContentMetaObjectByCode(
                x.content.curriculum_code)
            }
          }))
        }
      }
    })
  })
  .case(actions.setBookmarks, (state, payload) => {
    return produce(state, draftState => {
      if (payload.params) {
        draftState.bookmarks.records =
          payload.params.map(x => ({
            ...x,
            content: {
              ...x.content,
              meta: storeUtils.getContentMetaObjectByCode(
                x.content.curriculum_code)
            }
          })
          )
      }
    })
  })
  .case(actions.setBookmarksLearningCount, (state, payload) => {  
    return produce(state, draftState => {
      draftState.bookmarksLearningCount.records = payload.params;
      })
    }
  )

