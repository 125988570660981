import { reducerWithInitialState } from 'typescript-fsa-reducers';
import produce from 'immer'

import uiActions from 'actions/uiActions';
import * as Models from 'models/api'

export interface UIState {
  type: Models.UIType | null;
  env: string|null;
  sidenav: {
    // issue-545
    openEntrustNav: boolean;
    // issue-545
    openRecommendNav: boolean;
    openStudyNav: boolean;
    openHistoryNav: boolean;
    openTestNav: boolean;
  },
  notification: {
    open: boolean;
    level?: 'success' | 'error' | 'warning' | 'info',
    message?: string,
  },
  master: {
    records: null | Models.MasterData;
  }
}

const initialState: UIState = {
  type: null,
  env: null,
  notification: {
    open: false,
  },
  master: {
    records: null,
  },
  sidenav: {
    // issue-545
    openEntrustNav: false,
    // issue-545
    openRecommendNav: false,
    openStudyNav: false,
    openHistoryNav: false,
    openTestNav: false,
  }
};

export const uiReducer = reducerWithInitialState(initialState)
  .case(uiActions.setType, (state, type) => {
    return produce(state, draftState => {
      draftState.type = type;
    })
  })
  .case(uiActions.setEnv, (state, env) => {
    return produce(state, draftState => {
      draftState.env = env;
    })
  })
  .case(uiActions.showApiErrorNotification, (state, error) => {
    return produce(state, draftState => {
      let message: string
      if (Models.isApiError(error)) {
        if (!error.response) {
          message = '処理できませんでした。';
        } else {
          if (error.response!.data && error.response!.data.errors) {
            message = error.response!.data.errors.join('、')
          } else {
            message = '処理できませんでした。';
          }
        }
      } else {
        message = '処理できませんでした。';
      }
      draftState.notification.open = true;
      draftState.notification.level = 'error';
      draftState.notification.message = message;
    })
  })
  .case(uiActions.showNotification, (state, notification) => {
    return produce(state, draftState => {
      draftState.notification.open = true;
      draftState.notification.level = notification.level;
      draftState.notification.message = notification.message;
    })
  })
  .case(uiActions.closeNotification, (state) => {
    return produce(state, draftState => {
      draftState.notification.open = false;
    })
  })
  .case(uiActions.setMasterData, (state, payload) => {
    return produce(state, draftState => {
      draftState.master.records = {
        ...payload.records,
        math_levels: payload.records.math_levels.filter(x => x.level !== 1).map(x => ({
          level: x.level,
          course_names: x.course_names.replace('小中数学・', ''),
        }))
      }
    })
  })
  .case(uiActions.setOpenRecommendNav, (state, payload) => {
    return produce(state, draftState => {
      draftState.sidenav.openRecommendNav = payload;
    })
  })
  .case(uiActions.setOpenStudyNav, (state, payload) => {
    return produce(state, draftState => {
      draftState.sidenav.openStudyNav = payload;
    })
  })
  .case(uiActions.setOpenHistoryNav, (state, payload) => {
    return produce(state, draftState => {
      draftState.sidenav.openHistoryNav = payload;
    })
  })
  .case(uiActions.setOpenTestNav, (state, payload) => {
    return produce(state, draftState => {
      draftState.sidenav.openTestNav = payload;
    })
  })
  // issue-545
  .case(uiActions.setOpenEntrustNav, (state, payload) => {
    return produce(state, draftState => {
      draftState.sidenav.openEntrustNav = payload;
    })
  })
  // issue-545
