import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import moment from "moment";
import momentTimezone from 'moment-timezone'
import smoothscroll from 'smoothscroll-polyfill';
import { Helmet } from "react-helmet";

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import ErrorBoundary from 'react-error-boundary';

import './assets/css/site.css.sass';

import * as serviceWorker from './serviceWorker';
import configureStore from "./store";

import { getUIType } from './uiTypes';
import uiActions from './actions/uiActions';
import repositoryActions from './actions/repositoryActions';
import errorPage from 'components/common/Error'
import MaintenancePage from 'components/common/Maintenance'

import { getCurrentSchedule, schedules } from 'utils/maintenance';

smoothscroll.polyfill();

// debug sc_func
// (window as any).sc_func = function(...args: any[]) {
//   console.debug('sc_func', args)
// }

// MARK: 週の始まりを月曜にする設定
momentTimezone.updateLocale('en', {
  week: {
    dow: 1, // First day of week is Monday
    doy: 7  // First week of year must contain 1 January (7 + 1 - 1) 
  },
})
momentTimezone.tz.setDefault('Asia/Tokyo')

const maintenanceSchedule = getCurrentSchedule(moment(), schedules);

const store = configureStore();

const uiType = getUIType(
  window.location.hostname,
  process.env.REACT_APP_TYPE,
  process.env.REACT_APP_API_URL);

const componentErrorHandler = (error: Error, componentStack: string) => {
  // MARK: componentの描画中に例外が発生したとき
  console.error(error);
};

store.dispatch(repositoryActions.setApiBaseUrl(uiType.apiBaseUrl));
store.dispatch(repositoryActions.initApi());
store.dispatch(uiActions.setEnv(uiType.env));
store.dispatch(uiActions.setType(uiType.name));

ReactDOM.render(
  <Provider store={store}>
    <ErrorBoundary onError={componentErrorHandler} FallbackComponent={errorPage}>
      <Helmet>
        <title>{ uiType.title }</title>
        { uiType.description && <meta name="description" content={uiType.description} /> }
        { uiType.robots && <meta name="robots" content={uiType.robots} /> }
      </Helmet>
      {
        maintenanceSchedule ? <MaintenancePage schedule={maintenanceSchedule} /> : <uiType.app />
      }
    </ErrorBoundary>
  </Provider>,
  document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
