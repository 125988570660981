import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router'
import { reduxForm, InjectedFormProps, SubmissionError, } from 'redux-form'

import { AppState } from "store";
import SettingPointExchangeConfirm from 'components/student/SettingPointExchangeConfirm';
import * as Models from 'models/api'
import studentActions from 'actions/studentActions';

import {
  pointRate,
  amazonGiftExchangeRate,
  planDiscountExchangeRate,
} from 'utils/constants'

export const formKey = 'setting_point_exchange_confirm'

type FormData = {
  [key: string]: any
};

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch })
const mapStateToProps = (appState: AppState, routeProps: RouteComponentProps) => {
  const user = appState.session.user! as Models.StudentUser
  const plan = appState.student.plan
  const pointExchangeState = appState.student.pointExchange
  const initialValues = {}
  
  return {
    user,
    plan,
    initialValues,
    pointRate,
    amazonGiftExchangeRate,
    planDiscountExchangeRate,
    pointExchangeState,
    ...routeProps,
  }
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  { dispatch }: ReturnType<typeof mapDispatchToProps>) => {
  return {
    ...stateProps,
    onSubmit: (values: FormData) => {
      return new Promise<void>((resolve, reject) => {
        const payload = {
          promises: { resolve, reject }
        }
        dispatch(studentActions.executePointExchange(payload))
      }).catch((e: Error) => {
        throw new SubmissionError({})
      })
    },
    fetchData: () => {
      dispatch(studentActions.fetchPlan())
    },
  }
}

type connectMappedProps = ReturnType<typeof mergeProps>
export type mappedProps = connectMappedProps & InjectedFormProps<FormData, connectMappedProps>

const form = reduxForm<FormData, connectMappedProps>({
  form: formKey,
})(SettingPointExchangeConfirm)

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(form))
