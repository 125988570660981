import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import * as Models from 'models/api'

type ownProps = {
  page: string;
  user: Models.User;
}

const SettingSideNav: React.FC<ownProps> = (props: ownProps) => {
  const studentUser = props.user.type === 'student' ? props.user as Models.StudentUser : undefined;

  return (
    <div className="site-main__sub site-main__sub--setting">
      <dl className="setting-nav">
        <dt className="setting-nav__title">
          設定変更
        </dt>
        {
          props.user.type === 'student' &&
          <dd className="setting-nav__item">
            <Link
              to='/setting/profile_edit'
              className={classNames("setting-nav__link setting-nav__link--profile", { 'is-active' : props.page === 'profile_edit' })}>
                プロフィール確認・変更</Link>
          </dd>
        }

        {
          props.user.type === 'trainer' &&
          <dd className="setting-nav__item">
            <Link
              to='/setting/profile_edit'
              className={classNames("setting-nav__link setting-nav__link--profile", { 'is-active' : props.page === 'profile_edit' })}>
                プロフィール確認・変更</Link>
          </dd>
        }

        {
          props.user.type === 'parent' &&
          <dd className="setting-nav__item">
            <Link
              to='/setting/profile_edit'
              className={classNames("setting-nav__link setting-nav__link--profile", { 'is-active' : props.page === 'profile_edit' })}>
                プロフィール確認・変更</Link>
          </dd>
        }

        {/*
        <dd className="setting-nav__item">
          <Link
            to='/setting/password_edit'
            className={classNames("setting-nav__link setting-nav__link--password ", { 'is-active' : props.page === 'password_edit' })}>
              パスワード変更</Link>
        </dd>
        */}

        {
          ['student', 'trainer', 'parent'].includes(props.user.type) &&
          <dd className="setting-nav__item">
            <Link
              to='/setting/password_edit'
              className={classNames("setting-nav__link setting-nav__link--password ", { 'is-active' : props.page === 'password_edit' })}>
                パスワード変更</Link>
          </dd>
        }

        {
          props.user.type === 'parent' &&
          <React.Fragment>
            <dd className="setting-nav__item">
              <Link
                to='/setting/purchase_logs'
                className={classNames("setting-nav__link setting-nav__link--creditcard ", { 'is-active' : props.page === 'purchase_logs' })}>
                  購入履歴</Link>
            </dd>
          </React.Fragment>
        }

        {
          props.user.type === 'student' &&
          <>
            {
              !studentUser!.corporation &&
              <>
                <dd className="setting-nav__item">
                  <Link
                    to='/setting/card_edit'
                    className={classNames("setting-nav__link setting-nav__link--creditcard ", { 'is-active' : props.page === 'card_edit' })}>
                      クレジットカード情報変更</Link>
                </dd>
                <dd className="setting-nav__item">
                  <Link
                    to='/setting/trainer_edit'
                    className={classNames("setting-nav__link setting-nav__link--trainer-service", { 'is-active' : props.page === 'trainer_edit' })}>
                    トレーナー申込・変更</Link>
                </dd>
                <dd className="setting-nav__item">
                  <Link
                    to='/setting/withdrawal'
                    className={classNames("setting-nav__link setting-nav__link--withdraw ", { 'is-active' : props.page === 'withdrawal' })}>
                    退会</Link>
                </dd>
              </>
            }
          </>
        }
      </dl>
    </div>
  );
}

export default SettingSideNav;
