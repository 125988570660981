import React, { useEffect, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';

import PrivateHeader from 'containers/parts/PrivateHeader';
import { mappedProps } from 'containers/student/EntrustStudyUnit';
import SiteMain from 'containers/parts/SiteMain';
import { curriculums } from "../../utils/constants"
import { EntrustCurrentRecord } from '../../models/api';
import CorrectAnswerCount from './common/CorrectAnswerCount';

const EntrustStudyUnit: React.FC<mappedProps> = (props: mappedProps) => {
  const { unit_id } = useParams<{ unit_id: string }>();
  const history = useHistory()

  useEffect(() => {
    if (unit_id) {
      props.fetchData(Number(unit_id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unit_id]);

  useEffect(() => {
    props.fetchStudyAchievementsLatest();
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  , []);

  const page = props.student.studyUnit.record;
  const [curriculumCode, setCurriculumCodes] = useState<string>("")

  useEffect(() => {
    if (page) {
      const curriculum_code = curriculums.filter(x => {
        return x.id === page.curriculum.id
      })
      setCurriculumCodes(curriculum_code[0].code)
    }
  }, [page, props])

  const [topicId, setTopicId] = useState<number>(0);
  const [submitActive, setSubmitActive] = useState<boolean>(false);

  useEffect(() => {
    if (page) {
      props.fetchEntrustStudyData(page.curriculum.id).catch(error => {
        // MARK: 取得リクエストでエラーになった場合にTOPにリダイレクトする
        history.push('/')
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const [entrustStudyRecords, setEntrustStudyRecords] = useState<EntrustCurrentRecord[] | null>(null);
  useEffect(() => {
    const records = props.getEntrustStudyState();
    if (records) {
      setEntrustStudyRecords(records)
    }
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.fetchEntrustStudyData]);
  
  const submit = () => {
    if (page) {
      props.updateEntrustBaseTopic(topicId).then(
        () => {
          history.push(`/entrust/${curriculumCode}`)
        })
    }
  }

  const checkBoxHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSubmitActive(true);
  }

  return (
    <React.Fragment>
      <PrivateHeader />
      <SiteMain>
        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          {
            page &&
            <React.Fragment>
              <li className="breadcrumbs__item">
                <Link to={`/entrust_setting/${page.curriculum.id}`} className="breadcrumbs__link">
                  おまかせ学習{"(" + page.curriculum.name + ")"}
                </Link>
              </li>
              <li className="breadcrumbs__item">
                <Link to={`/entrust_setting/courses/${page.course.id}`} className="breadcrumbs__link">
                  {page.course.name}
                </Link>
              </li>
              <li className="breadcrumbs__item">
                {page.unit.name}
              </li>
            </React.Fragment>
          }
        </ol>

        {
          page &&

          <div className="site-main__main-contents">
            <div className="page-title">
              <div className="page-title__left">
                おまかせ学習設定{"（" + page.curriculum.name + "）"}
                &nbsp;
                <div className="page-title__badge">
                 {page.course.name}
                </div>
                &nbsp;
                <br className="_sp" />{page.unit.name}
              </div>
              <div className="page-title__correct-count">
                {props.student.studyAchievementsLatest.record && <>
                <CorrectAnswerCount correctAnswerCount={props.student.studyAchievementsLatest.record.study_achievements.consecutive_correct_answer_count}/>
              </>}
            </div>  
            </div>

            {
              page.curriculum.al_engine_connected &&
              <div className="suggestion-box">
                AIがおすすめするカリキュラムを進めていくとテストの受講が可能です。
                「<Link to={`/recommends/${page.curriculum.id}`} className="">もっともおすすめ学習</Link>」からご確認ください。
              </div>
            }

            <div className="topic-list">
              <div className="topic-list__head entrust-topic-list_head">
                <div className="topic-list__title entrust-topic-list__title">
                  “{page.unit.name}”のトピック
                </div>
                <div className="entrust-head-note">
                  おまかせ設定
                </div>
              </div>
              <div className="topic-list__body">

                {page &&
                  page.topics.map((topic: any, index) => (
                    <div key={index} className="topic-list__item entrust-topic-list__item">
                      <div className="entrust-topic-list__body-inner">
                        <input className="entrust-input-check-box" checked={topic.id === topicId} type="checkbox" id={`topic-${topic.id}`} name={`topic-${topic.id}`}
                          onChange={(e) => {
                            setTopicId(topic.id)
                            checkBoxHandler(e)
                          }}
                        />
                        <label className="entrust-input-label" htmlFor={`topic-${topic.id}`} >
                          {topic.title}
                        </label>
                      </div>
                      {entrustStudyRecords && entrustStudyRecords.length > 0 && entrustStudyRecords[0].topic.description && topic.title === entrustStudyRecords[0].topic.description && <>
                        <p className="entrust-selected">設定済</p>
                      </>}
                    </div>
                  ))
                }
              </div>
            </div>
            <div className="entrust-unit-center">

              <Link to={`/entrust/${curriculumCode}`} className=" button button--outline-cancel  entrust-button">反映せずに戻る</Link>
              <button
                className="button button--primary entrust-button"
                onClick={x => {
                  submit()
                }}
                disabled={!submitActive}
              >
                設定する
              </button>
            </div>
          </div>
        }

        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          {
            page &&
            <React.Fragment>
              <li className="breadcrumbs__item">
                <Link to={`/entrust_setting/${page.curriculum.id}`} className="breadcrumbs__link">
                  おまかせ学習{"(" + page.curriculum.name + ")"}
                </Link>
              </li>
              <li className="breadcrumbs__item">
                <Link to={`/entrust_setting/courses/${page.course.id}`} className="breadcrumbs__link">
                  {page.course.name}
                </Link>
              </li>
              <li className="breadcrumbs__item">
                {page.unit.name}
              </li>
            </React.Fragment>
          }
        </ol>

      </SiteMain>
    </React.Fragment>
  );
}

export default EntrustStudyUnit