import React from 'react';
import { Link } from 'react-router-dom';
//import classNames from 'classnames';

import logo from 'assets/img/img_logo.png';
import logoShowin from 'assets/img/logo_showin.png';
//import userNoImage from 'assets/img/user_noimage.png';
//import userNoImageTrainer from 'assets/img/user_noimage_trainer.png';
//import userNoImageInstructor from 'assets/img/user_noimage_teacher.png';
//import userNoImageParent from 'assets/img/user_noimage_parent.png';
import { mappedProps } from 'containers/parts/PrivateHeader';
//import * as Models from 'models/api'

/* const getUserImage = (user: Models.User) => {
  if (user.type === 'trainer') {
    return userNoImageTrainer
  } else if (user.type === 'instructor') {
    return userNoImageInstructor
  } else if (user.type === 'parent') {
    return userNoImageParent
  } else {
    return userNoImage
  }
} */

const RealtimeViewHeader : React.FC<mappedProps> = (props: mappedProps) => {
  //const [userNav, setUserNav] = useState(false);
  //const [userSubNav, setUserSubNav] = useState(false);
  //const [userNavMobile, setUserNavMobile] = useState(false);
  //const [userSubNavMobile1, setUserSubNavMobile1] = useState(false);
  //const [userSubNavMobile2, setUserSubNavMobile2] = useState(false);
  //const [userSubNavMobile3, setUserSubNavMobile3] = useState(false);
  //const [userSubNavMobile4, setUserSubNavMobile4] = useState(false);
  //const [userSubNavMobile5, setUserSubNavMobile5] = useState(false);
  //const [userSubNavMobile6, setUserSubNavMobile6] = useState(false);
  // issue-545
  //const [userSubNavMobile7, setUserSubNavMobile7] = useState(false);
  //const entrustCurriculumsList: string[] = ["junior_english", "junior_math"] //ここでおまかせで表示する教科を指定
  // issue-545

  //const userImage = getUserImage(props.user)
  /* const resetMobileNav = () => {
    setUserNavMobile(false)
    setUserSubNavMobile1(false)
    setUserSubNavMobile2(false)
    setUserSubNavMobile3(false)
    setUserSubNavMobile4(false)
    setUserSubNavMobile5(false)
    setUserSubNavMobile6(false)
    setUserSubNavMobile7(false)
  } */

  //const curriculums = props.curriculums && props.curriculums

  // issue-545
  //const entrustCurriculums = curriculums && curriculums.filter(x => entrustCurriculumsList.includes(x.code))
  // issue-545

  //const alEngineConnectedCurriculums = curriculums && curriculums.filter(x => x.al_engine_connected)

  // MARK: テスト一覧画面に遷移すべき教科のリスト（単元テストか科目修了テストがある）
  //const testListCurridulums = curriculums && curriculums.filter(x => x.al_engine_connected || x.has_completion_test)

  return (
    <header className="site-header-realtime">
      <div className="site-header-realtime__inner">
        <div className="site-header-realtime__logo">
          <Link to='/'>
            <img src={!props.isShowin ? logo : logoShowin} alt="" className={!props.isShowin ? "site-header-realtime__image" : "site-header-realtime__image-showin"} />
          </Link>
          {!props.isShowin &&
            <div className="site-header-realtime__text">
              わたし専用。河合塾One
            </div>}
        </div>
      </div>
    </header>
  );
}

export default RealtimeViewHeader;
