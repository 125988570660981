import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { AppState } from "store";

import targetComponent from 'components/parent/SettingPurchaseLogs';

import parentActions from 'actions/parentActions';

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch })

const mapStateToProps = (appState: AppState) => {
  return {
    user: appState.session.user!,
    parent: appState.parent,
  }
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  { dispatch }: ReturnType<typeof mapDispatchToProps>) => {
  return {
    ...stateProps,
    fetchData: () => {
      dispatch(parentActions.fetchPaymentLogRecords())
    },
  }
}

export type mappedProps = ReturnType<typeof mergeProps>
export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(targetComponent)