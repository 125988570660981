import React, { useEffect, } from 'react';
import { Link, useParams, } from 'react-router-dom';

import { mappedProps } from 'containers/instructor/StudentQuestions';
import PrivateHeader from 'containers/parts/PrivateHeader';
import SiteMain from 'containers/parts/SiteMain';

import StudentBar from '../parts/CommonStudentBar'
// import StudentNav from '../parts/InstructorStudentNav'
import Moment from 'react-moment';
import * as Models from '../../models/api';

const StudentQuestions: React.FC<mappedProps> = (props: mappedProps) => {
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      props.fetchData(Number(id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const student = props.getStudentById(Number(id))
  // const student = props.getStudent(Number(id))
  const records = props.getQuestions(Number(id))
  const hasNext = props.hasNextQuestion(Number(id))

  return (
    <React.Fragment>
      <PrivateHeader />
      <SiteMain>
        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          <li className="breadcrumbs__item">
            受講者情報
          </li>
        </ol>

        <div className="site-main__main-contents">
          {
            student && 
            <StudentBar student={student as Models.StudentUser} />
          }

          {
            /*
          <StudentNav student={student} type="questions" />
             */
          }

          <div className="question-list">
            <div className={`question-list__body`}>
              {
                records && records.map((record, index) => (
                  <div key={index} className={`question-box question-box--${record.statusCode}`}>
                    <div className="question-box__inner question-box__inner--teacher">
                      <div className="question-box__question">
                        <div className="question-box__badge">
                          { record.statusText }
                        </div>
                        <div className="question-box__title">
                          <Link to={`/questions/${record.id}?from_student_list=1`} className="question-box__link">
                            { record.content.topic.title } > { record.content.meta!.label}
                          </Link>
                        </div>
                        <div className="question-box__spec">
                          <span className="question-box__name">
                            { record.student.nick_name }さん
                          </span>
                          <span className="question-box__datetime">
                            <Moment format="M月D日 HH:mm">{ record.created_at }</Moment>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>

          {
            hasNext &&
            <div className="more-button">
              <div
                onClick={() => { props.fetchNext() }}
                className="more-button__text">
                次のトピックを表示
              </div>
            </div>
          }

          <div className="back-home">
            <Link to='/' className="back-home__link">マイページへ戻る</Link>
          </div>
        </div>

        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          <li className="breadcrumbs__item">
            受講者情報
          </li>
        </ol>
      </SiteMain>
    </React.Fragment>
  );
}

export default StudentQuestions
