import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, InjectedFormProps, SubmissionError, FormErrors } from 'redux-form'

import Login from 'components/common/Login';
import sessionActiions from 'actions/sessionActions';
import { AppState } from "store";
import * as Models from 'models/api'

interface FormData {
  login?: string;
  password?: string;
  [key: string]: string|undefined;
}

export const FormValidate = (values: FormData) => {
  const errors: FormErrors<FormData> = {}
  const requiredFields: string[] = [
    'login',
    'password',
  ]
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required'
    }
  })
  return errors
}

const getLoginIdPrefix = (uiType: Models.UIType) => {
  if (uiType === 'student') { return 'S' }
  if (uiType === 'parent') { return 'P' }
  return ''
}

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch })

const mapStateToProps = (appState: AppState) => {
  const uiType = appState.ui.type!
  return {
    uiType,
    loginIdPlaceholder: getLoginIdPrefix(uiType),
    initialValues: {}
  }
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  { dispatch }: ReturnType<typeof mapDispatchToProps>) => {
  return {
    ...stateProps,
    onSubmit: (values: FormData) => {
      const login = values.login!
      const password = values.password!
      return new Promise<void>((resolve, reject) => {
        dispatch(sessionActiions.createSession({ login, password, promises: { resolve, reject } }))
      }).catch((e: Error) => {
        throw new SubmissionError({
          login: 'User does not exist',
          _error: 'Login failed!'
        })
      })
    }
  }
}

type connectMappedProps = ReturnType<typeof mergeProps>
export type mappedProps = connectMappedProps & InjectedFormProps<FormData, connectMappedProps>

const LoginForm = reduxForm<FormData, connectMappedProps>({
  form: 'login',
  validate: FormValidate
})(Login)

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(LoginForm)