import { reducerWithInitialState } from 'typescript-fsa-reducers';
import produce from 'immer'
import storeUtils from 'utils/store'

import * as Models from 'models/api'
import actions from 'actions/instructorActions';

export interface InstructorState {
  mypage: Models.InstructorMypage | null;
  questionDetail: {
    question_id: number | null;
    loading: boolean;
    record: Models.QuestionDetail | null; 
  },
  processingQuestion: {
    id: number | null; 
  },
  question: {
    type: 'not-answered'|'processing'|'answered'|null;
    records: Models.Question[] | null; 
    page: number;
    has_next: boolean;
  },
  studentQuestion: {
    student_id: number | null;
    records: Models.Question[] | null;
    page: number;
    has_next: boolean;
  },
}

const initialState: InstructorState = {
  mypage: null,
  questionDetail: {
    loading: true,
    question_id: null,
    record: null,
  },
  processingQuestion: {
    id: null,
  },
  question: {
    records: null,
    page: 0,
    type: null,
    has_next: true,
  },
  studentQuestion: {
    student_id: null,
    page: 0,
    has_next: true,
    records: null,
  },
};

const instructorQuestionStateMap: { [key: number]: any } = {
  1: {
    text: '未対応',
    code: 'not-answered',
  },
  2: {
    text: '対応中',
    code: 'processing',
  },
  3: {
    text: '回答済み',
    code: 'answered',
  },
}

const getInstructorStatusMeta = (x: Models.Question) => {
  const tmp = instructorQuestionStateMap[x.status]
  if (x.status !== 1 || !x.support_required) {
    return tmp
  }
  return {
    code: 'office',
    text: '事務局対応',
  }
}

export const instructorReducer = reducerWithInitialState(initialState)
  .case(actions.setMypage, (state, payload) => {
    return produce(state, draftState => {
      draftState.mypage = payload.record
    })
  })
  .case(actions.setQuestionRecords, (state, payload) => {
    return produce(state, draftState => {
      draftState.question.page = payload.page
      draftState.question.type = payload.type

      const records = payload.records.records.map(x => ({
        ...x,
        statusText: getInstructorStatusMeta(x).text,
        statusCode: getInstructorStatusMeta(x).code,
        content: {
          ...x.content,
          meta: storeUtils.getContentMetaObjectByCode(
            x.content.launch_parameters.curriculum_code) 
        }
      }))

      if (!draftState.question.records || payload.page === 1) {
        draftState.question.records = records
      } else {
        draftState.question.records.push(...records)
      }
      draftState.question.has_next = payload.records.total_page > payload.page
    })
  })
  .case(actions.setStudentQuestionRecords, (state, payload) => {
    return produce(state, draftState => {
      draftState.studentQuestion.student_id = payload.student_id
      draftState.studentQuestion.page = payload.page

      // MARK: このデータを使う画面では受講生と同じステータス表記をする
      const records = payload.records.records.map(x => ({
        ...x,
        statusText: x.status === 3 ? '回答済み' : '質問中',
        statusCode: x.status === 3 ? 'answered' : 'not-answered',
        content: {
          ...x.content,
          meta: storeUtils.getContentMetaObjectByCode(
            x.content.launch_parameters.curriculum_code) 
        }
      }))

      if (!draftState.studentQuestion.records || payload.page === 1) {
        draftState.studentQuestion.records = records
      } else {
        draftState.studentQuestion.records.push(...records)
      }
      draftState.studentQuestion.has_next = payload.records.total_page > payload.page
    })
  })
  .case(actions.setQuestionDetail, (state, payload) => {
    return produce(state, draftState => {
      draftState.questionDetail.question_id = payload.question_id
      draftState.questionDetail.loading = payload.loading
      if (payload.record === null) {
        draftState.questionDetail.record = payload.record
      } else {
        const x = payload.record
        draftState.questionDetail.record = {
          ...payload.record,
          statusText: getInstructorStatusMeta(x).text,
          statusCode: getInstructorStatusMeta(x).code,
          content: {
            ...x.content,
            meta: storeUtils.getContentMetaObjectByCode(
              x.content.launch_parameters.curriculum_code) 
          }
        }
      }
    })
  })
  .case(actions.setProcessingQuestion, (state, payload) => {
    return produce(state, draftState => {
      draftState.processingQuestion.id = payload.record ? payload.record.id : null
    })
  })
