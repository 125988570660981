import React from 'react';

import campaignBannerPC from 'assets/img/campaign/guide/banner_pc.jpg';
import campaignBannerSP from 'assets/img/campaign/guide/banner_sp.jpg';

import { Link } from 'react-router-dom';

export const MypageBanner: React.FC = () => {
  return (
    <section className="campaign-box">
      <Link to='/guide' className="campaign-box__banner">
        <picture>
          <source media="(max-width: 767px)" srcSet={campaignBannerSP} />
          <source media="(min-width: 768px)" srcSet={campaignBannerPC} />
          <img src={campaignBannerPC} alt="" className="campaign-box__image" />
        </picture>
      </Link>
    </section>
  )
}