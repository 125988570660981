import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RouteProps } from 'react-router'

import { AppState } from 'store';
import LoginForm from 'containers/common/LoginForm';
import sessionActiions from 'actions/sessionActions';

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    loadSession: () => dispatch(sessionActiions.loadSession()),
  };
}

const mapStateToProps = (appState: AppState) => {
  return {
    session: appState.session,
  }
}

export type mappedProps = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

type loginRequiredProps = RouteProps & mappedProps

export const RedirectBackIfLoggedIn: React.FC<loginRequiredProps> = (props: loginRequiredProps) => {
  useEffect(() => {
    props.loadSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!props.session.loaded) {
    return <React.Fragment></React.Fragment>;
  }

  const redirectToPathName = props.location && props.location.state ? props.location.state.from.pathname : '/'

  if (props.session.user) {
    return <Redirect to={{pathname: redirectToPathName}} />;
  }

  return <LoginForm />;
}

export default connect(mapStateToProps, mapDispatchToProps)(RedirectBackIfLoggedIn)