import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router'

import { AppState } from "store";
import targetComponent from 'components/instructor/StudentStudyResultUnit';
import studyResultActions from 'actions/studyResultActions';

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch })

const mapStateToProps = (appState: AppState, ownProps: RouteComponentProps) => {
  return {
    user: appState.session.user!,
    studyResult: appState.studyResult,
    instructor: appState.instructor,
    ...ownProps,
  }
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  { dispatch }: ReturnType<typeof mapDispatchToProps>) => {
  return {
    ...stateProps,
    getStudent: (student_id: number) => {
      const student = stateProps.studyResult.student
      if (student.student_id !== student_id) {
        return null
      }
      return student.record
    },
    getRecord: (student_id: number, unit_id: number) => {
      const page = stateProps.studyResult.studyUnit
      if (page.unit_id !== unit_id || page.student_id !== student_id) {
        return null
      }
      return page.record
    },
    fetchData: (student_id: number, unit_id: number) => {
      dispatch(studyResultActions.fetchStudent({ student_id }))
      dispatch(studyResultActions.fetchStudyUnit({ student_id, unit_id }))
    },
  }
}

export type mappedProps = ReturnType<typeof mergeProps>
export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(targetComponent))
