import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router'

import { AppState } from "store";
import targetComponent from 'components/trainer/StudentStudyResultCurriculum';
import { curriculums } from 'utils/constants'

import studyResultActions from 'actions/studyResultActions';
import { isRecommendCurriculum, }  from 'utils/store'

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch })

const mapStateToProps = (appState: AppState, ownProps: RouteComponentProps) => {
  return {
    user: appState.session.user!,
    trainer: appState.trainer,
    studyResult: appState.studyResult,
    ...ownProps,
  }
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  { dispatch }: ReturnType<typeof mapDispatchToProps>) => {
  return {
    ...stateProps,
    isRecommendCurriculum,
    getCurriculum: (id: string | number | undefined) => {
      return curriculums.find(x => x.id === Number(id) || x.code === id)
    },
    getStudent: (student_id: number) => {
      const student = stateProps.trainer.student
      if (student.student_id !== student_id) {
        return null
      }
      return student.record
    },
    getRecord: (student_id: number, curriculum_id: number) => {
      const page = stateProps.studyResult.studyCurriculum
      if (page.curriculum_id !== curriculum_id || page.student_id !== student_id) {
        return null
      }
      return page.record
    },
    fetchData: (student_id: number, curriculum_id: number) => {
      dispatch(studyResultActions.fetchStudyCurriculum({ curriculum_id, student_id }))
    },
  }
}

export type mappedProps = ReturnType<typeof mergeProps>
export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(targetComponent))
