import { reducerWithInitialState } from 'typescript-fsa-reducers';
import produce from 'immer'

import * as Models from 'models/api'
import borderResultsActions from 'actions/borderResultsActions';

export interface BorderResultsState {
  verifyTokenResult: {
    token: null | string;
    loading: boolean;
    error: null | boolean;
  }
  universities: Models.BorderResultsUniversitiesRecord | null;
  faculties: Models.BorderResultsFacultiesRecord | null;
  facultyEmptyCode: string | null;
  departments: Models.BorderResultsDepartmentsRecord | null;
  departmentEmptyCode: string | null;
}

const initialState: BorderResultsState = {
  verifyTokenResult: {
    token: null,
    loading: false,
    error: null,
  },
  universities: null,
  faculties: null,
  facultyEmptyCode: null,
  departments: null,
  departmentEmptyCode: null,
};

export const borderResultsReducer = reducerWithInitialState(initialState)
  .case(borderResultsActions.setVerifyTokenResult, (state, payload) => {
    return produce(state, draftState => {
      draftState.verifyTokenResult = payload
    })
  })
  .case(borderResultsActions.setUniversitiesRecord, (state, payload) => {
    return produce(state, draftState => {
      draftState.universities = payload.record
    })
  })
  .case(borderResultsActions.setFacultiesRecord, (state, payload) => {
    return produce(state, draftState => {
      if (!payload.record) {
        draftState.faculties = null
        draftState.facultyEmptyCode = null
        return
      }

      // FIXME:　これの条件は正しいか不明、要検証
      const emptyRecord = payload.record.results.find(x => x.name === '')

      if (emptyRecord) {
        draftState.facultyEmptyCode = emptyRecord.code
      }

      draftState.faculties = {
        ...payload.record,
        results: payload.record.results.filter(x => x.name !== '')
      }
    })
  })
  .case(borderResultsActions.setDepartmentsRecord, (state, payload) => {
    return produce(state, draftState => {
      if (!payload.record) {
        draftState.departments = null
        draftState.departmentEmptyCode = null
        return
      }

      // FIXME:　これの条件は正しいか不明、要検証
      const emptyRecord = payload.record.results.find(x => x.name === '')

      if (emptyRecord) {
        draftState.departmentEmptyCode = emptyRecord.code
      }

      draftState.departments = {
        ...payload.record,
        results: payload.record.results.filter(x => x.name !== '')
      }
    })
  })