import React from 'react';
import { Field } from 'redux-form';

import { mappedProps } from 'containers/common/SettingPasswordEdit';
import PrivateHeader from 'containers/parts/PrivateHeader';
import SiteSettingMainArea from 'components/common/SiteSettingMainArea';

const SettingPasswordEdit: React.FC<mappedProps> = (props: mappedProps) => {
  const resultFragment = (
    <div className="setting-box">
      <div className="setting-box__title setting-box__title--password">
        パスワード変更
      </div>
      <div className="setting-message">
        パスワードの変更が完了しました。
      </div>
      <div className="setting-box__button">
        <button
          onClick={(e) => {
            e.preventDefault()
            props.history.push('/') }}
          className="button button--gray button--back button--xlarge">
            マイページに戻る</button>
      </div>
    </div>
  )
  const formFragment = (
    <div className="setting-box">
      <div className="setting-box__title setting-box__title--password">
        パスワード変更
      </div>
      <div className="setting-box__lead">
        変更するパスワードを入力してください。
      </div>
      <form onSubmit={props.handleSubmit}>
        <div className="setting-box__form">
          <div className="form">
            <div className="form__item">
              <label className="form__label form__label--required">新しいパスワード</label>
              <div className="form__field">
                <Field
                  component="input"
                  className="input input--required form__input form__input--password"
                  type="password"
                  name="password"
                />
              </div>
            </div>
            <div className="form__item">
              <label className="form__label form__label--required form__label--2lines">新しいパスワード<br />（確認）</label>
              <div className="form__field">
                <Field
                  component="input"
                  className="input input--required form__input form__input--password"
                  type="password"
                  name="password_confirmation"
                />

                {
                  !props.pristine && props.invalid &&
                    <div className="form__error">
                      <div className="error-message">
                        パスワードが一致しません。再度入力してください。
                      </div>
                    </div>
                }
              </div>
            </div>
          </div>
          <div className="form">
            <div className="form__buttons">
              <div className="form__button form__button--flex-item">
                <button
                  disabled={props.pristine || props.submitting || props.invalid}
                  className="button button--primary button--xlarge">
                    変更する</button>
              </div>
              <div className="form__button form__button--flex-item">
                <button
                  onClick={(e) => {
                    e.preventDefault()
                    props.history.push('/') }}
                  className="button button--gray button--back button--xlarge">
                    マイページに戻る</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )

  return (
    <React.Fragment>
      <PrivateHeader />
      <SiteSettingMainArea page="password_edit" user={props.user}>
        { props.submitSucceeded ? resultFragment : formFragment }
      </SiteSettingMainArea>
    </React.Fragment>
  );
}

export default SettingPasswordEdit;
