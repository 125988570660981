import React, { useEffect, useState } from 'react';
import Moment from 'react-moment'
import { Field, Fields } from 'redux-form'
import { useHistory } from 'react-router-dom';
import { Yubinbango } from 'lib/yubinbango';

import { mappedProps } from 'containers/student/SettingProfileEdit';
import PrivateHeader from 'containers/parts/PrivateHeader';
import SiteSettingMainArea from '../common/SiteSettingMainArea';
import UserInfoParts from  './setting_profile_edit/UserInfo';
import DefaultStudyPlanForm from  './setting_profile_edit/DefaultStudyPlanForm';
import CorporateStudyPlanForm from  './setting_profile_edit/CorporateStudyPlanForm';

import { renderInputField, renderSelectField, renderIfFieldsHasError } from 'components/parts/FormParts'
import classNames from 'classnames';

const updateFieldsByPostalCode = (code: string, change: (field: string, value:any) => void) => {
  new Yubinbango(code, (x: any) => {
    if (x.region !== '') {
      change('prefecture', x.region);
    }
    if (x.locality !== '' || x.street !== '') {
      change('municipality', x.locality + x.street);
    }
  });
}

const SettingProfileEdit: React.FC<mappedProps> = (props: mappedProps) => {
  const [openSelectPlan, setOpenSelectPlan] = useState<boolean>(false);
  const history = useHistory()

  useEffect(() => {
    props.fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (props.submitSucceeded) {
    return (
      <React.Fragment>
        <PrivateHeader />
        <SiteSettingMainArea page="profile_edit" user={props.user}>
          <div className="setting-box">
            <div className="setting-box__title setting-box__title--profile">
              プロフィール確認・変更
            </div>
            <div className="setting-message">
              プロフィールを更新しました。
            </div>
            <div className="setting-box__button">
              <button
                onClick={(e) => {
                  e.preventDefault()
                  history.push('/')
                }}
                className="button button--gray button--back button--xlarge">マイページに戻る</button>
            </div>
          </div>
        </SiteSettingMainArea>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <PrivateHeader />
      <SiteSettingMainArea page="profile_edit" user={props.user}>
        <div className="setting-box">
          <form onSubmit={props.handleSubmit}>
            <div className="setting-box__title setting-box__title--profile">
              プロフィール確認・変更
            </div>
            <div className="setting-box__subtitle">
              会員情報
            </div>

            <UserInfoParts user={props.user} plan={props.plan} />

            {
              !props.user.corporation && 
              <>
                <div className="setting-box__subtitle">
                  契約プラン
                </div>
                <div className="setting-box__form">
                  <div className="form form--confirm">
                    <div className="form__item">
                      <div className="form__label">
                        ご契約プラン
                      </div>
                      <div className="form__field">
                        { props.user.plan.name }
                        <span
                          onClick={e => {
                            e.preventDefault()
                            if (props.user.plan.id === 3) return
                            setOpenSelectPlan(!openSelectPlan)
                          }}
                          className={classNames(
                            "plan-change-toggler js-toggle-plan-box", {
                              'is-disabled': props.user.plan.id === 3,
                            })}>{
                              props.user.plan.id === 3 ? '※プランの変更はできません' : 'プランを変更する'
                            }
                        </span>
                        <div className="form__field-annotation-div">
                          ※ ご選択プランの期間ごとの自動更新となります。料金は<a href="https://www.kawaijukuone.co.jp/price/" target="_blank" rel="noopener noreferrer">こちら</a>をご確認ください。
                        </div>
                      </div>
                    </div>
                    <div className={classNames("plan-change-box", { 'is-active': openSelectPlan })}>
                      <div className="plan-change-box__title">
                        プランを選択してください
                      </div>

                      {
                        props.selectablePlans.map(plan => (
                          <label key={plan.id} className={classNames("radio plan-change-box__label", {
                            'is-checked': props.planId === plan.id.toString(),
                          })}>
                            <Field
                              component="input"
                              className="radio__radio plan-change-box__radio"
                              type="radio"
                              name='plan_id'
                              value={plan.id}
                            />
                            {plan.name} ：<b>{plan.lump_sum_price_with_tax}円</b>（月あたり&nbsp;{plan.price_with_tax}円）
                          </label>
                        ))
                      }

                      <div className="plan-change-box__notes">
                        <strong>※プランの変更は、ページ下部の「変更する」ボタンから完了してください。</strong><br/>
                        ※価格は税込です。<br/>
                        ※変更後のプランは、次回決済後に切り替わります。
                      </div>
                    </div>
                    <div className="form__item">
                      <div className="form__label">
                        トレーナーサービス
                      </div>
                      <div className="form__field">
                        { props.user.trainer_contracted && '申し込み済' }
                      </div>
                    </div>
                    <div className="form__item">
                      <div className="form__label">
                        トレーナー希望条件
                      </div>
                      <div className="form__field">
                        第1希望：{ props.user.first_request } <br />
                        第2希望：{ props.user.second_request }<br />
                        第3希望：{ props.user.third_request } <br />
                        <div className="form__annotation">
                          ※ 特定商取引法に基づく表記は、<a href="https://www.kawaijukuone.co.jp/law/" target="_blank" rel="noopener noreferrer">こちら</a>をご確認ください。
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            }

            <div className="setting-box__subtitle">
              受講者情報
            </div>
            <div className="setting-box__form">
              <div className="form form--confirm">
                <div className="form__item">
                  <label className="form__label">氏名</label>
                  <div className="form__field">
                    { props.user.full_name }
                  </div>
                </div>
                <div className="form__item">
                  <label className="form__label">氏名(カナ)</label>
                  <div className="form__field">
                    { props.user.full_name_read }
                  </div>
                </div>
                <div className="form__item">
                  <label className="form__label">性別</label>
                  <div className="form__field">
                    { props.user.gender }
                  </div>
                </div>
                <div className="form__item">
                  <label className="form__label">生年月日</label>
                  <div className="form__field">
                    <Moment format="YYYY年M月D日">{ props.user.birthday }</Moment>
                  </div>
                </div>
              </div>
              <div className="form">
                <div className="form__item">
                  <label className="form__label form__label--required">メールアドレス</label>
                  <div className="form__field">
                    <Field
                      component={renderInputField}
                      className="input input--required form__input form__input--email"
                      type="email"
                      name="email"
                      placeholder="user＠kawai-one.com"
                    />

                    <Fields names={[ 'email' ]} component={renderIfFieldsHasError}>
                      <div className="form__error">
                        <div className="error-message">
                          メールアドレス（半角英数記号）をご入力ください。
                        </div>
                      </div>
                    </Fields>
                  </div>
                </div>
                <div className="form__item">
                  <label className="form__label">住所</label>
                  <div className="form__field">

                    {
                      !props.user.corporation && 
                      <div className="form__note form__note--orange">
                        住所は、保護者と異なる場合のみご入力ください。
                      </div>
                    }

                    〒
                    <Field
                      component={renderInputField}
                      className="input input--required form__input form__input--zip"
                      type="text"
                      name="postal_code"
                      placeholder="1112222"
                      size={7}
                    />

                    {
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        updateFieldsByPostalCode(props.postalCode, props.change);
                      }}
                      className="button button--primary button--no-chevron form__zip-button">
                      住所検索
                    </button>
                    }

                    <Fields names={[ 'postal_code' ]} component={renderIfFieldsHasError}>
                      <div className="form__error">
                        <div className="error-message">
                          郵便番号（半角数字）を入力してください。
                        </div>
                      </div>
                    </Fields>

                    <div className="form__note">
                      （半角数字、- [ハイフン] は含めない）
                    </div>
                    <div className="select form__select-wrapper">
                      <Field
                        component={renderSelectField}
                        className="select__select"
                        name="prefecture"
                      >
                        <option value="">選択してください</option>
                        { props.prefectures.map(x => (<option key={x}>{x}</option>)) }
                      </Field>

                      <Fields names={[ 'prefecture' ]} component={renderIfFieldsHasError}>
                        <div className="form__error">
                          <div className="error-message">
                            都道府県を選択してください。
                          </div>
                        </div>
                      </Fields>
                    </div>
                    <br />

                    <Field
                      component={renderInputField}
                      className="input form__input form__input--city"
                      type="text"
                      name="municipality"
                    />

                    <Fields names={[ 'municipality' ]} component={renderIfFieldsHasError}>
                      <div className="form__error">
                        <div className="error-message">
                          市区町村名を入力してください。
                        </div>
                      </div>
                    </Fields>

                    <div className="form__note">
                      市区町村名までを入力してください。
                    </div>

                    <Field
                      component={renderInputField}
                      className="input form__input form__input--address"
                      type="text"
                      name="address"
                    />

                    <Fields names={[ 'address' ]} component={renderIfFieldsHasError}>
                      <div className="form__error">
                        <div className="error-message">
                          住所を入力してください。
                        </div>
                      </div>
                    </Fields>

                    <div className="form__note">
                      番地、建物名（マンション・団地・社宅）、部屋番号等
                    </div>
                  </div>
                </div>
                <div className="form__item">
                  <label className="form__label">高校種別</label>
                  <div className="form__field">
                    <div className="select form__select-wrapper">
                      <Field
                        component={renderSelectField}
                        className="select__select"
                        name="high_school_kind"
                      >
                        <option value="">
                          選択してください
                        </option>
                        { props.highSchoolKinds.map(x => (<option key={x}>{x}</option>)) }
                     </Field>
                    </div>
                  </div>
                </div>
                <div className="form__item">
                  <label className="form__label form__label--required">在籍（卒業）高校</label>
                  <div className="form__field">
                    <Field
                      component={renderInputField}
                      className="input input--required form__input form__input--highschool"
                      type="text"
                      name="high_school_name"
                      placeholder="東京都立河合高等学校"
                    />

                    <Fields names={[ 'high_school_name' ]} component={renderIfFieldsHasError}>
                      <div className="form__error">
                        <div className="error-message">
                          在籍高校をご入力ください。
                        </div>
                      </div>
                    </Fields>

                    <div className="form__note">
                      中学生の方は中学校名をご記入ください。
                    </div>
                  </div>
                </div>
                <div className="form__item">
                  <label className="form__label form__label--required">学年</label>
                  <div className="form__field">
                    <div className="select form__select-wrapper">
                      <Field
                        component={renderSelectField}
                        className="select__select select__select--required"
                        name="school_grade"
                      >
                        <option value="">
                          選択してください
                        </option>
                        { props.masterData && props.masterData.school_grades.map(x => (<option key={x}>{x}</option>)) }
                     </Field>
                    </div>

                    <Fields names={[ 'school_grade' ]} component={renderIfFieldsHasError}>
                      <div className="form__error">
                        <div className="error-message">
                          学年を選択してください。
                        </div>
                      </div>
                    </Fields>
                  </div>
                </div>
                <div className="form__item">
                  <label className="form__label">志望大学</label>
                  <div className="form__field">
                    <Field
                      component={renderInputField}
                      className="input form__input form__input--long"
                      type="text"
                      name="desired_university"
                    />

                    <div className="form__note">
                      志望大学名、学部・学科を入力してください。
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {
              !props.user.corporation &&
              <>
                <div className="setting-box__subtitle">
                  トレーナー希望情報
                </div>
                <div className="setting-box__form">
                  <div className="form">
                    <div className="form__item">
                      <label className="form__label">文理系統</label>
                      <div className="form__field">
                        <div className="select form__select-wrapper">
                          <Field
                            name="trainer_department_type"
                            component={renderSelectField}
                            className="select__select" >
                            <option value="">決まっていない</option>
                            { props.facultyKinds.map(x => <option key={x.id}>{x.name}</option>) }
                          </Field>
                        </div>
                      </div>
                    </div>
                    <div className="form__item">
                      <label className="form__label">学部系統</label>
                      <div className="form__field">
                        <div className="select form__select-wrapper">
                          <Field
                            name="trainer_department_name"
                            component={renderSelectField}
                            className="select__select" >
                            <option value="">決まっていない</option>
                            { props.selectableFaculties.map(x => <option key={x.name}>{x.name}</option>) }
                          </Field>
                        </div>
                      </div>
                    </div>
                    <div className="form__item">
                      <label className="form__label form__label--university">在籍または<br className="_pc" />合格大学</label>
                      <div className="form__field">
                        <div className="select form__select-wrapper">
                          <Field
                            name="trainer_university"
                            component={renderSelectField}
                            className="select__select">
                            <option value="">決まっていない</option>
                            { props.universities.map(x => <option key={x.name}>{x.name}</option>) }
                          </Field>
                        </div>
                      </div>
                    </div>
                    <div className="form__item">
                      <label className="form__label">部活動</label>
                      <div className="form__field">
                        <div className="select form__select-wrapper">
                          <Field
                            name="trainer_club_type"
                            component={renderSelectField}
                            className="select__select">
                            <option value="">無所属</option>
                            { props.clubKinds.map(x => <option key={x.name}>{x.name}</option>) }
                          </Field>
                        </div>

                        <br />

                        <Field
                          component={renderInputField}
                          className="input form__input form__input--club"
                          type="text"
                          name="trainer_club_name"
                          placeholder=""
                          // disabled={!props.selectClubKind}
                        />
                        <div className="form__note">
                          あなたが今在籍している部活動名を入力してください。指導の参考にさせていただきます。
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            }

            <div className="setting-box__subtitle">
              学習プラン<small className="setting-box__subtitle-note">（学習範囲）</small>
            </div>

            {
              props.user.corporation ?
              <CorporateStudyPlanForm
                user={props.user}
                masterData={props.masterData}
                submitFailed={props.submitFailed} />
              :
              <DefaultStudyPlanForm masterData={props.masterData} submitFailed={props.submitFailed} />
            }

            {
              !props.user.corporation &&
              <div className="setting-box__subtitle">
                保護者情報
              </div>
            }

            <div className="setting-box__form">
              {
                !props.user.corporation &&
                <div className="form form--confirm">
                  <div className="form__item">
                    <label className="form__label">氏名</label>
                    <div className="form__field">
                      { props.user.parent.full_name }
                    </div>
                  </div>
                  <div className="form__item">
                    <label className="form__label">氏名(カナ)</label>
                    <div className="form__field">
                      { props.user.parent.full_name_read }
                    </div>
                  </div>
                  <div className="form__item">
                    <label className="form__label">住所</label>
                    <div className="form__field">
                      〒{ props.user.parent.postal_code }<br />
                      { props.user.parent.prefecture }<br />
                      { props.user.parent.municipality }<br />
                      { props.user.parent.address }
                    </div>
                  </div>
                  <div className="form__item">
                    <label className="form__label">メールアドレス</label>
                    <div className="form__field">
                      { props.user.parent.email }
                    </div>
                  </div>
                  <div className="form__item">
                    <label className="form__label">ID</label>
                    <div className="form__field">
                      { props.user.parent.login }
                    </div>
                  </div>
                </div>
              }

              <div className="form">
                <div className="form__buttons">
                  {
                  <div className="form__button form__button--flex-item">
                    <button className="button button--primary button--xlarge">変更する</button>
                  </div>
                  }
                  <div className="form__button form__button--flex-item">
                    <button
                      onClick={(e) => {
                        e.preventDefault()
                        history.push('/')
                      }}
                      className="button button--gray button--back button--xlarge">
                      マイページに戻る
                    </button>
                  </div>
                </div>
              </div>

            </div>

          </form>
        </div>
      </SiteSettingMainArea>
    </React.Fragment>
  );
}

export default SettingProfileEdit;
