import React from 'react';
import SettingSideNav from './SettingSubNav'

import * as Models from 'models/api'

type ownProps = {
  children?: React.ReactNode;
  page: string;
  user: Models.User;
}

const SiteSettingMainArea: React.FC<ownProps> = (props: ownProps) => {
  return (
    <main className="site-main site-main--setting">
      <div className="site-main__main">
        <div className="site-main__main-inner">
          <div className="site-main__main-contents">
            {props.children}
          </div>
        </div>
      </div>
      <SettingSideNav page={props.page} user={props.user} />
    </main>
  );
}

export default SiteSettingMainArea;
