import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, InjectedFormProps, SubmissionError } from 'redux-form'

import SignupStep2 from 'components/signup/SignupStep2';

import { AppState } from "store";

import { withRouter, RouteComponentProps } from 'react-router';
import storeUtils from 'utils/store'
import { plans as defaultPlans } from 'utils/constants'
import * as Models from 'models/api'

import { Step1FormData, formKey as step1FormKey } from './SignupStep1';
import userActions from 'actions/userActions';

import{ isAvailable as isAvailableDiscount2024winter, campaignPlans } from 'utils/campaign/discount2024winter'
import moment from 'moment';

const formKey = 'signup_step2'

const availableExternalAlliances = [
  'moshinavi'
]

export type Step2FormData = {
  external_alliance?: string;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch })

const mapStateToProps = (appState: AppState, ownProps: RouteComponentProps) => {
  const step1Values: Step1FormData = appState.temporaryForm.forms[step1FormKey];

  const useCampaignPlans = isAvailableDiscount2024winter(
    appState.ui.env!,
    moment())
  const plans = useCampaignPlans ? campaignPlans: defaultPlans

  const masterData = appState.ui.master.records
  const selectedPlan = plans.find(x => step1Values && x.id === step1Values.plan_id);

  return {
    step1Values,
    selectedPlan,
    masterData,
    isCompleteBeforeStep: storeUtils.hasTemporaryForms(
      appState, [step1FormKey]),
    initialValues: {},
    initPage: '/step1',
    secondPage: '/step2',
    nextPage: '/result',
    ...ownProps,
  }
}

const mapFormValuesToSignupParams = (
  step1Values: Step1FormData,
  step2Values: { external_alliance?: string; },
): Models.SignupParams => {

  let external_alliance: string | undefined = step2Values.external_alliance
  if (external_alliance && !availableExternalAlliances.includes(external_alliance)) {
    external_alliance = undefined
  }

  // const recommend_math = masterData.math_levels.slice(-1).pop()!.level
  // const recommend_chemistry = masterData.chemistry_levels.slice(-1).pop()!.level
  // const recommend_physics = masterData.physics_levels.slice(-1).pop()!.level
  // const recommend_classical_japanese = masterData.classical_japanese_levels.slice(-1).pop()!.level
  // const recommend_world_history = masterData.world_history_levels.slice(-1).pop()!.level
  // const recommend_japanese_history = masterData.japanese_history_levels.slice(-1).pop()!.level
  const recommend_junior_english = 1
  const recommend_junior_math = 1
  const recommend_junior_science = 1
  const recommend_english = 2
  const recommend_math = 6
  const recommend_chemistry = 2
  const recommend_physics = 2
  const recommend_modern_japanese = 1
  const recommend_classical_japanese = 1
  const recommend_world_history = 1
  const recommend_japanese_history = 1
  const recommend_biology = 1
  const recommend_english_words = 1
  /* issue-673 */
  const recommend_new_chemistry_2024 = 0
  const recommend_new_biology_2024 = 0
  // MARK: 新課程数学は新規登録時受講しない設定
  // https://github.com/gatjp/kawai1-front/issues/498#issuecomment-1088177218
  const recommend_new_math = 0

  let values: Models.SignupParams & { [key: string]: any } = {
    full_name: `${step1Values.student_last_name} ${step1Values.student_first_name}`,
    full_name_read: `${step1Values.student_last_name_read} ${step1Values.student_first_name_read}`,
    birthday: `${step1Values.birthday_year}-${step1Values.birthday_month}-${step1Values.birthday_day}`,
    gender: step1Values.gender!,
    email: step1Values.student_email!,
    high_school_name: step1Values.high_school_name!,
    school_grade: step1Values.school_grade!,
    nick_name: step1Values.nick_name!,

    recommend_junior_english,
    recommend_junior_math,
    recommend_junior_science,
    recommend_english,
    recommend_math,
    recommend_chemistry,
    recommend_physics,
    recommend_modern_japanese,
    recommend_classical_japanese,
    recommend_world_history,
    recommend_japanese_history,
    recommend_biology,
    recommend_english_words,
    recommend_new_math,
    recommend_new_chemistry_2024,
    recommend_new_biology_2024,

    trainer_contracted: step1Values.trainer_contracted!,
    plan_id: step1Values.plan_id!,
    external_alliance: external_alliance,

    parent: {
      full_name: `${step1Values.last_name} ${step1Values.first_name}`,
      full_name_read: `${step1Values.last_name_read} ${step1Values.first_name_read}`,
      email: step1Values.email!,
      postal_code: step1Values.postal_code!,
      prefecture: step1Values.prefecture!,
      municipality: step1Values.municipality!,
      address: step1Values.address!,
    }
  }

  if (values.trainer_contracted) {
    values = {
      ...values,
      trainer_department_type: step1Values.desired_faculty_kind,
      trainer_department_name: step1Values.desired_faculty,
      trainer_university: step1Values.desired_university,
      trainer_club_type: step1Values.club_kind,
      trainer_club_name: step1Values.club_name,
      first_request: step1Values.first_request,
      second_request: step1Values.second_request,
      third_request: step1Values.third_request,
    }
  }
  return values
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  { dispatch }: ReturnType<typeof mapDispatchToProps>) => {

  return {
    ...stateProps,
    onSubmit: (values: any) => {
      const external_alliance = values.external_alliance as string
      const params = mapFormValuesToSignupParams(
        stateProps.step1Values,
        {
          external_alliance,
        }
      )

      // console.log(params)
      // return new Promise<void>((resolve, reject) => {
      //   resolve()
      // }).catch((e: Error) => {
      //   throw new SubmissionError({
      //     _error: storeUtils.getErrorMessage(e, '\n')
      //   })
      // })

      return new Promise<void>((resolve, reject) => {
        dispatch(userActions.signup({ params, promises: { resolve, reject } }))
      }).catch((e: Error) => {
        throw new SubmissionError({
          _error: storeUtils.getErrorMessage(e, '\n')
        })
      })
    }
  };
}

type connectMappedProps = ReturnType<typeof mergeProps> & ReturnType<typeof mapStateToProps>
export type mappedProps = connectMappedProps & InjectedFormProps<FormData, connectMappedProps>

const form = reduxForm<FormData, connectMappedProps>({
  form: formKey,
})(SignupStep2)

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(form))
