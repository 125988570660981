import React, { useEffect } from 'react';
import { Redirect, Link } from 'react-router-dom';
import Moment from 'react-moment';

import { mappedProps } from 'containers/student/SettingPointExchangeConfirm';
import PrivateHeader from 'containers/parts/PrivateHeader';
import SideNav from 'containers/student/SettingPointExchangeNav';

const commaFormat = (value: number) => {
  return value.toLocaleString()
}

const getPlanName = (item: number) => {
  if (item === 1) return 'プラン料金割引'
  if (item === 2) return '質問チケット交換'
  if (item === 3) return 'Amazonギフト券（Eメールタイプ）'
  return ''
}

const SettingPointExchangeConfirm: React.FC<mappedProps> = (props: mappedProps) => {
  useEffect(() => {
    props.fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // MARK: コーポレートユーザ利用不可
  if (props.user.corporation) {
    return <Redirect to='/' />;
  }

  const prevPathname = '/setting/point_exchange'

  if (!props.pointExchangeState.confirm && !props.pointExchangeState.execute  && !props.pointExchangeState.error) {
    return <Redirect to={prevPathname} />;
  }

  if (props.pointExchangeState.error) {
    const paramsState = props.pointExchangeState.params!
    // MARK: エラー表示
    // https://github.com/gatjp/kawai1-back/blob/feature/point-exchange-program-api/docs/api/v2/student/point_exchange_create.md
    return (
      <React.Fragment>
        <PrivateHeader />
        <main className="site-main site-main--setting">
          <div className="site-main__main">
            <div className="site-main__main-inner">
              <div className="site-main__main-contents">
                <div className="setting-box setting-box--point">
                  <div className="setting-box__title setting-box__title--point">
                    ポイント交換
                  </div>
                  <div className="setting-box__body">
                    <div className="point-exchange-confirmation">
                      <div className="point-exchange-confirmation__head">
                        { getPlanName(paramsState.item_ordered)}
                      </div>
                      <div className="point-exchange-confirmation__error">
                        { props.pointExchangeState.error }
                      </div>
                      <div className="point-exchange-confirmation__text">
                        「ポイント交換」ページをご確認ください。
                      </div>
                    </div>
                  </div>
                  <div className="form">
                    <div className="form__buttons">
                      <div className="form__button form__button--flex-item">
                        <button 
                          onClick={e => {
                            e.preventDefault()
                            props.history.push(prevPathname)
                          }}
                          className="button button--gray button--back button--xlarge">
                            ポイント交換へ戻る
                          </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SideNav />
        </main>
      </React.Fragment>
    )
  }

  if (props.pointExchangeState.execute) {
    const executeState = props.pointExchangeState.execute!

    // MARK: 結果表示
    return (
      <React.Fragment>
        <PrivateHeader />
        <main className="site-main site-main--setting">
          <div className="site-main__main">
            <div className="site-main__main-inner">
              <div className="site-main__main-contents">
                <div className="setting-box setting-box--point">
                  <div className="setting-box__title setting-box__title--point">
                    ポイント交換
                  </div>
                  <div className="setting-box__body">
                    <div className="point-exchange-confirmation">
                      <div className="point-exchange-confirmation__head">
                        { getPlanName(executeState.point_exchange.item_ordered)}
                      </div>

                      {
                        executeState.point_exchange.item_ordered === 1 &&
                        <div className="point-exchange-confirmation__text">
                          次回お支払い金額から、ご利用ポイント分の金額が差し引かれます。
                        </div>
                      }

                      {
                        executeState.point_exchange.item_ordered === 3 &&
                        <div className="point-exchange-confirmation__text">
                          Amazonギフト券（Eメールタイプ）は、ご登録の受講者メールアドレス宛に送付されます。送付には最大15営業日程度かかることがございます。
                        </div>
                      }

                      <div className="point-exchange-confirmation__complete">
                        ポイントの交換が完了しました。
                      </div>

                      <div className="point-exchange-confirmation__point point-exchange-confirmation__point--complete">
                        現在のポイント数：{ commaFormat(props.user.current_points) }pt
                      </div>

                      {
                        executeState.point_exchange.item_ordered === 1 &&
                        <div className="point-exchange-confirmation__note">
                          プラン料金割引額の詳細は、<Link className="point-exchange-confirmation__link" to='/setting/point_exchange'>ポイント交換について</Link>に表示されます。
                        </div>
                      }

                    </div>
                  </div>
                  <div className="form">
                    <div className="form__buttons">
                      <div className="form__button form__button--flex-item">
                        <button
                          onClick={e => {
                            e.preventDefault()
                            props.history.push('/')
                          }}
                          className="button button--gray button--back button--xlarge">マイページへ戻る</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SideNav />
        </main>
      </React.Fragment>
    )
  }

  // 確認画面表示
  const confirmState = props.pointExchangeState.confirm!
  const plan = props.plan

  return (
    <React.Fragment>
      <PrivateHeader />
      <main className="site-main site-main--setting">
        <div className="site-main__main">
          <div className="site-main__main-inner">
            <div className="site-main__main-contents">
              <div className="setting-box setting-box--point">
                <div className="setting-box__title setting-box__title--point">
                  ポイント交換
                </div>
                <div className="setting-box__body">
                  <div className="point-exchange-confirmation">
                    <div className="point-exchange-confirmation__head">
                      { getPlanName(confirmState.point_exchange.item_ordered)}
                    </div>
                    <div className="point-exchange-confirmation__text">
                      {
                        confirmState.point_exchange.item_ordered === 1 &&
                        '次回お支払い金額から、ご利用ポイント分の金額が差し引かれます。'
                      }
                      {
                        confirmState.point_exchange.item_ordered === 3 &&
                        'Amazonギフト券（Eメールタイプ）は、ご登録の受講者メールアドレス宛に送付されます。送付には最大15営業日程度かかることがございます。'
                      }
                    </div>
                    <div className="point-exchange-confirmation__confirm">
                      {
                        confirmState.point_exchange.item_ordered === 1 &&
                        <React.Fragment>
                          {commaFormat(confirmState.point_exchange.number_ordered * props.pointRate) }ptを{ commaFormat(confirmState.point_exchange.number_ordered * props.planDiscountExchangeRate) }円分と交換しますが、<br />よろしいですか？
                        </React.Fragment>
                      }
                      {
                        confirmState.point_exchange.item_ordered === 3 &&
                        <React.Fragment>
                          { commaFormat(confirmState.point_exchange.number_ordered * props.pointRate) }ptを{ commaFormat(confirmState.point_exchange.number_ordered * props.amazonGiftExchangeRate) }円分と交換しますが、<br />よろしいですか？
                        </React.Fragment>
                      }

                    </div>
                    <div className="point-exchange-confirmation__point">
                      {
                        plan && confirmState.point_exchange.item_ordered === 1 &&
                        <div className="point-exchange-confirmation__price">
                          次回お支払い金額：<b>{ commaFormat(plan.price - plan.discount_points - confirmState.point_exchange.number_ordered * props.planDiscountExchangeRate) } 円（税込）</b>
                          <small>次回決済日：<Moment format="YYYY年M月D日">{ plan.next_payment_on }</Moment></small>
                        </div>
                      }
                      ご利用後のポイント数：<b>{ commaFormat(props.user.current_points - confirmState.point_exchange.number_ordered * props.pointRate) }pt</b>
                      <br className="_sp" />（現在のポイント数 : { commaFormat(props.user.current_points) } pt）
                    </div>
                    <div className="point-exchange-confirmation__note">
                      <b>ご利用いただいたポイントの返還・交換内容の変更はできませんので、予めご了承ください。</b>
                    </div>
                  </div>
                </div>
                <div className="form">
                  <div className="form__buttons">
                    <div className="form__button form__button--flex-item">
                      <form onSubmit={props.handleSubmit}>
                        <button
                          disabled={props.submitting}
                          className="button button--primary button--xlarge">ポイントを交換する</button>
                      </form>
                    </div>
                    <div className="form__button form__button--flex-item">
                      <button
                        onClick={e => {
                          e.preventDefault()
                          props.history.push(prevPathname)
                        }}
                        className="button button--gray button--back button--xlarge">前のページへ戻る</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="back-home">
                <a
                  onClick={e => {
                    e.preventDefault()
                    props.history.push('/')
                  }}
                  href="#!"
                  className="back-home__link">マイページへ戻る</a>
              </div>

            </div>
          </div>
        </div>

        <SideNav />
      </main>
    </React.Fragment>
  );
}

export default SettingPointExchangeConfirm;