import { StudentUser } from 'models/api';

const targetCorporationNames = [
  'ショウイン',
]

export const isAvailable = (user: StudentUser): boolean => {
  // MARK: ロゴ変更の対象法人か 
  if (!user.corporation) {
    return false
  }
  return targetCorporationNames.includes(user.corporation.name)
}
