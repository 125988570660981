import React, { useState, useEffect } from 'react';
import { Field } from 'redux-form';
import { Link, useParams, } from 'react-router-dom';

import { mappedProps } from 'containers/student/StudyLogQuestionForm';
import PrivateHeader from 'containers/parts/PrivateHeader';
import SiteMain from 'containers/parts/SiteMain';

import { renderTextareaField, FormFileInput } from 'components/parts/FormParts'
import Moment from 'react-moment';

import pdfIconImage from 'assets/img/img_pdf.png';

const nl2br = require('react-nl2br');

const TicketNoticeMessageBox = () => {
  return (
    <div className="ticket-message">
      <div className="ticket-message__text">
        先生への質問の注意事項
        <ul className="ticket-message__list">
          <li className="ticket-message__list-item">
            河合塾Oneの教材の学習内容について、河合塾Oneの先生に質問をすることができます。
          </li>
          <li className="ticket-message__list-item">
            ２問以上の問題を１つにまとめた質問、複数領域にまたがる質問、河合塾Oneの教材以外からの質問はできません。
          </li>
          <li className="ticket-message__list-item">
            対象外の質問をされた場合、質問は無効となり、質問チケットのみ消費されます。
          </li>
          <li className="ticket-message__list-item">
            １回の質問につき、質問チケットが１枚消費されます。
          </li>
          <li className="ticket-message__list-item">
            質問チケットは毎月１日に自動的に３枚にリセットされます。
          </li>
          <li className="ticket-message__list-item">
            最長で７２時間以内にマイページに回答が届きます。（質問の難易度・種類によっては、７２時間を超える場合もありますので、予めご了承ください。）
          </li>
        </ul>
      </div>
    </div>
  )
}

type QuestionAttachmentPreviewProps = { attachment: { data: string; file: File; }}
const QuestionAttachmentPreview: React.FC<QuestionAttachmentPreviewProps> = 
(props: QuestionAttachmentPreviewProps) => {
  return (
    <React.Fragment>
      {
        props.attachment.file.type.startsWith('image/') &&
        <React.Fragment>
          <img src={props.attachment.data} alt="添付画像" className="question-form__image" /><br />
          <div className="answer-form__file-name answer-form__file-name--teacher">
            { props.attachment.file.name }
          </div>
        </React.Fragment>
      }
      {
        props.attachment.file.type.startsWith('application/pdf') &&
        <React.Fragment>
          <img src={pdfIconImage} alt="" className="question-form__image question-form__image--pdf" />
          <div className="answer-form__file-name answer-form__file-name--teacher">
            {
              props.attachment.file.name
            }
          </div>
        </React.Fragment>
      }
    </React.Fragment>
  )
}

const StudyLogQuestionForm: React.FC<mappedProps> = (props: mappedProps) => {
  const { curriculum, id } = useParams();

  const [confirm, setConfirm] = useState(false)
  const [attachmentSizeOver, setAttachmentSizeOver] = useState(false)

  useEffect(() => {
    if (id) {
      props.fetchData(Number(id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const record = props.getRecord(Number(id));

  const ticketCount = props.student.questionsSheetsCountInMonth.record;
  const canNotSendQuestion = ticketCount !== null && ticketCount <= 0;

  if (props.submitSucceeded) {
    return (
      <React.Fragment>
        <PrivateHeader />
        <SiteMain>
          <ol className="breadcrumbs">
            <li className="breadcrumbs__item">
              <Link to='/' className="breadcrumbs__link">マイページ</Link>
            </li>
            <li className="breadcrumbs__item">
              <Link to={`/study_logs/${curriculum}`} className="breadcrumbs__link">学習履歴</Link>
            </li>
            <li className="breadcrumbs__item">
              先生への質問
            </li>
          </ol>

          <div className="site-main__main-contents">
            <div className="page-title page-title--teacher">
              担当教科の質問
              <Link to={`/study_logs/${curriculum}`} className="page-title__link page-title__link--back">学習履歴に戻る</Link>
            </div>

            <div className="question-form">
              <div className="question-form__complete">
                質問を送信しました。<br />
                先生からの回答をお待ちください。<br />
                <small className="question-form__complete-note">（目安：72時間以内）</small>
              </div>

              <div className="question-form__buttons">
                <div className="question-form__button">
                  <Link to={`/study_logs/${curriculum}`} className="button button--large button--back button--gray">学習履歴に戻る</Link>
                </div>
              </div>
            </div>

            <div className="back-home">
              <Link to='/' className="back-home__link">マイページへ戻る</Link>
            </div>
          </div>

          <ol className="breadcrumbs">
            <li className="breadcrumbs__item">
              <Link to='/' className="breadcrumbs__link">マイページ</Link>
            </li>
            <li className="breadcrumbs__item">
              <Link to={`/study_logs/${curriculum}`} className="breadcrumbs__link">学習履歴</Link>
            </li>
            <li className="breadcrumbs__item">
              先生への質問
            </li>
          </ol>
        </SiteMain>
      </React.Fragment>
    )
  }

  if (canNotSendQuestion) {
    return (
      <React.Fragment>
        <PrivateHeader />
        <SiteMain>
          <ol className="breadcrumbs">
            <li className="breadcrumbs__item">
              <Link to='/' className="breadcrumbs__link">マイページ</Link>
            </li>
            <li className="breadcrumbs__item">
              <Link to={`/study_logs/${curriculum}`} className="breadcrumbs__link">学習履歴</Link>
            </li>
            <li className="breadcrumbs__item">
              先生への質問
            </li>
          </ol>

          <div className="site-main__main-contents">
            <div className="page-title">
              先生に質問
              <Link to={`/study_logs/${curriculum}`} className="page-title__link page-title__link--back">学習履歴に戻る</Link>
            </div>

            <TicketNoticeMessageBox />

            <div className="question-form">
              <div className="question-form__ticket">
                質問チケット
                <div className="question-form__ticket-number question-form__ticket-number--empty">
                  { ticketCount }
                </div>
                枚

                <div className="question-form__ticket-empty">
                  今月の質問回数が上限に達しました。
                </div>

                {
                  !props.user.corporation &&
                  <Link to={`/study_logs/${curriculum}/${id}/buy_ticket`} className="question-form__ticket-button">
                    質問チケットを購入する
                  </Link>
                }

              </div>

              <div className="question-form__title">
                トピック
              </div>
              {
                record && 
                <div className="question-form__topic">
                  <div className="question-form__topic-date">
                    <Moment format="YYYY年M月D日 HH:mm">{ record.created_at }</Moment>
                    〜
                    <Moment format="YYYY年M月D日 HH:mm">{ record.updated_at }</Moment>
                  </div>
                  <div className="question-form__topic-title">
                    { record.content.topic.title } > { record.content.meta!.label}
                  </div>
                </div>
              }

              <div className="question-form__buttons question-form__buttons--no-border">
                <div className="question-form__button">
                  <Link to={`/study_logs/${curriculum}`} className="button button--large button--back button--gray">
                    学習履歴に戻る</Link>
                </div>
              </div>

            </div>


            <div className="back-home">
              <Link to='/' className="back-home__link">マイページへ戻る</Link>
            </div>
          </div>

          <ol className="breadcrumbs">
            <li className="breadcrumbs__item">
              <Link to='/' className="breadcrumbs__link">マイページ</Link>
            </li>
            <li className="breadcrumbs__item">
              <Link to={`/study_logs/${curriculum}`} className="breadcrumbs__link">学習履歴</Link>
            </li>
            <li className="breadcrumbs__item">
              先生への質問
            </li>
          </ol>
        </SiteMain>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <PrivateHeader />
      <SiteMain>
        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          <li className="breadcrumbs__item">
            <Link to={`/study_logs/${curriculum}`} className="breadcrumbs__link">学習履歴</Link>
          </li>
          <li className="breadcrumbs__item">
            先生への質問
          </li>
        </ol>

        <div className="site-main__main-contents">
          <div className="page-title">
            先生に質問
            <Link to={`/study_logs/${curriculum}`} className="page-title__link page-title__link--back">学習履歴に戻る</Link>
          </div>

          {
            !confirm &&

            <TicketNoticeMessageBox />
          }

          <div className="question-form">
            <form onSubmit={props.handleSubmit}>
              {
                confirm && record && ticketCount &&
                <React.Fragment>
                  <div className="question-form__ticket">
                    質問チケット
                    <div className="question-form__ticket-number">
                      { ticketCount }
                    </div>
                    枚
                  </div>

                  <div className="question-form__title">
                    トピック
                  </div>
                  <div className="question-form__topic">
                    <div className="question-form__topic-date">
                      <Moment format="YYYY年M月D日 HH:mm">{ record.created_at }</Moment>
                      〜
                      <Moment format="YYYY年M月D日 HH:mm">{ record.updated_at }</Moment>
                    </div>
                    <div className="question-form__topic-title">
                      { record.content.topic.title } > { record.content.meta!.label}
                    </div>
                  </div>
                  <div className="question-form__title">
                    質問内容
                  </div>
                  <div className="question-form__textarea question-form__textarea--confirm">
                    { nl2br(props.questionBody) }
                  </div>

                  {
                    props.questionAttachment && 
                    <React.Fragment>
                      <div className="question-form__title">
                        添付ファイル
                      </div>
                      <div className="question-form__attachment">
                        <QuestionAttachmentPreview attachment={props.questionAttachment} />
                      </div>
                    </React.Fragment>
                  }

                  <div className="question-form__confirmation">
                    上記内容で質問します。「質問する」ボタンを押すと、質問チケットを1枚消費します。
                  </div>
                  <div className="question-form__buttons">
                    <div className="question-form__button">
                      <button
                        disabled={props.submitting || props.invalid}
                        className="button button--large button--primary">質問を送信する</button>
                    </div>
                    <div className="question-form__button">
                      <button
                        onClick={(e) => {
                          e.preventDefault()
                          setConfirm(false)
                        }}
                        className="button button--large button--back button--gray">内容を修正する</button>
                    </div>
                  </div>
                </React.Fragment>
              }

              {
                !confirm && record && ticketCount &&
                <React.Fragment>
                  <div className="question-form__ticket">
                    質問チケット
                    <div className="question-form__ticket-number">
                      { ticketCount }
                    </div>
                    枚
                  </div>
                  <div className="question-form__title">
                    トピック
                  </div>
                  <div className="question-form__topic">
                    <div className="question-form__topic-date">
                      <Moment format="YYYY年M月D日 HH:mm">{ record.created_at }</Moment>
                      〜
                      <Moment format="YYYY年M月D日 HH:mm">{ record.updated_at }</Moment>
                    </div>
                    <div className="question-form__topic-title">
                      { record.content.topic.title } > { record.content.meta!.label}
                    </div>
                  </div>
                  <div className="question-form__title">
                    質問内容<span className="question-form__title-note">※テキスト入力は必須です。</span>
                  </div>
                  <div className="question-form__textarea">
                    <Field
                      component={renderTextareaField}
                      className="textarea textarea--required"
                      name="body"
                    />

                    <div className="question-form__note">
                      添付画像に質問内容を記入していただいても結構です。
                    </div>

                  </div>

                  {
                    props.invalid &&
                    <div className="question-form__error">
                      質問を入力してください。
                    </div>
                  }

                  <div className="question-form__title">
                    添付ファイル
                  </div>
                  <div className="question-form__input-file">
                    <div className="input-file">
                      <FormFileInput
                        className="input-file__input"
                        accept="image/png,image/jpeg"
                        limitSize={1024 * 1024 * 10}
                        onFailed={() => {
                          setAttachmentSizeOver(true)
                        }}
                        onLoad={(data) => {
                          setAttachmentSizeOver(false)
                          props.change('attachment', data)
                        }} />
                      <div className="input-file__note">
                        アップロード可能なファイル形式：jpg、png　ファイル容量上限：10MB
                      </div>
                      {
                        attachmentSizeOver &&
                        <div className="input-file__error">
                          <div className="error-message">
                            ファイル容量が上限を超えているためアップロードできません。
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                  <div className="question-form__title">
                    {
                      props.questionAttachment && 
                      <QuestionAttachmentPreview attachment={props.questionAttachment} />
                    }
                  </div>

                  <div className="question-form__buttons">
                    <div className="question-form__button">
                      <button
                        onClick={(e) => {
                          e.preventDefault()
                          setConfirm(true)
                        }}
                        disabled={props.submitting || props.invalid}
                        className="button button--large button--primary">質問内容の確認</button>
                    </div>
                    <div className="question-form__button">
                      <Link to={`/study_logs/${curriculum}`} className="button button--large button--back button--gray">
                        学習履歴に戻る</Link>
                    </div>
                  </div>
                </React.Fragment>
              }
            </form>
          </div>

          <div className="back-home">
            <Link to='/' className="back-home__link">マイページへ戻る</Link>
          </div>
        </div>

        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          <li className="breadcrumbs__item">
            <Link to={`/study_logs/${curriculum}`} className="breadcrumbs__link">学習履歴</Link>
          </li>
          <li className="breadcrumbs__item">
            先生への質問
          </li>
        </ol>
      </SiteMain>
    </React.Fragment>
  );
}

export default StudyLogQuestionForm
