import React from 'react';

type ownProps = {
  children?: React.ReactNode;
}

const SiteMainSimple: React.FC<ownProps> = (props: ownProps) => {
  return (
    <main className="site-main site-main--account">
      <div className="site-main__main site-main__main--simple">
        <div className="site-main__main-inner site-main__main-inner--simple">
          <div className="site-main__main-contents">
            { props.children }
          </div>
        </div>
      </div>
    </main>
  );
}

export default SiteMainSimple;
