import { StudentUser } from 'models/api';

const disabledCorporationNames = [
  '2020KEI　大学入学前教育',
  'Univas2020',
]

export const isAvailable = (user: StudentUser): boolean => {
  // MARK: チュートリアルページ利用可能か（一般受講生か、KEI法人以外）
  return !user.corporation || !disabledCorporationNames.includes(user.corporation.name)
}
