import Axios from 'axios';
import { AxiosInstance, AxiosResponse } from 'axios';
import * as Models from 'models/api'
import { curriculums } from 'utils/constants'
import storeUtils from 'utils/store'

const apiPathPrefixes = {
  v1: '/api/v1',
  v2: '/api/v2'
};
const apiPathPrefix = apiPathPrefixes.v1;

export const authHeaderKey = 'X-KAWAI-ONE-TOKEN';

const isAuthorizedApi = (api: AxiosInstance): boolean => {
  return !!api.defaults.headers[authHeaderKey];
}

export const normalizeStudentUser = (student: any) => {
  // MARK: サーバーに合わせて置換
  const visibleIds: number[] = student.visible_curriculums.map((x: any) => x.id)
  const attendedIds: number[] = student.attended_curriculums.map((x: any) => x.id)
  const y = {
    ...student,
    visible_curriculums: curriculums.filter(x => visibleIds.includes(x.id)),
    attended_curriculums: curriculums.filter(x => attendedIds.includes(x.id)),
    recommend_world_history: student.world_history_attended ? 1 : null,
    recommend_japanese_history: student.japanese_history_attended ? 1 : null,
    recommend_english_basic: student.english_basic_attended ? 1 : null,
    recommend_math_basic: student.math_basic_attended ? 1 : null,
    recommend_biology: student.biology_attended ? 1 : null,
    recommend_english_words: student.english_words_attended ? 1 : null,
    recommend_new_math: student.new_math_attended ? 1 : null,
    recommend_junior_english: student.junior_english_attended ? 1 : null,
    recommend_junior_math: student.junior_math_attended ? 1 : null,
    recommend_junior_science: student.junior_science_attended ? 1 : null,
    recommend_modern_japanese: student.modern_japanese_attended ? 1 : null,
    recommend_new_chemistry_2024: student.new_chemistry_2024_attended ? 1 : null,
    recommend_new_biology_2024: student.new_biology_2024_attended ? 1 : null,
    /* issue-673 */
  } as Models.StudentUser

  if (y.corporation) {
    y.visible_curriculums =  y.visible_curriculums.filter(x => {
      // MARK: https://github.com/gatjp/kawai1-front/issues/486
      // 法人受講生の場合、受講生が受講中の教科を閲覧可能教科として設定する
      // return (y as any)[`recommend_${x.code}`] || (y.corporation!.available_curriculums as any)[x.code]
      return (y as any)[`recommend_${x.code}`]
    })
  }

  return y
}

// 共通仕様
// https://docbase.io/posts/900521/sharing/deb62c78-bf46-4307-903c-70ee2a44317a

//
// 進学状況収集
// https://github.com/gatjp/kawai1-back/pull/606/files
//

// 受講生識別子の妥当性確認API
export type borderResultsVerificationResponse = AxiosResponse<{ remaining_time: number }>
export const borderResultsVerification = async (
  api: AxiosInstance,
  params: { token: string }) : Promise<borderResultsVerificationResponse> => {
  return await api.get(`${apiPathPrefixes.v1}/student/border_results/verification`, { params })
}

// (進学状況回答用)大学一覧取得API
export type borderResultsUniversitiesResponse = AxiosResponse<Models.BorderResultsUniversitiesRecord>
export const borderResultsUniversities = async (
  api: AxiosInstance,
  params: { category: string; area: string }) : Promise<borderResultsUniversitiesResponse> => {
  return await api.get(`${apiPathPrefixes.v1}/student/border_results/target_school/universities`, { params })
}

// (進学状況回答用)学部一覧取得API
export type borderResultsFacultiesResponse = AxiosResponse<Models.BorderResultsFacultiesRecord>
export const borderResultsFaculties = async (
  api: AxiosInstance,
  params: { area: string; university: string; }) : Promise<borderResultsFacultiesResponse> => {
  return await api.get(`${apiPathPrefixes.v1}/student/border_results/target_school/faculties`, { params })
}

// (進学状況回答用)学科一覧取得API
export type borderResultsDepartmentsResponse = AxiosResponse<Models.BorderResultsDepartmentsRecord>
export const borderResultsDepartments = async (
  api: AxiosInstance,
  params: { university: string; faculty: string; }) : Promise<borderResultsDepartmentsResponse> => {
  return await api.get(`${apiPathPrefixes.v1}/student/border_results/target_school/departments`, { params })
}

// 進学状況調査結果登録API
export type borderResultsRegisterResponse = AxiosResponse<void>
export const borderResultsRegister = async (
  api: AxiosInstance,
  params: Models.BorderResultsRegisterParams) : Promise<borderResultsFacultiesResponse> => {
  return await api.post(`${apiPathPrefixes.v1}/student/border_results/register`, params)
}

//
// 共通
//

// 申し込み
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/student/signup_create.md
export type signupResponse = AxiosResponse<void>
export const signup = async (
  api: AxiosInstance,
  params: Models.SignupParams) : Promise<signupResponse> => {
  
  const student = {
    ...params,
    // MARK: サーバーに合わせて置換
    world_history_attended: !!params.recommend_world_history,
    japanese_history_attended: !!params.recommend_japanese_history,
    biology_attended: !!params.recommend_biology,
    english_words_attended: !!params.recommend_english_words,
    new_math_attended: !!params.recommend_new_math,
    junior_english_attended: !!params.recommend_junior_english,
    junior_math_attended: !!params.recommend_junior_math,
    junior_science_attended: !!params.recommend_junior_science,
    modern_japanese_attended: !!params.recommend_modern_japanese,
    new_chemistry_2024_attended: !!params.recommend_new_chemistry_2024,
    new_biology_2024_attended: !!params.recommend_new_biology_2024,
  }

  return await api.post(`${apiPathPrefixes.v2}/student/signup`, { student })
}

// SMS認証番号送信
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/student/sms_notifications_create.md
export type createSmsNotificationResponse = AxiosResponse<void>
export const createSmsNotification = async (
  api: AxiosInstance,
  params: Models.CreateSmsNotificationParams) => {
  const url = `${apiPathPrefix}/student/sms_notifications`
  return await api.post(url, params)
}

// 受講生マスターデータ
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/student/master_data_index.md
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/v1/student/master_data_logged_in.md
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/v1/parent/master_data_logged_in.md
export type getMasterDataResponse = AxiosResponse<Models.MasterData>
export const getMasterData = async (
  api: AxiosInstance,
  params: { ui: Models.UIType }
) : Promise<getMasterDataResponse> => {
  let url = ''
  if (params.ui === 'signup') {
    url = `${apiPathPrefix}/student/master_data`
  } else if (params.ui === 'student') {
    url = `${apiPathPrefix}/student/master_data/logged_in`
  } else if (params.ui === 'parent') {
    url = `${apiPathPrefix}/parent/master_data/logged_in`
  } else {
    throw new Error('invalid ui');
  }

  return await api.get<Models.MasterData>(url, {
    transformResponse: [(data) => {
      const body = JSON.parse(data)
      return {
        ...body,
        // MARK: https://github.com/gatjp/kawai1-front/issues/486
        // english_levelsに受講しないの選択肢がなかったので追加
        english_levels: [
          { level: null, course_names: "受講しない" },
          { level: 1, course_names: "英文法" },
          { level: 2, course_names: "英文法・英文解釈" },
        ],
        world_history_levels: [
          {
            level: null,
            course_names: '受講しない',
          },
          {
            level: 1,
            course_names: '受講する',
          },
        ],
        japanese_history_levels: [
          {
            level: null,
            course_names: '受講しない',
          },
          {
            level: 1,
            course_names: '受講する',
          },
        ],
        english_basic_levels: [
          {
            level: null,
            course_names: '受講しない',
          },
          {
            level: 1,
            course_names: '受講する',
          },
        ],
        math_basic_levels: [
          {
            level: null,
            course_names: '受講しない',
          },
          {
            level: 1,
            course_names: '受講する',
          },
        ],
        english_words_levels: [
          {
            level: null,
            course_names: '受講しない',
          },
          {
            level: 1,
            course_names: '受講する',
          },
        ],
        biology_levels: [
          {
            level: null,
            course_names: '受講しない',
          },
          {
            level: 1,
            course_names: '受講する',
          },
        ],
        new_math_levels: [
          {
            level: null,
            course_names: '受講しない',
          },
          {
            level: 1,
            course_names: '受講する',
          },
        ],
        junior_english_levels: [
          {
            level: null,
            course_names: '受講しない',
          },
          {
            level: 1,
            course_names: '受講する',
          },
        ],
        junior_math_levels: [
          {
            level: null,
            course_names: '受講しない',
          },
          {
            level: 1,
            course_names: '受講する',
          },
        ],
        junior_science_levels: [
          {
            level: null,
            course_names: '受講しない',
          },
          {
            level: 1,
            course_names: '受講する',
          },
        ],
        modern_japanese_levels: [
          {
            level: null,
            course_names: '受講しない',
          },
          {
            level: 1,
            course_names: '受講する',
          },
        ],
        new_chemistry_2024_levels: [
          {
            level: null,
            course_names: '受講しない',
          },
          {
            level: 1,
            course_names: '受講する',
          },
        ],
        new_biology_2024_levels: [
          {
            level: null,
            course_names: '受講しない',
          },
          {
            level: 1,
            course_names: '受講する',
          },
        ],
      }
    }]
  })
}

// ログイン
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/student/sessions_create.md
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/trainer/sessions_create.md
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/instructor/sessions_create.md
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/parent/sessions_create.md
export type createSessionResponse = AxiosResponse<Models.Session>
export const createSession = async (
  api: AxiosInstance,
  params: Models.CreateSessionParams) : Promise<createSessionResponse> => {
  const url = `${apiPathPrefix}/${params.ui}/sessions`
  return await api.post(url, { login: params.login, password: params.password })
}

// パスワードを忘れた
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/student/password_notifications_create.md
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/trainer/password_notifications_create.md
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/instructor/password_notifications_create.md
export type createPasswordNotificationResponse = AxiosResponse<void>
export const createPasswordNotification = async (
  api: AxiosInstance,
  params: Models.CreatePasswordNotificationParams) => {
  const url = `${apiPathPrefix}/${params.ui}/password_notifications`
  // MARK: パスワード再設定画面のURL Path
  const path = '/#/password_reset';
  if ((params.ui === 'student' || params.ui === 'parent') && !params.login) {
    throw new Error('require params.login');
  }
  return await api.post(url, { email: params.email, path, login: params.login })
}

// パスワード更新（パスワードを忘れた方経由）
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/student/password_resetters_create.md
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/trainer/password_resetters_create.md
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/instructor/password_resetters_create.md
export type createPasswordResettersResponse = AxiosResponse<void>
export const createPasswordResetter = async (
  api: AxiosInstance,
  params: Models.CreatePasswordResetterParams) => {
  const url = `${apiPathPrefix}/${params.ui}/password_resetters`
  return await api.post(url, params)
}

// ログイン中の受講生情報取得
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/student/current.md
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/trainer/current.md
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/instructor/current.md
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/parent/current.md
export type getCurrentUserResponse = AxiosResponse<Models.CurrentUser>
export const getCurrentUser = async (
  api: AxiosInstance,
  params: Models.GetCurrentUserParams) : Promise<getCurrentUserResponse> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  let url = `${apiPathPrefixes.v2}/${params.ui}/current`
  if (params.ui === 'instructor' || params.ui === 'trainer') {
    // MARK: trainer/instructorはv1
    url = `${apiPathPrefixes.v1}/${params.ui}/current`
  }
  return await api.get<Models.CurrentUser>(url, {
    transformResponse: [(data) => {
      const body = JSON.parse(data)
      if (!body.student) {
        return body
      }
      return {
        student: normalizeStudentUser(body.student),
      }
    }]
  })
}

// お知らせ一覧取得
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/student/admin_informations_index.md
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/trainer/admin_informations_index.md
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/parent/admin_informations_index.md
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/instructor/admin_informations_index.md
export type getInfoRecordsResponse = AxiosResponse<Models.InfoRecords>
export const getInfoRecords = async (
  api: AxiosInstance,
  params: Models.GetRecordsParams & { ui: Models.LoginableUIType }) : Promise<getInfoRecordsResponse> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  const url = `${apiPathPrefix}/${params.ui}/admin_informations`
  return await api.get<Models.InfoRecords>(url, {
    params,
    transformResponse: [(data) => {
      const body = JSON.parse(data)
      return {
        total_count: body.total_count,
        total_page: body.total_page,
        records: body.admin_informations,
      }
    }]
  })
}

// お知らせ詳細取得
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/student/admin_informations_show.md
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/trainer/admin_informations_show.md
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/instructor/admin_informations_show.md
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/parent/admin_informations_show.md
export type getInfoResponse = AxiosResponse<Models.Info>
export const getInfo = async (
  api: AxiosInstance,
  params: { id: number; ui: Models.LoginableUIType }) : Promise<getInfoResponse> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  const url = `${apiPathPrefix}/${params.ui}/admin_informations/${params.id}`
  return await api.get<Models.Info>(url, {
    params,
    transformResponse: [(data) => {
      const body = JSON.parse(data)
      return body.admin_information
    }]
  })
}

// パスワード更新（初回ログイン・設定変更）
// TODO: 講師、保護者
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/student/password_update.md
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/trainer/password_update.md
export type changePasswordResponse = AxiosResponse<void>
export const changePassword = async (
  api: AxiosInstance,
  params: Models.ChangePasswordParams) => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  let url = ''
  if (process.env.REACT_APP_API_URL === 'http://localhost:3002') {
    url = `${apiPathPrefix}/student/signup`
  } else {
    url = `${apiPathPrefix}/${params.ui}/password`
  }
  return await api.put(url, params)
}

// トレーナー/保護者/講師 受講生情報取得
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/trainer/students_show.md
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/instructor/students_show.md
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/parent/current.md
export type getOwnStudentResponse = AxiosResponse<Models.StudentUser>
export const getOwnStudent = async (
  api: AxiosInstance,
  params: { ui: Models.LoginableUIType; student_id: number; }) : Promise<getOwnStudentResponse> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  let url = ''
  if (process.env.REACT_APP_API_URL === 'http://localhost:3002') {
    url = `${apiPathPrefix}/trainer_student`
  } else {
    if (params.ui === 'parent') {
      url = `${apiPathPrefixes.v2}/parent/current`
    } else {
      url = `${apiPathPrefix}/${params.ui}/students/${params.student_id}`
    }
  }
  return await api.get<Models.StudentUser>(url, {
    transformResponse: [(data) => {
      const body = JSON.parse(data)
      return normalizeStudentUser(body.student)
    }]
  })
}

// トレーナー/講師 受講生教科一覧 
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/trainer/curriculums_index.md
export type getOwnStudentStudyCurriculumsResponse = AxiosResponse<Models.OwnStudentStudyCurriculums>
export const getOwnStudentStudyCurriculums = async (
  api: AxiosInstance,
  params: { ui: Models.LoginableUIType; student_id?: number; }) : Promise<getOwnStudentStudyCurriculumsResponse> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  let url = ''
  if (process.env.REACT_APP_API_URL === 'http://localhost:3002') {
    url = `${apiPathPrefix}/student_study_curriculums`
  } else {
    url = `${apiPathPrefix}/${params.ui}/curriculums`
  }
  return await api.get<Models.OwnStudentStudyCurriculums>(url, { params })
}

// トレーナー/保護者/講師 受講生教科内科目一覧
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/trainer/courses.md
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/parent/courses.md
export type getOwnStudentStudyCurriculumResponse = AxiosResponse<Models.OwnStudentStudyCurriculum>
export const getOwnStudentStudyCurriculum = async (
  api: AxiosInstance,
  params: { ui: Models.LoginableUIType; curriculum_id: number; student_id: number; }) : Promise<getOwnStudentStudyCurriculumResponse> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  let url = ''
  if (process.env.REACT_APP_API_URL === 'http://localhost:3002') {
    url = `${apiPathPrefix}/student_study_curriculum`
  } else {
    url = `${apiPathPrefix}/${params.ui}/courses`
  }
  return await api.get<Models.OwnStudentStudyCurriculum>(url, { params })
}

// トレーナー/保護者/講師 受講生科目内単元一覧
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/trainer/units_index.md
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/parent/units_index.md
export type getOwnStudentStudyCourseResponse = AxiosResponse<Models.OwnStudentStudyCourse>
export const getOwnStudentStudyCourse = async (
  api: AxiosInstance,
  params: { ui: Models.LoginableUIType; course_id: number; student_id?: number; }) : Promise<getOwnStudentStudyCourseResponse> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  let url = ''
  if (process.env.REACT_APP_API_URL === 'http://localhost:3002') {
    url = `${apiPathPrefix}/student_study_course`
  } else {
    url = `${apiPathPrefix}/${params.ui}/units`
  }
  return await api.get<Models.OwnStudentStudyCourse>(url, { params })
}

// トレーナー/保護者/講師 受講生科目内トピック一覧
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/trainer/topics_index.md
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/parent/topics_index.md
export type getOwnStudentStudyUnitResponse = AxiosResponse<Models.OwnStudentStudyUnit>
export const getOwnStudentStudyUnit = async (
  api: AxiosInstance,
  params: { ui: Models.LoginableUIType; unit_id: number; student_id?: number; }) : Promise<getOwnStudentStudyUnitResponse> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  let url = ''
  if (process.env.REACT_APP_API_URL === 'http://localhost:3002') {
    url = `${apiPathPrefix}/student_study_unit`
  } else {
    url = `${apiPathPrefix}/${params.ui}/topics`
  }
  return await api.get<Models.OwnStudentStudyUnit>(url, { params })
}

// トレーナー/保護者/講師 受講生トピック詳細（コンテンツ一覧）
export type getOwnStudentStudyTopicResponse = AxiosResponse<Models.OwnStudentStudyTopic>
export const getOwnStudentStudyTopic = async (
  api: AxiosInstance,
  params: { ui: Models.LoginableUIType; topic_id: number; student_id?: number; }) : Promise<getOwnStudentStudyTopicResponse> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  let url = ''
  if (process.env.REACT_APP_API_URL === 'http://localhost:3002') {
    url = `${apiPathPrefix}/student_study_topic`
  } else {
    url = `${apiPathPrefix}/${params.ui}/contents`
  }
  return await api.get<Models.OwnStudentStudyTopic>(url, { params })
}

// トレーナー/保護者/講師 受講生の学習結果比較
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/trainer/study_result_index.md
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/parent/study_result_index.md
export type getOwnStudentStudyUnitResultResponse = AxiosResponse<Models.OwnStudentStudyUnitResult>
export const getOwnStudentStudyUnitResult = async (
  api: AxiosInstance,
  params: { ui: Models.LoginableUIType; unit_id: number; student_id: number; }) : Promise<getStudentStudyUnitResultResponse> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  let url = ''
  if (process.env.REACT_APP_API_URL === 'http://localhost:3002') {
    url = `${apiPathPrefix}/student_study_result`
  } else {
    url = `${apiPathPrefix}/${params.ui}/study_result`
  }
  return await api.get<Models.OwnStudentStudyUnitResult>(url, { params })
}

// トレーナー/保護者/講師 受講生 学習履歴
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/trainer/study_logs_show.md
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/parent/study_logs_index.md
export type getOwnStudentStudyLogRecordsResponse = AxiosResponse<Models.StudyLogRecords>
export const getOwnStudentStudyLogRecords = async (
  api: AxiosInstance,
  params: Models.GetRecordsParams & { ui: Models.LoginableUIType; student_id: number; curriculum_id: number; }) : Promise<getOwnStudentStudyLogRecordsResponse> => {

  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  let url = ''
  if (process.env.REACT_APP_API_URL === 'http://localhost:3002') {
    url = `${apiPathPrefix}/student/study_logs`
  } else {
    if (params.ui === 'parent') {
      url = `${apiPathPrefix}/${params.ui}/study_logs`
    } else {
      url = `${apiPathPrefix}/${params.ui}/study_logs/${params.student_id}`
    }
  }
  return await api.get<Models.StudyLogRecords>(url, {
    params,
    transformResponse: [(data) => {
      const body = JSON.parse(data)
      return {
        curriculum: body.curriculum,
        records: body.study_logs,
        total_count: body.total_count,
        total_page: body.total_page,
      }
    }]
  })
}

// トレーナー/保護者/講師 受講生の科目別修了テスト取得API
export const getOwnStudentStudyCourseCompletionTests = async (
  api: AxiosInstance, { ui, ...params }: { course_id: number; ui: Models.LoginableUIType; student_id: number; }) => {

  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }

  const url = `${apiPathPrefix}/${ui}/course_completion_tests`
  return await api.get<Models.StudyCourseCompletionTestsRecord>(url, { params })
}

// トレーナー/保護者/講師 受講生の科目別修了テスト結果取得API
export const getOwnStudentStudyCourseCompletionTestResults = async (
  api: AxiosInstance, { unit_id, ui, ...params }: { unit_id: number; student_id: number; ui: Models.LoginableUIType; page?: number; per_page?: number; }) => {

  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }

  const url = `${apiPathPrefix}/${ui}/course_completion_tests/${unit_id}`
  return await api.get<Models.StudyCourseCompletionTestResultsRecord>(url, { params })
}

//
// 受講生関連
//

// 受講生加入プラン詳細情報取得API
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/v2/student/current_plan.md
export type getStudentCurrentPlanResponse = AxiosResponse<Models.StudentPlan>
export const getStudentCurrentPlan = async (
  api: AxiosInstance) : Promise<getStudentCurrentPlanResponse> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  const url = `${apiPathPrefixes.v2}/student/current_plan`
  return await api.get<Models.StudentPlan>(url, {
    transformResponse: [(data) => {
      const body = JSON.parse(data)
      return body.student.plan
    }]
  })
}

// 受講生向けポイント交換プログラム申請API
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/v2/student/point_exchange_create.md
export type createStudentPointExchangeResponse = AxiosResponse<Models.StudentPointExchangeRecord>
export const createStudentPointExchange = async (
  api: AxiosInstance,
  params: Models.StudentPointExchangeParams): Promise<createStudentPointExchangeResponse> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  const url = `${apiPathPrefixes.v2}/student/point_exchange`
  return await api.post(url, { point_exchange: params })
}

// 受講生のポイント交換履歴一覧
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/v2/student/point_exchange_logs_index.md
export type getStudentPointExchangeLogsResponse = AxiosResponse<Models.PointExchangeLogsRecords>
export const getStudentPointExchangeLogs = async (
  api: AxiosInstance,
  params: Models.GetRecordsParams
  ) : Promise<getStudentPointExchangeLogsResponse> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  const url = `${apiPathPrefixes.v2}/student/point_exchange_logs`
  return await api.get<Models.PointExchangeLogsRecords>(url, {
    params,
    transformResponse: [(data) => {
      const body = JSON.parse(data)
      return {
        total_count: body.total_count,
        total_page: body.total_page,
        records: body.point_exchange_logs
      }
    }]
  })
}

// 受講生プロフィール更新API
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/student/students_update.md
export type updateStudentProfileResponse = AxiosResponse<void>
export const updateStudentProfile = async (
  api: AxiosInstance,
  params: Models.UpdateStudentProfileParams) => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  const url = `${apiPathPrefix}/student/students`
  const student = {
    ...params,
    // MARK: サーバーに合わせて置換
    world_history_attended: !!params.recommend_world_history,
    japanese_history_attended: !!params.recommend_japanese_history,
    math_basic_attended: !!params.recommend_math_basic,
    english_basic_attended: !!params.recommend_english_basic,
    biology_attended: !!params.recommend_biology,
    english_words_attended: !!params.recommend_english_words,
    new_math_attended: !!params.recommend_new_math,
    junior_math_attended: !!params.recommend_junior_math,
    junior_english_attended: !!params.recommend_junior_english,
    junior_science_attended: !!params.recommend_junior_science,
    modern_japanese_attended: !!params.recommend_modern_japanese,
    new_chemistry_2024_attended: !!params.recommend_new_chemistry_2024,
    new_biology_2024_attended: !!params.recommend_new_biology_2024,
  }
  return await api.put(url, { student })
}

// 受講生としてトレーナーを契約する API です
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/student/contract_trainer_create.md
export const requestTrainer = async (api: AxiosInstance,
  params: Models.RequestTrainerParams) => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  const url = `${apiPathPrefix}/student/contract_trainer`
  return await api.post(url, { student: params, })
}

// 受講生としてトレーナーを解約する API です
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/student/cancel_trainer.md
export const cancelTrainer = async (api: AxiosInstance) => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  const url = `${apiPathPrefix}/student/cancel_trainer`
  return await api.put(url, {})
}

// 受講生退会PI
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/student/unsubscribe.md
export type withdrawResponse = AxiosResponse<void>
export const withdraw = async (api: AxiosInstance, params: Models.StudentUnsubscribeParams) => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  const url = `${apiPathPrefix}/student/unsubscribe`
  return await api.put(url, params)
}

// 受講生 スケジュール一覧取得
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/student/schedules_index.md
export type getScheduleRecordsResponse = AxiosResponse<Models.ScheduleRecords>
export const getScheduleRecords = async (
  api: AxiosInstance,
  params: Models.GetRecordsParams) : Promise<getScheduleRecordsResponse> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  const url = `${apiPathPrefix}/student/schedules`
  return await api.get<Models.ScheduleRecords>(url, {
    params,
    transformResponse: [(data) => {
      const body = JSON.parse(data)
      return {
        total_count: body.total_count,
        total_page: body.total_page,
        records: body.schedules.map((x: any) => {
          return {
            id: x.id,
            body: x.body,
            date: x.acted_on,
          }
        }),
      }
    }]
  })
}

// 受講生 スケジュール更新
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/student/schedules_update.md 
export type updateScheduleResponse = AxiosResponse<void>
export const updateSchedule = async (api: AxiosInstance, params: Models.UpdateScheduleParams) => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  let url = ''
  if (process.env.REACT_APP_API_URL === 'http://localhost:3002') {
    url = `${apiPathPrefix}/student/schedules2`
  } else {
    url = `${apiPathPrefix}/student/schedules/${params.id}`
  }
  return await api.put(url, params)
}

// 受講生AIおすすめ学習 
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/student/recommend.md 
// MARK: 未使用、後で消す
export type getRecommendStudyRecordsResponse = AxiosResponse<Models.RecommendStudyRecords>
export const getRecommendStudyRecords = async (
  api: AxiosInstance,
  params: Models.GetRecordsParams & { curriculum_id: number; }) : Promise<getRecommendStudyRecordsResponse> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  const hogeParams = {
    limit: params.per_page,
    curriculum_id: params.curriculum_id
  }
  const url = `${apiPathPrefix}/student/recommend`
  return await api.get<Models.RecommendStudyRecords>(url,{
    params: hogeParams,
    transformResponse: [(data) => {
      const hoge = JSON.parse(data)
      return {
        total_count: hoge.contents.length,
        total_page: 1,
        records: hoge.contents.map((x: any) => {
          return {
            ...x,
            curriculum: hoge.curriculum,
          }
        }),
      }
    }]
  })
}

// 受講生AIおすすめ学習v2
// https://github.com/gatjp/kawai1-back/blob/a3d6763f811ef5321396fb39abaf37914dcd39b6/docs/api/v2/student/recommends.md
export type getRecommendStudyRecordsResponse2 = AxiosResponse<Models.RecommendStudyRecords2>
export const getRecommendStudyRecords2 = async (
  api: AxiosInstance,
  params: { curriculum_id: number; limit: number; }) : Promise<getRecommendStudyRecordsResponse2> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  const url = `${apiPathPrefixes.v2}/student/recommends`
  return await api.get<Models.RecommendStudyRecords2>(url,{ params })
}

// issue-545
// 受講生おまかせ学習 API
// https://github.com/gatjp/kawai1-back/blob/ac1e784b759bc8b02a000db548dcbaddd85f33a5/docs/api/v1/student/entrust_current.md
export type getEntrustCurrentRecordsResponse = AxiosResponse<Models.EntrustCurrentRecords>
export const getEntrustCurrent = async (
  api: AxiosInstance,
  params: { curriculum_id?: number }) : Promise<getEntrustCurrentRecordsResponse> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  const url = `${apiPathPrefixes.v1}/student/entrust/current`
  return await api.get<Models.EntrustCurrentRecords>(url,{ params })
}

// issue-545
// 受講生おまかせ学習基点変更 API
// https://github.com/gatjp/kawai1-back/blob/ac1e784b759bc8b02a000db548dcbaddd85f33a5/docs/api/v1/student/entrust_base_topic_update.md
export type updateEntrustBaseTopicResponse = AxiosResponse<Models.EntrustBaseTopicRecord>
export const updateEntrustBaseTopic = async (
  api: AxiosInstance,
  params: { topic_id: number }) : Promise<updateEntrustBaseTopicResponse> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  const url = `${apiPathPrefixes.v1}/student/entrust/base_topic`
  return await api.post<Models.EntrustBaseTopicRecord>(url, params)
}

// 受講生プレテストおすすめ学習スキップ API
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/v1/student/recommend_skip_pretest.md
export const skipRecommendStudyPretest = async (
  api: AxiosInstance,
  params: { content_id: number; rank: number; }) : Promise<void> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  const url = `${apiPathPrefixes.v1}/student/recommend/skip/pretest`
  return await api.post(url, params)
}

// 受講生マイページ
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/student/mypage_index.md
export type getStudentMypageResponse = AxiosResponse<Models.StudentMypage>
export const getStudentMypage = async (
  api: AxiosInstance) : Promise<getStudentMypageResponse> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  let url = ''
  if (process.env.REACT_APP_API_URL === 'http://localhost:3002') {
    url = `${apiPathPrefix}/student_mypage`
  } else {
    url = `${apiPathPrefix}/student/mypage`
  }
  return await api.get<Models.StudentMypage>(url)
}

// 受講生教科内科目一覧
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/student/courses.md
export type getStudentStudyCurriculumResponse = AxiosResponse<Models.StudentStudyCurriculum>
export const getStudentStudyCurriculum = async (
  api: AxiosInstance,
  params: { curriculum_id: number }) : Promise<getStudentStudyCurriculumResponse> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  let url = ''
  if (process.env.REACT_APP_API_URL === 'http://localhost:3002') {
    url = `${apiPathPrefix}/student_study_curriculum`
  } else {
    url = `${apiPathPrefix}/student/courses`
  }
  return await api.get<Models.StudentStudyCurriculum>(url, { params })
}

// 受講生科目内単元一覧
//https://github.com/gatjp/kawai1-back/blob/fb04bb659a13767bc9b6557a5b2e56bb29ae4080/docs/api/v1/student/units_index.md
export type getStudentStudyCourseResponse = AxiosResponse<Models.StudentStudyCourse>
export const getStudentStudyCourse = async (
  api: AxiosInstance,
  params: { course_id: number }) : Promise<getStudentStudyCourseResponse> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  let url = ''
  if (process.env.REACT_APP_API_URL === 'http://localhost:3002') {
    url = `${apiPathPrefix}/student_study_course`
  } else {
    url = `${apiPathPrefix}/student/units`
  }
  return await api.get<Models.StudentStudyCourse>(url, { params })
}

// 受講生科目内トピック一覧
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/student/topics_index.md 
export type getStudentStudyUnitResponse = AxiosResponse<Models.StudentStudyUnit>
export const getStudentStudyUnit = async (
  api: AxiosInstance,
  params: { unit_id: number }) : Promise<getStudentStudyUnitResponse> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  let url = ''
  if (process.env.REACT_APP_API_URL === 'http://localhost:3002') {
    url = `${apiPathPrefix}/student_study_unit`
  } else {
    url = `${apiPathPrefix}/student/topics`
  }
  return await api.get<Models.StudentStudyUnit>(url, { params })
}

// 受講生の学習結果比較
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/student/study_result_index.md
export type getStudentStudyUnitResultResponse = AxiosResponse<Models.StudentStudyUnitResult>
export const getStudentStudyUnitResult = async (
  api: AxiosInstance,
  params: { unit_id: number }) : Promise<getStudentStudyUnitResultResponse> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  let url = ''
  if (process.env.REACT_APP_API_URL === 'http://localhost:3002') {
    url = `${apiPathPrefix}/student_study_result`
  } else {
    url = `${apiPathPrefix}/student/study_result`
  }
  return await api.get<Models.StudentStudyUnitResult>(url, { params })
}

// 受講生トピック詳細（コンテンツ一覧）
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/student/contents_index.md
export type getStudentStudyTopicResponse = AxiosResponse<Models.StudentStudyTopic>
export const getStudentStudyTopic = async (
  api: AxiosInstance,
  params: { topic_id: number }) : Promise<getStudentStudyTopicResponse> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  let url = ''
  if (process.env.REACT_APP_API_URL === 'http://localhost:3002') {
    url = `${apiPathPrefix}/student_study_topic`
  } else {
    url = `${apiPathPrefix}/student/contents`
  }
  return await api.get<Models.StudentStudyTopic>(url, { params })
}

// 受講生 受講生コンテンツ起動 API
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/student/study_logs_create.md 
export type createStudyLogResponse = AxiosResponse<{ launch_url: string; }>
export const createStudyLog = async (
  api: AxiosInstance,
  params: {
    content_id: number
    return_url: string
    rank?: number
    learned?: boolean
    m_entrust?: string
  }) : Promise<createStudyLogResponse> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  let url = ''
  if (process.env.REACT_APP_API_URL === 'http://localhost:3002') {
    url = `${apiPathPrefix}/student/study_log_create`
  } else {
    url = `${apiPathPrefix}/student/study_logs`
  }
  return await api.post<{ launch_url: string; }>(url, params)
}

// 受講生 クレジットカード登録
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/student/creditcard_create.md
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/student/payment_create.md
export type createCreaditcardTokenResponse = AxiosResponse<{ token: string; }>
export const createCreditcardToken = async (
  api: AxiosInstance,
  params: {
    status: 'temporary'|'trial'|'regular'|'suspension'; }) : Promise<createCreaditcardTokenResponse> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  let url = ''
  if (process.env.REACT_APP_API_URL === 'http://localhost:3002') {
    url = `${apiPathPrefix}/student/creditcard`
  } else {
    if(params.status === 'suspension') {
      url = `${apiPathPrefix}/student/payment`
    } else {
      url = `${apiPathPrefix}/student/creditcard`
    }
  }
  return await api.post<{ token: string; }>(url, {})
}

// 受講生 相談内容取得
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/student/talks_index.md
export type getStudentMessageRecordsResponse = AxiosResponse<Models.StudentMessageRecords>
export const getStudentMessageRecords = async (
  api: AxiosInstance,
  params: Models.GetRecordsParams) : Promise<getStudentMessageRecordsResponse> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  let url = ''
  if (process.env.REACT_APP_API_URL === 'http://localhost:3002') {
    url = `${apiPathPrefix}/student/messages`
  } else {
    url = `${apiPathPrefix}/student/talks`
  }
  return await api.get<Models.StudentMessageRecords>(url, {
    params,
    transformResponse: [(data) => {
      const body = JSON.parse(data)
      return {
        total_page: body.total_page,
        total_count: body.total_count,
        records: body.talks,
      }
    }]
  })
}

// 受講生 相談登録
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/student/talks_create.md
export const createStudentMessage = async (
  api: AxiosInstance,
  params: Models.CreateStudentMessageParams) : Promise<void> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  // TODO: fix url
  let url = ''
  if (process.env.REACT_APP_API_URL === 'http://localhost:3002') {
    url = `${apiPathPrefix}/student/signup`
  } else {
    url = `${apiPathPrefix}/student/talks`
  }
  return await api.post(url, params)
}

// 受講生のコンテンツ受講履歴一覧
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/student/study_logs_index.md 
export type getStudyLogRecordsResponse = AxiosResponse<Models.StudyLogRecords>
export const getStudyLogRecords = async (
  api: AxiosInstance,
  params: Models.GetRecordsParams & { curriculum_id: number }) : Promise<getStudyLogRecordsResponse> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  const url = `${apiPathPrefix}/student/study_logs`
  return await api.get<Models.StudyLogRecords>(url, {
    params,
    transformResponse: [(data) => {
      const body = JSON.parse(data)
      return {
        curriculum: body.curriculum,
        records: body.study_logs,
        total_count: body.total_count,
        total_page: body.total_page,
      }
    }]
  })
}

// 受講生の受講履歴詳細
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/v1/student/study_logs_show.md
export type getStudentStudyLogResponse = AxiosResponse<Models.StudyLog>
export const getStudentStudyLog = async (
  api: AxiosInstance,
  id: number) : Promise<getStudentStudyLogResponse> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  let url = '';
  if (process.env.REACT_APP_API_URL === 'http://localhost:3002') {
    url = `${apiPathPrefix}/student_study_log_detail`
  } else {
    url = `${apiPathPrefix}/student/study_logs/${id}`
  }
  return await api.get<Models.StudyLog>(url, {
    transformResponse: [(data) => {
      const body = JSON.parse(data)
      return body
    }]
  })
}

// 受講生 質問履歴一覧の取得
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/student/questions_index.md
export type getStudentQuestionRecordsResponse = AxiosResponse<Models.QuestionRecords>
export const getStudentQuestionRecords = async (
  api: AxiosInstance,
  params: Models.GetRecordsParams) : Promise<getStudentQuestionRecordsResponse> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }

  let url = '';
  if (process.env.REACT_APP_API_URL === 'http://localhost:3002') {
    url = `${apiPathPrefix}/student_questions`
  } else {
    url = `${apiPathPrefix}/student/questions`
  }
  return await api.get<Models.QuestionRecords>(url, {
    params,
    transformResponse: [(data) => {
      const body = JSON.parse(data)
      return {
        total_page: body.total_page,
        total_count: body.total_count,
        records: body.questions,
      }
    }]
  })
}

// 受講生 質問詳細の取得
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/student/questions_show.md
export type getStudentQuestionResponse = AxiosResponse<Models.QuestionDetail>
export const getStudentQuestion = async (
  api: AxiosInstance,
  id: number) : Promise<getStudentQuestionResponse> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  let url = '';
  if (process.env.REACT_APP_API_URL === 'http://localhost:3002') {
    url = `${apiPathPrefix}/student_question_detail`
  } else {
    url = `${apiPathPrefix}/student/questions/${id}`
  }

  return await api.get<Models.QuestionDetail>(url, {
    transformResponse: [(data) => {
      const body = JSON.parse(data)
      return body.question
    }]
  })
}

// 受講生 講師質問
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/student/questions_create.md
export const createQuestion = async (
  api: AxiosInstance,
  params: Models.CreateQuestionParams) : Promise<void> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  let url = ''
  if (process.env.REACT_APP_API_URL === 'http://localhost:3002') {
    url = `${apiPathPrefix}/student/signup`
  } else {
    url = `${apiPathPrefix}/student/questions`
  }
  return await api.post(url, params)
}  

// 受講生 質問回数の取得
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/student/questions_count_in_month.md
// MARK: 未使用
export const getStudentQuestionsCountInMonth = async (
  api: AxiosInstance) : Promise<AxiosResponse<number>> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  let url = ''
  if (process.env.REACT_APP_API_URL === 'http://localhost:3002') {
    url = `${apiPathPrefix}/student_question_count_in_month`
  } else {
    url = `${apiPathPrefix}/student/questions/count_in_month`
  }
  return await api.get<number>(url, {
    transformResponse: [(data) => {
      const body = JSON.parse(data)
      return body.count
    }]
  })
}

// 受講生 質問残回数の取得
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/v1/student/questions_sheets_count_in_month.md
export const getStudentQuestionsSheetsCountInMonth = async (
  api: AxiosInstance) : Promise<AxiosResponse<number>> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }

  const url = `${apiPathPrefix}/student/questions/sheets_count_in_month`
  return await api.get<number>(url, {
    transformResponse: [(data) => {
      const body = JSON.parse(data)
      return body.sheets_count
    }]
  })
}

// 受講生 質問チケットを購入する
// https://github.com/gatjp/kawai1-back/blob/2537f5c0145ea18c0cec6b991d878546a03f5fd0/docs/api/v1/student/question_tickets_create.md
export const buyQuestionTicket = async (api: AxiosInstance) : Promise<AxiosResponse> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  const url = `${apiPathPrefix}/student/question_tickets`
  return await api.post(url)
}

// 週間学習実績取得API
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/v1/student/study_achievements_weekly.md
export const getStudentStudyAchivementWeekly = async (
  api: AxiosInstance, params: { date?: string; }) : Promise<AxiosResponse<Models.StudyAchivementRecord>> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }

  const url = `${apiPathPrefix}/student/study_achievements/weekly`
  return await api.get<Models.StudyAchivementRecord>(url, {
    params,
    transformResponse: [(data) => {
      const body = JSON.parse(data)
      return body.study_achievements
    }]
  })
}

// 一日平均目標学習時間設定API
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/v1/student/study_goal_time_update.md
export const updateStudyAchivementGoalTime = async (
  api: AxiosInstance, params: { daily_goal_time: number; }) : Promise<AxiosResponse> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }

  const url = `${apiPathPrefix}/student/study_goal_time`
  return await api.put(url, params)
}

// 受講生の科目別テスト結果最新一覧取得API
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/v1/student/study_course_results_index.md
export const getStudentStudyCourseResults = async (
  api: AxiosInstance, params: { course_id: number; }) : Promise<AxiosResponse<Models.StudyCourseResultsRecord>> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }

  const url = `${apiPathPrefix}/student/study_course_results`
  return await api.get<Models.StudyCourseResultsRecord>(url, { params })
}

// 受講生の単元別テスト結果一覧取得API
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/v1/student/study_unit_results_index.md
export const getStudentStudyUnitResults = async (
  api: AxiosInstance, params: { unit_id: number; page?: number; per_page?: number; }) : Promise<AxiosResponse<Models.StudyUnitResultsRecord>> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }

  const url = `${apiPathPrefix}/student/study_unit_results`
  return await api.get<Models.StudyUnitResultsRecord>(url, { params })
}

// 受講生の科目別修了テスト取得API
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/v1/student/course_completion_tests_index.md
export const getStudentStudyCourseCompletionTests = async (
  api: AxiosInstance,
  params: { course_id: number;  }) => {

  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }

  const url = `${apiPathPrefix}/student/course_completion_tests`
  return await api.get<Models.StudyCourseCompletionTestsRecord>(url, { params })
}

// 受講生の科目別修了テスト結果取得API
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/v1/student/course_completion_tests_show.md
export const getStudentStudyCourseCompletionTestResults = async (
  api: AxiosInstance,
  { unit_id, ...params }: { unit_id: number; page?: number; per_page?: number; }) => {

  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }

  const url = `${apiPathPrefix}/student/course_completion_tests/${unit_id}`
  return await api.get<Models.StudyCourseCompletionTestResultsRecord>(url, { params })
}

//
// トレーナー関連
//

// トレーナーマイページ
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/trainer/mypage_index.md
export type getTrainerMypageResponse = AxiosResponse<Models.TrainerMypage>
export const getTrainerMypage = async (
  api: AxiosInstance) : Promise<getTrainerMypageResponse> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  let url = ''
  if (process.env.REACT_APP_API_URL === 'http://localhost:3002') {
    url = `${apiPathPrefix}/trainer_mypage`
  } else {
    url = `${apiPathPrefix}/trainer/mypage`
  }
  return await api.get<Models.TrainerMypage>(url)
}

// トレーナー 受講生学習結果集計取得
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/trainer/mypage_index.md
export type getTrainerStudyStatusResponse = AxiosResponse<Models.StudyStatus>
export const getTrainerStudyStatus = async (
  api: AxiosInstance,
  params:  Models.FetchStudyStatusParams
  ) : Promise<getTrainerStudyStatusResponse> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  let url = ''
  if (process.env.REACT_APP_API_URL === 'http://localhost:3002') {
    url = `${apiPathPrefix}/trainer/study_status`
  } else {
    url = `${apiPathPrefix}/trainer/study_status`
  }
  const result = await api.get<Models.StudyStatus>(url, {params})
  return result
  //return await api.get<Models.StudyStatus>(url)
}

// トレーナー 受講生学習結果集計取得
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/trainer/mypage_index.md
export type getTrainerStudyStatusRealtimeResponse = AxiosResponse<Models.StudyStatusRealtime>
export const getTrainerStudyStatusRealtime = async (
  api: AxiosInstance,
  params:  Models.FetchStudyStatusParams
  ) : Promise<getTrainerStudyStatusRealtimeResponse> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  let url = ''
  if (process.env.REACT_APP_API_URL === 'http://localhost:3002') {
    url = `${apiPathPrefix}/trainer/study_status/realtime`
  } else {
    url = `${apiPathPrefix}/trainer/study_status/realtime`
  }
  const result = await api.get<Models.StudyStatusRealtime>(url, {params})
  return result
}

// スケジュール一覧取得
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/trainer/schedules_index.md
export type getTrainerStudentScheduleRecordsResponse = AxiosResponse<Models.ScheduleRecords>
export const getTrainerStudentScheduleRecords = async (
  api: AxiosInstance,
  params: Models.GetRecordsParams & { student_id: number }) : Promise<getTrainerStudentScheduleRecordsResponse> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  const url = `${apiPathPrefix}/trainer/schedules`
  return await api.get<Models.ScheduleRecords>(url, {
    params,
    transformResponse: [(data) => {
      const body = JSON.parse(data)
      return {
        total_count: body.total_count,
        total_page: body.total_page,
        records: body.schedules.map((x: any) => {
          return {
            id: x.id,
            body: x.body,
            date: x.acted_on,
          }
        }),
      }
    }]
  })
}

// トレーナープロフィール更新API
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/trainer/trainers_update.md
export type updateTrainerProfileResponse = AxiosResponse<void>
export const updateTrainerProfile = async (
  api: AxiosInstance,
  params: Models.UpdateTrainerProfileParams) => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  const url = `${apiPathPrefix}/trainer/trainers`
  return await api.put(url, { trainer: params })
}

// 相談登録
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/trainer/talks_create.md
export const createTrainerMessage = async (
  api: AxiosInstance,
  params: Models.CreateStudentMessageParams) : Promise<void> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  let url = ''
  if (process.env.REACT_APP_API_URL === 'http://localhost:3002') {
    url = `${apiPathPrefix}/student/signup`
  } else {
    url = `${apiPathPrefix}/trainer/talks`
  }
  return await api.post(url, params)
}

// 相談内容取得
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/trainer/talks_index.md

export type getTrainerMessageRecordsResponse = AxiosResponse<Models.StudentMessageRecords>
export const getTrainerMessageRecords = async (
  api: AxiosInstance,
  params: Models.GetRecordsParams & { student_id:number; }) : Promise<getStudentMessageRecordsResponse> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  let url = ''
  if (process.env.REACT_APP_API_URL === 'http://localhost:3002') {
    url = `${apiPathPrefix}/student/messages`
  } else {
    url = `${apiPathPrefix}/trainer/talks`
  }
  return await api.get<Models.StudentMessageRecords>(url, {
    params,
    transformResponse: [(data) => {
      const body = JSON.parse(data)
      return {
        total_page: body.total_page,
        total_count: body.total_count,
        records: body.talks,
      }
    }]
  })
}

// 教科別受講生各種学習記録CSVの履歴一覧取得
// https://github.com/gatjp/kawai1-back/blob/issue/599/docs/api/v1/trainer/csv_export_histories.md
export type getTrainerCsvExportHistoriesResponse = AxiosResponse<Models.CsvExportHistoriesRecord>
export const getTrainerCsvExportHistories = async (
  api: AxiosInstance,
  params: { page: number; per_page: number; }
) : Promise<getTrainerCsvExportHistoriesResponse> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  const url = `${apiPathPrefix}/trainer/csv_export_histories`
  return await api.get<Models.CsvExportHistoriesRecord>(url, { params })
}

// 教科別担当受講生学習履歴CSV出力予約
// https://github.com/gatjp/kawai1-back/blob/30ebc43a7bbabfe05894b86265c09dce6831bfeb/docs/api/v1/trainer/study_logs_csv_export.md

export type createTrainerCsvExportResponse = AxiosResponse<{ history: Models.CsvExportHistoryRecord }>
export const createTraineCsvExport = async (
  api: AxiosInstance,
  params: {
    type: 'study_logs' | 'study_results' | 'pre_post_test_logs',
    curriculum_id: number;
  }): Promise<createTrainerCsvExportResponse> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  const url = `${apiPathPrefix}/trainer/csv_export/${params.type}`
  return await api.post(url, params)
}

// 受講生週間学習実績取得API
// https://github.com/gatjp/kawai1-back/blob/8fc04419f893ef1ff3f5e78c588fb2d32f8802b3/docs/api/v1/student/study_achievements_weekly.md
export const getTrainerStudentStudyAchivementWeekly = async (
  api: AxiosInstance, params: { student_id: number; date?: string; }) : Promise<AxiosResponse<Models.StudyAchivementRecord>> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }

  const url = `${apiPathPrefix}/trainer/study_achievements/weekly`
  return await api.get<Models.StudyAchivementRecord>(url, {
    params,
    transformResponse: [(data) => {
      const body = JSON.parse(data)
      return body.study_achievements
    }]
  })
}

// [StudyPlus連携]ログイン認証取得API
export type getTrainerStudyplusAuthTokenResponse = AxiosResponse<{
  jwt: string;
  uid: string;
}>
export const getTrainerStudyplusAuthToken = async (
  api: AxiosInstance) : Promise<getTrainerStudyplusAuthTokenResponse> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  const url = `${apiPathPrefix}/trainer/sessions/studyplus`
  return await api.get<{
    jwt: string;
    uid: string;
  }>(url, {
    transformResponse: [(data) => {
      // {"customer_uid":"CR14RNTR0010","exp":1635816230,"jwt":"eyJhbGciOiJIUzI1NiJ9.eyJwYXJ0bmVyX2N1c3RvbWVyX3VpZCI6IkNSMTRSTlRSMDAxMCIsInRpbWVzdGFtcCI6IjIwMjEvMTEvMDIgMDE6MTg6NTAifQ.0kSUPD8gTFmyNCPceWZ1Lrn5e12YAGQvDVLoTyjGizA","school_name":"札幌校"}
      const body = JSON.parse(data)
      return {
        jwt: body.jwt,
        uid: body.customer_uid,
      }
    }],
  })
}

// トレーナー受講生クラス一覧取得

export type getTrainerSchoolClassesResponse = AxiosResponse<Models.SchoolClassRecord[]>
export const getTrainerSchoolClasses = async (
  api: AxiosInstance) : Promise<getTrainerSchoolClassesResponse> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  const url = `${apiPathPrefix}/trainer/school_classes`
  return await api.get<Models.SchoolClassRecord[]>(url, {
    transformResponse: [(data) => {
      const body = JSON.parse(data)
      return body.school_classes
    }]
  })
}

// トレーナー教科内科目一覧

export type geTrainerCurriculumCoursesResponse = AxiosResponse<{ id: number; title: string }[]>
export const getTrainerCurriculumCourses = async (
  api: AxiosInstance,
  params: { curriculum_id: number; }) : Promise<geTrainerCurriculumCoursesResponse> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  const url = `${apiPathPrefix}/trainer/courses`
  return await api.get<{ id: number; title: string }[]>(url, {
    params,
    transformResponse: [(data) => {
      const body = JSON.parse(data)
      return body.courses
    }]
  })
}

// トレーナー受講生教科ごと学習状況一覧取得

export type getTrainerStudentStudySummaryPerCurriculumRecordsResponse = AxiosResponse<Models.StudentStudySummaryPerCurriculumRecords>
export const getTrainerStudentStudySummaryPerCurriculumRecords = async (
  api: AxiosInstance, params: Models.GetRecordsParams & { school_class_id?: number; }) : Promise<getTrainerStudentStudySummaryPerCurriculumRecordsResponse> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  const url = `${apiPathPrefix}/trainer/student_study_summaries/per_curriculum`
  return await api.get<Models.StudentStudySummaryPerCurriculumRecords>(url, {
    params,
  })
}

// トレーナー受講生単元ごと学習状況一覧取得

export type getTrainerStudentStudySummaryPerUnitRecordsResponse = AxiosResponse<Models.StudentStudySummaryPerUnitRecords>
export const getTrainerStudentStudySummaryPerUnitRecords = async (
  api: AxiosInstance,
  params: Models.GetRecordsParams & {
    school_class_id?: number;
    course_id: number;
  }
) : Promise<getTrainerStudentStudySummaryPerUnitRecordsResponse> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  const url = `${apiPathPrefix}/trainer/student_study_summaries/per_unit`
  return await api.get<Models.StudentStudySummaryPerUnitRecords>(url, {
    params,
  })
}

//
// 講師関連
//

// https://github.com/gatjp/kawai1-back/blob/master/docs/api/instructor/mypage_index.md
// 講師マイページAPI
export type getInstructorMypageResponse = AxiosResponse<Models.InstructorMypage>
export const getInstructorMypage = async (
  api: AxiosInstance) : Promise<getInstructorMypageResponse> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  const url = `${apiPathPrefix}/instructor/mypage`;
  return await api.get<Models.InstructorMypage>(url)
}

// 講師プロフィール更新API
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/instructor/instructor_update.md 
export type updateInstructorProfileResponse = AxiosResponse<void>
export const updateInstructorProfile = async (
  api: AxiosInstance,
  params: Models.UpdateInstructorProfileParams) => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  const url = `${apiPathPrefix}/instructor/instructors`
  return await api.put(url, { instructor: params })
}

// 講師 質問一覧の取得
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/instructor/questions_index.md
export type getInstructorQuestionRecordsResponse = AxiosResponse<Models.QuestionRecords>
export const getInstructorQuestionRecords = async (
  api: AxiosInstance,
  params: Models.GetRecordsParams & { status: number }) : Promise<getInstructorQuestionRecordsResponse> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  let url = ''
  if (process.env.REACT_APP_API_URL === 'http://localhost:3002') {
    url = `${apiPathPrefix}/student_questions`
  } else {
    url = `${apiPathPrefix}/instructor/questions`
  }
  
  return await api.get<Models.QuestionRecords>(url, {
    params: {
      ...params,
    },
    transformResponse: [(data) => {
      const body = JSON.parse(data)
      return {
        total_page: body.total_page,
        total_count: body.total_count,
        records: body.questions,
      }
    }]
  })
}

// 講師として選択した受講生の質問履歴一覧が取得できるAPIの定義
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/instructor/questions_history.md
export type getInstructorStudentQuestionRecordsResponse = AxiosResponse<Models.QuestionRecords>
export const getInstructorStudentQuestionRecords = async (
  api: AxiosInstance,
  params: Models.GetRecordsParams & { student_id: number; }) : Promise<getInstructorStudentQuestionRecordsResponse> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  let url = ''
  if (process.env.REACT_APP_API_URL === 'http://localhost:3002') {
    url = `${apiPathPrefix}/student_questions`
  } else {
    url = `${apiPathPrefix}/instructor/questions/history/${params.student_id}`
  }
  return await api.get<Models.QuestionRecords>(url, {
    params,
    transformResponse: [(data) => {
      const body = JSON.parse(data)
      return {
        total_page: body.total_page,
        total_count: body.total_count,
        records: body.questions,
      }
    }]
  })
}

// 講師 質問詳細の取得
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/instructor/questions_show.md
export type getInstructorQuestionResponse = AxiosResponse<Models.QuestionDetail>
export const getInstructorQuestion = async (
  api: AxiosInstance,
  id: number) : Promise<getInstructorQuestionResponse> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  let url = ''
  if (process.env.REACT_APP_API_URL === 'http://localhost:3002') {
    url = `${apiPathPrefix}/student_question_detail`
  } else {
    url = `${apiPathPrefix}/instructor/questions/${id}`
  }
  return await api.get<Models.QuestionDetail>(url, {
    transformResponse: [(data) => {
      const body = JSON.parse(data)
      return body.question
    }]
  })
}

// 講師が受講生の質問を回答中にするAPI
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/instructor/questions_answer_begin.md
export type updateInstructorQuestionToProcessingResponse = AxiosResponse<void>
export const updateInstructorQuestionToProcessing = async (
  api: AxiosInstance,
  id: number) : Promise<updateInstructorQuestionToProcessingResponse> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  // PUT /api/v1/instructor/questions/:id/answer/in_progress
  let url = ''
  if (process.env.REACT_APP_API_URL === 'http://localhost:3002') {
    url = `${apiPathPrefix}/student_question_detail`
  } else {
    url = `${apiPathPrefix}/instructor/questions/${id}/answer/in_progress`
  }
  return await api.put(url)
}

// 講師が受講生の質問を回答中から未回答に戻すAPI
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/instructor/questions_answer_release.md
export type updateInstructorQuestionToNotAnsweredResponse = AxiosResponse<void>
export const updateInstructorQuestionToNotAnswered = async (
  api: AxiosInstance,
  id: number) : Promise<updateInstructorQuestionToNotAnsweredResponse> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  // DELETE  /api/v1/instructor/questions/:id/answer/in_progress
  let url = ''
  if (process.env.REACT_APP_API_URL === 'http://localhost:3002') {
    url = `${apiPathPrefix}/student_question_detail`
  } else {
    url = `${apiPathPrefix}/instructor/questions/${id}/answer/in_progress`
  }
  return await api.delete(url)
}

// 講師が事務局対応中の質問を差し戻すAPI
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/instructor/questions_answer_pending_cancel.md
export const updateInstructorQuestionToNotPending = async (
  api: AxiosInstance,
  id: number) : Promise<AxiosResponse<void>> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  // DELETE /api/v1/instructor/questions/:id/answer/pending
  let url = ''
  if (process.env.REACT_APP_API_URL === 'http://localhost:3002') {
    url = `${apiPathPrefix}/student_question_detail`
  } else {
    url = `${apiPathPrefix}/instructor/questions/${id}/answer/pending`
  }
  return await api.delete(url)
}

// 講師　質問回答
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/instructor/questions_answer.md
export const createQuestionAnswer = async (
  api: AxiosInstance,
  params: Models.CreateQuestionAnswerParams) : Promise<void> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  let url = ''
  if (process.env.REACT_APP_API_URL === 'http://localhost:3002') {
    url = `${apiPathPrefix}/student/signup`
  } else {
    url = `${apiPathPrefix}/instructor/questions/${params.id}/answer`
  }
  return await api.post(url, params)
}

// 講師が受講生の質問を事務局送りにするAPI
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/instructor/questions_answer_pending.md
export const createQuestionPendingAnswer = async (
  api: AxiosInstance,
  params: Models.CreateQuestionPendingAnswerParams) : Promise<void> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  let url = ''
  if (process.env.REACT_APP_API_URL === 'http://localhost:3002') {
    url = `${apiPathPrefix}/student/signup`
  } else {
    url = `${apiPathPrefix}/instructor/questions/${params.id}/answer/pending`
  }
  return await api.post(url, params)
}

//
// 保護者関連
//

// 保護者マイページ
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/parent/mypage_index.md
export type getParentMypageResponse = AxiosResponse<Models.ParentMypage>
export const getParentMypage = async (
  api: AxiosInstance) : Promise<getParentMypageResponse> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  let url = ''
  if (process.env.REACT_APP_API_URL === 'http://localhost:3002') {
    url = `${apiPathPrefix}/parent_mypage`
  } else {
    url = `${apiPathPrefix}/parent/mypage`
  }
  return await api.get<Models.ParentMypage>(url)
}

// 保護者購入履歴
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/parent/payments_index.md
export type getParentPaymentLogsResponse = AxiosResponse<Models.ParentPaymentLogs>
export const getParentPaymentLogs = async (
  api: AxiosInstance) : Promise<getParentPaymentLogsResponse> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  const url = `${apiPathPrefix}/parent/payments`
  return await api.get<Models.ParentPaymentLogs>(url, {
    transformResponse: [(data) => {
      const body = JSON.parse(data)
      return body.payments
    }]
  })
}

// 受講生のコンテンツ受講履歴一覧
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/parent/study_logs_index.md
export type getParentStudyLogRecordsResponse = AxiosResponse<Models.StudyLogRecords>
export const getParentStudyLogRecords = async (
  api: AxiosInstance,
  params: Models.GetRecordsParams & { curriculum_id: number }) : Promise<getParentStudyLogRecordsResponse> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  let url = ''
  if (process.env.REACT_APP_API_URL === 'http://localhost:3002') {
    url = `${apiPathPrefix}/student/study_logs`
  } else {
    url = `${apiPathPrefix}/parent/study_logs`
  }
  return await api.get<Models.StudyLogRecords>(url, {
    params,
    transformResponse: [(data) => {
      const body = JSON.parse(data)
      return {
        curriculum: body.curriculum,
        records: body.study_logs,
        total_count: body.total_count,
        total_page: body.total_page,
      }
    }]
  })
}

// 受講生情報取得
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/v2/parent/current.md
export type getParentStudentResponse = AxiosResponse<Models.StudentUser>
export const getParentStudent = async (
  api: AxiosInstance) : Promise<getParentStudentResponse> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  let url = `${apiPathPrefixes.v2}/parent/current`
  return await api.get<Models.StudentUser>(url, {
    transformResponse: [(data) => {
      const body = JSON.parse(data)
      return normalizeStudentUser(body.student)
    }]
  })
}

// 保護者プロフィール更新
// https://github.com/gatjp/kawai1-back/blob/master/docs/api/v2/parent/parents_update.md
export type updateParentProfileResponse = AxiosResponse<void>
export const updateParentProfile = async (
  api: AxiosInstance,
  params: Models.UpdateParentProfileParams) => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  let url = ''
  if (process.env.REACT_APP_API_URL === 'http://localhost:3002') {
    url = `${apiPathPrefix}/parent/signup`
  } else {
    url = `${apiPathPrefixes.v2}/parent/parents`
  }
  return await api.put(url, { parent: params })
}

// issue-547
// ブックマーク一覧を取得する
// https://github.com/gatjp/kawai1-back/blob/e7a1a96ada5996bde1abb939dd095042beafd59b/docs/api/v1/student/bookmarks_index.md
export type fetchBookmarksResponse = AxiosResponse<Models.BookmarksResponse>
export const fetchBookmarks =async (
  api: AxiosInstance,
  params: { },
  ): Promise<fetchBookmarksResponse>  => {
    if (!isAuthorizedApi(api)) {
      throw new Error('require Authorization');
    }
      const url = `${apiPathPrefix}/student/bookmarks`
      return await api.get<Models.BookmarksResponse>(url , {
        transformResponse: [(data) => {
          const body = JSON.parse(data)
          return body
        }]
      })
  }

// ブックマークを削除する
// https://github.com/gatjp/kawai1-back/blob/e7a1a96ada5996bde1abb939dd095042beafd59b/docs/api/v1/student/bookmarks_delete.md
//export type fetchBookmarkResponse = AxiosResponse<Models.Bookmark>
export const deleteBookmarks =async (
  api: AxiosInstance,
  params: Models.BookmarksDeleteParams,
  ) => {
    if (!isAuthorizedApi(api)) {
      throw new Error('require Authorization');
    }
    const url_str = params
    .map((bookmarkId) => "ids[]=" + bookmarkId)
    .join("&");
    const url = `${apiPathPrefix}/student/bookmarks?${url_str}`
    return await api.delete(url, {
      transformResponse: [(data) => {
        const body = JSON.parse(data)
        return body
      }]
    })
  }
  
// トレーナーとして特定の生徒のブックマーク一覧を取得する
// https://github.com/gatjp/kawai1-back/blob/e7a1a96ada5996bde1abb939dd095042beafd59b/docs/api/v1/trainer/students_bookmarks_index.md
export type fetchStudentBookmarksResponse = AxiosResponse<Models.BookmarksResponse>
export const fetchStudentBookmarks =async (
  api: AxiosInstance,
  params:   Models.StudentBookmarksParams,
  ): Promise<fetchStudentBookmarksResponse>  => {
    if (!isAuthorizedApi(api)) {
      throw new Error('require Authorization');
    }
    let studentId = params.student_id;
    //studentId = "1"
    const url = `${apiPathPrefix}/trainer/students/${studentId}/bookmarks`
    return await api.get<Models.BookmarksResponse>(url , {
      transformResponse: [(data) => {
        const body = JSON.parse(data)
        return body
      }]
    })
  }

// 受講生コンテンツ別学習回数取得
// https://github.com/gatjp/kawai1-back/blob/e7a1a96ada5996bde1abb939dd095042beafd59b/docs/api/v1/trainer/contents_learning_counts.md
export const getStudentLearningCount = async (
  api: AxiosInstance,
  params: { curriculumCodes: string[], studentId: number },
): Promise<Models.StudentStudyLearningCountRecords> => {

  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  const studentIdParam = params.studentId;
  const curriculumCodesParams = params.curriculumCodes;

  let baseUrl = "";
  if (process.env.REACT_APP_API_URL === "http://localhost:3002") {
    baseUrl = apiPathPrefix;
  } else {
    baseUrl = apiPathPrefixes.v1;
  }

  // プロミス関数を作成
  const requestLimit = 20; // ここで一回のリクエストでの上限数を決める
  let JsonPromiseList = [];
  for (let i = 0; i < curriculumCodesParams.length; i += requestLimit) {
    const curriculum_codes = curriculumCodesParams.slice(i, i + requestLimit);
    const curriculum_codes_str = curriculum_codes
      .map((code) => "curriculum_codes[]=" + code)
      .join("&");
    const urlStr = `${baseUrl}/trainer/contents/learning_counts?student_id=${studentIdParam}&${curriculum_codes_str}`;
    JsonPromiseList.push(api.get(urlStr).then(res => {
      return res.data
    }))
  }
  // プロミス関数を実行
  let JSONArrays = await Promise.all(JsonPromiseList)

  let contentsArray: any[] = []
  JSONArrays.forEach((x: any) => {
    contentsArray = contentsArray.concat(x.contents)
  })
  const response: any = { student: JSONArrays[0].student, contents: contentsArray }
  return response
};

// オススメ学習、教科・単元選択学習側の学習回数を取得する
export const getLearningCount = async (
  api: AxiosInstance,
  params: Models.StudentStudyLearningCountParams,
): Promise<Models.StudentStudyLearningCountRecords> => {

  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  } 

 let baseUrl = "";
  if (process.env.REACT_APP_API_URL === "http://localhost:3002") {
    baseUrl = apiPathPrefix;
  } else {
    baseUrl = apiPathPrefixes.v1;
  } 

   // プロミス関数を作成
  const requestLimit = 20; // ここで一回のリクエストでの上限数を決める
  let JsonPromiseList = [];
  for (let i = 0; i < params.length; i += requestLimit) {
    const curriculum_codes = params.slice(i, i + requestLimit);
    const curriculum_codes_str = curriculum_codes
      .map((code) => "curriculum_codes[]=" + code)
      .join("&");
    const urlStr = `${baseUrl}/student/contents/learning_counts?${curriculum_codes_str}`;
    JsonPromiseList.push(api.get(urlStr).then(res => {
      return  res.data
    }))
  }

   // プロミス関数を実行
  let JSONArrays = await Promise.all(JsonPromiseList.flat())

    // レスポンスを整理
  const contentsArray = JSONArrays.map((x)=> {
    return x.contents
  });
  const organizedResult = contentsArray.flat().map((x)=>{
    return {
      curriculum_code: x.content.launch_parameters.curriculum_code, 
      learning_count: x.content.learning_count
    }
  })
 
  return organizedResult;
};

// PDF Download
export type getPDFResponse = AxiosResponse<Models.PdfDownloadResponse>
export const getPDF = async (
  api: AxiosInstance,
  params: { code: string; id: any, type: string, env: string}
  ) : Promise<any> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  const contentBaseUrl = params.env === 'production' ? 
  'https://content.kawaijukuone.jp' : 
  'https://stg-content.kawaijukuone.jp'
  const result = await Axios.get(`${contentBaseUrl}/getpdf/`, {
  params: {
    code: params.code,
    type: params.type,
    id: params.id
  },
  responseType: 'blob' })

  let mimeType = 'application/zip'
  let fileName = result.headers['content-disposition'].split('filename=')[1].slice(1, -1)
  const fileType = fileName.split('.').pop()
  if (fileType === 'zip') { 
    fileName = 'kawaijukuone_download.zip'
  }
  if (fileType === 'pdf') {
    mimeType = 'application/pdf'
  }

  const blob = new Blob([result.data], { type: mimeType });
  const downloadURL = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = downloadURL;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(downloadURL);
  document.body.removeChild(a);

  return result 
}

export type getLaunchUrlResponse = AxiosResponse<{ launch_url: string; }>
export const getLaunchUrl = async (
  api: AxiosInstance,
  params: {
    content_id: number
    return_url: string
  }) : Promise<getLaunchUrlResponse> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }
  let url = ''
  if (process.env.REACT_APP_API_URL === 'http://localhost:3002') {
    url = `${apiPathPrefix}/trainer/study_logs`
  } else {
    url = `${apiPathPrefix}/trainer/study_logs`
  }
  return await api.post<{ launch_url: string; }>(url, params)
}

// 受講生 学習継続ステータス表示API
export type GetStudyAchievementsLatestResponse = AxiosResponse<Models.StudyAchievementsLatest>
export const getStudyAchievementsLatest = async (
  api: AxiosInstance
  ) : Promise<GetStudyAchievementsLatestResponse> => {
  return await api.get(`${apiPathPrefixes.v1}/student/study_achievements/latest`)
}

// トレーナー 学習継続ステータス表示API
export type GetTrainerStudyAchievementsLatestResponse = AxiosResponse<Models.TrainerStudyAchievementsLatest>
export const getTrainerStudyAchievementsLatest = async (
  api: AxiosInstance,
  params: { student_id: number }
  ) : Promise<GetTrainerStudyAchievementsLatestResponse> => {
  return await api.get(`${apiPathPrefixes.v1}/trainer/study_achievements/latest`, {params})
}

export type getAllStudentStudyTopicsResponse = AxiosResponse<Models.StudentStudyTopic>
export const downloadAllTopicsPdf = async (
  api: AxiosInstance,
  params: { all_topic_ids: number[], id: any, type: string, env: string }
  ) : Promise<getStudentStudyTopicResponse> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }

  let url = ''  
  if (process.env.REACT_APP_API_URL === 'http://localhost:3002') {
    url = `${apiPathPrefix}/student_study_topic`
  } else {
    url = `${apiPathPrefix}/student/contents`
  }
  const all_topic_ids = params.all_topic_ids
  let JsonPromiseList = [];
  for (let i = 0; i < all_topic_ids.length; i ++ ) {
    JsonPromiseList.push(api.get(url,  {params: {topic_id : params.all_topic_ids[i]}})
    .then(res => {
      return res.data
    }))
  }
  // 全てのコンテンツを取得
  let JSONArrays = await Promise.all(JsonPromiseList)
  const allCurriculumCode: any = JSONArrays.map((x : any) => {
    const curriculumCode = x.contents.map((content: any) => {
      // isVideoがfalseのものだけのカリキュラムコードを取得
      if (storeUtils.getContentMetaObjectByCode(content.launch_parameters.curriculum_code).isVideo === false) {
        return content.launch_parameters.curriculum_code
      } else {
        return null
      }
    })
    return curriculumCode
  })
  const code = allCurriculumCode.flat().filter((x: any) => x !== null).join('_')
  const type = params.type
  const contentBaseUrl = params.env === 'production' ? 
  'https://content.kawaijukuone.jp' : 
  'https://stg-content.kawaijukuone.jp'
  try {
    const result = await Axios.get(`${contentBaseUrl}/getpdf/`, {
    params: {code: code, type: type, id: params.id}, responseType: 'blob' })

    let mimeType = 'application/zip'
    let fileName = result.headers['content-disposition'].split('filename=')[1].slice(1, -1)
    const fileType = fileName.split('.').pop()
    if (fileType === 'zip') { 
      fileName = 'kawaijukuone_download.zip'
    }
    if (fileType === 'pdf') {
      mimeType = 'application/pdf'
    }

    const blob = new Blob([result.data], { type: mimeType });
    const downloadURL = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = downloadURL;
    a.download = fileName;
    document.body.appendChild(a);

    a.click();
    window.URL.revokeObjectURL(downloadURL);
    document.body.removeChild(a);
    } catch (error) {
      console.log(error)
    }

  return await code
}

export type getTrainerStudyTopicsResponse = AxiosResponse<Models.StudentStudyTopic>
export const getTrainerStudyTopics = async (
  api: AxiosInstance,
  params: { all_topic_ids: number[], id: string, type: string, env: string}
  ) : Promise<getStudentStudyTopicResponse> => {
  if (!isAuthorizedApi(api)) {
    throw new Error('require Authorization');
  }

  let url = ''  
  if (process.env.REACT_APP_API_URL === 'http://localhost:3002') {
    url = `${apiPathPrefix}/student_study_topic`
  } else {
    url = `${apiPathPrefix}/trainer/contents`
  }
  const all_topic_ids = params.all_topic_ids
  let JsonPromiseList = [];
  for (let i = 0; i < all_topic_ids.length; i ++ ) {
    JsonPromiseList.push(api.get(url,  {params: {topic_id : params.all_topic_ids[i]}})
    .then(res => {
      return res.data
    }))
  }
  // 全てのコンテンツを取得
  let JSONArrays = await Promise.all(JsonPromiseList)
  const allCurriculumCode: any = JSONArrays.map((x : any) => {
    const curriculumCode = x.contents.map((content: any) => {
      // isVideoがfalseのものだけのカリキュラムコードを取得
      if (storeUtils.getContentMetaObjectByCode(content.launch_parameters.curriculum_code).isVideo === false) {
        return content.launch_parameters.curriculum_code
      } else {
        return null
      }
    })
    return curriculumCode
  })
  const code = allCurriculumCode.flat().filter((x: any) => x !== null).join('_')
  const contentBaseUrl = params.env === 'production' ?
  'https://content.kawaijukuone.jp' :
  'https://stg-content.kawaijukuone.jp'
  try {
    const result = await Axios.get(`${contentBaseUrl}/getpdf/`, {
      params: {code: code, type: params.type, id: params.id}, responseType: 'blob' })

      let mimeType = 'application/zip'
      let fileName = result.headers['content-disposition'].split('filename=')[1].slice(1, -1)
      const fileType = fileName.split('.').pop()
      if (fileType === 'zip') { 
        fileName = 'kawaijukuone_download.zip'
      }
      if (fileType === 'pdf') {
        mimeType = 'application/pdf'
      }
    
    const blob = new Blob([result.data], { type: mimeType });
    const downloadURL = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = downloadURL;
    a.download = fileName;
    document.body.appendChild(a);

    a.click();
    window.URL.revokeObjectURL(downloadURL);
    document.body.removeChild(a);
  } catch (error) {
    console.log(error)
  }
  return await code
}