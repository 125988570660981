import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router'

import { AppState } from "store";
import targetComponent from 'components/student/StudyQuestionLogs';
import studentActions from 'actions/studentActions';
import * as Models from 'models/api';

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch })

const mapStateToProps = (appState: AppState, ownProps: RouteComponentProps) => {

  const user = appState.session.user! as Models.StudentUser

  const availableQuestions = (
    !user.corporation ||
    ( user.corporation && user.corporation.available_services.questions )
  )

  return {
    user,
    availableQuestions,
    question: appState.student.question,
    ...ownProps,
  }
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  { dispatch } : ReturnType<typeof mapDispatchToProps>) => {
  return {
    ...stateProps,
    fetchFirst: () => {
      dispatch(studentActions.fetchQuestionRecords({ initialize: true }))
    },
    fetchNext: () => {
      dispatch(studentActions.fetchQuestionRecords({ initialize: false }))
    }
  }
}

type connectMappedProps = ReturnType<typeof mergeProps>
export type mappedProps = connectMappedProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(targetComponent))