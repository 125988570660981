import React from 'react';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';

import * as Models from 'models/api'

type props = {
  infoRecords: Models.InfoListItem[] | null;
  hideListPageLink?: boolean;
}

const SectionInfoBox: React.FC<props> = (props: props) => {
  const {
    infoRecords,
  } = props
  return (
    <section className="box">
      <header className="box__head">
        <div className="box__title box__title--information">
          お知らせ
        </div>
        {
          !props.hideListPageLink &&
          <Link to="/infos" className="box__head-link">一覧を見る</Link>
        }
      </header>
      <div className="box__body">
        <ul className="information-list">
          {
            infoRecords && infoRecords.slice(0, 2).map((record, index) => (
              <li key={index} className="information-list__item">
                <Link to={`/infos/${record.id}`} className="information-list__title">
                  { record.title }
                </Link>
                <div className="information-list__date">
                  <Moment format="YYYY年M月D日">{ record.published_from }</Moment>
                </div>
              </li>
            ))
          }
        </ul>
        {
          !props.hideListPageLink &&
          <div className="box__more">
            <Link to="/infos" className="box__more-link">一覧を見る</Link>
          </div>
        }
      </div>
    </section>
  );
}

export default SectionInfoBox
