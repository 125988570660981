import { fork, race, take, call, put, select, takeEvery, takeLatest, delay } from 'redux-saga/effects'
import momentTimezone from 'moment-timezone'
import { AxiosResponse } from 'axios';

import * as apiService from 'services/api'
import * as Models from 'models/api'
import uiActions from 'actions/uiActions'
import { AppState } from 'store';
import actions from 'actions/trainerActions'
import { updateUser } from './session'

export function* fetchScheduleRecords(action: ReturnType<typeof actions.fetchScheduleRecords>) {
  const state: AppState = yield select()
  try {
    const student_id = action.payload.student_id
    const per_page = 10
    let page = state.trainer.schedule.page + 1
    if (action.payload.initialize) {
      page = 1
    }
    const params = {
      page,
      per_page,
      student_id,
    }
    const response: apiService.getTrainerStudentScheduleRecordsResponse = yield call(
      apiService.getTrainerStudentScheduleRecords,
      yield select(x => x.repository.authorizedApi!), params);
    const records = response.data
    yield put(actions.setScheduleRecords({ student_id, page, records }));
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

export function* fetchMessageRecords(action: ReturnType<typeof actions.fetchMessageRecords>) {
  try {
    const student_id = action.payload.student_id
    const params = {
      page: 1,
      per_page: 100,
      student_id,
    }
    const response: apiService.getTrainerMessageRecordsResponse= yield call(
      apiService.getTrainerMessageRecords,
      yield select(x => x.repository.authorizedApi!), params);
    const records = response.data
    yield put(actions.setMessageRecords({ student_id, records }));
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

export function* updateProfile(action: ReturnType<typeof actions.updateProfile>) {
  const state: AppState = yield select()
  try {
    yield call(
      apiService.updateTrainerProfile,
        state.repository.authorizedApi!,
        action.payload.params);

    yield updateUser()

    action.payload.promises && action.payload.promises.resolve()
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
    action.payload.promises && action.payload.promises.reject(e)
  }
}

export function* fetchMypage(action: ReturnType<typeof actions.fetchMypage>) {
  try {
    const response: apiService.getTrainerMypageResponse = yield call(
      apiService.getTrainerMypage,
      yield select(x => x.repository.authorizedApi!));
    const record = response.data
    yield put(actions.setMypage({ record }));
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

export function* fetchStudyStatus(action: ReturnType<typeof actions.fetchStudyStatus>) {
  const params = action.payload.params || {}
  try {
    const response: apiService.getTrainerStudyStatusResponse = yield call(
      apiService.getTrainerStudyStatus,
      yield select(x => x.repository.authorizedApi!),
      params
    );
    const records = response.data
    yield put(actions.setStudyStatus({ records }));

  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

export function* fetchStudyStatusRealtime(action: ReturnType<typeof actions.fetchStudyStatusRealtime>) {
  const params = action.payload.params || {}
  try {
    const response: apiService.getTrainerStudyStatusRealtimeResponse = yield call(
      apiService.getTrainerStudyStatusRealtime,
      yield select(x => x.repository.authorizedApi!),
      params
      );
    const records = response.data
    yield put(actions.setStudyStatusRealtime({ records }));
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}
// TODO: 発火のたびに古いポーリングを止める必要がある
export function* pollStudyStatusRealtime(action: ReturnType<typeof actions.fetchStudyStatusRealtime>) {
  try {
    while (true) {
      yield call(fetchStudyStatusRealtime, action);
      yield delay(60000);
    }
  } catch (e) {
    console.error(e);
    yield put(actions.stopFetchStudyStatusRealtime());
    yield put(uiActions.showApiErrorNotification(e));
  }
}

function* pollStudyStatusRealtimeWatcher() {
  while (true) {
      const action = yield take(actions.fetchStudyStatusRealtime)
      yield race([
        call(pollStudyStatusRealtime, action),
        take(actions.stopFetchStudyStatusRealtime),
      ])
  }
}

export function* fetchStudent(action: ReturnType<typeof actions.fetchStudent>) {
  const api = yield select(x => x.repository.authorizedApi!)
  const student_id = action.payload.student_id
  try {
    const response: apiService.getOwnStudentResponse = yield call(
      apiService.getOwnStudent,
      api,
      { ui: 'trainer', student_id });
    const record = response.data

    // MARK: 週間学習実績取得API
    const records = []
    let date: string | undefined = undefined 
    while(true) {
      const response: AxiosResponse<Models.StudyAchivementRecord> = yield call(
        apiService.getTrainerStudentStudyAchivementWeekly,
        api,
        { student_id, date }
      );

      if(!response.data.start_week_on) {
        break
      }

      records.push(response.data)

      // MARK: 前の週リンク表示のため、初期リクエスト時に前の週分まで取得
      if (records.length >= 2) {
        break
      }
      date = momentTimezone(response.data.start_week_on).subtract(7, 'day').format('YYYY-MM-DD')
    }

    const has_next = records.length === 2
    yield put(actions.setStudyTimeStats({ records, student_id, has_next }));
    yield put(actions.setStudent({ record, student_id }));

  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

export function* fetchStudentNextStudyTimeStats(action: ReturnType<typeof actions.fetchStudentNextStudyTimeStats>) {
  const state: AppState = yield select()
  try {
    const api = state.repository.authorizedApi!
    if (!state.trainer.studyTimeStats.has_next || !state.trainer.studyTimeStats.student_id) {
      return
    }

    // MARK: 一つ前の週のデータを取得
    const student_id = state.trainer.studyTimeStats.student_id
    const records = state.trainer.studyTimeStats.records
    const date = momentTimezone(records[records.length - 1].start_week_on).subtract(7, 'day').format('YYYY-MM-DD')

    const response: AxiosResponse<Models.StudyAchivementRecord> = yield call(
      apiService.getTrainerStudentStudyAchivementWeekly,
      api,
      { student_id, date }
    );

    if(!response.data.start_week_on) {
      yield put(actions.pushStudyTimeStats({ records: [], has_next: false }));
    } else {
      yield put(actions.pushStudyTimeStats({ records: [response.data], has_next: true }));
    }

  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

export function* createCsvExport(action: ReturnType<typeof actions.createCsvExport>) {
  const state: AppState = yield select()
  try {
    yield call(
      apiService.createTraineCsvExport,
        state.repository.authorizedApi!,
        action.payload.params);

    action.payload.promises && action.payload.promises.resolve()
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
    action.payload.promises && action.payload.promises.reject(e)
  }
}

export function* fetchCsvExportHistories(action: ReturnType<typeof actions.fetchCsvExportHistories>) {
  const state: AppState = yield select()
  try {
    const per_page = 10
    let page = state.trainer.csvExportHistory.page + 1
    if (action.payload.initialize) {
      page = 1
    }
    const params = {
      page,
      per_page,
    }
    const response: apiService.getTrainerCsvExportHistoriesResponse = yield call(
      apiService.getTrainerCsvExportHistories,
      yield select(x => x.repository.authorizedApi!),
      params);
    const record = response.data
    yield put(actions.setCsvExportHistories({ page, record }));
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

export function* fetchSchoolClasses(action: ReturnType<typeof actions.fetchSchoolClasses>) {
  try {
    const response: apiService.getTrainerSchoolClassesResponse = yield call(
      apiService.getTrainerSchoolClasses,
      yield select(x => x.repository.authorizedApi!));
    const records = response.data
    yield put(actions.setSchoolClasses({ records }));
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

export function* fetchCurriculumCourses(action: ReturnType<typeof actions.fetchCurriculumCourses>) {
  try {
    const curriculum_id = action.payload.curriculum_id
    const response: apiService.geTrainerCurriculumCoursesResponse = yield call(
      apiService.getTrainerCurriculumCourses,
      yield select(x => x.repository.authorizedApi!), {
        curriculum_id,
      });
    const records = response.data
    yield put(actions.setCurriculumCourses({ curriculum_id, records }));
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

export function* fetchStudySummariesPerCurriculum(action: ReturnType<typeof actions.fetchStudySummariesPerCurriculum>) {
  const state: AppState = yield select()
  try {
    const per_page = 10
    let page = state.trainer.studySummariesPerCurriculum.page + 1
    if (action.payload.initialize) {
      page = 1
    }
    const params = {
      page,
      per_page,
      school_class_id: action.payload.school_class_id,
    }
    const response: apiService.getTrainerStudentStudySummaryPerCurriculumRecordsResponse = yield call(
      apiService.getTrainerStudentStudySummaryPerCurriculumRecords,
      yield select(x => x.repository.authorizedApi!), params);
    const records = response.data
    yield put(actions.setStudySummariesPerCurriculum({page, records }));
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

export function* fetchStudySummariesPerUnit(action: ReturnType<typeof actions.fetchStudySummariesPerUnit>) {
  const state: AppState = yield select()
  const course_id = action.payload.course_id
  try {
    const per_page = 10
    let page = state.trainer.studySummariesPerUnit.page + 1
    if (action.payload.initialize) {
      page = 1
    }
    const params = {
      page,
      per_page,
      course_id,
      school_class_id: action.payload.school_class_id,
    }
    const response: apiService.getTrainerStudentStudySummaryPerUnitRecordsResponse = yield call(
      apiService.getTrainerStudentStudySummaryPerUnitRecords,
      yield select(x => x.repository.authorizedApi!), params);
    const records = response.data
    yield put(actions.setStudySummariesPerUnit({ course_id, page, records }));
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}
// issue-547
export function* fetchStudentBookmarks(action: ReturnType<typeof actions.fetchStudentBookmarks>) {
  const state: AppState = yield select();
  try {
    const response: apiService.fetchStudentBookmarksResponse = yield call(
      apiService.fetchStudentBookmarks,
      state.repository.authorizedApi!,
      action.payload.params,
    );
    if (response) {
      yield put(actions.setStudentBookmarks({ params: response.data.bookmarks }))
    }
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

export function * fetchStudentBookmarksLearningCount(action: ReturnType<typeof actions.fetchStudentBookmarksLearningCount>) {
  const state: AppState = yield select()
  try {
    const response: Models.StudentBookmarksLearningCount = yield call(
      apiService.getStudentLearningCount,
      state.repository.authorizedApi!,
      action.payload.params
    );
    if (response) {
      yield put(actions.setStudentBookmarksLearningCount({ params: response}))
    }
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}
// issue-547
export function* fetchStudyCourse(action: ReturnType<typeof actions.fetchStudyCourse>) {
  let state: AppState = yield select()
  const ui = state.ui.type! as Models.LoginableUIType
  try {
    const course_id = action.payload.course_id
    const params = {
      ui: ui,
      course_id: course_id,
    }
    const response: apiService.getOwnStudentStudyCourseResponse = yield call(
      apiService.getOwnStudentStudyCourse,
      yield select(x => x.repository.authorizedApi!),
      params);
    const record = response.data
    yield put(actions.setStudyCourse({ record, course_id }));
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

export function* fetchTrainerStudyAchievementsLatest(action: ReturnType<typeof actions.fetchTrainerStudyAchievementsLatest>) {
  try {
    const params = action.payload.params;
    const response: apiService.GetTrainerStudyAchievementsLatestResponse = yield call(
      apiService.getTrainerStudyAchievementsLatest,
    yield select(x => x.repository.authorizedApi!), params);
    const record = response.data
    yield put(actions.setTrainerStudyAchievementsLatest({ record: record }));
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

export function* fetchStudyUnit(action: ReturnType<typeof actions.fetchStudyUnit>) {
  let state: AppState = yield select()
  const ui = state.ui.type! as Models.LoginableUIType
  try {
    const unit_id = action.payload.unit_id
    const params = {
      ui,
      unit_id,
    }

    const response: apiService.getOwnStudentStudyUnitResponse = yield call(
      apiService.getOwnStudentStudyUnit,
      yield select(x => x.repository.authorizedApi!),
      params);
    const record = response.data
    yield put(actions.setStudyUnit({ record, unit_id }));
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

export function* fetchStudyTopic(action: ReturnType<typeof actions.fetchStudyTopic>) {
  let state: AppState = yield select()
  const ui = state.ui.type! as Models.LoginableUIType
  try {
    const topic_id = action.payload.topic_id
    const params = {
      ui,
      topic_id,
    }

    const response: apiService.getOwnStudentStudyTopicResponse = yield call(
      apiService.getOwnStudentStudyTopic,
      yield select(x => x.repository.authorizedApi!),
      params);
    const record = response.data
    yield put(actions.setStudyTopic({ record, topic_id }));
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

export function* downloadAllTopicsPdf(action: ReturnType<typeof actions.downloadAllTopicsPdf>) {
  try {
    const state: AppState = yield select()
    const all_topic_ids = action.payload.all_topic_ids
    const userId = state.session.user!.login
    const type = state.ui.type! as Models.LoginableUIType
    const env = state.ui.env!
    const params = {
      all_topic_ids,
      id : userId,
      type,
      env
    }
    const response: apiService.getAllStudentStudyTopicsResponse = yield call(
      apiService.getTrainerStudyTopics,
      yield select(x => x.repository.authorizedApi!),
      params);

    const records = response.data
    return records

  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

export function* fetchPdfDownload(action: ReturnType<typeof actions.fetchPdfDownload>) {
  let state: AppState = yield select()
  const id = state.session.user!.login
  const code = action.payload.pdf_curriculums
  const type = state.ui.type! as Models.LoginableUIType
  const env = state.ui.env!
  const params = {
    code,
    id,
    type,
    env
  }

  try {
    const response: apiService.getPDFResponse = yield call(
      apiService.getPDF,
      yield select(x => x.repository.authorizedApi!),
      params);
      const record = response.data
    action.payload.promises && action.payload.promises.resolve(record)
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

export function* startStudy(action: ReturnType<typeof actions.startStudy>) {
  const state: AppState = yield select()

  const content_id = action.payload.params.content_id
  const return_pathname = action.payload.params.return_pathname
  const params = {
    content_id,
    return_url: '/#' + return_pathname
  }

  try {
    const response: apiService.createStudyLogResponse = yield call(
      apiService.getLaunchUrl,
        state.repository.authorizedApi!,
        params);
    action.payload.promises && action.payload.promises.resolve(response.data.launch_url)
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
    action.payload.promises && action.payload.promises.reject(e)
  }
}

function* saga() {
  yield takeEvery(actions.fetchScheduleRecords, fetchScheduleRecords);
  yield takeEvery(actions.fetchMessageRecords, fetchMessageRecords);
  yield takeEvery(actions.updateProfile, updateProfile);
  yield takeEvery(actions.fetchMypage, fetchMypage);
  yield takeEvery(actions.fetchStudyStatus, fetchStudyStatus);
  yield fork(pollStudyStatusRealtimeWatcher);

  yield takeEvery(actions.fetchStudent, fetchStudent);
  yield takeEvery(actions.fetchStudentNextStudyTimeStats, fetchStudentNextStudyTimeStats);
  yield takeEvery(actions.createCsvExport, createCsvExport);
  yield takeLatest(actions.fetchCsvExportHistories, fetchCsvExportHistories);
  yield takeLatest(actions.fetchSchoolClasses, fetchSchoolClasses);
  yield takeLatest(actions.fetchCurriculumCourses, fetchCurriculumCourses);
  yield takeLatest(actions.fetchStudySummariesPerCurriculum, fetchStudySummariesPerCurriculum);
  yield takeLatest(actions.fetchStudySummariesPerUnit, fetchStudySummariesPerUnit);
  // issue-547
  yield takeLatest(actions.fetchStudentBookmarks, fetchStudentBookmarks);
  yield takeLatest(actions.fetchStudentBookmarksLearningCount, fetchStudentBookmarksLearningCount);
  yield takeLatest(actions.fetchStudyCourse, fetchStudyCourse);
  yield takeLatest(actions.fetchStudyUnit, fetchStudyUnit);
  yield takeEvery(actions.fetchStudyTopic, fetchStudyTopic);
  yield takeEvery(actions.downloadAllTopicsPdf, downloadAllTopicsPdf );
  yield takeEvery(actions.fetchPdfDownload, fetchPdfDownload);
  yield takeEvery(actions.startStudy, startStudy);
  yield takeLatest(actions.fetchTrainerStudyAchievementsLatest, fetchTrainerStudyAchievementsLatest);
}

export default saga;
