import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router'

import { AppState } from "store"
import targetComponent from 'components/instructor/Mypage'
import instructorActions from 'actions/instructorActions';
import * as Models from 'models/api'

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch })

const mapStateToProps = (appState: AppState, ownProps: RouteComponentProps) => {
  const uiType = appState.ui.type
  return {
    user: appState.session.user!,
    instructor: appState.instructor,
    uiType,
    ...ownProps,
  }
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  { dispatch }: ReturnType<typeof mapDispatchToProps>) => {
  return {
    ...stateProps,
    fetchData: (questionType: 'not-answered'|'processing'|'answered') => {
      dispatch(instructorActions.fetchMypage({ type: questionType }))
    },
    fetchNext: (questionType: 'not-answered'|'processing'|'answered') => {
      dispatch(instructorActions.fetchQuestionRecords({ initialize: false, type: questionType }))
    },
    getQuestionRecordsByType:
      (questionType: 'not-answered'|'processing'|'answered'): [Models.Question[] | null, boolean] => {
      if (questionType !== stateProps.instructor.question.type) {
        return [null, false];
      }
      return [
        stateProps.instructor.question.records,
        stateProps.instructor.question.has_next
      ];
    },
  }
}

export type mappedProps = ReturnType<typeof mergeProps>

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(targetComponent))
