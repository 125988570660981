import React, { useEffect } from 'react';
import { Link, useParams, Redirect } from 'react-router-dom';
import { mappedProps } from 'containers/trainer/StudentStudyResultCurriculum';

const nl2br = require('react-nl2br');

const TrainerStudentStudyResultCurriculum: React.FC<mappedProps> = (props: mappedProps) => {
  const { id, curriculum_id } = useParams<{ id: string; curriculum_id: string; }>();

  const curriculum = curriculum_id && props.getCurriculum(curriculum_id)

  useEffect(() => {
    if (id && curriculum) {
      props.fetchData(Number(id), curriculum.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, curriculum_id]);

  if (!curriculum) {
    return <Redirect to='/' />;
  }

  const student = props.getStudent(Number(id))
  const page = student && props.getRecord(student.id, curriculum.id)

  if (student && !props.isRecommendCurriculum(curriculum.id, student)) {
    return <Redirect to='/' />;
  }

  return (
    <React.Fragment>
      {
        student && page &&
        <>
          <div className="study-breadcrumbs">
            <Link to={`/students/${student.id}/study_result`} className="study-breadcrumbs__item">
              教科
            </Link>
            <div className="study-breadcrumbs__item">
              科目
            </div>
          </div>

          <div className="page-subtitle">
            科目
          </div>

          <ul className="subject-list">
            { page.courses.map(course => (
              <li key={course.id} className="subject-list__item">
                {
                  course.title.length <= 8 ?
                  <Link
                    to={`/students/${student.id}/study_result/courses/${course.id}`}
                    className="subject-list__link">
                    { course.title }
                  </Link>:
                  <Link
                    to={`/students/${student.id}/study_result/courses/${course.id}`}
                    className="subject-list__link subject-list__link--multi-line"
                  >
                    { nl2br(course.title.replace('　', '\n') ) }
                  </Link>
                }

              </li>
            ))}
          </ul>
        </>
      }
    </React.Fragment>
  );
}

export default TrainerStudentStudyResultCurriculum