import React, { useEffect, useState } from 'react';
import { Link, useParams, } from 'react-router-dom';
import classNames from 'classnames';
import { mappedProps } from 'containers/trainer/StudentStudyResultCourseCompleteTest';
import { CommonTestHintBalloon } from 'components/common/course_completion_test/HintBalloon';
import { CourseCompletionTestResultBoxItem } from 'components/common/course_completion_test/CourseCompletionTestResultBoxItem';

const TrainerStudentStudyResultCourseCompleteTest: React.FC<mappedProps> = (props: mappedProps) => {
  const { id, unit_id } = useParams<{ id: string; unit_id: string }>();
  const [showMore, setShowMore] = useState<boolean>(false);

  useEffect(() => {
    if (id && unit_id) {
      props.fetchData(Number(id), Number(unit_id))

      // MARK: 以前の結果を表示を初期化
      setShowMore(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, unit_id]);

  const student = props.getStudent(Number(id))
  const record = props.getRecord(Number(id), Number(unit_id))
  const moreRecord = props.getMoreRecord(Number(id), Number(unit_id))
  const hasMore = record && record.total_page > 1

  return (
    <React.Fragment>
      {
        student && record &&
        <>
          {/* <div className="study-breadcrumbs">
            <Link to={`/students/${student.id}/study_result`} className="study-breadcrumbs__item">
              教科
            </Link>
            <Link to={`/students/${student.id}/study_result/${record.curriculum.id}`} className="study-breadcrumbs__item">
              科目
            </Link>
            <Link to={`/students/${student.id}/study_result/courses/${record.course.id}`} className="study-breadcrumbs__item">
              単元
            </Link>

            <span className="study-breadcrumbs__item">
              科目修了テスト
            </span>
          </div> */}

          <div className="page-title">
            { record.curriculum.name }&nbsp;
            <div className="page-title__badge">
              { record.course.name }
            </div>
          </div>
          <div className="test-box">
            <div className="test-box__title test-box__title--large">
              科目修了テスト
            </div>

            <CommonTestHintBalloon />

            <div className="test-box__body">
              <div className="completion-test-result-box">
                <div className="completion-test-result-box__head completion-test-result-box__head--completion">
                  <div className="completion-test-result-box__point-head">
                    合計点数
                  </div>
                  <div className="completion-test-result-box__expectation-head _pc">
                    共通テスト予想点数
                  </div>
                  <div className="completion-test-result-box__date-head">
                    実施日時
                  </div>
                </div>
                <div className="completion-test-result-box__body is-active">
                  {
                    record.results.length <= 0 && 
                    <div className="completion-test-result-box__result completion-test-result-box__result--empty">
                      テスト結果がありません
                    </div>
                  }
                  {
                    record.results.map((x, index) => 
                    <CourseCompletionTestResultBoxItem key={index} result={x} />)
                  }
                </div>

                {
                  hasMore && 
                  <div className="completion-test-result-box__more">
                    <div className="more-button more-button--result">
                      <div
                        className={classNames("more-button__text more-button__text--result", {
                          'is-active': showMore,
                        })}
                        onClick={e => {
                          e.preventDefault()
                          setShowMore(!showMore)
                          if (!moreRecord) {
                            props.fetchMore()
                          }
                        }}
                      >
                        以前の結果を表示
                      </div>
                    </div>
                  </div>
                }
                {
                  moreRecord && showMore &&
                  <div className="completion-test-result-box__body is-active">
                    {
                      moreRecord.results.length <= 0 && 
                      <div className="completion-test-result-box__result completion-test-result-box__result--empty">
                        テスト結果がありません
                      </div>
                    }
                    {
                      moreRecord.results.map((x, index) =>
                        <CourseCompletionTestResultBoxItem key={index} result={x} />)
                    }
                  </div>
                }

                <div className="completion-test-result-box__button">
                  <Link
                    to={`/students/${student.id}/study_result/courses/${record.course.id}`}
                    className="button button--xlarge button--gray button--back">
                    前のページへ戻る
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </>
      }
    </React.Fragment>
  );
}

export default TrainerStudentStudyResultCourseCompleteTest