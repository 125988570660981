import { reducerWithInitialState } from 'typescript-fsa-reducers';
import produce from 'immer'
import storeUtils from 'utils/store'

import * as Models from 'models/api'
import actions from 'actions/trainerActions';

import {disabledContentsCode} from './studentState'
export interface TrainerState {
  mypage: Models.TrainerMypage | null;
  studyStatus: Models.StudyStatus | null;
  studyStatusRealtime: Models.StudyStatusRealtimeGlobalStateType | null;
  selectedSchoolClassId?: number | null;
  schholClasses: null | Models.SchoolClassRecord[],
  courses: {
    curriculum_id: number | null;
    records: {
      id: number;
      title: string;
    }[];
  },
  studySummariesPerCurriculum: {
    records: Models.StudentStudySummaryPerCurriculumRecord[] | null;
    page: number;
    has_next: boolean;
  }
  studySummariesPerUnit: {
    records: Models.StudentStudySummaryPerUnitRecord[] | null;
    page: number;
    course_id: number | null;
    has_next: boolean;
  }
  student: {
    student_id: number | null;
    record: Models.StudentUser | null;
  },
  schedule: {
    student_id: number | null;
    records: Models.Schedule[] | null;
    page: number;
    has_next: boolean;
  }
  message: {
    student_id: number | null;
    records: Models.StudentMessage[] | null;
  }
  studyTimeStats: {
    student_id: number | null;
    records: Models.StudyAchivementRecord[];
    has_next: boolean;
  },
  csvExportHistory: {
    records: Models.CsvExportHistoryRecord[];
    page: number;
    has_next: boolean;
  },
  // issue-547
  studentBookmarks: {
    records: Models.Bookmarks | null;
  },
  studentBookmarksLearningCount: {
    records: Models.StudentBookmarksLearningCount | null;
  },
  studyCourse: {
    course_id: number | null;
    record: Models.StudentStudyCourse | null;
  },
  studyUnit: {
    unit_id: number | null;
    record: Models.StudentStudyUnit | null;
  },  
  studyTopic: {
    topic_id: number | null;
    record: Models.StudentStudyTopic | null;
  },
  trainerStudyAchievementsLatest: {
    records: Models.TrainerStudyAchievementsLatest | null;
  },
}

const initialState: TrainerState = {
  mypage: null,
  studyStatus: null,
  studyStatusRealtime: null,
  selectedSchoolClassId: null,
  schholClasses: null,
  courses: {
    curriculum_id: null,
    records: [],
  },
  studySummariesPerCurriculum: {
    records: null,
    page: 0,
    has_next: false,
  },
  studySummariesPerUnit: {
    records: null,
    page: 0,
    has_next: false,
    course_id: null,
  },
  studyTimeStats: {
    student_id: null,
    records: [],
    has_next: false,
  },
  student: {
    student_id: null,
    record: null,
  },
  schedule: {
    student_id: null,
    records: null,
    page: 0,
    has_next: true,
  },
  message: {
    records: null,
    student_id: null,
  },
  csvExportHistory: {
    records: [],
    page: 0,
    has_next: false,
  },
  // issue-547
  studentBookmarks : {
    records: null,
  },
  studentBookmarksLearningCount: {
    records: null,
  },
  studyCourse: {
    course_id: null,
    record: null,
  },
  studyUnit: {
    unit_id: null,
    record: null,
  },
  studyTopic: {
    topic_id: null,
    record: null,
  },
  trainerStudyAchievementsLatest : {
    records: null,
  }
};

export const trainerReducer = reducerWithInitialState(initialState)
  .case(actions.setScheduleRecords, (state, payload) => {
    return produce(state, draftState => {
      draftState.schedule.student_id = payload.student_id
      draftState.schedule.page = payload.page
      if (!draftState.schedule.records || payload.page === 1) {
        draftState.schedule.records = payload.records.records
      } else {
        draftState.schedule.records.push(...payload.records.records)
      }
      draftState.schedule.has_next = payload.records.total_page > payload.page
    })
  })
  .case(actions.setMessageRecords, (state, payload) => {
    return produce(state, draftState => {
      draftState.message.student_id = payload.student_id
      // MARK: チャット形式なので古い順にする
      draftState.message.records = payload.records.records.reverse()
    })
  })
  .case(actions.setMypage, (state, payload) => {
    return produce(state, draftState => {
      draftState.mypage = payload.record
    })
  })
  .case(actions.setStudyStatus, (state, payload) => {
    return produce(state, draftState => {
      draftState.studyStatus = payload.records
    })
  })
  .case(actions.setStudyStatusRealtime, (state, payload) => {
    let students = payload.records.students
    const result = students.map((student) => {
      const total_count = student.status.total_view_count + student.status.total_answer_count
      return {
        ...student,
        status: {
          ...student.status,
          count_total: total_count,
      }
    }
    })
    return produce(state, draftState => {
      draftState.studyStatusRealtime = {
        ...payload.records,
        students: result
      }
    })
  })

  .case(actions.setStudent, (state, payload) => {
    return produce(state, draftState => {
      // FIXME: API設計見た感じマイページの情報をマージするような想定かもしれないので...
      let tmp : any = {}
      if (draftState.mypage) {
        tmp = draftState.mypage.students.find(x => x.id === payload.record.id)
      }
      const record = {
        ...tmp,
        ...payload.record,
      }
      draftState.student.record = record
      draftState.student.student_id = payload.student_id
    })
  })
  .case(actions.setStudyTimeStats, (state, payload) => {
    return produce(state, draftState => {
      draftState.studyTimeStats.student_id = payload.student_id
      draftState.studyTimeStats.records = payload.records
      draftState.studyTimeStats.has_next = payload.has_next
    })
  })
  .case(actions.pushStudyTimeStats, (state, payload) => {
    return produce(state, draftState => {
      draftState.studyTimeStats.records.push(...payload.records)
      draftState.studyTimeStats.has_next = payload.has_next
    })
  })
  .case(actions.setCsvExportHistories, (state, payload) => {
    return produce(state, draftState => {
      draftState.csvExportHistory.page = payload.page
      const histories = payload.record.histories
      const total_page = payload.record.total_page
      if (!draftState.csvExportHistory.records || payload.page === 1) {
        draftState.csvExportHistory.records = histories
      } else {
        draftState.csvExportHistory.records.push(...histories)
      }
      draftState.csvExportHistory.has_next = total_page > payload.page
    })
  })
  .case(actions.setSchoolClasses, (state, payload) => {
    return produce(state, draftState => {
      draftState.schholClasses = payload.records
    })
  })
  .case(actions.setCurriculumCourses, (state, payload) => {
    return produce(state, draftState => {
      draftState.courses.curriculum_id = payload.curriculum_id
      draftState.courses.records = payload.records
    })
  })
  .case(actions.setStudySummariesPerCurriculum, (state, payload) => {
    return produce(state, draftState => {
      draftState.studySummariesPerCurriculum.page = payload.page
      const students = payload.records.students
      const total_page = payload.records.total_page
      if (!draftState.studySummariesPerCurriculum.records || payload.page === 1) {
        draftState.studySummariesPerCurriculum.records = students
      } else {
        draftState.studySummariesPerCurriculum.records.push(...students)
      }
      draftState.studySummariesPerCurriculum.has_next = total_page > payload.page
    })
  })
  .case(actions.setStudySummariesPerUnit, (state, payload) => {
    return produce(state, draftState => {
      draftState.studySummariesPerUnit.page = payload.page
      const students = payload.records.students
      const total_page = payload.records.total_page
      if (!draftState.studySummariesPerUnit.records || payload.page === 1) {
        draftState.studySummariesPerUnit.records = students
      } else {
        draftState.studySummariesPerUnit.records.push(...students)
      }
      draftState.studySummariesPerUnit.has_next = total_page > payload.page
      draftState.studySummariesPerUnit.course_id = payload.course_id
    })
  })
  // issue-547
  .case(actions.setStudentBookmarks, (state, payload) => {
    return produce(state, draftState => {
      if (payload.params) {
        draftState.studentBookmarks.records =
          payload.params.map(x => ({
            ...x,
            content: {
              ...x.content,
              meta: storeUtils.getContentMetaObjectByCode(
                x.content.curriculum_code)
            }
          })
          )
      }
    })
  })
  .case(actions.setStudentBookmarksLearningCount, (state, payload) => {      
    return produce(state, draftState => {
        draftState.studentBookmarksLearningCount.records = payload.params;
      })
    })
  .case(actions.setStudyCourse, (state, payload) => {
    return produce(state, draftState => {
      draftState.studyCourse.course_id = payload.course_id
      draftState.studyCourse.record = payload.record
    })
  })
  .case(actions.setStudyUnit, (state, payload) => {
    return produce(state, draftState => {
      draftState.studyUnit.unit_id = payload.unit_id
      if (payload.record) {
        // MARK: 未受講はレベル１で表示
        draftState.studyUnit.record = {
          ...payload.record,
          topics: payload.record.topics.map(x => ({
            ...x,
            check_level: x.check_level === '未受講' ? 'レベル1' : x.check_level
          }))
        }
      } else {
        draftState.studyUnit.record = payload.record
      }
    })
  })    
  .case(actions.setStudyTopic, (state, payload) => {
    return produce(state, draftState => {
      draftState.studyTopic.topic_id = payload.topic_id
      if (payload.record) {
        draftState.studyTopic.record = {
          ...payload.record,
          contents: payload.record.contents
            .filter(x => !disabledContentsCode.includes(x.title))
            .map(x => ({
              ...x,
              meta: storeUtils.getContentMetaObjectByCode(x.title)
              //meta: storeUtils.getContentMetaObjectByCode(x.launch_parameters.curriculum_code)
            }))
        }
      } else {
        draftState.studyTopic.record = null
      }
    })
  })
  .case(actions.setTrainerStudyAchievementsLatest, (state, payload) => {
    return produce(state, draftState => {
      draftState.trainerStudyAchievementsLatest ={records: payload.record} 
    })
  })  

