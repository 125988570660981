import React, { useEffect } from 'react';
import { Link, useParams, } from 'react-router-dom';

import PrivateHeader from 'containers/parts/PrivateHeader';
import { mappedProps } from 'containers/parent/StudentStudyResultUnit';
import SiteMain from 'containers/parts/SiteMain';
import StudentBar from '../parts/CommonStudentBar'
import classNames from 'classnames';

const ParentStudentStudyResultUnit: React.FC<mappedProps> = (props: mappedProps) => {
  // MARK: 講師、トレーナーとインターフェース統一のためダミーIDを指定
  const id = 1;
  const { unit_id } = useParams<{ unit_id: string; }>();

  useEffect(() => {
    if (id && unit_id) {
      props.fetchData(Number(id), Number(unit_id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, unit_id]);

  const student = props.getStudent(Number(id))
  const page = student && props.getRecord(Number(id), Number(unit_id))

  return (
    <React.Fragment>
      <PrivateHeader />
      <SiteMain>
        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          {
            page &&
            <React.Fragment>
              <li className="breadcrumbs__item">
                <Link to={`/student/study_result/${page.curriculum.id}`} className="breadcrumbs__link">
                  { page.curriculum.name }
                </Link>
              </li>
              <li className="breadcrumbs__item">
                <Link to={`/student/study_result/courses/${page.course.id}`} className="breadcrumbs__link">
                  { page.course.name }
                </Link>
              </li>
              <li className="breadcrumbs__item">
                { page.unit.name }
              </li>
            </React.Fragment>
          }

        </ol>

        {
          student && page &&
          <div className="site-main__main-contents">
            <StudentBar student={student} />

            <div className="page-title">
              { page.curriculum.name }
              &nbsp;
              <div className="page-title__badge">
                { page.course.name }
              </div>
              &nbsp;
              <br className="_sp" />{ page.unit.name }
            </div>

            <div className="topic-list">
              <div className="topic-list__head">
                <div className="topic-list__title">
                  “{ page.unit.name }”のトピック
                </div>
              </div>
              <div className="topic-list__body">
                {
                  page.topics.map((topic, index) => (
                    <div key={index} className="topic-list__item">
                      <span>
                        { topic.title }
                      </span>
                      {
                        topic.check_level &&
                        <div className="topic-list__phase">
                          AI理解度判定
                          <div className={classNames("topic-list__level", {  "topic-list__level--complete" : topic.check_level === 'コンプリート' })}>
                            { topic.check_level }
                          </div>
                        </div>
                      }
                      {
                        topic.status &&
                        <div className="topic-list__phase">
                          学習状況
                          <div className={classNames("topic-list__level", {  "topic-list__level--complete" : topic.status === 'complete' })}>
                            { topic.status === 'none' && '未学習' }
                            { topic.status === 'progress' && '学習中' }
                            { topic.status === 'complete' && '学習終了' }
                          </div>
                        </div>
                      }
                    </div>
                  ))
                }
              </div>
            </div>

            <div className="back-home">
              <Link to='/' className="back-home__link">マイページへ戻る</Link>
            </div>
          </div>
        }

        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          {
            page &&
            <React.Fragment>
              <li className="breadcrumbs__item">
                <Link to={`/student/study_result/${page.curriculum.id}`} className="breadcrumbs__link">
                  { page.curriculum.name }
                </Link>
              </li>
              <li className="breadcrumbs__item">
                <Link to={`/student/study_result/courses/${page.course.id}`} className="breadcrumbs__link">
                  { page.course.name }
                </Link>
              </li>
              <li className="breadcrumbs__item">
                { page.unit.name }
              </li>
            </React.Fragment>
          }
        </ol>
      </SiteMain>
    </React.Fragment>
  );
}

export default ParentStudentStudyResultUnit