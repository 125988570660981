import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import classNames from 'classnames';

import PrivateHeader from 'containers/parts/PrivateHeader';
import { mappedProps } from 'containers/instructor/Mypage';
import SiteMain from 'containers/parts/SiteMain';
import SectionInfoBox from 'components/parts/SectionInfoBox'

const Mypage: React.FC<mappedProps> = (props: mappedProps) => {
  const [visibleType, setVisibleType] = useState<'not-answered'|'processing'|'answered'>('not-answered');

  useEffect(() => {
    props.fetchData(visibleType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleType]);

  const [records, hasNext] = props.getQuestionRecordsByType(visibleType)

  const mypage = props.instructor.mypage

  return (
    <React.Fragment>
      <PrivateHeader />
      <SiteMain>
        <div className="site-main__main-contents">
          <SectionInfoBox infoRecords={mypage ? mypage.admin_informations : []} />

          <section className="box">
            <header className="box__head">
              <div className="box__title box__title--question">
                担当教科の質問
              </div>
            </header>
            <div className="box__body">
              <div className="question-list question-list--home">
                <div className="question-list__head">
                  <div
                    onClick={() => setVisibleType('not-answered')}
                    className={classNames("question-list__tab",
                      { 'is-active' : visibleType === 'not-answered' })}>
                    未対応
                  </div>
                  <div
                    onClick={() => setVisibleType('processing')}
                    className={classNames("question-list__tab",
                      { 'is-active' : visibleType === 'processing' })}>
                    対応中
                  </div>
                  <div
                    onClick={() => setVisibleType('answered')}
                    className={classNames("question-list__tab question-list__tab--past",
                      { 'is-active' : visibleType === 'answered' })}>
                    回答済み
                  </div>
                </div>
                <div className={`question-list__body is-${visibleType} is-active`}>
                  {
                    records && records.map((record, index) => (
                      <div key={index} className={`question-box question-box--${record.statusCode}`}>
                        <div className="question-box__inner question-box__inner--teacher">
                          <div className="question-box__question">
                            <div className="question-box__badge">
                              { record.statusText }
                            </div>
                            <div className="question-box__title">
                              <Link to={`/questions/${record.id}`} className="question-box__link">
                                { record.content.topic.title } > { record.content.meta!.label}
                              </Link>
                            </div>
                            <div className="question-box__spec">
                              <span className="question-box__name">
                                { record.student.nick_name }さん
                              </span>
                              <span className="question-box__datetime">
                                <Moment format="M月D日 HH:mm">{ record.created_at }</Moment>
                              </span>
                            </div>
                          </div>
                          <Link to={`/students/${record.student.id}/questions`}
                            className="question-box__button">
                            受講者情報
                          </Link>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
            {
              hasNext && 
              <div className="more-button">
                <div
                  onClick={() => props.fetchNext(visibleType) }
                  className="more-button__text">
                  次のトピックを表示
                </div>
              </div>
            }
          </section>

        </div>
      </SiteMain>
    </React.Fragment>
  );
}

export default Mypage
