import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router'
import { reduxForm, InjectedFormProps, SubmissionError, FormErrors, formValueSelector } from 'redux-form'

import { AppState } from "store";
import SettingWithdrawal from 'components/student/SettingWithdrawal';
import userActions, { withdrawPayload } from 'actions/userActions';
import * as Models from 'models/api'
import {
  getEnqueteItemIndex,
  withdrawalEnquetes,
  Enquete as WithdrawalEnquete
} from 'utils/withdrawalEnquetes'
import { get, forEach } from 'lodash'

export const formKey = 'setting_withdrawal'

type FormData = {
  check?: boolean;
  answers?: any;
  [key: string]: any
};

const isEnqueteRequired = (values: FormData, e: WithdrawalEnquete) => {
  values = values || {}
  const key = e.code;
  if (e.required) {
    if (e.type === 'checkbox') {
      const ans = values.answers ? values.answers[key] : {}
      let isChecked = false;
      for (const i in ans) {
        isChecked = isChecked || ans[i];
      }
      return !isChecked;
    } else {
      return !values.answers || !values.answers[key] || !values.answers[key].item;
    }
  }
  return false;
}

export const FormValidate = (values: FormData) => {
  const errors: FormErrors<FormData> = {}
  if (!values.check) {
    errors.check = 'Required'
  }
  withdrawalEnquetes.forEach((e) => {
    if (isEnqueteRequired(values, e)) {
      const key = e.code;
      errors[key] = 'Required'
    }
  })

  return errors
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onSubmit: (values: FormData) => {
      const result: any = {}
      withdrawalEnquetes.filter(x => x.type === 'textarea').forEach(x => {
        result[x.code] = get(values, `answers.${x.code}.item`, '')
      })
      withdrawalEnquetes.filter(x => x.type === 'radio').forEach(x => {
        const tmp = get(values, `answers.${x.code}`, {})
        forEach(tmp, (value, key) => {
          if (key === 'item') {
            result[x.code] = getEnqueteItemIndex(
              x.code,
              value,
            )
          } else {
            result[`${x.code}_other_text`] = value
          }
        })
      })
      withdrawalEnquetes.filter(x => x.type === 'checkbox').forEach(x => {
        const itemChecks = x.items!.map(x => false)
        const tmp = get(values, `answers.${x.code}`, {})
        forEach(tmp, (value, key) => {
          if (key.startsWith('item-')) {
            const index = Number(key.split('-')[1])
            itemChecks[index] = true
          } else {
            result[`${x.code}_other_text`] = value
          }
        })
        result[x.code] = itemChecks
      })

      const params: Models.StudentUnsubscribeParams = {
        unsubscribe_questionnaire: result
      }

      return new Promise<void>((resolve, reject) => {
        const payload: withdrawPayload = {
          params,
          promises: { resolve, reject }
        }
        dispatch(userActions.withdraw(payload))
      }).catch((e: Error) => {
        throw new SubmissionError({})
      })
    }
  }
}

const createEnqueteItems = (choices: string[], options: { hasEtc?: boolean, type: string } = { type: 'textarea' }) => {
  const newchoices: { title: string, type: string, hasText: boolean }[] = choices.map((c) => {
    return {
      title: c,
      type: options.type,
      hasText: false,
    }
  })
  if (options.hasEtc) {
    newchoices.push({
      title: 'その他',
      type: options.type,
      hasText: true
    })
  }
  return newchoices;
}

const enquetes = withdrawalEnquetes.map((e) => {
  return {
    ...e,
    items: createEnqueteItems(e.items || [''], { type: e.type, hasEtc: e.hasEtc })
  }
})

const mapStateToProps = (appState: AppState, routeProps: RouteComponentProps) => {
  const selector = formValueSelector(formKey);
  const user = appState.session.user! as Models.StudentUser
  const initialValues = {
  }
  return {
    user,
    enquetes,
    initialValues,
    ...routeProps,
    nextPage: '/withdrawal_complete',
    answers: selector(appState, 'answers') || {}
  }
}

type connectMappedProps = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>
export type mappedProps = connectMappedProps & InjectedFormProps<FormData, connectMappedProps>

const form = reduxForm<FormData, connectMappedProps>({
  form: formKey,
  validate: FormValidate
})(SettingWithdrawal)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(form))
