import React, { useState } from 'react';
import classNames from 'classnames';
import * as Models  from 'models/api';

type props = {
  user: Models.StudentUser
}

const TrainerProfileBalloon: React.FC<props> = (props: props) => {
  const [show, setShow] = useState(false)
  const trainer = props.user.trainer!
  return (
    <div
      onClick={() => {
        setShow(!show)
      }}
      className="page-title__link page-title__link--trainer">
      トレーナーの<br className="_sp" />プロフィール
      <div className={classNames("balloon balloon--trainer page-title__balloon", { 'is-active': show })}>
        <div className="balloon__title balloon__title--trainer">
          { trainer.nick_name }
        </div>
        <div className="balloon__body">
          <dl className="trainer-profile">
            <dt className="trainer-profile__title">
              在籍大学
            </dt>
            <dd className="trainer-profile__text">
            { trainer.university_name }
            </dd>
            <dt className="trainer-profile__title">
              在籍学部系統
            </dt>
            <dd className="trainer-profile__text">
              { trainer.department_type }
            </dd>
            <dt className="trainer-profile__title">
              合格大学
            </dt>
            <dd className="trainer-profile__text">
              { trainer.passed_university }
            </dd>
            <dt className="trainer-profile__title">
              出身県
            </dt>
            <dd className="trainer-profile__text">
              { trainer.prefecture_name }
            </dd>
            <dt className="trainer-profile__title">
              出身高校系統
            </dt>
            <dd className="trainer-profile__text">
              { trainer.graduated_high_school_type }
            </dd>
            <dt className="trainer-profile__title">
              高校時代部活
            </dt>
            <dd className="trainer-profile__text">
              { trainer.club_activity_name_in_high_school }
            </dd>
            <dt className="trainer-profile__title">
              入試利用科目
            </dt>
            <dd className="trainer-profile__text">
              { trainer.examination_subject }
            </dd>
          </dl>
        </div>
      </div>
    </div>
  );
}

export default TrainerProfileBalloon
