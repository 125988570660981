import React, { useEffect, useState } from 'react';
import { Link, useParams, } from 'react-router-dom';

import PrivateHeader from 'containers/parts/PrivateHeader';
import { mappedProps } from 'containers/student/StudyCourse';
import SiteMain from 'containers/parts/SiteMain';
import classNames from 'classnames';
import CircleChart from 'components/parts/CircleChart';
import { CourseCompletionTestBox } from './study_course_result/CourseCompletionTestBox';
import CorrectAnswerCount from './common/CorrectAnswerCount';

const StudyCourse: React.FC<mappedProps> = (props: mappedProps) => {
  const { course_id } = useParams<{ course_id: string }>();
  const [showHint, setShowHint] = useState(false);
  const [disabledStartStudy, setDisabledStartStudy] = useState<boolean>(false);
  const hideCourseCompletionTestBox: number[] = [13, 14, 15, 16] // issue-639 
  
  useEffect(() => {
    if (course_id) {
      props.fetchData(Number(course_id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course_id]);

  useEffect(() => {
    props.fetchStudyAchievementsLatest();
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  , []);

  const page = props.getRecord(Number(course_id))
  const testRecord = Number(course_id) && props.getCompletionTest(Number(course_id))
  const needHelp = page && !page.curriculum.al_engine_connected

  const startStudy = ({ type, unit_id, content_id, }: { content_id: number, unit_id: number, type?: 'completion_test'; }) => {
    if (disabledStartStudy) return

    let return_pathname = `/study/courses/${course_id}`
    if (type === 'completion_test') {
      // MARK: 科目終了テストの場合は科目修了テスト結果画面に遷移
      return_pathname = `/study/course_complete_results/${unit_id}`
    }

    setDisabledStartStudy(true)
    props.startStudy({ content_id, return_pathname }).then((url) => {
      setDisabledStartStudy(false)
      window.location.href = url
    })
  }

  return (
    <React.Fragment>
      <PrivateHeader />
      <SiteMain>
        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          {
            page && 
            <React.Fragment>
              <li className="breadcrumbs__item">
                <Link to={`/study/${page.curriculum.id}`} className="breadcrumbs__link">
                  { page.curriculum.name }
                </Link>
              </li>
              <li className="breadcrumbs__item">
                { page.course.name }
              </li>
            </React.Fragment>
          }
        </ol>

        {
          page &&
          <div className="site-main__main-contents">
            <div className="page-title page-title--study">
              <div className="page-title__left">
                { page.curriculum.name }&nbsp;
                <div className="page-title__badge">
                { page.course.name }
                </div>
              </div>
              <div className={'page-title__correct-count'}>
                {props.student.studyAchievementsLatest.record && <>
                 <CorrectAnswerCount correctAnswerCount={props.student.studyAchievementsLatest.record.study_achievements.consecutive_correct_answer_count}/>
                </>}
              </div>
            </div>

            {
              !hideCourseCompletionTestBox.includes(page.curriculum.id) && testRecord &&
              <CourseCompletionTestBox
                curriculumObj={page.curriculum}
                testRecord={testRecord}
                disabledStartStudy={disabledStartStudy}
                startStudy={startStudy} />
            }

            <div className="subject-chart-list">
              <div className="subject-chart-list__head">
                <div className="subject-chart-list__title">
                  {
                    page.curriculum.al_engine_connected ? '単元（コンプリート率）': '単元（教材学習終了率）'
                  }
                </div>
                {
                  needHelp && 
                  <div className="subject-chart-list__head-note">
                    <div
                      onClick={() => setShowHint(!showHint)}
                      className="">
                      教材学習終了率とは？
                    </div>
                    <div
                      className={classNames("balloon subject-chart-list__balloon", { 'is-active': showHint })}>
                      <div
                        onClick={() => setShowHint(false)}
                        className="balloon__close">
                        説明を閉じる
                      </div>
                      <div className="balloon__title">
                        教材学習終了率とは？
                      </div>
                      <div className="balloon__lead">
                        各単元に設定されている教材のうち、学習を済ませた教材数の割合を算出しています。
                      </div>
                    </div>
                  </div>
                }
              </div>

              <div className="subject-chart-list__items">
                {
                  page.units.map((unit, index) => (
                    <div key={index} className="subject-chart-list__item">
                      <div className="subject-chart-list__chart">
                        <CircleChart rate={unit.understanding / 100} colorType={page.curriculum.al_engine_connected ? 1 : 2} />
                      </div>
                      <div className="subject-chart-list__subject">
                        <Link
                          to={`/study/units/${unit.id}`}
                          className="subject-chart-list__subject-link">
                          { unit.name }
                        </Link>
                      </div>

                      {/* <div className="subject-chart-list__buttons">
                        <span className={classNames("button button--short button--no-chevron button--no-hover button--center subject-chart-list__button", { "is-disabled" : !unit.pretest })}>
                          受講前<br className="_pc" />テスト
                        </span>
                        <span className={classNames("button button--short button--no-chevron button--no-hover button--center subject-chart-list__button", { "is-disabled" : !unit.posttest })}>
                          受講後<br className="_pc" />テスト
                        </span>
                        {
                          unit.posttest && unit.pretest && 
                          <Link
                            to={`/study/unit_results/${unit.id}`}
                            className="button button--short button--primary subject-chart-list__button subject-chart-list__button--results">
                            結果<br className="_pc" />
                            比較
                          </Link>
                        }
                      </div> */}
                    </div>
                  ))
                }
              </div>

              {
                // MARK: 教科：数学、科目：数A 
                page.course.id === 3 &&
                <div style={{
                  marginTop: 16,
                  textAlign: 'right',
                  color: '#666',
                  fontSize: 14,
                }}>
                  「整数」単元は新課程では数学Ａ「整数の性質」「数学と人間の活動」に移行されましたが、<br />
                  入試では問われる可能性があるため、<br className='_sp' />学習をすませておくことをおすすめします。
                </div>
              }
              {
                // MARK: 教科：数学、科目：数B 
                page.course.id === 5 &&
                <div style={{
                  marginTop: 16,
                  textAlign: 'right',
                  color: '#666',
                  fontSize: 14,
                }}>
                  新課程の「統計的な推測」は、「教科・単元選択学習」＞「新課程数学」＞「新課程　数学Ｂ」<br />から学習を始めてください。<br className='_sp' />
                </div>
              }
              {
                // MARK: 教科：新課程数学、科目：新課程　数学Ｂ
                page.course.id === 28 &&
                <div style={{
                  marginTop: 16,
                  textAlign: 'right',
                  color: '#666',
                  fontSize: 14,
                }}>
                「新課程　統計的な推測」の学習を終えたら、「教科・単元選択学習」＞「数学」に戻り、<br />必要な科目を学習してください。<br className='_sp' />
                </div>
              }
            </div>

            <div className="back-home">
              <Link to='/' className="back-home__link">マイページへ戻る</Link>
            </div>
          </div>
        }

        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          {
            page && 
            <React.Fragment>
              <li className="breadcrumbs__item">
                <Link to={`/study/${page.curriculum.id}`} className="breadcrumbs__link">
                  { page.curriculum.name }
                </Link>
              </li>
              <li className="breadcrumbs__item">
                { page.course.name }
              </li>
            </React.Fragment>
          }
        </ol>
      </SiteMain>
    </React.Fragment>
  );
}

export default StudyCourse
