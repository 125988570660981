import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import PrivateHeader from 'containers/parts/PrivateHeader';
import { mappedProps } from 'containers/student/Schedules';
import SiteMain from 'containers/parts/SiteMain';

const StudentSchedules: React.FC<mappedProps> = (props: mappedProps) => {
  useEffect(() => {
    props.fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const records = props.student.schedule.records
  const hasNext = props.student.schedule.has_next

  return (
    <React.Fragment>
      <PrivateHeader />
      <SiteMain>
        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          <li className="breadcrumbs__item">
            スケジュール
          </li>
        </ol>

        <div className="site-main__main-contents">
          <div className="page-title page-title--schedule">
            スケジュール
          </div>
          <ul className="schedule-list">
            {
              records && records.map((record, index) => (
                <li key={index} className="schedule-list__item">
                  <div className="schedule-list__date">
                    { record.date }〜
                  </div>
                  <div className="schedule-list__text">
                    { record.body }
                  </div>
                </li>
              ))
            }
          </ul>
          {
            hasNext && 
            <div className="more-button">
              <div
                onClick={(e) => {
                  e.preventDefault()
                  props.fetchNext()
                }}
                className="more-button__text">
                次の10件を表示
              </div>
            </div>
          }
          <div className="back-home">
            <Link to='/' className="back-home__link">マイページへ戻る</Link>
          </div>
        </div>

        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          <li className="breadcrumbs__item">
            スケジュール
          </li>
        </ol>
      </SiteMain>
    </React.Fragment>
  );
}

export default StudentSchedules
