import React from 'react';
import classNames from 'classnames';

type ownProps = {
  step: Number;
}

const SignupBoxSteps: React.FC<ownProps> = (props: ownProps) => {
  const step = props.step;
  return (
    <div className={classNames("register-box__steps", { 'register-box__steps--step1' : step === 1 })}>
      <ol className="register-steps">
        <li className={classNames("register-steps__step register-steps__step--1", { 'is-active' : step === 1 })}>
          プラン選択・情報入力
        </li>
        {/* <li className={classNames("register-steps__step register-steps__step--2", { 'is-active' : step === 2 })}>
          受講者<br className="_sp" />情報
        </li> */}
        <li className={classNames("register-steps__step register-steps__step--3", { 'is-active' : step === 3 })}>
          入力情報確認
        </li>
        {/* <li className={classNames("register-steps__step register-steps__step--4", { 'is-active' : step === 4 })}>
          ご本人<br className="_sp" />確認
        </li> */}
        <li className={classNames("register-steps__step register-steps__step--5", { 'is-active' : step === 5 })}>
          申し込み完了
        </li>
      </ol>
    </div>
  );
}

export default SignupBoxSteps;
