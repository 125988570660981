import { call, put, select, takeEvery } from 'redux-saga/effects'

import * as Models from 'models/api'
import * as apiService from 'services/api'
import uiActions from 'actions/uiActions'
import { AppState } from 'store';
import actions from 'actions/studyResultActions'
import { AxiosResponse } from 'axios';

function* fetchStudyCoursesIfEmpty(student_id: number, curriculum_id: number) {
  const state: AppState = yield select()
  const x = state.studyResult.studyCurriculum
  const ui = state.ui.type! as Models.LoginableUIType

  if (x.curriculum_id !== curriculum_id || x.student_id !== student_id) {
    const response: apiService.getOwnStudentStudyCurriculumResponse = yield call(
      apiService.getOwnStudentStudyCurriculum,
      yield select(x => x.repository.authorizedApi!),
      {
        ui,
        curriculum_id,
        student_id,
      });
    const record = response.data
    yield put(actions.setStudyCurriculum({ record, curriculum_id, student_id }));
  }
}

export function* fetchStudent(action: ReturnType<typeof actions.fetchStudent>) {
  const state: AppState = yield select()
  const student_id = action.payload.student_id
  const ui = state.ui.type! as Models.LoginableUIType
  try {

    if (ui === 'parent') {
      // MARK: parentの場合はログイン時に受講生情報を取得しているのでAPI実行不要
      const parentStudent =state.parent.student!
      yield put(actions.setStudent({ record: parentStudent, student_id }));
      return
    }

    const response: apiService.getOwnStudentResponse = yield call(
      apiService.getOwnStudent,
      yield select(x => x.repository.authorizedApi!), { ui, student_id });
    const record = response.data
    yield put(actions.setStudent({ record, student_id }));
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

export function* fetchStudyCurriculums(action: ReturnType<typeof actions.fetchStudyCurriculums>) {
  const state: AppState = yield select()
  try {
    const ui = state.ui.type! as Models.LoginableUIType
    const student_id = action.payload.student_id
    const response: apiService.getOwnStudentStudyCurriculumsResponse = yield call(
      apiService.getOwnStudentStudyCurriculums,
      yield select(x => x.repository.authorizedApi!), { ui, student_id });
    const record = response.data
    yield put(actions.setStudyCurriculums({ record, student_id }));
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

export function* fetchStudyCurriculum(action: ReturnType<typeof actions.fetchStudyCurriculum>) {
  const state: AppState = yield select()
  try {
    const ui = state.ui.type! as Models.LoginableUIType
    const curriculum_id = action.payload.curriculum_id
    const student_id = action.payload.student_id
    const response: apiService.getOwnStudentStudyCurriculumResponse = yield call(
      apiService.getOwnStudentStudyCurriculum,
      yield select(x => x.repository.authorizedApi!), { ui, student_id, curriculum_id });
    const record = response.data
    yield put(actions.setStudyCurriculum({ record, curriculum_id, student_id }));
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

export function* fetchStudyCourse(action: ReturnType<typeof actions.fetchStudyCourse>) {
  try {
    let state: AppState = yield select()
    const ui = state.ui.type! as Models.LoginableUIType
    const course_id = action.payload.course_id
    const student_id = action.payload.student_id

    const params = {
      course_id,
      student_id,
      ui,
    }
    const response: apiService.getOwnStudentStudyCourseResponse = yield call(
      apiService.getOwnStudentStudyCourse,
      yield select(x => x.repository.authorizedApi!),
      params);
    const record = response.data
    yield put(actions.setStudyCourse({ record, course_id, student_id }));

    const curriculum_id = record.curriculum.id

    // MARK: 教科の科目情報を取得する
    yield fetchStudyCoursesIfEmpty(student_id, curriculum_id)

    state = yield select()
    const courses = state.studyResult.studyCurriculum.record!.courses
    const course = courses.find(x => x.id === course_id)
    if (!course) {
      throw new Error('invalid course')
    }

    if (course.completion_test) {
      // MARK: 科目修了テスト情報を取得
      const response: AxiosResponse<Models.StudyCourseCompletionTestsRecord> = yield call(
        apiService.getOwnStudentStudyCourseCompletionTests,
        yield select(x => x.repository.authorizedApi!),
        { course_id, ui, student_id });
      const record = response.data
      if (record.results) {
        yield put(actions.setStudyCourseCompletionTests({ student_id, course_id, record }));
      } else {
        yield put(actions.setStudyCourseCompletionTests({ student_id, course_id, record: null }));
      }
    }

  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

export function* fetchStudyCourseCompletionTestResult(action: ReturnType<typeof actions.fetchStudyCourseCompletionTestResult>) {
  const state: AppState = yield select()
  try {
    const ui = state.ui.type! as Models.LoginableUIType
    const student_id = action.payload.student_id
    const unit_id = action.payload.unit_id

    const page = 1;
    let per_page: number | undefined = 2;
    if (!action.payload.initialize) {
      // MARK: per_page 未指定で全件取得
      per_page = undefined
    }

    // MARK: 科目修了テスト結果を取得
    const response: AxiosResponse<Models.StudyCourseCompletionTestResultsRecord> = yield call(
      apiService.getOwnStudentStudyCourseCompletionTestResults,
      yield select(x => x.repository.authorizedApi!),
      { unit_id, page, per_page, ui, student_id });

    const record = response.data

    if (action.payload.initialize) {
      // MARK: 以前の結果表示用データをリセット
      yield put(actions.setStudyCourseCompletionTestResultsMore({ record: null }));
      yield put(actions.setStudyCourseCompletionTestResults({ student_id, unit_id, record }));
    } else {
      // MARK: もっと見る
      yield put(actions.setStudyCourseCompletionTestResultsMore({ record }));
    }
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

export function* fetchStudyUnit(action: ReturnType<typeof actions.fetchStudyUnit>) {
  const state: AppState = yield select()
  try {
    const ui = state.ui.type! as Models.LoginableUIType
    const unit_id = action.payload.unit_id
    const student_id = action.payload.student_id
    const params = {
      unit_id,
      student_id,
      ui,
    }

    const response: apiService.getOwnStudentStudyUnitResponse = yield call(
      apiService.getOwnStudentStudyUnit,
      yield select(x => x.repository.authorizedApi!),
      params);
    const record = response.data
    yield put(actions.setStudyUnit({ record, unit_id, student_id }));
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

export function* fetchStudyUnitResult(action: ReturnType<typeof actions.fetchStudyUnitResult>) {
  const state: AppState = yield select()
  try {
    const ui = state.ui.type! as Models.LoginableUIType
    const unit_id = action.payload.unit_id
    const student_id = action.payload.student_id
    const params = {
      unit_id,
      student_id,
      ui,
    }

    const response: apiService.getOwnStudentStudyUnitResultResponse =  yield call(
      apiService.getOwnStudentStudyUnitResult,
      yield select(x => x.repository.authorizedApi!),
      params);

    const record = response.data

    yield put(actions.setStudyUnitResult({ record, unit_id, student_id }));
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

export function* fetchStudyTopic(action: ReturnType<typeof actions.fetchStudyTopic>) {
  const state: AppState = yield select()
  try {
    const ui = state.ui.type! as Models.LoginableUIType
    const topic_id = action.payload.topic_id
    const student_id = action.payload.student_id
    const params = {
      topic_id,
      student_id,
      ui,
    }

    const response: apiService.getOwnStudentStudyTopicResponse = yield call(
      apiService.getOwnStudentStudyTopic,
      yield select(x => x.repository.authorizedApi!),
      params);
    const record = response.data
    yield put(actions.setStudyTopic({ record, topic_id, student_id }));
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

export function* fetchStudyLogRecords(action: ReturnType<typeof actions.fetchStudyLogRecords>) {
  const state: AppState = yield select()
  try {
    const student_id = action.payload.student_id
    const curriculum_id = action.payload.curriculum_id
    const per_page = 10
    let page = state.studyResult.studyLog.page + 1
    if (action.payload.initialize) {
      page = 1
    }
    const ui = state.ui.type! as Models.LoginableUIType
    const params = {
      page,
      per_page,
      student_id,
      curriculum_id,
      ui,
    }
    const response: apiService.getOwnStudentStudyLogRecordsResponse = yield call(
      apiService.getOwnStudentStudyLogRecords,
      yield select(x => x.repository.authorizedApi!), params);
    const records = response.data
    yield put(actions.setStudyLogRecords({ student_id, curriculum_id, page, records }));
  } catch (e) {
    console.error(e);
    yield put(uiActions.showApiErrorNotification(e));
  }
}

function* saga() {
  yield takeEvery(actions.fetchStudent, fetchStudent);
  yield takeEvery(actions.fetchStudyCurriculums, fetchStudyCurriculums);
  yield takeEvery(actions.fetchStudyCurriculum, fetchStudyCurriculum);
  yield takeEvery(actions.fetchStudyCourse, fetchStudyCourse);
  yield takeEvery(actions.fetchStudyUnit, fetchStudyUnit);
  yield takeEvery(actions.fetchStudyUnitResult, fetchStudyUnitResult);
  yield takeEvery(actions.fetchStudyTopic, fetchStudyTopic);
  yield takeEvery(actions.fetchStudyLogRecords, fetchStudyLogRecords);
  yield takeEvery(actions.fetchStudyCourseCompletionTestResult, fetchStudyCourseCompletionTestResult);
}

export default saga;
