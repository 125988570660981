import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RouteProps } from 'react-router'
import { reduxForm, InjectedFormProps, SubmissionError } from 'redux-form'

import FixProvisionalPassword from 'components/common/FixProvisionalPassword';
import { AppState } from "store";
import storeUtils from 'utils/store'
import userActions from 'actions/userActions'

interface FormData {
  password?: string;
  passwordConfirmation?: string;
  [key: string]: string|undefined;
}

export const FormValidate = (values: FormData) => {
  const errors: FormData = {}
  const requiredFields: string[] = [
    'password',
    'passwordConfirmation',
  ]

  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = '入力してください'
    }
  })

  if (values.password !== values.passwordConfirmation) {
    errors.passwordConfirmation = 'パスワードが一致しません。再度入力してください。'
  }

  return errors
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onSubmit: (values: FormData) => {
      return new Promise<void>((resolve, reject) => {
        dispatch(userActions.changePassword({
          params: {
            password: values.password!,
          },
          promises: { resolve, reject }
        }))
      }).catch((e: Error) => {
        throw new SubmissionError({})
      })
    }
  };
}

const mapStateToProps = (appState: AppState, ownProps: RouteProps) => {
  const isAuthenticated = appState.session.user !== null
  const alreadyFixed = !storeUtils.shouldFixProvisionalPassword(
      appState.ui, appState.session.user);
  const nextPage = ownProps.location && ownProps.location.state ?
    ownProps.location.state.from.pathname : '/';
  return {
    initialValues: {},
    isAuthenticated,
    alreadyFixed,
    nextPage,
  }
}

type connectMappedProps = RouteProps & ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>
export type mappedProps = connectMappedProps & InjectedFormProps<FormData, connectMappedProps>

const form = reduxForm<FormData, connectMappedProps>({
  form: 'fix_provisional_password',
  validate: FormValidate
})(FixProvisionalPassword)

export default connect(mapStateToProps, mapDispatchToProps)(form)
