import actionCreatorFactory from 'typescript-fsa';
import * as Models from "models/api";

const actionCreator = actionCreatorFactory('trainer');

const actions = {
  updateProfile:
    actionCreator<{ 
      params: Models.UpdateTrainerProfileParams;
      promises?: { resolve: () => void, reject: (e: Error) => void}
    }>('UPDATE_PROFILE'),
  fetchMypage:
    actionCreator<void>(
      'FETCH_MYPAGE'),
  setMypage:
    actionCreator<{ record: Models.TrainerMypage }>(
      'SET_MYPAGE'),
  fetchStudyStatus:
    actionCreator<{
      params?: Models.FetchStudyStatusParams | null;
      promises?: {resolve: () => void, reject: (e: Error) => void }
    }>(
      'FETCH_STUDY_STATUS'),
  setStudyStatus:
    actionCreator<{ records: Models.StudyStatus }>(
      'SET_STUDY_STATUS'),
  fetchStudyStatusRealtime:
    actionCreator<{
      params?: Models.FetchStudyStatusRealtimeParams | null;
      promises?: {resolve: () => void, reject: (e: Error) => void }
    }>(
      'FETCH_STUDY_STATUS_REALTIME'),
  stopFetchStudyStatusRealtime:
    actionCreator(
      'STOP_FETCH_STUDY_STATUS_REALTIME'),
  setStudyStatusRealtime:
    actionCreator<{ records: Models.StudyStatusRealtime }>(
      'SET_STUDY_STATUS_REALTIME'),        
  fetchScheduleRecords:
    actionCreator<{ student_id: number; initialize: boolean; }>(
      'FETCH_SCHEDULE_RECORDS'),
  setScheduleRecords:
    actionCreator<{ student_id: number; page: number; records: Models.ScheduleRecords }>(
      'SET_SCHEDULE_RECORDS'),
  fetchMessageRecords:
    actionCreator<{ student_id: number; }>(
      'FETCH_MESSAGE_RECORDS'),
  setMessageRecords:
    actionCreator<{ student_id: number; records: Models.StudentMessageRecords }>(
      'SET_MESSAGE_RECORDS'),
  fetchStudent:
    actionCreator<{ student_id: number; }>(
      'FETCH_STUDENT'),
  fetchStudentNextStudyTimeStats:
    actionCreator<void>(
      'FETCH_STUDENT_NEXT_STUDY_TIME_STATS'),
  setStudent:
    actionCreator<{ student_id: number; record: Models.StudentUser }>(
      'SET_STUDENT'),
  setStudyTimeStats:
    actionCreator<{ student_id: number; records: Models.StudyAchivementRecord[]; has_next: boolean; }>(
      'SET_STUDY_TIME_STATS'),
  pushStudyTimeStats:
    actionCreator<{ records: Models.StudyAchivementRecord[]; has_next: boolean; }>(
      'PUSH_STUDY_TIME_STATS'),

  fetchCsvExportHistories:
    actionCreator<{ initialize: boolean; }>(
      'FETCH_CSV_EXPORT_HISTORIES'),
  setCsvExportHistories:
    actionCreator<{ page: number; record: Models.CsvExportHistoriesRecord }>(
      'SET_CSV_EXPORT_HISTORIES'),
  createCsvExport:
    actionCreator<{ 
      params: {
        type: 'study_logs' | 'study_results' | 'pre_post_test_logs',
        curriculum_id: number;
      };
      promises?: { resolve: () => void, reject: (e: Error) => void}
    }>('CREATE_CSV_EXPORT'),
  fetchSchoolClasses:
    actionCreator<void>(
      'FETCH_SCHOOL_CLASSES'),
  setSchoolClasses:
    actionCreator<{ records: Models.SchoolClassRecord[] }>(
      'SET_SCHOOL_CLASS_RECORDS'),
  fetchCurriculumCourses:
    actionCreator<{ curriculum_id: number; }>(
      'FETCH_CURRICULUM_COURSES'),
  setCurriculumCourses:
    actionCreator<{ curriculum_id: number; records: { id: number; title: string; }[]; }>(
      'SET_CURRICULUM_COURSES'),
  fetchStudyCourse:
    actionCreator<{ course_id: number; }>(
      'FETCH_STUDY_COURSE'),
  setStudyCourse:
    actionCreator<{ course_id: number; record: Models.StudentStudyCourse | null }>(
      'SET_STUDY_COURSE'),
  fetchStudyUnit:
      actionCreator<{ unit_id: number; }>(
        'FETCH_STUDY_UNIT'),
  setStudyUnit:
      actionCreator<{ unit_id: number; record: Models.StudentStudyUnit | null }>(
        'SET_STUDY_UNIT'),      
  fetchStudyTopic:
    actionCreator<{ topic_id: number; }>(
      'FETCH_STUDY_TOPIC'),
  setStudyTopic:
    actionCreator<{ topic_id: number; record: Models.StudentStudyTopic | null }>(
      'SET_STUDY_TOPIC'),
  downloadAllTopicsPdf:
    actionCreator<{ all_topic_ids: number[]; }>(
      'FETCH_ALL_TOPICS_PDF'),
  fetchPdfDownload:
      actionCreator<{
        pdf_curriculums: string; 
        promises?: { resolve: (record:{url : string}) => void, reject: (e: Error) => void}
      }>(
        'FETCH_PDF_DOWNLOAD'),      
  fetchStudySummariesPerCurriculum:
    actionCreator<{ initialize: boolean; school_class_id?: number }>(
      'FETCH_STUDY_SUMMARIES_PER_CURRICULUM'),
  setStudySummariesPerCurriculum:
    actionCreator<{ page: number; records: Models.StudentStudySummaryPerCurriculumRecords }>(
      'SET_STUDY_SUMMARIES_PER_CURRICULUM'),

  fetchStudySummariesPerUnit:
    actionCreator<{ initialize: boolean; course_id: number; school_class_id?: number }>(
      'FETCH_STUDY_SUMMARIES_PER_UNIT'),
  setStudySummariesPerUnit:
    actionCreator<{ course_id: number; page: number; records: Models.StudentStudySummaryPerUnitRecords }>(
      'SET_STUDY_SUMMARIES_PER_UNIT'),
  // issue-547    
  fetchStudentBookmarks:
      actionCreator<{  
        params: Models.StudentBookmarksParams;
        promises?: { resolve: (data : Models.BookmarksResponse)=> void , reject: (e: Error) => void}
       }>(
        'FETCH_STUDENT_BOOKMARKS'),
  setStudentBookmarks:
      actionCreator<{ params: Models.Bookmarks | null }>(
        'SET_STUDENT_BOOKMARKS'),
  fetchStudentBookmarksLearningCount:
      actionCreator<{ 
        params: { curriculumCodes:Models.StudentStudyLearningCountParams, studentId: number}
        promises?: { resolve: (data : Models.StudentStudyLearningCountRecords)=> void , reject: (e: Error) => void}
       }>(
        'FETCH_STUDENT_BOOKMARKS_LEARNING_COUNT'),
  setStudentBookmarksLearningCount:
      actionCreator<{ params: Models.StudentBookmarksLearningCount | null}>(
        'SET_STUDENT_BOOKMARKS_LEARNING_COUNT'),
  startStudy:
    actionCreator<{ 
      params: { content_id: number; return_pathname: string };
      promises?: { resolve: (url: string) => void, reject: (e: Error) => void}
    }>('START_STUDY'),
  fetchTrainerStudyAchievementsLatest:
    actionCreator<{
      params: Models.FetchTrainerStudyAchievementsLatestParams;
      promises?: { resolve: (data : Models.TrainerStudyAchievementsLatest)=> void , reject: (e: Error) => void}
    }>(
      'FETCH_TRAINER_STUDY_ACHIEVEMENTS_LATEST'),      
  setTrainerStudyAchievementsLatest:
    actionCreator<{ record: Models.TrainerStudyAchievementsLatest | null}>(
      'SET_TRAINER_STUDY_ACHIEVEMENTS_LATEST'),
};

export default actions;