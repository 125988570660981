import React, { useEffect } from 'react';
import { Link, useParams, } from 'react-router-dom';
import { mappedProps } from 'containers/trainer/StudentStudyResultCourse';
import CircleChart from '../parts/CircleChart'
import { CourseCompletionTestBox } from 'components/common/course_completion_test/CourseCompletionTestBox';

const TrainerStudentStudyResultCourse: React.FC<mappedProps> = (props: mappedProps) => {
  const { id, course_id } = useParams<{ id: string; course_id: string; }>();

  useEffect(() => {
    if (id && course_id) {
      props.fetchData(Number(id), Number(course_id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, course_id]);

  const student = props.getStudent(Number(id))
  const page = student && props.getRecord(student.id, Number(course_id))
  const completionTestRecord = student && props.getCompletionTest(Number(id), Number(course_id))

  return (
    <React.Fragment>
      {
        student && page &&
        <>
          <div className="study-breadcrumbs">
            <Link to={`/students/${student.id}/study_result`} className="study-breadcrumbs__item">
              教科
            </Link>
            <Link to={`/students/${student.id}/study_result/${page.curriculum.id}`} className="study-breadcrumbs__item">
              科目
            </Link>
            <div className="study-breadcrumbs__item">
              単元
            </div>
          </div>

          <div className="page-title">
            { page.curriculum.name }
            &nbsp;
            <div className="page-title__badge">
              { page.course.name }
            </div>
          </div>

          {
            completionTestRecord &&
            <CourseCompletionTestBox
              testRecord={completionTestRecord}
              resultPagePath={`/students/${id}/study_result/course_complete_tests/${completionTestRecord.unit.id}`}
            />
          }

          <div className="subject-chart-list">
            <div className="subject-chart-list__title">
              単元
            </div>
            <div className="subject-chart-list__items">
              { page.units.map(unit => (
                <div key={unit.id} className="subject-chart-list__item">
                  <div className="subject-chart-list__chart">
                    <CircleChart rate={unit.understanding / 100} colorType={page.curriculum.al_engine_connected ? 1 : 2} />
                  </div>
                  <div className="subject-chart-list__subject">
                    <Link
                      to={`/students/${student.id}/study_result/units/${unit.id}`}
                      className="subject-chart-list__subject-link">
                      { unit.name }
                    </Link>
                  </div>
                  <div className="subject-chart-list__buttons subject-chart-list__buttons--teacher">
                    {
                      unit.posttest && unit.pretest ?
                      <Link
                        to={`/students/${student.id}/study_result/unit_results/${unit.id}`}
                        style={{ pointerEvents: 'all' }}
                        className="button button--short button--primary subject-chart-list__button subject-chart-list__button--large">
                        結果比較
                      </Link>
                      :
                      <span
                        className="button button--short button--primary subject-chart-list__button subject-chart-list__button--large is-disabled">
                        結果比較
                      </span>
                    }
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      }
    </React.Fragment>
  );
}

export default TrainerStudentStudyResultCourse