import React, { useEffect } from 'react';
import { Redirect, Link } from 'react-router-dom';
import classNames from 'classnames';
import Moment from 'react-moment';
import { Field } from 'redux-form'

import { mappedProps } from 'containers/student/SettingPointExchange';
import PrivateHeader from 'containers/parts/PrivateHeader';
import SideNav from 'containers/student/SettingPointExchangeNav';
import Help from './SettingPointExchangeHelp';
import Desc from './SettingPointExchangeDesc';

// import campaignBannerPC from 'assets/img/campaign/point/point_campaign_pc.jpg';
// import campaignBannerSP from 'assets/img/campaign/point/point_campaign_sp.jpg';

const commaFormat = (value: number) => {
  return value.toLocaleString()
}

const SettingPointExchange: React.FC<mappedProps> = (props: mappedProps) => {
  useEffect(() => {
    props.fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // MARK: コーポレートユーザ利用不可
  if (props.user.corporation) {
    return <Redirect to='/' />;
  }

  if (props.submitSucceeded) {
    return <Redirect to={props.nextPage} />;
  }

  return (
    <React.Fragment>
      <PrivateHeader />
      <main className="site-main site-main--setting">
        <div className="site-main__main">
          <div className="site-main__main-inner">
            <div className="site-main__main-contents">
              <div className="setting-box setting-box--point">
                <div className="setting-box__title setting-box__title--point">
                  ポイント交換について
                </div>
                <div className="setting-box__subtitle setting-box__subtitle--point">
                  河合塾Oneでは、貯めたポイントをプラン料金の割引や質問チケットの購入に利用することができます。<br />
                  「ポイントのご利用について」をよくお読みの上、交換対象を選択してください。
                </div>

                {/* <section className="campaign-box" style={{ marginTop: '24px' }}>
                  <picture>
                    <source media="(max-width: 767px)" srcSet={campaignBannerSP} />
                    <source media="(min-width: 768px)" srcSet={campaignBannerPC} />
                    <img src={campaignBannerPC} alt="" className="campaign-box__image" />
                  </picture>
                </section> */}

                <div className="setting-box__point">
                  <div className="point-box">
                    <Help />

                    <div className="point-box__head">
                      あなたの現在のポイント<strong>{ commaFormat(props.user.current_points) }pt</strong>
                    </div>
                    <div className="point-box__body">
                      {
                        props.plan && 
                        <dl className="point-box__list">
                          <dt className="point-box__list-head">
                            ご利用中のプラン
                          </dt>
                          <dd className="point-box__list-body">
                            { props.plan.name }
                          </dd>
                          <dt className="point-box__list-head">
                            次回お支払い金額
                          </dt>
                          <dd className="point-box__list-body">
                            { commaFormat(props.plan.price - props.plan.discount_points) }円（税込）
                            <small>プラン料金：{ commaFormat(props.plan.price) }円（税込）／割引ご利用ポイント：{ commaFormat(props.plan.discount_points) } pt</small>
                          </dd>
                          <dt className="point-box__list-head">
                            次回決済日
                          </dt>
                          <dd className="point-box__list-body">
                            <Moment format="YYYY年M月D日">{ props.plan.next_payment_on }</Moment>
                          </dd>
                        </dl>
                      }
                      <Link to='/setting/point_exchange_logs' className="point-box__button link-button link-button--point">ポイント交換履歴</Link>
                    </div>
                  </div>
                </div>

                <form onSubmit={props.handleSubmit}>
                  <Field component='input' type="hidden" name="item_ordered" />
                  <div className="setting-box__point-exchange">
                    <div className="point-exchange-box">
                      <div className="point-exchange-box__item">
                        <div className="point-exchange-box__head">
                          <div className="point-exchange-box__title">
                            プラン料金割引
                          </div>
                        </div>
                        <div className="point-exchange-box__rate">
                          <div className="point-exchange-box__rate-from">
                            500pt&nbsp;
                          </div>
                          <div className="point-exchange-box__rate-to">
                            500円分&nbsp;
                          </div>
                        </div>
                        <div className="point-exchange-box__note">
                          次回お支払い金額以内で交換可能です。
                        </div>

                        <Field
                          component='select'
                          className="point-exchange-box__select"
                          name="plan_discount_number_ordered"
                        >
                          <option value="">選択してください</option>
                          {
                            props.planDiscountExchangeOptions && props.planDiscountExchangeOptions.map(x => (
                              <option key={x.key} value={x.key}>
                                { x.val }
                              </option>
                            ))
                          }
                        </Field>

                        <div className="point-exchange-box__hold">
                          { commaFormat((props.planDiscountNumberOrdered || 0) * props.planDiscountExchangeRate) }
                          <small>円分</small>
                        </div>
                        <a
                          className={classNames("point-exchange-box__button", {
                            "is-disable": !props.planDiscountNumberOrdered,
                          })}
                          onClick={e => {
                            e.preventDefault()
                            props.change('item_ordered', '1')
                            if (!props.submitting) {
                              props.manualSubmit()
                            }
                          }}
                          href="#!"
                        >
                          交換する
                        </a>
                      </div>
                      <div className="point-exchange-box__item">
                        <div className="point-exchange-box__head">
                          <div className="point-exchange-box__title">
                            Amazonギフト券<small>（Eメールタイプ）</small>
                          </div>
                        </div>
                        <div className="point-exchange-box__rate">
                          <div className="point-exchange-box__rate-from">
                            500pt&nbsp;
                          </div>
                          <div className="point-exchange-box__rate-to">
                            300円分&nbsp;
                          </div>
                        </div>
                        <div className="point-exchange-box__note _pc">
                          &nbsp;
                        </div>

                        <Field
                          component='select'
                          className="point-exchange-box__select"
                          name="amazon_number_ordered"
                        >
                          <option value="">選択してください</option>
                          {
                            props.amazonGiftExchangeOptions && props.amazonGiftExchangeOptions.map(x => (
                              <option key={x.key} value={x.key}>
                                { x.val }
                              </option>
                            ))
                          }
                        </Field>

                        <div className="point-exchange-box__hold">
                          { commaFormat((props.amazonNumberOrdered || 0) * props.amazonGiftExchangeRate) }
                          <small>円分</small>
                        </div>

                        <a
                          className={classNames("point-exchange-box__button", {
                            "is-disable": !props.amazonNumberOrdered
                          })}
                          onClick={e => {
                            e.preventDefault()
                            props.change('item_ordered', '3')
                            if (!props.submitting) {
                              props.manualSubmit()
                            }
                          }}
                          href="#!"
                        >
                          交換する
                        </a>

                      </div>
                      <div className="point-exchange-box__item">
                        <div className="point-exchange-box__head">
                          <div className="point-exchange-box__title">
                            質問チケット
                          </div>
                        </div>
                        <div className="point-exchange-box__rate">
                          <div className="point-exchange-box__rate-from">
                            500pt&nbsp;
                          </div>
                          <div className="point-exchange-box__rate-to">
                            1枚&nbsp;
                          </div>
                        </div>
                        <div className="point-exchange-box__text">
                          追加で購入する質問チケット１枚と交換ができます。
                        </div>
                        <div className="point-exchange-box__note">
                          当月分の質問チケットが０枚の場合に、ポイントを使って質問チケットに交換することができます。質問は
                          <Link to='/study_logs' className="point-exchange-box__link">学習履歴</Link>から行ってください。
                        </div>
                      </div>
                    </div>
                  </div>

                </form>
                <div className="setting-box__note setting-box__note--point">
                  <strong>ご利用いただいたポイントの返還・交換内容の変更はできませんので、予めご了承ください。</strong>
                </div>

                <Desc />

                <div className="form">
                  <div className="form__buttons">
                    <div className="form__button">
                      <button 
                        onClick={(e) => {
                          e.preventDefault()
                          props.history.push('/')
                        }}
                        className="button button--gray button--back button--xlarge">マイページへ戻る</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SideNav />
      </main>
    </React.Fragment>
  );
}

export default SettingPointExchange;