import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router'

import { AppState } from "store";
import targetComponent from 'components/student/StudyCourse';
import studentActions from 'actions/studentActions';
import * as Models from 'models/api';

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch })

const mapStateToProps = (appState: AppState, ownProps: RouteComponentProps) => {
  return {
    user: appState.session.user!,
    student: appState.student,
    ...ownProps,
  }
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  { dispatch }: ReturnType<typeof mapDispatchToProps>) => {
  return {
    ...stateProps,
    fetchData: (course_id: number) => {
      dispatch(studentActions.fetchStudyCourse({ course_id }))
    },
    getRecord: (course_id: number) => {
      const page = stateProps.student.studyCourse
      if (page.course_id !== course_id) {
        return null
      }
      return page.record
    },
    getCompletionTest: (course_id: number) => {
      const page = stateProps.student.studyCourseCompletionTests
      if (page.course_id !== course_id || !page.record || !page.record.results) {
        return null
      }
      return page.record.results[0]
    },
    startStudy: (params: { content_id: number, return_pathname: string }) => {
      return new Promise<string>((resolve, reject) => {
        dispatch(studentActions.startStudy({
          params,
          promises: { resolve, reject }
        }))
      })
    },
    fetchStudyAchievementsLatest: () => {
      return new Promise<Models.StudyAchievementsLatest>((resolve, reject) => {
        dispatch(studentActions.fetchStudyAchievementsLatest(
          { promises: { resolve, reject } }
        ))
      })
    }
  }
}

export type mappedProps = ReturnType<typeof mergeProps>
export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(targetComponent))
