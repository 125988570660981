import React from 'react';

import { MasterData, StudentUser, MasterDataCurriculumLevel } from 'models/api';

import { renderSelectField, renderIfFieldsHasError } from 'components/parts/FormParts'
import { Field, Fields } from 'redux-form';

type props = {
  user: StudentUser;
  masterData: MasterData | null;
  submitFailed: boolean;
}

const CorporateStudyPlanForm: React.FC<props> = (props: props) => {
  const curriculums = props.user.visible_curriculums

  return <div className="setting-box__form">
    {/*
      MARK: https://github.com/gatjp/kawai1-front/issues/486
      対応不要になった
    */}
    {/* MARK: https://github.com/gatjp/kawai1-front/issues/482 */}
    {/* {
      isInvalidUser &&
      <div className="form__note" style={{ marginTop: 16 }}>
        法人受講範囲外の教科が受講設定されています。「変更する」から受講解除お願いします。<br />
        <span className='error-message'>
          ※受講解除するまで他の教科の受講設定を変更できません。
        </span>
      </div>
    } */}
    <div className="form">
      {
        curriculums.map(x => (
          <div key={x.id} className="form__item">
            <div className="form__label">
              {x.name}
            </div>
            <div className="form__field">
              <div className="select form__select-wrapper">
                <Field
                  component={renderSelectField}
                  className="select__select select__select--required"
                  name={`recommend_${x.code}`}>
                  {
                    // MARK: https://github.com/gatjp/kawai1-front/issues/492
                    // 教科内の科目の範囲は変更できるが、教科自体のON・OFFは受講者側からはできないように
                    props.masterData && (props.masterData as any)[`${x.code}_levels`].filter((x: MasterDataCurriculumLevel) => x.level !== null).map(
                      (x: MasterDataCurriculumLevel) => <option key={x.level || 0} value={x.level || 0}>{x.course_names}</option>)
                  }
                </Field>

                <Fields names={[ `recommend_${x.code}` ]} component={renderIfFieldsHasError}>
                  <div className="form__error">
                    <div className="error-message">
                      選択してください。
                    </div>
                  </div>
                </Fields>
              </div>
              {
                x.code === 'math' &&
                <div className="form__note">
                  IAの方はIA、IIBCの方はIAIIBを範囲としてください。
                </div>
              }
            </div>
          </div>
        ))
      }
    </div>
  </div>
}

export default CorporateStudyPlanForm;
