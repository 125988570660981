import actionCreatorFactory from 'typescript-fsa';

const actionCreator = actionCreatorFactory('temporaryForm');

const temporaryFormActions = {
  setForm: actionCreator<{ key: string; values: any }>('SET_FORM'),
  unsetForm: actionCreator<string>('UNSET_FORM'),
};

export default temporaryFormActions;
