import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router'
import { reduxForm, InjectedFormProps, FormErrors, SubmissionError } from 'redux-form'

import { AppState } from "store";
import TrainerSettingProfileEdit from 'components/trainer/SettingProfileEdit';
import validators from 'utils/validators'
import trainerActions from 'actions/trainerActions'

type FormData = {
  email?: string;
  email_confirmation?: string;
  tel?: string;
  [key: string]: any
};

export const formKey = 'setting_profile_edit'

export const FormValidate = (values: FormData) => {
  const errors: FormErrors<FormData> = {}
  const requiredFields: string[] = [
    'tel',
    'email',
    'email_confirmation',
  ]
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required'
    }
  })

  if (
    !values.email ||
    !validators.email(values.email) || !validators.length(values.email, { max: 254 })
    ) {
    errors.email = 'Invalid'
  }
  if (!values.email_confirmation || values.email_confirmation !== values.email) {
    errors.email_confirmation = 'Invalid'
  }

  if (
    !values.tel || !validators.phonenum(values.tel)
    ) {
    errors.tel = 'Invalid'
  }

  return errors
}

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch })

const mapStateToProps = (appState: AppState, routeProps: RouteComponentProps) => {
  const user = appState.session.user!
  const initialValues: FormData = {
    ...user,
    email_confirmation: user.email,
  }
  return {
    user,
    initialValues,
    ...routeProps,
  }
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  { dispatch }: ReturnType<typeof mapDispatchToProps>) => {
  return {
    ...stateProps,
    onSubmit: (values: FormData) => {
      return new Promise<void>((resolve, reject) => {
        const params = {
          email: values.email!,
          tel: values.tel!,
        }
        dispatch(trainerActions.updateProfile({ params, promises: { resolve, reject } }))
      }).catch((e: Error) => {
        throw new SubmissionError({})
      })
    }
  }
}

type connectMappedProps = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>
export type mappedProps = connectMappedProps & InjectedFormProps<FormData, connectMappedProps>

const form = reduxForm<FormData, connectMappedProps>({
  form: formKey,
  validate: FormValidate
})(TrainerSettingProfileEdit)

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(form))
