import React, { useEffect } from 'react';
import { Link, useParams, } from 'react-router-dom';

import PrivateHeader from 'containers/parts/PrivateHeader';
import { mappedProps } from 'containers/instructor/StudentStudyResultUnit';
import SiteMain from 'containers/parts/SiteMain';
import StudentBar from '../parts/CommonStudentBar'
import StudentNav from '../parts/InstructorStudentNav';
import classNames from 'classnames';

const TrainerStudentStudyResultUnit: React.FC<mappedProps> = (props: mappedProps) => {
  const { id, unit_id } = useParams();

  useEffect(() => {
    if (id && unit_id) {
      props.fetchData(Number(id), Number(unit_id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, unit_id]);

  const student = props.getStudent(Number(id))
  const page = student && props.getRecord(student.id, Number(unit_id))

  return (
    <React.Fragment>
      <PrivateHeader />
      <SiteMain>
        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          <li className="breadcrumbs__item">
            受講者情報
          </li>
        </ol>

        {
          student && page &&
          <div className="site-main__main-contents">
            <StudentBar student={student} />
            <StudentNav student={student} type="study_result" />

            <div className="study-breadcrumbs">
              <Link to={`/students/${student.id}/study_result`} className="study-breadcrumbs__item">
                教科
              </Link>
              <Link to={`/students/${student.id}/study_result/${page.curriculum.id}`} className="study-breadcrumbs__item">
                科目
              </Link>
              <Link to={`/students/${student.id}/study_result/courses/${page.course.id}`} className="study-breadcrumbs__item">
                単元
              </Link>
              <div className="study-breadcrumbs__item">
                トピック
              </div>
            </div>

            <div className="page-title">
              { page.curriculum.name }
              &nbsp;
              <div className="page-title__badge">
                { page.course.name }
              </div>
              &nbsp;
              <br className="_sp" />{ page.unit.name }
            </div>

            <div className="topic-list">
              <div className="topic-list__head">
                <div className="topic-list__title">
                  “{ page.unit.name }”のトピック
                </div>
              </div>
              <div className="topic-list__body">
                {
                  page.topics.map((topic, index) => (
                    <div key={index} className="topic-list__item">
                      <span>
                        { topic.title }
                      </span>
                      <div className="topic-list__phase">
                        AI理解度判定
                        <div className={classNames("topic-list__level", {  "topic-list__level--complete" : topic.check_level === 'コンプリート' })}>
                          { topic.check_level }
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>

            <div className="back-home">
              <Link to='/' className="back-home__link">マイページへ戻る</Link>
            </div>
          </div>
        }

        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          <li className="breadcrumbs__item">
            受講者情報
          </li>
        </ol>
      </SiteMain>
    </React.Fragment>
  );
}

export default TrainerStudentStudyResultUnit