import React from 'react';
import PublicHeader from '../parts/PublicHeader';

const SignupTerm2: React.FC = () => {
  return (
    <React.Fragment>
      <PublicHeader />
      <main className="site-main site-main--register">
        <div className="site-main__main site-main__main--simple">
          <div className="site-main__main-inner site-main__main-inner--simple">
            <div className="site-main__main-contents">
              <div className="register-box">
                <div className="register-box__subtitle register-box__subtitle--terms">
                  河合塾One利用規約
                </div>
                <div className="register-box__terms">
                  <div className="terms">
                    <div className="terms__title">
                      「河合塾Ｏｎｅ」利用規約
                    </div>
                    <div className="terms__text">
                      第１条（利用規約の適用）
                    </div>
                    <ol className="terms__list terms__list--compact">
                      <li className="terms__list-item">
                        この利用規約（以下「本規約」といいます）は、株式会社河合塾Ｏｎｅ（以下「当社」といいます）が運営するインターネット上の教育サービス「河合塾Ｏｎｅ」（以下「本サイト」といいます）ならびに本サイトに付随するメール配信、その他各種サービス（以下「関連サービス」といいます）に適用されます。
                      </li>
                      <li className="terms__list-item">
                        本規約は、第２条に定める会員（以下「会員」といいます）が、本サイトの申し込みを行ったこと、または本サイトのコンテンツや各種情報を閲覧・使用等をしたことをもって、その内容に同意されたものとみなし、適用されます。
                      </li>
                      <li className="terms__list-item">
                        当社は会員に対して予告なしに本規約を変更できます。この場合、変更された本規約は、これらを本サイト上に掲示した後、会員が本サイトを利用した時点および当社が適当と判断する方法により通知した時点から、効力を生じます。
                      </li>
                    </ol>
                    <div className="terms__text">
                      第２条（会員）<br/>会員とは、当社が本サイトへの入会を承認した者をいいます。
                    </div>
                    <div className="terms__text">
                      第３条（会員資格の停止および抹消）
                    </div>
                    <ol className="terms__list terms__list--compact">
                      <li className="terms__list-item">
                        次の各号のいずれかに該当する場合は、当社は会員の承諾なしに会員資格を抹消できます。<br/>（１）会員資格を不正に利用した場合<br/>（２）本サイトおよび関連サービスを不正に利用した場合<br/>（３）会員の登録情報に虚偽の事実が認められた場合<br/>（４）その他、本規約のいずれかに違反した場合
                      </li>
                      <li className="terms__list-item">
                        会員資格を抹消する場合、その会員が本サイトおよび関連サービスで保有するすべての権利も同時に消滅します。
                      </li>
                      <li className="terms__list-item">
                        当社は、会員が一定期間以上本サイトを利用しない場合、または当社が必要と認めた場合には、当該会員に対し事前に通知したうえで、会員資格の停止または抹消を行うことができます。
                      </li>
                    </ol>
                    <div className="terms__text">
                      第４条（設備等の準備・費用の負担）<br/>会員は、本サイトおよび関連サービスを利用する環境（利用端末、通信機器、ソフトウェア、回線、インターネット接続サービスおよびその他これらに付随して必要となる全てのもの）の準備を、自己の費用負担と責任において行います。
                    </div>
                    <div className="terms__text">
                      第５条（自己責任の原則）<br/>会員は、自己のＩＤにより本サイトおよび関連サービスを利用してなされた⼀切の行為およびその結果について、当社の責めに帰すべき事由がない限り、当該行為を自己がしたか否かを問わず、責任を負います。
                    </div>
                    <div className="terms__text">
                      第６条 (ＩＤおよびパスワードの管理責任)
                    </div>
                    <ol className="terms__list terms__list--compact">
                      <li className="terms__list-item">
                        会員は、自己のＩＤおよびこれに対応するパスワードを、他者に使用させず、他者と共有あるいは他者に許諾しないとともに、自己のＩＤおよびこれに対応するパスワードの使用および管理について⼀切の責任を負います。
                      </li>
                      <li className="terms__list-item">
                        当社は、会員のＩＤおよびこれに対応するパスワードが他者に使用されたことによって当該会員が被る損害については、当社の責めに帰すべき事由がない限り、当該会員の故意過失の有無にかかわらず⼀切責任を負いません。また、当該ＩＤおよびこれに対応するパスワードによりなされた本サイトおよび関連サービスの利用は当該会員によりなされたものとみなし、当社の責めに帰すべき事由がない限り、当該会員は⼀切の責任を負います。
                      </li>
                      <li className="terms__list-item">
                        会員は、ＩＤ等の盗難があった場合、またはＩＤ等が第三者に使⽤されていることが判明した場合、直ちに当社にその旨を届け出るとともに、当社からの指⽰に従います。
                      </li>
                    </ol>
                    <div className="terms__text">
                      第７条（学習ポイントの付与）
                    </div>
                    <ol className="terms__list terms__list--compact">
                      <li className="terms__list-item">
                        会員が本サイトを利用し、所定の条件を満たした場合、当社は会員に対し学習ポイント（以下「ポイント」といいます）を会員一人あたり累計10,000ポイントまで付与します。なお、ポイント付与の有無、ポイント数、ポイントの有効期限、その他ポイントに関する一切の条件は、当社が決定します。
                      </li>
                      <li className="terms__list-item">
                        会員は、当社が指定するサイトにて、保有するポイントを、当社指定の商品や割引サービスと交換することができます。
                      </li>
                      <li className="terms__list-item">
                        会員が次の各号のいずれかに該当する行為を行ったと当社が判断した場合、当社は会員への事前の通知なくして、交換商品の申込取消、会員の保有ポイントの取消、会員資格の停止または抹消のいずれかまたは全部の処分を行うことができます。<br/>（１）交換商品を、その目的を問わず、第三者に転売、転売のために第三者に提供する行為、または転売を試みる行為があった場合（オークションまたはインターネットオークション、その他個人間売買による転売、または転売を試みる行為を含みます）<br/>（２）利用者が偽造・変造その他不正の手段を用いてポイントを作出・交換等を行うなどの不正行為があった場合<br/>（３）その他当社が本項に定める処分を行うことが適当であると判断した場合
                      </li>
                    </ol>
                    <div className="terms__text">
                      第８条（著作権等）
                    </div>
                    <ol className="terms__list terms__list--compact">
                      <li className="terms__list-item">
                        本サイトおよび関連サービスで提供するすべての情報・サービス（問題、解答、記事、データベース、データ、ホームページデザイン、肖像、商標、標章、および表現等すべて）にかかわる著作権、著作者人格権、産業財産権、その他⼀切の知的財産権およびノウハウは、本サイト内の正当な権利を有する権利者に帰属します。
                      </li>
                      <li className="terms__list-item">
                        当社は、会員への事前の通知なくして、会員が本サイトおよび関連サービスに提供した情報を引用、編集、再構成することがあります。会員はこれに対し何ら異議を申し立てることができません。また、当社は、会員の本サイトおよび関連サービスに提供した情報に関し、本人および他の会員からの要求による編集等の義務は一切負いません。
                      </li>
                    </ol>
                    <div className="terms__text">
                      第９条（会員の禁止事項）
                    </div>
                    <ol className="terms__list terms__list--compact">
                      <li className="terms__list-item">
                        会員は本サイトおよび関連サービスを利用するにあたり、次の各号に該当し、またはそのおそれがある行為を行ってはなりません。<br/>（１）公序良俗に反すること<br/>（２）法令に違反する行為または犯罪的行為に結びつくこと<br/>（３）他の会員、第三者または当社の知的所有権・財産・信⽤・プライバシーを侵害すること、および他の会員、第三者または当社に不利益を与える⼀切の行為<br/>（４）他の会員、第三者または当社を誹謗中傷すること<br/>（５）選挙活動またはこれに類似する行為<br/>（６）特定の宗教の勧誘活動またはこれに類似する行為<br/>（７）当社が承認していない商業行為、営業活動またはそれに類する行為<br/>（８）有害なコンピュータプログラムまたはスパムメール・チェーンレター等を送信または書きこむこと<br/>（９）本サイトおよび関連サービスにおいて当社が提供する情報等の内容の無断転載および再配布<br/>（１０）本規約に反する⼀切の行為<br/>（１１）その他、当社が不適切と判断する行為<br/><br/>
                      </li>
                      <li className="terms__list-item">
                        前項にいずれかに該当し、またはそのおそれがある投稿等については、当社は会員への事前の通知なくして、本サイトおよび関連サービスから削除することがあります。
                      </li>
                    </ol>
                    <div className="terms__text">
                      第１０条（個人情報）<br/>個人情報の取り扱いについては、別途定める「個人情報の取り扱いについて」のとおりとします。
                    </div>
                    <div className="terms__text">
                      第１１条（本サイトの内容等の変更・一時停止・中止）
                    </div>
                    <ol className="terms__list terms__list--compact">
                      <li className="terms__list-item">
                        当社は、次の各号に該当する場合には、会員への事前の通知をすることなく、本サイトの停止および中断を行うことがあります。<br/>（１）本サイトの保守または仕様の変更を行う場合<br/>（２）天変地異その他非常事態が発生し、または発生するおそれがあり、本サイト上で提供する各種サービスの一部または全部の運営ができなくなった場合<br/>（３）当社がやむを得ない事由により本サイト上で提供する各種サービスの停止が必要であると判断した場合
                      </li>
                      <li className="terms__list-item">
                        当社は、会員へ事前の通知をすることなく、本サイトの内容および仕様をいつでも変更することができます。
                      </li>
                      <li className="terms__list-item">
                        当社は、1か月の予告期間をもって会員に通知のうえ、本サイトを長期的に中断または終了することがあります。
                      </li>
                    </ol>
                    <div className="terms__text">
                      第１２条（免責事項）
                    </div>
                    <ol className="terms__list terms__list--compact">
                      <li className="terms__list-item">
                        本サイトおよび関連サービスにおいて提供する情報の正確性について、当社は最善を尽くしております。しかしながら、本サイトおよび関連サービスにおいて提供するすべての情報について、その正確性、有用性、最新性、適切性などその内容ついて何ら保証をするものではありません。
                      </li>
                      <li className="terms__list-item">
                        本サイトおよび関連サービスの利用により発生した会員の損害（他者との間で生じたトラブルに起因する損害を含みます）、および本サイトおよび関連サービスを利用できなかったことにより発生した会員または他者の損害に対し、当社は、何ら責任を負いません。ただし、当社の故意または重過失により当該トラブルが発生した場合は、この限りではありません。
                      </li>
                    </ol>
                    <div className="terms__text">
                      第１３条（損害賠償）
                    </div>
                    <ol className="terms__list terms__list--compact">
                      <li className="terms__list-item">
                        会員が、本サイトおよび関連サービスの利用に際して、会員の故意または過失により当社に損害を与えた場合、あるいは会員が本規約に違反することにより当社が損害を被った場合、当社は当該会員に対して当該損害の賠償請求ができます。
                      </li>
                      <li className="terms__list-item">
                        会員の故意または過失により登録された会員情報に起因して、当社または第三者が被害、不利益等を受けた場合、当社は、当該会員に対し、当該損害の賠償請求ができます。
                      </li>
                    </ol>
                    <div className="terms__text">
                      第１４条（準拠法および管轄）<br/>規約の準拠法は日本法とし、本規約に関する一切の紛争は、東京地方裁判所または東京簡易裁判所を第一審の専属的合意管轄裁判所とします。
                    </div>
                    <div className="terms__text">
                      附則<br/>本規約は２０１９年１２⽉１⽇から実施します。
                    </div>
                    <div className="terms__text--revised">
                      2023年3月6日改定
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
}

export default SignupTerm2;
