import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router'

import { AppState } from "store";
import targetComponent from 'components/trainer/StudentSchedules';
import trainerActions from 'actions/trainerActions';

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch })

const mapStateToProps = (appState: AppState, ownProps: RouteComponentProps) => {
  return {
    user: appState.session.user!,
    trainer: appState.trainer,

    ...ownProps,
  }
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  { dispatch }: ReturnType<typeof mapDispatchToProps>) => {
  return {
    ...stateProps,
    fetchData: (student_id: number) => {
      dispatch(trainerActions.fetchScheduleRecords({ student_id, initialize: true }))
    },
    getSchedules: (student_id: number) => {
      const page = stateProps.trainer.schedule
      if (page.student_id !== student_id) {
        return false
      }
      return page.records
    },
    hasNextSchedule: (student_id: number) => {
      const page = stateProps.trainer.schedule
      if (page.student_id !== student_id) {
        return false
      }
      return page.has_next
    },
    fetchNext: () => {
      const student_id = stateProps.trainer.schedule.student_id
      if (student_id) {
        dispatch(trainerActions.fetchScheduleRecords({ student_id, initialize: false }))
      }
    }
  }
}

export type mappedProps = ReturnType<typeof mergeProps>
export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(targetComponent))
