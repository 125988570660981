import React, { useEffect } from 'react';
import Moment from 'react-moment'
import { Field, Fields } from 'redux-form'
import { useHistory } from 'react-router-dom';
import { Yubinbango } from 'lib/yubinbango';
import { mappedProps } from 'containers/parent/SettingProfileEdit';
import PrivateHeader from 'containers/parts/PrivateHeader';
import SiteSettingMainArea from '../common/SiteSettingMainArea';

import {
  renderInputField,
  renderSelectField,
  renderIfFieldsHasError,
} from 'components/parts/FormParts'

const updateFieldsByPostalCode = (code: string, change: (field: string, value:any) => void) => {
  new Yubinbango(code, (x: any) => {
    if (x.region !== '') {
      change('prefecture', x.region);
    }
    if (x.locality !== '' || x.street !== '') {
      change('municipality', x.locality + x.street);
    }
  });
}

const SettingProfileEdit: React.FC<mappedProps> = (props: mappedProps) => {
  const history = useHistory()

  const student = props.parent.student

  useEffect(() => {
    props.fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  if (props.submitSucceeded) {
    return (
      <React.Fragment>
        <PrivateHeader />
        <SiteSettingMainArea page="profile_edit" user={props.user}>
          <div className="setting-box">
            <div className="setting-box__title setting-box__title--profile">
              プロフィール確認・変更
            </div>
            <div className="setting-message">
              プロフィールを更新しました。
            </div>
            <div className="setting-box__button">
              <button
                onClick={(e) => {
                  e.preventDefault()
                  history.push('/')
                }}
                className="button button--gray button--back button--xlarge">マイページに戻る</button>
            </div>
          </div>
        </SiteSettingMainArea>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <PrivateHeader />
      <SiteSettingMainArea page="profile_edit" user={props.user}>
        <div className="setting-box">
          <form onSubmit={props.handleSubmit}>
            <div className="setting-box__title setting-box__title--profile">
              プロフィール確認・変更
            </div>
            <div className="setting-box__subtitle">
              会員情報
            </div>

            {
              student && 
              <div className="setting-box__form">
                <div className="form form--confirm">
                  <div className="form__item">
                    <div className="form__label">
                      ニックネーム
                    </div>
                    <div className="form__field">
                      <div className="form__field-text">
                        { student.nick_name }
                      </div>
                    </div>
                  </div>
                  <div className="form__item">
                    <div className="form__label">
                      ログインID
                    </div>
                    <div className="form__field">
                      <div className="form__field-text">
                        { student.login }
                      </div>
                    </div>
                  </div>
                  <div className="form__item">
                    <div className="form__label">
                      ステータス
                    </div>
                    <div className="form__field">
                      <div className="account-status">
                        <span className="account-status__badge">
                          {/* MARK: temporary, suspensionの場合はこの画面に遷移できない */}
                          { student.status === 'trial' && 'トライアル' }
                          { student.status === 'regular' && '本登録' }
                        </span>
                        <div className="account-status__text">
                          {
                            // MARK: トライアル終了日はバッチ処理で設定されるため空の場合がある、空の場合は非表示
                            student.status === 'trial' && student.trial_expired_on &&
                            <>
                              <Moment format="YYYY年M月D日">{ student.trial_expired_on }</Moment>
                              までトライアルサービス利用中
                            </>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  {
                    /*
                    MARK: 未実装なので非表示
                    student.status === 'trial' && student.trial_started_at &&
                    <div className="form__item">
                      <div className="form__label">
                        トライアル申込日
                      </div>
                      <div className="form__field">
                        <Moment format="YYYY年M月D日">{ student.trial_started_at}</Moment>
                      </div>
                    </div>
                    */
                  }
                  <div className="form__item">
                    <div className="form__label">
                      登録日
                    </div>
                    <div className="form__field">
                      <Moment format="YYYY年M月D日">{ student.created_at }</Moment>
                    </div>
                  </div>
                  {
                    /*
                    MARK: 未実装なので非表示
                    student.credit_updated_at &&
                    <div className="form__item">
                      <div className="form__label">
                        カード情報更新日
                      </div>
                      <div className="form__field">
                        <Moment format="YYYY年M月D日">{ student.credit_updated_at }</Moment>
                      </div>
                    </div>
                    */
                  }
                  {
                  /*
                  MARK: 質問機能は未実装なので非表示
                  <div className="form__item">
                    <div className="form__label">
                      当月質問回数
                    </div>
                    <div className="form__field">
                      { student.contract_month }
                    </div>
                  </div>
                  */
                  }
                </div>
              </div>
            }

            <div className="setting-box__subtitle">
              契約プラン
            </div>
            {
              student &&
              <div className="setting-box__form">
                <div className="form form--confirm">
                  <div className="form__item">
                    <div className="form__label">
                      ご契約プラン
                    </div>
                    <div className="form__field">
                      { student.plan.name }
                    </div>
                  </div>
                  <div className="form__item">
                    <div className="form__label">
                      トレーナーサービス
                    </div>
                    <div className="form__field">
                      { student.trainer_contracted && '申し込み済' }
                    </div>
                  </div>
                  <div className="form__item">
                    <div className="form__label">
                      トレーナー希望条件
                    </div>
                    <div className="form__field">
                      第1希望：{ student.first_request } <br />
                      第2希望：{ student.second_request }<br />
                      第3希望：{ student.third_request }
                    </div>
                  </div>
                </div>
              </div>
            }

            <div className="setting-box__subtitle">
              保護者情報
            </div>
            <div className="setting-box__form">
              <div className="form form--confirm">
                <div className="form__item">
                  <label className="form__label">氏名</label>
                  <div className="form__field">
                    { props.user.full_name }
                  </div>
                </div>
                <div className="form__item">
                  <label className="form__label">氏名(カナ)</label>
                  <div className="form__field">
                    { props.user.full_name_read }
                  </div>
                </div>
              </div>

              <div className="form">
                <div className="form__item">
                  <label className="form__label form__label--required">メールアドレス</label>
                  <div className="form__field">
                    <Field
                      component={renderInputField}
                      className="input input--required form__input form__input--email"
                      type="email"
                      name="email"
                      placeholder="user＠kawai-one.com"
                    />

                    <Fields names={[ 'email' ]} component={renderIfFieldsHasError}>
                      <div className="form__error">
                        <div className="error-message">
                          メールアドレス（半角英数記号）をご入力ください。
                        </div>
                      </div>
                    </Fields>
                  </div>
                </div>

                <div className="form__item">
                  <label className="form__label form__label--required">住所</label>
                  <div className="form__field">
                    <div className="form__note form__note--orange">
                      住所は、保護者と異なる場合のみご入力ください。
                    </div>

                    〒
                    <Field
                      component={renderInputField}
                      className="input input--required form__input form__input--zip"
                      type="text"
                      name="postal_code"
                      placeholder="1112222"
                      size={7}
                    />

                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        updateFieldsByPostalCode(props.postalCode, props.change);
                      }}
                      className="button button--primary button--no-chevron form__zip-button">
                      住所検索
                    </button>

                    <Fields names={[ 'postal_code' ]} component={renderIfFieldsHasError}>
                      <div className="form__error">
                        <div className="error-message">
                          郵便番号（半角数字）を入力してください。
                        </div>
                      </div>
                    </Fields>

                    <div className="form__note">
                      （半角数字、- [ハイフン] は含めない）
                    </div>
                    <div className="select form__select-wrapper">
                      <Field
                        component={renderSelectField}
                        className="select__select"
                        name="prefecture"
                      >
                        <option value="">選択してください</option>
                        { props.prefectures.map(x => (<option key={x}>{x}</option>)) }
                      </Field>

                      <Fields names={[ 'prefecture' ]} component={renderIfFieldsHasError}>
                        <div className="form__error">
                          <div className="error-message">
                            都道府県を選択してください。
                          </div>
                        </div>
                      </Fields>
                    </div>
                    <br />

                    <Field
                      component={renderInputField}
                      className="input form__input form__input--city"
                      type="text"
                      name="municipality"
                    />

                    <Fields names={[ 'municipality' ]} component={renderIfFieldsHasError}>
                      <div className="form__error">
                        <div className="error-message">
                          市区町村名を入力してください。
                        </div>
                      </div>
                    </Fields>

                    <div className="form__note">
                      市区町村名までを入力してください。
                    </div>

                    <Field
                      component={renderInputField}
                      className="input form__input form__input--address"
                      type="text"
                      name="address"
                    />

                    <Fields names={[ 'address' ]} component={renderIfFieldsHasError}>
                      <div className="form__error">
                        <div className="error-message">
                          住所を入力してください。
                        </div>
                      </div>
                    </Fields>

                    <div className="form__note">
                      番地、建物名（マンション・団地・社宅）、部屋番号等
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div className="setting-box__subtitle">
              受講者情報
            </div>
            {
              student && 

              <div className="setting-box__form">
                <div className="form form--confirm">
                  <div className="form__item">
                    <label className="form__label">受講者氏名</label>
                    <div className="form__field">
                      {student.full_name}
                    </div>
                  </div>
                  <div className="form__item">
                    <label className="form__label">受講者氏名(カナ)</label>
                    <div className="form__field">
                      {student.full_name_read}　
                    </div>
                  </div>
                  <div className="form__item">
                    <label className="form__label">生年月日</label>
                    <div className="form__field">
                      <Moment format="YYYY年M月D日">{ student.birthday}</Moment>
                    </div>
                  </div>
                  <div className="form__item">
                    <label className="form__label">性別</label>
                    <div className="form__field">
                      {student.gender}
                    </div>
                  </div>
                  <div className="form__item">
                    <label className="form__label">メールアドレス</label>
                    <div className="form__field">
                      {student.email}
                    </div>
                  </div>
                  <div className="form__item">
                    <label className="form__label">住所</label>
                    <div className="form__field">
                      〒{student.postal_code}<br />
                      {student.prefecture}<br />
                      {student.municipality}<br />
                      {student.address}<br />
                    </div>
                  </div>
                  <div className="form__item">
                    <label className="form__label">在籍（卒業）高校</label>
                    <div className="form__field">
                      {student.high_school_name}
                    </div>
                  </div>
                  <div className="form__item">
                    <label className="form__label">学年</label>
                    <div className="form__field">
                      {student.school_grade}
                    </div>
                  </div>
                  <div className="form__item">
                    <label className="form__label">ニックネーム</label>
                    <div className="form__field">
                      {student.nick_name}
                    </div>
                  </div>
                </div>
              </div>

            }

            <div className="setting-box__subtitle">
              トレーナー希望情報
            </div>
            {
              student && 
              <div className="setting-box__form">
                <div className="form form--confirm">
                  <div className="form__item">
                    <label className="form__label">文理系統</label>
                    <div className="form__field">
                      { student.trainer_department_type}
                    </div>
                  </div>
                  <div className="form__item">
                    <label className="form__label">学部系統</label>
                    <div className="form__field">
                      { student.trainer_department_name}
                    </div>
                  </div>
                  <div className="form__item">
                    <label className="form__label">在籍または<br className="_pc" />合格大学</label>
                    <div className="form__field">
                      { student.trainer_university}
                    </div>
                  </div>
                  <div className="form__item">
                    <label className="form__label">部活動</label>
                    <div className="form__field">
                      { student.trainer_club_name}
                    </div>
                  </div>
                </div>
              </div>
            }

            <div className="setting-box__subtitle">
              学習プラン（学習範囲）
            </div>
            <div className="setting-box__form">
              {
                student && 
                <div className="form form--confirm">
                  <div className="form__item">
                    <label className="form__label">中学英語</label>
                    <div className="form__field">
                      {
                        props.studentJuniorEnglishLevel ? props.studentJuniorEnglishLevel.course_names : ''
                      }
                    </div>
                  </div>
                  <div className="form__item">
                    <label className="form__label">中学数学</label>
                    <div className="form__field">
                      {
                        props.studentJuniorMathLevel ? props.studentJuniorMathLevel.course_names : ''
                      }
                    </div>
                  </div>
                  <div className="form__item">
                    <label className="form__label">中学理科</label>
                    <div className="form__field">
                      {
                        props.studentJuniorScienceLevel ? props.studentJuniorScienceLevel.course_names : ''
                      }
                    </div>
                  </div>
                  <div className="form__item">
                    <label className="form__label">英語</label>
                    <div className="form__field">
                      英文法・英文解釈
                    </div>
                  </div>
                  <div className="form__item">
                    <label className="form__label">数学</label>
                    <div className="form__field">
                      {
                        props.studentMathLevel ? props.studentMathLevel.course_names : ''
                      }
                    </div>
                  </div>
                  <div className="form__item">
                    <label className="form__label">物理</label>
                    <div className="form__field">
                      {
                        props.studentPhysicsLevel ? props.studentPhysicsLevel.course_names : ''
                      }
                    </div>
                  </div>
                  <div className="form__item">
                    <label className="form__label">化学</label>
                    <div className="form__field">
                      {
                        props.studentChemistryLevel ? props.studentChemistryLevel.course_names : ''
                      }
                    </div>
                  </div>
                  <div className="form__item">
                    <label className="form__label">現代文</label>
                    <div className="form__field">
                      {
                        props.studentModernJapaneseLevel ? props.studentModernJapaneseLevel.course_names : ''
                      }
                    </div>
                  </div>
                  <div className="form__item">
                    <label className="form__label">古文</label>
                    <div className="form__field">
                      {
                        props.studentClassicalJapaneseLevel ? props.studentClassicalJapaneseLevel.course_names : ''
                      }
                    </div>
                  </div>
                  <div className="form__item">
                    <label className="form__label">日本史</label>
                    <div className="form__field">
                      {
                        props.studentJapaneseHistoryLevel ? props.studentJapaneseHistoryLevel.course_names : ''
                      }
                    </div>
                  </div>
                  <div className="form__item">
                    <label className="form__label">世界史</label>
                    <div className="form__field">
                      {
                        props.studentWorldHistoryLevel ? props.studentWorldHistoryLevel.course_names : ''
                      }
                    </div>
                  </div>
                  <div className="form__item">
                    <label className="form__label">生物</label>
                    <div className="form__field">
                      {
                        props.studentBiologyLevel ? props.studentBiologyLevel.course_names : ''
                      }
                    </div>
                  </div>
                  <div className="form__item">
                    <label className="form__label">英単語</label>
                    <div className="form__field">
                      {
                        props.studentEnglishWordsLevel ? props.studentEnglishWordsLevel.course_names : ''
                      }
                    </div>
                  </div>
                  <div className="form__item">
                    <label className="form__label">新課程数学</label>
                    <div className="form__field">
                      {
                        props.studentNewMathLevel ? props.studentNewMathLevel.course_names : ''
                      }
                    </div>
                  </div>
                  {/* issue-673 */}
                  <div className="form__item">
                    <label className="form__label">新課程化学</label>
                    <div className="form__field">
                      {
                        props.studentNewChemistry2024Level ? props.studentNewChemistry2024Level.course_names : ''
                      }
                    </div>
                  </div>
                  <div className="form__item">
                    <label className="form__label">新課程生物</label>
                    <div className="form__field">
                      {
                        props.studentNewBiology2024Level ? props.studentNewBiology2024Level.course_names : ''
                      }
                    </div>
                  </div>
                </div>
              }
              <div className="form">
                <div className="form__buttons">
                  <div className="form__button form__button--flex-item">
                    <button
                      disabled={props.submitting || props.invalid}
                      className="button button--primary button--xlarge">変更する</button>
                  </div>
                  <div className="form__button form__button--flex-item">
                    <button
                      onClick={(e) => {
                        e.preventDefault()
                        history.push('/')
                      }}
                      className="button button--gray button--back button--xlarge">
                      マイページに戻る
                    </button>
                  </div>
                </div>
              </div>
            </div>


          </form>
        </div>
      </SiteSettingMainArea>
    </React.Fragment>
  );
}

export default SettingProfileEdit;
