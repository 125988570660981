import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router'

import { AppState } from "store";
import targetComponent from 'components/student/StudyLogs';
import studentActions from 'actions/studentActions';
import { getAvailableCurriculums }  from 'utils/store'
import * as Models from 'models/api';

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch })
const mapStateToProps = (appState: AppState, ownProps: RouteComponentProps) => {

  const user = appState.session.user! as Models.StudentUser

  const availableQuestions = (
    !user.corporation ||
    ( user.corporation && user.corporation.available_services.questions )
  )

  return {
    // MARK: 学習履歴は受講設定可能な教科をタブ表示する
    curriculums: getAvailableCurriculums(user),
    user,
    availableQuestions,
    api: appState.repository.authorizedApi!,
    student: appState.student,
    ...ownProps,
  }
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  { dispatch } : ReturnType<typeof mapDispatchToProps>) => {
  return {
    ...stateProps,
    getCurriculum: (code: string| undefined) => {
      const x = stateProps.curriculums.find(x => x.code === code)
      return x || stateProps.curriculums[0]
    },
    fetchData: (curriculum_id: number) => {
      dispatch(studentActions.fetchStudyLogRecords({ curriculum_id, initialize: true }))
    },
    getStudyLogRecords: (curriculum_id: number) => {
      const studyLog = stateProps.student.studyLog
      if (studyLog.curriculum_id !== curriculum_id) {
        return null
      }
      return studyLog.records
    },
    getStudyLogHasNext: (curriculum_id: number) => {
      const studyLog = stateProps.student.studyLog
      if (studyLog.curriculum_id !== curriculum_id) {
        return false
      }
      return studyLog.has_next
    },
    fetchNext: () => {
      const studyLog = stateProps.student.studyLog
      const curriculum_id = studyLog.curriculum_id!
      dispatch(studentActions.fetchStudyLogRecords({ curriculum_id, initialize: false }))
    },
    pdfDownload: (curriculum_codes: string[]) => {
      const curriculumCodesString = curriculum_codes.join("_")
      return new Promise<{url: string}>((resolve, reject) => {
        dispatch(studentActions.fetchPdfDownload({
          pdf_curriculums: curriculumCodesString,
          promises: { resolve, reject }
        }))
      })}
  }
}

type connectMappedProps = ReturnType<typeof mergeProps>
export type mappedProps = connectMappedProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(targetComponent))