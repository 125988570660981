import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router'

import { AppState } from "store";
import targetComponent from 'components/trainer/StudyCurriculum';
import trainerActions from 'actions/trainerActions';
import {curriculums} from "../../utils/constants"

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch })

const mapStateToProps = (appState: AppState, ownProps: RouteComponentProps) => {

  const authrorizedApi = appState.repository.authorizedApi!
  return {
    ...ownProps,
    trainer: appState.trainer,
    curriculums,
    authrorizedApi,
  }
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  { dispatch }: ReturnType<typeof mapDispatchToProps>) => {
  return {
    ...stateProps,
    getCurriculum: (id: string | number | undefined) => {
      //return stateProps.curriculums.find(x => x.id === Number(id) || x.code === id)
      return curriculums.find(x => x.id === Number(id) || x.code === id)
    },
    fetchCourses: (curriculum_id: number) => {
      dispatch(trainerActions.fetchCurriculumCourses({ curriculum_id }))
    },
    fetchStudySummariesPerUnit: (course_id: number, school_class_id?: number) => {
      dispatch(trainerActions.fetchStudySummariesPerUnit({
        initialize: true,
        course_id,
        school_class_id,
      }))
    },    
  }
}

export type mappedProps = ReturnType<typeof mergeProps>
export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(targetComponent))