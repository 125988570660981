import React, { useEffect, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import useTimeout from 'use-timeout'

import PrivateHeader from 'containers/parts/PrivateHeader';
import { mappedProps } from 'containers/student/EntrustStudy';
import SiteMain from 'containers/parts/SiteMain';
import { normalContentTypes, preOrPostContentTypes } from 'utils/constants'
import { CourseCompletionTestConfirmModal } from 'components/student/common/CourseCompletionTestConfirmModal';
import { StudentStudyLearningCountRecord, EntrustCurrentRecord } from '../../models/api';
import CorrectAnswerCount from './common/CorrectAnswerCount';

const EntrustStudyIndex: React.FC<mappedProps> = (props: mappedProps) => {
  const { curriculum } = useParams<{ curriculum: string; }>();
  const history = useHistory()

  const [topics, setTopics] = useState<any>(null)
  const [units, setUnits] = useState<any>(null)

  const topicsState = props.student.studyUnit.record
  useEffect(() => {
    if (topicsState) {
      setTopics(topicsState.topics)
    }
  }, [topicsState])

  useEffect(() => {
    props.fetchStudyAchievementsLatest();
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  , []);

  const unitsState = props.student.studyCourse.record
  useEffect(() => {
    if (unitsState) {
      setUnits(unitsState.units)
    }
  }, [unitsState])

  const [showConfirm, setShowConfirm] = useState(false)
  const [selectedContent, setSelectedContent] = useState<{ content_id: number; rank: number; m_entrust: string } | null>(null)
  const [disabledContents, setDisabledContents] = useState<number[]>([]);

  const isDisabledContent = (contentId: number) => disabledContents.includes(contentId)

  const setDisabledContent = (contentId: number) => {
    if (isDisabledContent(contentId)) { return }
    setDisabledContents(disabledContents.concat([contentId]))
  }

  const unsetDisabledContent = (contentId: number) => {
    setDisabledContents(disabledContents.filter(x => x === contentId))
  }

  const [showGraph, setShowGraph] = useState<boolean>(false)
  useTimeout(() => setShowGraph(true), 500)
  const curriculumObj = props.getCurriculum(curriculum)
  useEffect(() => {
    if (curriculumObj) {
      props.fetchEntrustStudyData(curriculumObj.id).catch(error => {
        // MARK: 取得リクエストでエラーになった場合にTOPにリダイレクトする
        history.push('/')
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curriculum]);

  useEffect(() => {
    if (!curriculumObj) {
      history.push('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curriculumObj])

  const [curriculumName, setCurriculumName] = useState<string | null>(null)
  useEffect(() => {
    if (curriculumObj) {
      setCurriculumName(curriculumObj.name)
    }
  }, [curriculumObj])

  const [entrustStudyRecords, setEntrustStudyRecords] = useState<EntrustCurrentRecord[] | null>(null);
  useEffect(() => {
    if (curriculumObj) {
      const records = props.getEntrustStudyState()
      if (records) {
        setEntrustStudyRecords(records)
      }
    }
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curriculumObj, props.fetchEntrustStudyData]);

  useEffect(() => {
    if (entrustStudyRecords && entrustStudyRecords.length > 0) {
      // 受講生科目内トピック一覧取得
      props.fetchStudyUnit(entrustStudyRecords[0].unit.id)
      // TODO: global stateから取得しViewに表示する
    }
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entrustStudyRecords])

  useEffect(() => {
    if (curriculumObj) {
    props.getCoursesId(curriculumObj.id)
    }
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curriculumObj])

  const courses = props.student.studyCurriculum.record;
  const [courseId, setCourseId] = useState<number | null>(null)

  useEffect(() => {
    if (courses) {
      if (entrustStudyRecords && entrustStudyRecords.length > 0) {
        const course = courses.courses.find((x: any) => x.title === entrustStudyRecords[0].unit.course_name)
        if (course) {
          setCourseId(course.id)
        }
      }
    }
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courses, entrustStudyRecords])

  useEffect(() => {
    if (courseId) {
      props.fetchStudyCourse(courseId)
    }
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseId])

  // カリキュラムコードの取得
  const [curriculumCodes, setCurriculumCodes] = useState<string[] | null>(null)
  useEffect(() => {
    if (entrustStudyRecords) {
      const curriculumCodes = entrustStudyRecords.map((x: any) => x.content.curriculum_code)
      if (curriculumCodes) {
        setCurriculumCodes(curriculumCodes)
      }
    }
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [entrustStudyRecords])

  // 学習回数の取得
  const [learningCount, setLearningCount] = useState<StudentStudyLearningCountRecord[] | null>(null);
  useEffect(() => {
    if (curriculumCodes) {
      props.getLearningCount({ curriculumCodes }).then(
        (learningCount: any) => {
          setLearningCount(learningCount)
        })
    }
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [curriculumCodes])

  const startStudy = ({ content_id, m_entrust, rank }: { content_id: number, m_entrust: string, rank?: number }) => {
    if (isDisabledContent(content_id)) { return }
    setDisabledContent(content_id)

    const return_pathname = history.location.pathname
    props.startStudy({ content_id, return_pathname, m_entrust }).then((url) => {
      unsetDisabledContent(content_id)
      window.location.href = url
    })
  }

  const showLearningCount = (curriculum_code: string) => {
    if (learningCount) {
      const learningCountRecord = learningCount.find((x: any) => x.curriculum_code === curriculum_code)
      if (learningCountRecord) {
        return `学習回数 ${learningCountRecord.learning_count}回`
      } else {
        return "学習回数 0回"
      }
    }
  }

  const truncate = (str: string, len: number) => {
    return str.length <= len ? str : (str.substr(0, len) + "...");
  }

  const isManyLearningCount = (curriculum_code: string) => {
    if (learningCount) {
      const learningCountRecord = learningCount.find((x: any) => x.curriculum_code === curriculum_code)
      if (learningCountRecord && learningCountRecord.learning_count !== null) {
        if (learningCountRecord.learning_count > 0) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }
  }

  const downloadPdf = (curriculumCode: string) => {
    const curriculumCodesArray = [curriculumCode]
    props.pdfDownload(curriculumCodesArray)
  }

  return (
    <React.Fragment>
      <CourseCompletionTestConfirmModal
        show={showConfirm && !!selectedContent}
        onSubmit={() => {
          startStudy({
            content_id: selectedContent!.content_id,
            rank: selectedContent!.rank,
            m_entrust: selectedContent!.m_entrust
          })
        }}
        onClose={() => {
          setShowConfirm(false)
          setSelectedContent(null)
        }} />
      <PrivateHeader />
      <SiteMain>
        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          <li className="breadcrumbs__item">おまかせ学習({curriculumName})</li>
        </ol>
        <div className="site-main__main-contents">
          <div className="page-title page-title--recommend">
            {props.user.nick_name}さん専用のおまかせ学習
            <div className="page-title__correct-count">
              {props.student.studyAchievementsLatest.record && <>
               <CorrectAnswerCount correctAnswerCount={props.student.studyAchievementsLatest.record.study_achievements.consecutive_correct_answer_count}/>
              </>}
              </div>
          </div>
          {entrustStudyRecords && entrustStudyRecords.length === 0 ?
            <>
              <div className="topic-box topic-box--require_entrust_setting">
                <p className="entrust-setting-explanation-text-title">「おまかせ学習」を利用すると、河合塾の知見に基づいた最適な順序で学習を進めることが出来ます。</p>
                <p className="entrust-setting-explanation-text-description">
                  初めて「おまかせ学習」を利用する場合は、まずどのトピックから学習を開始するか、その起点トピックを設定する必要があります。設定方法は以下の手順で。<br />
                「おまかせ学習設定」＞「科目」選択＞「単元」選択＞「トピック」選択＞「設定する」<br />
                （この起点トピックは、いつでも自由に変更・再設定することが出来ます）</p>
                <div className='entrust-setting-button-container'>
                  <Link
                    to={`/entrust_setting/${curriculum}`}
                    className="button button--outline entrust-setting-button-margin"
                  >
                    おまかせ学習設定
                  </Link>
                </div>
              </div>
            </> :
            <>
              {entrustStudyRecords && entrustStudyRecords.length === 0 &&
                <React.Fragment>
                  <div className="page-subtitle page-subtitle--recommend">
                    おまかせ学習
                  </div>
                  <div className="topic-box topic-box--recommend">
                    <div className="topic-box__subject">
                      <small>{curriculumName}</small>
                    </div>
                    <div className="topic-box__title">
                      「教科・単元選択学習」から学習したい教材を選んで、学習してください。AIが学習したトピックをスタート地点として、新たにあなた専用の学習をおすすめしてくれます。
                    </div>
                    <div className="topic-box__type">
                      &nbsp;
                    </div>
                    <div className="topic-box__button">
                      <Link to={`/study/${curriculumObj && curriculumObj.id}`} className="button button--primary">
                        教科・単元選択学習へ
                      </Link>
                    </div>
                  </div>
                </React.Fragment>
              }

              {entrustStudyRecords && entrustStudyRecords.length > 0 && entrustStudyRecords.map((record: any, index) => (
                <React.Fragment key={index}>
                  {
                    index === 0 &&
                    <div className="page-subtitle-entrust">
                      {/* おまかせ学習 */}
                    </div>
                  }

                  <div className="recommend-header">
                    <div className="recommend-header__title">
                      <div>
                        {curriculumName}
                      </div>
                      <div className="recommend-header__badge">
                        {record && record.unit.course_name}
                      </div>
                      {
                        record && record.unit.understanding &&
                        <div className="recommend-header__subject">
                          {record.unit.name}
                          <div className="recommend-progress-bar">
                            <div className="recommend-progress-bar__bar" style={{ width: showGraph ? record.unit.understanding + '%' : 0 }}></div>
                            <div className="recommend-progress-bar__text">
                              {Math.floor(record.unit.understanding)}%
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  </div>

                  <dl className="recommend-status-list">
                    {
                      units &&
                      <>
                        <dt className="recommend-status-list__head">
                          単元
                        </dt>
                        <dd className="recommend-status-list__body">
                          <ul className="recommend-status-list__items">
                            {units &&
                              units.map((x: any) => (

                                <React.Fragment key={x.name}>
                                  <li className={classNames("recommend-status-list__item", { 'is-active': x.id === entrustStudyRecords[0].unit.id })} title={x.name}>
                                    {truncate(x.name, 10)}
                                  </li>
                                  &nbsp;
                                </React.Fragment >
                              ))
                            }
                          </ul>
                        </dd>
                        <dt className="recommend-status-list__head">
                          トピック
                        </dt>
                        <dd className="recommend-status-list__body">
                          <ul className="recommend-status-list__items">
                            {topics &&
                              topics.map((x: any) => (
                                <React.Fragment key={x.title}>
                                  <li key={x.title} className={classNames("recommend-status-list__item", { 'is-active': x.title === entrustStudyRecords[0].topic.description })} title={x.title}>
                                    {truncate(x.title, 10)}
                                  </li>
                                  &nbsp;
                                </React.Fragment>
                              ))
                            }
                          </ul>
                        </dd>
                        {record.content.meta!.type !== '科目修了テスト' &&
                          <>
                            <dt className="recommend-status-list__head">
                              教材
                            </dt>
                            <dd className="recommend-status-list__body">
                              {
                                preOrPostContentTypes.includes(record.content.meta!.type) &&
                                <ul className="recommend-status-list__items">
                                  {
                                    preOrPostContentTypes.map(x => (
                                      <li className={classNames("recommend-status-list__item", { 'is-active': record.content.meta!.type === x })} key={x}>
                                        {x}
                                      </li>
                                    ))
                                  }
                                </ul>
                              }
                              {
                                normalContentTypes.includes(record.content.meta!.type) &&
                                <ul className="recommend-status-list__items">
                                  {
                                    normalContentTypes.map(x => (
                                      <li className={classNames("recommend-status-list__item", { 'is-active': record.content.meta!.type === x })} key={x}>
                                        {x}
                                      </li>
                                    ))
                                  }
                                </ul>
                              }
                            </dd>
                          </>}
                      </>
                    }

                  </dl>

                  <div className={classNames(
                    "topic-box"/* , "entrust-topic-box" */
                  )}>
                    <div className="topic-box__subject">
                      <small>{curriculumName}</small>
                      {record.unit.name}
                    </div>

                    <div className="topic-box__title">
                      {record.topic.description}
                    </div>
                    {
                      record.content.meta &&
                      <div className="topic-box__type">
                        {record.content.meta.label}
                      </div>
                    }
                    <div className='topic-box__learning_counts_container'>
                      <div className={classNames(
                        "topic-box__learning_counts",
                        { "topic-box__learning_counts--many": isManyLearningCount(record.content.curriculum_code) }
                      )
                      } >
                        {showLearningCount(record.content.curriculum_code)}
                      </div>
                    </div>
                    <div className="topic-box__button">
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          //record.content.meta!.type = '科目修了テスト'
                          if (record.content.meta!.type === '科目修了テスト') {
                            setSelectedContent({ content_id: record.content.id, rank: record.rank, m_entrust: record.m_entrust })
                            setShowConfirm(true)
                          } else {
                            startStudy({ content_id: record.content.id, m_entrust: record.m_entrust })
                          }
                        }}
                        className={
                          classNames(
                            "button button--primary",
                            { "is-disabled": isDisabledContent(record.content.id) }
                          )
                        }
                        href="#!">おまかせ学習を始める</a>
                    </div>
                    { record.content.pdf_ready &&
                    <div className="topic-box__pdf-download-button-container">
                      <button
                        disabled={!record.content.pdf_ready}
                        onClick={()=> downloadPdf(record.content.curriculum_code)}
                        className="pdf-download-button"
                      >
                        PDF
                        <div className="pdf-download-button__icon" />
                      </button>
                    </div>
                    }
                  </div>
                </React.Fragment>
              ))}
            </>}
        </div>

        {entrustStudyRecords && entrustStudyRecords.length > 0 && <>
          <div className="entrust-center">
            <Link
              to={`/entrust_setting/${curriculum}`}
              className="button button--outline"
            >おまかせ学習設定</Link>
          </div>
          <div className="site-main__main-contents">
            <div className="back-home">
              <Link to='/' className="back-home__link">マイページへ戻る</Link>
            </div>
          </div>
        </>}

        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          <li className="breadcrumbs__item">
            おまかせ学習({curriculumName})
          </li>
        </ol>
      </SiteMain>
    </React.Fragment>
  );
}

export default EntrustStudyIndex
