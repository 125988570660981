import React from 'react';
import { HashRouter, Route } from 'react-router-dom';

import ScrollToTop from 'components/parts/ScrollToTop';
import Footer from 'components/parts/Footer';
import PrivateRoute from 'containers/parts/PrivateRoute';
import Notification from 'containers/parts/Notification';

import PasswordReminder from 'containers/parent/PasswordReminder'
import PasswordReset from 'containers/common/PasswordReset'
import Login from 'containers/common/Login'
import Logout from 'containers/common/Logout';
import Infos from 'containers/common/Infos';
import Info from 'containers/common/Info';
import SettingPasswordEdit from 'containers/common/SettingPasswordEdit';
import SettingProfileEdit from 'containers/parent/SettingProfileEdit';
import SettingPurchaseLogs from 'containers/parent/SettingPurchaseLogs';
import StudentStudyLogs from 'containers/parent/StudentStudyLogs';
import StudentStudyResultCurriculum from 'containers/parent/StudentStudyResultCurriculum';
import StudentStudyResultCourse from 'containers/parent/StudentStudyResultCourse';
import StudentStudyResultUnit from 'containers/parent/StudentStudyResultUnit';
import StudentStudyResultUnitResult from 'containers/parent/StudentStudyResultUnitResult';
import StudentStudyCourseCompleteResult from 'containers/parent/StudentStudyCourseCompleteResult';

import Mypage from 'containers/parent/Mypage';

const App: React.FC = () => {
  return (
    <React.Fragment>
      <Notification />
      <HashRouter>
        <ScrollToTop />

        <Route exact path='/password_reminder' component={PasswordReminder} />
        <Route exact path='/password_reset' component={PasswordReset} />
        <Route exact path='/login' component={Login} />
        <Route exact path='/logout' component={Logout} />

        <PrivateRoute exact path='/' component={Mypage} />
        <PrivateRoute exact path='/infos' component={Infos} />
        <PrivateRoute exact path='/infos/:id' component={Info} />
        <PrivateRoute exact path='/setting/password_edit' component={SettingPasswordEdit} />
        <PrivateRoute exact path='/setting/profile_edit' component={SettingProfileEdit} />
        <PrivateRoute exact path='/setting/purchase_logs' component={SettingPurchaseLogs} />

        <PrivateRoute exact path='/student/study_logs' component={StudentStudyLogs} />
        <PrivateRoute exact path='/student/study_result/:curriculum_id' component={StudentStudyResultCurriculum} />
        <PrivateRoute exact path='/student/study_result/courses/:course_id' component={StudentStudyResultCourse} />
        <PrivateRoute exact path='/student/course_complete_results/:unit_id' component={StudentStudyCourseCompleteResult} />
        <PrivateRoute exact path='/student/study_result/units/:unit_id' component={StudentStudyResultUnit} />
        <PrivateRoute exact path='/student/study_result/unit_results/:unit_id' component={StudentStudyResultUnitResult} />
      </HashRouter>

      <Footer />
    </React.Fragment>
  );
}

export default App;