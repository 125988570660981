import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';

import Footer from 'components/parts/Footer';
import ScrollToTop from 'components/parts/ScrollToTop';
import PrivateRoute from 'containers/parts/PrivateRoute';
import Notification from 'containers/parts/Notification';

import Login from 'containers/common/Login'
import Logout from 'containers/common/Logout';
import Infos from 'containers/common/Infos';
import Info from 'containers/common/Info';
import PasswordReset from 'containers/common/PasswordReset'
import PasswordReminder from 'containers/common/PasswordReminder'
import SettingPasswordEdit from 'containers/common/SettingPasswordEdit';

import Mypage from 'containers/trainer/Mypage';
import TrainerRealtimeView from 'containers/trainer/RealtimeView';
import CsvDownload from 'containers/trainer/CsvDownload';
import StudySummaries from 'containers/trainer/StudySummaries';
import SettingProfileEdit from 'containers/trainer/SettingProfileEdit';

import StudentArea from 'containers/trainer/StudentArea';
import StudentSchedules from 'containers/trainer/StudentSchedules';
import StudentStudyLogs from 'containers/trainer/StudentStudyLogs';
import StudentMessages from 'containers/trainer/StudentMessages';
// issue-547
import StudentBookmark from 'containers/trainer/StudentBookmark';
import StudentStudyResult from 'containers/trainer/StudentStudyResult';
import StudentStudyResultCurriculum from 'containers/trainer/StudentStudyResultCurriculum';
import StudentStudyResultCourse from 'containers/trainer/StudentStudyResultCourse';
import StudentStudyResultUnit from 'containers/trainer/StudentStudyResultUnit';
import StudentStudyResultUnitResult from 'containers/trainer/StudentStudyResultUnitResult';
import StudentStudyResultCourseCompleteTest from 'containers/trainer/StudentStudyResultCourseCompleteTest';

import StudyCurriculum from 'containers/trainer/StudyCurriculum';
import StudyCourse from 'containers/trainer/StudyCourse';
import StudyUnit from 'containers/trainer/StudyUnit';
import StudyTopic from 'containers/trainer/StudyTopic';

import DelegateLogin from 'containers/trainer/DelegateLogin'

const App: React.FC = () => {
  return (
    <React.Fragment>
      <Notification />
      <HashRouter>
        <ScrollToTop />

        <Route exact path='/login' component={Login} />
        <Route exact path='/delegate_login' component={DelegateLogin} />
        <Route exact path='/logout' component={Logout} />
        <Route exact path='/password_reset' component={PasswordReset} />
        <Route exact path='/password_reminder' component={PasswordReminder} />

        <PrivateRoute exact path='/' component={Mypage} />
        <PrivateRoute exact path='/csv_download' component={CsvDownload} />
        <PrivateRoute exact path='/study_summaries' component={StudySummaries} />
        <PrivateRoute exact path='/realtime_view/:classId' component={TrainerRealtimeView} />
        <PrivateRoute exact path='/realtime_view' component={TrainerRealtimeView} />

        <PrivateRoute path='/students/:id'>
          <StudentArea>
            <Switch>
              <Route exact path='/students/:id/messages' component={StudentMessages} />
              {/* // issue-547 */}
              <Route exact path='/students/:id/bookmark' component={StudentBookmark} />
              <Route exact path='/students/:id/schedules' component={StudentSchedules} />
              <Route exact path='/students/:id/study_logs' component={StudentStudyLogs} />
              <Route exact path='/students/:id/study_result' component={StudentStudyResult} />
              <Route exact path='/students/:id/study_result/:curriculum_id' component={StudentStudyResultCurriculum} />
              <Route exact path='/students/:id/study_result/courses/:course_id' component={StudentStudyResultCourse} />
              <Route exact path='/students/:id/study_result/units/:unit_id' component={StudentStudyResultUnit} />
              <Route exact path='/students/:id/study_result/unit_results/:unit_id' component={StudentStudyResultUnitResult} />
              <Route exact path='/students/:id/study_result/course_complete_tests/:unit_id' component={StudentStudyResultCourseCompleteTest} />
            </Switch>
          </StudentArea>
        </PrivateRoute>

        <PrivateRoute exact path='/infos' component={Infos} />
        <PrivateRoute exact path='/infos/:id' component={Info} />
        <PrivateRoute exact path='/setting/profile_edit' component={SettingProfileEdit} />
        <PrivateRoute exact path='/setting/password_edit' component={SettingPasswordEdit} />

        <PrivateRoute exact path='/study/:curriculum_id' component={StudyCurriculum} />
        <PrivateRoute exact path='/study/courses/:course_id' component={StudyCourse} />
        <PrivateRoute exact path='/study/units/:unit_id' component={StudyUnit} />
        <PrivateRoute exact path='/study/topics/:topic_id' component={StudyTopic} />
      </HashRouter>

      <Footer />
    </React.Fragment>
  );
}

export default App;