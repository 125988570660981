import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router'

import { AppState } from "store";
import tragetComponent from 'components/student/Schedules';
import studentActions from 'actions/studentActions';

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch })

const mapStateToProps = (appState: AppState, ownProps: RouteComponentProps) => {
  return {
    api: appState.repository.authorizedApi!,
    student: appState.student,
    ...ownProps,
  }
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  { dispatch } : ReturnType<typeof mapDispatchToProps>) => {
  return {
    ...stateProps,
    fetchData: () => {
      dispatch(studentActions.fetchScheduleRecords({ initialize: true }))
    },
    fetchNext: () => {
      dispatch(studentActions.fetchScheduleRecords({ initialize: false }))
    }
  }
}

type connectMappedProps = ReturnType<typeof mergeProps>
export type mappedProps = connectMappedProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(tragetComponent))
