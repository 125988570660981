import { call, put, select, takeEvery } from 'redux-saga/effects'

import * as apiService from 'services/api'
import * as cookiesService from 'services/cookies'
import sessionActions from 'actions/sessionActions'
import repositoryActions from 'actions/repositoryActions'
import parentActions from 'actions/parentActions'
import uiActions from 'actions/uiActions'
import { fetchMasterData }  from './ui'
import { AppState } from 'store';
import * as Models from 'models/api'

export function* loadSession() {
  const state: AppState = yield select()
  if (state.session.loaded) {
    return
  }

  try {
    const token = yield call(
      cookiesService.getAuthCookie, state.repository.cookies)
    const ui = state.ui.type! as Models.LoginableUIType
    if (!Models.loginableUIs.includes(ui)) {
      throw new Error(`invalid ui : ${ui}`);
    }

    if (token) {
      yield put(repositoryActions.initAuthorizedApi(token));
      yield updateUser();

      // MARK: ログイン時にマスターデータをロードする
      yield fetchMasterData()
    }
  } catch (e) {
    yield call(deleteSession)
  }
  yield put(sessionActions.setLoaded());
}

export function* loadDelegateSession (action: ReturnType<typeof sessionActions.loadDelegateSession>) {
  const state: AppState = yield select()
  try {
    const ui = state.ui.type! as Models.LoginableUIType
    if (ui !== 'trainer') {
      throw new Error(`invalid ui : ${ui}`);
    }

    const token = action.payload
    yield put(repositoryActions.initAuthorizedApi(token));
    yield updateUser();

    // MARK: ログイン時にマスターデータをロードする
    yield fetchMasterData()
  } catch (e) {
    console.log(e)
    yield put(uiActions.showApiErrorNotification(e));
  }
}

export function* createSession (action: ReturnType<typeof sessionActions.createSession>) {
  try {
    const state: AppState = yield select()
    const ui = state.ui.type! as Models.LoginableUIType
    if (!Models.loginableUIs.includes(ui)) {
      throw new Error(`invalid ui : ${ui}`);
    }
    const response: apiService.createSessionResponse = yield call(
      apiService.createSession,
      yield select(x => x.repository.api),
      { ...action.payload, ui });
    const session = response.data
    yield put(repositoryActions.initAuthorizedApi(session.jwt));
    yield call(
      cookiesService.setAuthCookie,
      yield select(x => x.repository.cookies), session.jwt, session.exp)

    if (ui === 'student' && session.student && session.student.status === 'suspension') {
      // MARK: suspension statusのユーザはcurrent apiの実行権限がない
      let user = session.student;
      yield put(sessionActions.setUser({
        ui, user
      }));
    } else {

      // MARK: current user apiからデータを取得
      // MARK: password_updatedがcreateSession apiにしか含まれてないのでmergeする
      let user = (session.student || session.parent || session.trainer || session.instructor)!
      const response2: apiService.getCurrentUserResponse = yield call(
        apiService.getCurrentUser,
        yield select(x => x.repository.authorizedApi), { ui });
      const data = response2.data

      let user2;
      if (ui === 'parent') {
        // MARK: parentの場合はstudentと同じ形式でデータが返ってくる
        user2 = data.student!.parent

        // MARK: ナビゲーションで必要なため、受講生情報を保存する
        yield put(parentActions.setStudent({
          record: data.student! as Models.StudentUser
        }));
      } else {
        user2 = (data.student || data.trainer || data.instructor)!
      }

      yield put(sessionActions.setUser({
        ui,
        user: {
          ...user,
          ...user2,
        }
      }));
    }

    // MARK: ログイン時にマスターデータをロードする
    yield fetchMasterData()

    action.payload.promises && action.payload.promises.resolve()
  } catch (e) {
    yield call(deleteSession)
    action.payload.promises && action.payload.promises.reject(e)
  }
}

export function* deleteSession () {
  yield put(sessionActions.unsetUser());
  yield call(
    cookiesService.removeAuthCookie,
    yield select(x => x.repository.cookies))
}

export function* updateUser () {
  const state: AppState = yield select()
  const ui = state.ui.type! as Models.LoginableUIType
  if (!Models.loginableUIs.includes(ui)) {
    throw new Error(`invalid ui : ${ui}`);
  }

  const response: apiService.getCurrentUserResponse = yield call(
    apiService.getCurrentUser,
    yield select(x => x.repository.authorizedApi!), { ui });
  const data = response.data

  let user;
  if (ui === 'parent') {
    // MARK: parentの場合はstudentと同じ形式でデータが返ってくる
    user = data.student!.parent

    // MARK: ナビゲーションで必要なため、受講生情報を保存する
    yield put(parentActions.setStudent({
      record: data.student! as Models.StudentUser
    }));
  } else {
    user = (data.student || data.trainer || data.instructor)!
  }

  yield put(sessionActions.setUser({ user, ui }));
}

function* sessionSaga() {
  yield takeEvery(sessionActions.createSession, createSession);
  yield takeEvery(sessionActions.deleteSession, deleteSession);
  yield takeEvery(sessionActions.loadSession, loadSession);
  yield takeEvery(sessionActions.loadDelegateSession, loadDelegateSession);
}

export default sessionSaga;
