import React, { useState } from 'react';

import { StudyCourseCompletionTestUnitRecord, } from 'models/api';
import { CurridulumType } from 'utils/constants';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { CourseCompletionTestConfirmModal } from 'components/student/common/CourseCompletionTestConfirmModal';

type props = {
  curriculumObj: Pick<CurridulumType, 'name' | 'al_engine_connected'>;
  testRecord: StudyCourseCompletionTestUnitRecord;
  startStudy: (props: { content_id: number, unit_id: number, type?: 'completion_test' }) => void;
  disabledStartStudy: boolean;
}

export const CourseCompletionTestBox: React.FC<props> = ({
  curriculumObj,
  testRecord,
  startStudy,
  disabledStartStudy,
}: props) => {
  const [showConfirm, setShowConfirm] = useState(false)

  return <>
    <CourseCompletionTestConfirmModal
      show={showConfirm}
      onSubmit={() => {
        startStudy({
          type: 'completion_test',
          content_id: testRecord.unit.content_id,
          unit_id: testRecord.unit.id
        })
      }}
      onClose={() => setShowConfirm(false)} />
    <div className="test-box">
      <div className="test-box__title test-box__title--large">
        科目修了テスト
      </div>

      {
        !testRecord.unit.attend && <>
          {
            !testRecord.unit.available &&
            <div className="test-box__lead">
              全ての単元学習を完了すると「科目修了テスト」の受験ができるようになります。
            </div>
          }

          {
            testRecord.unit.available &&
            <div className="test-box__lead">
              <strong>全ての単元学習が完了しましたので「科目修了テスト」を受験してください。</strong>
            </div>
          }
       </>
      }

      {
        // MARK: 修了前
        !testRecord.unit.attend &&
        <div className="test-box__body">
          <div className="test-box__item">
            <div className="test-box__unit-title test-box__unit-title--completion">
              科目修了テスト
            </div>
            <div className="test-box__status test-box__status--completion is-disabled">
              未受験
            </div>
            <div className="test-box__text is-disabled _pc">
              テスト結果
            </div>
            <div className="test-box__buttons">
              <div className="test-box__button test-box__button--result is-disabled _sp">
                テスト結果
              </div>

              {
                /// 一般教材の場合はレコメンドからしか受講出来ない為 false になります
                // ドリル教材の場合は科目内のコンテンツがすべて受講済みの場合に true になります
                // MARK: 通常教科はここから初回受講不可（レコメンドからのみ）
                !testRecord.unit.available &&
                <div className="test-box__button is-disabled">
                  テストを受ける
                </div>
              }

              {
                // MARK: ドリル教科はここから受講可能
                testRecord.unit.available &&
                <a className={classNames("test-box__button", {
                  'is-disabled': disabledStartStudy,
                })} href="#!" onClick={e => {
                  e.preventDefault()
                  setShowConfirm(true)
                }}>テストを受ける</a>
              }
            </div>
          </div>
        </div>
      }

      {
        // MARK: 修了後
        testRecord.unit.attend &&
        <div className="test-box__body">
          <div className="test-box__item">
            <div className="test-box__unit-title test-box__unit-title--completion">
              科目修了テスト
            </div>
            <div className="test-box__status test-box__status--completion">
              受験済
            </div>

            <Link
              to={`/study/course_complete_results/${testRecord.unit.id}`}
              className="test-box__text _pc">
              テスト結果
            </Link>

            <div className="test-box__buttons">
              <Link
                to={`/study/course_complete_results/${testRecord.unit.id}`}
                className="test-box__button test-box__button--result _sp">
                テスト結果
              </Link>

              <a className={classNames("test-box__button", {
                'is-disabled': disabledStartStudy,
              })} href="#!" onClick={e => {
                e.preventDefault()
                setShowConfirm(true)
              }}>再テストを受ける</a>
            </div>
          </div>
        </div>
      }
    </div>
  </>

}
