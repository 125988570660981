import React, { useEffect, useRef } from 'react';
import { Field } from 'redux-form';
import { Link, Redirect } from 'react-router-dom';
import Moment from 'react-moment';

import PrivateHeader from 'containers/parts/PrivateHeader';
import { mappedProps } from 'containers/student/Messages';
import SiteMain from 'containers/parts/SiteMain';

import TrainerProfileBalloon from './TrainerProfileBalloon';
import * as Models  from 'models/api';

const nl2br = require('react-nl2br');

const Messages: React.FC<mappedProps> = (props: mappedProps) => {
  const scrollElement = useRef(null);

  const messages = props.student.message.records
  const studentUser = props.user as Models.StudentUser;

  useEffect(() => {
    props.fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (scrollElement.current) {
      const scrollHeight = (scrollElement.current as any).scrollHeight;
      (scrollElement.current as any).scroll(0, scrollHeight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  if (!props.isAvailableStudentMessage) {
    // MARK: トレーナーがいない
    return <Redirect to="/" />
  }

  return (
    <React.Fragment>
      <PrivateHeader />
      <SiteMain>
        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          <li className="breadcrumbs__item">
            トレーナー相談
          </li>
        </ol>

        <div className="site-main__main-contents">
          <div className="page-title page-title--trainer">
            トレーナー相談
            <TrainerProfileBalloon user={studentUser} />
          </div>

          <div
            ref={scrollElement}
            className="trainer-conversation-list">
            {
              messages && messages.map((record, index) => (
                <div key={index} className="trainer-conversation-list__item">
                  {
                    record.trainer &&
                    <div className={`question-box question-box--trainer`}>
                      <div className="question-box__inner">
                        <div className="question-box__title">
                          { nl2br(record.body) }
                        </div>
                        <div className="question-box__spec">
                          <span className={`question-box__name question-box__name--trainer`}>
                            { record.trainer.nick_name }
                          </span>
                          <span className="question-box__datetime">
                            {/* とりあえず対応: mobile safariで2019-12-05 17:42:21 +0900の形式の日付を処理できない */}
                            <Moment format="M月D日　HH:mm">
                              { record.created_at.replace(/-/g, '/') }
                            </Moment>
                          </span>
                        </div>
                      </div>
                    </div>
                  }
                  {
                    !record.trainer &&
                    <div className={`question-box question-box--student`}>
                      <div className="question-box__inner">
                        <div className="question-box__title">
                          { nl2br(record.body) }
                        </div>
                        <div className="question-box__spec">
                          <span className={`question-box__name question-box__name--student`}>
                            { props.user.nick_name }
                          </span>
                          <span className="question-box__datetime">
                            {/* とりあえず対応: mobile safariで2019-12-05 17:42:21 +0900の形式の日付を処理できない */}
                            <Moment format="M月D日　HH:mm">
                              { record.created_at.replace(/-/g, '/') }
                            </Moment>
                          </span>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              ))
            }
          </div>

          {
            messages && 
            <div className="trainer-conversation-form">
              <form onSubmit={props.handleSubmit}>
                <div className="trainer-conversation-form__textarea">
                  <Field
                    component="textarea"
                    className="textarea textarea--trainer"
                    name="body"
                  />
                </div>
                <div className="trainer-conversation-form__button">
                  <button
                  disabled={props.pristine || props.submitting || props.invalid}
                  className="button button--large button--no-chevron button--trainer">
                    送信する</button>
                </div>
              </form>
            </div>
          }

          <div className="back-home">
            <Link to='/' className="back-home__link">マイページへ戻る</Link>
          </div>
        </div>

        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          <li className="breadcrumbs__item">
            トレーナー相談
          </li>
        </ol>
      </SiteMain>
    </React.Fragment>
  );
}

export default Messages