import validator from 'validator';
import moment from 'moment';

export const emailValidator = (value: string) => {
  return validator.isEmail(value);
}

export const zenkakuValidator = (value: string) => {
  // 全角スペースは認めない
  return !validator.isHalfWidth(value) && !value.includes('　');
  //return /^[^\x01-\x7E\xA1-\xDF]+$/i.test(value);
}

export const zenkanaValidator = (value: string) => {
  return /^[\u30A0-\u30FF]+$/.test(value);
}

export const postalcodeValidator = (value: string) => {
  let targetValue = value
  // MARK: ハイフンありはエラー扱い
  if (value.includes('-')) {
    return false
  }
  targetValue = value.slice(0, 3) + '-' + value.slice(3);
  return validator.isPostalCode(targetValue, 'JP');
}

export const lengthValidator = (value: string, options: { min?: number, max?: number } = { min: 0 }) => {
  const min = options.min || 0;
  const max = options.max;
  return validator.isLength(value, min, max);
}

export const integerRangeValidator = (value: string, options: { min?: number, max?: number } = { min: 0 }) => {
  const min = options.min || 0;
  const max = options.max;
  return validator.isInt(value, { min, max });
}

export const numericValidator = (value: string) => {
  return validator.isNumeric(value);
}

export const phoneNumValidator = (value: string) => {
  if (!lengthValidator(value, { min: 10, max: 11 })) {
    return false
  }
  if (value.includes('-')) {
    return false
  }
  return validator.isNumeric(value);
}

export const omissionValidator = (object: {[key: string]: string | undefined}, keys: string[]) => {
  // 複数のフォームで、部分的に抜けている時にエラー
  const existCount = keys.map(key => {
    if (object[key]) return true;
    else return false;
  }).filter(isExist => isExist === true).length;

  return existCount === keys.length || existCount === 0;
}

export const dateValidator = (y: string, m: string, d: string) => {
  const birthday = [y, m, d].join('-');
  return moment(birthday, 'YYYY-M-D').isValid();
}

const validators = {
  email: emailValidator,
  zenkaku: zenkakuValidator,
  zenkana: zenkanaValidator,
  postalcode: postalcodeValidator,
  length: lengthValidator,
  intrange: integerRangeValidator,
  numeric: numericValidator,
  phonenum: phoneNumValidator,
  omission: omissionValidator,
  date: dateValidator
}

export default validators