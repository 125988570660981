import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router'

import { AppState } from "store";
import targetComponent from 'components/common/Infos';
import infoActions from 'actions/infoActions';
import * as Models from 'models/api';

import * as studentInfo from 'utils/campaign/studentInfo'

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch })

const mapStateToProps = (appState: AppState, ownProps: RouteComponentProps) => {
  const user = appState.session.user!
  const uiType = appState.ui.type
  let studentUser: Models.StudentUser | null = null
  if (uiType === 'student') {
    studentUser = user as Models.StudentUser
  }

  const isAvailableStudentInfo = studentUser && studentInfo.isAvailable(studentUser)

  return {
    isAvailableStudentInfo,
    info: appState.info,
    ...ownProps,
    user,
    studentUser,
  }
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  { dispatch }: ReturnType<typeof mapDispatchToProps>) => {
  return {
    ...stateProps,
    fetchFirst: () => {
      dispatch(infoActions.fetchRecords({ initialize: true }))
    },
    fetchNext: () => {
      dispatch(infoActions.fetchRecords({ initialize: false }))
    }
  }
}

export type mappedProps = ReturnType<typeof mergeProps>

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(targetComponent))