import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RouteProps } from 'react-router'
import { reduxForm, InjectedFormProps, FormErrors, formValueSelector, SubmissionError } from 'redux-form'

import { AppState } from "store";
import SettingProfileEdit from 'components/student/SettingProfileEdit';
import validators from 'utils/validators'
import * as Models from 'models/api'
import {
  plans,
  universities,
  highSchoolKinds,
  facultyKinds,
  faculties,
  prefectures,
  clubKinds } from 'utils/constants'
import studentActions from 'actions/studentActions'

type FormData = {
  email?: string;
  plan_id?: string;
  postal_code?: string;
  prefecture?: string;
  municipality?: string;
  address?: string;
  high_school_kind?: string;
  high_school_name?: string;
  school_grade?: string;
  desired_university?: string;

  trainer_department_type?: string;
  trainer_department_name?: string;
  trainer_university?: string;
  trainer_club_type?: string;
  trainer_club_name?: string;
  recommend_english?: string;
  recommend_math?: string;
  recommend_chemistry?: string;
  recommend_physics?: string;
  recommend_classical_japanese?: string;
  recommend_world_history?: string;
  recommend_japanese_history?: string;
  recommend_english_basic?: string;
  recommend_math_basic?: string;
  recommend_english_words?: string;
  recommend_biology?: string;
  recommend_new_math?: string;
  recommend_junior_english?: string;
  recommend_junior_math?: string;
  recommend_junior_science?: string;
  recommend_modern_japanese?: string;
  /* issue-673 */
  recommend_new_chemistry_2024?: string;
  recommend_new_biology_2024?: string;
  [key: string]: any
};

export const formKey = 'setting_profile_edit'

export const FormValidate = (values: FormData) => {
  const errors: FormErrors<FormData> = {}
  const requiredFields: string[] = [
    'high_school_name',
    'school_grade',
    'email',
    'recommend_english',
    'recommend_math',
    'recommend_chemistry',
    'recommend_physics',
    'recommend_classical_japanese',
    'recommend_world_history',
    'recommend_japanese_history',
    'recommend_math_basic',
    'recommend_english_basic',
    'recommend_english_words',
    'recommend_biology',
    'recommend_new_math',
    'recommend_junior_english',
    'recommend_junior_math',
    'recommend_junior_science',
    'recommend_modern_japanese',
    'recommend_new_chemistry_2024',
    'recommend_new_biology_2024',
  ]
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required'
    }
  })

  if (
    !values.email ||
    !validators.email(values.email) || !validators.length(values.email, { max: 254 })
    ) {
    errors.email = 'Invalid'
  }

  if (
    values.postal_code &&
    !validators.postalcode(values.postal_code)) {
    errors.postal_code = 'Invalid'
  }
  if (
    values.municipality && !validators.length(values.municipality, { max: 10 })
    ) {
    errors.municipality = 'Invalid'
  }
  if (
    values.address && !validators.length(values.address, { max: 254 })
    ) {
    errors.address = 'Invalid'
  }

  return errors
}

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch })

const mapStateToProps = (appState: AppState, routeProps: RouteProps) => {
  // MARK: student uiにしかこのコンポーネントはroutingしない
  const user = appState.session.user! as Models.StudentUser
  const plan = appState.student.plan as Models.StudentPlan
  const initialValues: FormData = {
    ...user,
    recommend_english: ( user.recommend_english || 0 ).toString(),
    recommend_math: ( user.recommend_math || 0 ).toString(),
    recommend_physics: ( user.recommend_physics || 0 ).toString(),
    recommend_chemistry: ( user.recommend_chemistry || 0 ).toString(),
    recommend_classical_japanese: ( user.recommend_classical_japanese || 0 ).toString(),
    recommend_world_history: ( user.recommend_world_history || 0 ).toString(),
    recommend_japanese_history: ( user.recommend_japanese_history || 0 ).toString(),
    recommend_english_basic: ( user.recommend_english_basic || 0 ).toString(),
    recommend_math_basic: ( user.recommend_math_basic || 0 ).toString(),
    recommend_english_words: ( user.recommend_english_words || 0 ).toString(),
    recommend_biology: ( user.recommend_biology || 0 ).toString(),
    recommend_new_math: ( user.recommend_new_math || 0 ).toString(),
    recommend_junior_english: ( user.recommend_junior_english || 0 ).toString(),
    recommend_junior_math: ( user.recommend_junior_math || 0 ).toString(),
    recommend_junior_science: ( user.recommend_junior_science || 0 ).toString(),
    recommend_modern_japanese: ( user.recommend_modern_japanese || 0 ).toString(),
    recommend_new_chemistry_2024: ( user.recommend_new_chemistry_2024 || 0 ).toString(),
    recommend_new_biology_2024: ( user.recommend_new_biology_2024 || 0 ).toString(),
  }

  // MARK: https://github.com/gatjp/kawai1-front/issues/486
  // MARK: https://github.com/gatjp/kawai1-front/issues/482
  // if (user.corporation) {
  //   const curriculums = getCorporateCurriculums(user)
  //   const curriculumIds = curriculums.map(x => x.id)
  //   user.visible_curriculums.forEach(x => {
  //     if (!curriculumIds.includes(x.id)) {
  //       (initialValues as any)[`recommend_${x.code}`] = '0';
  //     }
  //   })
  // }

  const selector = formValueSelector(formKey);
  const facultyKindName = selector(appState, 'trainer_department_type');
  const facultyKind = facultyKinds.find(x => x.name === facultyKindName);
  const selectableFaculties = facultyKind ?
    faculties.filter(x => x.kind_id === facultyKind.id) : [];
  const selectClubKind = !!selector(appState, 'club_kind');
  const masterData = appState.ui.master.records

  const selectablePlans = plans.filter(x => x.id > user.plan.id!)

  return {
    api: appState.repository.authorizedApi!,
    masterData,
    user,
    plan,
    initialValues,
    highSchoolKinds,
    facultyKinds,
    prefectures,
    selectableFaculties,
    selectClubKind,
    clubKinds,
    universities,
    postalCode: selector(appState, 'postal_code'),
    planId: selector(appState, 'plan_id'),
    selectablePlans,
  }
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  { dispatch }: ReturnType<typeof mapDispatchToProps>) => {
  return {
    ...stateProps,
    fetchData: () => {
      dispatch(studentActions.fetchPlan())
    },
    onSubmit: (values: FormData) => {
      const recommend_english =  Number(values.recommend_english!)
      const recommend_math =  Number(values.recommend_math!)
      const recommend_chemistry =  Number(values.recommend_chemistry!)
      const recommend_physics =  Number(values.recommend_physics!)
      const recommend_classical_japanese =  Number(values.recommend_classical_japanese!)
      const recommend_japanese_history =  Number(values.recommend_japanese_history!)
      const recommend_world_history =  Number(values.recommend_world_history!)
      const recommend_english_basic =  Number(values.recommend_english_basic!)
      const recommend_math_basic =  Number(values.recommend_math_basic!)
      const recommend_english_words =  Number(values.recommend_english_words!)
      const recommend_biology =  Number(values.recommend_biology!)
      const recommend_new_math =  Number(values.recommend_new_math!)
      const recommend_junior_english =  Number(values.recommend_junior_english!)
      const recommend_junior_math =  Number(values.recommend_junior_math!)
      const recommend_junior_science =  Number(values.recommend_junior_science!)
      const recommend_modern_japanese =  Number(values.recommend_modern_japanese!)
      const recommend_new_chemistry_2024 =  Number(values.recommend_new_chemistry_2024!)
      const recommend_new_biology_2024 =  Number(values.recommend_new_biology_2024!)

      const plan_id = values.plan_id ? Number(values.plan_id) : undefined
      return new Promise<void>((resolve, reject) => {
        const params: Models.UpdateStudentProfileParams = {
          plan_id: plan_id,
          email: values.email!,
          postal_code: values.postal_code!,
          prefecture: values.prefecture!,
          municipality: values.municipality!,
          address: values.address!,
          high_school_kind: values.high_school_kind!,
          high_school_name: values.high_school_name!,
          school_grade: values.school_grade!,
          desired_university: values.desired_university!,
          trainer_department_type: values.trainer_department_type!,
          trainer_department_name: values.trainer_department_name!,
          trainer_university: values.trainer_university!,
          trainer_club_type: values.trainer_club_type!,
          trainer_club_name: values.trainer_club_name!,
          recommend_english: recommend_english === 0 ? null : recommend_english,
          recommend_math: recommend_math === 0 ? null : recommend_math,
          recommend_chemistry: recommend_chemistry === 0 ? null : recommend_chemistry,
          recommend_physics: recommend_physics === 0 ? null : recommend_physics,
          recommend_classical_japanese: recommend_classical_japanese === 0 ? null : recommend_classical_japanese,
          recommend_world_history: recommend_world_history === 0 ? null : recommend_world_history,
          recommend_japanese_history: recommend_japanese_history === 0 ? null : recommend_japanese_history,
          recommend_english_basic: recommend_english_basic === 0 ? null : recommend_english_basic,
          recommend_math_basic: recommend_math_basic === 0 ? null : recommend_math_basic,
          recommend_english_words: recommend_english_words === 0 ? null : recommend_english_words,
          recommend_biology: recommend_biology === 0 ? null : recommend_biology,
          recommend_new_math: recommend_new_math === 0 ? null : recommend_new_math,
          recommend_junior_english: recommend_junior_english === 0 ? null : recommend_junior_english,
          recommend_junior_math: recommend_junior_math === 0 ? null : recommend_junior_math,
          recommend_junior_science: recommend_junior_science === 0 ? null : recommend_junior_science,
          recommend_modern_japanese: recommend_modern_japanese === 0 ? null : recommend_modern_japanese,
          recommend_new_chemistry_2024: recommend_new_chemistry_2024 === 0 ? null : recommend_new_chemistry_2024,
          recommend_new_biology_2024: recommend_new_biology_2024 === 0 ? null : recommend_new_biology_2024,
        }

        dispatch(studentActions.updateProfile({ params, promises: { resolve, reject } }))
      }).catch((e: Error) => {
        throw new SubmissionError({})
      })
    }
  }
}

type connectMappedProps = ReturnType<typeof mergeProps>
export type mappedProps = connectMappedProps & InjectedFormProps<FormData, connectMappedProps>

const form = reduxForm<FormData, connectMappedProps>({
  form: formKey,
  validate: FormValidate
})(SettingProfileEdit)

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(form)
