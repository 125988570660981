import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router'

import { AppState } from "store"
import TrainerCsvDownload from 'components/trainer/CsvDownload'
import { curriculums, CurridulumType } from 'utils/constants'
import uiActions from 'actions/uiActions'
import trainerActions from 'actions/trainerActions';
import { getNameCookie, removeNameCookie, setNameCookie } from 'services/cookies';
import moment from 'moment';

const downloadTypes = [
  {
    name: '学習結果',
    type: 'study_results',
  },
  {
    name: '学習履歴',
    type: 'study_logs',
  },
  {
    name: '受講前・後テスト結果',
    type: 'pre_post_test_logs',
  },
]

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch })

const mapStateToProps = (appState: AppState, ownProps: RouteComponentProps) => {
  return {
    ...ownProps,
    trainer: appState.trainer,
    user: appState.session.user!,
    cookies: appState.repository.cookies!,
    curriculums,
    downloadTypes,
  }
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  { dispatch }: ReturnType<typeof mapDispatchToProps>) => {
  const getStatusKey = (trainerId: string, c: CurridulumType, downloadType: string) => {
    return `csv_download_${c.code}_${downloadType}_${trainerId}`
  }
  const getCreateCsvExportStatus = (key: string) => {
    return getNameCookie(stateProps.cookies, key)
  }
  const removeCreateCsvExportStatus = (key: string) => {
    return removeNameCookie(stateProps.cookies, key)
  }

  return {
    ...stateProps,
    getStatusKey,
    getCreateCsvExportStatus,
    removeCreateCsvExportStatus,
    getDefaultStatuses: () => {
      const initStatuses: any = {}
      for (const curriculum of stateProps.curriculums) {
        for (const downloadType of stateProps.downloadTypes) {
          const key = getStatusKey(stateProps.user.login, curriculum, downloadType.type)
          initStatuses[key] = getCreateCsvExportStatus(key)
        }
      }
      return initStatuses
    },
    setCreateCsvExportStatus: (key: string) => {
      const expiredAt = moment().add(1, 'hours').unix()
      setNameCookie(stateProps.cookies, key, key, expiredAt)
    },
    createCsvExport: (params: {
      type: 'study_logs' | 'study_results' | 'pre_post_test_logs';
      curriculum_id: number;
    }) => {
      return new Promise<void>((resolve, reject) => {
        dispatch(trainerActions.createCsvExport({ params, promises: { resolve, reject } }))
      }).catch(e => {
        console.error(e)
        dispatch(uiActions.showApiErrorNotification(e))
      })
    },
    getHistories: () => {
      const page = stateProps.trainer.csvExportHistory
      return page.records
    },
    fetchFirst: () => {
      dispatch(trainerActions.fetchCsvExportHistories({ initialize: true }))
    },
    hasNext: () => {
      const page = stateProps.trainer.csvExportHistory
      return page.has_next
    },
    fetchNext: () => {
      dispatch(trainerActions.fetchCsvExportHistories({ initialize: false }))
    }
  }
}

export type mappedProps = ReturnType<typeof mergeProps>

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(TrainerCsvDownload))
