import React from 'react';

import footerLogo from 'assets/img/img_footer_logo.png';
import txtFooter from 'assets/img/txt_footer.png';

const Footer: React.FC = () => {

  const url = window.location.href;
  const realtimeView = url.indexOf("realtime_view");
  if (realtimeView > 0) {
    // リアルタイムビューの場合
    return (<>
    <footer className="site-footer-realtime">
      <div className="site-footer-realtime__copyright">
        &copy; KAWAIJUKUOne.
      </div>
      <div className="site-footer-realtime__body">
        <div className="site-footer-realtime__logo">
          <a href="https://www.kawai-juku.ac.jp/" target="_blank" rel="noopener noreferrer">
            <img src={footerLogo} alt="河合塾グループ" className="site-footer-realtime__image" />
          </a>
        </div>
        <div className="site-footer-realtime__nav">
          <img src={txtFooter} alt="河合塾グループサイトのご案内" className="site-footer-realtime__nav-image" />
          <dl className="footer-nav">
            <dt className="footer-nav__title"></dt>
            <dd className="footer-nav__item">
              <a href="https://www.kawaijuku.jp/jp/kawaijuku/" className="footer-nav__link" target="_blank" rel="noopener noreferrer">河合塾グループについて</a>
            </dd>
            <dd className="footer-nav__item">
              <a href="https://www.kawaijuku.jp/jp/kawaijuku/education/" className="footer-nav__link" target="_blank" rel="noopener noreferrer">事業紹介</a>
            </dd>
            <dd className="footer-nav__item">
              <a href="https://www.kawaijuku.jp/jp/sr/" className="footer-nav__link" target="_blank" rel="noopener noreferrer">社会へ向けた取り組み(SR)</a>
            </dd>
            <dd className="footer-nav__item">
              <a href="https://www.kawaijuku.jp/jp/recruit/" className="footer-nav__link" target="_blank" rel="noopener noreferrer">採用情報</a>
            </dd>
            <dd className="footer-nav__item">
              <a href="https://www.kawaijukuone.co.jp/contact/" className="footer-nav__link" target="_blank" rel="noopener noreferrer">お問い合わせ</a>
            </dd>
          </dl>
        </div>
      </div>
    </footer>
  </>);
  }

  return (
    <footer className="site-footer">
      <div className="site-footer__copyright">
        &copy; KAWAIJUKUOne.
      </div>
      <div className="site-footer__body">
        <div className="site-footer__logo">
          <a href="https://www.kawai-juku.ac.jp/" target="_blank" rel="noopener noreferrer">
            <img src={footerLogo} alt="河合塾グループ" className="site-footer__image" />
          </a>
        </div>
        <div className="site-footer__nav">
          <img src={txtFooter} alt="河合塾グループサイトのご案内" className="site-footer__nav-image" />
          <dl className="footer-nav">
            <dt className="footer-nav__title"></dt>
            <dd className="footer-nav__item">
              <a href="https://www.kawaijuku.jp/jp/kawaijuku/" className="footer-nav__link" target="_blank" rel="noopener noreferrer">河合塾グループについて</a>
            </dd>
            <dd className="footer-nav__item">
              <a href="https://www.kawaijuku.jp/jp/kawaijuku/education/" className="footer-nav__link" target="_blank" rel="noopener noreferrer">事業紹介</a>
            </dd>
            <dd className="footer-nav__item">
              <a href="https://www.kawaijuku.jp/jp/sr/" className="footer-nav__link" target="_blank" rel="noopener noreferrer">社会へ向けた取り組み(SR)</a>
            </dd>
            <dd className="footer-nav__item">
              <a href="https://www.kawaijuku.jp/jp/recruit/" className="footer-nav__link" target="_blank" rel="noopener noreferrer">採用情報</a>
            </dd>
            <dd className="footer-nav__item">
              <a href="https://www.kawaijukuone.co.jp/contact/" className="footer-nav__link" target="_blank" rel="noopener noreferrer">お問い合わせ</a>
            </dd>
          </dl>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
