import React, { useEffect } from 'react';
import queryString from 'query-string'

import { mappedProps } from 'containers/signup/SignupResult';
import SiteMainArea from '../parts/SiteMainArea';
import PublicHeader from '../parts/PublicHeader';
import SignupBoxSteps from './SignupBoxSteps';
import { useHistory } from 'react-router-dom';

const Result: React.FC<mappedProps> = (props: mappedProps) => {
  const history = useHistory()

  useEffect(() => {
    const scFunc = (window as any).sc_func
    if (scFunc) { scFunc('kjone', 'result', '') }
  }, [])

  const queryParams = queryString.parse(history.location.search);
  const fromMoshinavi = queryParams.external_alliance === 'moshinavi'

  return (
    <React.Fragment>
      <PublicHeader />
      <SiteMainArea>
        <div className="register-box">

          <div className="register-box__title">
            河合塾One 新規お申し込み
          </div>
          <div className="register-box__lead">
            {
              fromMoshinavi ? 
              'お申込み日から１４日間、無料でご利用いただけます。':
              'お申込み日から７日間、無料でご利用いただけます。'
            }
          </div>

          <SignupBoxSteps step={5} />

          <div className="register-box__subtitle register-box__subtitle--complete">
            お申し込みが完了しました。
          </div>

          <div className="register-box__text register-box__text--complete">
            受講者・保護者に申込完了および案内メールをお送りしました。<br />
            メール内に記載のログインID・パスワードでログインをしてください。
          </div>

          <div className="register-box__button register-box__button--complete">
            <button
              onClick={(e) => {
                e.preventDefault();
                window.open(props.loginPageUrl);
              }}
              className="button button--primary button--xlarge">ログイン
            </button>
          </div>

        </div>
      </SiteMainArea>
    </React.Fragment>
  );
}

export default Result
