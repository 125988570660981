import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router'

import { AppState } from "store"
import TrainerRealtimeView from 'components/trainer/RealtimeView'
import trainerActions from 'actions/trainerActions';
import { curriculums } from 'utils/constants'

import * as Models from "models/api";
const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch })

const mapStateToProps = (appState: AppState, ownProps: RouteComponentProps) => {
  return {
    user: appState.session.user!,
    info: appState.info,
    studyStatus: appState.trainer.studyStatus,
    studyStatusRealtime: appState.trainer.studyStatusRealtime,
    //selectedSchoolClassId: appState.trainer.selectedSchoolClassId,
    trainer: appState.trainer,
    ...ownProps,
    curriculums,
  }
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  { dispatch }: ReturnType<typeof mapDispatchToProps>) => {
  return {
    ...stateProps,
    fetchSchoolClassess: () => dispatch(trainerActions.fetchSchoolClasses()),
    fetchCourses: (curriculum_id: number) => {
      dispatch(trainerActions.fetchCurriculumCourses({ curriculum_id }))
    },
    fetchData: () => {
      dispatch(trainerActions.fetchMypage())
    },
    fetchStudyStatus: ({school_class_id, curriculum_id, beginning_date_on, end_date_on }: Models.FetchStudyStatusParams) => {
      const params = {school_class_id, curriculum_id, beginning_date_on, end_date_on  };
      return new Promise((resolve, reject) => {
        dispatch(trainerActions.fetchStudyStatus({ params}))
      });
    },
    fetchStudyStatusRealtime: ({
      school_class_id, beginning_date_at}: Models.FetchStudyStatusRealtimeParams) => {
      // const params = { school_class_id, beginning_date_on};
      const params = school_class_id === 0 ?
        {beginning_date_at}
        :
        {school_class_id, beginning_date_at};

      return new Promise((resolve, reject) => {
        dispatch(trainerActions.fetchStudyStatusRealtime({ params}))
      });
    },
    stopFetchStudyStatusRealtime: () => {
      dispatch(trainerActions.stopFetchStudyStatusRealtime())
    },

  }
}

export type mappedProps = ReturnType<typeof mergeProps>

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(TrainerRealtimeView))
