import { reducerWithInitialState } from 'typescript-fsa-reducers';
import produce from 'immer'

import temporaryFormActions from 'actions/temporaryFormActions';

export interface TemporaryFormState {
  forms: {
    [key: string]: any;
  }
}

const initialState: TemporaryFormState= {
  forms: {},
};

export const temporaryFormReducer = reducerWithInitialState(initialState)
  .case(temporaryFormActions.setForm, (state, payload) => {
    return produce(state, draftState => {
      draftState.forms[payload.key] = payload.values;
    })
  })
  .case(temporaryFormActions.unsetForm, (state, key) => {
    return produce(state, draftState => {
      draftState.forms[key] = undefined;
    })
  })
