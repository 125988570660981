import React, { useEffect } from 'react';
//import classNames from 'classnames';
//import { Link, NavLink } from 'react-router-dom';

import { mappedProps } from 'containers/parts/SiteMain';
import { hideStudyplusCorporationNames } from 'utils/constants'

type siteMainProps = {
  children?: React.ReactNode;
}

const RealtimeViewMain: React.FC<mappedProps> = (props: mappedProps & siteMainProps) => {
  /* const openRecommendNav = props.sidenav.openRecommendNav
  const setOpenRecommendNav = props.setOpenRecommendNav
  const openStudyNav = props.sidenav.openStudyNav
  const setOpenStudyNav = props.setOpenStudyNav
  const openHistoryNav = props.sidenav.openHistoryNav
  const setOpenHistoryNav = props.setOpenHistoryNav
  const openTestNav = props.sidenav.openTestNav
  const setOpenTestNav = props.setOpenTestNav */
  //const entrustCurriculumsList: string[] = ["junior_english",  "junior_math"] //ここで表示する教科を指定
  //const openEntrustNav = props.sidenav.openEntrustNav
  //const setOpenEntrustNav = props.setOpenEntrustNav
  // issue-545

  //const availCurriculums = props.availCurriculums
  //const curriculums = props.curriculums
  // issue-545
  //const entrustCurriculums = curriculums && curriculums.filter(x => entrustCurriculumsList.includes(x.code))
  // issue-545
  //const alEngineConnectedCurriculums = curriculums && curriculums.filter(x => x.al_engine_connected)

  // MARK: テスト一覧画面に遷移すべき教科のリスト（単元テストか科目修了テストがある）
  //const testListCurridulums = curriculums && curriculums.filter(x => x.al_engine_connected || x.has_completion_test)

  const trainerUser = props.trainerUser

  //const [studyplusAuthToken, setStudyplusAuthToken] = useState<null | { jwt: string; uid: string }>(null)

  useEffect(() => {
    // MARK: 法人トレーナーのみ
    if (trainerUser && trainerUser.corporation && !hideStudyplusCorporationNames.includes(trainerUser.corporation.name)) {
      props.getStudyplusAuthToken().then(x => {
        //setStudyplusAuthToken(x);
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trainerUser]);

  return (
    <main /* className="site-main" style={{"display": "flex", "justifyContent" :"center", "padding": "24px"}} */>
      <div /* className="site-main__main" *//* style={{ "width": "1024px"}} */>
        <div /* className="site-main__main-inner" */>
          { props.children }
        </div>
      </div>
    </main>
  )
}
export default RealtimeViewMain
