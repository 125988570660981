import React, { useState, useEffect } from 'react';
import { Field } from 'redux-form';
import { Link, useParams, Redirect, } from 'react-router-dom';

import { mappedProps } from 'containers/instructor/QuestionForm';
import PrivateHeader from 'containers/parts/PrivateHeader';
import SiteMain from 'containers/parts/SiteMain';
import QuestionDetail from '../parts/QuestionDetail';

import pdfIconImage from 'assets/img/img_pdf.png';

import { renderTextareaField, FormFileInput } from 'components/parts/FormParts'

const nl2br = require('react-nl2br');

type QuestionAttachmentPreviewProps = { attachment: { data: string; file: File; }}
const QuestionAttachmentPreview: React.FC<QuestionAttachmentPreviewProps> = 
(props: QuestionAttachmentPreviewProps) => {
  return (
    <React.Fragment>
      {
        props.attachment.file.type.startsWith('image/') &&
        <React.Fragment>
          <img src={props.attachment.data} alt="添付画像" className="question-form__image" /><br />
          <div className="answer-form__file-name answer-form__file-name--teacher">
            { props.attachment.file.name }
          </div>
        </React.Fragment>
      }
      {
        props.attachment.file.type.startsWith('application/pdf') &&
        <React.Fragment>
          <img src={pdfIconImage} alt="" className="question-form__image question-form__image--pdf" />
          <div className="answer-form__file-name answer-form__file-name--teacher">
            { props.attachment.file.name }
          </div>
        </React.Fragment>
      }
    </React.Fragment>
  )
}

const QuestionForm: React.FC<mappedProps> = (props: mappedProps) => {
  const { id } = useParams();

  const [confirm, setConfirm] = useState(false)
  const [attachmentSizeOver, setAttachmentSizeOver] = useState(false)

  useEffect(() => {
    props.fetchQuestion(Number(id))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const loading = props.instructor.questionDetail.loading

  const question = props.getQuestion(Number(id))

  // MARK: 回答中でない場合は詳細に戻す
  const processingByMyself =
    question &&
    question.statusCode === 'processing' &&
    question.instructor && question.instructor.id === props.user.id

  if (!loading && question && !processingByMyself) {
    return <Redirect to={`/questions/${question.id}`} />;
  }

  if (props.submitSucceeded) {
    return (
      <React.Fragment>
        <PrivateHeader />
        <SiteMain>
          <ol className="breadcrumbs">
            <li className="breadcrumbs__item">
              <Link to='/' className="breadcrumbs__link">マイページ</Link>
            </li>
            <li className="breadcrumbs__item">
              担当教科の質問

            </li>
          </ol>

          <div className="site-main__main-contents">
            <div className="page-title page-title--teacher">
              担当教科の質問
              <Link to='/' className="page-title__link page-title__link--back">マイページへ戻る</Link>
            </div>

            <div className="question-form">
              <div className="question-form__complete question-form__complete--teacher">
                回答を送信しました。
              </div>
            </div>

            <div className="back-home">
              <Link to='/' className="back-home__link">マイページへ戻る</Link>
            </div>
          </div>

          <ol className="breadcrumbs">
            <li className="breadcrumbs__item">
              <Link to='/' className="breadcrumbs__link">マイページ</Link>
            </li>
            <li className="breadcrumbs__item">
              担当教科の質問
            </li>
          </ol>
        </SiteMain>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <PrivateHeader />
      <SiteMain>
        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          <li className="breadcrumbs__item">
            担当教科の質問
          </li>
        </ol>

        <div className="site-main__main-contents">
          <div className="page-title page-title--teacher">
            担当教科の質問
            <Link to='/' className="page-title__link page-title__link--back">マイページへ戻る</Link>
          </div>
          {
            !loading && question &&
            <React.Fragment>
              <div className="page-subtitle">
                質問内容
              </div>

              <QuestionDetail question={question} type={props.user.type} />

              {
                confirm &&
                <div className="answer-form">
                  <div className="answer-form__title answer-form__title--primary">
                    受講生への回答
                    <span className="answer-form__title-note">※テキスト入力は必須です。</span>
                  </div>
                  <div className="answer-form__body">
                    <div className="answer-form__textarea answer-form__textarea--confirm">
                      { nl2br(props.questionBody) }
                    </div>

                    {
                      props.questionAttachment && 
                      <React.Fragment>
                        <div className="question-form__title">
                          添付ファイル
                        </div>
                        <div className="question-form__attachment">
                          <QuestionAttachmentPreview attachment={props.questionAttachment} />
                        </div>
                      </React.Fragment>
                    }

                    <div className="answer-form__confirm-message">
                      上記内容で送信します。
                    </div>
                    <div className="answer-form__buttons">
                      <div className="answer-form__button">
                        <form onSubmit={props.handleSubmit}>
                          <button
                            disabled={props.pristine || props.submitting || props.invalid}
                            className="button button--large button--primary">回答を送る</button>
                        </form>
                      </div>
                      <div className="answer-form__button">
                        <a
                          onClick={(e) => {
                            e.preventDefault()
                            setConfirm(false)
                          }}
                          href="#!"
                          className="button button--large button--back button--gray">内容を修正</a>
                      </div>
                    </div>
                  </div>
                </div>
              }

              {
                !confirm &&
                <div className="answer-form">
                  <div className="answer-form__title answer-form__title--primary">
                    受講生への回答
                    <span className="answer-form__title-note">※テキスト入力は必須です。</span>
                  </div>
                  <div className="answer-form__body">
                    <div className="answer-form__textarea">
                      <Field
                        component={renderTextareaField}
                        className="textarea textarea--required"
                        name="body"
                      />
                    </div>

                    {
                      props.invalid &&
                      <div className="answer-form__error">
                        回答を入力してください。
                      </div>
                    }

                    <div className="answer-form__title">
                      添付ファイル
                    </div>
                    <div className="answer-form__input-file">
                      <div className="input-file">
                        <FormFileInput
                          className="input-file__input"
                          accept="image/png,image/jpeg,application/pdf"
                          limitSize={1024 * 1024 * 10}
                          onFailed={() => {
                            setAttachmentSizeOver(true)
                            props.change('attachment', null)
                          }}
                          onLoad={(data) => {
                            setAttachmentSizeOver(false)
                            props.change('attachment', data)
                          }} />

                        {/* NOTE: おそらくアップロードエラーが出たときにファイル名をここに出すものと思います */}
                        {/*
                        <span className="input-file__file-name">answerimage_upload.jpg</span>
                        */}

                        <div className="input-file__note">
                          アップロード可能なファイル形式：jpg、png, pdf　ファイル容量上限：10MB
                        </div>
                        {
                          attachmentSizeOver &&
                          <div className="input-file__error">
                            <div className="error-message">
                              ファイル容量が上限を超えているためアップロードできません。
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                    <div className="answer-form__attachment">
                      {
                        props.questionAttachment && 
                        <QuestionAttachmentPreview attachment={props.questionAttachment} />
                      }
                    </div>
                    <div className="answer-form__buttons">
                      <div className="answer-form__button">
                        <button
                          onClick={(e) => {
                            e.preventDefault()
                            setConfirm(true)
                          }}
                          disabled={props.pristine || props.submitting || props.invalid}
                          className="button button--large button--primary">回答内容の確認</button>
                      </div>
                      <div className="answer-form__button">
                        <a
                          onClick={(e) => {
                            e.preventDefault()
                            props.updateQuestionToNotAnswered()
                          }}
                          href="#!"
                          className="button button--large button--back button--gray">回答を止める</a>
                      </div>
                    </div>

                    <a
                      href="#!"
                      onClick={(e) => {
                        e.preventDefault()
                        props.updateQuestionToNotAnswered()
                        props.history.push(`/questions/${question.id}/form2`)
                      }}
                      className="answer-form__secretariat-button">事務局対応の質問にする</a>
                  </div>
                </div>
              }

            </React.Fragment>
          }

          <div className="back-home">
            <Link to='/' className="back-home__link">マイページへ戻る</Link>
          </div>
        </div>

        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          <li className="breadcrumbs__item">
            担当教科の質問
          </li>
        </ol>
      </SiteMain>
    </React.Fragment>
  );
}

export default QuestionForm
