import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router'

import { AppState } from "store";
import targetComponent from 'components/trainer/StudyCourse';
import trainerActions from 'actions/trainerActions';

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch })

const mapStateToProps = (appState: AppState, ownProps: RouteComponentProps) => {
  return {
    user: appState.session.user!,
    trainer: appState.trainer,
    ...ownProps,
  }
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  { dispatch }: ReturnType<typeof mapDispatchToProps>) => {
  return {
    ...stateProps,
    fetchData: (course_id: number) => {
      dispatch(trainerActions.fetchStudyCourse({ course_id }))
    },
    getRecord: (course_id: number) => {
      const page = stateProps.trainer.studyCourse
      if (page.course_id !== course_id) {
        return null
      }
      return page.record
    },
    /* startStudy: (params: { content_id: number, return_pathname: string }) => {
      return new Promise<string>((resolve, reject) => {
         dispatch(trainerActions.startStudy({
           params,
           promises: { resolve, reject }
         }))
      })
    }, */
    /* fetchStudySummariesPerUnit: (course_id: number, school_class_id?: number) => {
      dispatch(trainerActions.fetchStudySummariesPerUnit({
        initialize: true,
        course_id,
        school_class_id,
      }))
    },     */    
  }
}

export type mappedProps = ReturnType<typeof mergeProps>
export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(targetComponent))
