import React, { useEffect } from 'react';
import { Link, useParams, useHistory, Redirect } from 'react-router-dom';

import { mappedProps } from 'containers/student/StudyLogBuyTicket';
import PrivateHeader from 'containers/parts/PrivateHeader';
import SiteMain from 'containers/parts/SiteMain';
import classNames from 'classnames';

const StudyLogNoTicket: React.FC<mappedProps> = (props: mappedProps) => {
  const history = useHistory();
  const { curriculum, id } = useParams();

  useEffect(() => {
    props.fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ticketCount = props.student.questionsSheetsCountInMonth.record || 0;

  // MARK: コーポレートユーザ利用不可
  if (props.user.corporation) {
    return <Redirect to='/' />;
  }

  if (ticketCount > 0) {
    return <Redirect to={`/study_logs/${curriculum}/${id}`} />
  }
  
  if (props.submitSucceeded) {
    return (
      <React.Fragment>
        <PrivateHeader />
        <SiteMain>
          <ol className="breadcrumbs">
            <li className="breadcrumbs__item">
              <Link to='/' className="breadcrumbs__link">マイページ</Link>
            </li>
            <li className="breadcrumbs__item">
              <Link to={`/study_logs/${curriculum}`} className="breadcrumbs__link">学習履歴</Link>
            </li>
            <li className="breadcrumbs__item">
              先生への質問
            </li>
          </ol>

          <div className="site-main__main-contents">
            <div className="page-title">
              先生に質問
              <Link to={`/study_logs/${curriculum}`} className="page-title__link page-title__link--back">学習履歴に戻る</Link>
            </div>

            <div className="question-form">
              <div className="question-form__complete">
                質問チケットの購入が完了しました。
              </div>

              <div className="question-form__buttons question-form__buttons--no-border">
                <div className="question-form__button">
                  {/* <Link to={`/study_logs/${curriculum}`} className="button button--large button--back button--gray">学習履歴に戻る</Link> */}
                  <Link to={`/study_logs/${curriculum}/${id}`} className="button button--large button--back button--gray">先生に質問へ戻る</Link>
                </div>
              </div>
            </div>

            <div className="back-home">
              <Link to='/' className="back-home__link">マイページへ戻る</Link>
            </div>
          </div>

          <ol className="breadcrumbs">
            <li className="breadcrumbs__item">
              <Link to='/' className="breadcrumbs__link">マイページ</Link>
            </li>
            <li className="breadcrumbs__item">
              <Link to={`/study_logs/${curriculum}`} className="breadcrumbs__link">学習履歴</Link>
            </li>
            <li className="breadcrumbs__item">
              先生への質問
            </li>
          </ol>
        </SiteMain>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <PrivateHeader />
      <SiteMain>
        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          <li className="breadcrumbs__item">
            <Link to={`/study_logs/${curriculum}`} className="breadcrumbs__link">学習履歴</Link>
          </li>
          <li className="breadcrumbs__item">
            先生への質問
          </li>
        </ol>

        <div className="site-main__main-contents">
          <div className="page-title">
            先生に質問
            <Link to={`/study_logs/${curriculum}`} className="page-title__link page-title__link--back">学習履歴に戻る</Link>
          </div>

          <div className="question-form">
            <form onSubmit={props.handleSubmit}>
              <div className="ticket-purchase">
                <div className="ticket-purchase__title">
                  当月分の質問チケットが<br className="_sp" />{ ticketCount } 枚です。
                </div>
                <div className="ticket-purchase__text">
                  質問をする場合は、質問チケットを<br className="_sp" />購入する必要があります。<br />注意事項をよく読み、<br className="_sp" />「質問チケットを購入する」の<br className="_sp" />ボタンを押してください。
                </div>
                <div className="ticket-purchase__note">
                  <div className="ticket-purchase__note-head">
                    注意事項
                  </div>
                  <div className="ticket-purchase__note-text">
                    質問チケットを購入すると、ご登録いただいているクレジットカードから、550円（税込)がその場で決済されます。<br />必ず保護者の方の許可を得てから、質問チケットを購入してください。
                  </div>
                </div>
                <div className="ticket-purchase__ticket">
                  <div className="ticket-purchase__ticket-inner">
                    質問チケット１枚　<b>550<small>円</small></b><small>&nbsp;（税込)</small>
                  </div>
                </div>
              </div>
              <div className={classNames(
                'question-form__buttons question-form__buttons--no-border',
                { 'question-form__buttons--point': props.canTicketPointExchange })}>
                <div className="question-form__button">
                  <button className="button button--large button--primary">質問チケットを購入する</button>
                </div>

                {
                  props.canTicketPointExchange && 
                  <div className="question-form__button">
                    <button
                      onClick={e => {
                        e.preventDefault()
                        e.currentTarget.disabled = true
                        props.confirmTicketPointExchange().finally(() => {
                          history.push(`/study_logs/${curriculum}/${id}/point_exchange_confirm`)
                        })
                      }}
                      className="button button--large button--point">ポイントで購入する</button>
                  </div>
                }

                <div className="question-form__button">
                  <Link to={`/study_logs/${curriculum}/${id}`} className="button button--large button--back button--gray">前のページへ戻る</Link>
                </div>
              </div>
            </form>
          </div>

          <div className="back-home">
            <a
              onClick={e => {
                e.preventDefault()
                history.push('/')
              }}
              href="#!" className="back-home__link">マイページへ戻る</a>
          </div>

        </div>

        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          <li className="breadcrumbs__item">
            <Link to={`/study_logs/${curriculum}`} className="breadcrumbs__link">学習履歴</Link>
          </li>
          <li className="breadcrumbs__item">
            先生への質問
          </li>
        </ol>
      </SiteMain>
    </React.Fragment>
  )
}

export default StudyLogNoTicket
