import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { mappedProps } from 'containers/student/PayConfirm';
import PublicHeader from '../parts/PublicHeader';
import SiteMainSimple  from '../parts/SiteMainSimple';

const PayConfirm: React.FC<mappedProps> = (props: mappedProps) => {
  const history = useHistory()
  useEffect(() => {
    // この画面に遷移したらログアウトする
    // 再度ログインするとクレカステータスが最新化される
    props.logout()
  });

  useEffect(() => {
    const scFunc = (window as any).sc_func
    if (scFunc) { scFunc('kjone', 'pay_confirm', '') }
  }, [])

  return (
    <React.Fragment>
      <PublicHeader />
      <SiteMainSimple>
        <div className="account-box">
          <div className="account-box__title">
            クレジットカード情報のご登録／更新
          </div>
          <div className="account-box__lead account-box__lead--card">
            クレジットカード情報の登録が完了しました。
          </div>
          <div className="account-box__button">
            <a
              onClick={(e) => {
                e.preventDefault()
                history.push('/')
              }}
              href="#!" className="button button--primary button--large">マイページへ</a>
          </div>
        </div>
      </SiteMainSimple>
    </React.Fragment>
  );
}

export default PayConfirm
