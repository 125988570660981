import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router'
import { reduxForm, InjectedFormProps, FormErrors, formValueSelector, SubmissionError } from 'redux-form'

import { AppState } from "store";
import targetComponent from 'components/instructor/QuestionForm';
import instructorActions from 'actions/instructorActions'

type FormData = {
  body?: string;
  attachment?: { data: string; file: File; };
  [key: string]: any
};

export const formKey = 'question_create'

export const FormValidate = (values: FormData) => {
  const errors: FormErrors<FormData> = {}
  const requiredFields: string[] = [
    'body',
  ]
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required'
    }
  })
  return errors
}

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch })

const mapStateToProps = (appState: AppState, ownProps: RouteComponentProps) => {
  const selector = formValueSelector(formKey);
  const questionBody = selector(appState, 'body');
  const questionAttachment = selector(appState, 'attachment') as { data: string; file: File } | null;
  return {
    user: appState.session.user!,
    instructor: appState.instructor,
    questionBody,
    questionAttachment,
    ...ownProps,
  }
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  { dispatch }: ReturnType<typeof mapDispatchToProps>) => {
  return {
    ...stateProps,
    fetchQuestion: (id: number) => {
      dispatch(instructorActions.fetchQuestionDetail({ id }))
    },
    getQuestion: (id: number) => {
      const page = stateProps.instructor.questionDetail
      if (id !== page.question_id) {
        return null
      }
      return page.record
    },
    updateQuestionToNotAnswered: () => {
      dispatch(instructorActions.updateQuestionDetailToNotAnswered())
    },
    onSubmit: (values: FormData) => {
      const id = stateProps.instructor.questionDetail.record!.id;
      const body = values.body!
      const attachment = values.attachment
      return new Promise<void>((resolve, reject) => {
        dispatch(instructorActions.createQuestionAnswer({
          params: {
            id,
            question: {
              answer: body,
              answer_attachment: attachment? {
                encoded_file: attachment.data,
                mime_type: attachment.file.type,
                file_name: attachment.file.name,
                file_size: attachment.file.size,
              } : undefined,
            },
          },
          promises: { resolve, reject }
        }))
      }).catch((e: Error) => {
        throw new SubmissionError({})
      })
    }
  }
}

type connectMappedProps = ReturnType<typeof mergeProps>
export type mappedProps = connectMappedProps & InjectedFormProps<FormData, connectMappedProps>

const form = reduxForm<FormData, connectMappedProps>({
  form: formKey,
  validate: FormValidate
})(targetComponent)

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(form))
