import React, { useEffect, useState } from 'react';

import { mappedProps } from 'containers/student/RegisterBorderResults';
import PublicHeader from 'components/parts/PublicHeader';
import { Field, Fields } from 'redux-form';
import { renderIfFieldsHasError, renderSelectField } from 'components/parts/FormParts';
import classNames from 'classnames';

export const SiteMainArea: React.FC = ({ children }) => {
  return (
    <main className="site-main">
      <div className="site-main__main site-main__main--simple">
        <div className="site-main__main-inner site-main__main-inner--simple">
          <div className="site-main__main-contents">
            <div className="examresult-container">
              { children }
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export const RegisterBorderResults: React.FC<mappedProps> = (props: mappedProps) => {
  const [confirm, setConfirm] = useState(false)

  useEffect(() => {
    props.verifyToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.token]);

  useEffect(() => {
    props.change('area', undefined)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.categoryValue]);

  useEffect(() => {
    props.change('university', undefined)

    if (props.areaValue && props.categoryValue) {
      props.fetchUniversities();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.areaValue]);

  useEffect(() => {
    props.change('faculty', undefined)

    if (props.universityValue && props.areaValue) {
      props.fetchFaculties();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.universityValue]);

  useEffect(() => {
    props.change('department', undefined)

    if (props.facultyValue && props.universityValue) {
      props.fetchDepartments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.facultyValue]);

  useEffect(() => {
    props.change('result', undefined)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.departmentValue]);

  useEffect(() => {
    if (props.borderResultsState.faculties) {
      const records = props.borderResultsState.faculties.results
      if (records.length === 0) {
        // MARK: 学部がない場合
        props.change('faculty', 'none')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.borderResultsState.faculties]);

  useEffect(() => {
    if (props.borderResultsState.departments) {
      const records = props.borderResultsState.departments.results
      if (records.length === 0) {
        // MARK: 学科がない場合
        props.change('department', 'none')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.borderResultsState.departments]);

  const verifyTokenResult = props.borderResultsState.verifyTokenResult
  const universitiesRecord = props.borderResultsState.universities
  const facultiesRecord = props.borderResultsState.faculties
  const departmentsRecord = props.borderResultsState.departments

  const selectedCategory = props.categories.find(x => x.key === props.categoryValue)
  const selectedArea = props.availableAreas && props.availableAreas.find(x => x.key === props.areaValue)
  const selectedUniversity = universitiesRecord && universitiesRecord.results.find(x => x.code === props.universityValue)
  const selectedFaculty = facultiesRecord && facultiesRecord.results.find(x => x.code === props.facultyValue)
  const selectedDepartment = departmentsRecord && departmentsRecord.results.find(x => x.code === props.departmentValue)
  const selectedResult = props.results.find(x => x.key === props.resultValue)

  // MARK: tokenが不正または未指定の場合
  if (!verifyTokenResult.loading && verifyTokenResult.error) {
    return <>
      <PublicHeader />
      <SiteMainArea>
        <div className="examresult-container__head">
          <h1 className="examresult-container__title examresult-container__title--error">
            認証ができませんでした。
          </h1>
        </div>
        <div className="examresult-container__text">
          URLが誤っているか、有効期限を過ぎています。
        </div>
        <div className="examresult-container__text">
          大変お手数ですが、このページを閉じて、受信したメールから再度ご登録手続きを行ってください。
        </div>
      </SiteMainArea>
    </>
  }

  // MARK: 送信済み
  if(props.submitSucceeded) {
    return <>
      <PublicHeader />
      <SiteMainArea>
        <div className="examresult-container__head">
          <h1 className="examresult-container__title examresult-container__title--complete">
            受験校／受験結果の送信が完了しました。
          </h1>
          <div className="examresult-container__lead examresult-container__lead--complete">
            他の受験校を登録される方は、下記よりお進みください。
          </div>
        </div>
        <div className="examresult-container__button examresult-container__button--complete">
          <a
            className="examresult-button" href="#!"
            onClick={(e) => {
              e.preventDefault();
              window.location.reload()
            }}
          >
            つづけて受験校を登録する
          </a>
        </div>
        <div className="examresult-container__note">
          終了する場合は、このウィンドウを閉じてください。
        </div>
      </SiteMainArea>
    </>
  }

  const formConfirmFragment = (
    <div style={{ display: confirm ? 'block' : 'none' }}>
      <div className="examresult-container__section">
        <div className="examresult-title">
          <h2 className="examresult-title__title">
            受験校
          </h2>
        </div>
        <table className="examresult-form">
          <tbody>
            <tr className="examresult-form__row">
              <th className="examresult-form__head examresult-form__head--confirm">
                区分
              </th>
              <td className="examresult-form__body examresult-form__body--confirm">
                { selectedCategory && selectedCategory.val }
              </td>
            </tr>
            <tr className="examresult-form__row">
              <th className="examresult-form__head examresult-form__head--confirm">
                エリア
              </th>
              <td className="examresult-form__body examresult-form__body--confirm">
                { selectedArea && selectedArea.val }
              </td>
            </tr>
            <tr className="examresult-form__row">
              <th className="examresult-form__head examresult-form__head--confirm">
                学校名
              </th>
              <td className="examresult-form__body examresult-form__body--confirm">
                { selectedUniversity && selectedUniversity.name }
              </td>
            </tr>
            <tr className="examresult-form__row">
              <th className="examresult-form__head examresult-form__head--confirm">
                学部
              </th>
              <td className="examresult-form__body examresult-form__body--confirm">
                { selectedFaculty && selectedFaculty.name }
              </td>
            </tr>
            <tr className="examresult-form__row">
              <th className="examresult-form__head examresult-form__head--confirm">
                学科
              </th>
              <td className="examresult-form__body examresult-form__body--confirm">
                { selectedDepartment && selectedDepartment.name }
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="examresult-container__section">
        <div className="examresult-title">
          <h2 className="examresult-title__title">
            受験結果
          </h2>
        </div>
        <div className="examresult-badge">
          { selectedResult && selectedResult.val }
        </div>
      </div>
    </div>
  )

  const formFragment = (
    <div className="examresult-container__main">
      <form onSubmit={props.handleSubmit}>
        { formConfirmFragment }

        <div style={{ display: confirm ? 'none' : 'block' }}>
          <div className="examresult-container__section">
            <div className="examresult-title">
              <h2 className="examresult-title__title">
                受験校
              </h2>
              <small className="examresult-title__note"><b>＊</b>必須項目</small>
            </div>

            {/* {
              props.invalid &&
              <div className="examresult-error examresult-error--large">
                正しく選択されていない項目があります。
              </div>
            } */}

            <table className="examresult-form">
              <tbody>
                <tr className="examresult-form__row">
                  <th className="examresult-form__head">
                    <b>＊</b>区分
                  </th>
                  <td className="examresult-form__body">
                    <div className="select">
                      <Field
                        component={renderSelectField}
                        name="category"
                        className={classNames('select__select select__select--examresult', {
                          'is-selected': props.categoryValue
                        })}
                      >
                        <option value="">選択してください</option>
                        { props.categories.map(x => <option key={x.key} value={x.key}>{ x.val }</option>)}
                      </Field>

                    </div>

                    <Fields names={[ 'category' ]} component={renderIfFieldsHasError}>
                      <div className="examresult-form__error">
                        <div className="examresult-error">
                          「区分」を選択してください
                        </div>
                      </div>
                    </Fields>
                  </td>
                </tr>
                <tr className="examresult-form__row">
                  <th className="examresult-form__head">
                    <b>＊</b>エリア
                  </th>
                  <td className="examresult-form__body">
                    <p className="examresult-form__lead">
                      エリアは本部が所在する都道府県を選択してください。
                    </p>
                    <div className={classNames("select", { 'is-disabled': !props.categoryValue })}>
                      <Field
                        component={renderSelectField}
                        name="area"
                        className={classNames('select__select select__select--examresult select__select--full', {
                          'is-selected': props.areaValue
                        })}
                        disabled={!props.categoryValue}
                      >
                        <option value="">選択してください</option>
                        { props.availableAreas && props.availableAreas.map(
                            x => <option key={x.key} value={x.key}>{ x.val }</option>
                          )
                        }
                      </Field>
                    </div>
                    {
                      props.categoryValue &&
                      <Fields names={[ 'area' ]} component={renderIfFieldsHasError}>
                        <div className="examresult-form__error">
                          <div className="examresult-error">
                            「エリア」を選択してください
                          </div>
                        </div>
                      </Fields>
                    }
                  </td>
                </tr>
                <tr className="examresult-form__row">
                  <th className="examresult-form__head">
                    <b>＊</b>学校名
                  </th>
                  <td className="examresult-form__body">
                    <div className={classNames("select select--full", { 'is-disabled': !props.areaValue })}>
                      <Field
                        component={renderSelectField}
                        name="university"
                        disabled={!props.areaValue}
                        className={classNames('select__select select__select--examresult select__select--full', {
                          'is-selected': props.universityValue
                        })}
                      >
                        <option value="">選択してください</option>
                        {
                          universitiesRecord && universitiesRecord.results.map(x => (
                            <option key={x.code} value={x.code}>{ x.name }</option>
                          ))
                        }
                      </Field>

                    </div>
                    {
                      props.areaValue &&
                      <Fields names={[ 'university' ]} component={renderIfFieldsHasError}>
                        <div className="examresult-form__error">
                          <div className="examresult-error">
                            「学校名」を選択してください
                          </div>
                        </div>
                      </Fields>
                    }
                  </td>
                </tr>
                <tr className="examresult-form__row">
                  <th className="examresult-form__head">
                    <b>＊</b>学部
                  </th>
                  <td className="examresult-form__body">
                    <div className={classNames("select select--full", { 'is-disabled': props.facultyValue === 'none' || !props.universityValue })}>
                      <Field
                        component={renderSelectField}
                        name="faculty"
                        disabled={props.facultyValue === 'none' || !props.universityValue}
                        className={classNames('select__select select__select--examresult select__select--full', {
                          'is-selected': props.facultyValue
                        })}
                      >
                        {
                          props.facultyValue === 'none' && <>
                            <option value="none">選択の必要はありません</option>
                          </>
                        }
                        {
                          props.facultyValue !== 'none' && <>
                            <option value="">選択してください</option>
                            {
                              facultiesRecord && facultiesRecord.results.map(x => (
                                <option key={x.code} value={x.code}>{ x.name }</option>
                              ))
                            }
                          </>
                        }
                      </Field>
                    </div>

                    {
                      props.facultyValue !== 'none' && props.universityValue &&
                      <Fields names={[ 'faculty' ]} component={renderIfFieldsHasError}>
                        <div className="examresult-form__error">
                          <div className="examresult-error">
                            「学部」を選択してください
                          </div>
                        </div>
                      </Fields>
                    }

                  </td>
                </tr>
                <tr className="examresult-form__row">
                  <th className="examresult-form__head">
                    <b>＊</b>学科
                  </th>
                  <td className="examresult-form__body">
                    <div className={classNames("select select--full", { 'is-disabled': props.departmentValue === 'none' || !props.facultyValue })}>
                      <Field
                        component={renderSelectField}
                        name="department"
                        disabled={props.departmentValue === 'none' || !props.facultyValue}
                        className={classNames('select__select select__select--examresult select__select--full', {
                          'is-selected': props.departmentValue
                        })}
                      >
                        {
                          props.departmentValue === 'none' && <>
                            <option value="none">選択の必要はありません</option>
                          </>
                        }
                        {
                          props.departmentValue !== 'none' && <>
                            <option value="">選択してください</option>
                            {
                              departmentsRecord && departmentsRecord.results.map(x => (
                                <option key={x.code} value={x.code}>{ x.name }</option>
                              ))
                            }
                          </>
                        }
                      </Field>

                    </div>
                    {
                      props.departmentValue !== 'none' && props.facultyValue &&
                      <Fields names={[ 'department' ]} component={renderIfFieldsHasError}>
                        <div className="examresult-form__error">
                          <div className="examresult-error">
                            「学科」を選択してください
                          </div>
                        </div>
                      </Fields>
                    }
                  </td>
                </tr>

              </tbody>
            </table>
          </div>
          <div className="examresult-container__section">
            <div className="examresult-title">
              <h2 className="examresult-title__title">
                受験結果
              </h2>
              <small className="examresult-title__note"><b>＊</b>必須項目</small>
            </div>
            {/* <div className="examresult-error examresult-error--large">
              受験結果が選択されていません。
            </div> */}
            <div className="examresult-radio-buttons">
              {
                props.results.map(x => (
                  <div key={x.key} className="examresult-radio-buttons__item">
                    <label
                      htmlFor={`result_radio_${x.key}`}
                      className={classNames("radio", {
                        'is-checked' : x.key === props.resultValue,
                      })}
                    >
                      <Field
                        component="input"
                        className="radio__radio"
                        type="radio"
                        name="result"
                        value={x.key}
                        id={`result_radio_${x.key}`}
                      />
                      {x.val}
                    </label>
                  </div>
                ))
              }

            </div>

            <Fields names={[ 'result' ]} component={renderIfFieldsHasError}>
              <div className="examresult-error examresult-error--center">
                「受験結果」を選択してください
              </div>
            </Fields>

          </div>

        </div>

        {
          !confirm && 
          <div className="examresult-container__buttons">
            <div className="examresult-container__button">
              {
                !confirm && <>
                  <button
                    onClick={(e) => {
                      e.preventDefault()
                      setConfirm(true)
                      window.scrollTo(0, 0);
                    }}
                    disabled={props.pristine || props.invalid}
                    className={classNames('button button--primary button--xlarge', {
                      'is-disabled' : props.pristine || props.invalid
                    })}
                  >登録内容を確認
                  </button>
                </>
              }
            </div>
          </div>
        }
        {
          confirm && 
          <div className="examresult-container__buttons">
            <div className="examresult-container__button">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setConfirm(false)
                  window.scrollTo(0, 0);
                }}
                className="button button--back button--gray button--xlarge">内容を変更する</button>
            </div>
            <div className="examresult-container__button">
              <button
                type="submit"
                disabled={props.submitting}
                className={classNames('button button--primary button--xlarge', {
                  'is-disabled' : props.submitting,
                })}
              >登録内容を送信</button>
            </div>
          </div>
        }
      </form>
    </div>
  )

  return (
    <>
      <PublicHeader />
      <SiteMainArea>
        <div className="examresult-container__head">
          <h1 className="examresult-container__title">
            受験校／受験結果のご登録
          </h1>
          <div className="examresult-container__lead">
            受験した大学と結果を入力してください。複数校を入力する場合は、登録完了後の画面から続けて入力ができます。
          </div>
        </div>

        { !verifyTokenResult.loading && formFragment }
      </SiteMainArea>

    </>
  );
}
