import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, InjectedFormProps, SubmissionError, } from 'redux-form'

import { AppState } from "store";
import SettingTrainerEdit from 'components/student/SettingTrainerEdit';
import userActions from 'actions/userActions'
import * as Models from 'models/api'

const formKey = 'setting_trainer_cancel'

type FormData = { [key: string]: any };

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch })
const mapStateToProps = (appState: AppState) => {
  const user = appState.session.user! as Models.StudentUser
  const initialValues = {}
  return {
    user,
    initialValues,
  }
}

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  { dispatch }: ReturnType<typeof mapDispatchToProps>) => {
  return {
    ...stateProps,
    onSubmit: (values: FormData) => {
      return new Promise<void>((resolve, reject) => {
        dispatch(userActions.cancelTrainer({ promises: { resolve, reject } }))
      }).catch((e: Error) => {
        throw new SubmissionError({})
      })
    }
  }
}

type connectMappedProps = ReturnType<typeof mergeProps>
export type mappedProps = connectMappedProps & InjectedFormProps<FormData, connectMappedProps>

const form = reduxForm<FormData, connectMappedProps>({
  form: formKey,
})(SettingTrainerEdit)

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(form)
