import React from 'react'
import { connect } from 'react-redux'
import Snackbar from '@material-ui/core/Snackbar';
import { Dispatch } from 'redux';

import uiActiions from 'actions/uiActions';
import NoticationContent from 'components/parts/NotificationContent'
import { AppState } from 'store';

const mapStateToProps = (state: AppState) => {
  return {
    ...state.ui.notification
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    handleClose: () => {
      dispatch(uiActiions.closeNotification())
    }
  }
}

export type mappedProps =ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

const Notification = (props: mappedProps) => (
  <Snackbar
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    open={props.open}
    autoHideDuration={5000}
    onClose={props.handleClose}
  >
    <NoticationContent
        onClose={props.handleClose}
        variant={props.level!}
        message={props.message!}
      />
  </Snackbar>
)

export default connect(mapStateToProps, mapDispatchToProps)(Notification)
