import moment, { Moment } from "moment";

// マイページバナー、動画ページ共に5/10日に公開終了
// シロ先生からの応援動画
// → 入会者のみ（status=regular）
// → 4/6 13:00 から画像から動画表示へ
// → 3/10 13:00、3/17 13:00、3/24 13:00 に1本ずつ公開（画像から動画表示へ）
// https://docs.google.com/presentation/d/1p-Sns2ZCLMWLbwtYOlF-lEqNjixHceRmyJ1-1AyMsWM/edit#slide=id.g8117bc88d0_0_4

const setting: { [key: string]: any } = {
  production: {
    startAt:   moment('2020-03-10T13:00:00+0900'),
    mainVideo: moment('2020-04-10T00:00:00+0900'),
    subVideo1: moment('2020-03-10T13:00:00+0900'),
    subVideo2: moment('2020-03-17T00:00:00+0900'),
    subVideo3: moment('2020-03-23T00:00:00+0900'),
    endAt:     moment('2020-05-11T00:00:00+0900'),
  },
  staging: {
    startAt:   moment('2020-03-06T00:00:00+0900'),
    mainVideo: moment('2020-03-31T00:00:00+0900'), // 日時指定なかったのでproductionの一週間前
    subVideo1: moment('2020-03-03T13:00:00+0900'),
    subVideo2: moment('2020-03-09T18:00:00+0900'),
    subVideo3: moment('2020-03-09T19:00:00+0900'),
    endAt:     moment('2020-03-10T00:00:00+0900'),
  },
  development: {
    startAt:   moment('2020-03-01T00:00:00+0900'),
    mainVideo: moment('2020-03-09T00:00:00+0900'),
    subVideo1: moment('2020-03-01T00:00:00+0900'),
    subVideo2: moment('2020-03-09T00:00:00+0900'),
    subVideo3: moment('2020-03-09T00:00:00+0900'),
    endAt:     moment('2020-03-10T00:00:00+0900'),
  }
}

export const isOpenedKey = (type: string, now: Moment, key: string): boolean => {
  if (!isAvailable(type, now)) {
    return false
  }
  const dt = setting[type][key]
  return dt <= now
}

export const isAvailable = (type: string, now: Moment): boolean => {
  const endAt = setting[type].endAt
  const startAt = setting[type].startAt
  return endAt > now && now >= startAt
}
