import React, { useEffect, useState } from 'react';
import { Link, useParams, } from 'react-router-dom';

import PrivateHeader from 'containers/parts/PrivateHeader';
import { mappedProps } from 'containers/parent/StudentStudyCourseCompleteResult';
import SiteMain from 'containers/parts/SiteMain';
import { CommonTestHintBalloon } from 'components/common/course_completion_test/HintBalloon';
import { CourseCompletionTestResultBoxItem } from 'components/common/course_completion_test/CourseCompletionTestResultBoxItem';
import StudentBar from '../parts/CommonStudentBar'
import classNames from 'classnames';

export const StudentStudyCourseCompleteResult: React.FC<mappedProps> = (props: mappedProps) => {
  // MARK: 講師、トレーナーとインターフェース統一のためダミーIDを指定
  const id = 1;
  const { unit_id } = useParams<{ unit_id: string }>();
  const [showMore, setShowMore] = useState<boolean>(false);

  useEffect(() => {
    if (unit_id) {
      props.fetchData(id, Number(unit_id))

      // MARK: 以前の結果を表示を初期化
      setShowMore(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unit_id]);

  const student = props.getStudent(Number(id))
  const record = props.getRecord(id, Number(unit_id))
  const moreRecord = props.getMoreRecord(id, Number(unit_id))
  const hasMore = record && record.total_page > 1

  return (
    <React.Fragment>
      <PrivateHeader />
      <SiteMain>
        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          {
            record &&
            <>
              <li className="breadcrumbs__item">
                <Link to={`/student/study_result/${record.curriculum.id}`} className="breadcrumbs__link">
                  { record.curriculum.name }
                </Link>
              </li>
              <li className="breadcrumbs__item">
                <Link to={`/student/study_result/courses/${record.course.id}`} className="breadcrumbs__link">
                  { record.course.name }
                </Link>
              </li>
              <li className="breadcrumbs__item">
                科目修了テスト
              </li>
            </>
          }
        </ol>

        <div className="site-main__main-contents">

          {
            student &&
            <StudentBar student={student} />
          }

          {
            record && <>
            <div className="page-title">
              { record.curriculum.name }&nbsp;
              <div className="page-title__badge">
                { record.course.name }
              </div>
            </div>
            <div className="test-box">
              <div className="test-box__title test-box__title--large">
                科目修了テスト
              </div>

              <CommonTestHintBalloon />

              <div className="test-box__body">
                <div className="completion-test-result-box">
                  <div className="completion-test-result-box__head completion-test-result-box__head--completion">
                    <div className="completion-test-result-box__point-head">
                      合計点数
                    </div>
                    <div className="completion-test-result-box__expectation-head _pc">
                      共通テスト予想点数
                    </div>
                    <div className="completion-test-result-box__date-head">
                      実施日時
                    </div>
                  </div>
                  <div className="completion-test-result-box__body is-active">
                    {
                      record.results.length <= 0 && 
                      <div className="completion-test-result-box__result completion-test-result-box__result--empty">
                        テスト結果がありません
                      </div>
                    }
                    {
                      record.results.map((x, index) => 
                      <CourseCompletionTestResultBoxItem key={index} result={x} />)
                    }
                  </div>

                  {
                    hasMore && 
                    <div className="completion-test-result-box__more">
                      <div className="more-button more-button--result">
                        <div
                          className={classNames("more-button__text more-button__text--result", {
                            'is-active': showMore,
                          })}
                          onClick={e => {
                            e.preventDefault()
                            setShowMore(!showMore)
                            if (!moreRecord) {
                              props.fetchMore()
                            }
                          }}
                        >
                          以前の結果を表示
                        </div>
                      </div>
                    </div>
                  }
                  {
                    moreRecord && showMore &&
                    <div className="completion-test-result-box__body is-active">
                      {
                        moreRecord.results.length <= 0 && 
                        <div className="completion-test-result-box__result completion-test-result-box__result--empty">
                          テスト結果がありません
                        </div>
                      }
                      {
                        moreRecord.results.map((x, index) =>
                          <CourseCompletionTestResultBoxItem key={index} result={x} />)
                      }
                    </div>
                  }

                  <div className="completion-test-result-box__button">
                    <Link
                      to={`/student/study_result/courses/${record.course.id}`}
                      className="button button--xlarge button--gray button--back">
                      前のページへ戻る
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            </>
          }
          <div className="back-home">
            <Link to='/' className="back-home__link">マイページへ戻る</Link>
          </div>
        </div>

        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          {
            record &&
            <>
              <li className="breadcrumbs__item">
                <Link to={`/student/study_result/${record.curriculum.id}`} className="breadcrumbs__link">
                  { record.curriculum.name }
                </Link>
              </li>
              <li className="breadcrumbs__item">
                <Link to={`/student/study_result/courses/${record.course.id}`} className="breadcrumbs__link">
                  { record.course.name }
                </Link>
              </li>
              <li className="breadcrumbs__item">
                科目修了テスト
              </li>
            </>
          }
        </ol>
      </SiteMain>
    </React.Fragment>
  );
}