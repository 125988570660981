import React, { useEffect } from 'react';
import { Link, useParams, Redirect } from 'react-router-dom';

import PrivateHeader from 'containers/parts/PrivateHeader';
import SiteMain from 'containers/parts/SiteMain';
import StudentBar from '../parts/CommonStudentBar'
import StudentNav from '../parts/InstructorStudentNav';

import { mappedProps } from 'containers/instructor/StudentStudyResultCurriculum';

const TrainerStudentStudyResultCurriculum: React.FC<mappedProps> = (props: mappedProps) => {
  const { id, curriculum_id } = useParams();

  const curriculum = curriculum_id && props.getCurriculum(curriculum_id)

  useEffect(() => {
    if (id && curriculum) {
      props.fetchData(Number(id), curriculum.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, curriculum_id]);

  if (!curriculum) {
    return <Redirect to='/' />;
  }

  const student = props.getStudent(Number(id))
  const page = student && props.getRecord(student.id, curriculum.id)

  return (
    <React.Fragment>
      <PrivateHeader />
      <SiteMain>
        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          <li className="breadcrumbs__item">
            受講者情報
          </li>
        </ol>

        {
          student && page &&
          <div className="site-main__main-contents">
            <StudentBar student={student} />
            <StudentNav student={student} type="study_result" />

            <div className="study-breadcrumbs">
              <Link to={`/students/${student.id}/study_result`} className="study-breadcrumbs__item">
                教科
              </Link>
              <div className="study-breadcrumbs__item">
                科目
              </div>
            </div>

            <div className="page-subtitle">
              科目
            </div>

            <ul className="subject-list">
              { page.courses.map(course => (
                <li key={course.id} className="subject-list__item">
                  <Link to={`/students/${student.id}/study_result/courses/${course.id}`} className="subject-list__link">
                    { course.title }
                  </Link>
                </li>
              ))}
            </ul>

            <div className="back-home">
              <Link to='/' className="back-home__link">マイページへ戻る</Link>
            </div>
          </div>
        }

        <ol className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to='/' className="breadcrumbs__link">マイページ</Link>
          </li>
          <li className="breadcrumbs__item">
            受講者情報
          </li>
        </ol>
      </SiteMain>
    </React.Fragment>
  );
}

export default TrainerStudentStudyResultCurriculum